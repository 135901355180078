import { useSnackbar } from '@elentari/core'
import {
  Box,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import { FormikValues } from 'formik'
import { useEffect } from 'react'
import { Paper } from 'src/components'
import { FormikStepper } from 'src/components/Formik/FormikStepper'
import { uploadManyDocumentos } from 'src/modules/candidato/hooks/documentosRepository'
import { ExperienciaProfMotoristaCurriculos } from 'src/utils/yup-schemas/candidato/expProfStepSchema'
import { validationMotoristaCurriculo } from 'src/utils/yup-schemas/candidato/motoristaStepSchema'
import logoG10 from '../../../../images/g10-logo-2.png'
import logoTp from '../../../../images/tp-logo.png'
import CandidatoCurriculosStep from '../../components/CandidatoCurriculoStep'
import ExperienciaProfCurriculoStep from '../../components/ExperienciaProfCurriculoStep'
import { SaveCurriculosApi } from '../../hooks'
import { Curriculo, InfoFile } from '../../types'

interface Props {
  candidatoType: 'Administrativo' | 'Motorista'
  empresa: 'Transpanorama' | 'G10'
}

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: '#FFFF',
    boxShadow: '0px 4px 20px rgba(0,0,0,0.1)'
  },
  logoImage: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

const Motorista = ({ candidatoType, empresa }: Props) => {
  const [, snackbarActions] = useSnackbar()
  const classes = useStyles()
  const formikState = {
    initialValues: {
      nomeCompleto: '',
      uf: '',
      tipo: '',
      telefone: '',
      celular: '',
      cidade: '',
      email: '',
      cpf: '',
      dataNascimento: null,
      empresaId: '',
      primeiroEmprego: false,
      recaptcha: null,
      anexoCurriculo: {
        file: {} as File,
        name: '',
        path: ''
      } as InfoFile,
      anexoCNH: {
        file: {} as File,
        name: '',
        path: ''
      } as InfoFile,
      Cursos: [
        {
          descricao: '',
          dataInicio: null,
          dataConclusao: null,
          cargaHoraria: 0
        }
      ],
      Motorista: {
        categoriaCNH: '',
        rg: '',
        estadoCivil: null,
        dataValidadeMopp: null,
        pamcary: '',
        earCNH: '',
        mopp: '',
        grauEscolaridade: '',
        indicacao: null,
        cargoQuemIndicou: '',
        quemIndicou: '',
        coordenadorQuemIndicou: ''
      },
      ExperienciaProfissional: [
        {
          empresa: '',
          cidade: '',
          uf: '',
          telefone: '',
          dataEntrada: null,
          dataSaida: null,
          tipoCaminhao: null,
          tempoExperienciaCaminhao: 0,
          atualmenteEmpregado: false,
          principaisAtividades: ''
        },
        {
          empresa: '',
          cidade: '',
          uf: '',
          telefone: '',
          dataEntrada: null,
          dataSaida: null,
          tipoCaminhao: null,
          tempoExperienciaCaminhao: 0,
          atualmenteEmpregado: false,
          principaisAtividades: ''
        },
        {
          empresa: '',
          cidade: '',
          uf: '',
          telefone: '',
          dataEntrada: null,
          dataSaida: null,
          tipoCaminhao: null,
          tempoExperienciaCaminhao: 0,
          atualmenteEmpregado: false,
          principaisAtividades: ''
        }
      ]
    },
    onSubmit: async (data: FormikValues) => {
      try {
        data.tipo = 'MOTORISTA'
        data.origemCurriculo = 'SITE'
        data.empresaId = empresa === 'Transpanorama' ? 'TP' : 'G10'
        if (!data.Motorista.indicacao) {
          data.Motorista.quemIndicou = ''
          data.Motorista.cargoQuemIndicou = ''
          data.Motorista.coordenadorQuemIndicou = ''
        }

        data.AreaInteresse = []

        const documentos = await uploadManyDocumentos([
          {
            ...data.anexoCurriculo,
            descricao: 'anexoCurriculo',
            candidatoId: data.id,
            siglaEmpresa: data.empresaId
          },
          {
            ...data.anexoCNH,
            descricao: 'anexoCNH',
            candidatoId: data.id,
            siglaEmpresa: data.empresaId
          }
        ])
        if (!documentos) {
          return snackbarActions.setMessage(
            'Ocorreu um erro ao salvar o(s) documento(s)'
          )
        }

        delete data.anexoCurriculo
        delete data.anexoCNH

        const response = await SaveCurriculosApi({
          ...data,
          documentos
        } as Curriculo)

        if (response.status !== 200 && !response.ok) {
          throw new Error(response.info.message)
          data.recaptcha = ''
        } else {
          snackbarActions.setMessage('Dados enviados com sucesso.')
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  useEffect(() => {
    document.title = `${empresa === 'G10' ? 'G10' : 'TP'} - Envio de Currículos`
    const favIcon = document.getElementById('icon') as HTMLLinkElement
    if (empresa === 'Transpanorama') {
      favIcon.href = '/faviconTP.ico'
    } else {
      favIcon.href = '/favicon.ico'
    }
  }, [])

  useEffect(() => {
    return () => {}
  }, [])

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box padding={2} className={classes.header}>
            <Grid item xs={12} className={classes.logoImage}>
              <Typography variant="h4">Sou Motorista</Typography>
              <img
                src={empresa === 'Transpanorama' ? logoTp : logoG10}
                alt={empresa}
                width={empresa === 'Transpanorama' ? 90 : 120}
                height={empresa === 'Transpanorama' ? 70 : 60}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontSize: 12 }} component="span">
                *Para todos os tipos de caminhão/logística é exigido experiência
                comprovada na função e experiência com viagens interestaduais.
              </Typography>
              <Divider />
            </Grid>
          </Box>
          <Paper noTopBorderRadius>
            <Grid container spacing={2} direction="column">
              <FormikStepper
                onSubmit={formikState.onSubmit}
                initialValues={formikState.initialValues}
              >
                <CandidatoCurriculosStep
                  empresa={empresa}
                  label="Candidato(a)"
                  candidatoType={candidatoType}
                  validationSchema={validationMotoristaCurriculo}
                />

                <ExperienciaProfCurriculoStep
                  label="Experiência Profissional"
                  candidatoType={candidatoType}
                  validationSchema={ExperienciaProfMotoristaCurriculos}
                />
              </FormikStepper>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Motorista
