import { apiSauceInstance } from '../../../services/api'
import { InteresseDetail, SaveInteresseInput } from '../types'
import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { ParsedQs } from 'qs'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params
  return { ...paginationParams(rest), ...params }
}

export const InteresseRepository = makeRepository<
  EdgesPage<InteresseDetail>,
  SaveInteresseInput,
  InteresseDetail,
  string
>(
  {
    resource: 'interesse',
    mapQueryParams
  },
  apiSauceInstance
)
