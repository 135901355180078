import {
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import { differenceInMonths, differenceInYears, isValid } from 'date-fns'
import { useField, useFormikContext } from 'formik'
import { isNil } from 'ramda'
import { useEffect, useState } from 'react'
import { ExperienciaProfissional } from 'src/modules/candidato/types'

interface Item {
  label: string
  value: number
}

interface Convertion {
  items: Item[]
  operator: 'MULT' | 'DIV' | 'ADD' | 'SUB'
}

interface ConvertionProps {
  name: string
  hidden?: boolean
  label: string
  convertion: Convertion
  diffDates?: {
    startDate: string | Date | null
    endDate: string | Date | null
  }
  disabled: boolean
  index: number
}

export const useConvertionStyles = makeStyles(theme => ({
  textField: {
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
    {
      display: 'none'
    }
  },
  select: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '0.5px 10px'
  },
  endAdornment: {
    padding: 0
  }
}))

export function ConvertionSelect(
  items: Item[],
  defaultValue: Number,
  onChange: (event: any) => void
): JSX.Element {
  const style = useConvertionStyles()

  return (
    <InputAdornment position="end">
      <Select
        className={style.select}
        defaultValue={defaultValue}
        disableUnderline
        onChange={onChange}
      >
        {items.map(item => {
          return (
            <MenuItem key={item.label} value={item.value}>
              {item.label}
            </MenuItem>
          )
        })}
      </Select>
    </InputAdornment>
  )
}

function ConvertionInput(props: ConvertionProps) {
  const getDefaultValues = (
    fieldValue: number
  ): { inputValue: number; convertionValue: number } => {
    let highestMatch = 1

    if (fieldValue >= 12) {
      highestMatch = 12
    }

    return {
      inputValue: fieldValue / highestMatch,
      convertionValue: highestMatch
    }
  }

  const { name, label, convertion, disabled } = props
  const formik = useFormikContext<{ ExperienciaProfissional: ExperienciaProfissional[] }>()
  const [field, meta, helpers] = useField(props)
  const defaultValue = getDefaultValues(field.value)

  const [inputValue, setInputValue] = useState(
    defaultValue.inputValue.toString()
  )
  const [convertionValue, setConvertionValue] = useState(
    defaultValue.convertionValue
  )
  const classes = useConvertionStyles()


  const getDifferences = (fieldValue: number) => {
    const experienciaProfissional = formik.values.ExperienciaProfissional[props.index]
    const dataSaida = !experienciaProfissional.atualmenteEmpregado ? experienciaProfissional.dataSaida : new Date().toISOString()
    if (
      convertionValue === 12 &&
      experienciaProfissional &&
      experienciaProfissional.dataEntrada &&
      dataSaida
    ) {
      const initialDate = new Date(experienciaProfissional.dataEntrada)
      const endDate = new Date(dataSaida)

      const years = differenceInYears(endDate, initialDate)
      const month = Math.floor(fieldValue % 12)

      return `${years}.${month}`
    } else {
      return `${fieldValue}`
    }
  }

  const handleOnChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      typeof event.target.value === 'number' ? event.target.value : 0
    setConvertionValue(value)
    setFormikValue(Number.parseInt(inputValue), value)
  }

  const setFormikValue = (
    inputValue: number,
    convertionValue: number
  ): void => {
    let convertedValue = 0
    switch (convertion.operator) {
      case 'ADD':
        convertedValue = inputValue + convertionValue
        break
      case 'SUB':
        convertedValue = inputValue - convertionValue
        break
      case 'MULT':
        convertedValue = inputValue * convertionValue
        break
      case 'DIV':
        convertedValue = inputValue / convertionValue
        break
      default:
        convertedValue = 0
    }
    helpers.setValue(convertedValue)
  }

  const updateField = (fieldValue: number) => {
    const value = getDifferences(fieldValue)
    setInputValue(value)
    helpers.setValue(fieldValue)
  }

  useEffect(() => {
    if (props.diffDates) {
      let diffMonths = 0
      const exp = formik.values.ExperienciaProfissional[props.index]

      const initialDate =
        !isNil(exp.dataEntrada) ? new Date(exp.dataEntrada) : NaN
      const endDate = !isNil(exp.dataSaida) ? new Date(exp.dataSaida) : NaN

      if (isValid(initialDate) && isValid(endDate)) {
        diffMonths = differenceInMonths(endDate, initialDate)
      } else if (isValid(initialDate) && exp.atualmenteEmpregado === true) {
        diffMonths = differenceInMonths(new Date(), initialDate)
      }

      if (diffMonths !== NaN && Math.sign(diffMonths) >= 0) {
        if (field.value !== diffMonths) {
          updateField(diffMonths)
        }
      }
    }
  }, [props.diffDates, field.value])

  const changeFieldValue = (event: { target: { value: string } }) => {
    setInputValue(event.target.value)
    setFormikValue(Number.parseInt(event.target.value), convertionValue)
  }

  return (
    <div hidden={props.hidden}>
      <TextField
        variant="outlined"
        fullWidth
        className={classes.textField}
        name={name}
        label={label}
        type="number"
        disabled={disabled}
        value={inputValue}
        onChange={changeFieldValue}
        onBlur={changeFieldValue}
        InputProps={{
          endAdornment: ConvertionSelect(
            convertion.items,
            defaultValue.convertionValue,
            handleOnChangeSelect
          ),
          classes: {
            adornedEnd: classes.endAdornment
          }
        }}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      />
    </div>
  )
}

export default ConvertionInput
