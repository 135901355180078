import { apiSauceInstance } from '../../../services/api'
import { ListaBloqueioDetail, SaveListaBloqueioInput } from '../types'
import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { ParsedQs } from 'qs'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params
  return { ...params, ...paginationParams(rest) }
}

export const listaBloqueioRepository = makeRepository<
  EdgesPage<ListaBloqueioDetail>,
  SaveListaBloqueioInput,
  ListaBloqueioDetail,
  string
>(
  {
    resource: 'lista-bloqueios',
    mapQueryParams
  },
  apiSauceInstance
)

interface DesbloqueioType {
  id: number
  motivoDesbloqueio: string
}

export const desbloquear = async (data: DesbloqueioType) => {
  const response = await apiSauceInstance.post<any>(
    '/lista-bloqueios/desbloquear',
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const bloquear = async (data: { id: number }) => {
  const response = await apiSauceInstance.post<any>(
    '/lista-bloqueios/bloquear',
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}
