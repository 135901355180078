import { Button, Grid, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useFormikContext } from 'formik'

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import TextInput from 'src/components/Formik/Forms/TextInput'

export default function PassosAdmissaoInput() {
  const { values, setFieldValue } = useFormikContext<{ passos: string[] }>()

  const handleAddPassos = () => {
    setFieldValue('passos', [...values.passos, ''])
  }

  const handleRemovePassos = (index: number) => {
    const passo = [...values.passos]
    if (passo.length < 2) {
      return
    }
    passo.splice(index, 1)
    setFieldValue('passos', [...passo])
  }

  return (
    <>
      {values?.passos &&
        values.passos.map((passo, index) => (
          <Grid
            container
            key={index}
            style={{
              paddingBottom: 20,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Grid item xs={values.passos.length > 1 ? 11 : 12}>
              <TextInput
                name={`passos.${index}`}
                label={` ${index + 1}° Passo`}
                placeholder="Sequência de Passos a serem seguidos."
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              xs={1}
            >
              {values.passos.length > 1 && (
                <Tooltip placement="top" title={`Deletar ${index + 1}° Passo`}>
                  <Button
                    style={{ flex: 1, height: 56, border: 'none' }}
                    variant="outlined"
                    color="primary"
                    onClick={() => handleRemovePassos(index)}
                  >
                    <DeleteOutlineIcon />
                  </Button>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        ))}

      <Tooltip placement="right-start" title="Adicionar Passo">
        <Button
          variant="outlined"
          color="primary"
          type="button"
          onClick={handleAddPassos}
        >
          <AddIcon />
        </Button>
      </Tooltip>
    </>
  )
}
