import { TriagemDetail, SaveTriagemInput } from './../types'
import { apiSauceInstance } from '../../../services/api'

import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { ParsedQs } from 'qs'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params
  return { ...params, ...paginationParams(rest) }
}

export const repository = makeRepository<
  EdgesPage<TriagemDetail>,
  SaveTriagemInput,
  TriagemDetail,
  string
>(
  {
    resource: 'triagem',
    mapQueryParams
  },
  apiSauceInstance
)

export const fetchTriagemByCandidatoId = async (candidatoId: string) => {
  const response = await apiSauceInstance.get<any>(
    `/triagem/candidato/${candidatoId}`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const save = async (data: TriagemDetail) => {
  if (data.id) {
    const response = await apiSauceInstance.put<any>(
      `/triagem/${data.id}`,
      data
    )

    const { data: info, ok, status } = response

    if (info && ok) {
      return {
        info,
        ok,
        status
      }
    }
    return null
  } else {
    const response = await apiSauceInstance.post<any>(`/triagem`, data)

    const { data: info, ok, status } = response

    if (info && ok) {
      return {
        info,
        ok,
        status
      }
    }
    return null
  }
}
