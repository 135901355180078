type CargoStatus = 'ATIVO' | 'INATIVO' | 'PENDENTE'

export interface CargoDetail {
  id: string
  nome: string
  area: string
  categoria: string
  descricao: string
  nivelExperiencia: string
  salarioMin: number
  salarioMax: number
  status: CargoStatus
  criadoPor?: string
  empresaId: string
}

export interface CargoFormData extends CargoDetail {}

export interface SaveCargoInput extends CargoDetail {}

export interface CargoSubmitValues {
  nome?: string
  area?: string
  categoria?: string
  status?: string
}

interface Beneficio {
  id?: string
  descricao: string
  cargoId?: string
  empresaId?: string
}

export interface CargoBeneficioDetail {
  cargoId: string
  Beneficios: Beneficio[]
}

export interface CargoBeneficioFormData extends CargoBeneficioDetail {}

export interface SaveCargoBeneficioInput extends CargoBeneficioDetail {}

export interface CargoBeneficioSubmitValues {
  descricao?: string
}

interface Responsabilidade {
  id?: string
  descricao: string
  cargoId?: string
  empresaId?: string
}

export interface CargoResponsabilidadeDetail {
  cargoId: string
  Responsabilidades: Responsabilidade[]
}

export interface CargoResponsabilidadeFormData
  extends CargoResponsabilidadeDetail {}

export interface SaveCargoResponsabilidadeInput
  extends CargoResponsabilidadeDetail {}

export interface CargoResponsabilidadeSubmitValues {
  descricao?: string
}

interface Requisito {
  id?: string
  descricao: string
  cargoId?: string
  empresaId?: string
}

export interface CargoRequisitoDetail {
  cargoId: string
  Requisitos: Requisito[]
}

export interface CargoRequisitoFormData extends CargoRequisitoDetail {}

export interface SaveCargoRequisitoInput extends CargoRequisitoDetail {}

export interface CargoRequisitoSubmitValues {
  descricao?: string
}

export const StatusCargo = [
  {
    label: 'Ativo',
    value: 'ATIVO'
  },
  {
    label: 'Pendente',
    value: 'PENDENTE'
  },
  {
    label: 'Inativo',
    value: 'INATIVO'
  }
]
