import { Button, Divider, Grid, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import AutoCompleteInput from 'src/components/Formik/Forms/AutoCompleteInput'
import DateInput from 'src/components/Formik/Forms/DateInput'
import SelectInput from 'src/components/Formik/Forms/SelectInput'
import { Option } from 'src/hooks/useAutoComplete'
import { treinamentosRepository } from 'src/modules/treinamentos/hooks/treinamentosRepository'
import { tipoTurnos } from '../../types'

export default function AgendamentosAdmissaoInput() {

  const [treinamentos, setTreinamentos] = useState<Option[]>()

  useEffect(() => {
    async function fetchTreinamentos() {
      const response = await treinamentosRepository.fetch({ limit: '999' })

      if (response.ok) {
        setTreinamentos(
          response.data?.edges.map(treinamento => {
            return {
              id: treinamento.node.id,
              name: treinamento.node.nome
            }
          })
        )
      }
    }

    fetchTreinamentos()
  }, [])

  const { values, setFieldValue } = useFormikContext<{
    AgendaInstrucao: [
      {
        data: string
        turno: string
        treinamentoId: string
      }
    ]
  }>()

  const handleAddAgenda = () => {
    setFieldValue('AgendaInstrucao', [
      ...values.AgendaInstrucao,
      {
        data: null,
        turno: '',
        treinamentoId: ''
      }
    ])
  }

  const handleRemoveAgenda = (index: number) => {
    const agenda = [...values.AgendaInstrucao]
    if (agenda.length < 2) {
      return
    }
    agenda.splice(index, 1)
    setFieldValue('AgendaInstrucao', [...agenda])
  }

  return (
    <Grid container spacing={2} style={{ paddingBottom: 20 }}>
      {values.AgendaInstrucao &&
        values.AgendaInstrucao.map((agenda, index) => (
          <>
            <Grid
              container
              key={index}
              spacing={2}
              xs={values.AgendaInstrucao.length > 1 ? 11 : 12}
              style={{ paddingBottom: 20 }}
            >
              <Grid item xs={12} sm={6}>
                <AutoCompleteInput
                  label="Treinamento"
                  name={`AgendaInstrucao.${index}.treinamentoId`}
                  options={treinamentos ?? []}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DateInput
                  label="Data"
                  name={`AgendaInstrucao.${index}.data`}
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <SelectInput
                  label="Período"
                  name={`AgendaInstrucao.${index}.turno`}
                  options={tipoTurnos}
                />
              </Grid>

              <Divider />
            </Grid>
            {values.AgendaInstrucao.length > 1 && (
              <Tooltip placement="top" title={`Deletar ${index + 1}° Treinamento`}>
                <Button
                  style={{
                    flex: 1,
                    height: 60,
                    border: 'none'
                  }}
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => handleRemoveAgenda(index)}
                >
                  <DeleteOutlineIcon />
                </Button>
              </Tooltip>
            )}
          </>
        ))}
      <Grid item xs={12} sm={3}>
        <Tooltip placement="right-start" title="Adicionar Treinamento">
          <Button
            variant="outlined"
            color="primary"
            type="button"
            onClick={handleAddAgenda}
          >
            <AddIcon />
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  )
}
