import { CargoCandidato } from '../types'

export function normalizeCargoCandidato(cargoCandidato: CargoCandidato[] | undefined): number | undefined {
  if(cargoCandidato && cargoCandidato.length > 0) {
    return cargoCandidato[0].aderencia
  }

  return undefined
}

export function normalizeToStringCargoCandidato(cargoCandidato: CargoCandidato[] | undefined): string | undefined {
  if(cargoCandidato && cargoCandidato.length > 0) {
    return `${cargoCandidato[0].aderencia}%`
  }

  return undefined
}