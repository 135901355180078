import { apiSauceInstance } from '../../../services/api'
import { TreinamentoDetail } from '../types'
import { EdgesPage } from '@elentari/core/types'

export const treinamentosEmAndamento = async () => {
  const response = await apiSauceInstance.get<EdgesPage<TreinamentoDetail>>('/treinamentos', {
    status: 'EM_ANDAMENTO'
  })

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}
