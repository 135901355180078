import { Typography } from "@material-ui/core"
import ReactHtmlParser from 'react-html-parser'
import { ProcessoSeletivoAdministrativoDetail, statusProcessoAdministrativo, tipoReprovacao } from "../types"

type ProcessoAdministrativoModalDetailsProps = {
  processoAdministrativo: ProcessoSeletivoAdministrativoDetail
}

export const ProcessoAdministrativoModalDetails = ({ processoAdministrativo }: ProcessoAdministrativoModalDetailsProps) => {
  return (
    <>
      <Typography color="textSecondary">
        <strong>Status do Processo: </strong>
        {
          statusProcessoAdministrativo.find(
            value => value.value === processoAdministrativo.status
          )?.label
        }
      </Typography>
      <Typography color="textSecondary">
        <strong>Vaga: </strong> {processoAdministrativo.vagasFK?.cargoFk.nome}
      </Typography>
      <Typography color="textSecondary">
        <strong>Aderência: </strong>
        {`${processoAdministrativo.aderencia}%`}
      </Typography>
      {processoAdministrativo.criadoPor && (
        <Typography color="textSecondary">
          <strong>Criado por: </strong> {processoAdministrativo.criadoPor}
        </Typography>
      )}
      {processoAdministrativo.tipoReprovacao && (
        <Typography color="textSecondary">
          <strong>Tipo Reprovação: </strong>
          {
            tipoReprovacao.find(
              value => value.value === processoAdministrativo.tipoReprovacao
            )?.label
          }
        </Typography>
      )}
      {processoAdministrativo.motivoReprovacao && (
        <Typography color="textSecondary">
          <strong>Motivo Reprovação: </strong>
          {ReactHtmlParser(processoAdministrativo.motivoReprovacao)}
        </Typography>
      )}
      <Typography color="textSecondary">
        <strong>Comentário: </strong>
        {ReactHtmlParser(processoAdministrativo.comentario)}
      </Typography>
    </>
  )
}