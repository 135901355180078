import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useAppBar } from '../../../../hooks'
import { handleStateErrorsToRender } from '../../../../utils/handleFetchStateError'
import { useAdministrativo } from '../../administrativo/hooks/useAdministrativo'
import { useMotorista } from '../../motorista/hooks/useMotorista'
import {
  AdmissaoCandidatoAdministrativoForm,
  AdmissaoCandidatoMotoristaForm
} from './AdmissaoCandidatoForm'

export function AdmissaoCandidatoMotoristaMasterDetail() {
  const [state, actions] = useMotorista()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      state,
      u => u.entity.id,
      u => u.entity.nomeCompleto
    )
  ])
  useFetchAndReset(actions.fetch, actions.reset)
  return handleStateErrorsToRender(state, <AdmissaoCandidatoMotoristaForm />)
}

export function AdmissaoCandidatoAdministrativoMasterDetail() {
  const [state, actions] = useAdministrativo()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      state,
      u => u.entity.id,
      u => u.entity.nomeCompleto
    )
  ])
  useFetchAndReset(actions.fetch, actions.reset)
  return handleStateErrorsToRender(
    state,
    <AdmissaoCandidatoAdministrativoForm />
  )
}
