import { Button, Grid, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import ComplexDialog from "src/components/ComplexDialog";
import RichTextField from 'src/components/Forms/RichTextField';
import Select from '@elentari/components-eve/final-form/Select'
import TextField from "@elentari/components-eve/final-form/TextField";
import DatePicker from '@elentari/components-eve/final-form/DatePicker';
import EventIcon from '@material-ui/icons/Event';
import { tipoContratacao, tiposOperacao } from "src/utils";
import * as yup from 'yup'
import { useSnackbar } from "@elentari/core";
import yupValidation from "src/utils/yupValidation";
import { MessagesYup } from "src/modules/messages";
import { useLoggedUser } from "src/modules/login/AuthProvider";
import { admitirCandidato } from "../admissao-candidato/hooks/admissaoCandidatoRepository";
import { GridRepository } from "src/modules/grids/hooks/GridRepository";
import { Option } from "src/hooks/useAutoComplete";
import RFFAutocomplete from "src/components/Forms/RFFAutocomplete";

type ModalFormData = {
  operacao: string
  coordenador: string
  gridId?: string
  tipoContratacao: string
  dataPrevistaExame: Date
  dataEntregaDocumentos: Date
  dataPrevistaAdmissao: Date
  observacao?: string
}

interface CreateAdmissaoButtonProps {
  candidatoId: string | false
  buttonLabel: string
  className?: string
  dialog?: {
    title?: string
    acceptLabel?: string
    rejectLabel?: string
  }
  onSaveSuccessful?: () => Promise<void>
  disabled?: boolean
}

export const CreateAdmissaoButton = ({
  candidatoId,
  buttonLabel,
  className,
  dialog,
  onSaveSuccessful,
  disabled
}: CreateAdmissaoButtonProps) => {

  const [gridsOptions, setGridsOptions] = useState<Option[]>([])
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const userLogged = useLoggedUser()


  const loadGrids = async () => {
    const response = await GridRepository.fetch({ limit: '999' })

    if (response.ok) {
      setGridsOptions(
        response.data?.edges.map(i => ({
          id: i.node.id,
          name: i.node.nome
        })) ?? []
      )
    }
  }

  useEffect(() => {
    loadGrids()
  }, [])

  const dialogSchema = yup.object().shape({
    operacao: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    coordenador: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    gridId: yup
      .string()
      .nullable()
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    tipoContratacao: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    dataPrevistaExame: yup
      .date()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
    dataEntregaDocumentos: yup
      .date()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
    dataPrevistaAdmissao: yup
      .date()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_HORA_INVALIDA),
    observacao: yup
      .string()
      .nullable()
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  })

  const handleAdmitirCandidato = async (modalData: ModalFormData) => {
    try {
      if (candidatoId && userLogged?.name) {
        const result = await admitirCandidato({
          operacao: modalData.operacao,
          coordenador: modalData.coordenador,
          gridId: modalData.gridId,
          tipoContratacao: modalData.tipoContratacao,
          dataPrevistaExame: modalData.dataPrevistaExame,
          dataEntregaDocumentos: modalData.dataEntregaDocumentos,
          dataPrevistaAdmissao: modalData.dataPrevistaAdmissao,
          observacao: modalData.observacao,
          admitidoPor: userLogged.name,
          candidatoId: candidatoId,
        })

        if (result?.ok && result.info) {
          if (onSaveSuccessful) {
            await onSaveSuccessful()
          }
          snackbarActions.setMessage('Candidato(a) admitido(a) com sucesso')
        } else {
          snackbarActions.setMessage(
            'Ocorreu um erro ao admitir o(a) Candidato(a)'
          )
        }
      } else {
        snackbarActions.setMessage('Não foi possível encontrar o candidato')
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        className={className}
        onClick={() => setOpenDialog(true)}
        disabled={disabled}
      >
        {buttonLabel}
      </Button>

      <ComplexDialog
        open={openDialog}
        title={dialog?.title ?? "Deseja admitir este Candidato(a)?"}
        primaryActionButtonLabel={dialog?.acceptLabel ?? "Admitir"}
        closeActionButtonLabel={dialog?.rejectLabel ?? "Voltar"}
        handleClose={() => setOpenDialog(false)}
        primaryAction={handleAdmitirCandidato}
        validate={yupValidation(dialogSchema)}
        disableBackdropClick={true}
        maxWidth={'lg'}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <Select
              fullWidth
              name="operacao"
              label="Operação"
              items={tiposOperacao}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              name="coordenador"
              label="Coordenador"
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <RFFAutocomplete
              name="gridId"
              label="Grid"
              options={gridsOptions}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Select
              fullWidth
              name="tipoContratacao"
              label="Tipo da Contratação *"
              items={tipoContratacao}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <DatePicker
              fullWidth
              name="dataPrevistaExame"
              label="Data prevista Exame"
              keyboardIcon={
                <Tooltip title="Selecionar data">
                  <EventIcon />
                </Tooltip>
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <DatePicker
              fullWidth
              name="dataEntregaDocumentos"
              label="Data prevista Entrega Documentos"
              keyboardIcon={
                <Tooltip title="Selecionar data">
                  <EventIcon />
                </Tooltip>
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <DatePicker
              fullWidth
              name="dataPrevistaAdmissao"
              label="Data prevista para Admissão"
              keyboardIcon={
                <Tooltip title="Selecionar data">
                  <EventIcon />
                </Tooltip>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextField
              name="observacao"
              label="Observação"
            />
          </Grid>
        </Grid>
      </ComplexDialog>
    </>
  )
}