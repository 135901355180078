import { useSnackbar } from '@elentari/core'
import { Button, Grid, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { FieldArray, FieldArrayRenderProps, Formik, FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import TextInput from 'src/components/Formik/Forms/TextInput'
import { useAppBar } from 'src/hooks'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import * as yup from 'yup'
import { FormWrap, Paper } from '../../../components'
import { MessagesYup } from '../../messages'
import {
  createVagaBeneficios,
  getAllVagaBeneficiosByVagaId
} from '../hooks/vagaBeneficioRepository'
import { VagaBeneficioDetail, BeneficioDetail } from '../types'

interface Option {
  id: string
  name: string
}

interface Params {
  id: string
}

export const VagaBeneficiosForm = () => {
  const [vagaBeneficios, setVagaBeneficios] = useState<VagaBeneficioDetail>()

  const params = useParams<Params>()

  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()
  const userLogged = useLoggedUser()
  const canUpdate = userLogged?.rolesInSystem.includes('GESTOR') || userLogged?.rolesInSystem.includes('ADMIN')

  useEffect(() => {
    appBarActions.setTitle('Benefícios')
    loadVagaBeneficios()
  }, [])

  const loadVagaBeneficios = async () => {
    const response = await getAllVagaBeneficiosByVagaId(params.id)

    if (response?.ok) {
      if (response?.info.length !== 0) {
        setVagaBeneficios({
          vagasId: params.id,
          Beneficios: response.info
        })
      }
    }
  }

  const schemaObject = {
    Beneficios: yup
      .array()
      .of(
        yup.object().shape({
          descricao: yup
            .string()
            .trim()
            .required(MessagesYup.MENSAGEM_OBRIGATORIO)
            .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
        })
      )
      .test('unique-beneficio', function (beneficios: any) {
        const arrDescricoesBeneficios = beneficios.map(
          (beneficio: BeneficioDetail) => beneficio?.descricao?.toUpperCase()
        )

        const numberDiffBeneficios = new Set(arrDescricoesBeneficios).size

        if (beneficios.length !== numberDiffBeneficios) {
          const arrRepetidos: number[] = []
          arrDescricoesBeneficios.filter((descricao: string, index: number) => {
            if (arrDescricoesBeneficios.indexOf(descricao) !== index) {
              arrRepetidos.push(index)
            }

            return arrDescricoesBeneficios.indexOf(descricao) == index
          })

          return this.createError({
            path: `Beneficios[${arrRepetidos[0]}].descricao`,
            message: 'Não pode haver benefícios repetidos'
          })
        } else {
          return true
        }
      })
  }
  const schema = yup.object().shape(schemaObject)

  const handleAddBeneficio = (arrayHelpers: FieldArrayRenderProps) => {
    return () => {
      arrayHelpers.push({ descricao: '', vagasId: '' })
    }
  }

  const handleDeleteBeneficio = (
    index: number,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    return () => {
      arrayHelpers.remove(index)
    }
  }

  const formikState = {
    initialValues: {
      vagasId: '',
      Beneficios: [
        {
          descricao: ''
        }
      ]
    },
    onSubmit: async (data: FormikValues) => {
      try {
        setLoading(true)
        const response = await createVagaBeneficios({
          ...data,
          vagasId: params.id
        } as VagaBeneficioDetail)

        if (!response.ok) {
          snackbarActions.setMessage(response.message)
        } else {
          snackbarActions.setMessage('Salvo com sucesso!')
        }
        setLoading(false)
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={formikState.onSubmit}
      initialValues={vagaBeneficios ?? formikState.initialValues}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <Paper noTopBorderRadius>
          <FormWrap
            handleSubmit={handleSubmit}
            disableSubmit={isSubmitting}
            disableBack={isSubmitting}
            loading={loading}
          >
            <Grid container spacing={2}>
              <FieldArray name={'Beneficios'} validateOnChange={true}>
                {arrayHelpers => (
                  <Grid item xs={12}>
                    {values.Beneficios?.map((beneficio, index) => (
                      <Grid
                        container
                        key={index}
                        style={{
                          paddingBottom: 20,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'start'
                        }}
                      >
                        <Grid item xs={(values.Beneficios.length > 1 && canUpdate) ? 11 : 12}>
                          <TextInput
                            name={`Beneficios[${index}].descricao`}
                            label={`${index + 1}° Benefício`}
                            disabled={!canUpdate}
                          />
                        </Grid>

                        {(values.Beneficios.length > 1 && canUpdate) && (
                          <Grid
                            item
                            xs={1}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <Tooltip
                              placement="top"
                              title={`Deletar ${index + 1}° Benefício`}
                            >
                              <Button
                                style={{
                                  flex: 1,
                                  height: 56,
                                  border: 'none'
                                }}
                                variant="outlined"
                                color="primary"
                                onClick={handleDeleteBeneficio(
                                  index,
                                  arrayHelpers
                                )}
                              >
                                <DeleteOutlineIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    ))}
                    {canUpdate && (
                      <Tooltip
                        placement="right-start"
                        title="Adicionar Benefício"
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          type="button"
                          onClick={handleAddBeneficio(arrayHelpers)}
                        >
                          <AddIcon />
                        </Button>
                      </Tooltip>
                    )}
                  </Grid>
                )}
              </FieldArray>
            </Grid>
          </FormWrap>
        </Paper>
      )}
    </Formik>
  )
}
