import { apiSauceInstance } from "src/services/api"

export const getProcessoById = async (processoSeletivoId: string) => {
  const response = await apiSauceInstance.get<any>(
    `/processo-seletivo-administrativos/${processoSeletivoId}`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }

  return response.data
}
