import {
  IconButton,
  Tooltip,
} from "@material-ui/core"
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

type ShowDetailsIconProps = {
  title?: string
  onClick: () => void
  disabled: boolean
}

export const ShowDetailsIcon = ({
  title,
  onClick,
  disabled
} : ShowDetailsIconProps) => {
  return (
    <Tooltip title={title || 'Consultar registro'}>
      <IconButton
        aria-label="detalhes"
        onClick={onClick}
        disabled={disabled}
      >
        <VisibilityOutlinedIcon />
      </IconButton>
    </Tooltip>
  )
}