import { Grid, makeStyles, Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 600,
    textDecoration: 'underline'
  }
}))

interface CandidatoFieldProps {
  title: string,
  subtitle: string
}

export const CandidatoField = ({
  title,
  subtitle
}: CandidatoFieldProps) => {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label}>
          {subtitle}
        </Typography>
      </Grid>
    </Grid>
  )
}