import LinkMD from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Rating } from '@material-ui/lab'
import QueryString from 'qs'
import { Link, useLocation } from 'react-router-dom'
import { CpfBloqueadoIcon } from 'src/components/Icons/CpfBloqueadoIcon'
import {
  NoResultsTable,
  Paper,
  TableContainer,
  When
} from '../../../components'
import Spacer from '../../../components/Spacer'
import { TableCellHead } from '../../../components/TableCellHead'
import { FormatedDate } from '../../../utils'
import { ModalHistorico } from '../components/ModalHistorico'
import triagemStore from '../store/store'
import { MotoristaDetail } from './types'

interface Props {
  list: MotoristaDetail[]
  loading: boolean
  totalCount: number
  onDelete: (id: string) => void
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const TriagemMotoristaTable = ({
  list,
  loading,
  totalCount,
  authorization
}: Props) => {
  const { search } = useLocation()

  return (
    <>
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Nome Completo" sort="nomeCompleto" />
                  <TableCellHead label="CPF" sort="cpf" />
                  <TableCellHead label="Celular" sort="celular" />
                  <TableCellHead label="Cidade" sort="cidade" />
                  <TableCellHead label="UF" sort="uf" />
                  <TableCellHead
                    label="Classificação"
                    sort="notaClassificacao"
                  />
                  <TableCellHead label="Data do Cadastro" sort="createdAt" />
                  <TableCellHead label="Quantidade que se cadastrou" sort="quantidadeCandidaturas" />
                  <When
                    value={!authorization || authorization.canCreate}
                    equals
                  >
                    <TableCell></TableCell>
                  </When>
                  <TableCellHead label="Histórico" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(motorista => (
                  <TableRow hover key={motorista.id}>
                    <TableCell>
                      {!authorization || authorization.canUpdate ? (
                        <When
                          value={!authorization || authorization.canUpdate}
                          equals
                        >
                          <LinkMD
                            component={Link}
                            to={`/triagem-motorista/${motorista.id}`}
                            onClick={() => {
                              triagemStore.fetchCandidatos(
                                QueryString.stringify({
                                  sort: { createdAt: 'desc' },
                                  incluirBloqueados: 'false',
                                  tipo: 'MOTORISTA',
                                  statusCandidato: 'INICIAL',
                                  limit: 50,
                                  ...QueryString.parse(search.substring(1))
                                }),
                                motorista.id
                              )
                            }}
                          >
                            {motorista.nomeCompleto}
                          </LinkMD>
                        </When>
                      ) : (
                        motorista.id
                      )}
                    </TableCell>
                    <TableCell>{motorista.cpf}</TableCell>
                    <TableCell>{motorista.celular}</TableCell>
                    <TableCell>{motorista.cidade}</TableCell>
                    <TableCell>{motorista.uf}</TableCell>
                    <TableCell>
                      <Rating
                        max={3}
                        value={motorista.notaClassificacao}
                        disabled
                      />
                    </TableCell>
                    <TableCell>
                      {motorista.createdAt && FormatedDate(motorista.createdAt)}
                    </TableCell>
                    <TableCell>{motorista.quantidadeCandidaturas}</TableCell>
                    <When value={authorization?.canCreate} equals>
                      <TableCell>
                        {motorista.idListaBloqueio && (
                          <CpfBloqueadoIcon
                            idListaBloqueio={motorista.idListaBloqueio}
                          />
                        )}
                      </TableCell>
                    </When>
                    <TableCell>
                      <ModalHistorico
                        candidatoId={motorista.id}
                        candidatoType="MOTORISTA"
                        triagens={motorista.Triagem}
                        disabled={loading}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
    </>
  )
}
