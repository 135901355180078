import { Content, TableCell } from "pdfmake/interfaces"
import { FormatedDate, tipoEntrevista } from "src/utils"
import { AgendamentoAdministrativo, ProcessoSeletivoAdministrativo } from "./processoAdministrativoTypes"

export const getProcessoAdministrativoData = (processo: ProcessoSeletivoAdministrativo): Content => {
  return [
    {
      text: 'Dados do Processo Seletivo',
      margin: [10, 10],
      alignment: 'center',
      style: 'dataTitle'
    },
    processo.criadoPor ? {
      columns: [
        {
          text: [
            {
                text: 'Criado por: ',
                style: 'dataLabel'
            },
            `${processo.criadoPor}`
          ],
        },
        {
          text: [
            {
                text: 'Cargo: ',
                style: 'dataLabel'
            },
            `${processo.vagasFK?.cargoFk.nome}`
          ],
        },
      ],
      margin: [30, 3]
    } : {
      text: [
        {
            text: 'Cargo: ',
            style: 'dataLabel'
        },
        `${processo.vagasFK?.cargoFk.nome}`
      ],
      margin: [30, 3]
    },
    {
      columns: [
        {
          text: [
            {
                text: 'Área: ',
                style: 'dataLabel'
            },
            `${processo.vagasFK?.area}`
          ],
        },
        
        {
          text: [
            {
                text: 'Categoria: ',
                style: 'dataLabel'
            },
            `${processo.vagasFK?.categoria}`
          ],
        },
      ],
      margin: [30, 3]
    },
    {
      columns: [
        processo.createdAt ? {
          text: [
            {
                text: 'Data de Início: ',
                style: 'dataLabel'
            },
            `${processo.createdAt && FormatedDate(processo.createdAt.toString())}`
          ],
        } : '',
        processo.dataEncerramento ? {
          text: [
            {
                text: 'Data de Encerramento: ',
                style: 'dataLabel'
            },
            `${processo.dataEncerramento && FormatedDate(processo.dataEncerramento)}`
          ],
        } : ''
      ],
      margin: [30, 3]
    },
  ]
}

export const getAgendamentoAdministrativoData = (agendamentos?: AgendamentoAdministrativo[]): Content => {
  if(agendamentos && agendamentos.length > 0) {
    return [
      {
        text: 'Agendamentos',
        margin: [10, 10],
        alignment: 'center',
        style: 'dataTitle'
      },
      {
        text: [
          {
            text: 'Quantidade de Agendamentos: ',
            style: 'dataLabel'
          },
          `${agendamentos.length}`
        ],
        margin: [30, 3]
      },
      {
        table: {
          body: [
            [
              {
                text: 'Data Candidato Agendado',
                style: 'tableHeader'
              },
              {
                text: 'Tipo Entrevista',
                style: 'tableHeader'
              },
              {
                text: 'Reagendamento',
                style: 'tableHeader'
              },
              {
                text: 'Compareceu',
                style: 'tableHeader'
              },
              {
                text: 'Agendado Por',
                style: 'tableHeader'
              }
            ],
            ...agendamentos.map((agendamento): TableCell[] => {
              return [
                {
                  text: FormatedDate(agendamento.dataCandidatoAgendado.toString()),
                  style: 'tableData'
                },
                {
                  text: `${tipoEntrevista.find(
                    value => value.value === agendamento.tipoEntrevista
                  )?.label}`,
                  style: 'tableData'
                },
                {
                  text: agendamento.reagendamento ? 'Sim' : 'Não',
                  style: 'tableData'
                },
                {
                  text: agendamento.compareceu ? 'Sim' : 'Não',
                  style: 'tableData'
                },
                {
                  text: agendamento.agendadoPor || '',
                  style: 'tableData'
                },
              ]
            })
          ]
        },
        margin: [25, 0]
      }
    ]
  } else {
    return [
      {
        text: 'Não foi realizado nenhum Agendamento',
        margin: [0, 10],
        alignment: 'center',
        style: 'dataLabel'
      },
    ]
  }
}