import { apiSauceInstance } from 'src/services/api'
import { TesteAdministrativoDetail } from '../types'

export const createTestes = async (data: TesteAdministrativoDetail) => {
  const response = await apiSauceInstance.post<any>(
    `/teste-processo-administrativos/create-many`,
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }

  return response.data
}

export const getAllTestesByProcessoId = async (processoId: string) => {
  const response = await apiSauceInstance.get<any>(
    `/teste-processo-administrativos/processo-seletivo/${processoId}`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}
