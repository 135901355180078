import { InputLabelProps, TextField } from '@material-ui/core'
import { useField } from 'formik'
import { KeyboardEventHandler } from 'react'

interface TextInputProps {
  id?: string
  multiline?: boolean
  rows?: number
  name: string
  label: string
  disabled?: boolean
  defaultValue?: string
  value?: string
  placeholder?: string
  InputLabelProps?: Partial<InputLabelProps>
  inputProps?: any
  helperTextCustom?: string
  type?: string
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>
  onChange?: (event: any) => void
}

function TextInput(props: TextInputProps) {
  const [field, meta] = useField(props)

  return (
    <TextField
      variant="outlined"
      fullWidth
      {...field}
      {...props}
      placeholder={props.placeholder}
      error={meta.touched && Boolean(meta.error)}
      helperText={(meta.touched && meta.error) || props.helperTextCustom}
    />
  )
}

export default TextInput
