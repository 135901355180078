import Filter from "@elentari/components-eve/components/Filter";
import DatePicker from '@elentari/components-eve/final-form/DatePicker';
import { useQueryParams } from "@elentari/core";
import { Grid, Tooltip } from "@material-ui/core";
import EventIcon from '@material-ui/icons/Event';
import QueryString from "qs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Spacer from "src/components/Spacer";
import yupValidation from "src/utils/yupValidation";
import * as yup from 'yup';
import { MessagesYup } from "../messages";
import { BarChart } from "./components/BarChart";
import ChartCard from "./components/ChartCard";
import { DonutChart } from "./components/DonutChart";
import InfoCard from "./components/InfoCard";
import { getCandidatosInfo, getGridsInfo, getProcessosAdministrativoInfo, getVagasInfo } from "./hooks/dashboardRepository";
import { CandidatoInfo, DashboardSubmitFilter, GridInfo, ProcessoAdministrativoInfo, VagasInfo } from "./types";

export default function Dashboard() {
  const [loading, setLoading] = useState<boolean>(false)
  const [vagasInfo, setVagasInfo] = useState<VagasInfo>()
  const [processosAdministrativoInfo, setProcessosAdministrativoInfo] = useState<ProcessoAdministrativoInfo>()
  const [candidatosInfo, setCandidatosInfo] = useState<CandidatoInfo>()
  const [gridsInfo, setGridsInfo] = useState<GridInfo>()

  const { search, pathname } = useLocation()

  const params: { [key: string]: any } = QueryString.parse(search.substring(1))

  async function fetchVagasInfo() {
    const response = await getVagasInfo(params)

    if (response && response.ok) {
      return response.info
    }
  }
  async function fetchProcessosAdministrativoInfo() {
    const response = await getProcessosAdministrativoInfo(params)

    if (response && response.ok) {
      return response.info
    }
  }
  async function fetchCandidatosInfo() {
    const response = await getCandidatosInfo(params)

    if (response && response.ok) {
      return response.info
    }
  }
  async function fetchGridsInfo() {
    const response = await getGridsInfo(params)

    if (response && response.ok) {
      return response.info
    }
  }

  useEffect(() => {
    async function handleAllFetchs() {
      setLoading(true)
      const [
        responseVagas,
        responseProcessosAdministrativos,
        responseCandidatosInfo,
        responseGridsInfo
      ] = await Promise.all([
        fetchVagasInfo(),
        fetchProcessosAdministrativoInfo(),
        fetchCandidatosInfo(),
        fetchGridsInfo()
      ])
      setVagasInfo(responseVagas)
      setProcessosAdministrativoInfo(responseProcessosAdministrativos)
      setCandidatosInfo(responseCandidatosInfo)
      setGridsInfo(responseGridsInfo)

      setLoading(false)
    }
    handleAllFetchs()
  }, [pathname, search])

  return (
    <>
      <DashboardFilters />
      <Spacer y={2} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <InfoCard
            title="Candidatos Motoristas"
            info={candidatosInfo?.candidatoMotorista.toString() || ''}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InfoCard
            title="Candidatos Administrativos"
            info={candidatosInfo?.candidatoAdministrativo.toString() || ''}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InfoCard
            title="Vagas em Andamento"
            info={vagasInfo ?
              (vagasInfo.statusEmDivulgacao +
                vagasInfo.statusEmEntrevista +
                vagasInfo.statusEmTriagemCurriculos).toString()
              : ''
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InfoCard
            title="Vagas Concluídas"
            info={vagasInfo?.statusConcluida.toString() || ''}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <ChartCard>
            <DonutChart
              info={processosAdministrativoInfo}
              title={'Quantidade de Processos Seletivos Administrativos por Status'}
            />
          </ChartCard>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <ChartCard>
            <DonutChart
              info={vagasInfo}
              title={'Quantidade de Vagas por Status'}
            />
          </ChartCard>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <ChartCard>
            <DonutChart
              info={candidatosInfo}
              title={'Quantidade de Candidatos por Origem do Currículo'}
            />
          </ChartCard>
        </Grid>
        <Grid item xs={12}>
          <ChartCard>
            <BarChart
              info={gridsInfo}
              title={'Visão Geral das Grids'}
            />
          </ChartCard>
        </Grid>
      </Grid>
    </>
  )
}

type DashboardQueryFilter = {
  [key: string]: any
}
const schema = yup.object().shape({
  dataCriacaoStart: yup
    .date()
    .max(new Date(Date.now() + 1), MessagesYup.MENSAGEM_DATA_MAX_ATUAL)
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  dataCriacaoEnd: yup
    .date()
    .max(new Date(Date.now() + 1), MessagesYup.MENSAGEM_DATA_MAX_ATUAL)
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .test({
      name: 'data-criacao-valid',
      message: "A data informada é inferior a 'Data Início de'",
      test: (dataCriacaoEnd, context) => {
        if (!dataCriacaoEnd || !context.parent.dataCriacaoStart) {
          return true
        }
        return dataCriacaoEnd >= context.parent.dataCriacaoStart
      }
    }),
})

const DashboardFilters = () => {
  const { clear, push, initialValues } =
    useQueryParams<DashboardQueryFilter>()

  const handleSubmitFilter = (values: DashboardSubmitFilter) => {
    if (values.dataCriacaoStart) {
      values.dataCriacaoStart = new Date(values.dataCriacaoStart).toISOString()
    }
    if (values.dataCriacaoEnd) {
      values.dataCriacaoEnd = new Date(values.dataCriacaoEnd).toISOString()
    }

    push(values)
  }

  const format = (initialValues: DashboardQueryFilter) => {
    return initialValues
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter(values)}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            fullWidth
            name="dataCriacaoStart"
            label="Data Início de"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            fullWidth
            name="dataCriacaoEnd"
            label="Data Início até"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
      </Grid>
    </Filter>
  )
}