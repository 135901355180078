import { isAfter } from 'date-fns'
import { MessagesYup } from 'src/modules/messages'
import { validateCPF } from '../../validators/cpf'
import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'

const candidatoSchemaProperties = {
  nomeCompleto: Yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  cpf: Yup.string()
    .min(14, MessagesYup.MENSAGEM_CPF_LIMTADA)
    .test('cpf-validation', MessagesYup.MESSAGEM_VALOR_CPF_INVALIDO, value => {
      if (!value) return true
      return validateCPF(value)
    }),
  dataNascimento: Yup.date()
    .nullable()
    .max(new Date(Date.now() + 1), MessagesYup.MENSAGEM_DATA_MAX_ATUAL)
    .transform((curr, orig) => (orig === '' ? null : curr))
    .test({
      name: 'data-de-nascimento-validation',
      message: MessagesYup.MENSAGEM_DATA_INVALIDA,
      test: (dataNascimento, context) => {
        if (dataNascimento) {
          return new Date(dataNascimento).getFullYear() >= 1900
        }
        return true
      }
    })
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  email: Yup.string().email(MessagesYup.MENSAGEM_EMAIL).nullable(),
  cidade: Yup.string()
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  uf: Yup.string().typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  telefone: Yup.string().nullable(),
  celular: Yup.string(),
  Cursos: Yup.array().of(
    Yup.object().shape({
      descricao: Yup.string().trim().nullable(),
      cargaHoraria: Yup.number().when('descricao', {
        is: (descricao: string) => descricao !== undefined,
        then: Yup.number()
          .min(0, MessagesYup.MENSAGEM_VALOR_NEGATIVO)
          .nullable()
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      }),
      dataInicio: Yup.date()
        .nullable()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .when('descricao', {
          is: (descricao: string) => descricao !== undefined,
          then: Yup.date()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .required(MessagesYup.MENSAGEM_OBRIGATORIO)
            .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
        })
        .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
      dataConclusao: Yup.date()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .when('descricao', {
          is: (descricao: string) => descricao !== undefined,
          then: Yup.date()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .required(MessagesYup.MENSAGEM_OBRIGATORIO)
            .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
            .test({
              name: 'data-conclusao-maior-data-inicio',
              message: 'A data de conclusão não pode ser menor que a data de início.',
              test: (dataConclusao, context) => {
                if (dataConclusao) {
                  return isAfter(dataConclusao, context.parent.dataInicio)
                }
                return true
              }
            })
        })
        .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    })
  )
}

export const candidatoSchemaPropertiesRequired: ObjectShape = {
  ...candidatoSchemaProperties,
  cpf: candidatoSchemaProperties.cpf.required(MessagesYup.MENSAGEM_OBRIGATORIO).typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  dataNascimento: candidatoSchemaProperties.dataNascimento.required(MessagesYup.MENSAGEM_OBRIGATORIO).typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  cidade: candidatoSchemaProperties.cidade.required(MessagesYup.MENSAGEM_OBRIGATORIO).typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  uf: candidatoSchemaProperties.uf.required(MessagesYup.MENSAGEM_OBRIGATORIO).typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  celular: candidatoSchemaProperties.celular.required(MessagesYup.MENSAGEM_OBRIGATORIO).typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
}

export const candidatoSchemaPropertiesOptional: ObjectShape = {
  ...candidatoSchemaProperties,
  cpf: candidatoSchemaProperties.cpf.nullable(),
  dataNascimento: candidatoSchemaProperties.dataNascimento.nullable(),
  cidade: candidatoSchemaProperties.cidade.nullable(),
  uf: candidatoSchemaProperties.uf.nullable(),
  celular: candidatoSchemaProperties.celular.nullable(),
}