import { useField } from 'formik'
import { CurrencyComponent } from 'src/components/CurrencyComponent'

interface CurrencyInputProps {
  id?: string
  name: string
  label: string
  disabled?: boolean
  value?: string
  startAdornment?: React.ReactNode
  onBlur?(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void
}

function CurrencyInput(props: CurrencyInputProps) {
  const [field, meta, helper] = useField(props)

  return (
    <CurrencyComponent
      thousandSeparator="."
      decimalSeparator=","
      precision="2"
      variant="outlined"
      color="primary"
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched ? meta.error : undefined}
      {...field}
      {...props}
      onChange={number => {
        helper.setValue(number)
      }}
    />
  )
}

export default CurrencyInput