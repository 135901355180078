import { useSnackbar } from '@elentari/core'
import { useFormikContext } from 'formik'
import { ChangeEvent } from 'react'
import { RadioGroupInput } from 'src/components/Formik/Forms/RadioGroupInput'
import { updateSelecaoCandidato } from '../../hooks/selecaoCandidatoRepository'
import { SelecaoCandidatoDetail } from '../../types'
import { compareceuOptions } from '../types'

interface CompareceuInputProps {
  name: string
  label: string
}

export const CompareceuInput = ({
  name,
  label
}: CompareceuInputProps) => {

  const { values, setFieldValue } = useFormikContext<SelecaoCandidatoDetail>()
  const [, snackbarActions] = useSnackbar()

  const handleOnChangeCompareceu = async (event: ChangeEvent<HTMLInputElement>, value: string) => {
    const eventValue = value === 'true'

    if (values?.id) {
      const response = await updateSelecaoCandidato(values.id, {
        compareceu: eventValue
      })

      if (response && response.ok) {
        setFieldValue(name, eventValue)
      } else {
        snackbarActions.setMessage(
          `Ocorreu um erro ao alterar o campo compareceu do(a) Candidato(a)`
        )
      }
    } else {
      snackbarActions.setMessage(
        `O Candidato(a) não possui nenhuma Seleção Cadastrada`
      )
    }
  }

  return (
    <RadioGroupInput
      name={name}
      label={label}
      options={compareceuOptions}
      type="boolean"
      labelVariant="h6"
      onChange={handleOnChangeCompareceu}
    />
  )
}