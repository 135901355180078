import Checkbox from '@elentari/components-eve/final-form/Checkbox'
import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import ComplexDialog from '../../../components/ComplexDialog'
import { useSnackbar } from '@elentari/core'
import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { FormLabel, Grid, Tooltip, makeStyles } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import EventIcon from '@material-ui/icons/Event'
import { styled } from '@material-ui/styles'
import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useLocation } from 'react-router'
import RichTextField from 'src/components/Forms/RichTextField'
import { useAppBar } from 'src/hooks'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import { tipoEntrevista, searchTipoEntrevista } from 'src/utils'
import { setTimeout } from 'timers'
import * as yup from 'yup'
import { FormWrap, Paper } from '../../../components'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import {
  AgendamentoRepository,
  enviarEmailGestor
} from '../hooks/AgendamentoRepository'
import { useAgendamento } from '../hooks/useAgendamento'
import { AgendamentoFormData, AgendamentoColors } from '../types'

const CheckBoxContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    color: '#ff9000',
    margin: '5px'
  }
})

const useStyles = makeStyles({
  desbloquear: {
    backgroundColor: AgendamentoColors.EMAIL_GESTOR,
    '&:hover': {
      color: 'white',
      backgroundColor: AgendamentoColors.EMAIL_GESTOR_DARK
    }
  }
})

const TempoModal = 100

const Operacao = {
  enviarEmailGestor: 'Enviar email gestor.'
}

export const AgendamentoAdministrativoForm = () => {
  const location = useLocation()
  const [AgendamentoState, AgendamentoActions] = useAgendamento()
  const [initialValues, setInitialValues] = useState({})
  const [processoId, setProcessoId] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    callback: (data: any) =>
      new Promise<{
        info: any
        ok: boolean
        status: number | undefined
      } | null>((resolve, reject) => {
        resolve({
          info: {},
          ok: false,
          status: 0
        })
        reject(null)
      }),
    id: '',
    mensagem: '',
    operacao: ''
  })
  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()
  const userLogged = useLoggedUser()
  const isNewAgendamento = location.pathname.includes('/new')
  const classes = useStyles()

  const { save } = useEntitySaver<AgendamentoFormData>(async data => {
    const response = await AgendamentoRepository.save(data)
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return response
  })

  useEffect(() => {
    if (location.pathname.split('/')[2] !== undefined) {
      setProcessoId(location.pathname.split('/')[2])
    }
    if (isNewAgendamento) {
      setInitialValues({
        dataCandidatoAgendado: new Date(),
        agendadoPor: userLogged?.name
      })
    }
  }, [location])

  useEffect(() => {
    if (AgendamentoState.tag === 'with-data') {
      setInitialValues(AgendamentoState.entity)
    }
  }, [AgendamentoState.tag])

  const handleSubmit = async (data: any) => {
    try {
      setLoading(true)
      data.agendadoPor = userLogged?.name
      data.processoSeletivoAdministrativoId = processoId
      if (!data.compareceu) {
        data.compareceu = false
      }
      if (!data.reagendamento) {
        data.reagendamento = false
      }

      await save(data)
      if (data.id) {
        await AgendamentoActions.fetch(data.id)

        appBarActions.setTitle(searchTipoEntrevista(data.tipoEntrevista))
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  const closeDialog = () => {
    //Modal começa a fechar
    setConfirmationDialog({
      ...confirmationDialog,
      open: false
    })

    //Após o TempoModal, os campos confirmationDialog se tornam vazios
    setTimeout(() => {
      setConfirmationDialog({
        mensagem: '',
        operacao: '',
        open: false,
        callback: () => new Promise(() => {}),
        id: ''
      })
    }, TempoModal) //TempoModal é o tempo que demora para o Modal fechar/abrir por completo
  }

  const handleConfirmationDialog = async (data: any) => {
    const { id, callback } = confirmationDialog
    if (callback) {
      const response = await callback(id)
      if (response?.ok) {
        snackbarActions.setMessage('Email enviado com sucesso.')
      } else {
        snackbarActions.setMessage('Ocorreu um erro ao enviar o email.')
      }
    }
  }

  const handleActionsClick = (
    id: string,
    callback: (
      data: any
    ) => Promise<{ info: any; ok: boolean; status: number | undefined } | null>,
    operacao: string
  ) => {
    setConfirmationDialog({
      open: true,
      callback,
      id,
      mensagem: `A seguinte operação será executada:`,
      operacao: operacao
    })
  }

  const customActionsFactory = () => {
    const actions = []
    if (AgendamentoState.tag === 'with-data' && AgendamentoState.entity) {
      const desbloquearButton = {
        label: 'Email Gestor',
        disabled: confirmationDialog.open,
        classes: classes.desbloquear,
        onClick: () =>
          handleActionsClick(
            AgendamentoState.entity.id,
            enviarEmailGestor,
            Operacao.enviarEmailGestor
          )
      }
      actions.push(desbloquearButton)
    }
    return actions
  }

  const customActions = customActionsFactory()

  const schemaObject = {
    comentario: yup
      .string()
      .nullable()
      .typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
    dataCandidatoAgendado: yup
      .date()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
    tipoEntrevista: yup
      .string()
      .nullable()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_CAMPO_STRING)
  }
  const schema = yup.object().shape(schemaObject)

  return (
    <>
      <Form
        validate={yupValidation(schema)}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting, values }) => (
          <Paper>
            <FormWrap
              handleSubmit={handleSubmit}
              disableSubmit={submitting}
              disableBack={submitting}
              loading={loading}
              customActions={customActions}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="agendadoPor"
                    label="Agendado por"
                    value={userLogged?.name}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    fullWidth
                    name="dataCandidatoAgendado"
                    label="Data em que o Candidato(a) foi Agendado(a)"
                    keyboardIcon={
                      <Tooltip title="Selecionar data">
                        <EventIcon />
                      </Tooltip>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    name="tipoEntrevista"
                    label="Tipo da Entrevista"
                    items={tipoEntrevista}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormLabel>
                    <Checkbox name="reagendamento" color="primary" />
                    Reagendamento
                  </FormLabel>
                </Grid>

                <Grid item xs={3}>
                  <CheckBoxContainer>
                    <FormLabel>
                      <Checkbox name="compareceu" color="primary" />
                      Compareceu
                    </FormLabel>
                  </CheckBoxContainer>
                </Grid>
                <Grid item xs={12}>
                  <RichTextField name="comentario" label="Comentário" />
                </Grid>
              </Grid>
            </FormWrap>
          </Paper>
        )}
      </Form>
      <ComplexDialog
        open={!!confirmationDialog.open}
        disableBackdropClick={true}
        closeActionButtonLabel="Não"
        handleClose={closeDialog}
        primaryAction={handleConfirmationDialog}
        title="Deseja confirmar essa operação?"
        message={confirmationDialog.mensagem}
        action={confirmationDialog.operacao}
        primaryActionButtonLabel="Confirmar"
        transitionDuration={TempoModal}
      >
        <></>
      </ComplexDialog>
    </>
  )
}
