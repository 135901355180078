import { Button, Grid, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { makeStyles } from '@material-ui/styles'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import AutoCompleteInput from 'src/components/Formik/Forms/AutoCompleteInput'
import SelectInput from 'src/components/Formik/Forms/SelectInput'
import { Option } from 'src/hooks/useAutoComplete'
import { apiSauceInstance } from 'src/services/api'
import { tipoDepartamento } from 'src/utils'

interface Props {
  label: string
  sigla_empresa: string
}

const useStyles = makeStyles({
  bottomSpace: {
    padding: '10px 0'
  },
  interesseContainer: {
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'start'
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start'
  }
})

export default function AdministrativoCurriculoStep({ label, sigla_empresa }: Props) {
  const [interessesOptions, setInteresseOptions] = useState<Option[]>([])

  const { values, setFieldValue } =
    useFormikContext<{ AreaInteresse: string[]; origemCurriculo: string }>()

  const classes = useStyles()

  const loadInteresses = async () => {
    const response = await apiSauceInstance.get(
      '/interesse/external-interesses',
      {
        sigla_empresa: sigla_empresa
      }
    )

    if (response.ok) {
      setInteresseOptions(
        (response.data as any).edges.map((i: any) => ({
          id: i.node.id,
          name: i.node.descricao
        })) ?? []
      )
    }
  }

  useEffect(() => {
    loadInteresses()
  }, [])

  const handleAddInteresse = () => {
    setFieldValue('AreaInteresse', [
      ...values.AreaInteresse,
      {
        id: ''
      }
    ])
  }

  const handleRemoveInteresse = (index: number) => {
    const interesses = [...values.AreaInteresse]
    if (interesses.length < 2) {
      return
    }
    interesses.splice(index, 1)
    setFieldValue('AreaInteresse', [...interesses])
  }

  return (
    <Grid container spacing={2} className={classes.bottomSpace}>
      <Grid item xs={12}>
        <Typography className={classes.bottomSpace} variant="h5" align="center">
          {label}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          style={{ fontWeight: 'bold', marginBottom: 10 }}
          component="h6"
        >
          Departamento:
        </Typography>

        <SelectInput
          name="departamento"
          label="Departamento"
          options={tipoDepartamento}
          firstOptionDisabled
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          style={{ fontWeight: 'bold', marginBottom: 10 }}
          component="h6"
        >
          Interesses:
        </Typography>
        {values.AreaInteresse.map((interesse, index) => (
          <Grid container key={index} className={classes.interesseContainer}>
            <Grid item xs={values.AreaInteresse.length > 2 ? 11 : 12}>
              <AutoCompleteInput
                name={`AreaInteresse.${index}.id`}
                label={` ${index + 1}° Interesse`}
                options={interessesOptions}
              />
            </Grid>
            {values.AreaInteresse.length > 2 && (
              <Grid item className={classes.buttonGrid} xs={1}>
                <Tooltip
                  placement="top"
                  title={`Deletar ${index + 1}° Interesse`}
                >
                  <Button
                    style={{ flex: 1, height: 56, border: 'none' }}
                    variant="outlined"
                    color="primary"
                    onClick={() => handleRemoveInteresse(index)}
                  >
                    <DeleteOutlineIcon />
                  </Button>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        ))}
        <Tooltip placement="right-start" title="Adicionar Interesse">
          <Button
            variant="outlined"
            color="primary"
            type="button"
            onClick={handleAddInteresse}
            startIcon={<AddIcon />}
          >
            Adicionar Interesses
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  )
}
