import { Link } from 'react-router-dom'
import * as dateFns from 'date-fns'

import * as yup from 'yup'
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Grid
} from '@material-ui/core'
import LinkMD from '@material-ui/core/Link'
import { useQueryParams, useSnackbar } from '@elentari/core'
import { useGridsList } from './hooks/useGrids'
import { GridFormData, SubmitValues } from './types'
import { GridRepository } from './hooks/GridRepository'

import { TableCellHead } from '../../components/TableCellHead'
import Spacer from '../../components/Spacer'
import Filter from '@elentari/components-eve/components/Filter'
import {
  AddRecord,
  DeleteRecord,
  Paper,
  TableContainer,
  NoResultsTable,
  When
} from '../../components'
import { useAbility } from '../login'
import { getAuthorizations } from '../../utils/crudAuthorization'
import { HandleErrorPage } from '../../utils/handleErrorPage'
import yupValidation from '../../utils/yupValidation'
import { MessagesYup } from '../messages'
import TextField from '@elentari/components-eve/final-form/TextField'
import Select from '@elentari/components-eve/final-form/Select'
import { tiposOperacao } from 'src/utils'

interface Props {
  list: GridFormData[]
  loading: boolean
  totalCount: number
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

const schema = yup.object().shape({
  name: yup.string().typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  email: yup.string().typeError(MessagesYup.MENSAGEM_CAMPO_STRING)
})

export const GridsTable = ({
  list,
  loading,
  totalCount,
  onDelete,
  authorization
}: Props) => {
  return (
    <>
      <GridFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Nome" sort="nome" />
                  <TableCellHead label="Operação" sort="operacao" />
                  <TableCellHead label="Coordenador" sort="coordenador" />
                  <TableCellHead label="Veículos" sort="veiculos" />
                  <TableCellHead
                    label="Quant. Titulares Hoje"
                    sort="totalMotoristasAtual"
                  />
                  <TableCellHead
                    label="Quant. Feristas Ideal"
                    sort="feristasQtdIdeal"
                  />
                  <TableCellHead
                    label="Quant. Feristas Hoje"
                    sort="totalFeristasAtual"
                  />

                  <When
                    value={!authorization || authorization.canDelete}
                    equals
                  >
                    <TableCell></TableCell>
                  </When>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(grid => (
                  <TableRow hover key={grid.id}>
                    <TableCell>
                      {!authorization || authorization.canUpdate ? (
                        <When
                          value={!authorization || authorization.canUpdate}
                          equals
                        >
                          <LinkMD component={Link} to={`/grids/${grid.id}`}>
                            {grid.nome}
                          </LinkMD>
                        </When>
                      ) : (
                        grid.nome
                      )}
                    </TableCell>
                    <TableCell>{grid.operacao}</TableCell>
                    <TableCell>{grid.coordenador}</TableCell>
                    <TableCell>{grid.veiculos}</TableCell>
                    <TableCell>{grid.totalMotoristasAtual}</TableCell>
                    <TableCell>{grid.feristasQtdIdeal}</TableCell>
                    <TableCell>{grid.totalFeristasAtual}</TableCell>
                    <When value={authorization?.canDelete} equals>
                      <DeleteRecord onDelete={() => onDelete(grid.id)} />
                    </When>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="grids" authorization={authorization} />
    </>
  )
}

type GridQueryFilter = {
  createdAt?: { gt: string }
  [key: string]: any
}

const GridFilter = () => {
  const { clear, push, initialValues } = useQueryParams<GridQueryFilter>()

  const handleSubmitFilter = (values: SubmitValues) => {
    if (values.operacao === null) {
      delete values.operacao
    }
    if (values.createdAt) {
      const dateFormated = dateFns.parse(
        String(values.createdAt),
        'dd/MM/yyyy',
        new Date()
      )

      return push({
        ...values,
        createdAt: {
          gt: dateFormated.toISOString()
        }
      })
    }
    push({
      ...values,
      createdAt: undefined
    })
  }

  const format = (initialValues: GridQueryFilter) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues,
        createdAt: dateFns.format(
          dateFns.parseISO(initialValues.createdAt.gt),
          'dd/MM/yyyy'
        )
      }
    }
    return initialValues
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter(values)}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField fullWidth name="nome" label="Nome" />
        </Grid>
        <Grid item xs>
          <Select
            fullWidth
            name="operacao"
            label="Operação"
            items={tiposOperacao}
          />
        </Grid>
        <Grid item xs>
          <TextField fullWidth name="coordenador" label="Coordenador" />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Grids = () => {
  const [state, actions] = useGridsList()
  const ability = useAbility()
  const [, snackbarActions] = useSnackbar()


  async function handleDelete(id: string) {
    const response = await GridRepository.delete(id)
    actions.fetchPage()

    if (response.ok) {
      snackbarActions.setSnackBar({
        message: 'Deletado com sucesso',
        severity: 'success'
      })
      actions.fetchPage()
      return
    }
    const message = (response.data as unknown as any).message
    snackbarActions.setSnackBar({
      message: message as string,
      severity: 'warning'
    })
  }

  const authorization = getAuthorizations(ability, 'grids')

  switch (state.tag) {
    case 'empty':
      return (
        <GridsTable
          onDelete={() => { }}
          totalCount={0}
          list={[]}
          loading={true}
        />
      )
    case 'with-data':
      return (
        <GridsTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onDelete={handleDelete}
          authorization={authorization}
        />
      )
    case 'error':
      return <HandleErrorPage state={state.data} />
  }
}

export default Grids
