import { makeStyles } from '@material-ui/core'
import { DropzoneArea } from 'material-ui-dropzone'

interface Props {
  onSelectFile: (files: File[]) => void
  onRemoveFile: () => void
  error: boolean
}

const useStyles = makeStyles(themes => ({
  backdrop: {
    zIndex: themes.zIndex.drawer + 1,
    color: themes.palette.primary.main
  },
  dropzone: {
    '& .MuiDropzonePreviewList-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > .MuiDropzonePreviewList-imageContainer': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
      }
    },
    '& .MuiDropzoneArea-root > .MuiDropzoneArea-textContainer': {
      opacity: '0.6 !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: 40
    },
    '& .MuiDialogActions-root': {
      display: 'flex',
      alignItems: 'center',
      '& button': {
        width: '100px',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        color: '#3A3A3A'
      },
      '& button:nth-child(2)': {
        width: '100px',
        color: '#7b7b7b'
      },
      '& button:nth-child(2):not([disabled])': {
        width: '100px',
        color: '#fff',
        backgroundColor: '#632336'
      }
    }
  },
  dropzoneError: {
    color: themes.palette.error.main,
    borderColor: themes.palette.error.main
  }
}))

export const DropzoneAreaAnexo = ({
  onSelectFile,
  onRemoveFile,
  error
}: Props) => {
  const classes = useStyles()
  return (
    <DropzoneArea
      filesLimit={1}
      alertSnackbarProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      }}
      dropzoneClass={error ? classes.dropzoneError : classes.dropzone}
      onChange={file => {
        if (file.length > 0) onSelectFile(file)
      }}
      onDelete={file => onRemoveFile()}
      dropzoneText="Arraste o documento aqui"
      showFileNames={true}
      showPreviews={false}
      acceptedFiles={['.pdf', '.png', '.jpg', '.jpeg']}
      showPreviewsInDropzone={true}
      showAlerts={['success', 'info', 'error']}
      getFileRemovedMessage={filename => {
        return `Arquivo: ${filename} Removido!`
      }}
      getFileAddedMessage={filename => {
        return `Arquivo: ${filename} Adicionado.`
      }}
      getDropRejectMessage={(filename, accept, size) => {
        const filesAccepted = accept.map(n => n)

        return `Arquivo: ${
          filename.name
        } rejeitado, somente arquivos [${filesAccepted}] aceitos com limite de tamanho de ${
          size / 1000 / 1000
        }mb`
      }}
      getFileLimitExceedMessage={numberLimit => {
        return `Quantidade de arquivos é ${numberLimit}, não foi possível adicionar devido o limite de upload.`
      }}
    />
  )
}
