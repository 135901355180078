import { Tooltip } from '@material-ui/core'
import AccessTime from '@material-ui/icons/AccessTime'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { useField } from 'react-final-form'

interface TimeFieldProps {
  name: string
  label: string
  disabled?: any
}

function TimeField(props: TimeFieldProps) {
  const { input, meta } = useField(props.name)
  const { label } = props

  function handleChange(date: any) {
    input.onChange(date)
  }

  return (
    <KeyboardTimePicker
      {...props}
      ampm={false}
      value={input.value ? new Date(input.value) : null}
      onChange={handleChange}
      inputVariant="outlined"
      label={label}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      keyboardIcon={
        <Tooltip title="Selecionar hora">
          <AccessTime />
        </Tooltip>
      }
      fullWidth
      cancelLabel="Cancelar"
    />
  )
}

export default TimeField
