import { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'

import SimpleBreadcrumb from './SimpleBreadcrumb'
import MenuItems, { Item } from './MenuItems'
import UserAvatar from './UserAvatar'

import styles from './AppWrapStyles'
import clsx from 'clsx'
import useAppBar from '../../hooks/useAppBar'
import When from '../When'

// review later
const useStyles = makeStyles(styles as any)

const enumMenu = {
  OPEN: 'open',
  CLOSE: 'close'
}

interface AppWrapProps {
  children: any
  logo: any
  isPositionButtonMenuDrawer: boolean
  onToggleDrawer: (open: string) => void
  classes: any
  menuItems: Item[]
}

const AppWrap = ({
  children,
  logo,
  menuItems,
  isPositionButtonMenuDrawer = false,
  onToggleDrawer,
  classes: appWrapClasses
}: AppWrapProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(true)
  const [hideMenu, setHideMenu] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)
  const [popperOpen, setPopperOpen] = useState(false)
  const [appBarState, appBarActions] = useAppBar()

  const updateDimensions = () => {
    if (window.innerWidth < theme.breakpoints.values.md) {
      setOpen(false)
    }
    setWidth(window.innerWidth)
  }

  const handleMenuItemClick = (label: string) => {
    if (label) setOpen(true)
  }

  const handleToggleDrawer = (open: boolean) => {
    onToggleDrawer(open ? enumMenu.OPEN : enumMenu.CLOSE)
  }

  useEffect(() => {
    setHideMenu(width < theme.breakpoints.values.sm && !open)
  }, [open, width])

  useEffect(() => {
    setOpen(localStorage.toggleDrawer === '1')
    handleToggleDrawer(localStorage.toggleDrawer === '1')
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  const toggleMenu = () => {
    setOpen(!open)
    handleToggleDrawer(!open)
    localStorage.setItem('toggleDrawer', !open ? '1' : '0')
  }

  const buttonMenu = () => (
    <Tooltip
      title={open ? 'Retrair menu' : 'Expandir menu'}
      aria-label={open ? 'Retrair menu' : 'Expandir menu'}
    >
      <IconButton
        aria-label="open drawer"
        onClick={toggleMenu}
        className={clsx(
          classes.menuButton,
          isPositionButtonMenuDrawer && classes.menuButtonInMenuDrawer,
          appWrapClasses && appWrapClasses.menuButton
        )}
      >
        <MenuIcon />
      </IconButton>
    </Tooltip>
  )

  const hiddenItemsMenu = (item: Item[]) => {
    const filterMenuHidden: Item[] = []

    menuItems.forEach(item => {
      if (item.hidden === true) {
        return
      }
      if (item.hidden !== true && item.group !== true) {
        filterMenuHidden.push(item)
      }
      if (item.group === true) {
        const index = item.items.findIndex(subItem => subItem.hidden !== true)
        if (index !== -1) {
          const corte = item.items.slice(index, item.items.length)
          item.items = corte
        }
        filterMenuHidden.push(item)
      }
    })

    return filterMenuHidden
  }

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <AppBar
          className={clsx(
            classes.appBar,
            open && classes.appBarShift,
            hideMenu && classes.appBarHiddenMenu
          )}
          position="absolute"
        >
          <Toolbar disableGutters classes={{ root: classes.navBarRoot }}>
            <Grid container>
              <Grid
                item
                xs
                className={clsx(
                  classes.toolsLeft,
                  isPositionButtonMenuDrawer &&
                    classes.toolsLeftWithButtonInDrawer,
                  !hideMenu && classes.toolsLeftHideMenu
                )}
              >
                {buttonMenu()}

                <When value={appBarState.showBreadcrumb} equals={true}>
                  <SimpleBreadcrumb />
                </When>
              </Grid>

              <Grid item className={clsx(classes.toolsRight)}>
                <UserAvatar />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          onMouseOver={() => setPopperOpen(!open)}
          onMouseLeave={() => setPopperOpen(false)}
          variant="permanent"
          classes={{
            paper: clsx(
              classes.drawerPaper,
              !open && classes.drawerPaperClose,
              hideMenu && classes.drawerHideMenu,
              appWrapClasses && appWrapClasses.drawerMenu
            )
          }}
          open={open}
        >
          <div className={classes.drawerInner}>
            <div
              className={clsx(
                classes.drawerHeader,
                isPositionButtonMenuDrawer && classes.drawerHeaderInMenuDrawer,
                appWrapClasses && appWrapClasses.drawerHeader
              )}
            >
              <div
                className={clsx(
                  open
                    ? classes.drawerLogo
                    : isPositionButtonMenuDrawer
                    ? classes.drawerLogoClosedWithButtonInDrawer
                    : classes.drawerLogoClosed
                )}
              >
                <div className={open ? classes.logoOpen : classes.logoClose}>
                  {logo}
                </div>
              </div>
            </div>
            <Divider />
            <List disablePadding={true}>
              <MenuItems
                isExpanded={open}
                isPopperOpen={popperOpen}
                items={hiddenItemsMenu(menuItems)}
                onMenuItemClick={handleMenuItemClick}
              />
            </List>
          </div>
        </Drawer>
        <main
          className={clsx(
            classes.content,
            appWrapClasses && appWrapClasses.content
          )}
        >
          <div>{children}</div>
        </main>
      </div>
    </div>
  )
}

export default AppWrap
