export interface ListaBloqueioFormData {
  id: string
  cpf: string
  motivo: string
  motivodesbloqueio: string
  datadesbloqueio: Date,
  bloqueadoPor: string
  empresaId: number
  empresaFK: {
    sigla: string
  }
}

export interface ListaBloqueioDetail {
  id: string
  cpf: string
  motivo: string
  motivodesbloqueio: string
  datadesbloqueio: Date
  empresaId: number
  bloqueadoPor: string
  empresaFK: {
    sigla: string
  }
}

export interface SaveListaBloqueioInput {
  id: string
  cpf: string
  motivo: string
  motivodesbloqueio?: string
  datadesbloqueio?: Date
  empresaId: number
}

export const BloqueioColors = {
  DESBLOQUEIO: '#81c784',
  DESBLOQUEIO_DARK: '#388e3c',
  BLOQUEIO: '#e57373',
  BLOQUEIO_DARK: '#e53935'
}

export interface SubmitValues {
  createdAt?: Date
  cpf?: string
  datadesbloqueio?: string
}
