import Select from '@elentari/components-eve/final-form/Select';
import { useSnackbar } from "@elentari/core";
import { Button, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { FormSpy } from "react-final-form";
import ComplexDialog from "src/components/ComplexDialog";
import RFFAutocomplete from 'src/components/Forms/RFFAutocomplete';
import RichTextField from 'src/components/Forms/RichTextField';
import { Option } from 'src/hooks/useAutoComplete';
import { GridRepository } from 'src/modules/grids/hooks/GridRepository';
import { useLoggedUser } from "src/modules/login/AuthProvider";
import { MessagesYup } from "src/modules/messages";
import { isG10 } from 'src/utils';
import yupValidation from "src/utils/yupValidation";
import * as yup from 'yup';
import { save } from "../hooks/triagemRepository";
import { aprovarTriagemOptions, reprovarTriagemOptions } from "../utils/triagemOptions";

type ModalFormData = {
  motivo: string
  atendeCriterios: boolean
  gridId?: string
  outrosMotivos?: string
}

interface CreateTriagemButtonProps {
  candidatoId: string | false
  buttonLabel: string
  className?: string
  dialog?: {
    title?: string
    acceptLabel?: string
    rejectLabel?: string
  }
  defaultAtendeCriterios: boolean
  onSaveSuccessful?: () => Promise<void>
}

export const CreateTriagemButton = ({
  candidatoId,
  buttonLabel,
  className,
  dialog,
  defaultAtendeCriterios,
  onSaveSuccessful
}: CreateTriagemButtonProps) => {

  const [gridsOptions, setGridsOptions] = useState<Option[]>([])
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const [, snackbarActions] = useSnackbar()
  const userLogged = useLoggedUser()
  const userEmpresaIsG10 = isG10(userLogged?.empresa)

  useEffect(() => {
    const loadGrids = async () => {
      const response = await GridRepository.fetch({ limit: '999' })

      if (response.ok) {
        setGridsOptions(
          response.data?.edges.map(grid => ({
            id: grid.node.id,
            name: grid.node.nome
          })) ?? []
        )
      }
    }
    loadGrids()
  }, [])

  const dialogSchema = yup.object().shape({
    motivo: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    outrosMotivos: yup
      .string()
      .nullable()
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    gridId: yup
      .string()
      .nullable()
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  })

  const handleTriarCandidato = async (modalData: ModalFormData) => {
    try {
      if (candidatoId && userLogged?.name) {
        const response = await save({
          atendeCriterios: modalData.atendeCriterios,
          candidatoId: candidatoId,
          motivo: modalData.motivo,
          comentario: modalData.outrosMotivos,
          gridId: modalData.gridId,
          triadoPor: userLogged.name
        })

        if (response?.ok && response.info) {
          if (onSaveSuccessful) {
            await onSaveSuccessful()
          }
          snackbarActions.setMessage("Candidato triado com sucesso")
        } else {
          snackbarActions.setMessage(
            'Ocorreu um erro ao triar o(a) Candidato(a)'
          )
        }
      } else {
        snackbarActions.setMessage('Não foi possível encontrar o candidato')
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        className={className}
        onClick={() => setOpenDialog(true)}
      >
        {buttonLabel}
      </Button>

      <ComplexDialog
        open={openDialog}
        title={dialog?.title ?? "Deseja triar este Candidato(a)?"}
        primaryActionButtonLabel={dialog?.acceptLabel ?? "Triar"}
        closeActionButtonLabel={dialog?.rejectLabel ?? "Voltar"}
        handleClose={() => setOpenDialog(false)}
        primaryAction={handleTriarCandidato}
        validate={yupValidation(dialogSchema)}
        initialValues={{
          atendeCriterios: defaultAtendeCriterios
        }}
        disableBackdropClick={true}
      >
        <Grid container spacing={2} style={{ width: '400px' }}>
          <Grid item xs={12}>
            <FormSpy
              render={({ values }) => (
                <Select
                  fullWidth
                  name="motivo"
                  label="Motivo"
                  items={values.atendeCriterios ? aprovarTriagemOptions : reprovarTriagemOptions}
                />
              )}
            />
          </Grid>
          <FormSpy
            render={({ values }) => (
              <>
                {(values.atendeCriterios && userEmpresaIsG10) && (
                  <Grid item xs={12}>
                    <RFFAutocomplete
                      name="gridId"
                      label="Grid"
                      options={gridsOptions}
                    />
                  </Grid>
                )}
              </>
            )}
          />
          <Grid item xs={12}>
            <RichTextField name="outrosMotivos" label="Outros Motivos" />
          </Grid>
        </Grid>
      </ComplexDialog>
    </>
  )
}