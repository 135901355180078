import { useSnackbar } from '@elentari/core'
import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Grid } from '@material-ui/core'
import { FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Paper } from 'src/components'
import { useAppBar } from 'src/hooks'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import { isAnalistaRh, isG10 } from 'src/utils'
import { ExperienciaProfMotoristaSchemaStep } from 'src/utils/yup-schemas/candidato/expProfStepSchema'
import { CandidatoSchemaStepMotorista } from 'src/utils/yup-schemas/candidato/motoristaStepSchema'
import { FormikStepper } from '../../../../components/Formik/FormikStepper'
import { FormHeader } from '../../components/FormHeader'
import { uploadManyDocumentos } from '../../hooks/documentosRepository'
import CandidatoStep from '../../sharedSteps/CandidatoStep'
import ExperienciaProfeStep from '../../sharedSteps/ExperienciaProfStep'
import { objectRemoveEmptyStrings } from '../../utils'
import { repository as motoristaRepository } from '../hooks/motoristaRepository'
import { useMotorista } from '../hooks/useMotorista'
import { SaveMotoristaInput } from '../types'
import { initialValues } from '../utils/initialValues'

export const MotoristaForm = () => {
  const [motoristaState, actions] = useMotorista()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()

  const history = useHistory()
  const userLogged = useLoggedUser()
  const isAnalista = isAnalistaRh(userLogged?.rolesInSystem)
  const userEmpresaIsG10 = isG10(userLogged?.empresa)

  const { save } = useEntitySaver<SaveMotoristaInput>(data =>
    motoristaRepository.save(data)
  )

  const formikState = {
    initialValues,
    onSubmit: async (data: FormikValues) => {
      setIsSubmitting(true)
      try {
        data.tipo = 'MOTORISTA'
        if (!data.Motorista.indicacao) {
          data.Motorista.quemIndicou = ''
          data.Motorista.cargoQuemIndicou = ''
          data.Motorista.coordenadorQuemIndicou = ''
        }

        const documentos = await uploadManyDocumentos([
          { ...data.anexoCurriculo, descricao: 'anexoCurriculo', candidatoId: data.id },
          { ...data.anexoCNH, descricao: 'anexoCNH', candidatoId: data.id }
        ])
        if (!documentos) {
          setIsSubmitting(false)
          return snackbarActions.setMessage("Ocorreu um erro ao salvar o(s) documento(s)")
        }

        delete data.anexoCurriculo
        delete data.anexoCNH

        const dataFormatted = objectRemoveEmptyStrings(data)
        const response = await save({
          ...dataFormatted,
          documentos
        } as SaveMotoristaInput)
        if (response.status === 200) {
          if (response.data?.id) {
            actions.fetch(response.data.id)
          }
          appBarActions.setTitle(data.nomeCompleto)
        }
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (motoristaState.tag === 'with-data') {
      if (userLogged?.empresa !== motoristaState.entity.empresaFK?.sigla) {
        history.push('/')
      }
    }
  }, [motoristaState])

  useEffect(() => {
    return actions.reset()
  }, [actions])

  return (
    <Paper noTopBorderRadius>
      <Grid container spacing={2} direction="column">
        <FormikStepper
          onSubmit={formikState.onSubmit}
          enableReinitialize={true}
          initialValues={
            motoristaState.tag === 'with-data'
              ? motoristaState.entity
              : formikState.initialValues
          }
        >
          <FormHeader
            header
            disableButtons={isSubmitting}
            showReturnButton={true}
            candidatoData={motoristaState.tag === 'with-data' && motoristaState.entity}
          />

          <CandidatoStep
            candidatoType="Motorista"
            label="Candidato(a)"
            validationSchema={CandidatoSchemaStepMotorista(isAnalista)}
          />

          <ExperienciaProfeStep
            label="Experiência Profissional"
            candidatoType="Motorista"
            validationSchema={ExperienciaProfMotoristaSchemaStep(isAnalista, userEmpresaIsG10)}
          />
        </FormikStepper>
      </Grid>
    </Paper>
  )
}
