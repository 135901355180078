import { useSnackbar } from '@elentari/core'
import { getAuthorizations } from '../../../utils/crudAuthorization'
import { HandleErrorPage } from '../../../utils/handleErrorPage'
import { useAbility } from '../../login'
import { repository } from './hooks/motoristaRepository'
import { useMotoristas } from './hooks/useMotoristas'
import { MotoristaFilter } from './MotoristaFilter'
import { MotoristaTable } from './MotoristaTable'
import { MotoristaDetail } from './types'

const MotoristaList = (props: {
  list: MotoristaDetail[]
  loading: boolean
  totalCount: number
  onDelete: (id: string) => void
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}) => {
  return (
    <>
      <MotoristaFilter />
      <MotoristaTable {...props} />
    </>
  )
}

const Motoristas = () => {
  const [state, actions] = useMotoristas()
  const [, snackbarActions] = useSnackbar()
  const ability = useAbility()

  async function handleDelete(id: string) {
    const response = await repository.delete(id)
    actions.fetchPage()
    if (response.ok) {
      snackbarActions.setSnackBar({
        message: 'Deletado com sucesso',
        severity: 'success'
      })
      actions.fetchPage()
      return
    }
    const message = (response.data as unknown as any).message
    snackbarActions.setSnackBar({
      message: message as string,
      severity: 'warning'
    })
  }

  const authorization = getAuthorizations(ability, 'motorista')

  switch (state.tag) {
    case 'empty':
      return (
        <MotoristaList
          onDelete={() => { }}
          totalCount={0}
          list={[]}
          loading={true}
        />
      )
    case 'with-data':
      return (
        <MotoristaList
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onDelete={handleDelete}
          authorization={authorization}
        />
      )
    case 'error':
      return <HandleErrorPage state={state.data} />
  }
}

export default Motoristas
