import { makeUseList } from '@elentari/core'
import { VagaDetail } from '../types'
import { vagasRepository } from './vagaRepository'

export const useVagas = makeUseList<VagaDetail>(vagasRepository.fetch)
// export const useOrigemList = makeUseListSubRepository<LayoutOrigemDetail>(
//   origemRepository.fetch
// )
// export const useDestinoList = makeUseListSubRepository<LayoutDestinoDetail>(
//   destinoRepository.fetch
// )
// export const useMapeamentoList =
//   makeUseListSubRepository<LayoutMapeamentoDetail>(mapeamentoRepository.fetch)
