import { useSnackbar } from '@elentari/core'
import { DropzoneAreaAnexo } from './DropzoneAreaAnexo'
import { FormHelperText, FormControl } from '@material-ui/core'
import { useField } from 'react-final-form'

type Props = {
  name: string
}

export const AnexoInput = ({ name }: Props) => {
  const { input, meta } = useField(name)
  const [, snackbarActions] = useSnackbar()

  const handleSelectFile = (file: File[]): void => {
    try {
      const uniqueFile = file[0]
      if (!uniqueFile) {
        snackbarActions.setMessage('O arquivo é obrigatório!')
      }
      input.onChange(uniqueFile)
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  const handleRemoveFile = (): void => {
    try {
      input.onChange(undefined)
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }
  
  return (
    <FormControl error={meta.error && meta.touched} fullWidth>
      <DropzoneAreaAnexo
        onSelectFile={handleSelectFile}
        onRemoveFile={handleRemoveFile}
        error={meta.error && meta.touched}
      />
      {meta.error && meta.touched && <FormHelperText error>{meta.error}</FormHelperText> }
    </FormControl>
  )
}
