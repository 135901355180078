import { FormHelperText } from '@material-ui/core'
import { useField } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import config from '../../src/config'

interface CaptchaInputProps {
  name: string
}

function CaptchaInput(props: CaptchaInputProps) {
  const [field, meta, helpers] = useField(props)
  const siteKey = config.recaptchaSiteKey || ''

  function handleChange(token: string | null) {
    helpers.setValue(token)
  }
  return (
    <>
      <ReCAPTCHA sitekey={siteKey} onChange={handleChange} />
      <FormHelperText error={Boolean(meta.error)}>{meta.error}</FormHelperText>
    </>
  )
}

export default CaptchaInput
