import { useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { FieldsPending } from "../Forms/FieldPending"

interface FormikStepperFieldPendingProps {
  step: number
}

export const FormikStepperFieldPending = ({
  step
}: FormikStepperFieldPendingProps) => {
  const [stepSubmitCount, setStepSubmitCount] = useState<number>(0)
  const { errors, submitCount } = useFormikContext()

  useEffect(() => {
    if (submitCount) {
      setStepSubmitCount(submitCount)
    }
  }, [step])

  return (
    <FieldsPending errors={errors} submitCount={submitCount - stepSubmitCount} />
  )
}