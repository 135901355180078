import { useBeneficio } from '../hooks/useBeneficio'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { BeneficioForm } from './BeneficioForm'

export function BeneficioDetail() {
  const [BeneficioState, BeneficioActions] = useBeneficio()
  const [, appBarActions] = useAppBar()

  BeneficioState &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useReplaceFragments(appBarActions.replaceFragment, [
      mapFragmentReplace(
        BeneficioState,
        u => u.entity.id,
        u => u.entity.descricao
      )
    ])
  useFetchAndReset(BeneficioActions.fetch, BeneficioActions.reset)
  return handleStateErrorsToRender(BeneficioState, <BeneficioForm />)
}
