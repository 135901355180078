import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import LinkMD from '@material-ui/core/Link'
import { Skeleton } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { PaperCard } from 'src/components/PaperCard'
import { StatusChip } from 'src/components/StatusChip'
import { FormatedDate } from 'src/utils'
import {
  AddRecord,
  NoResultsTable,
  Paper,
  TableContainer
} from '../../../components'
import { TableCellHead } from '../../../components/TableCellHead'
import { apiSauceInstance } from '../../../services/api'
import {
  statusProcessoAdministrativo,
  ProcessoSeletivoAdministrativoFormData
} from '../types'

interface Props {
  list: ProcessoSeletivoAdministrativoFormData[]
  loading: boolean
  totalCount: number
  candidatoId: string
}

export const ProcessoTable = ({
  list,
  loading,
  totalCount,
  candidatoId
}: Props) => {
  return (
    <Box marginBottom={4}>
      <PaperCard
        title="Processos Seletivos"
        Content={
          <TableContainer
            loading={loading}
            totalCount={0}
            disablePagination={true}
          >
            {list.length ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellHead label="Candidato(a)" />
                    <TableCellHead label="Vaga" />
                    <TableCellHead label="Status" />
                    <TableCellHead label="Data Início do Processo" />
                    <TableCellHead label="Data Encerramento do Processo" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map(processo => (
                    <TableRow hover key={processo.id}>
                      <TableCell>
                        <LinkMD
                          component={Link}
                          to={`/processo-seletivo-administrativo/${processo.id}`}
                        >
                          {processo.candidatoFK.nomeCompleto}
                        </LinkMD>
                      </TableCell>
                      <TableCell>{processo.vagasFK?.cargoFk?.nome}</TableCell>
                      <TableCell>
                        <StatusChip
                          label={
                            statusProcessoAdministrativo.find(
                              value => value.value === processo.status
                            )?.label ?? processo.status
                          }
                          status={processo.status}
                        />
                      </TableCell>
                      <TableCell>
                        {processo.createdAt && FormatedDate(processo.createdAt)}
                      </TableCell>
                      <TableCell>
                        {processo.dataEncerramento &&
                          FormatedDate(processo.dataEncerramento)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <NoResultsTable loading={loading} />
            )}
          </TableContainer>
        }
      />
      <AddRecord
        title="Adicionar Processo"
        path="/processo-seletivo-administrativo"
        candidatoId={candidatoId}
      />
    </Box>
  )
}

type ProcessoProps = {
  id: string
}

type APIResponseProcessos = {
  edges: ProcessoSeletivoAdministrativoFormData[]
  totalCount: number
  loading: boolean
}

const ProcessoAdministrativoList = ({ id }: ProcessoProps) => {
  const [processoState, setProcessoState] = useState<APIResponseProcessos>(
    {} as APIResponseProcessos
  )

  useEffect(() => {
    async function loadProcessosById() {
      try {
        setProcessoState({
          ...processoState,
          loading: true
        })

        const { data } = await apiSauceInstance.get<any>(
          `/processo-seletivo-administrativos/candidato`,
          {
            candidatoId: id
          }
        )
        if (data) {
          setProcessoState({
            totalCount: data.totalCount,
            edges: data.edges,
            loading: false
          })
        }
      } catch (error) {}
    }
    loadProcessosById()
  }, [id])

  return (
    <div>
      {processoState.loading === false ? (
        <ProcessoTable
          list={processoState.edges}
          totalCount={processoState.totalCount}
          loading={processoState.loading}
          candidatoId={id}
        />
      ) : (
        <Paper minHeight={340} maxHeight={340}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Paper>
      )}
    </div>
  )
}

export default ProcessoAdministrativoList
