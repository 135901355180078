import {
  createStyles,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core"
import RestoreIcon from '@material-ui/icons/Restore'

type ReagendamentoProps = {
  disabled?: boolean
}

export const useStyles = makeStyles(() =>
  createStyles({
    reagendamentoIcon: {
      color: '#11aa4e',
      fontSize: '2rem'
    }
  })
);

export const ReagendamentoIcon = ({
  disabled
}: ReagendamentoProps) => {
  const style = useStyles()

  return (
    <Tooltip title="Reagendamento">
      <IconButton
        aria-label="reagendamento"
        disabled={disabled}
      >
        <RestoreIcon className={style.reagendamentoIcon} />
      </IconButton>
    </Tooltip>
  )
}