import { Grid, Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import AutoCompleteInput from 'src/components/Formik/Forms/AutoCompleteInput'
import DateInput from 'src/components/Formik/Forms/DateInput'
import { RadioGroupInput } from 'src/components/Formik/Forms/RadioGroupInput'
import SelectInput from '../../../../../components/Formik/Forms/SelectInput'
import TextInput from '../../../../../components/Formik/Forms/TextInput'
import {
  booleanOptions,
  categoriaCNH,
  estadoCivil
} from '../../../../../utils/index'

interface Props {
  label: string
}

export default function MotoristaStep({ label }: Props) {
  const { values } = useFormikContext<{
    Motorista: {
      indicacao: boolean
      mopp: string
    }
  }>()

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          style={{ paddingBottom: 20, paddingTop: 20 }}
          variant="h5"
          align="center"
        >
          {label}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <AutoCompleteInput
            label="Estado Civil"
            name="Motorista.estadoCivil"
            options={estadoCivil}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextInput label="RG" name="Motorista.rg" />
        </Grid>

        <Grid item sm={6} xs={12}>
          <SelectInput
            label="Categoria da CNH"
            name="Motorista.categoriaCNH"
            options={categoriaCNH}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectInput
            name="Motorista.pamcary"
            label="Pamcary"
            options={booleanOptions}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectInput
            name="Motorista.earCNH"
            label="Possui EAR na CNH"
            options={booleanOptions}
          />
        </Grid>
        <Grid
          item
          xs={
            values.Motorista.mopp === 'true' ||
              (values.Motorista.mopp as any) === true
              ? 6
              : 12
          }
          sm={
            values.Motorista.mopp === 'true' ||
              (values.Motorista.mopp as any) === true
              ? 3
              : 6
          }
        >
          <SelectInput
            name="Motorista.mopp"
            label="Mopp"
            options={booleanOptions}
          />
        </Grid>
        {(values.Motorista.mopp === 'true' ||
          (values.Motorista.mopp as any) === true) && (
            <Grid item xs={6} sm={3}>
              <DateInput
                label="Data de validade da Mopp"
                name="Motorista.dataValidadeMopp"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          )}

        <Grid item xs={12}>
          <Typography style={{ paddingBottom: 20 }} variant="h5" align="center">
            Indicação
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <RadioGroupInput
            label="Algum funcionário indicou você"
            name="Motorista.indicacao"
            type="boolean"
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false }
            ]}
          />
        </Grid>
        {values.Motorista.indicacao && (
          <>
            <Grid item xs={12} md={4}>
              <TextInput name="Motorista.quemIndicou" label="Nome Completo de quem indicou" />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextInput
                name="Motorista.cargoQuemIndicou"
                label="Cargo de Quem Indicou"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextInput
                name="Motorista.coordenadorQuemIndicou"
                label="Coordenador de Quem Indicou"
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}
