import { CargoBeneficioDetail } from '../types'
import { apiSauceInstance } from 'src/services/api'

export const createCargoBeneficios = async (
  data: CargoBeneficioDetail
) => {
  const response = await apiSauceInstance.post<any>(
    `/cargo-beneficios/create-many`,
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }

  return response.data
}


export const getAllCargoBeneficiosByCargoId = async (
  CargoId: string
) => {
  const response = await apiSauceInstance.get<any>(
    `/cargo-beneficios/cargo/${CargoId}`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const getAllCargoAreasUsedByCargo = async () => {
  const response = await apiSauceInstance.get<{area: string}[]>(
    '/cargo-areas/used-by-cargos'
  )

  const { data: info, ok, status } = response

  if(info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const getAllCargoCategoriasUsedByCargo = async () => {
  const response = await apiSauceInstance.get<{categoria: string}[]>(
    '/cargo-categorias/used-by-cargos'
  )

  const { data: info, ok, status } = response

  if(info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}