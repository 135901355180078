import {
  NoResultsTable,
  Paper,
  TableContainer
} from '../../../components'
import Spacer from '../../../components/Spacer'
import { MotoristaDetail } from '../motorista/types'
import { CandidatoDetail } from '../types'
import { SelecaoCandidatoCard } from './components/SelecaoCandidatoCard'

interface SelecaoCandidatoTableProps {
  list: { node: MotoristaDetail | CandidatoDetail }[]
  loading: boolean
  totalCount: number
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const SelecaoCandidatoTable = ({
  list,
  loading,
  totalCount,
  authorization
}: SelecaoCandidatoTableProps) => {

  return (
    <>
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            list.map((candidato, index) => <SelecaoCandidatoCard key={index} candidato={candidato.node} canUpdate={authorization?.canUpdate} />)
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
    </>
  )
}