import { AdmissaoCandidatoDetail } from "../admissao-candidato/types";
import { AgendamentosCandidatoDetail } from "../agendamento-candidato/types";
import { SelecaoCandidatoDetail, TriagemDetail } from "../types";

export function getNewestTriagem(triagens: TriagemDetail[]): TriagemDetail {
  const triagensSortedByCreatedAt = triagens.sort(
    (triagemA, triagemB) => {
      if (!triagemA.createdAt || !triagemB.createdAt) {
        return -5
      } else {
        return new Date(triagemB.createdAt).getTime() - new Date(triagemA.createdAt).getTime()
      }
    }
  )

  return triagensSortedByCreatedAt[0]
}

export function getNewestAgendamento(agendamentos: AgendamentosCandidatoDetail[]): AgendamentosCandidatoDetail {
  const agendamentosSortedByCreatedAt = agendamentos.sort(
    (agendamentoA, agendamentoB) => {
      if (!agendamentoA.createdAt || !agendamentoB.createdAt) {
        return -5
      } else {
        return new Date(agendamentoB.createdAt).getTime() - new Date(agendamentoA.createdAt).getTime()
      }
    }
  )

  return agendamentosSortedByCreatedAt[0]
}

export function getNewestSelecao(selecoes: SelecaoCandidatoDetail[]): SelecaoCandidatoDetail {
  const selecoesSortedByCreatedAt = selecoes.sort(
    (selecaoA, selecaoB) => {
      if (!selecaoA.createdAt || !selecaoB.createdAt) {
        return -5
      } else {
        return new Date(selecaoB.createdAt).getTime() - new Date(selecaoA.createdAt).getTime()
      }
    }
  )

  return selecoesSortedByCreatedAt[0]
}

export function getNewestAdmissao(admissoes: AdmissaoCandidatoDetail[]): AdmissaoCandidatoDetail {
  const admissoesSortedByCreatedAt = admissoes.sort(
    (admissaoA, admissaoB) => {
      if (!admissaoA.createdAt || !admissaoB.createdAt) {
        return -5
      } else {
        return new Date(admissaoB.createdAt).getTime() - new Date(admissaoA.createdAt).getTime()
      }
    }
  )

  return admissoesSortedByCreatedAt[0]
}

export function objectRemoveEmptyStrings(object: Object): Object {
  const newObj: { [key: string]: any } = object

  Object.entries(newObj).forEach(([key, value]) =>
    (value && typeof value === 'object') && objectRemoveEmptyStrings(value) ||
    (value === '') && delete newObj[key]
  )

  return newObj
}