import { Redirect, Route } from 'react-router-dom'
import { Consumer } from './AuthProvider'
import { AuthContext } from './types'

interface PrivateRouteProps {
  loginPath: string
  path: string
  component?: any
  render?: any
}

const PrivateRoute = ({
  component: Component,
  render,
  loginPath = '/login',
  ...props
}: PrivateRouteProps) => (
  <Consumer>
    {(auth: AuthContext) => {
      if (!auth.initialized) {
        return null
      }
      return (
        <Route
          exact
          {...props}
          render={props =>
            auth.state.loggedin ? (
              Component ? (
                <Component />
              ) : (
                render(props)
              )
            ) : (
              <Redirect to={loginPath} />
            )
          }
        />
      )
    }}
  </Consumer>
)

export default PrivateRoute
