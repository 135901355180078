import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import * as yup from 'yup'

import TextField from '@elentari/components-eve/final-form/TextField'
import { Grid } from '@material-ui/core'
import { CargoAreaFormData } from '../types'
import yupValidation from '../../../../utils/yupValidation'

import { FormWrap, Paper } from '../../../../components'
import { useCargoArea } from '../hooks/useCargoArea'

import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { CargoAreaRepository } from '../hooks/CargoAreaRepository'
import { MessagesYup } from '../../../messages'

import { useSnackbar } from '@elentari/core'
import { setTimeout } from 'timers'
import { useAppBar } from 'src/hooks'

export const CargoAreaForm = () => {
  const [CargoAreaState, CargoAreaAction] = useCargoArea()

  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()

  const { save } = useEntitySaver<CargoAreaFormData>(async data => {
    const response = await CargoAreaRepository.save(data)
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return response
  })

  const handleSubmit = async (data: any) => {
    try {
      setLoading(true)
      const response = await save(data)
      if(response.ok) {
        appBarActions.setTitle(data.descricao)
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }
  const schemaObject = {
    descricao: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
  }
  const schema = yup.object().shape(schemaObject)

  useEffect(() => {
    return CargoAreaAction.reset()
  }, [CargoAreaAction])

  return (
    <Form
      validate={yupValidation(schema)}
      onSubmit={handleSubmit}
      initialValues={
        CargoAreaState.tag === 'with-data' && CargoAreaState.entity
      }
    >
      {({ handleSubmit, submitting, values }) => (
        <Paper>
          <FormWrap
            handleSubmit={handleSubmit}
            disableSubmit={submitting}
            disableBack={submitting}
            loading={loading}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField fullWidth name="descricao" label="Descrição" />
              </Grid>
            </Grid>
          </FormWrap>
        </Paper>
      )}
    </Form>
  )
}
