import { useEffect, useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import SelectInput from 'src/components/Formik/Forms/SelectInput'
import TextInput from 'src/components/Formik/Forms/TextInput'
import { Grid, InputAdornment } from '@material-ui/core'
import { SaveTreinamentoInput, statusTreinamento, TreinamentoFormData } from '../types'
import yupValidation from '../../../utils/yupValidation'

import { Paper } from '../../../components'
import { useTreinamento } from '../hooks/useTreinamento'

import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { treinamentosRepository } from '../hooks/treinamentosRepository'
import { MessagesYup } from '../../messages'

import { useSnackbar } from '@elentari/core'
import { setTimeout } from 'timers'
import { useAppBar } from 'src/hooks'
import { useHistory, useLocation, useParams } from 'react-router'
import { handleKeyCodes } from 'src/modules/options'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import { TreinamentoFormWrap } from '../components/TreinamentoFormWrap'
import RichTextInput from 'src/components/Formik/Forms/RichTextInput'
import CurrencyInput from 'src/components/Formik/Forms/CurrencyInput'

interface Params {
  id: string
}

export const TreinamentosForm = () => {
  const [treinamentoState, treinamentoAction] = useTreinamento()

  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()
  
  const params = useParams<Params>()
  const location = useLocation()

  const isNew = location.pathname.includes('/new')
  const userLogged = useLoggedUser()
  const isGestor = userLogged?.rolesInSystem.includes('GESTOR')
  const isAdmin = userLogged?.rolesInSystem.includes('ADMIN')
  const disableFields =
    !isNew &&
    !(
      isGestor &&
      treinamentoState.tag === 'with-data' &&
      treinamentoState.entity.status === 'PENDENTE_APROVACAO'
    ) &&
    !isAdmin

  const { save } = useEntitySaver<SaveTreinamentoInput>(async data => {
    const response = await treinamentosRepository.save(data)
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return response
  })

  const formikState = {
    initialValues: {
      nome: '',
      descricao: '',
      cargaHoraria: 0,
      valorPorPessoa: 0,
      status: 'PENDENTE_APROVACAO',
    } as TreinamentoFormData,
    onSubmit: async (data: TreinamentoFormData) => {
      try {
        setLoading(true)
        const response = await save(data)
        if(response.ok) {
          appBarActions.setTitle(data.nome)
        }
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  const formatData = (values: TreinamentoFormData): TreinamentoFormData => {
    return {
      ...values,
      cargaHoraria: Number(values.cargaHoraria),
      valorPorPessoa: Number(values.valorPorPessoa)
    }
  }

  
  useEffect(() => {
    async function fetchTreinamento() {
      if (params && params.id !== 'new' && location.pathname) {
        await treinamentoAction.fetch(params.id)
      }
    }
    fetchTreinamento()
  }, [location])

  useEffect(() => {
    return treinamentoAction.reset()
  }, [treinamentoAction])

  const schemaObject = {
    nome: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    status: yup
      .string()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    cargaHoraria: yup
      .number()
      .moreThan(0, MessagesYup.MENSAGEM_VALOR_ZERO)
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO),
    valorPorPessoa: yup
      .number()
      .moreThan(0, MessagesYup.MENSAGEM_VALOR_ZERO)
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO),
    descricao: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  }
  const schema = yup.object().shape(schemaObject)

  return (
    <Formik
      validate={yupValidation(schema)}
      onSubmit={formikState.onSubmit}
      enableReinitialize
      initialValues={
        treinamentoState.tag === 'with-data'
          ? formatData(treinamentoState.entity)
          : formikState.initialValues
      }
    >
      <Paper>
        <TreinamentoFormWrap
          formLoading={loading}
          isNew={isNew}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInput
                name="nome"
                label="Nome"
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                name="status"
                label="Status"
                options={
                  isNew && !isAdmin
                    ? statusTreinamento.filter(
                        status => status.value === 'PENDENTE_APROVACAO'
                      )
                    : statusTreinamento
                }
                defaultValue="PENDENTE_APROVACAO"
                firstOptionDisabled
                disabled={!isNew && !isAdmin}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                type="number"
                name="cargaHoraria"
                label="Carga Horária"
                inputProps={{ min: 0, form: 'novalidatedform' }}
                onKeyDown={handleKeyCodes}
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={6}>
              <CurrencyInput
                name="valorPorPessoa"
                label="Valor por Pessoa"
                startAdornment={
                  <InputAdornment position="start">R$</InputAdornment>
                }
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={12}>
              <RichTextInput
                name="descricao"
                label="Descrição"
                disabled={disableFields}
              />
            </Grid>
          </Grid>
        </TreinamentoFormWrap>
      </Paper>
    </Formik>
  )
}
