import { apiSauceInstance } from '../../../services/api'
import { GridDetail, SaveGridInput } from '../types'
import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { ParsedQs } from 'qs'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params
  return { ...params, ...paginationParams(rest) }
}

export const GridRepository = makeRepository<
  EdgesPage<GridDetail>,
  SaveGridInput,
  GridDetail,
  string
>(
  {
    resource: 'grids',
    mapQueryParams
  },
  apiSauceInstance
)

export const GridWithoutPaginationRepository = makeRepository<
  EdgesPage<GridDetail>,
  SaveGridInput,
  GridDetail,
  string
>(
  {
    resource: 'grids'
  },
  apiSauceInstance
)