import {
  createStyles,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core"
import AutorenewIcon from '@material-ui/icons/Autorenew'

type AprovarTriagemIconProps = {
  onClick: () => void
  disabled: boolean
}

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      transform: "rotate(-50deg)",
      color: '#11aa4e',
      fontSize: '1.8rem'
    }
  })
);

export const AprovarTriagemIcon = ({
  onClick,
  disabled
}: AprovarTriagemIconProps) => {
  const style = useStyles()

  return (
    <Tooltip title="Aprovar Candidato">
      <IconButton
        aria-label="aprovar-candidato"
        onClick={onClick}
        disabled={disabled}
      >
        <AutorenewIcon className={style.rotateIcon} />
      </IconButton>
    </Tooltip>
  )
}