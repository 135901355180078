import formatString from 'format-string-by-pattern'

const cpfCnpjUnmask = (value: string) => {
  if (value === undefined || value === null) return ''
  return value.replace(/\D/g, '')
}

export const formatCpfCnpj = (string: string) => {
  string = cpfCnpjUnmask(string)
  if (string.length <= 11) {
    return formatString('XXX.XXX.XXX-XX', string)
  }
  return formatString('XX.XXX.XXX/XXXX-XX', string)
}
