import { ListState } from '@elentari/core/types'
import { useAbility } from 'src/modules/login'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import { HandleErrorPage } from 'src/utils/handleErrorPage'
import { AgendamentoCandidatoFilter } from './AgendamentoCandidatoFilter'
import { AgendamentoCandidatoTable } from './AgendamentoCandidatoTable'
import { useAgendamentosCandidatosAdministrativos, useAgendamentosCandidatosMotoristas } from './hooks/useAgendamentosCandidato'
import { AgendamentosCandidatoDetail } from './types'

const AgendamentoCandidatoList = (props: {
  list: AgendamentosCandidatoDetail[]
  loading: boolean
  totalCount: number
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
  candidatoType: 'ADMINISTRATIVO' | 'MOTORISTA'
}) => {
  return (
    <>
      <AgendamentoCandidatoFilter candidatoType={props.candidatoType} />
      <AgendamentoCandidatoTable {...props} />
    </>
  )
}

const AgendamentoCandidato = ({
  state,
  candidatoType
}: {
  state: ListState<AgendamentosCandidatoDetail>
  candidatoType: 'ADMINISTRATIVO' | 'MOTORISTA'
}) => {
  const ability = useAbility()

  const authorization = getAuthorizations(ability, 'agendamentosMotoristas')

  switch (state.tag) {
    case 'empty':
      return (
        <AgendamentoCandidatoList
          totalCount={0}
          list={[]}
          loading={true}
          candidatoType={candidatoType}
        />
      )
    case 'with-data':
      return (
        <AgendamentoCandidatoList
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          authorization={authorization}
          candidatoType={candidatoType}
        />
      )
    case 'error':
      return <HandleErrorPage state={state.data} />
  }
}

export const AgendamentoCandidatoMotorista = () => {
  const [motoristaState,] = useAgendamentosCandidatosMotoristas()
  return <AgendamentoCandidato state={motoristaState} candidatoType="MOTORISTA" />
}
export const AgendamentoCandidatoAdministrativo = () => {
  const [administrativoState,] = useAgendamentosCandidatosAdministrativos()
  return <AgendamentoCandidato state={administrativoState} candidatoType="ADMINISTRATIVO" />
}