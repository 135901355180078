import { Grid, Typography } from '@material-ui/core'
import SelectInput from '../../../../../components/Formik/Forms/SelectInput'
import { grauEscolaridade } from '../../../../../utils/index'

interface Props {
  label: string
}

export default function EscolaridadeStep({ label }: Props) {
  return (
    <Grid container spacing={2} style={{ paddingBottom: 20 }}>
      <Grid item xs={12}>
        <Typography style={{ paddingBottom: 20 }} variant="h5" align="center">
          {label}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <SelectInput
          name="Motorista.grauEscolaridade"
          label="Grau de Escolaridade"
          options={grauEscolaridade}
        />
      </Grid>
    </Grid>
  )
}
