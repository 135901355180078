import { EdgesPage } from "@elentari/core/types"
import QueryString, { ParsedQs } from "qs"
import { apiSauceInstance } from "src/services/api"
import { HistoricoCandidatoDetail } from "../types"

export const fetchHistoricoCandidatoFromCandidatoId = async (candidatoId: string, params?: ParsedQs) => {
  const response = await apiSauceInstance.get<EdgesPage<HistoricoCandidatoDetail>>(
    `/historico-candidato${params ? `?${QueryString.stringify(params)}` : ''}`,
    {
      candidatoId,
      skip: params && params.page ? (+params.page - 1) * 10 : 0,
      limit: 10
    }
  )

  return response
}