import { useLabelCaracteristica } from '../hooks/useLabelCaracteristica'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { LabelCaracteristicaForm } from './LabelCaracteristicaForm'

export function LabelCaracteristicaMasterDetail() {
  const [LabelCaracteristicaState, LabelCaracteristicaActions] = useLabelCaracteristica()
  const [, appBarActions] = useAppBar()

  LabelCaracteristicaState &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useReplaceFragments(appBarActions.replaceFragment, [
      mapFragmentReplace(
        LabelCaracteristicaState,
        u => u.entity.id,
        u => u.entity.descricao
      )
    ])
  useFetchAndReset(LabelCaracteristicaActions.fetch, LabelCaracteristicaActions.reset)
  return handleStateErrorsToRender(LabelCaracteristicaState, <LabelCaracteristicaForm />)
}
