import { makeAutoObservable } from "mobx";
import { fetchListaTriagem } from "../hooks/candidatoRepository";

class TriagemStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  candidatosId: string[] = []
  currentIndex: number = 0

  async fetchCandidatos(filter: string, candidatoIdSelected: string) {
    const response = await fetchListaTriagem(filter)
    if (response) {
      const responseFormatted = response.info.filter(candidatoId => candidatoId.id !== candidatoIdSelected).map(candidatoId => candidatoId.id)
      this.candidatosId = [candidatoIdSelected, ...responseFormatted]
      this.currentIndex = 0
    }
  }

  nextCandidatoId(): string | null {
    const nextIndex = this.currentIndex + 1
    if(this.candidatosId[nextIndex] !== undefined) {
      this.currentIndex = nextIndex
      return this.candidatosId[nextIndex]
    } else {
      return null
    }
  }

  previousCandidatoId() {
    this.currentIndex--
  }

  resetCandidatosId() {
    this.candidatosId = []
    this.currentIndex = 0
  }
}

const triagemStore = new TriagemStore()

export default triagemStore