import { CargoResponsabilidadeDetail } from '../types'
import { apiSauceInstance } from 'src/services/api'

export const createCargoResponsabilidades = async (
  data: CargoResponsabilidadeDetail
) => {
  const response = await apiSauceInstance.post<any>(
    `/cargo-responsabilidade/create-many`,
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }

  return response.data
}


export const getAllCargoResponsabilidadesByCargoId = async (
  CargoId: string
) => {
  const response = await apiSauceInstance.get<any>(
    `/cargo-responsabilidade/cargo/${CargoId}`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}