import { makeAutoObservable } from 'mobx'
import { TreinamentoDetail } from 'src/modules/treinamentos/types'
import { CandidatoSelecaoDetail } from '../types'
import * as ramda from 'ramda'

class CandidatosStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  candidatosSelecionados = [] as CandidatoSelecaoDetail[]
  candidatosChecados = [] as CandidatoSelecaoDetail[]
  treinamento: TreinamentoDetail | null = null
  selecaoCandidatos = false

  isChecked(candidatoId: string) {
    return this.candidatosChecados.some(
      candidato => candidato.id === candidatoId
    )
  }

  selecionarCandidatos() {
    this.candidatosSelecionados = ramda.clone(this.candidatosChecados)
  }

  resetChecados() {
    this.candidatosChecados = ramda.clone(this.candidatosSelecionados)
  }

  adicionarCheckCandidato(candidato: CandidatoSelecaoDetail) {
    this.candidatosChecados.push(candidato)
  }

  removerCheckCandidato(candidatoId: string) {
    this.candidatosChecados = this.candidatosChecados.filter(
      candidatoArr => candidatoArr.id !== candidatoId
    )
  }

  removerCandidato(candidatoId: string) {
    this.candidatosChecados = this.candidatosChecados.filter(
      candidatoArr => candidatoArr.id !== candidatoId
    )
    this.candidatosSelecionados = this.candidatosSelecionados.filter(
      candidatoArr => candidatoArr.id !== candidatoId
    )
  }

  selectCandidato(candidato: CandidatoSelecaoDetail, checked: boolean) {
    if (checked) {
      this.adicionarCheckCandidato(candidato)
    } else {
      this.removerCheckCandidato(candidato.id)
    }
  }

  resetarInscricaoCandidatos() {
    this.candidatosSelecionados = []
    this.candidatosChecados = []
    this.treinamento = null
    this.selecaoCandidatos = false
  }

  setTreinamento(treinamento: TreinamentoDetail) {
    this.treinamento = treinamento
  }

  setSelecaoCandidatos(valor: boolean) {
    this.selecaoCandidatos = valor
  }

  resetCandidatos() {
    this.candidatosSelecionados = []
    this.candidatosChecados = []
  }
}

const candidatosStore = new CandidatosStore()

export default candidatosStore
