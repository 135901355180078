import {
  Checkbox,
  IconButton,
  ListItemSecondaryAction,
  Tooltip,
  Typography
} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { useField, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import SimpleDialog from 'src/components/SimpleDialog'
import ReactHtmlParser from 'react-html-parser'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',

      backgroundColor: theme.palette.background.default
    },
    modalVagas: {
      width: '90%',
      height: 300,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 300,

      border: '0.4px solid',
      borderColor: theme.palette.secondary.main,
      borderRadius: 4,
      padding: 20
    }
  })
)

interface DetalhesVaga {
  descricao: string
}

export interface VagaDetail {
  id: string
  descricao: string
  area: string
  categoria: string
  VagaBeneficio: DetalhesVaga[]
  VagaRequisito: DetalhesVaga[]
  VagaResponsabilidade: DetalhesVaga[]
}

interface Props {
  name: string
  label: string
  vagas: VagaDetail[]
}

export default function VagasInfo({ vagas, ...props }: Props) {
  const [field, meta, helpers] = useField(props)
  const [openModal, setOpenModal] = useState(false)
  const classes = useStyles()
  const [vagaSelectedModal, setVagaSelectedModal] = useState<VagaDetail | null>(
    null
  )
  const [selectedIndex, setSelectedIndex] = useState<string[]>([])

  const { values } = useFormikContext<{ vagasId: string[] }>()

  useEffect(()=>{
    if(values.vagasId && values.vagasId.length > 0){
      setSelectedIndex(values.vagasId)
    }
  }, [values.vagasId])
  

  const handleListItemClick = (index: string) => {
    const currentIndex = selectedIndex.findIndex(vagas => vagas === index)
    const vagasList = [...selectedIndex]

    if (currentIndex === -1) {
      vagasList.push(index)
      helpers.setValue(vagasList)
      setSelectedIndex(vagasList)
    } else {
      vagasList.splice(currentIndex, 1)
      setSelectedIndex(vagasList)
      helpers.setValue(vagasList)
    }
  }

  const handleOpenModalData = (vaga: VagaDetail) => {
    setOpenModal(!openModal)
    setVagaSelectedModal(vaga)
  }

  const vagasFormated = (vaga: DetalhesVaga[]) => {
    if (vaga.length === 0) {
      return
    }

    const vagasFormatadas = vaga.map((v, index) => {
      if (index === vaga.length - 1) {
        return v.descricao + '. '
      } else if (vaga.length > 1) {
        return v.descricao + ', '
      } else {
        return v
      }
    })
    return vagasFormatadas
  }
  return (
    <div className={classes.root}>
      <List
        className={classes.modalVagas}
        component="nav"
        aria-label="main mailbox folders"
      >
        {!!vagas.length &&
          vagas.map(vaga => (
            <>
              <ListItem
                button
                selected={selectedIndex.indexOf(vaga.id) !== -1}
                onClick={() => handleListItemClick(vaga.id)}
              >
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    edge="start"
                    checked={selectedIndex.indexOf(vaga.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  primary={`Vaga - ${vaga.categoria}`}
                  secondary={`Áreas: ${vaga.area}`}
                />
                <ListItemSecondaryAction
                  onClick={() => handleOpenModalData(vaga)}
                >
                  <Tooltip
                    key={vaga.id}
                    placement="top-start"
                    title={`Detalhes da vaga '${vaga.categoria}'`}
                  >
                    <IconButton edge="end" aria-label="comments">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>

              <Divider />
            </>
          ))}
      </List>
      <Divider />
      <SimpleDialog
        primaryActionButtonLabel="Ok"
        open={openModal}
        buttonLabel="Fechar"
        handleClose={() => {
          setOpenModal(!openModal)
        }}
        title="Informações sobre a vaga selecionada:"
      >
        {vagaSelectedModal && (
          <div>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              align="left"
            >
              <strong>Descrição da Vaga: </strong>{' '}
              {ReactHtmlParser(vagaSelectedModal.descricao)}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              align="left"
            >
              <strong>Categoria da Vaga: </strong> {vagaSelectedModal.categoria}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              align="left"
            >
              <strong>Área da Vaga: </strong> {vagaSelectedModal.area}
            </Typography>
            {!!vagaSelectedModal.VagaRequisito.length && (
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="left"
              >
                <strong>Requisitos da Vaga: </strong>
                {vagasFormated(vagaSelectedModal.VagaRequisito)}
              </Typography>
            )}
            {!!vagaSelectedModal.VagaResponsabilidade.length && (
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="left"
              >
                <strong>Responsabilidade da Vaga: </strong>
                {vagasFormated(vagaSelectedModal.VagaResponsabilidade)}
              </Typography>
            )}
            {!!vagaSelectedModal.VagaBeneficio.length && (
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="left"
              >
                <strong>Benefícios da Vaga: </strong>
                {vagasFormated(vagaSelectedModal.VagaBeneficio)}
              </Typography>
            )}
          </div>
        )}
      </SimpleDialog>
    </div>
  )
}
