import { useEffect, useState } from 'react'
import { useSnackbar } from '@elentari/core'
import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Grid, InputAdornment } from '@material-ui/core'
import { Formik, FormikValues } from 'formik'
import { useLocation, useParams } from 'react-router'
import { Skeleton } from '@material-ui/lab'
import { useVaga } from '../hooks/useVaga'
import { Paper } from '../../../components'
import { HandleErrorPage } from '../../../utils/handleErrorPage'
import { VagaCard } from '../components/VagaCard'
import ProcessoAdministrativoList from '../components/CandidatosVagaList'
import { useAppBar } from 'src/hooks'

interface Params {
  vagaId: string
}

export const VagaInfo = () => {
  const params = useParams<Params>()
  const [loading, setLoading] = useState<boolean>(false)
  const [vagasState, vagasAction] = useVaga()
  const [, appBarActions] = useAppBar()

  useEffect(() => {
    async function setAppBar() {
      if (vagasState.tag === 'with-data') {
        appBarActions.replacePaths(
          [
            {
              label: `Vagas`,
              path: `/vagas`
            }
          ],
          vagasState.entity.cargoFk?.nome ?? 'Cargo'
        )
      } else {
        appBarActions.replacePaths([], params.vagaId)
      }
    }
    setAppBar()
  }, [vagasState])

  useEffect(() => {
    async function fetchData() {
      await vagasAction.fetch(params.vagaId)
    }
    fetchData()
  }, [params.vagaId, vagasAction])

  useEffect(() => {
    return () => {
      vagasAction.reset()
    }
  }, [vagasAction])

  switch (vagasState.tag) {
    case 'initial':
      return (
        <Paper>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Paper>
      )
    case 'empty-response':
      return (
        <Paper>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Paper>
      )
    case 'error':
      return <HandleErrorPage state={vagasState.data} />
    case 'with-data':
      return (
        <>
          {vagasState.entity && (
            <>
              <VagaCard {...vagasState.entity} />
              <ProcessoAdministrativoList vagaId={vagasState.entity.id} />
            </>
          )}
        </>
      )
  }
}
