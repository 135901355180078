import { Grid, makeStyles, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import ReactHtmlParser from 'react-html-parser'
import { StatusChip } from "src/components/StatusChip"
import { TriagemDetail } from "../types"
import { getNewestTriagem } from "../utils"
import { formatMotivoTriagem } from "../utils/triagemOptions"

type TriagemActiveProps = {
  hasActiveTriagem: boolean
  Triagens: TriagemDetail[] | false | undefined
}

const useStyles = makeStyles({
  triagemContainer: {
    padding: '20px 0',
  },
  triagemLabel: {
    marginRight: 10
  }
})

export const TriagemActive = ({
  hasActiveTriagem,
  Triagens
}: TriagemActiveProps) => {

  const [newestTriagem, setNewestTriagem] = useState<TriagemDetail>()
  const classes = useStyles()

  useEffect(() => {
    if (Triagens) {
      const triagem = getNewestTriagem(Triagens)
      setNewestTriagem(triagem)
    }
  }, [Triagens])

  return (
    <Grid justifyContent="center" alignItems="center" item container spacing={2}>
      {(hasActiveTriagem && newestTriagem) && (
        <div className={classes.triagemContainer}>
          <Typography color="textSecondary">
            <strong className={classes.triagemLabel}>Status: </strong>
            <StatusChip
              label={newestTriagem.atendeCriterios ? 'Aprovado' : 'Reprovado'}
              status={newestTriagem.atendeCriterios ? 'TRUE' : 'FALSE'}
            />
          </Typography>
          <Typography color="textSecondary">
            <strong className={classes.triagemLabel}>Motivo: </strong>
            {newestTriagem.motivo ? formatMotivoTriagem(newestTriagem.atendeCriterios, newestTriagem.motivo) : '-'}
          </Typography>
          <Typography color="textSecondary">
            <strong className={classes.triagemLabel}>Triado por: </strong>
            {newestTriagem.triadoPor}
          </Typography>
          {newestTriagem.gridId && newestTriagem.gridFK && (
            <Typography color="textSecondary">
              <strong className={classes.triagemLabel}>Grid: </strong>
              {newestTriagem.gridFK.nome}
            </Typography>
          )}
          <Typography color="textSecondary">
            <strong className={classes.triagemLabel}>Outros Motivos: </strong>
            {newestTriagem.comentario && ReactHtmlParser(newestTriagem.comentario)}
          </Typography>
        </div>
      )}
    </Grid>
  )
}