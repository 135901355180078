import { Checkbox, Grid, makeStyles, Typography } from "@material-ui/core"
import { FormikValues, useFormikContext } from "formik"
import { ChangeEvent } from "react"
import DateInput from "src/components/Formik/Forms/DateInput"
import ObservacaoInput from "./ObservacaoInput"

const useStyles = makeStyles(theme => ({
  checkbox: {
    marginTop: '-10px'
  },
  alignContent: {
    display: 'flex',
    alignItems: 'center'
  },
  disabledLabel: {
    color: 'rgba(0, 0, 0, 0.40)'
  }
}))

interface ResultadoSelecaoFormItemProps {
  label: string
  fieldsNames: {
    checkbox: string
    data: string
    observacao: string
  }
  disabled: boolean
}

export const ResultadoSelecaoFormItem = ({
  label,
  fieldsNames,
  disabled
}: ResultadoSelecaoFormItemProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>()
  const classes = useStyles()

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value === 'true'

    setFieldValue(fieldsNames.checkbox, eventValue)
  }

  return (
    <>
      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={6} className={classes.alignContent}>
            <Typography variant="body2" className={disabled ? classes.disabledLabel : undefined}>
              {label}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Checkbox
              checked={values[fieldsNames.checkbox] === true}
              value={true}
              onChange={handleChangeCheckbox}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={3}>
            <Checkbox
              checked={values[fieldsNames.checkbox] === false}
              value={false}
              onChange={handleChangeCheckbox}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DateInput
              label="Data"
              name={fieldsNames.data}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <ObservacaoInput
              label="Observação"
              name={fieldsNames.observacao}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}