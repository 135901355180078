import { useSnackbar } from '@elentari/core'
import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Button, CircularProgress, createStyles, Divider, Grid, makeStyles } from '@material-ui/core'
import { Formik, FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Paper } from 'src/components'
import RichTextInput from 'src/components/Formik/Forms/RichTextInput'
import { NavigationButton } from 'src/components/NavigationButton'
import { useAppBar } from 'src/hooks'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import { isAnalistaRh, isG10 } from 'src/utils'
import { ExperienciaProfMotoristaSchemaStep } from 'src/utils/yup-schemas/candidato/expProfStepSchema'
import { CandidatoSchemaStepMotorista } from 'src/utils/yup-schemas/candidato/motoristaStepSchema'
import * as Yup from 'yup'
import { FormHeader } from '../../components/FormHeader'
import HistoricoCandidato from '../../components/HistoricoCandidato'
import { ReturnButton } from '../../components/ReturnButton'
import { TriagemActive } from '../../components/TriagemActive'
import { TriagemButtons } from '../../components/TriagemButtons'
import { uploadManyDocumentos } from '../../hooks/documentosRepository'
import CandidatoStep from '../../sharedSteps/CandidatoStep'
import ExperienciaProfeStep from '../../sharedSteps/ExperienciaProfStep'
import triagemStore from '../../store/store'
import { objectRemoveEmptyStrings } from '../../utils'
import { repository as motoristaRepository } from '../hooks/motoristaRepository'
import { useMotorista } from '../hooks/useMotorista'
import { SaveMotoristaInput } from '../types'
import { initialValues } from '../utils/initialValues'

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '160px'
      }
    },
    divider: {
      marginTop: 30,
      marginBottom: 30
    },
    marginBottom: {
      marginBottom: 20
    },
    fullHeight: {
      height: '100%'
    }
  })
)

export const TriagemMotoristaForm = () => {
  const [motoristaState, actions] = useMotorista()
  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()

  const history = useHistory()
  const location = useLocation()
  const userLogged = useLoggedUser()
  const isAnalista = isAnalistaRh(userLogged?.rolesInSystem)
  const userEmpresaIsG10 = isG10(userLogged?.empresa)

  const [showHistorico, setShowHistorico] = useState<boolean>(false)
  const classes = useStyles()

  const { save } = useEntitySaver<SaveMotoristaInput>(data =>
    motoristaRepository.save(data)
  )

  const formikState = {
    initialValues,
    onSubmit: async (data: FormikValues) => {
      try {
        data.tipo = 'MOTORISTA'
        if (!data.Motorista.indicacao) {
          data.Motorista.quemIndicou = ''
          data.Motorista.cargoQuemIndicou = ''
          data.Motorista.coordenadorQuemIndicou = ''
        }

        const documentos = await uploadManyDocumentos([
          { ...data.anexoCurriculo, descricao: 'anexoCurriculo', candidatoId: data.id },
          { ...data.anexoCNH, descricao: 'anexoCNH', candidatoId: data.id }
        ])
        if (!documentos) {
          return snackbarActions.setMessage("Ocorreu um erro ao salvar o(s) documento(s)")
        }

        delete data.anexoCurriculo
        delete data.anexoCNH

        const dataFormatted = objectRemoveEmptyStrings(data)
        const response = await save({
          ...dataFormatted,
          documentos
        } as SaveMotoristaInput)
        if (response.status === 200) {
          if (response.data?.id) {
            actions.fetch(response.data.id)
          }
          appBarActions.setTitle(data.nomeCompleto)
        }
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  useEffect(() => {
    if (motoristaState.tag === 'with-data') {
      if (userLogged?.empresa !== motoristaState.entity.empresaFK?.sigla) {
        history.push('/')
      }
    }
  }, [motoristaState])

  useEffect(() => {
    return actions.reset()
  }, [actions])

  const scrollTop = () => {
    const mainContainer = document.querySelector('main')
    if (mainContainer) {
      mainContainer.scrollTo({
        top: 0
      })
    }
  }

  const handleNextCandidato = () => {
    const nextCandidato = triagemStore.nextCandidatoId()
    if (nextCandidato) {
      history.push(`/${location.pathname.split('/')[1]}/${nextCandidato}`)
      scrollTop()
    } else {
      snackbarActions.setMessage('Não possui mais candidatos na lista')
    }
  }

  const handlePrevious = () => {
    triagemStore.previousCandidatoId()
    history.goBack()
    scrollTop()
  }

  return (
    <>
      <Paper noTopBorderRadius>
        <Grid container spacing={2} direction="column" className={classes.marginBottom}>
          <Formik
            onSubmit={formikState.onSubmit}
            validationSchema={Yup.object().shape({
              ...CandidatoSchemaStepMotorista(isAnalista).fields,
              ...ExperienciaProfMotoristaSchemaStep(isAnalista, userEmpresaIsG10).fields
            })}
            enableReinitialize={true}
            initialValues={
              motoristaState.tag === 'with-data'
                ? motoristaState.entity
                : formikState.initialValues
            }
          >
            {({ isSubmitting, handleSubmit }) => (
              <div>
                <FormHeader
                  header
                  disableButtons={false}
                  showReturnButton={false}
                  isTriagem={true}
                  candidatoData={motoristaState.tag === 'with-data' && motoristaState.entity}
                />

                <CandidatoStep
                  candidatoType="Motorista"
                  label="Candidato(a)"
                  validationSchema={CandidatoSchemaStepMotorista}
                  isTriagem={true}
                />

                <ExperienciaProfeStep
                  label="Experiência Profissional"
                  candidatoType="Motorista"
                  validationSchema={ExperienciaProfMotoristaSchemaStep}
                />

                {motoristaState.tag === 'with-data' && motoristaState.entity.statusTriagem === 'APROVADO' && (
                  <RichTextInput
                    label="Observações sobre Processo"
                    name="observacaoProcesso"
                  />
                )}

                <Divider className={classes.divider} />

                <TriagemActive
                  hasActiveTriagem={motoristaState.tag === 'with-data' && Boolean(motoristaState.entity.statusTriagem)}
                  Triagens={motoristaState.tag === 'with-data' && motoristaState.entity.Triagem}
                />

                <TriagemButtons
                  candidatoId={motoristaState.tag === 'with-data' && motoristaState.entity.id}
                  candidatoCpf={motoristaState.tag === 'with-data' && motoristaState.entity.cpf}
                  candidatoBloqueio={motoristaState.tag === 'with-data' && Boolean(motoristaState.entity.idListaBloqueio)}
                  candidatoAprovado={motoristaState.tag === 'with-data' && motoristaState.entity.statusTriagem === 'APROVADO'}
                  candidatoFirstAgendamento={motoristaState.tag === 'with-data' && motoristaState.entity.AgendamentoCandidato !== undefined && motoristaState.entity.AgendamentoCandidato.length === 0}
                  candidatoType={"MOTORISTA"}
                  historicoState={{ state: showHistorico, action: setShowHistorico }}
                />

                <Divider className={classes.divider} />

                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <NavigationButton
                      label="Anterior"
                      direction="left"
                      disabled={isSubmitting}
                      onClick={() => handlePrevious()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Grid container className={classes.fullHeight} justifyContent='center' alignContent="center" >
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={isSubmitting}
                        onClick={() => handleSubmit()}
                        type="submit"
                      >
                        {isSubmitting ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          <>Salvar</>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Grid container justifyContent='flex-end' spacing={2}>
                      <Grid item xs={12}>
                        <Grid container justifyContent='flex-end'>
                          <NavigationButton
                            label="Próximo"
                            direction="right"
                            disabled={isSubmitting}
                            onClick={() => handleNextCandidato()}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <ReturnButton disableButtons={isSubmitting} />
                  </Grid>
                </Grid>
              </div>
            )}
          </Formik>
        </Grid>
      </Paper>
      <HistoricoCandidato
        open={showHistorico}
        candidatoId={motoristaState.tag === 'with-data' && motoristaState.entity.id}
        Triagens={motoristaState.tag === 'with-data' && motoristaState.entity.Triagem}
      />
    </>
  )
}
