import { useSnackbar } from '@elentari/core'
import {
  Box,
  Button,
  CardContent,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import { Skeleton } from '@material-ui/lab'
import { FieldArray, FieldArrayRenderProps, Formik, FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import AutoCompleteInput from 'src/components/Formik/Forms/AutoCompleteInput'
import * as yup from 'yup'
import { MessagesYup } from '../../messages'
import { FormWrap, Paper } from '../../../components'
import {
  createCargoCandidato,
  fetchCargoCandidatoByCandidatoId
} from '../hooks/cargoCandidatoRepository'
import { CargoCandidatoDetail, CargoCandidatoFormData } from '../types'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import TextInput from 'src/components/Formik/Forms/TextInput'
import { CargoRepository } from 'src/modules/cargos/cargo/hooks/CargoRepository'
import { handleKeyCodes } from 'src/modules/options'
import React from 'react'
import { PaperCard } from 'src/components/PaperCard'

interface Option {
  id: string
  name: string
}

type Props = {
  id: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cargosCandidatoContainer: {
      maxHeight: '203px',
      overflow: 'scroll'
    },
    selectContainer: {
      paddingBottom: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'start'
    },
  })
)

export const CargoCandidatoCard = ({ id }: Props) => {
  const [cargosCandidato, setCargosCandidato] = useState<
    CargoCandidatoDetail[]
  >([])
  const [cargos, setCargos] = useState<Option[]>([])
  const [editFields, setEditFields] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingCargos, setLoadingCargos] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const classes = useStyles()
  const cargosCandidatoContainerRef: React.RefObject<HTMLDivElement> =
    React.createRef()
  const addButtonRef: React.RefObject<HTMLButtonElement> = React.createRef()

  useEffect(() => {
    async function fetchAllCargosCandidato() {
      if (id) {
        setLoading(true)
        const response = await fetchCargoCandidatoByCandidatoId(id)
        if (response?.ok) {
          setCargosCandidato(
            response?.info.edges.map(cargoCandidato => {
              return {
                aderencia: cargoCandidato.node.aderencia,
                cargoId: cargoCandidato.node.cargoId,
                cargoFK: {
                  nome: `${cargoCandidato.node.cargoFK?.nome}`
                }
              }
            })
          )
        }
        setLoading(false)
      }
    }
    fetchAllCargosCandidato()
  }, [id])

  useEffect(() => {
    async function fetchAllCargos() {
      setLoadingCargos(true)
      const response = await CargoRepository.fetch({ limit: '999' })
      if (response.ok) {
        setCargos(
          response.data?.edges.map(cargo => {
            return {
              id: cargo.node.id,
              name: cargo.node.nome
            }
          }) ?? []
        )
      }
      setLoadingCargos(false)
    }

    if (editFields && cargos.length <= 0) {
      fetchAllCargos()
    }
  }, [editFields])

  const schemaObject = {
    CargosCandidato: yup
      .array()
      .of(
        yup.object().shape({
          aderencia: yup
            .number()
            .min(1, MessagesYup.MENSAGEM_VALOR_ZERO)
            .max(100, 'Valor máximo permitido é 100')
            .required(MessagesYup.MENSAGEM_OBRIGATORIO)
            .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO),
          cargoId: yup
            .string()
            .required(MessagesYup.MENSAGEM_OBRIGATORIO)
            .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
        })
      )
      .test('unique-cargo', function (CargosCandidato: any) {
        const cargosIds = CargosCandidato.map(
          (cargoCandidato: any) => cargoCandidato.cargoId
        )
        const numberDiffCargosCandidato = new Set(cargosIds).size

        if (cargosIds.length !== numberDiffCargosCandidato) {
          const arrRepetidos: number[] = []
          cargosIds.filter((cargoId: string, index: number) => {
            if (cargosIds.indexOf(cargoId) !== index) {
              arrRepetidos.push(index)
            }

            return cargosIds.indexOf(cargoId) == index
          })

          return this.createError({
            path: `CargosCandidato[${arrRepetidos[0]}].cargoId`,
            message: 'Não podem haver cargos repetidos'
          })
        } else {
          return true
        }
      })
  }
  const schema = yup.object().shape(schemaObject)

  const formikState = {
    initialValues: {
      CargosCandidato: []
    },
    onSubmit: async (data: FormikValues) => {
      try {
        setLoading(true)
        const response = await createCargoCandidato({
          ...data,
          candidatoId: id
        } as CargoCandidatoFormData)

        if (!response.ok) {
          snackbarActions.setMessage(response.message)
        } else {
          setCargosCandidato(response?.info)
          setEditFields(false)
          snackbarActions.setMessage('Salvo com sucesso!')
        }
        setLoading(false)
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  const handleAddCargoCandidato = (arrayHelpers: FieldArrayRenderProps) => {
    return () => {
      arrayHelpers.push({
        aderencia: 0,
        cargoId: ''
      })

      setTimeout(() => {
        setTimeout(() => {
          cargosCandidatoContainerRef.current?.scrollTo({
            top: addButtonRef.current?.offsetTop,
            behavior: 'smooth'
          })
        }, 10)
      }, 10)
    }
  }

  const handleDeleteCargoCandidato = (
    index: number,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    return () => {
      arrayHelpers.remove(index)
    }
  }

  return (
    <Box>
      <PaperCard
        title="Cargos Aderências"
        disableScroll
        primaryButtonText={
          editFields
            ? 'Cancelar Edição'
            : cargosCandidato?.length !== 0
            ? 'Editar Cargos Aderências'
            : 'Adicionar Cargos Aderências'
        }
        primaryButtonAction={() => setEditFields(!editFields)}
        primaryButtonIcon={
          editFields ? (
            <CancelIcon />
          ) : cargosCandidato?.length !== 0 ? (
            <EditIcon />
          ) : (
            <AddIcon />
          )
        }
        Content={
          editFields ? (
            <Formik
              validationSchema={schema}
              onSubmit={formikState.onSubmit}
              initialValues={
                cargosCandidato.length !== 0
                  ? {
                      CargosCandidato: cargosCandidato
                    }
                  : formikState.initialValues
              }
              enableReinitialize
            >
              {({ handleSubmit, isSubmitting, values }) => (
                <Box marginTop={0}>
                  <FormWrap
                    handleSubmit={handleSubmit}
                    disableSubmit={isSubmitting || loadingCargos}
                    disableBack={isSubmitting}
                    loading={loading}
                    hideGoBack={true}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        className={classes.cargosCandidatoContainer}
                        ref={cargosCandidatoContainerRef}
                      >
                        {!loadingCargos ? (
                          <FieldArray
                            name={'CargosCandidato'}
                            validateOnChange={true}
                          >
                            {arrayHelpers => (
                              <Grid item xs={12}>
                                {values.CargosCandidato?.map(
                                  (
                                    cargoCandidato: { aderencia: number },
                                    index
                                  ) => (
                                    <Grid
                                      container
                                      key={index}
                                      className={classes.selectContainer}
                                      spacing={2}
                                    >
                                      <Grid item xs={4}>
                                        <TextInput
                                          name={`CargosCandidato[${index}].aderencia`}
                                          label={`${index + 1}° Aderência`}
                                          type="number"
                                          value={cargoCandidato.aderencia.toString()}
                                          onKeyDown={handleKeyCodes}
                                          inputProps={{
                                            min: '0',
                                            max: '100',
                                            step: '1'
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <AutoCompleteInput
                                          name={`CargosCandidato[${index}].cargoId`}
                                          label={`Cargo`}
                                          options={cargos}
                                        />
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Tooltip
                                          placement="top"
                                          title={`Deletar ${
                                            index + 1
                                          }° Cargo Aderência`}
                                        >
                                          <Button
                                            style={{
                                              flex: 1,
                                              height: 56,
                                              border: 'none'
                                            }}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleDeleteCargoCandidato(
                                              index,
                                              arrayHelpers
                                            )}
                                          >
                                            <DeleteOutlineIcon />
                                          </Button>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  )
                                )}
                                <Tooltip
                                  placement="right-start"
                                  title="Adicionar Cargo Aderência"
                                >
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleAddCargoCandidato(
                                      arrayHelpers
                                    )}
                                    ref={addButtonRef}
                                  >
                                    <AddIcon />
                                  </Button>
                                </Tooltip>
                              </Grid>
                            )}
                          </FieldArray>
                        ) : (
                          <Box>
                            <Skeleton />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </FormWrap>
                </Box>
              )}
            </Formik>
          ) : loading ? (
            <Box marginTop={2}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          ) : cargosCandidato?.length === 0 ? (
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              align="left"
            >
              <strong>
                Não foi adicionado nenhuma aderência para este candidato
              </strong>
            </Typography>
          ) : (
            <div className={classes.cargosCandidatoContainer}>
              {cargosCandidato.map(cargoCandidato => {
                return (
                  <Typography color="textSecondary" align="left">
                    <strong>Aderência: </strong>
                    {`${cargoCandidato.aderencia.toLocaleString('pt-BR', {
                      minimumIntegerDigits: 2,
                      useGrouping: false
                    })}%`}

                    <strong> Cargo: </strong>
                    {cargoCandidato.cargoFK?.nome}
                  </Typography>
                )
              })}
            </div>
          )
        }
      />
    </Box>
  )
}
