import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useSnackbar } from '@elentari/core'
import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/PictureAsPdf'
import { Skeleton } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useLocation } from 'react-router'
import { Paper } from 'src/components'
import { useAppBar } from 'src/hooks'
import { MessagesYup } from 'src/modules/messages'
import * as yup from 'yup'
import yupValidation from '../../../utils/yupValidation'
import { getInfoProcessoAdministrativo, repository } from '../hooks/processoAdministrativoRepository'
import { useProcessoAdministrativo } from '../hooks/useProcessoAdministrativo'
import {
  ProcessoSeletivoAdministrativoDetail,
  ProcessoSeletivoAdministrativoFormData,
  statusProcessoAdministrativo,
  tipoReprovacao
} from '../types'
import VagasRFFAutocomplete from '../../vagas/VagasRFFAutocomplete'
import { handleKeyCodes } from 'src/modules/options'
import RichTextField from 'src/components/Forms/RichTextField'
import ComplexDialog from 'src/components/ComplexDialog'
import { handleProcessoDataToPDF } from '../utils/configPDF'
import { useCandidato } from '../hooks/useCandidato'
import { isNil } from 'ramda'
import { ProcessoAdministrativoModalDetails } from '../components/ProcessoAdministrativoModalDetails'
import { useLoggedUser } from 'src/modules/login/AuthProvider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2)
      }
    },
    cardInicial: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingRight: 16,
      marginTop: '30px'
    },
    processoContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingRight: 16
    },
    processoInfo: {
      width: 'fill-available'
    },
    cardIcons: {
      display: 'flex',
      flexDirection: 'column'
    }
  })
)
interface ProcessoProps {
  candidatoAdmId: string
  showIcons: boolean
}

const ProcessoAdministrativoForm = ({
  candidatoAdmId,
  showIcons
}: ProcessoProps) => {
  const [, appBarActions] = useAppBar()
  const [candidatoId, setCandidatoId] = useState(candidatoAdmId)
  const [editFields, setEditFields] = useState(false)

  const classes = useStyles()
  const [state, actions] = useProcessoAdministrativo()
  const [, candidatoActions] = useCandidato()
  const location = useLocation()
  const pathname = location.pathname
  const isNewProcesso = pathname.includes('/new')

  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const userLogged = useLoggedUser()

  const [confirmationDialog, setConfirmationDialog] = useState<{
    open: boolean
    formData: ProcessoSeletivoAdministrativoDetail | undefined
  }>({
    open: false,
    formData: undefined
  })

  const handleCloseDialog = () => {
    setConfirmationDialog({
      ...confirmationDialog,
      open: false
    })
  }

  const handleConfirmDialog = async (data: any) => {
    await save({
      ...confirmationDialog.formData,
      ...data
    })

    candidatoActions.fetch(candidatoId)
  }

  const { save } = useEntitySaver<ProcessoSeletivoAdministrativoFormData>(
    async data => {
      setLoading(true)

      const response = await repository.save(data)
      setTimeout(() => {
        setLoading(false)
        setEditFields(false)
      }, 1000)

      return response
    }
  )

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      state,
      u => u.entity.id,
      u => u.entity.candidatoFK?.nomeCompleto || ''
    )
  ])

  useEffect(() => {
    if (!pathname.includes('/new')) {
      const id = pathname.split('/')[2]
      actions.fetch(id)
    }

    return () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      actions.reset()
    }
  }, [actions, pathname, editFields])

  useEffect(() => {
    if (pathname.includes('/new')) {
      if (location.state && (location.state as any).candidatoId) {
        setCandidatoId((location.state as any).candidatoId)
      }
    }

    if (state.tag === 'with-data') {
      setCandidatoId(state.entity.candidatoId)
    }
  }, [state, candidatoId, location, pathname])

  const handleSubmit = async (data: any) => {
    try {
      data.candidatoId = candidatoId
      data.aderencia = Number(data.aderencia)
      delete data.empresaFK
      delete data.candidatoFK
      delete data.vagasFK

      if (isNewProcesso) {
        data.criadoPor = userLogged?.name
      }

      if (data.status === 'REPROVADO' && (state.tag === 'with-data' && state.entity.status !== 'REPROVADO')) {
        setConfirmationDialog({
          open: true,
          formData: data
        })
      } else {
        if (data.status !== 'REPROVADO') {
          data.motivoReprovacao = null
          data.tipoReprovacao = null
        }
        await save(data)
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  const handleProcessoToPDF = async () => {
    try {
      if (state.tag === 'with-data') {
        const response = await getInfoProcessoAdministrativo(state.entity.id)
        if (response && response.ok) {
          handleProcessoDataToPDF(response.info)
        }
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  const schemaObject = {
    aderencia: yup
      .number()
      .min(0, MessagesYup.MENSAGEM_VALOR_ZERO)
      .max(100, 'Valor máximo permitido é 100')
      .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO)
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    vagasId: yup
      .string()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    comentario: yup
      .string()
      .nullable()
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    motivoReprovacao: yup
      .string()
      .nullable()
      .when(['tipoReprovacao', 'status'], {
        is: (tipoReprovacao: string, status: string) => !isNil(tipoReprovacao) && status === 'REPROVADO',
        then: yup.string()
          .trim()
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
  }
  const schema = yup.object().shape(schemaObject)

  const dialogSchema = yup.object().shape({
    tipoReprovacao: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    motivoReprovacao: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
  })

  return (
    <>
      <Box>
        <Paper minHeight={340} maxHeight={340}>
          <div
            style={{
              maxHeight: '340px',
              overflow: 'scroll'
            }}
          >
            <CardContent>
              {state.tag === 'with-data' && (
                <div className={classes.cardInicial}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 'bold' }}
                    gutterBottom
                  >
                    Processo Seletivo
                  </Typography>
                  {showIcons && (
                    <Tooltip
                      title={editFields ? 'Cancelar Edição' : 'Editar Processo'}
                    >
                      <IconButton
                        aria-label="edit"
                        onClick={() => setEditFields(!editFields)}
                        color="primary"
                      >
                        {editFields ? (
                          <CancelIcon />
                        ) : (
                          !isNewProcesso && <EditIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              )}
              {editFields || isNewProcesso ? (
                <Form
                  validate={yupValidation(schema)}
                  onSubmit={handleSubmit}
                  initialValues={state.tag === 'with-data' ? state.entity : {}}
                >
                  {({ handleSubmit, submitting, values }) => (
                    <Box marginTop={4}>
                      <form onSubmit={handleSubmit} noValidate>
                        <Grid container spacing={2}>
                          <Grid item sm={6} md={editFields ? 6 : 3} xs={12}>
                            <Select
                              fullWidth
                              name="status"
                              label="Status"
                              defaultValue="AGENDAMENTO"
                              items={
                                isNewProcesso
                                  ? statusProcessoAdministrativo.filter(
                                    processo =>
                                      processo.value === 'AGENDAMENTO'
                                  )
                                  : statusProcessoAdministrativo
                              }
                            />
                          </Grid>
                          <Grid item sm={6} md={editFields ? 6 : 3} xs={12}>
                            <TextField
                              fullWidth
                              disabled
                              value={
                                isNewProcesso
                                  ? userLogged?.name
                                  : values.criadoPor
                              }
                              name="criadoPor"
                              label="Criado por"
                            />
                          </Grid>
                          <Grid item sm={6} md={editFields ? 6 : 3} xs={12}>
                            {editFields ? (
                              <TextField
                                fullWidth
                                name="vagasFK.cargoFk.nome"
                                label="Vaga"
                                disabled
                              />
                            ) : (
                              <VagasRFFAutocomplete />
                            )}
                          </Grid>
                          <Grid item sm={6} md={editFields ? 6 : 3} xs={12}>
                            <TextField
                              fullWidth
                              name="aderencia"
                              type="number"
                              label="Aderência"
                              inputProps={{ min: 0, max: 100 }}
                              onKeyDown={handleKeyCodes}
                            />
                          </Grid>
                          {values.tipoReprovacao && (
                            <Grid item xs={12}>
                              <RichTextField
                                name="motivoReprovacao"
                                label="Motivo Reprovação"
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <RichTextField
                              name="comentario"
                              label="Comentário"
                            />
                          </Grid>
                        </Grid>
                        <Grid justify="flex-end" container spacing={2}>
                          <Grid item style={{ width: 160 }}>
                            {loading ? (
                              <Button
                                data-testid="salvar"
                                fullWidth
                                disabled={true}
                                variant="contained"
                                color="primary"
                              >
                                <div className={classes.root}>
                                  <CircularProgress color="inherit" size={24} />
                                </div>
                              </Button>
                            ) : (
                              <Button
                                data-testid="salvar"
                                fullWidth
                                type="submit"
                                disabled={submitting}
                                variant="contained"
                                color="primary"
                              >
                                Salvar
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                        <ComplexDialog
                          open={confirmationDialog.open}
                          title="Descreva o Motivo da Reprovação do(a) Candidato(a)"
                          handleClose={handleCloseDialog}
                          primaryAction={handleConfirmDialog}
                          validate={yupValidation(dialogSchema)}
                          initialValues={
                            state.tag === 'with-data' && {
                              tipoReprovacao: state.entity.tipoReprovacao,
                              motivoReprovacao: state.entity.motivoReprovacao
                            }
                          }
                          disableBackdropClick={true}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Select
                                fullWidth
                                name="tipoReprovacao"
                                label="Tipo de Reprovação"
                                items={tipoReprovacao}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <RichTextField
                                name="motivoReprovacao"
                                label="Motivo Reprovação"
                              />
                            </Grid>
                          </Grid>
                        </ComplexDialog>
                      </form>
                    </Box>
                  )}
                </Form>
              ) : state.tag === 'with-data' ? (
                <div className={classes.processoContainer}>
                  <div className={classes.processoInfo}>
                    <ProcessoAdministrativoModalDetails processoAdministrativo={state.entity} />
                  </div>
                  <div className={classes.cardIcons}>
                    {showIcons && state.entity.status === 'CONCLUSAO' && (
                      <Tooltip
                        title="Gerar Resumo do Processo Seletivo em PDF"
                      >
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleProcessoToPDF()}
                          color="primary"
                        >
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </div>
              ) : (
                <Box marginBottom={2}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </Box>
              )}
            </CardContent>
          </div>
        </Paper>
      </Box>
    </>
  )
}

export default ProcessoAdministrativoForm
