import { useSnackbar } from '@elentari/core'
import { Button } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import EmailIcon from '@material-ui/icons/FileCopy'

type Props = {
  link: string
}

export const ClipboardButton = ({ link }: Props) => {
  const [, snackbarActions] = useSnackbar()

  const handleClipboard = () => {
    navigator.clipboard.writeText(link)
    snackbarActions.setMessage('Link copiado com sucesso!')
  }

  return (
    <Tooltip
      placement="top"
      title="Copiar o link para o candidato(a) anexar documentos"
    >
      <Button onClick={handleClipboard}>
        <EmailIcon />
      </Button>
    </Tooltip>
  )
}
