import { apiSauceInstance } from 'src/services/api'
import { FileData } from '../types'

export const fetchValidTokenByIdAndReturnDocuments = async (
  token_id: string
) => {
  const response = await apiSauceInstance.get<any>(
    `/documentos-administrativos/valid-token-candidato/${token_id}`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }

  return {
    info
  }
}

export const saveUploadS3 = async (data: FileData) => {
  const formData = new FormData()
  if (data.file) formData.append('upload', data.file)

  const response = await apiSauceInstance.post<any>('/uploads/file', formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const updateDocumentsById = async (
  id: string,
  url: string,
  token: string
) => {
  const response = await apiSauceInstance.patch<any>(
    `/documentos-administrativos/external-update`,
    { url, document_id: id, token }
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }

  return {
    info
  }
}
