import {
  Button,
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import LinkMD from '@material-ui/core/Link'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { apiSauceInstance } from 'src/services/api'
import { FormatedDate } from 'src/utils'
import {
  AddRecord,
  NoResultsTable,
  Paper,
  TableContainer
} from '../../components'
import { TableCellHead } from '../../components/TableCellHead'
import { AgendamentoFormData } from './types'
import QueryString from 'qs'

interface Props {
  list: AgendamentoFormData[]
  loading: boolean
  totalCount: number
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
}

const useStyles = makeStyles({
  returnButton: {
    top: '-1rem',
    left: '-1rem',
    marginBottom: '1rem'
  }
})

export const AgendamentoAdministrativoTable = ({
  list,
  loading,
  totalCount
}: Props) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const style = useStyles()

  return (
    <>
      <Paper>
        <Button
          className={style.returnButton}
          color="default"
          onClick={() =>
            history.push(
              `/processo-seletivo-administrativo/${pathname.split('/')[2]}`
            )
          }
          startIcon={<ArrowBackIcon />}
        >
          Retornar para o Processo de Contratação
        </Button>
        <TableContainer
          loading={loading}
          totalCount={totalCount}
          disablePagination={true}
        >
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead
                    label="Data de Agendamento"
                    sort="dataCandidatoAgendado"
                  />
                  <TableCellHead label="Agendado por" sort="agendadoPor" />
                  <TableCellHead label="Compareceu" sort="compareceu" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(agendamento => (
                  <TableRow hover key={agendamento.id}>
                    <TableCell>
                      <LinkMD
                        component={Link}
                        to={`agendamentos/${agendamento.id}`}
                      >
                        {FormatedDate(agendamento.dataCandidatoAgendado)}
                      </LinkMD>
                    </TableCell>
                    <TableCell>{agendamento.agendadoPor}</TableCell>
                    <TableCell>
                      {agendamento.compareceu ? 'Sim' : 'Não'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="agendamentos" />
    </>
  )
}

type APIResponseAgendamentos = {
  edges: AgendamentoFormData[]
  totalCount: number
  loading: boolean
}

const AgendamentoAdministrativo = () => {
  const [processoState, setProcessoState] = useState<APIResponseAgendamentos>(
    {} as APIResponseAgendamentos
  )
  const { search, pathname } = useLocation()
  const [skip, setSkip] = useState(0)

  useEffect(() => {
    const [, number] = search.split('page=')

    if (number) {
      setSkip((+number - 1) * 10)
    }
  }, [search])

  useEffect(() => {
    async function loadProcessosById() {
      try {
        setProcessoState({
          ...processoState,
          loading: true
        })

        const params = {
          processoSeletivoAdministrativoId: pathname.split('/')[2],
          sort: { createdAt: 'desc' },
          ...QueryString.parse(search.substring(1))
        }

        const { data } = await apiSauceInstance.get<any>(
          `agendamento-administrativos/processos?${QueryString.stringify(
            params
          )}`
        )

        if (data) {
          setProcessoState({
            totalCount: data.totalCount,
            edges: data.edges,
            loading: false
          })
        }
      } catch (error) {}
    }
    loadProcessosById()
  }, [skip, pathname, search])

  return (
    <>
      {processoState.loading === false ? (
        processoState.totalCount > 0 ? (
          <AgendamentoAdministrativoTable
            list={processoState.edges}
            totalCount={processoState.totalCount}
            loading={processoState.loading}
          />
        ) : (
          <AgendamentoAdministrativoTable
            list={[]}
            totalCount={0}
            loading={false}
          />
        )
      ) : (
        <Paper>
          <LinearProgress />
        </Paper>
      )}
    </>
  )
}

export default AgendamentoAdministrativo
