import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { ParsedQs } from 'qs'
import { apiSauceInstance } from '../../../services/api'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { SaveCandidatoTreinamentoInput, CandidatoTreinamentoDetail } from '../types'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, limit, ...rest } = params

  return {
    ...paginationParams(rest),
    sort: sort || {
      createdAt: 'desc'
    },
    limit: limit || '10'
  }
}

export const candidatosTreinamentosRepository = makeRepository<
  EdgesPage<CandidatoTreinamentoDetail>,
  SaveCandidatoTreinamentoInput,
  CandidatoTreinamentoDetail,
  string
>({ resource: 'candidatos-treinamentos', mapQueryParams }, apiSauceInstance)
