import React, { useRef } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Form } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple'

const useStyles = makeStyles(theme => ({
  content: {
    paddingBottom: theme.spacing(3)
  },
  contentWithField: {
    paddingBottom: theme.spacing(3),
    height: 320
  },
  btn: {
    width: 160
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'baseline'
  },
  contentMessage: {
    padding: 5
  },
  contentAction: {
    padding: 5,
    fontStyle: 'italic'
  }
}))

type Props = {
  open: boolean
  handleClose: () => void
  closeActionButtonLabel?: string
  primaryAction: (data: any) => void
  primaryActionButtonLabel?: string
  hidePrimaryActionButton?: boolean
  title: string
  message?: string
  action?: string
  validate?: any
  children: any
  holdWhileLoading?: boolean
  disableBackdropClick?: boolean
  transitionDuration?: number | { appear?: number, enter?: number, exit?: number },
  initialValues?: any
  hideTitle?: boolean
  maxWidth?: false | "lg" | "xs" | "sm" | "md" | "xl"
}

const ComplexDialog = (props: Props) => {
  const {
    open,
    handleClose,
    closeActionButtonLabel = 'Cancelar',
    primaryAction,
    primaryActionButtonLabel = 'Confirmar',
    hidePrimaryActionButton,
    title,
    message,
    action,
    validate,
    children,
    holdWhileLoading = true,
    disableBackdropClick,
    transitionDuration,
    initialValues,
    hideTitle,
    maxWidth
  } = props
  const classes = useStyles()
  const rippleRef = React.useRef<any>()
  const handlePrimaryAction = async (data: any) => {
    if (holdWhileLoading) {
      await primaryAction(data)
    } else {
      primaryAction(data)
    }
    handleClose()
  }
  const handleOnBackdropClick = () => {
    if (disableBackdropClick) {
      if (rippleRef && rippleRef.current) {
        rippleRef.current.start();
        setTimeout(() => {
          rippleRef.current.stop({});
        }, 100)
      }
    }
  }
  const hasContent = Array.isArray(children) && children.some(v => !!v)
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick={disableBackdropClick}
        onBackdropClick={handleOnBackdropClick}
        transitionDuration={transitionDuration}
        maxWidth={maxWidth}
      >
        {!hideTitle && (
          <DialogTitle>{title}</DialogTitle>
        )}
        <Form onSubmit={handlePrimaryAction} validate={validate} initialValues={initialValues}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              {children && (
                <DialogContent
                  className={
                    hasContent ? classes.contentWithField : classes.content
                  }
                >
                  {children}
                </DialogContent>
              )}
              {(message || action) && (
                <DialogContent className={classes.messageContainer}>
                  {message &&
                    <Typography className={classes.contentMessage}>{message}</Typography>
                  }
                  {action &&
                    <Typography className={classes.contentAction}>{action}</Typography>
                  }
                </DialogContent>
              )}
              <DialogActions>
                <Button
                  className={classes.btn}
                  onClick={handleClose}
                  variant="outlined"
                  autoFocus
                >
                  {closeActionButtonLabel}
                  <TouchRipple ref={rippleRef} />
                </Button>
                {!hidePrimaryActionButton && (
                  <Button
                    className={classes.btn}
                    type="submit"
                    disabled={submitting}
                    color="primary"
                    variant="contained"
                    autoFocus
                  >
                    {submitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      primaryActionButtonLabel
                    )}
                  </Button>
                )}
              </DialogActions>
            </form>
          )}
        </Form>
      </Dialog>
    </div>
  )
}

export default ComplexDialog
