import { useSnackbar } from "@elentari/core"
import { Checkbox, Grid, makeStyles, Typography } from "@material-ui/core"
import { useState } from "react"
import { useLoggedUser } from "src/modules/login/AuthProvider"
import { isG10 } from "src/utils"
import { updateAgendamento } from "../../agendamento-candidato/hooks/agendamentoCandidatoRepository"
import { TipoEntrevista } from "../../agendamento-candidato/types"

const useStyles = makeStyles(theme => ({
  alignContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  disabledLabel: {
    color: 'rgba(0, 0, 0, 0.40)'
  }
}))

type TipoEntrevistaSelecaoProps = {
  agendamentoId: string | undefined
  tipoEntrevista: TipoEntrevista | undefined
}

export const TipoEntrevistaSelecao = ({
  agendamentoId,
  tipoEntrevista
}: TipoEntrevistaSelecaoProps) => {
  const [tipoEntrevistaValue, setTipoEntrevistaValue] = useState<TipoEntrevista | undefined>(tipoEntrevista)
  const [, snackbarActions] = useSnackbar()
  const classes = useStyles()

  const userLogged = useLoggedUser()
  const userEmpresaIsG10 = isG10(userLogged?.empresa)

  const handleOnChange = async (value: string, fieldName: 'tipoEntrevista', fieldValue: string | undefined) => {
    if (fieldValue === value) return
    if (value !== 'PRESENCIAL' && value !== 'VIDEO' && value !== 'TELEFONE') return

    if (agendamentoId) {
      const response = await updateAgendamento(agendamentoId, {
        [fieldName]: value
      })

      if (response && response.ok) {
        if (fieldName === 'tipoEntrevista')
          setTipoEntrevistaValue(value)
      } else {
        snackbarActions.setMessage(
          `Ocorreu um erro ao alterar o ${fieldName} da Seleção do(a) Candidato(a)`
        )
      }
    } else {
      snackbarActions.setMessage('Não foi possível encontrar a Seleção do(a) Candidato(a)')
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography align="center" className={!agendamentoId ? classes.disabledLabel : undefined}>
          Tipo Entrevista
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={6} className={classes.alignContent}>
            <Typography align="center" className={!agendamentoId ? classes.disabledLabel : undefined}>
              Presencial
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Checkbox
              checked={tipoEntrevistaValue !== undefined && tipoEntrevistaValue === 'PRESENCIAL'}
              value={'PRESENCIAL'}
              onChange={(event) => handleOnChange(event.target.value, 'tipoEntrevista', tipoEntrevistaValue)}
              disabled={!agendamentoId}
            />
          </Grid>
          <Grid item xs={6} className={classes.alignContent}>
            <Typography align="center" className={!agendamentoId ? classes.disabledLabel : undefined}>
              Vídeo
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Checkbox
              checked={tipoEntrevistaValue !== undefined && tipoEntrevistaValue === 'VIDEO'}
              value={'VIDEO'}
              onChange={(event) => handleOnChange(event.target.value, 'tipoEntrevista', tipoEntrevistaValue)}
              disabled={!agendamentoId}
            />
          </Grid>
          {userEmpresaIsG10 && (
            <>
              <Grid item xs={6} className={classes.alignContent}>
                <Typography align="center" className={!agendamentoId ? classes.disabledLabel : undefined}>
                  Telefone
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Checkbox
                  checked={tipoEntrevistaValue !== undefined && tipoEntrevistaValue === 'TELEFONE'}
                  value={'TELEFONE'}
                  onChange={(event) => handleOnChange(event.target.value, 'tipoEntrevista', tipoEntrevistaValue)}
                  disabled={!agendamentoId}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}