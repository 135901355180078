import React, { Fragment, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import MenuList from '@material-ui/core/MenuList'
import Popper from '@material-ui/core/Popper'
import Typography from '@material-ui/core/Typography'
import styles from './MenuItemsStyles'
import When from '../When'

const useStyles = makeStyles(styles as any)

export type Item = ItemOnly | ItemGroup

export interface ItemGroup {
  name: string
  label: string
  icon: any
  hidden?: true
  group: true
  items: ItemOnly[]
}

export interface ItemOnly {
  name: string
  label: string
  pathname: string
  icon: any
  hidden?: true
  group: false
  detail?: React.FC
  list?: React.FC
}

interface MenuItemsProps {
  items: Array<Item>
  isExpanded: boolean
  isPopperOpen: boolean
  onMenuItemClick: (label: string) => void
}

type CustomMenuListProps = {
  item: ItemGroup
  onClosePopper: () => void
}

type CustomPopperProps = {
  item: Item
  isPopperOpen: boolean
  anchorEl: any
  popperLabel: string
  handleClosePopper: () => void
}

const CustomMenuList = ({ item, onClosePopper }: CustomMenuListProps) => {
  const classes = useStyles()
  return (
    <div className={classes.menuList}>
      <MenuList>
        {item.items.map(item => {
          return (
            <MenuItem
              className={classes.menuItem}
              key={item.label}
              onClick={onClosePopper}
              component={Link}
              to={item.pathname}
            >
              {item.label}
            </MenuItem>
          )
        })}
      </MenuList>
    </div>
  )
}

const CustomPopper = ({
  item,
  isPopperOpen,
  anchorEl,
  popperLabel,
  handleClosePopper
}: CustomPopperProps) => {
  const classes = useStyles()

  return (
    <Popper
      className={classes.popper}
      placement="right"
      anchorEl={anchorEl}
      open={isPopperOpen && Boolean(anchorEl)}
      onClick={handleClosePopper}
    >
      <Paper>
        <div className={classes.popperArrow}></div>
        {item.label === popperLabel ? (
          <Typography className={classes.popperTitle}>{item.label}</Typography>
        ) : null}
        {item.group && popperLabel === item.label ? (
          <CustomMenuList item={item} onClosePopper={handleClosePopper} />
        ) : null}
      </Paper>
    </Popper>
  )
}

const MenuItems = ({
  items,
  isPopperOpen,
  onMenuItemClick,
  isExpanded
}: MenuItemsProps) => {
  const classes = useStyles()
  const location = useLocation()
  const [openSubmenu, setOpenSubmenu] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [popperLabel, setPopperLabel] = useState('')

  const handleClosePopper = () => setAnchorEl(null)
  const handleOpenPopper = (event: any, label: string) => {
    setPopperLabel(label)
    if (anchorEl !== event.currentTarget) setAnchorEl(event.currentTarget)
  }

  const handleGroupClick = (label: string) => () => {
    handleClosePopper()
    onMenuItemClick(label)
    setOpenSubmenu(openSubmenu === label ? '' : label)
  }

  useEffect(() => {
    if (!isPopperOpen) handleClosePopper()
  }, [isPopperOpen])

  useEffect(() => {
    if (!isExpanded) setOpenSubmenu('')
  }, [isExpanded])

  const isUserInGroupItems = (group: ItemGroup) => {
    return Boolean(
      group.items.find(item => location.pathname.includes(item.pathname))
    )
  }

  const renderItem = (item: Item, key: any) => {
    if (item.group) {
      return (
        <div key={item.label} className={classes.menuColor}>
          <ListItem
            button
            onClick={!isPopperOpen ? handleGroupClick(item.label) : () => { }}
            onMouseOver={event => handleOpenPopper(event, item.label)}
          >
            <ListItemIcon
              classes={{
                root: classes.menuColor
              }}
            >
              <item.icon />
            </ListItemIcon>

            <ListItemText
              primary={item.label}
              classes={{ primary: classes.menuColor }}
            />
            {openSubmenu === item.label ? (
              <ExpandLess color="inherit" />
            ) : (
              <ExpandMore color="inherit" />
            )}
          </ListItem>
          <Collapse
            in={
              !isPopperOpen
                ? openSubmenu === item.label ||
                (isExpanded && isUserInGroupItems(item))
                : false
            }
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <When value={!!item.items} equals={true}>
                {item.items.map(
                  item => renderListItem(item, { padLeft: true }),
                  true
                )}
              </When>
            </List>
          </Collapse>
          <CustomPopper
            item={item}
            isPopperOpen={isPopperOpen}
            handleClosePopper={handleClosePopper}
            anchorEl={anchorEl}
            popperLabel={popperLabel}
          />
        </div>
      )
    }

    return renderListItem(item)
  }

  const renderListItem = (item: ItemOnly, options?: { padLeft: boolean }) => {
    const active =
      `/${location.pathname.split('/')[1]}` === item.pathname.split('?')[0] ||
      location.pathname === item.pathname
    const activeColor = classes.menuColorActive

    return (
      <Fragment key={item.label}>
        <ListItem
          onMouseOver={(event: any) => handleOpenPopper(event, item.label)}
          button
          style={options?.padLeft ? { paddingLeft: 20 } : {}}
          classes={{
            root: active ? classes.active : ''
          }}
          component={Link}
          to={item.group ? '' : item.pathname}
          onClick={handleClosePopper}
        >
          <ListItemIcon
            classes={{
              root: active ? activeColor : classes.menuColor
            }}
          >
            <item.icon />
          </ListItemIcon>

          <ListItemText
            primary={item.label}
            classes={{
              primary: active ? activeColor : classes.menuColor
            }}
          />
        </ListItem>
        <CustomPopper
          item={item}
          handleClosePopper={handleClosePopper}
          isPopperOpen={isPopperOpen}
          anchorEl={anchorEl}
          popperLabel={popperLabel}
        />
      </Fragment>
    )
  }
  return <div>{items.map(renderItem)}</div>
}

export default MenuItems
