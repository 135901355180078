import {
  Box,
  Button,
  FormHelperText,
  makeStyles,
  Typography
} from '@material-ui/core'
import { useField } from 'formik'
import { ChangeEvent } from 'react'

interface FileUploadInputProps {
  name: string
  label: string
}

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none'
  },
  fileName: {
    paddingLeft: theme.spacing(2),
    wordBreak: 'break-all'
  },
  button: {
    width: '165px',
    textAlign: 'center'
  }
}))

function FileUploadInput(props: FileUploadInputProps) {
  const classes = useStyles()
  const [field, meta, helpers] = useField(props)

  async function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target.files

    try {
      if (files && files.length > 0) {
        const file = await toBase64(files[0])
        helpers.setValue(
          files?.length
            ? {
              name: files[0].name,
              path: file,
              file: files[0]
            }
            : null
        )
      }
    } catch (error) { }
  }

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  return (
    <Box display="flex" alignItems="center">
      <input
        accept=".doc,.docx,application/msword, image/png, image/jpeg, image/jpg, application/pdf"
        onChange={handleChange}
        className={classes.input}
        id={props.name}
        type="file"
      />
      <label htmlFor={props.name}>
        <Button variant="contained" color="primary" component="span" className={classes.button}>
          {props.label}
        </Button>
      </label>
      <Typography className={classes.fileName}>{field.value?.name}</Typography>
      <FormHelperText error={Boolean((meta.error as any)?.name)}>
        {(meta.error as any)?.name}
      </FormHelperText>
    </Box>
  )
}

export default FileUploadInput
