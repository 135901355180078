import { useListaBloqueio } from '../hooks/useListaBloqueio'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { ListaBloqueioForm } from './ListaBloqueioForm'
import { formatCpfCnpj } from '../../../utils/formatters'

export function ListaBloqueiosMasterDetail() {
  const [listaBloqueioState, listaBloqueioActions] = useListaBloqueio()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      listaBloqueioState,
      u => u.entity.id,
      u => formatCpfCnpj(u.entity.cpf)
    )
  ])
  useFetchAndReset(listaBloqueioActions.fetch, listaBloqueioActions.reset)
  return handleStateErrorsToRender(listaBloqueioState, <ListaBloqueioForm />)
}
