import Chart from "react-apexcharts"
import { GridInfo } from "../types"
import { isGridInfo } from "../utils/checkInterface"
import { GroupedBarChartProps, gridInfoApiResponseToChart } from "../utils/formatToChart"

interface GroupedBarChartComponentProps {
  info: GridInfo | undefined,
  title: string
}

function getCorrectInfoInterface(info: any): GroupedBarChartProps {
  if (isGridInfo(info)) {
    return gridInfoApiResponseToChart(info)
  } else {
    return {
      customLegendItems: [''],
      categories: [''],
      series: [{ data: [0] }]
    }
  }
}

export const BarChart = ({ info, title }: GroupedBarChartComponentProps) => {
  const chartPropByInfo = getCorrectInfoInterface(info);

  const options: ApexCharts.ApexOptions = {
    title: {
      text: title
    },
    legend: {
      customLegendItems: chartPropByInfo.customLegendItems,
      itemMargin: {
        horizontal: 10
      }
    },
    chart: {
      type: 'bar',
      height: 430
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        dataLabels: {
          position: 'top',
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: 10,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: chartPropByInfo.categories,
    },
  }

  return (
    <Chart
      options={options}
      series={chartPropByInfo.series}
      type="bar"
      width="100%"
      height="300px"
    />
  )
}