import { InfoFile } from "../../types";

export const initialValues = {
  nomeCompleto: '',
  uf: '',
  tipo: '',
  telefone: '',
  celular: '',
  origemCurriculo: '',
  cidade: '',
  email: '',
  cpf: '',
  primeiroEmprego: false,
  anexoCurriculo: {
    file: {} as File,
    name: '',
    path: ''
  } as InfoFile,
  Cursos: [
    {
      descricao: '',
      dataInicio: null,
      dataConclusao: null,
      cargaHoraria: 0
    }
  ],
  dataNascimento: null,
  empresaId: '',
  observacaoProcesso: '',
  departamento: '',
  AreaInteresse: [
    {
      id: ''
    }
  ],
  ExperienciaProfissional: [
    {
      empresa: '',
      cargoFuncao: '',
      cidade: '',
      uf: '',
      telefone: '',
      dataEntrada: null,
      dataSaida: null,
      atualmenteEmpregado: false,
      principaisAtividades: ''
    },
    {
      empresa: '',
      cargoFuncao: '',
      cidade: '',
      uf: '',
      telefone: '',
      dataEntrada: null,
      dataSaida: null,
      atualmenteEmpregado: false,
      principaisAtividades: ''
    },
    {
      empresa: '',
      cargoFuncao: '',
      cidade: '',
      uf: '',
      telefone: '',
      dataEntrada: null,
      dataSaida: null,
      atualmenteEmpregado: false,
      principaisAtividades: ''
    }
  ]
}