import {
  Button,
  Grid,
  makeStyles,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Skeleton } from '@material-ui/lab'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Paper } from 'src/components'
import { HandleErrorPage } from 'src/utils/handleErrorPage'
import { useCandidato } from '../hooks/useCandidato'
import ProcessoAdministrativoForm from '../master-detail/ProcessoAdministrativoForm'
import DocumentoProcessoSeletivoAdminList from './documents-administrativo/DocumentosProcessoSeletivoAdminList'
import { CandidatoCardContent } from './CandidatoCardContent'

type Props = {
  candidatoId: string
  processoContratacaoId: string
  showIcons: boolean
}

const useStyles = makeStyles(theme => ({
  returnButton: {
    top: '-1rem',
    left: '-1rem'
  }
}))

export const AdministrativoProcessoInfo = ({
  candidatoId,
  processoContratacaoId,
  showIcons
}: Props) => {
  const [state, actions] = useCandidato()
  const history = useHistory()
  const style = useStyles()

  useEffect(() => {
    async function fetchData() {
      if (candidatoId) {
        await actions.fetch(candidatoId)
      }
    }
    fetchData()
  }, [candidatoId, actions])

  switch (state.tag) {
    case 'initial':
      return (
        <Paper>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Paper>
      )
    case 'empty-response':
      return (
        <Paper>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Paper>
      )
    case 'error':
      return <HandleErrorPage state={state.data} />
    case 'with-data':
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            {state.entity ? (
              <Paper minHeight={340}>
                <Button
                  className={style.returnButton}
                  color="default"
                  onClick={() =>
                    history.push(`/processo-seletivo-administrativo`)
                  }
                  startIcon={<ArrowBackIcon />}
                >
                  Retornar para Listagem de Processos Seletivos
                </Button>
                <CandidatoCardContent
                  candidato={state.entity}
                  showIcons={showIcons || processoContratacaoId === 'new'}
                />
              </Paper>
            ) : (
              <Grid item xs={12} sm={6}>
                <Paper>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </Paper>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={processoContratacaoId !== 'new' && showIcons ? 6 : 12}
            sm={12}
          >
            <ProcessoAdministrativoForm
              candidatoAdmId={candidatoId}
              showIcons={showIcons}
            />
          </Grid>
          {processoContratacaoId !== 'new' && showIcons && (
            <>
              {candidatoId ? (
                <Grid item xs={12} md={6} sm={12}>
                  <DocumentoProcessoSeletivoAdminList
                    id={candidatoId}
                    processoId={processoContratacaoId}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={6}>
                  <Paper minHeight={340} maxHeight={340}>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </Paper>
                </Grid>
              )}
            </>
          )}
        </Grid>
      )
  }
}
