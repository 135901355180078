import { useSnackbar } from '@elentari/core'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  Step,
  StepLabel,
  Stepper
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { Skeleton } from '@material-ui/lab'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Paper } from 'src/components'
import { MessagesYup } from 'src/modules/messages'
import { useProcessoAdministrativo } from '../hooks/useProcessoAdministrativo'
import { LockOutlined } from '@material-ui/icons'
import ComplexDialog from '../../../components/ComplexDialog'
import { apiSauceInstance } from 'src/services/api'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    rootCard: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      background: '#f0f0f0'
    },
    contentCard: {
      height: 'fill-available',
      position: 'relative'
    },
    title: {
      fontSize: 14
    },
    pos: {
      marginBottom: 12
    },
    stepperCustom: {
      '& .MuiStepConnector-lineVertical': {
        height: '70px',
        [theme.breakpoints.up('xs')]: {
          height: '170px'
        },
        [theme.breakpoints.up('sm')]: {
          height: '170px'
        },
        [theme.breakpoints.up('md')]: {
          height: '170px'
        },
        [theme.breakpoints.up('lg')]: {
          height: '70px'
        }
      }
    }
  })
)

type ActionsEtapas = {
  path: string
  descricao?: string
  name: string
  disabled?: boolean
  status: 'AGENDAMENTO' | 'ENTREVISTA' | 'REPROVADO' | 'CONCLUSAO'
}

const normalEtapas: ActionsEtapas[] = [
  {
    name: 'Agendamentos',
    path: '/agendamentos',
    status: 'AGENDAMENTO'
  },
  {
    name: 'Entrevista',
    path: '/entrevista',
    status: 'ENTREVISTA'
  },
  {
    name: 'Conclusão',
    descricao: '',
    status: 'CONCLUSAO',
    path: '/conclusao'
  }
]

const EtapasAdministrativoCards = ({
  processoSeletivoId,
  showIcons
}: {
  processoSeletivoId: string
  showIcons: boolean
}) => {
  const [, snackbarActions] = useSnackbar()
  const classes = useStyles()
  const history = useHistory()
  const [processoState, actions] = useProcessoAdministrativo()
  const { pathname } = useLocation()
  const [refresh, setRefresh] = useState(false)
  const [etapaStatus, setEtapaStatus] = useState({} as ActionsEtapas)
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false
  })

  useEffect(() => {
    async function loadData() {
      const processoId = pathname.split('/')[2]
      await actions.fetch(processoId)
      setRefresh(false)
    }
    loadData()
  }, [actions, pathname, refresh])

  const openDialogWithEtapa = (etapa: ActionsEtapas) => {
    setConfirmationDialog({
      open: true
    })
    setEtapaStatus(etapa)
  }

  const closeDialog = () => {
    setConfirmationDialog({
      open: false
    })
  }

  const updateEtapaLock = useCallback(async () => {
    try {
      if (processoState.tag === 'with-data') {
        await apiSauceInstance.put<any>(
          `/processo-seletivo-administrativos/${processoState.entity.id}`,
          {
            status: etapaStatus.status,
            tipoReprovacao: null,
            motivoReprovacao: null
          }
        )
      }
      setRefresh(true)
    } catch (error) {
      snackbarActions.setMessage('Ocorreu um erro ao atualizar a etapa.')
    }
  }, [etapaStatus])

  function searchName(status: string) {
    return (
      normalEtapas.find(etapa => etapa.status === status)?.name ?? 'Reprovado'
    )
  }

  const getEtapas = (): ActionsEtapas[] => {
    if (processoState.tag === 'with-data') {
      let disableNextEtapas = false
      const filterEtapas: ActionsEtapas[] = normalEtapas.map(etapa => {
        if (processoState.entity.status === 'REPROVADO') {
          return {
            ...etapa,
            disabled: true
          }
        }
        if (etapa.status === processoState.entity.status) {
          disableNextEtapas = true
          return etapa
        } else {
          if (disableNextEtapas) {
            return {
              ...etapa,
              disabled: true
            }
          }
          return etapa
        }
      })
      return filterEtapas
    }

    return normalEtapas
  }

  const getActiveEtapa = (): number => {
    if (getEtapas().findIndex(etapa => etapa.disabled) !== -1) {
      return getEtapas().findIndex(etapa => etapa.disabled) - 1
    } else {
      return getEtapas().length - 1
    }
  }

  return (
    <>
      {processoState.tag === 'with-data' ? (
        <Box marginTop={2}>
          <Paper>
            <Grid
              justifyContent="center"
              container
              spacing={2}
              className={classes.root}
            >
              <Grid item xs={3} sm={2} md={2} lg={1} xl={1}>
                <Stepper
                  activeStep={getActiveEtapa()}
                  orientation="vertical"
                  className={classes.stepperCustom}
                >
                  {getEtapas().map((etapa, index) => (
                    <Step key={etapa.name}>
                      <StepLabel></StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs={7} sm={6} md={6}>
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  {getEtapas().map(etapa => (
                    <Grid key={etapa.name} item xs={12} sm={12} md={8} lg={12}>
                      <Card variant="elevation" className={classes.rootCard}>
                        <CardContent className={classes.contentCard}>
                          <Grid
                            container
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Grid item xs={6} sm={8} md={12} lg={4}>
                              <Typography variant="h5" component="h2">
                                {etapa.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4}>
                              <Button
                                disabled={etapa?.disabled || !showIcons}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={() =>
                                  history.push(
                                    `${processoSeletivoId + etapa.path}`,
                                    {
                                      processoSeletivoId
                                    }
                                  )
                                }
                              >
                                Editar
                              </Button>
                            </Grid>
                            {showIcons && (
                              <Grid item xs={12} sm={12} md={12} lg={4}>
                                <Tooltip
                                  title={`Liberar ${etapa.name}`}
                                  placement="top"
                                  aria-label={`Liberar ${etapa.name}`}
                                >
                                  <Button
                                    disabled={!etapa?.disabled}
                                    onClick={() => openDialogWithEtapa(etapa)}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    arial-label={`liberar ${etapa.name}`}
                                  >
                                    Liberar Etapa
                                  </Button>
                                </Tooltip>
                              </Grid>
                            )}
                          </Grid>
                        </CardContent>
                        <CardActions></CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <ComplexDialog
              open={!!confirmationDialog.open}
              disableBackdropClick
              closeActionButtonLabel="Não"
              handleClose={closeDialog}
              primaryAction={() => {
                updateEtapaLock()
              }}
              title={`Deseja realmente liberar etapa de ${etapaStatus.name}?`}
              primaryActionButtonLabel={`Liberar etapa`}
            >
              <div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  align="left"
                >
                  <strong>Etapa Atual:</strong>{' '}
                  {searchName(processoState.entity.status)}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  align="left"
                >
                  <strong>Próxima Etapa:</strong> {etapaStatus.name}
                </Typography>
              </div>
            </ComplexDialog>
          </Paper>
        </Box>
      ) : (
        <Box marginTop={2}>
          <Paper>
            <Grid
              justifyContent="center"
              container
              spacing={2}
              className={classes.root}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Skeleton variant="rect" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Skeleton variant="rect" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Skeleton variant="rect" />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
    </>
  )
}

export { EtapasAdministrativoCards }
