import { useSnackbar } from '@elentari/core'
import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Button, CircularProgress, createStyles, Divider, Grid, makeStyles } from '@material-ui/core'
import { Formik, FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Paper } from 'src/components'
import RichTextInput from 'src/components/Formik/Forms/RichTextInput'
import { NavigationButton } from 'src/components/NavigationButton'
import { useAppBar } from 'src/hooks'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import { isAnalistaRh } from 'src/utils'
import { CandidatoSchemaStepAdmin } from 'src/utils/yup-schemas/candidato/adminStepSchema'
import { ExperienciaProfAdministrativoSchemaStep } from 'src/utils/yup-schemas/candidato/expProfStepSchema'
import * as Yup from 'yup'
import { FormHeader } from '../../components/FormHeader'
import HistoricoCandidato from '../../components/HistoricoCandidato'
import { ReturnButton } from '../../components/ReturnButton'
import { TriagemActive } from '../../components/TriagemActive'
import { TriagemButtons } from '../../components/TriagemButtons'
import { uploadManyDocumentos } from '../../hooks/documentosRepository'
import CandidatoStep from '../../sharedSteps/CandidatoStep'
import ExperienciaProfeStep from '../../sharedSteps/ExperienciaProfStep'
import triagemStore from '../../store/store'
import { objectRemoveEmptyStrings } from '../../utils'
import { repository as usersRepository } from '../hooks/administrativoRepository'
import { useAdministrativo } from '../hooks/useAdministrativo'
import { SaveAdministrativoInput } from '../types'
import { initialValues } from '../utils/initialValues'

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '160px'
      }
    },
    divider: {
      marginTop: 30,
      marginBottom: 30
    },
    marginBottom: {
      marginBottom: 20
    },
    fullHeight: {
      height: '100%'
    }
  })
)

export const TriagemAdministrativoForm = () => {
  const [administrativoState, actions] = useAdministrativo()
  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()

  const history = useHistory()
  const location = useLocation()
  const userLogged = useLoggedUser()
  const isAnalista = isAnalistaRh(userLogged?.rolesInSystem)

  const [showHistorico, setShowHistorico] = useState<boolean>(false)
  const classes = useStyles()

  const { save } = useEntitySaver<SaveAdministrativoInput>(data =>
    usersRepository.save(data)
  )

  const formikState = {
    initialValues,
    onSubmit: async (data: FormikValues) => {
      try {
        data.tipo = 'ADMINISTRATIVO'

        const documentos = await uploadManyDocumentos([
          { ...data.anexoCurriculo, descricao: 'anexoCurriculo', candidatoId: data.id }
        ])
        if (!documentos) {
          return snackbarActions.setMessage("Ocorreu um erro ao salvar o(s) documento(s)")
        }

        delete data.anexoCurriculo

        const dataFormatted = objectRemoveEmptyStrings(data)
        const response = await save({
          ...dataFormatted,
          documentos
        } as SaveAdministrativoInput)
        if (response.status === 200) {
          if (response.data?.id) {
            actions.fetch(response.data.id)
          }
          appBarActions.setTitle(data.nomeCompleto)
        }
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  useEffect(() => {
    if (administrativoState.tag === 'with-data') {
      if (userLogged?.empresa !== administrativoState.entity.empresaFK?.sigla) {
        history.push('/')
      }
    }
  }, [administrativoState])

  useEffect(() => {
    return actions.reset()
  }, [actions])

  const scrollTop = () => {
    const mainContainer = document.querySelector('main')
    if (mainContainer) {
      mainContainer.scrollTo({
        top: 0
      })
    }
  }

  const handleNextCandidato = () => {
    const nextCandidato = triagemStore.nextCandidatoId()
    if (nextCandidato) {
      history.push(`/${location.pathname.split('/')[1]}/${nextCandidato}`)
      scrollTop()
    } else {
      snackbarActions.setMessage('Não há mais candidatos na lista.')
    }
  }

  const handlePrevious = () => {
    triagemStore.previousCandidatoId()
    history.goBack()
    scrollTop()
  }

  return (
    <>
      <Paper noTopBorderRadius>
        <Grid container spacing={2} direction="column" className={classes.marginBottom}>
          <Formik
            onSubmit={formikState.onSubmit}
            validationSchema={Yup.object().shape({
              ...CandidatoSchemaStepAdmin(isAnalista).fields,
              ...ExperienciaProfAdministrativoSchemaStep(isAnalista).fields
            })}
            enableReinitialize={true}
            initialValues={
              administrativoState.tag === 'with-data'
                ? administrativoState.entity
                : formikState.initialValues
            }
          >
            {({ isSubmitting, handleSubmit }) => (
              <div>
                <FormHeader
                  header
                  disableButtons={false}
                  showReturnButton={false}
                  isTriagem={true}
                  candidatoData={administrativoState.tag === 'with-data' && administrativoState.entity}
                />

                <CandidatoStep
                  label="Candidato(a)"
                  candidatoType="Administrativo"
                  validationSchema={CandidatoSchemaStepAdmin}
                  isTriagem={true}
                />

                <ExperienciaProfeStep
                  label="Experiência Profissional"
                  candidatoType="Administrativo"
                  validationSchema={ExperienciaProfAdministrativoSchemaStep}
                />

                {administrativoState.tag === 'with-data' && administrativoState.entity.statusTriagem === 'APROVADO' && (
                  <RichTextInput
                    label="Observações sobre Processo"
                    name="observacaoProcesso"
                  />
                )}

                <Divider className={classes.divider} />

                <TriagemActive
                  hasActiveTriagem={administrativoState.tag === 'with-data' && Boolean(administrativoState.entity.statusTriagem)}
                  Triagens={administrativoState.tag === 'with-data' && administrativoState.entity.Triagem}
                />

                <TriagemButtons
                  candidatoId={administrativoState.tag === 'with-data' && administrativoState.entity.id}
                  candidatoCpf={administrativoState.tag === 'with-data' && administrativoState.entity.cpf}
                  candidatoBloqueio={administrativoState.tag === 'with-data' && Boolean(administrativoState.entity.idListaBloqueio)}
                  candidatoAprovado={administrativoState.tag === 'with-data' && administrativoState.entity.statusTriagem === 'APROVADO'}
                  candidatoFirstAgendamento={administrativoState.tag === 'with-data' && administrativoState.entity.AgendamentoCandidato !== undefined && administrativoState.entity.AgendamentoCandidato.length === 0}
                  candidatoType={"ADMINISTRATIVO"}
                  historicoState={{ state: showHistorico, action: setShowHistorico }}
                />

                <Divider className={classes.divider} />

                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <NavigationButton
                      label="Anterior"
                      direction="left"
                      disabled={isSubmitting}
                      onClick={() => handlePrevious()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Grid container className={classes.fullHeight} justifyContent='center' alignContent='center' >
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={isSubmitting}
                        onClick={() => handleSubmit()}
                        type="submit"
                      >
                        {isSubmitting ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          <>Salvar</>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Grid container justifyContent='flex-end' spacing={2}>
                      <Grid item xs={12}>
                        <Grid container justifyContent='flex-end'>
                          <NavigationButton
                            label="Próximo"
                            direction="right"
                            disabled={isSubmitting}
                            onClick={() => handleNextCandidato()}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <ReturnButton disableButtons={isSubmitting} />
                  </Grid>
                </Grid>
              </div>
            )}
          </Formik>
        </Grid>
      </Paper>
      <HistoricoCandidato
        open={showHistorico}
        candidatoId={administrativoState.tag === 'with-data' && administrativoState.entity.id}
        Triagens={administrativoState.tag === 'with-data' && administrativoState.entity.Triagem}
      />
    </>
  )
}
