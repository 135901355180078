import { EdgesPage } from '@elentari/core/types'
import { apiSauceInstance } from 'src/services/api'
import { AdmissaoCandidatoDetail } from '../types'

export const admitirCandidato = async (data: any) => {
  const response = await apiSauceInstance.post<any>(
    `/admissao-candidatos`,
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const updateAdmissao = async (admissaoId: string, data: any) => {
  const response = await apiSauceInstance.put<any>(
    `/admissao-candidatos/${admissaoId}`,
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const notificarAdmissaoDp = async (admissaoId: string) => {
  const response = await apiSauceInstance.post<any>(
    `/admissao-candidatos/${admissaoId}/notificarDp`
  )

  return response.ok
}

export const alterarStatusAdmissaoCandidato = async (
  admissaoId: string,
  data: any
) => {
  const response = await apiSauceInstance.patch<any>(
    `/admissao-candidatos/${admissaoId}/alterar-status`,
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const fetchAdmissoesCandidatoFromCandidatoId = async (candidatoId: string) => {
  const response = await apiSauceInstance.get<EdgesPage<AdmissaoCandidatoDetail>>(
    `/admissao-candidatos`,
    {
      candidatoId
    }
  )

  return response
}

export const fetchAdmissaoCandidatoById = async (id: string) => {
  const response = await apiSauceInstance.get<AdmissaoCandidatoDetail>(`/admissao-candidatos/${id}`)

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}