export default {
  baseURL: process.env.REACT_APP_API_URL ?? 'http://localhost:3030',
  useFixture: false,
  ssoUrlLogin: process.env.REACT_APP_SSO_URL_LOGIN,
  rhUrl: process.env.REACT_APP_RH_URL,
  clientId: process.env.REACT_APP_RH_CLIENT_ID,
  ssoUrl: process.env.REACT_APP_SSO_URL,
  logoPath: process.env.REACT_APP_LOGO_PATH,
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_VALIDATE_SITEKEY
}
