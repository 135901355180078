import { useSnackbar } from "@elentari/core"
import { FetchState } from "@elentari/core/types"
import { FormLabel, Grid, makeStyles, Typography } from "@material-ui/core"
import { Formik, FormikValues } from "formik"
import { Paper } from "src/components"
import { RadioGroupInput } from "src/components/Formik/Forms/RadioGroupInput"
import RichTextInput from "src/components/Formik/Forms/RichTextInput"
import SelectInput from "src/components/Formik/Forms/SelectInput"
import TextInput from "src/components/Formik/Forms/TextInput"
import Spacer from "src/components/Spacer"
import { tipoContratacao } from "src/utils"
import { useAdministrativo } from "../../administrativo/hooks/useAdministrativo"
import { CandidatoCardContent } from "../../components/CandidatoCardContent"
import { updateSelecaoCandidato } from "../../hooks/selecaoCandidatoRepository"
import { useMotorista } from "../../motorista/hooks/useMotorista"
import { MotoristaDetail } from "../../motorista/types"
import { CandidatoDetail, SelecaoCandidatoDetail, statusSelecao } from "../../types"
import { getNewestSelecao } from "../../utils"
import { CompareceuInput } from "../components/ComparacaoInput"
import { ResultadoSelecaoForm } from "../components/ResultadoSelecaoForm"
import { SelecaoCandidatoButtons } from "../components/SelecaoCandidatoButtons"
import { useSubEmpresas } from "../hooks/useSubEmpresas"
import { schema } from "../utils/validationSchema"

const useStyles = makeStyles(theme => ({
  alignContentBottom: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  minWidth: {
    minWidth: '720px'
  },
  formContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
      paddingBottom: theme.spacing(4)
    }
  }
}))

const SelecaoCandidatoForm = ({
  state,
  action
}: {
  state: FetchState<CandidatoDetail | MotoristaDetail>
  action: { fetch: (id: string) => Promise<void>; reset: () => void; }
}) => {
  const [subEmpresasState, subEmpresasAction] = useSubEmpresas()
  const [, snackbarActions] = useSnackbar()
  const classes = useStyles()

  const formikState = {
    initialValues: {
      compareceu: null,
      tipoContratacao: '',
      subEmpresaId: '',
      status: '',
      guep: '',
      pis: '',

      testePratico: null,
      dataTestePratico: null,
      observacaoTestePratico: null,

      testePsicologico: null,
      dataTestePsicologico: null,
      observacaoTestePsicologico: null,

      testeTecnico: null,
      dataTesteTecnico: null,
      observacaoTesteTecnico: null,

      entrevistaRh: null,
      dataEntrevistaRh: null,
      observacaoEntrevistaRh: null,

      entrevistaGestor: null,
      dataEntrevistaGestor: null,
      observacaoEntrevistaGestor: null,

      parecerRh: '',
      parecerGestor: ''
    },
    onSubmit: async (data: FormikValues) => {
      try {
        const response = await updateSelecaoCandidato(data.id, data)

        if (response?.ok) {
          snackbarActions.setMessage('Salvo com sucesso!')
        } else {
          snackbarActions.setMessage('Ocorreu um erro ao salvar.')
        }
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  const formatData = (state: CandidatoDetail | MotoristaDetail): SelecaoCandidatoDetail => {
    return getNewestSelecao(state.SelecaoCandidato ?? [])
  }

  const resetForm = async () => {
    if (state.tag === 'with-data') {
      await action.fetch(state.entity.id)
    }
  }

  return (
    <>
      {state.tag === 'with-data' && (
        <Paper>
          <CandidatoCardContent
            candidato={state.entity}
            showIcons={true}
          />
        </Paper>
      )}

      <Spacer y={2} />

      <Formik
        initialValues={
          state.tag === 'with-data' ?
            formatData(state.entity)
            : formikState.initialValues
        }
        onSubmit={formikState.onSubmit}
        validationSchema={schema}
        enableReinitialize
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <>
            <Paper>
              <div className={classes.formContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} sm={6}>
                    <CompareceuInput
                      label="Compareceu"
                      name="compareceu"
                    />
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <RadioGroupInput
                      label="Tipo Contratação"
                      name="tipoContratacao"
                      options={tipoContratacao}
                      type="string"
                      labelVariant="h6"
                    />
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.alignContentBottom}>
                    <SelectInput
                      name="subEmpresaId"
                      label="Empresa"
                      options={
                        subEmpresasState.tag === 'with-data' ?
                          subEmpresasState.list.map(subEmpresa => {
                            return {
                              label: subEmpresa.nome,
                              value: subEmpresa.id ?? ''
                            }
                          })
                          :
                          []
                      }
                      firstOptionDisabled={subEmpresasState.tag === 'with-data'}
                    />
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <TextInput
                      name="guep"
                      label="Guep"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextInput
                      name="pis"
                      label="Pis"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ResultadoSelecaoForm disabled={values.compareceu === null} />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2} className={classes.minWidth}>
                      <Grid item xs={12}>
                        <RichTextInput
                          label="Parecer RH"
                          name="parecerRh"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <RichTextInput
                          label="Parecer Gestor"
                          name="parecerGestor"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {values.status && (
                    <Grid item container xs={12} justifyContent="center">
                      <FormLabel>
                        <Typography
                          variant="h6"
                          style={{ fontWeight: 'bold', marginRight: '0.5rem' }}
                        >
                          Status:
                        </Typography>
                      </FormLabel>
                      <Typography variant="h6">
                        {statusSelecao.find(selecao => selecao.value === values.status)?.label}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Paper>

            <Spacer y={2} />

            <SelecaoCandidatoButtons
              id={state.tag === 'with-data' && state.entity.SelecaoCandidato !== undefined && getNewestSelecao(state.entity.SelecaoCandidato).id}
              candidatoId={state.tag === 'with-data' && state.entity.id}
              onSubmit={handleSubmit}
              disabled={values.status !== 'PENDENTE'}
              actionButtonsDisabled={values.compareceu === null}
              onStatusChange={resetForm}
              isSubmitting={isSubmitting}
            />
          </>
        )}
      </Formik>
    </>
  )
}

export const SelecaoCandidatoAdministrativoForm = () => {
  const [administrativoState, administrativoActions] = useAdministrativo()

  return <SelecaoCandidatoForm state={administrativoState} action={administrativoActions} />
}

export const SelecaoCandidatoMotoristaForm = () => {
  const [motoristaState, motoristaActions] = useMotorista()

  return <SelecaoCandidatoForm state={motoristaState} action={motoristaActions} />
}