import { useSnackbar } from '@elentari/core'
import { Button, Grid, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { FieldArray, FieldArrayRenderProps, Formik, FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import TextInput from 'src/components/Formik/Forms/TextInput'
import { useAppBar } from 'src/hooks'
import * as yup from 'yup'
import { FormWrap, Paper } from '../../../../components'
import { MessagesYup } from '../../../messages'
import {
  createCargoResponsabilidades,
  getAllCargoResponsabilidadesByCargoId
} from '../hooks/cargoResponsabilidadeRepository'
import { CargoResponsabilidadeDetail } from '../types'

interface Params {
  id: string
}

export const CargoResponsabilidadeForm = () => {
  const [cargoResponsabilidades, setCargoResponsabilidades] =
    useState<CargoResponsabilidadeDetail>()

  const params = useParams<Params>()

  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()

  useEffect(() => {
    appBarActions.setTitle('Responsabilidades')
    loadCargoResponsabilidades()
  }, [])

  const loadCargoResponsabilidades = async () => {
    const response = await getAllCargoResponsabilidadesByCargoId(params.id)

    if (response?.ok) {
      if (response?.info.length !== 0) {
        setCargoResponsabilidades({
          cargoId: params.id,
          Responsabilidades: response.info
        })
      }
    }
  }

  const schemaObject = {
    Responsabilidades: yup
      .array()
      .of(
        yup.object().shape({
          descricao: yup
            .string()
            .trim()
            .required(MessagesYup.MENSAGEM_OBRIGATORIO)
            .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
        })
      )
      .test('unique-responsabilidade', function (responsabilidades: any) {
        interface Beneficio {
          descricao: string
        }
        const arrDescricoesResponsabilidades = responsabilidades.map(
          (responsabilidade: Beneficio) =>
            responsabilidade.descricao?.toUpperCase()
        )

        const numberDiffResponsabilidades = new Set(
          arrDescricoesResponsabilidades
        ).size

        if (responsabilidades.length !== numberDiffResponsabilidades) {
          const arrRepetidos: number[] = []
          arrDescricoesResponsabilidades.filter(
            (descricao: string, index: number) => {
              if (arrDescricoesResponsabilidades.indexOf(descricao) !== index) {
                arrRepetidos.push(index)
              }

              return arrDescricoesResponsabilidades.indexOf(descricao) == index
            }
          )

          return this.createError({
            path: `Responsabilidades[${arrRepetidos[0]}].descricao`,
            message: 'Não pode haver responsabilidades repetidas'
          })
        } else {
          return true
        }
      })
  }
  const schema = yup.object().shape(schemaObject)

  const handleAddResponsabilidade = (arrayHelpers: FieldArrayRenderProps) => {
    return () => {
      arrayHelpers.push({ descricao: '' })
    }
  }

  const handleDeleteResponsabilidade = (
    index: number,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    return () => {
      arrayHelpers.remove(index)
    }
  }

  const formikState = {
    initialValues: {
      cargoId: '',
      Responsabilidades: [
        {
          descricao: ''
        }
      ]
    },
    onSubmit: async (data: FormikValues) => {
      try {
        setLoading(true)
        const response = await createCargoResponsabilidades({
          ...data,
          cargoId: params.id
        } as CargoResponsabilidadeDetail)

        if (!response.ok) {
          snackbarActions.setMessage(response.message)
        } else {
          snackbarActions.setMessage('Salvo com sucesso!')
        }
        setLoading(false)
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={formikState.onSubmit}
      initialValues={cargoResponsabilidades ?? formikState.initialValues}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <Paper noTopBorderRadius>
          <FormWrap
            handleSubmit={handleSubmit}
            disableSubmit={isSubmitting}
            disableBack={isSubmitting}
            loading={loading}
          >
            <Grid container spacing={2}>
              <FieldArray name={'Responsabilidades'} validateOnChange={true}>
                {arrayHelpers => (
                  <Grid item xs={12}>
                    {values.Responsabilidades?.map(
                      (responsabilidade, index) => (
                        <Grid
                          container
                          key={index}
                          style={{
                            paddingBottom: 20,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'start'
                          }}
                        >
                          <Grid
                            item
                            xs={values.Responsabilidades.length > 1 ? 11 : 12}
                          >
                            <TextInput
                              name={`Responsabilidades[${index}].descricao`}
                              label={`${index + 1}° Responsabilidade`}
                            />
                          </Grid>

                          {values.Responsabilidades.length > 1 && (
                            <Grid
                              item
                              xs={1}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <Tooltip
                                placement="top"
                                title={`Deletar ${index + 1}° Responsabilidade`}
                              >
                                <Button
                                  style={{
                                    flex: 1,
                                    height: 56,
                                    border: 'none'
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  onClick={handleDeleteResponsabilidade(
                                    index,
                                    arrayHelpers
                                  )}
                                >
                                  <DeleteOutlineIcon />
                                </Button>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      )
                    )}
                    <Tooltip
                      placement="right-start"
                      title="Adicionar Responsabilidade"
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        type="button"
                        onClick={handleAddResponsabilidade(arrayHelpers)}
                      >
                        <AddIcon />
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
              </FieldArray>
            </Grid>
          </FormWrap>
        </Paper>
      )}
    </Formik>
  )
}
