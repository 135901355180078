import { MessagesYup } from "src/modules/messages"
import * as yup from 'yup'

const schemaObject = {
  compareceu: yup
    .boolean()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  tipoContratacao: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  subEmpresaId: yup
    .string()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  guep: yup
    .string()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  pis: yup
    .string()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  testePratico: yup
    .boolean()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  dataTestePratico: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .nullable()
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  observacaoTestePratico: yup
    .string()
    .trim()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  testePsicologico: yup
    .boolean()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  dataTestePsicologico: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .nullable()
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  observacaoTestePsicologico: yup
    .string()
    .trim()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  testeTecnico: yup
    .boolean()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  dataTesteTecnico: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .nullable()
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  observacaoTesteTecnico: yup
    .string()
    .trim()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  entrevistaRh: yup
    .boolean()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  dataEntrevistaRh: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .nullable()
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  observacaoEntrevistaRh: yup
    .string()
    .trim()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  entrevistaGestor: yup
    .boolean()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  dataEntrevistaGestor: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .nullable()
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  observacaoEntrevistaGestor: yup
    .string()
    .trim()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  parecerRh: yup
    .string()
    .trim()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  parecerGestor: yup
    .string()
    .trim()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
}
export const schema = yup.object().shape(schemaObject)