import { apiSauceInstance } from 'src/services/api'
import { InstrucaoAdmissao, InstrucaoAdmissaoFormData } from '../types'

export const fetchInstrucaoAdmissao = async (id: string) => {
  const response = await apiSauceInstance.get<InstrucaoAdmissao>(`/instrucao-admissao/${id}`)

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const saveInstrucaoAdmissao = async (data: InstrucaoAdmissaoFormData) => {
  if (data.id) {
    const response = await apiSauceInstance.put<InstrucaoAdmissao>(
      `/instrucao-admissao/${data.id}`,
      data
    )

    const { data: info, ok, status } = response

    if (info && ok) {
      return {
        info,
        ok,
        status
      }
    }
    return null
  } else {
    const response = await apiSauceInstance.post<InstrucaoAdmissao>(`/instrucao-admissao`, data)

    const { data: info, ok, status } = response

    if (info && ok) {
      return {
        info,
        ok,
        status
      }
    }
    return null
  }
}
