import { useCargoCategoria } from '../hooks/useCargoCategoria'
import { useAppBar } from '../../../../hooks'
import { handleStateErrorsToRender } from '../../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { CargoCategoriaForm } from './CargoCategoriaForm'

export function CargoCategoriaDetail() {
  const [CargoCategoriaState, CargoCategoriaActions] = useCargoCategoria()
  const [, appBarActions] = useAppBar()

  CargoCategoriaState &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useReplaceFragments(appBarActions.replaceFragment, [
      mapFragmentReplace(
        CargoCategoriaState,
        u => u.entity.id,
        u => u.entity.descricao
      )
    ])
  useFetchAndReset(CargoCategoriaActions.fetch, CargoCategoriaActions.reset)
  return handleStateErrorsToRender(CargoCategoriaState, <CargoCategoriaForm />)
}
