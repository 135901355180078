import { Ability } from '@casl/ability'
import { makeStyles } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import AppWrap from '../../components/AppWrap'
import { Item } from '../../components/AppWrap/MenuItems'
import { RouteByMenu } from '../../components/RouterByMenu'
import g10logo from '../../images/g10-logo-2.png'
import tplogo from '../../images/tp-logo.png'
import {
  AdmissaoCandidatoAdministrativoMasterDetail,
  AdmissaoCandidatoMotoristaMasterDetail
} from '../candidato/admissao-candidato/master-detail/AdmissaoCandidatoMasterDetail'
import Candidato from '../candidato/Candidato'
import { ProcessoAdministrativoView } from '../candidato/pages/processo/ProcessoAdministrativoView'
import { useAbility } from '../login'
import { useLoggedUser } from '../login/AuthProvider'
import menuItems from './MenuItems'

const useStyles = makeStyles(theme => ({
  logo: {
    maxWidth: '100%',
    maxHeight: '50px',
    filter: 'brightness(100)'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))
const canRead = (ability?: Ability) => (m: Item) => ability?.can('read', m.name)
const filterMenuItemByAbility = (ability: Ability | undefined, items: Item[]) =>
  items
    .map(m => {
      const copy = { ...m }

      if (copy.group) {
        copy.items = copy.items.filter(canRead(ability))
      }
      return copy
    })
    .filter(canRead(ability))

const HomeNavigation = () => {
  const ability = useAbility()
  const user = useLoggedUser()

  const [userMenuItems, setUserMenuItems] = useState<Item[]>([])
  const [, setOpen] = useState(localStorage.toggleDrawer === '1')
  const classes = useStyles()
  const onToggleDrawer = (status: string) => {
    setOpen(status === 'open')
  }

  useEffect(() => {
    setUserMenuItems(filterMenuItemByAbility(ability, menuItems))
  }, [ability, setUserMenuItems])

  useEffect(() => {
    document.title = `${user?.empresa} - Recursos Humanos`
    const favIcon = document.getElementById('icon') as HTMLLinkElement
    if (favIcon) {
      if (user?.empresa === 'TP') {
        favIcon.href = '/faviconTP.ico'
      } else {
        favIcon.href = '/favicon.ico'
      }
    }
  }, [])

  const renderLogo = () => {
    if (user?.empresa === 'TP') {
      return <img src={tplogo} alt="TP - RH" className={classes.logo} />
    } else {
      return <img src={g10logo} alt="G10 - RH" className={classes.logo} />
    }
  }
  return (
    <AppWrap
      logo={renderLogo()}
      menuItems={userMenuItems}
      isPositionButtonMenuDrawer={false}
      onToggleDrawer={onToggleDrawer}
      classes={null}
    >
      <div style={{ height: '100%' }}>
        <Switch>
          <RouteByMenu menuItems={userMenuItems}>
            {
              <>
                <Route path="/candidato/:id" component={Candidato} />
                <Route
                  path="/processo-seletivo-administrativo/:id"
                  exact
                  component={ProcessoAdministrativoView}
                />
                <Route
                  path="/admissao-motorista/:id"
                  exact
                  component={AdmissaoCandidatoMotoristaMasterDetail}
                />
                <Route
                  path="/admissao-administrativo/:id"
                  exact
                  component={AdmissaoCandidatoAdministrativoMasterDetail}
                />
              </>
            }
          </RouteByMenu>
        </Switch>
      </div>
    </AppWrap>
  )
}

export default HomeNavigation
