import FileComponent from 'src/components/FileComponent'

interface AnexoCandidatoTreinamentoFileProps {
  id?: string
  iconType?: 'clip' | 'default'
}

const AnexoCandidatoTreinamentoFile = ({ id, iconType = 'default' }: AnexoCandidatoTreinamentoFileProps) => {
  return (
    <FileComponent id={id} route="anexo-candidato-treinamento" iconType={iconType} />
  )
}

export default AnexoCandidatoTreinamentoFile