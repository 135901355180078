import { MessagesYup } from 'src/modules/messages'
import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { candidatoSchemaPropertiesOptional, candidatoSchemaPropertiesRequired } from './candidatoSchema'

const adminSchema = {
  departamento: Yup.string().typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  AreaInteresse: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string()
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
    )
    .test('unique-areas', function (areasId: any) {
      const arrInteresses = areasId.map((areas: { id: string }) => areas.id)

      const numberDiff = new Set(arrInteresses).size

      if (areasId.length !== numberDiff) {
        const arrRepetidos: number[] = []

        arrInteresses.filter((area: any, index: number) => {
          if (arrInteresses.indexOf(area) !== index) {
            arrRepetidos.push(index)
          }
          return arrInteresses.indexOf(area) === index
        })

        return this.createError({
          path: `AreaInteresse[${arrRepetidos[0]}].id`,
          message: 'Não pode haver Interesses repetidos'
        })
      } else {
        return true
      }
    }),
}

const schemaRequired: ObjectShape = {
  ...candidatoSchemaPropertiesRequired,
  ...adminSchema,
  departamento: adminSchema.departamento.required(MessagesYup.MENSAGEM_OBRIGATORIO),
}

export const CandidatoSchemaStepAdmin = (isAnalista: boolean) => {
  if (isAnalista) {
    return Yup.object().shape({
      ...candidatoSchemaPropertiesOptional,
      ...adminSchema,
      departamento: adminSchema.departamento.nullable(),
    })
  } else {
    return Yup.object().shape(schemaRequired)
  }
}

export const validationAdminCurriculo = Yup.object().shape(schemaRequired)