import LinkMD from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Rating } from '@material-ui/lab'
import QueryString from 'qs'
import { Link, useLocation } from 'react-router-dom'
import { CpfBloqueadoIcon } from 'src/components/Icons/CpfBloqueadoIcon'
import { ReagendamentoIcon } from 'src/components/Icons/ReagendamentoIcon'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import { formatTime } from 'src/utils/formatters/formatDate'
import {
  NoResultsTable,
  Paper,
  TableContainer,
  When
} from '../../../components'
import Spacer from '../../../components/Spacer'
import { TableCellHead } from '../../../components/TableCellHead'
import { FormatedDate, isG10, searchTipoEntrevista, tipoContratacao } from '../../../utils'
import { ModalHistorico } from '../components/ModalHistorico'
import agendamentoStore from './store/store'
import { AgendamentosCandidatoDetail } from './types'

interface AgendamentoCandidatoTableProps {
  list: AgendamentosCandidatoDetail[]
  loading: boolean
  totalCount: number
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const AgendamentoCandidatoTable = ({
  list,
  loading,
  totalCount,
  authorization
}: AgendamentoCandidatoTableProps) => {
  const { search } = useLocation()

  const userLogged = useLoggedUser()
  const userEmpresaIsG10 = isG10(userLogged?.empresa)

  return (
    <>
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label={userEmpresaIsG10 ? "Data do Processo" : "Data para o qual foi Agendado"} sort="dataAgendamento" />
                  <TableCellHead label={userEmpresaIsG10 ? "Horário do Processo" : "Hora para o qual foi Agendado"} />
                  <TableCellHead label="Nome Completo" />
                  <TableCellHead label="Celular" />
                  <TableCellHead label="Classificação" />
                  <TableCellHead label={userEmpresaIsG10 ? "Data do Agendamento" : "Data em que o Agendamento Aconteceu"} sort="createdAt" />
                  <TableCellHead label="Agendado por" sort="agendadoPor" />
                  <TableCellHead label="Tipo de Entrevista" sort="tipoEntrevista" />
                  <TableCellHead label="Tipo da Contratação" sort="tipoContratacao" />
                  <TableCellHead label="Quantidade de agendamentos" />
                  <TableCellHead label="Histórico" />
                  <TableCell></TableCell>
                  <When
                    value={!authorization || authorization.canCreate}
                    equals
                  >
                    <TableCell></TableCell>
                  </When>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(agendamentoCandidato => {
                  return (
                    <TableRow hover key={agendamentoCandidato.candidatoFK.id}>
                      <TableCell>
                        {!authorization || authorization.canUpdate ? (
                          <When
                            value={!authorization || authorization.canUpdate}
                            equals
                          >
                            <LinkMD
                              component={Link}
                              to={
                                agendamentoCandidato.candidatoFK.tipo === 'ADMINISTRATIVO' ?
                                  `agendamento-administrativo/${agendamentoCandidato.candidatoFK.id}`
                                  :
                                  `agendamento-motorista/${agendamentoCandidato.candidatoFK.id}`
                              }
                              onClick={() => {
                                agendamentoStore.fetchAgendamentos(
                                  QueryString.stringify({
                                    incluirBloqueados: 'false',
                                    sort: { dataAgendamento: 'desc' },
                                    ...QueryString.parse(search.substring(1)),
                                    tipoCandidato: agendamentoCandidato.candidatoFK.tipo,
                                    statusCandidato: 'AGENDADO',
                                    onlyNewest: 'true'
                                  }),
                                  agendamentoCandidato.candidatoFK.id
                                )
                              }}
                            >
                              {FormatedDate(agendamentoCandidato.dataAgendamento)}
                            </LinkMD>
                          </When>
                        ) : (
                          FormatedDate(agendamentoCandidato.dataAgendamento)
                        )}
                      </TableCell>
                      <TableCell>
                        {formatTime(new Date(agendamentoCandidato.dataAgendamento))}
                      </TableCell>
                      <TableCell>{agendamentoCandidato.candidatoFK.nomeCompleto}</TableCell>
                      <TableCell>{agendamentoCandidato.candidatoFK.celular}</TableCell>
                      <TableCell>
                        <Rating
                          max={3}
                          value={agendamentoCandidato.candidatoFK.notaClassificacao}
                          disabled
                        />
                      </TableCell>
                      <TableCell>
                        {FormatedDate(agendamentoCandidato.createdAt)}
                      </TableCell>
                      <TableCell>
                        {agendamentoCandidato.agendadoPor}
                      </TableCell>
                      <TableCell>
                        {searchTipoEntrevista(agendamentoCandidato.tipoEntrevista)}
                      </TableCell>
                      <TableCell>
                        {tipoContratacao.find(tipoContratacao => tipoContratacao.value === agendamentoCandidato.tipoContratacao)?.label}
                      </TableCell>
                      <TableCell>
                        {agendamentoCandidato.candidatoFK.contagemAgendamentos}
                      </TableCell>
                      <TableCell>
                        <ModalHistorico
                          candidatoId={agendamentoCandidato.candidatoFK.id}
                          candidatoType={agendamentoCandidato.candidatoFK.tipo === 'ADMINISTRATIVO' ? 'ADMINISTRATIVO' : 'MOTORISTA'}
                          triagens={agendamentoCandidato.candidatoFK.Triagem}
                          disabled={loading}
                        />
                      </TableCell>
                      <TableCell>
                        <When value={agendamentoCandidato.tipoAgendamento} equals="REAGENDAMENTO">
                          <ReagendamentoIcon />
                        </When>
                      </TableCell>
                      <When value={authorization?.canCreate} equals>
                        <TableCell>
                          {agendamentoCandidato.candidatoFK.idListaBloqueio && (
                            <CpfBloqueadoIcon
                              idListaBloqueio={agendamentoCandidato.candidatoFK.idListaBloqueio}
                            />
                          )}
                        </TableCell>
                      </When>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
    </>
  )
}