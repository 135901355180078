import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Paper } from 'src/components'
import { CardButton } from '../../components/Cards/CardButton'
import { CurriculosBackground } from '../../components/CurriculosBackground'
import g10Background from '../../images/g10-background.jpg'
import g10Logo from '../../images/g10-logo-2.png'

const useStyles = makeStyles(theme => ({
  gridPaper: {
    minHeight: '100vh',
    padding: '20px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 20px'
    }
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px'
  },
  imgLogo: {
    width: '300px',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      height: '50%'
    }
  }
}))

export const TrabalheConoscoG10 = () => {
  const classes = useStyles()

  return (
    <CurriculosBackground backgroundImage={g10Background}>
      <Grid
        container
        className={classes.gridPaper}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <div className={classes.logoContainer}>
            <img src={g10Logo} className={classes.imgLogo} />
          </div>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} style={{ padding: '20px' }}>
                <Typography variant="h4" color="secondary">
                  <b>Trabalhe Conosco</b>
                </Typography>
                <Typography variant="subtitle1">
                  Venha fazer parte da nossa equipe
                </Typography>
                <br />
                <Typography variant="subtitle1" align="justify">
                  A G10 Transportes tem sede em Maringá e mais de 160 unidades,
                  distribuídas pelas cinco regiões brasileiras. Com milhares de
                  colaboradores, a empresa atua no transporte de toda a cadeia
                  do agronegócio, líquidos e industrializados. A G10 está há 21
                  anos no mercado brasileiro. A empresa está entre as maiores e
                  melhores empresas do Brasil, no segmento de transporte
                  rodoviário de cargas. Assista ao nosso vídeo institucional.
                </Typography>
                <br />
                <Typography variant="subtitle1" align="justify">
                  A G10 Transportes está entre as Maiores e Melhores
                  transportadoras do Brasil. Isso é o que aponta o anuário
                  “Maiores e Melhores do Transporte”, produzido pela editora
                  OTM.
                </Typography>
              </Grid>
              <Grid item container xs={12} md={6} spacing={2}>
                <Grid item container xs={12}>
                  <CardButton
                    title="Administrativo"
                    tipo="administrativo"
                    empresa="G10"
                  />
                </Grid>
                <Grid item container xs={12}>
                  <CardButton
                    title="Operacional"
                    tipo="operacional"
                    empresa="G10"
                  />
                </Grid>
                <Grid item container xs={12}>
                  <CardButton
                    title="Motorista"
                    tipo="motorista"
                    empresa="G10"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </CurriculosBackground>
  )
}
