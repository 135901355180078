import { useSnackbar } from '@elentari/core'
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Formik, FormikValues } from 'formik'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import SelectInput from 'src/components/Formik/Forms/SelectInput'
import { monetaryFormatter } from 'src/utils/formatters'
import * as yup from 'yup'
import { Paper } from '../../../components'
import { MessagesYup } from '../../messages'
import { vagasEmTriagem } from '../../vagas/hooks/vagaRepository'
import CandidatosSelecionadosList from '../components/CandidatosSelecionadosList'
import { createManyProcessoSeletivo } from '../hooks/processoSeletivoRepository'
import candidatosSelecionadosStore from '../store/store'
import { VagaDetail } from '../types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2)
      }
    }
  })
)

interface Option {
  value: string
  label: string
}

interface FormikValuesProps {
  vagasId: string
}

interface VagaApiResult {
  node: VagaDetail
}

export const CandidatoVagaForm = observer(() => {
  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const [vagasOptions, setVagasOptions] = useState<Option[]>([])
  const [vagas, setVagas] = useState<VagaApiResult[]>([])
  const [selectCandidatosOpen, setSelectCandidatosOpen] = useState<boolean>(candidatosSelecionadosStore.selecaoCandidatos)
  const [vagaSelected, setVagaSelected] = useState<VagaDetail | undefined>(candidatosSelecionadosStore.vaga)
  const style = useStyles()
  const history = useHistory()
  const classes = useStyles()
  const location = useLocation()

  const loadVagas = async () => {
    const response = await vagasEmTriagem()
    if (response?.ok) {
      setVagasOptions(
        response?.info?.edges.map((vaga: VagaApiResult) => ({
          value: vaga.node.id,
          label: vaga.node.cargoFk?.nome
        })) ?? []
      )
      setVagas(response?.info?.edges ?? [])
    }
  }

  useEffect(() => {
    loadVagas()
  }, [])

  const schema = yup.object().shape({
    vagasId: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    CandidatosSelecionados: yup.array().of(
      yup.object().shape({
        aderencia: yup
          .number()
          .min(0, MessagesYup.MENSAGEM_VALOR_MINIMO)
          .max(100, 'Valor máximo permitido é 100')
          
          .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO)
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
    )
  })

  const formikState = {
    initialValues: {
      vagasId: candidatosSelecionadosStore.vaga?.id,
      CandidatosSelecionados:
        toJS(candidatosSelecionadosStore.candidatosSelecionados) ?? []
    },
    onSubmit: async (data: FormikValues) => {
      try {
        setLoading(true)
        data.CandidatosSelecionados = toJS(data.CandidatosSelecionados)
        const response = await createManyProcessoSeletivo(data)
        setLoading(false)
        if (response?.ok) {
          snackbarActions.setMessage('Processos criados com sucesso.')
        } else {
          snackbarActions.setMessage('Ocorreu um erro ao criar os processos.')
        }
        candidatosSelecionadosStore.resetarTriagemCandidatos()
        setSelectCandidatosOpen(false)
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  const handleChangeVaga = (vaga: VagaDetail) => {
    candidatosSelecionadosStore.setVaga(vaga)
  }

  function defaultHandleGoBack() {
   setSelectCandidatosOpen(false)
   candidatosSelecionadosStore.setSelecaoCandidatos(false)
  }

  function handleSelectVaga() {
    if(!candidatosSelecionadosStore.vaga) {
      snackbarActions.setMessage('É necessário selecionar uma Vaga para prosseguir')
    } else {
      if(vagaSelected && vagaSelected.id !== candidatosSelecionadosStore.vaga.id) {
        candidatosSelecionadosStore.resetCandidatos()
      }
      setSelectCandidatosOpen(true)
      candidatosSelecionadosStore.setSelecaoCandidatos(true)
    }
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={formikState.onSubmit}
      enableReinitialize
      initialValues={formikState.initialValues}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            {/* Informações da Vaga */}
            <Grid item xs={12} sm={12} md={6}>
              <Paper minHeight={375}>
                <Box marginBottom={2}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 'bold' }}
                    component="h2"
                  >
                    Informações da Vaga
                  </Typography>
                </Box>
                <div style={{height: '280px', marginBottom: '10px'}}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SelectInput
                        name="vagasId"
                        label="Vaga"
                        options={vagasOptions}
                        onChange={(ev: any) => {
                          const vagaFound = vagas.find(vaga => vaga.node.id === ev.target.value)?.node
                            if(vagaFound) {
                              handleChangeVaga(vagaFound)
                              setFieldValue('vagasId', ev.target.value)
                            }
                          }}
                        disabled={selectCandidatosOpen}
                      />
                    </Grid>
                    <Box height={190} padding={2}>
                      {(values.vagasId !== undefined && vagas.length != 0) && (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                align="left"
                              >
                                <strong>Área: </strong>{' '}
                                {
                                  vagas.find(
                                    vaga => vaga.node.id === values.vagasId
                                  )?.node.area
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                align="left"
                              >
                                <strong>Categoria: </strong>{' '}
                                {
                                  vagas.find(
                                    vaga => vaga.node.id === values.vagasId
                                  )?.node.categoria
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                align="left"
                              >
                                <strong>Salário: </strong>{' '}
                                {monetaryFormatter.format(
                                  Number(
                                    vagas.find(vaga => vaga.node.id === values.vagasId)
                                      ?.node.salario
                                  )
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                align="left"
                              >
                                <strong>Solicitado Por: </strong>{' '}
                                {
                                  vagas.find(
                                    vaga => vaga.node.id === values.vagasId
                                  )?.node.solicitadoPor
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Box>
                  </Grid>
                </div>
                <Grid item xs={12}>
                  <Grid justify="flex-end" container spacing={2}>
                    <Grid item style={{ width: 200 }}>
                      <Button
                        fullWidth
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={handleSelectVaga}
                        disabled={selectCandidatosOpen}
                      >
                        Selecionar Vaga
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {/* Lista de candidatos(as) */}
            <Grid item xs={12} sm={12} md={6}>
              <Paper minHeight={375}>
                <Box marginBottom={2}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 'bold', marginBottom: 8 }}
                    component="h2"
                  >
                    Lista de candidatos(as)
                  </Typography>
                </Box>
                <div style={{height: '280px', marginBottom: '10px'}}>
                  <Grid container justifyContent="flex-end">
                  <CandidatosSelecionadosList values={values} disabled={!selectCandidatosOpen}/>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!selectCandidatosOpen}
                    style={{ marginTop: 6 }}
                    onClick={() =>
                      history.push(
                        `/triagem-candidatos-administrativo/selecao-candidatos`
                      )
                    }
                    startIcon={<AddIcon />}
                  >
                    Selecionar Candidatos(as)
                  </Button>
                  </Grid>
                </div>
                <Grid item xs={12}>
                  <Grid justify="flex-end" container spacing={2}>
                    <Grid item style={{ width: 160 }}>
                      <Button
                        fullWidth
                        type="button"
                        variant="outlined"
                        disabled={!selectCandidatosOpen}
                        onClick={defaultHandleGoBack}
                      >
                        Voltar
                      </Button>
                    </Grid>
                    <Grid item style={{ width: 160 }}>
                      {loading ? (
                        <Button
                          data-testid="salvar"
                          fullWidth
                          disabled={true}
                          variant="contained"
                          color="primary"
                        >
                          <div className={classes.root}>
                            <CircularProgress color="inherit" size={24} />
                          </div>
                        </Button>
                      ) : (
                        <Button
                          data-testid="salvar"
                          fullWidth
                          type="submit"
                          disabled={isSubmitting || !selectCandidatosOpen}
                          variant="contained"
                          color="primary"
                        >
                          {'Salvar'}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
})
