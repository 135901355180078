import Chart from "react-apexcharts"
import { CandidatoInfo, ProcessoAdministrativoInfo, VagasInfo } from "../types"
import { isCandidatoInfo, isProcessoAdministrativoInfo, isVagaInfo } from "../utils/checkInterface"
import {
  candidatoInfoApiResponseToChart, DonutChartProps,
  processosAdministrativoInfoApiResponseToChart,
  vagaInfoApiResponseToChart
} from "../utils/formatToChart"

interface DonutChartComponentProps {
  info: VagasInfo | ProcessoAdministrativoInfo | CandidatoInfo | undefined,
  title: string
}

function getCorrectInfoInterface(info: any): DonutChartProps {
  if (isProcessoAdministrativoInfo(info)) {
    return processosAdministrativoInfoApiResponseToChart(info)
  } else if (isVagaInfo(info)) {
    return vagaInfoApiResponseToChart(info)
  } else if (isCandidatoInfo(info)) {
    return candidatoInfoApiResponseToChart(info)
  } else {
    return {
      labels: [''],
      series: [0]
    }
  }
}

export const DonutChart = ({ info, title }: DonutChartComponentProps) => {
  const chartPropByInfo = getCorrectInfoInterface(info);

  const options: ApexCharts.ApexOptions = {
    labels: chartPropByInfo.labels,
    title: {
      text: title
    },
    legend: {
      width: 200
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total',
              color: 'black',
              showAlways: true
            }
          },
        }
      }
    }
  }

  return (
    <Chart
      options={options}
      series={chartPropByInfo.series}
      type="donut"
      width="100%"
      height="300px"
    />
  )
}