import {
  Box,
  Grid,
  makeStyles,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  CardContent,
  Tooltip,
  IconButton
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import LinkMD from '@material-ui/core/Link'
import { Skeleton } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  CandidatoTreinamentoDetail
} from '../types'
import {
  NoResultsTable,
  Paper,
  TableContainer,
  TableCellHead
} from '../../../components'
import { apiSauceInstance } from '../../../services/api'
import { FormatedDate } from 'src/utils'
import { PaperCard } from 'src/components/PaperCard'

interface Props {
  list: { node: CandidatoTreinamentoDetail }[]
  loading: boolean
  totalCount: number
  candidatoId: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    candidatoTreinamentoListContainer: {
      maxHeight: '203px',
      overflow: 'scroll'
    },
  })
)

export const CandidatosTreinamentoTable = ({ list, loading, totalCount, candidatoId }: Props) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <PaperCard
      title="Treinamentos"
      disableScroll
      primaryButtonText="Adicionar Treinamento"
      primaryButtonAction={
        () => history.push({
          pathname: `/candidato-treinamento/new`,
          state: {
            candidatoId
          }
        })
      }
      Content={
        <TableContainer loading={loading} totalCount={0} disablePagination={true}>
          <div className={classes.candidatoTreinamentoListContainer}>
            {list.length ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellHead label="Nome do Treinamento" />
                    <TableCellHead label="Nota" />
                    <TableCellHead label="Data Início do Treinamento" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map(candidatoTreinamento => (
                    <TableRow hover key={candidatoTreinamento.node.id}>
                      <TableCell>
                        <LinkMD
                          component={Link}
                          to={`/candidato-treinamento/${candidatoTreinamento.node.id}`}
                        >
                          {candidatoTreinamento.node.treinamentoFK?.nome}
                        </LinkMD>
                      </TableCell>
                      <TableCell>{candidatoTreinamento.node.nota}</TableCell>
                      <TableCell>
                        {FormatedDate(candidatoTreinamento.node.createdAt)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <NoResultsTable loading={loading} />
            )}
          </div>
        </TableContainer>
      }
    />
  )
}

type CandidatosTreinamentosListProps = {
  id: string
}

type APIResponseCandidatosTreinamento = {
  edges: { node: CandidatoTreinamentoDetail }[]
  totalCount: number
  loading: boolean
}

const CandidatoTreinamentoList = ({ id }: CandidatosTreinamentosListProps) => {
  const [candidatosTreinamentoState, setCandidatosTreinamentoState] = useState<APIResponseCandidatosTreinamento>(
    {} as APIResponseCandidatosTreinamento
  )

  useEffect(() => {
    async function loadCandidatosTreinamentosByCandidatoId() {
      try {
        setCandidatosTreinamentoState({
          ...candidatosTreinamentoState,
          loading: true
        })

        const { data } = await apiSauceInstance.get<any>(
          `/candidatos-treinamentos`,
          {
            candidatoId: id
          }
        )
        if (data) {
          setCandidatosTreinamentoState({
            totalCount: data.totalCount,
            edges: data.edges,
            loading: false
          })
        }
      } catch (error) {}
    }
    loadCandidatosTreinamentosByCandidatoId()
  }, [id])

  return (
    <div>
      {candidatosTreinamentoState.loading === false ? (
        <CandidatosTreinamentoTable
          list={candidatosTreinamentoState.edges}
          totalCount={candidatosTreinamentoState.totalCount}
          loading={candidatosTreinamentoState.loading}
          candidatoId={id}
        />
      ) : (
        <Paper minHeight={340}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Paper>
      )}
    </div>
  )
}

export default CandidatoTreinamentoList
