import { useAgendamento } from '../hooks/useAgendamento'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { AgendamentoAdministrativoForm } from './AgendamentoAdministrativoForm'
import { searchTipoEntrevista } from 'src/utils'

export function AgendamentoAdministrativoMasterDetail() {
  const [AgendamentoState, AgendamentoActions] = useAgendamento()
  const [, appBarActions] = useAppBar()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      AgendamentoState,
      u => u.entity.id,
      u => searchTipoEntrevista(u.entity.tipoEntrevista)
    )
  ])
  useFetchAndReset(AgendamentoActions.fetch, AgendamentoActions.reset)
  return handleStateErrorsToRender(AgendamentoState, <AgendamentoAdministrativoForm />)
}
