import { useSnackbar } from '@elentari/core'
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core'
import LinkMD from '@material-ui/core/Link'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { FieldArray } from 'formik'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import TextInput from 'src/components/Formik/Forms/TextInput'
import { handleKeyCodes } from 'src/modules/options'
import candidatosSelecionadosStore from '../store/store'

interface Props {
  values: any
  disabled: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listaCandidatosContainer: {
      maxHeight: '240px',
      overflow: 'scroll'
    },
    candidatoContainer: {
      padding: 10,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'start'
    },
    itemCandidatoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '56px'
    }
  })
)

export const CandidatoSelecionadosList = observer((props: Props) => {
  const [_, snackbarActions] = useSnackbar()
  const { values, disabled } = props
  const classes = useStyles()

  async function handleDelete(candidatoId: string) {
    await candidatosSelecionadosStore.removerCandidato(candidatoId)

    snackbarActions.setSnackBar({
      message: 'Deletado com sucesso',
      severity: 'success'
    })
  }

  const handleChangeAderencia = (event: any, candidato: any) => {
    candidatosSelecionadosStore.setAderenciaByCandidatoId(
      candidato,
      event.target.value
    )
  }

  return (
    <Grid container className={classes.listaCandidatosContainer} spacing={2}>
      <FieldArray name={'CandidatosSelecionados'} validateOnChange={true}>
        {arrayHelpers => (
          <>
            {values.CandidatosSelecionados?.map(
              (candidato: any, index: any) => (
                <Grid
                  container
                  key={index}
                  justifyContent="center"
                  alignItems="center"
                  className={classes.candidatoContainer}
                >
                  <Grid
                    item
                    xs={12}
                    md={4}
                    className={classes.itemCandidatoContainer}
                  >
                    <LinkMD component={Link} to={`/candidato/${candidato.id}`}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center'
                        }}
                        component="p"
                      >
                        {candidato.nomeCompleto.length > 10
                          ? candidato.nomeCompleto.slice(0, 30) + '...'
                          : candidato.nomeCompleto}
                      </Typography>
                    </LinkMD>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      name={`CandidatosSelecionados[${index}].aderencia`}
                      label="Aderência"
                      type="number"
                      inputProps={{ min: 0, max: 100}}
                      disabled={disabled}
                      onChange={(event: any) =>
                        handleChangeAderencia(event, candidato)
                      }
                      onKeyDown={handleKeyCodes}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    className={classes.itemCandidatoContainer}
                  >
                    <Tooltip
                      placement="top"
                      title={`Deletar ${index + 1}° Candidato(a)`}
                    >
                      <Button
                        style={{
                          height: 56,
                          border: 'none'
                        }}
                        fullWidth
                        variant="outlined"
                        color="primary"
                        disabled={disabled}
                        onClick={() => handleDelete(candidato.id)}
                      >
                        <DeleteOutlineIcon />
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              )
            )}
          </>
        )}
      </FieldArray>
    </Grid>
  )
})

export default CandidatoSelecionadosList
