import { styled, Typography } from '@material-ui/core'

interface Props {
  message?: string
  errors: any
  submitCount: number
}

const ErrorTypography = styled(Typography)(theme => ({
  fontStyle: 'italic',
  color: theme.theme.palette.error.main,
  marginBottom: theme.theme.spacing(2),
  marginTop: theme.theme.spacing(2),
  marginRight: theme.theme.spacing(1),
  display: 'flex',
  justifyContent: 'flex-end'
}))

export const FieldsPending = ({
  message = 'Existem campos obrigatórios não preenchidos ou preenchidos incorretamente',
  errors,
  submitCount
}: Props) => {
  const hasError = !(Object.keys(errors).length === 0) && !!submitCount

  return <>{hasError && <ErrorTypography>{message}</ErrorTypography>}</>
}
