import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { isEmpty } from 'ramda';
import { useEffect, useState } from 'react';
import { useField } from 'react-final-form'
import RichTextEditor, { EditorValue } from 'react-rte';
import EditorToolbarConfig, { EmptyEditorToolbarConfig } from 'src/utils/toolbarConfig';

export default function RichTextField({
  name,
  label,
  disabled,
  enableToolbox
}: {
  name: string,
  label: string,
  disabled?: boolean,
  enableToolbox?: boolean
}) {
  const { input, meta } = useField(name)
  const [editorState, setEditorState] = useState(RichTextEditor.createValueFromString(input.value, 'html'))

  const hasValues = (state: EditorValue): boolean => {
    return !isEmpty(state.getEditorState().getCurrentContent().getPlainText().trim())
  }

  const handleChange = (state: EditorValue) => {
    setEditorState(state)
    input.onChange(hasValues(state) ? state.toString('html') : null)
  }

  useEffect(() => {
    if (input.value !== editorState.toString('html')) {
      setEditorState(RichTextEditor.createValueFromString(input.value, 'html'))
    }
  }, [input.value])

  return (
    <FormControl error={meta.error && meta.touched} disabled={disabled} fullWidth>
      <TextField
        {...input}
        {...meta}
        error={meta.touched && Boolean(meta.error)}
        label={label}
        variant="outlined"
        fullWidth
        disabled={disabled}
        InputProps={{
          inputComponent: () => (
            <RichTextEditor
              rootStyle={{
                width: '100%',
                border: '0',
                padding: 10
              }}
              value={editorState}
              onChange={handleChange}
              toolbarStyle={!enableToolbox ? {
                display: 'none'
              } : undefined}
              toolbarConfig={enableToolbox ? EditorToolbarConfig : EmptyEditorToolbarConfig}
              disabled={disabled}
            />
          )
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
      {meta.error && meta.touched && <FormHelperText error>{meta.error}</FormHelperText> }
    </FormControl>
  )
}