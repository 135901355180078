import { apiSauceInstance } from '../../../services/api'
import {
  DocumentosArray,
  DocumentosDetail,
  SaveDocumentosInput
} from '../types'
import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { ParsedQs } from 'qs'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params
  return {
    ...params,
    ...paginationParams(rest)
  }
}

export const DocumentosRepository = makeRepository<
  EdgesPage<DocumentosDetail>,
  SaveDocumentosInput,
  DocumentosDetail,
  string
>(
  {
    resource: 'documentos-administrativos',
    mapQueryParams
  },
  apiSauceInstance
)

export const fetchProcessoById = async (id: string) => {
  const response = await apiSauceInstance.get<any>(
    `/documentos-administrativos/${id}`,
    {
      origemDocumento: 'ETAPA_CONCLUSAO'
    }
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const createDocumentos = async (data: DocumentosArray) => {
  const response = await apiSauceInstance.post<any>(
    `/documentos-administrativos/create-many`,
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return info
}

export const sendEmail = async (id: string) => {
  const response = await apiSauceInstance.post<any>(
    `/documentos-administrativos/send-email`,
    {
      processoAdministrativoId: id
    }
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return info
}

export const renovateLink = async (id: string) => {
  const response = await apiSauceInstance.patch<any>(
    `/documentos-administrativos/renovate-link`,
    {
      processoAdministrativoId: id
    }
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return info
}
