import {
  IconButton,
  Tooltip
} from "@material-ui/core"
import AttachFileIcon from '@material-ui/icons/AttachFile'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { MouseEvent } from "react"

type iconProps = {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
  disabled: boolean
  iconType?: 'clip' | 'default'
}

export const AbrirAnexoIcon = (props: iconProps) => {
  return (
    <Tooltip title="Abrir Anexo">
      <IconButton
        aria-label="abrir-anexo"
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {!props.iconType || props.iconType === 'default' ? (
          <OpenInNewIcon />
        ) : (
          <AttachFileIcon />
        )}
      </IconButton>
    </Tooltip>
  )
}