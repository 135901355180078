import {
  IconButton,
  Tooltip,
} from "@material-ui/core"
import PersonIcon from '@material-ui/icons/Person'

export const AtualFuncionarioIcon = () => {
  return (
    <Tooltip title="Candidato(a) é um(a) Funcionário(a)">
      <IconButton aria-label="atual-funcionario">
        <PersonIcon />
      </IconButton>
    </Tooltip>
  )
}