import { apiSauceInstance } from '../../../../services/api'
import { MotoristaDetail, SaveMotoristaInput } from '../types'
import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { paginationParams } from '../../../../utils/paginationQueryParams'
import { ParsedQs } from 'qs'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params
  return {
    tipo: 'MOTORISTA',
    incluirBloqueados: 'false',
    ...params,
    ...paginationParams(rest)
  }
}

export const repository = makeRepository<
  EdgesPage<MotoristaDetail>,
  SaveMotoristaInput,
  MotoristaDetail,
  string
>(
  {
    resource: 'candidato',
    mapQueryParams
  },
  apiSauceInstance
)
