import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core'
import { useField } from 'formik'

interface SelectInputProps {
  label: string
  name: string
  disabled?: boolean
  onBlur?: () => void
  onClick?: () => void
}

function SimpleCheckbox(props: SelectInputProps) {
  const [field, meta] = useField(props)
  const { label } = props

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={field.value}
            onClick={props.onClick}
            color="primary"
            {...field}
            {...props}
          />
        }
        label={label}
      />
      <FormHelperText>{meta.touched && meta.error}</FormHelperText>
    </>
  )
}

export default SimpleCheckbox
