import { EdgesPage } from "@elentari/core/types"
import { apiSauceInstance } from "src/services/api"
import { CargoCandidatoDetail, CargoCandidatoFormData } from "../types"

export const fetchCargoCandidatoByCandidatoId = async (candidatoId: string) => {
  const response = await apiSauceInstance.get<EdgesPage<CargoCandidatoDetail>>(
    `/cargos-candidatos`,
    {
      candidatoId: candidatoId
    }
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const createCargoCandidato = async (
  data: CargoCandidatoFormData
) => {
  const response = await apiSauceInstance.post<any>(
    `/cargos-candidatos/create-many`,
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }

  return response.data
}