import { CandidatoInfo, GridInfo, ProcessoAdministrativoInfo, VagasInfo } from "../types";

export function isVagaInfo(info: any): info is VagasInfo {
  if (info) {
    return (<VagasInfo>info).statusEmTriagemCurriculos !== undefined;
  } else {
    return false
  }
}

export function isProcessoAdministrativoInfo(info: any): info is ProcessoAdministrativoInfo {
  if (info) {
    return (<ProcessoAdministrativoInfo>info).statusConclusao !== undefined;
  } else {
    return false
  }
}

export function isCandidatoInfo(info: any): info is CandidatoInfo {
  if (info) {
    return (<CandidatoInfo>info).countOrigemAdministrativo !== undefined;
  } else {
    return false
  }
}

export function isGridInfo(info: any): info is GridInfo {
  if (info) {
    return (<GridInfo>info).grids !== undefined;
  } else {
    return false
  }
}