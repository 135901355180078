import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import * as yup from 'yup'

import TextField from '@elentari/components-eve/final-form/TextField'
import MaskedField from '@elentari/components-eve/final-form/MaskedField'
import {
  makeStyles,
  Grid,
  TextField as TextFieldMaterial
} from '@material-ui/core'
import { ListaBloqueioFormData, BloqueioColors } from '../types'
import yupValidation from '../../../utils/yupValidation'

import { FormWrap, Paper } from '../../../components'
import { useListaBloqueio } from '../hooks/useListaBloqueio'

import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import {
  listaBloqueioRepository,
  desbloquear,
  bloquear
} from '../hooks/listaBloqueiosRepository'
import { MessagesYup } from '../../messages'
import { useParams, useHistory } from 'react-router-dom'
import { useSnackbar } from '@elentari/core'
import ComplexDialog from '../../../components/ComplexDialog'
import { getAlphanumericDigits } from '../../../utils/utils'
import { cpfRegex } from '../../../utils/regexes/cpfcnpj'
import { validateCPF } from '../../../utils/validators/cpf'
import RichTextField from 'src/components/Forms/RichTextField'
import { isNil } from 'ramda'

interface RouteParams {
  id: string
}

interface IHistory {
  from: string
}

const Operacao = {
  bloqueio: 'Adicionar Bloqueio',
  desbloqueio: 'Remover Bloqueio'
}

const TempoModal = 100

const useStyles = makeStyles({
  desbloquear: {
    backgroundColor: BloqueioColors.DESBLOQUEIO,
    '&:hover': {
      color: 'white',
      backgroundColor: BloqueioColors.DESBLOQUEIO_DARK
    }
  },
  bloquear: {
    backgroundColor: BloqueioColors.BLOQUEIO,
    '&:hover': {
      color: 'white',
      backgroundColor: BloqueioColors.BLOQUEIO_DARK
    }
  }
})
export const ListaBloqueioForm = () => {
  const [listaBloqueioState, listaBloqueioAction] = useListaBloqueio()
  const params = useParams<RouteParams>()
  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    callback: (data: any) =>
      new Promise<{
        info: any
        ok: boolean
        status: number | undefined
      } | null>((resolve, reject) => {
        resolve({
          info: {},
          ok: false,
          status: 0
        })
        reject(null)
      }),
    id: '',
    mensagem: '',
    operacao: '',
    isBloqueio: false
  })
  const history = useHistory<IHistory>()
  const classes = useStyles()
  const isNewBloqueio = params.id === 'new'

  const { save } = useEntitySaver<ListaBloqueioFormData>(data => {
    const response = listaBloqueioRepository.save(data)
    response.then(() => {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    })
    return response
  })

  useEffect(() => {
    return listaBloqueioAction.reset()
  }, [listaBloqueioAction])

  const handleSubmit = async (data: ListaBloqueioFormData) => {
    setLoading(true)
    if (data.cpf) {
      data.cpf = getAlphanumericDigits(data.cpf)
    }

    try {
      const response = await save(data)
    } catch (error) {
      snackbarActions.setMessage(error.message)
    }
  }
  const schemaObject = {
    cpf: yup
      .string()
      .test('cpf-validation', MessagesYup.MESSAGEM_VALOR_CPF_INVALIDO, value =>
        validateCPF(value || '')
      )
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    motivo: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    motivodesbloqueio: yup
      .string()
      .nullable()
      .when('datadesbloqueio', {
        is: (datadesbloqueio: string) => !isNil(datadesbloqueio),
        then: yup
          .string()
          .trim()
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
  }
  const schema = yup.object().shape(schemaObject)

  const dialogSchema = yup.object().shape({
    motivodesbloqueio: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
  })

  const handleActionsClick = (
    id: string,
    callback: (
      data: any
    ) => Promise<{ info: any; ok: boolean; status: number | undefined } | null>,
    operacao: string
  ) => {
    setConfirmationDialog({
      open: true,
      callback,
      id,
      mensagem: `A seguinte operação será executada:`,
      operacao: operacao,
      isBloqueio: operacao !== Operacao.desbloqueio
    })
  }

  const closeDialog = () => {
    //Modal começa a fechar
    setConfirmationDialog({
      ...confirmationDialog,
      open: false
    })

    //Após o TempoModal, os campos confirmationDialog se tornam vazios
    setTimeout(() => {
      setConfirmationDialog({
        mensagem: '',
        operacao: '',
        isBloqueio: false,
        open: false,
        callback: () => new Promise(() => {}),
        id: ''
      })
    }, TempoModal) //TempoModal é o tempo que demora para o Modal fechar/abrir por completo
  }

  const handleConfirmationDialog = async (data: any) => {
    const { id, callback } = confirmationDialog
    if (callback) {
      data.id = params.id
      await callback(data)
      if (listaBloqueioState.tag === 'with-data')
        await listaBloqueioAction.fetch(listaBloqueioState.entity.id)
    }
  }

  const customActionsFactory = () => {
    const actions = []
    if (listaBloqueioState.tag === 'with-data' && listaBloqueioState.entity) {
      if (!listaBloqueioState.entity.datadesbloqueio) {
        const desbloquearButton = {
          label: 'Desbloquear',
          disabled: confirmationDialog.open,
          classes: classes.desbloquear,
          onClick: () =>
            handleActionsClick(params.id, desbloquear, Operacao.desbloqueio)
        }
        actions.push(desbloquearButton)
      } else {
        const bloquearButton = {
          label: 'Bloquear',
          disabled: confirmationDialog.open,
          onClick: () =>
            handleActionsClick(params.id, bloquear, Operacao.bloqueio),
          classes: classes.bloquear
        }
        actions.push(bloquearButton)
      }
    }
    return actions
  }

  const customActions = customActionsFactory()

  const handleGoBack = () => {
    if (history.location.state?.from) {
      history.push(history.location.state.from, {
        ...history.location.state
      })
    } else {
      history.goBack()
    }
  }

  return (
    <>
      <Form
        validate={yupValidation(schema)}
        onSubmit={handleSubmit}
        initialValues={
          listaBloqueioState.tag === 'with-data' && listaBloqueioState.entity
        }
      >
        {({ handleSubmit, submitting, values }) => (
          <Paper>
            <FormWrap
              handleSubmit={handleSubmit}
              disableSubmit={submitting || confirmationDialog.open}
              disableBack={submitting || confirmationDialog.open}
              loading={loading}
              customActions={customActions}
              handleGoBack={handleGoBack}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MaskedField
                    fullWidth
                    name="cpf"
                    label="CPF"
                    mask={cpfRegex}
                    disabled={!isNewBloqueio}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RichTextField
                    name="motivo"
                    label="Motivo"
                  />
                </Grid>
                {values.datadesbloqueio && (
                  <Grid item xs={12}>
                    <RichTextField
                      name="motivodesbloqueio"
                      label="Motivo do Desbloqueio"
                    />
                  </Grid>
                )}
              </Grid>
            </FormWrap>
          </Paper>
        )}
      </Form>
      <ComplexDialog
        open={!!confirmationDialog.open}
        disableBackdropClick={true}
        closeActionButtonLabel="Não"
        handleClose={closeDialog}
        primaryAction={handleConfirmationDialog}
        title="Deseja confirmar essa operação?"
        message={confirmationDialog.mensagem}
        action={confirmationDialog.operacao}
        primaryActionButtonLabel="Confirmar"
        transitionDuration={TempoModal}
        validate={confirmationDialog.operacao === Operacao.desbloqueio && yupValidation(dialogSchema)}
      >
        {confirmationDialog.operacao === Operacao.desbloqueio && (
          <Grid item xs={12}>
            <RichTextField
              name="motivodesbloqueio"
              label="Motivo do Desbloqueio"
            />
          </Grid>
        )}
      </ComplexDialog>
    </>
  )
}
