import { useSnackbar } from "@elentari/core"
import {
  Divider,
  Grid,
  makeStyles,
  styled,
  Typography,
  Link as LinkMD
} from "@material-ui/core"
import { isNil } from "ramda"
import { useState, ChangeEvent } from "react"
import { Link } from "react-router-dom"
import { Paper } from "src/components"
import { FormatedDate } from "src/utils"
import { ModalHistorico } from "../../components/ModalHistorico"
import { updateSelecaoCandidato } from "../../hooks/selecaoCandidatoRepository"
import { MotoristaDetail } from "../../motorista/types"
import { CandidatoDetail, statusSelecao } from "../../types"
import { getNewestAgendamento, getNewestSelecao } from "../../utils"
import { compareceuOptions } from "../types"
import { CandidatoField } from "./CandidatoField"
import { RadioGroupSelecaoField } from "./RadioGroupSelecao"
import { ResultadoSelecao } from "./ResultadoSelecao"
import { TipoContratacaoSelecao } from "./TipoContratacaoSelecao"
import { TipoEntrevistaSelecao } from "./TipoEntrevistaSelecao"

interface SelecaoCandidatoCardProps {
  candidato: CandidatoDetail | MotoristaDetail
  canUpdate?: boolean
}

const useStyles = makeStyles(theme => ({
  paper: {
    border: '1px solid #c6c6c6',
    marginBottom: theme.spacing(3),
    width: 'fit-content'
  },
  marginTop: {
    marginTop: theme.spacing(3)
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    minHeight: '100px'
  },
  candidatoName: {
    width: '250px',
    fontSize: '1.2rem',
    fontWeight: 600
  },
  alignContent: {
    display: 'flex',
    alignItems: 'center'
  },
  justifyContent: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonGrid: {
    width: '50px'
  },
  candidatoMinWidth: {
    minWidth: '250px'
  },
  tipoEntrevistaMinWidth: {
    minWidth: '160px'
  }
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '3px',
  height: 'auto'
}));

export const SelecaoCandidatoCard = ({
  candidato
}: SelecaoCandidatoCardProps) => {
  const [, snackbarActions] = useSnackbar()
  const classes = useStyles()

  const candidatoSelecao = candidato.SelecaoCandidato ? getNewestSelecao(candidato.SelecaoCandidato) : undefined
  const candidatoAgendamento = candidato.AgendamentoCandidato ? getNewestAgendamento(candidato.AgendamentoCandidato) : undefined

  const [compareceuValue, setCompareceuValue] = useState<boolean | undefined>(candidatoSelecao?.compareceu)

  const handleOnChangeCompareceu = async (event: ChangeEvent<HTMLInputElement>, value: string) => {
    const eventValue = value === 'true' ? true : false

    if (candidatoSelecao && candidatoSelecao?.id) {
      const response = await updateSelecaoCandidato(candidatoSelecao.id, {
        compareceu: eventValue
      })

      if (response && response.ok) {
        setCompareceuValue(eventValue)
      } else {
        snackbarActions.setMessage(
          `Ocorreu um erro ao alterar o campo compareceu do(a) Candidato(a)`
        )
      }
    } else {
      snackbarActions.setMessage(
        `O Candidato(a) não possui nenhuma Seleção Cadastrada`
      )
    }
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.cardContainer}>
        <Grid container className={classes.candidatoMinWidth}>
          <Grid item xs={12}>
            <LinkMD
              component={Link}
              to={
                candidato.tipo === 'ADMINISTRATIVO' ?
                  `selecao-administrativo/${candidato.id}`
                  :
                  `selecao-motorista/${candidato.id}`
              }
            >
              <Typography className={classes.candidatoName}>{candidato.nomeCompleto}</Typography>
            </LinkMD>
          </Grid>
          <Grid item xs={12}>
            <Typography>{candidato.cpf}</Typography>
          </Grid>
          <Grid container spacing={2} className={classes.marginTop}>
            <Grid item xs={12}>
              <Typography>Status: {statusSelecao.find(selecao => selecao.value === candidatoSelecao?.status)?.label}</Typography>
            </Grid>
            <Grid item xs={6}>
              <CandidatoField
                title="Data Seleção"
                subtitle={FormatedDate(candidatoSelecao?.dataSelecao)}
              />
            </Grid>
            {(candidato.tipo === 'MOTORISTA' && 'Motorista' in candidato && candidato.Motorista.quemIndicou) && (
              <Grid item xs={6}>
                <CandidatoField
                  title="Quem Indicou"
                  subtitle={candidato.Motorista.quemIndicou}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <StyledDivider orientation="vertical" variant="middle" />

        <ResultadoSelecao
          selecaoCandidatoId={candidatoSelecao?.id}
          items={[
            { fieldLabel: 'Teste Prático', fieldName: 'testePratico', initialValue: candidatoSelecao?.testePratico },
            { fieldLabel: 'Teste Psicológico', fieldName: 'testePsicologico', initialValue: candidatoSelecao?.testePsicologico },
            { fieldLabel: 'Teste Técnico', fieldName: 'testeTecnico', initialValue: candidatoSelecao?.testeTecnico },
            { fieldLabel: 'Entrevista RH', fieldName: 'entrevistaRh', initialValue: candidatoSelecao?.entrevistaRh },
            { fieldLabel: 'Entrevista Gestor', fieldName: 'entrevistaGestor', initialValue: candidatoSelecao?.entrevistaGestor }
          ]}
          disabled={isNil(compareceuValue)}
        />

        <StyledDivider orientation="vertical" variant="middle" />

        <TipoContratacaoSelecao
          selecaoCandidatoId={candidatoSelecao?.id}
          tipoContratacao={candidatoSelecao?.tipoContratacao}
          subEmpresaId={candidatoSelecao?.subEmpresaId}
          disabled={isNil(compareceuValue)}
        />

        <StyledDivider orientation="vertical" variant="middle" />

        <Grid container className={classes.tipoEntrevistaMinWidth}>
          <TipoEntrevistaSelecao
            agendamentoId={candidatoAgendamento?.id}
            tipoEntrevista={candidatoAgendamento?.tipoEntrevista}
          />
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography align="center">Compareceu</Typography>
              </Grid>
              <Grid item xs={12} className={classes.justifyContent}>
                <RadioGroupSelecaoField
                  value={compareceuValue}
                  options={compareceuOptions}
                  onChange={handleOnChangeCompareceu}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <StyledDivider orientation="vertical" variant="middle" />

        <Grid container className={classes.buttonGrid}>
          <div className={classes.alignContent}>
            <ModalHistorico
              candidatoId={candidato.id}
              candidatoType={candidato.tipo === 'ADMINISTRATIVO' ? 'ADMINISTRATIVO' : 'MOTORISTA'}
              triagens={candidato.Triagem}
              disabled={false}
            />
          </div>
        </Grid>
      </div>
    </Paper>
  )
}