import TextField from '@elentari/components-eve/final-form/TextField'
import { useSnackbar } from '@elentari/core'
import { Grid } from '@material-ui/core'
import { isNil } from 'ramda'
import { useEffect } from 'react'
import { AnexoInput } from 'src/components/AnexoInput'
import * as yup from 'yup'
import ComplexDialog from '../../../../components/ComplexDialog'
import { MessagesYup } from '../../../../modules/messages'
import yupValidation from '../../../../utils/yupValidation'
import { uploadOneDocumento } from '../../hooks/documentosRepository'
import { useCandidatoDocumento } from '../../hooks/useCandidatoDocumento'
import { SaveAnexoCandidatoDocumentoInput } from './types'

type Props = {
  idDocumento: string | null
  idCandidato: string | null
  open: boolean
  handleClose: () => void
  fetchData: () => void
}

export const AnexoCandidatoDocumentoForm = ({
  idDocumento,
  idCandidato,
  open,
  handleClose,
  fetchData
}: Props) => {
  const [anexoCandidatoDocumentoState, anexoCandidatoDocumentoAction] =
    useCandidatoDocumento()
  const [, snackbarActions] = useSnackbar()

  const dialogSchema = yup.object().shape({
    descricao: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    anexo: yup.object().when('URL', {
      is: (URL: string) => isNil(URL),
      then: yup.object().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
  })

  const handleSubmit = async (data: SaveAnexoCandidatoDocumentoInput) => {
    try {
      if (!idCandidato) return snackbarActions.setMessage("Não foi possivel encontrar o Candidato")

      const documento = await uploadOneDocumento({
        descricao: data.descricao,
        file: data.anexo,
        candidatoId: idCandidato
      })
      if (!documento) {
        return snackbarActions.setMessage("Ocorreu um erro ao salvar o documento")
      }

      fetchData()
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  useEffect(() => {
    async function fetchAnexo() {
      if (idDocumento && idDocumento !== 'new') {
        await anexoCandidatoDocumentoAction.fetch(idDocumento)
      }
    }

    fetchAnexo()
  }, [idDocumento])

  useEffect(() => {
    return anexoCandidatoDocumentoAction.reset()
  }, [anexoCandidatoDocumentoAction, open])

  return (
    <ComplexDialog
      open={open}
      title={'Insira as informações do Anexo'}
      handleClose={handleClose}
      primaryAction={handleSubmit}
      validate={yupValidation(dialogSchema)}
      initialValues={
        anexoCandidatoDocumentoState.tag === 'with-data' && {
          id: anexoCandidatoDocumentoState.entity.id,
          descricao: anexoCandidatoDocumentoState.entity.descricao,
          URL: anexoCandidatoDocumentoState.entity.URL
        }
      }
      disableBackdropClick={true}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="descricao"
            label="Descrição do Documento"
          />
        </Grid>
        <Grid item xs={12}>
          <AnexoInput name="anexo" />
        </Grid>
      </Grid>
    </ComplexDialog>
  )
}
