import {
  CrudRoute,
  CrudTabs,
  useTabsNavigation
} from '@elentari/components-eve/components/CrudTabs'
import AnexosCandidatoTreinamento from '../AnexosCandidatoTreinamentoList'
import { CandidatoTreinamentoForm } from './CandidatoTreinamentoForm'
import { useState, useEffect } from 'react'
import qs from 'qs'
import { useLocation } from 'react-router'

export const CandidatoTreinamentoTabs = () => {
  const location = useLocation()
  const queryString = qs.parse(location.search.substring(1))
  const [from, setFrom] = useState<string>('')
  const hook = useTabsNavigation({
    mainPath: 'candidato-treinamento',
    tabs: [
      {
        value: '',
        label: 'Dados Treinamento'
      },
      {
        value: 'anexos',
        label: 'Documentos Treinamento'
      }
    ]
  })

  useEffect(() => {
    if (queryString.from === 'treinamento') {
      setFrom(queryString.from)
    }
  }, [])

  return (
    <CrudTabs {...hook}>
      <CrudRoute render={() => <CandidatoTreinamentoForm from={from} />} />
      <CrudRoute
        name={'anexos'}
        render={() => <AnexosCandidatoTreinamento />}
      />
    </CrudTabs>
  )
}
