import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { CandidatoTreinamentoTabs } from './CandidatoTreinamentoTabs'
import { useCandidatoTreinamento } from '../hooks/useCandidatoTreinamento'
import { useAppBar } from 'src/hooks'
import { handleStateErrorsToRender } from 'src/utils/handleFetchStateError'

export function CandidatoTreinamentoDetail() {
  const [candidatoTreinamentoState, candidatoTreinamentoActions] = useCandidatoTreinamento()
  const [, appBarActions] = useAppBar()

  candidatoTreinamentoState &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useReplaceFragments(appBarActions.replaceFragment, [
      mapFragmentReplace(
        candidatoTreinamentoState,
        u => u.entity.id,
        u => u.entity.candidatoFK?.nomeCompleto || ''
      )
    ])
  useFetchAndReset(candidatoTreinamentoActions.fetch, candidatoTreinamentoActions.reset)
  return handleStateErrorsToRender(candidatoTreinamentoState, <CandidatoTreinamentoTabs />)
}
