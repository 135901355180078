import Snackbar from '@elentari/components-eve/components/Snackbar'
import { Grid, Typography } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import LinkMD from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/styles'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import FileUploadInput from 'src/components/Formik/Forms/FileUploadInput'
import SelectInput from 'src/components/Formik/Forms/SelectInput'
import { getIdade, isCellPhone, optionsOrigemCurriculo } from 'src/utils'
import { FormikStep } from '../../../../components/Formik/FormikStepper'
import AutoCompleteInput from '../../../../components/Formik/Forms/AutoCompleteInput'
import DateInput from '../../../../components/Formik/Forms/DateInput'
import MaskInput from '../../../../components/Formik/Forms/MaskInput'
import TextInput from '../../../../components/Formik/Forms/TextInput'
import { Option } from '../../../../hooks/useAutoComplete'
import lugares from '../../../../utils/cidades.json'
import AdministrativoStep from '../../administrativo/steps/AdministrativoStep'
import { checaCpf } from '../../hooks/candidatoRepository'
import EscolaridadeStep from '../../motorista/steps/EscolaridadeStep'
import MotoristaStep from '../../motorista/steps/MotoristaStep'
import { CandidatoFormData } from '../../types'
import CursosInput from '../../../../components/Formik/Forms/CursosInput'

interface Props {
  label: string
  validationSchema: any
  candidatoType: 'Administrativo' | 'Motorista'
  isTriagem?: boolean
}

interface IHistory {
  candidatoFormValues: CandidatoFormData
}

interface Params {
  id: string
}

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: 'white'
  },
  uploadButton: {
    padding: '20px 0'
  }
})

export default function CandidatoStep({
  label,
  validationSchema,
  candidatoType,
  isTriagem
}: Props) {
  const [uf, setUF] = useState<Option[]>([])
  const [cidade, setCidade] = useState<Option[]>([])
  const [tipo, setTipo] = useState('')

  const [fetching, setFetching] = useState(false)
  const [resultadoBuscaCpf, setResultadoBuscaCpf] = useState('')
  const [ultimoCpfBuscado, setUltimoCpfBuscado] = useState('')

  const { values, setFieldValue, setValues } =
    useFormikContext<CandidatoFormData>()

  const classes = useStyles()

  const history = useHistory<IHistory>()

  const params = useParams<Params>()

  const isNew = params.id === 'new'

  //Cidade não é selecionada pois este useEffect é executado antes do select de cidades ser populado
  useEffect(() => {
    if (history.location.state?.candidatoFormValues) {
      setValues(history.location.state.candidatoFormValues)
    }
  }, [])

  useEffect(() => {
    if (window.location.pathname.includes('motorista')) {
      setTipo('Motorista')
    } else {
      setTipo('Administrativo')
    }
  }, [tipo])

  useEffect(() => {
    const ufs = lugares.estados.map(locais => {
      return {
        id: locais.sigla,
        name: locais.sigla
      }
    })
    setUF(ufs)
  }, [])

  useEffect(() => {
    const findUF = lugares.estados.find(cidade => cidade.sigla === values.uf)

    if (findUF) {
      const cidades = findUF.cidades.map(cidade => {
        return {
          id: cidade,
          name: cidade
        }
      })
      setCidade(cidades)
    }
  }, [values.uf])

  useEffect(() => {
    const match = lugares.estados.find(estado => estado.sigla === values.uf)
    if (!values.cidade || !match?.cidades.includes(values.cidade)) {
      setFieldValue('cidade', null)
    }
  }, [values.uf, setFieldValue, values.cidade])

  const handleChangeCpf = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const cpfApenasDigitos = e.target.value.replace(/[^\d]/g, '')

    if (
      cpfApenasDigitos &&
      cpfApenasDigitos.length === 11 &&
      cpfApenasDigitos !== ultimoCpfBuscado
    ) {
      setFetching(true)

      const response = await checaCpf(cpfApenasDigitos)
      if (response?.ok) {
        if (response?.info) {
          setResultadoBuscaCpf(response.info.id)
        }
      }

      setUltimoCpfBuscado(cpfApenasDigitos)
      setFetching(false)
    }
  }

  return (
    <FormikStep label={label} validationSchema={validationSchema}>
      <Grid container spacing={2}>
        {!isTriagem && (
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              {label}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextInput label="Nome Completo" name="nomeCompleto" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaskInput
            mask="999.999.999-99"
            label="CPF"
            name="cpf"
            onBlur={handleChangeCpf}
          />
          {fetching && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput label="E-mail" name="email" />
        </Grid>
        <Grid item xs={8} sm={4}>
          <DateInput
            label="Data Nascimento"
            name="dataNascimento"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <TextInput
            disabled={true}
            inputProps={{
              shrink: true
            }}
            name="idade"
            label="Idade"
            value={getIdade(values.dataNascimento ?? '')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaskInput mask="(99) 9 9999-9999" label="Celular" name="celular" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaskInput
            mask={
              values.telefone !== null && values.telefone && isCellPhone(values.telefone)
                ? '(99) 9 9999-9999'
                : '(99) 9999-9999'
            }
            label="Telefone"
            name="telefone"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoCompleteInput label="UF" name="uf" options={uf} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoCompleteInput
            label="Cidade"
            disabled={cidade.length === 0 || !values.uf}
            name="cidade"
            options={cidade}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.uploadButton}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FileUploadInput name="anexoCurriculo" label="Anexar Currículo" />
          </Grid>
          {candidatoType === 'Motorista' && (
            <Grid item xs={12}>
              <FileUploadInput name="anexoCNH" label="Anexar CNH" />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography style={{ fontWeight: 'bold', margin: 10 }} component="h6">
          Informações adicionais:
        </Typography>
        <SelectInput
          disabled={values.origemCurriculo === 'SITE' && isNew}
          name="origemCurriculo"
          label="Origem do Candidato(a)"
          options={optionsOrigemCurriculo}
        />
      </Grid>

      {candidatoType === 'Administrativo' && (
        <AdministrativoStep label="Administrativo" />
      )}

      {candidatoType === 'Motorista' && (
        <>
          <MotoristaStep label="Dados Motorista" />

          <EscolaridadeStep label="Escolaridade" />
        </>
      )}

      <CursosInput />

      <Snackbar
        severity="warning"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={resultadoBuscaCpf !== ''}
        message={
          <LinkMD
            component={Link}
            className={classes.link}
            to={{
              pathname: `/lista-bloqueios/${resultadoBuscaCpf}`,
              state: {
                candidatoFormValues: values,
                from: history.location.pathname
              }
            }}
          >
            CPF presente na Lista de Bloqueios. Clique para mais detalhes.
          </LinkMD>
        }
        onClose={() => setResultadoBuscaCpf('')}
      />
    </FormikStep>
  )
}
