import { LinearProgress } from "@material-ui/core"
import { useEffect, useState } from "react"
import ReactHtmlParser from 'react-html-parser'
import { When } from "src/components"
import { FormatedDate, tipoContratacao } from "src/utils"
import { fetchSelecaoCandidatoById } from "../hooks/selecaoCandidatoRepository"
import { SelecaoCandidatoDetail, statusSelecao } from "../types"
import { ModalDetailLabel } from "./ModalDetailLabel"

type SelecaoCandidatoModalDetailsProps = {
  idSelecao: string
}

export const SelecaoCandidatoModalDetails = ({ idSelecao }: SelecaoCandidatoModalDetailsProps) => {
  const [selecaoCandidato, setSelecaoCandidato] = useState<SelecaoCandidatoDetail>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    async function getSelecaoById(id: string) {
      setLoading(true)
      const response = await fetchSelecaoCandidatoById(id)

      if (response && response.ok) {
        setSelecaoCandidato(response.info)
      }
      setLoading(false)
    }

    if (idSelecao) {
      getSelecaoById(idSelecao)
    }
  }, [idSelecao])

  return (
    <>
      <When value={loading} equals>
        <LinearProgress />
      </When>
      {selecaoCandidato && (
        <>
          <ModalDetailLabel
            title="Selecionado por:"
            subtitle={selecaoCandidato.selecionadoPor}
          />
          <ModalDetailLabel
            title="Status:"
            subtitle={statusSelecao.find(statusSelecao => statusSelecao.value === selecaoCandidato.status)?.label ?? selecaoCandidato.status}
          />
          <ModalDetailLabel
            title="Data para o qual foi Selecionado:"
            subtitle={FormatedDate(selecaoCandidato.dataSelecao)}
          />
          <ModalDetailLabel
            title="Data em que a Seleção aconteceu:"
            subtitle={selecaoCandidato.createdAt && FormatedDate(selecaoCandidato.createdAt)}
          />
          <ModalDetailLabel
            title="Tipo da Contratação:"
            subtitle={tipoContratacao.find(tipoContratacao => tipoContratacao.value === selecaoCandidato.tipoContratacao)?.label ?? selecaoCandidato.tipoContratacao}
          />
          <ModalDetailLabel
            title="Compareceu:"
            subtitle={selecaoCandidato.compareceu ? 'Sim' : 'Não'}
          />
          <ModalDetailLabel
            title="Teste Prático:"
            subtitle={selecaoCandidato.testePratico ? 'Apto' : 'Inapto'}
            show={Boolean(selecaoCandidato.testePratico)}
          />
          <ModalDetailLabel
            title="Data Teste Prático:"
            subtitle={selecaoCandidato.dataTestePratico && FormatedDate(selecaoCandidato.dataTestePratico.toString())}
            show={Boolean(selecaoCandidato.dataTestePratico)}
          />
          <ModalDetailLabel
            title="Observação Teste Prático:"
            subtitle={selecaoCandidato.observacaoTestePratico && ReactHtmlParser(selecaoCandidato.observacaoTestePratico)}
            show={Boolean(selecaoCandidato.observacaoTestePratico)}
          />
          <ModalDetailLabel
            title="Teste Psicológico:"
            subtitle={selecaoCandidato.testePsicologico ? 'Apto' : 'Inapto'}
            show={Boolean(selecaoCandidato.testePsicologico)}
          />
          <ModalDetailLabel
            title="Data Teste Psicológico:"
            subtitle={selecaoCandidato.dataTestePsicologico && FormatedDate(selecaoCandidato.dataTestePsicologico.toString())}
            show={Boolean(selecaoCandidato.dataTestePsicologico)}
          />
          <ModalDetailLabel
            title="Observação Teste Psicológico:"
            subtitle={selecaoCandidato.observacaoTestePsicologico && ReactHtmlParser(selecaoCandidato.observacaoTestePsicologico)}
            show={Boolean(selecaoCandidato.observacaoTestePsicologico)}
          />
          <ModalDetailLabel
            title="Teste Técnico:"
            subtitle={selecaoCandidato.testeTecnico ? 'Apto' : 'Inapto'}
            show={Boolean(selecaoCandidato.testeTecnico)}
          />
          <ModalDetailLabel
            title="Data Teste Técnico:"
            subtitle={selecaoCandidato.dataTesteTecnico && FormatedDate(selecaoCandidato.dataTesteTecnico.toString())}
            show={Boolean(selecaoCandidato.dataTesteTecnico)}
          />
          <ModalDetailLabel
            title="Observação Teste Técnico:"
            subtitle={selecaoCandidato.observacaoTesteTecnico && ReactHtmlParser(selecaoCandidato.observacaoTesteTecnico)}
            show={Boolean(selecaoCandidato.observacaoTesteTecnico)}
          />
          <ModalDetailLabel
            title="Entrevista RH:"
            subtitle={selecaoCandidato.entrevistaRh ? 'Apto' : 'Inapto'}
            show={Boolean(selecaoCandidato.entrevistaRh)}
          />
          <ModalDetailLabel
            title="Data Entrevista RH:"
            subtitle={selecaoCandidato.dataEntrevistaRh && FormatedDate(selecaoCandidato.dataEntrevistaRh.toString())}
            show={Boolean(selecaoCandidato.dataEntrevistaRh)}
          />
          <ModalDetailLabel
            title="Observação Entrevista RH:"
            subtitle={selecaoCandidato.observacaoEntrevistaRh && ReactHtmlParser(selecaoCandidato.observacaoEntrevistaRh)}
            show={Boolean(selecaoCandidato.observacaoEntrevistaRh)}
          />
          <ModalDetailLabel
            title="Entrevista Gestor:"
            subtitle={selecaoCandidato.entrevistaGestor ? 'Apto' : 'Inapto'}
            show={Boolean(selecaoCandidato.entrevistaGestor)}
          />
          <ModalDetailLabel
            title="Data Entrevista Gestor:"
            subtitle={selecaoCandidato.dataEntrevistaGestor && FormatedDate(selecaoCandidato.dataEntrevistaGestor.toString())}
            show={Boolean(selecaoCandidato.dataEntrevistaGestor)}
          />
          <ModalDetailLabel
            title="Observação Entrevista Gestor:"
            subtitle={selecaoCandidato.observacaoEntrevistaGestor && ReactHtmlParser(selecaoCandidato.observacaoEntrevistaGestor)}
            show={Boolean(selecaoCandidato.observacaoEntrevistaGestor)}
          />
          <ModalDetailLabel
            title="Parecer RH:"
            subtitle={selecaoCandidato.parecerRh && ReactHtmlParser(selecaoCandidato.parecerRh)}
            show={Boolean(selecaoCandidato.parecerRh)}
          />
          <ModalDetailLabel
            title="Parecer Gestor:"
            subtitle={selecaoCandidato.parecerGestor && ReactHtmlParser(selecaoCandidato.parecerGestor)}
            show={Boolean(selecaoCandidato.parecerGestor)}
          />
        </>
      )
      }
    </>
  )
}