export interface SubEmpresaDetail {
  id?: string
  nome: string
  empresaId: string
}

export interface SaveSubEmpresaInput extends SubEmpresaDetail { }

export const compareceuOptions = [
  { label: "Sim", value: true },
  { label: "Não", value: false }
]