import { LinearProgress } from "@material-ui/core"
import { useEffect, useState } from "react"
import ReactHtmlParser from 'react-html-parser'
import { When } from "src/components"
import { motivoContratacao } from "src/modules/vagas/types"
import { FormatedDate, tipoContratacao, tiposOperacao } from "src/utils"
import { monetaryFormatter } from "src/utils/formatters"
import { fetchAdmissaoCandidatoById } from "../admissao-candidato/hooks/admissaoCandidatoRepository"
import { AdmissaoCandidatoDetail } from "../admissao-candidato/types"
import { statusAdmissao } from "../types"
import { ModalDetailLabel } from "./ModalDetailLabel"

type AdmissaoModalDetailsProps = {
  idAdmissao: string
}

export const AdmissaoCandidatoModalDetails = ({ idAdmissao }: AdmissaoModalDetailsProps) => {
  const [admissaoCandidato, setAdmissaoCandidato] = useState<AdmissaoCandidatoDetail>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    async function getAdmissaoById(id: string) {
      setLoading(true)
      const response = await fetchAdmissaoCandidatoById(id)

      if (response && response.ok) {
        setAdmissaoCandidato(response.info)
      }
      setLoading(false)
    }

    if (idAdmissao) {
      getAdmissaoById(idAdmissao)
    }
  }, [idAdmissao])

  return (
    <>
      <When value={loading} equals>
        <LinearProgress />
      </When>
      {admissaoCandidato && (
        <>
          <ModalDetailLabel
            title="Operação:"
            subtitle={tiposOperacao.find(tipoOperacao => tipoOperacao.value === admissaoCandidato.operacao)?.label ?? admissaoCandidato.operacao}
          />
          <ModalDetailLabel
            title="Coordenador:"
            subtitle={admissaoCandidato.coordenador}
          />
          <ModalDetailLabel
            title="Admitido por:"
            subtitle={admissaoCandidato.admitidoPor}
          />
          <ModalDetailLabel
            title="Grid:"
            subtitle={admissaoCandidato.gridFK && admissaoCandidato.gridFK.nome}
            show={Boolean(admissaoCandidato.gridFK)}
          />
          <ModalDetailLabel
            title="Status:"
            subtitle={statusAdmissao.find(statusAdmissao => statusAdmissao.value === admissaoCandidato.status)?.label ?? admissaoCandidato.status}
          />
          <ModalDetailLabel
            title="Tipo da Contratação:"
            subtitle={tipoContratacao.find(tipoContratacao => tipoContratacao.value === admissaoCandidato.tipoContratacao)?.label ?? admissaoCandidato.tipoContratacao}
          />
          <ModalDetailLabel
            title="Data da Entrega dos Documentos:"
            subtitle={FormatedDate(admissaoCandidato.dataEntregaDocumentos)}
          />
          <ModalDetailLabel
            title="Data Prevista do Exame:"
            subtitle={FormatedDate(admissaoCandidato.dataPrevistaExame)}
          />
          <ModalDetailLabel
            title="Data Prevista de Admissão:"
            subtitle={FormatedDate(admissaoCandidato.dataPrevistaAdmissao)}
          />
          <ModalDetailLabel
            title="Cargo:"
            subtitle={admissaoCandidato.cargo}
            show={Boolean(admissaoCandidato.cargo)}
          />
          <ModalDetailLabel
            title="Filial(CNPJ):"
            subtitle={admissaoCandidato.filialCNPJ}
            show={Boolean(admissaoCandidato.filialCNPJ)}
          />
          <ModalDetailLabel
            title="Centro de Custo:"
            subtitle={admissaoCandidato.centroCusto}
            show={Boolean(admissaoCandidato.centroCusto)}
          />
          <ModalDetailLabel
            title="Departamento:"
            subtitle={admissaoCandidato.departamento}
            show={Boolean(admissaoCandidato.departamento)}
          />
          <ModalDetailLabel
            title="Motivo Contratação:"
            subtitle={motivoContratacao.find(motivoContratacao => motivoContratacao.value === admissaoCandidato.motivoContratacao)?.label ?? admissaoCandidato.motivoContratacao}
            show={Boolean(admissaoCandidato.motivoContratacao)}
          />
          <ModalDetailLabel
            title="Nome Substituição:"
            subtitle={admissaoCandidato.nomeSubstituicao}
            show={Boolean(admissaoCandidato.nomeSubstituicao)}
          />
          <ModalDetailLabel
            title="Escala/Horário:"
            subtitle={admissaoCandidato.escalaHorario}
            show={Boolean(admissaoCandidato.escalaHorario)}
          />
          <ModalDetailLabel
            title="Salário Inicial:"
            subtitle={monetaryFormatter.format(admissaoCandidato.salarioInicial ?? 0)}
            show={Boolean(admissaoCandidato.salarioInicial)}
          />
          <ModalDetailLabel
            title="Salário Pós Experiência:"
            subtitle={monetaryFormatter.format(admissaoCandidato.salarioPosExperiencia ?? 0)}
            show={Boolean(admissaoCandidato.salarioPosExperiencia)}
          />
          <ModalDetailLabel
            title="Cargo de Confiança 40%:"
            subtitle={admissaoCandidato.cargoConfianca ? 'Sim' : 'Não'}
            show={Boolean(admissaoCandidato.cargoConfianca)}
          />
          <ModalDetailLabel
            title="Celular:"
            subtitle={admissaoCandidato.celular ? 'Sim' : 'Não'}
            show={Boolean(admissaoCandidato.celular)}
          />
          <ModalDetailLabel
            title="Notebook:"
            subtitle={admissaoCandidato.notebook ? 'Sim' : 'Não'}
            show={Boolean(admissaoCandidato.notebook)}
          />
          <ModalDetailLabel
            title="Chip:"
            subtitle={admissaoCandidato.chip ? 'Sim' : 'Não'}
            show={Boolean(admissaoCandidato.chip)}
          />
          <ModalDetailLabel
            title="Desktop:"
            subtitle={admissaoCandidato.desktop ? 'Sim' : 'Não'}
            show={Boolean(admissaoCandidato.desktop)}
          />
          <ModalDetailLabel
            title="Ramal:"
            subtitle={admissaoCandidato.ramal ? 'Sim' : 'Não'}
            show={Boolean(admissaoCandidato.ramal)}
          />
          <ModalDetailLabel
            title="Linha Coorporativa:"
            subtitle={admissaoCandidato.linhaCorporativa ? 'Sim' : 'Não'}
            show={Boolean(admissaoCandidato.linhaCorporativa)}
          />
          <ModalDetailLabel
            title="E-mail:"
            subtitle={admissaoCandidato.email ? 'Sim' : 'Não'}
            show={Boolean(admissaoCandidato.email)}
          />
          <ModalDetailLabel
            title="Pasta Compartilhada:"
            subtitle={admissaoCandidato.pastasCompartilhadas ? 'Sim' : 'Não'}
            show={Boolean(admissaoCandidato.pastasCompartilhadas)}
          />
          <ModalDetailLabel
            title="KMM:"
            subtitle={admissaoCandidato.kmm ? 'Sim' : 'Não'}
            show={Boolean(admissaoCandidato.kmm)}
          />
          <ModalDetailLabel
            title="Espelhar KMM para o Colaborador:"
            subtitle={admissaoCandidato.colaboradorKmmEspelhado}
            show={Boolean(admissaoCandidato.colaboradorKmmEspelhado)}
          />
          <ModalDetailLabel
            title="Notebook N° Patrimônio:"
            subtitle={admissaoCandidato.notebookNumPatrimonio}
            show={Boolean(admissaoCandidato.notebookNumPatrimonio)}
          />
          <ModalDetailLabel
            title="Celular N° IMEI:"
            subtitle={admissaoCandidato.celularIMEI}
            show={Boolean(admissaoCandidato.celularIMEI)}
          />
          <ModalDetailLabel
            title="Gerente da Área:"
            subtitle={admissaoCandidato.aprovadoPorGerenteArea}
            show={Boolean(admissaoCandidato.aprovadoPorGerenteArea)}
          />
          <ModalDetailLabel
            title="Gerente de RH:"
            subtitle={admissaoCandidato.aprovadoPorGerenteRh}
            show={Boolean(admissaoCandidato.aprovadoPorGerenteRh)}
          />
          <ModalDetailLabel
            title="Observação:"
            subtitle={admissaoCandidato.observacao && ReactHtmlParser(admissaoCandidato.observacao)}
            show={Boolean(admissaoCandidato.observacao)}
          />
        </>
      )}
    </>
  )
}