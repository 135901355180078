import {
  Button,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  Box
} from '@material-ui/core'
import { useHistory } from 'react-router'
import EditIcon from '@material-ui/icons/Edit'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { VagaDetail } from '../types'
import { Paper } from '../../../components'
import { StatusVagas, ModoSelecao } from '../types'
import { monetaryFormatter } from '../../../utils/formatters'
import ReactHtmlParser from 'react-html-parser'

const useStyles = makeStyles(theme => ({
  cardInicial: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  containerLocation: {
    display: 'flex',
    flexDirection: 'row'
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fill-available',
    padding: '2rem 0rem'
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right',
    padding: '2rem 0rem'
  },
  warningIcon: {
    color: 'red'
  },
  returnButton: {
    position: 'absolute',
    top: '2rem',
    left: '2rem'
  },
  itemVaga: {
    display: 'flex',
    alignItems: 'center',
    padding: 2
  },
  itemVagaLabel: {
    marginRight: 5
  },
  itemVagaContent: {
    paddingLeft: 10
  }
}))

export const VagaCard = (vaga: VagaDetail) => {
  const classes = useStyles()
  const history = useHistory()

  const handleReturnList = () => {
    history.push('/vagas')
  }

  const handleEditVaga = () => {
    history.push('/vagas/' + vaga.id)
  }

  return (
    <Box marginBottom={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Paper minHeight={300}>
            <Button
              className={classes.returnButton}
              color="default"
              onClick={handleReturnList}
              startIcon={<ArrowBackIcon />}
            >
              Retornar para a Listagem
            </Button>
            <CardContent>
              <div className={classes.containerLocation}>
                <div className={classes.dataContainer}>
                  <div className={classes.cardInicial}>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: 'bold' }}
                      gutterBottom
                    >
                      Vaga
                    </Typography>
                    <Typography
                      className={classes.itemVaga}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="left"
                    >
                      <strong className={classes.itemVagaLabel}>Cargo: </strong>
                      {vaga.cargoFk?.nome}
                    </Typography>
                    <Typography
                      className={classes.itemVaga}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="left"
                    >
                      <strong className={classes.itemVagaLabel}>Área: </strong>
                      {vaga.area}
                    </Typography>
                    <Typography
                      className={classes.itemVaga}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="left"
                    >
                      <strong className={classes.itemVagaLabel}>Categoria: </strong>
                      {vaga.categoria}
                    </Typography>
                    <Typography
                      className={classes.itemVaga}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="left"
                    >
                      <strong className={classes.itemVagaLabel}>Nível de Experiência: </strong>
                      {vaga.nivelExperiencia}
                    </Typography>
                    <Typography
                      className={classes.itemVaga}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="left"
                    >
                      <strong className={classes.itemVagaLabel}>Salário: </strong>
                      {monetaryFormatter.format(vaga.salario)}
                    </Typography>
                    <Typography
                      className={classes.itemVaga}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="left"
                    >
                      <strong className={classes.itemVagaLabel}>Status: </strong>
                      {
                        StatusVagas.find(status => status.value === vaga.status)
                          ?.label
                      }
                    </Typography>
                    <Typography
                      className={classes.itemVaga}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="left"
                    >
                      <strong className={classes.itemVagaLabel}>Modo de Seleção: </strong>
                      {
                        ModoSelecao.find(
                          modo => modo.value === vaga.modoSelecao
                        )?.label
                      }
                    </Typography>
                    
                    <Typography
                      className={classes.itemVaga}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      <strong className={classes.itemVagaLabel}>Descrição: </strong>
                      <div className={classes.itemVagaContent}>
                        {ReactHtmlParser(vaga.descricao)}
                      </div>
                    </Typography>
                  </div>
                </div>
                <div className={classes.iconContainer}>
                  <Tooltip title="Editar Vaga">
                    <IconButton
                      aria-label="edit"
                      onClick={handleEditVaga}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}
