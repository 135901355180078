/* eslint-disable eqeqeq */
import { Button, makeStyles, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { User } from 'src/modules/login/types'
import { Paper } from '../components'
import g10Logo from '../images/g10-logo-2.png'
import tpLogo from '../images/tp-logo.png'

type Data = {
  state: {
    statusCode: string
    timestamp: string
    path: string
    message?: string
  }
}

const useStyles = makeStyles({
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  root: {
    width: '100%',
    maxWidth: 980
  },
  status: {
    fontWeight: 'bold',
    lineHeight: 2,
    letterSpacing: 15
  },
  button: {
    marginTop: 100,
    width: '30%'
  },
  image: {
    position: 'absolute',
    top: 200,
    right: 200,
    width: 320,
    opacity: 0.1
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  }
})

export const HandleErrorPage = (state: any) => {
  const [data, setData] = useState(state as Data)
  const [logo, setLogo] = useState(g10Logo)
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    const user: User = JSON.parse(localStorage.user)
    if (user.empresa === 'G10') {
      setLogo(g10Logo)
    } else {
      setLogo(tpLogo)
    }
  }, [])

  return (
    <>
      {data.state && (
        <Paper>
          <div className={classes.page}>
            <div className={classes.root}>
              <Typography className={classes.status} variant="h2">
                {data.state.statusCode}
              </Typography>

              <br />

              <Typography>
                <strong>
                  Por favor, procurar a equipe técnica.
                  <br /> ocorreu um erro:{' '}
                </strong>
                {data.state.path}
              </Typography>
              <br />
              <Typography>
                <strong>Horário:</strong> {data.state.timestamp}
              </Typography>
              <br />
              {data.state.message && (
                <Typography>
                  <strong>Mensagem:</strong> {data.state.message}
                </Typography>
              )}

              <Button
                className={classes.button}
                type="submit"
                color="primary"
                variant="contained"
                onClick={() => history.push('/')}
              >
                Voltar para tela inicial
              </Button>
            </div>
            <img
              className={classes.image}
              src={logo}
              alt="G10|TP - Recursos Humanos"
            />
          </div>
        </Paper>
      )}
    </>
  )
}
