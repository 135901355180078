import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { ParsedQs } from 'qs'
import { isEmpty } from 'ramda'
import { apiSauceInstance } from 'src/services/api'
import { paginationParams } from 'src/utils/paginationQueryParams'
import { AnexoCandidatoDocumentoDetail, SaveAnexoCandidatoDocumentoInput } from '../components/documents/types'
import { DocumentosCandidatoDetail, SaveDocumentoCandidatoInput } from '../types'

export interface CreatedDocumentoId {
  id: string
}

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, limit, ...rest } = params

  return {
    ...paginationParams(rest),
    sort: sort || {
      createdAt: 'desc'
    },
    limit: limit || '10'
  }
}

export const candidatosDocumentosRepository = makeRepository<
  EdgesPage<AnexoCandidatoDocumentoDetail>,
  SaveAnexoCandidatoDocumentoInput,
  AnexoCandidatoDocumentoDetail,
  string
>({ resource: 'documentos', mapQueryParams }, apiSauceInstance)

export const findCurriculoCandidato = async (idCandidato: string) => {
  const response = await apiSauceInstance.get<AnexoCandidatoDocumentoDetail>(
    `/documentos/candidato-curriculo/${idCandidato}`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const findCNHCandidato = async (idCandidato: string) => {
  const response = await apiSauceInstance.get<AnexoCandidatoDocumentoDetail>(
    `/documentos/candidato-cnh/${idCandidato}`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const uploadOneDocumento = async (documento: SaveDocumentoCandidatoInput) => {
  if (!documento.file || isEmpty(documento.file)) return null

  const formData = new FormData()
  formData.append('upload', documento.file)

  if (documento.candidatoId) formData.append('candidatoId', documento.candidatoId)

  if (documento.descricao === 'anexoCurriculo') formData.append('descricao', 'Curriculo')
  else if (documento.descricao === 'anexoCNH') formData.append('descricao', 'CNH')
  else formData.append('descricao', documento.descricao)

  const res = await apiSauceInstance.post<DocumentosCandidatoDetail>(
    '/uploads/documento-candidato',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    }
  )

  if (res.ok) {
    return res.data!
  } else {
    return null
  }
}

export const uploadManyDocumentos = async (documentos: SaveDocumentoCandidatoInput[]) => {
  const createdDocumentos: CreatedDocumentoId[] = []

  for (const documento of documentos) {
    const doc = await uploadOneDocumento(documento)

    if (!doc) continue

    createdDocumentos.push({ id: doc.id! })
  }

  return createdDocumentos
}