import { useSnackbar } from '@elentari/core'
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useAppBar } from 'src/hooks'
import {
  DeleteRecord,
  NoResultsTable,
  Paper,
  TableContainer
} from '../../components'
import { TableCellHead } from '../../components/TableCellHead'
import When from '../../components/When'
import { getAuthorizations } from '../../utils/crudAuthorization'
import { useAbility } from '../login'
import { AddAnexo } from './components/AddAnexo'
import AnexoCandidatoTreinamentoFile from './components/AnexoCandidatoTreinamentoFile'
import { anexosCandidatoTreinamentoRepository, fetchAnexosByCandidatoTreinamentoId } from './hooks/anexosCandidatoTreinamentoRepository'
import { AnexoCandidatoTreinamentoForm } from './master-detail/AnexoCandidatoTreinamentoForm'
import { AnexoCandidatoTreinamentoDetail, AnexoCandidatoTreinamentoFormData } from './types'


interface Props {
  list: AnexoCandidatoTreinamentoFormData[]
  loading: boolean
  totalCount: number
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
  openFormModal: boolean,
  handleOpenFormModal: (idAnexoCandidatoTreinamento: string) => void
  handleCloseFormModal: () => void
  idAnexo: string
  fetchData: () => void
}

export const AnexosCandidatoTreinamentoTable = ({
  list,
  loading,
  totalCount,
  onDelete,
  authorization,
  openFormModal,
  handleOpenFormModal,
  handleCloseFormModal,
  idAnexo,
  fetchData
}: Props) => {
  return (
    <>
      <Paper noTopBorderRadius>
        <TableContainer loading={loading} totalCount={totalCount} disablePagination={true}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Descrição" />
                  <TableCellHead label="Anexo" />

                  <When
                    value={!authorization || authorization.canDelete}
                    equals
                  >
                    <TableCell></TableCell>
                  </When>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(anexoCandidatoTreinamento => (
                  <TableRow hover key={anexoCandidatoTreinamento.id}>
                    <TableCell>
                      {!authorization ||
                        (authorization.canUpdate && (
                          <When
                            value={!authorization || authorization.canUpdate}
                            equals
                          >
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => {
                                handleOpenFormModal(anexoCandidatoTreinamento.id)
                              }}
                            >
                              {anexoCandidatoTreinamento.descricao}
                            </Link>
                          </When>
                        ))}
                    </TableCell>
                    <TableCell>
                      <AnexoCandidatoTreinamentoFile id={anexoCandidatoTreinamento.id} />
                    </TableCell>
                    <When value={authorization?.canDelete} equals>
                      <DeleteRecord onDelete={() => onDelete(anexoCandidatoTreinamento.id)} />
                    </When>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddAnexo onClick={() => handleOpenFormModal('new')} />
      <AnexoCandidatoTreinamentoForm idAnexo={idAnexo} open={openFormModal} handleClose={handleCloseFormModal} fetchData={fetchData} />
    </>
  )
}

const AnexosCandidatoTreinamento = () => {
  const [anexosCandidatoTreinamento, setAnexosCandidatoTreinamento] = useState<AnexoCandidatoTreinamentoDetail[] | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [idAnexo, setIdAnexo] = useState<string>('')
  const location = useLocation()

  const handleOpenForm = (idAnexo: string) => {
    setOpenForm(true)
    setIdAnexo(idAnexo)
  }

  const handleCloseForm = () => {
    setOpenForm(false)
    setIdAnexo('')
  }

  async function fetchData() {
    setLoading(true)
    if (location.pathname.split('/')[2]) {
      const response = await fetchAnexosByCandidatoTreinamentoId(
        location.pathname.split('/')[2]
      )
      setAnexosCandidatoTreinamento(response?.info)
    }
    setLoading(false)
  }

  useEffect(() => {
    appBarActions.setTitle("Documentos Treinamento")
  }, [])

  useEffect(() => {
    fetchData()
  }, [location])


  const ability = useAbility()
  async function handleDelete(id: string) {
    const response = await anexosCandidatoTreinamentoRepository.delete(id)

    if (response.ok) {
      snackbarActions.setSnackBar({
        message: 'Deletado com sucesso',
        severity: 'success'
      })
      fetchData()
      return
    }
    const message = (response.data as unknown as any).message
    snackbarActions.setSnackBar({
      message: message as string,
      severity: 'warning'
    })
  }

  const authorization = getAuthorizations(ability, 'anexo-candidato-treinamento')

  return (
    <>
      {loading === false ? (
        anexosCandidatoTreinamento && anexosCandidatoTreinamento.length > 0 ? (
          <AnexosCandidatoTreinamentoTable
            list={anexosCandidatoTreinamento}
            totalCount={anexosCandidatoTreinamento.length}
            loading={loading}
            onDelete={handleDelete}
            authorization={authorization}
            openFormModal={openForm}
            handleOpenFormModal={handleOpenForm}
            handleCloseFormModal={handleCloseForm}
            idAnexo={idAnexo}
            fetchData={fetchData}
          />
        ) : (
          <AnexosCandidatoTreinamentoTable
            onDelete={() => { }}
            totalCount={0}
            list={[]}
            loading={false}
            openFormModal={openForm}
            handleOpenFormModal={handleOpenForm}
            handleCloseFormModal={handleCloseForm}
            idAnexo={idAnexo}
            fetchData={fetchData}
          />
        )
      ) : (
        <AnexosCandidatoTreinamentoTable
          onDelete={() => { }}
          totalCount={0}
          list={[]}
          loading={true}
          openFormModal={openForm}
          handleOpenFormModal={handleOpenForm}
          handleCloseFormModal={handleCloseForm}
          idAnexo={idAnexo}
          fetchData={() => { }}
        />
      )}
    </>
  )
}

export default AnexosCandidatoTreinamento
