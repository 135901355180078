import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { ReactNode } from 'react'
import logo from '../images/g10-logo-2.png'

const useStyles = makeStyles(theme => ({
  container: {
    padding: 20,
    minWidth: 300
  },
  logoContainer: {
    marginTop: 20
  }
}))

export const AppDialogBox = ({ children }: { children: ReactNode }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.container} elevation={4}>
      <Grid container direction="row" justify="center" alignItems="center">
        <img src={logo} alt="Logo" />
        <Grid item xs={12} className={classes.logoContainer}>
          <Typography align="center" variant="h4" gutterBottom>
            G10 - Recursos Humanos
          </Typography>
        </Grid>
      </Grid>
      <Grid>{children}</Grid>
    </Paper>
  )
}
