import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import TableContainerMD from '@material-ui/core/TableContainer'
import React, { ReactNode } from 'react'
import Pagination from './Pagination'
import Spacer from './Spacer'
import When from './When'

const useStyles = makeStyles(theme => ({
  tableContainer: {
    '& tbody': {
      wordBreak: 'break-word'
    }
  }
}))

export const TableContainer = ({
  children,
  loading,
  totalCount,
  disablePagination,
  enableBreakWord
}: {
  children: ReactNode
  totalCount: number
  loading: boolean
  disablePagination?: boolean
  enableBreakWord?: boolean
}) => {
  const classes = useStyles()

  return (
    <TableContainerMD className={enableBreakWord ? classes.tableContainer : undefined}>
      <When value={loading} equals>
        <LinearProgress />
      </When>
      {children}
      <Spacer y={2} />
      <Grid container justify="center">
        <Grid item>
          {!disablePagination &&
            <Pagination count={totalCount} />
          }
        </Grid>
      </Grid>
      <Spacer y={2} />
    </TableContainerMD>
  )
}
