import { Item } from '../components/AppWrap/MenuItems'
import makeGlobal from '@elentari/core/utils/makeGlobal'
import { StoreActions } from '@elentari/core/types'
import menuItems from '../modules/home/MenuItems'

interface Path {
  label: string
  path: string
}
export const flatItems = (item: Item): Item[] =>
  item.group ? item.items : [item]

const normalizeLabel = (fragmentpath: string) => {
  switch (fragmentpath) {
    case 'new':
      return 'Novo'
    default:
      return fragmentpath
  }
}

const createPaths = (pathname: string) => {
  const pathnameSplited = pathname ? pathname.split('/').filter(Boolean) : ['/']

  const pathsAndLabels = pathnameSplited.map((fragmentpath, fragmentindex) => {
    const pathToAppendandJoin: string[] = []
    pathnameSplited.forEach((path, pathIndex) => {
      if (pathIndex <= fragmentindex) {
        pathToAppendandJoin.push(path)
      }
    })

    const label =
      menuItems
        .flatMap(flatItems)
        .find(m => !m.group && (m.pathname.replace('/', '') === fragmentpath || m.name === fragmentpath))
        ?.label ?? normalizeLabel(fragmentpath)
    return { label, path: `/${pathToAppendandJoin.join('/')}` }
  })

  return pathsAndLabels
}

type State = {
  linksPaths: Path[]
  lastPath?: string
  showBreadcrumb: boolean
}

type Actions = {
  setTitle: (title: string) => void
  setPath: (pathname: string) => void
  changeBreadcrumbVisibility: (show: boolean) => void
  replaceFragment: (fragment: { [k: string]: string }) => void
  replacePaths: (linksPaths: Path[], lastPath: string) => void
}

const initialState: State = {
  lastPath: undefined,
  linksPaths: [],
  showBreadcrumb: true
}

const actions: StoreActions<State, Actions> = {
  setPath: store => pathname => {
    const paths = createPaths(pathname)
    store.setState({
      linksPaths: paths.reverse().slice(1).reverse(),
      lastPath: paths[0]?.label,
      showBreadcrumb: store.state.showBreadcrumb
    })
  },
  setTitle: store => title =>
    store.setState({
      ...store.state,
      lastPath: title,
      showBreadcrumb: store.state.showBreadcrumb
    }),
  changeBreadcrumbVisibility: store => show =>
    store.setState({
      ...store.state,
      showBreadcrumb: show
    }),
  replaceFragment: store => mapFragments => {
    const newPaths = store.state.linksPaths.map(path => ({
      label: mapFragments[path.label] ? mapFragments[path.label] : path.label,
      path: path.path
    }))

    store.setState({
      ...store.state,
      linksPaths: newPaths,
      lastPath:
        store.state.lastPath && mapFragments[store.state.lastPath]
          ? mapFragments[store.state.lastPath]
          : store.state.lastPath
    })
  },
  replacePaths: store => (linksPaths, lastPath) => {
    store.setState({
      ...store.state,
      linksPaths: linksPaths,
      lastPath: lastPath
    })
  }
}

const useAppBar = makeGlobal<State, Actions>(initialState, actions)

export default useAppBar
