import { useSnackbar } from '@elentari/core'
import { FormikValues, useFormikContext } from 'formik'
import { ReactNode, useState, useEffect } from 'react'
import { FormWrap } from 'src/components'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import {
  divulgarVaga,
  lastVagaByCargo,
  vagasRepository
} from '../../hooks/vagaRepository'
import { useHistory } from 'react-router'
import { ResultadoColors } from 'src/utils'
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  InputAdornment
} from '@material-ui/core'
import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { SaveVagaInput } from '../../types'
import ComplexDialog from 'src/components/ComplexDialog'
import TextField from '@elentari/components-eve/final-form/TextField'
import { requestNewCargo } from '../../hooks/vagaRepository'
import { CargoAreaRepository } from '../../../cargos/cargo-area/hooks/CargoAreaRepository'
import { CargoCategoriaRepository } from '../../../cargos/cargo-categoria/hooks/CargoCategoriaRepository'
import yupValidation from '../../../../utils/yupValidation'
import * as yup from 'yup'
import { MessagesYup } from '../../../messages'
import RichTextField from 'src/components/Forms/RichTextField'
import CurrencyInput from 'src/components/Forms/CurrencyField'
import { Option } from 'src/hooks/useAutoComplete'
import RFFAutocomplete from 'src/components/Forms/RFFAutocomplete'
import { CustomAction } from 'src/components/FormWrap'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reprovado: {
      [theme.breakpoints.up('sm')]: {
        width: '160px'
      },
      backgroundColor: ResultadoColors.REPROVADO,
      '&:hover': {
        color: 'white',
        backgroundColor: ResultadoColors.REPROVADO_DARK
      }
    },
    aprovado: {
      [theme.breakpoints.up('sm')]: {
        width: '160px'
      },
      backgroundColor: ResultadoColors.APROVADO,
      '&:hover': {
        color: 'white',
        backgroundColor: ResultadoColors.APROVADO_DARK
      }
    },
    novoCargo: {
      [theme.breakpoints.up('sm')]: {
        width: '160px'
      },
      backgroundColor: ResultadoColors.NOVO_CARGO,
      '&:hover': {
        color: 'black',
        backgroundColor: ResultadoColors.NOVO_CARGO_DARK
      }
    }
  })
)

export const VagaFormWrap = ({
  children,
  vagaId,
  formLoading,
  isNew
}: {
  children: ReactNode
  vagaId: string
  formLoading: boolean
  isNew: boolean
}) => {
  const {
    handleSubmit,
    isSubmitting,
    values,
    setValues,
    validateForm,
    setErrors,
    setTouched
  } = useFormikContext<FormikValues>()
  const [formWrapLoading, setFormWrapLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const [confirmationDialog, setConfirmationDialog] = useState<{
    open: boolean
  }>({
    open: false
  })
  const [cargoAreas, setCargoAreas] = useState<Option[]>([])
  const [cargoCategoria, setCargoCategorias] = useState<Option[]>([])
  const userLogged = useLoggedUser()
  const isGestorRh = userLogged?.rolesInSystem.includes('GESTOR_RH')
  const history = useHistory()
  const classes = useStyles()

  useEffect(() => {
    loadCargoAreas()
    loadCargoCategorias()
  }, [])

  const handleUseLastVagaByCargo = async () => {
    setFormWrapLoading(true)
    if (values.cargoId) {
      const response = await lastVagaByCargo(values.cargoId)

      if (response?.ok) {
        if (response.data) {
          setValues({
            cargoId: response.data.cargoId,
            area: response.data.area,
            categoria: response.data.categoria,
            descricao: response.data.descricao,
            modoSelecao: response.data.modoSelecao,
            nivelExperiencia: response.data.nivelExperiencia,
            salario: Number(response.data.salario),
            salarioMin: Number(response.data.salarioMin),
            salarioMax: Number(response.data.salarioMax),
            vagaSigilosa: response.data.vagaSigilosa,
            equipamentos: response.data.equipamentos,
            divulgaSite: response.data.divulgaSite,
            status: 'PENDENTE_APROVACAO',
            solicitadoPor: userLogged?.name,
            motivoContratacao: undefined,
            nomeSubstituicao: undefined
          })
        } else {
          snackbarActions.setMessage(
            'Não existe nenhuma vaga cadastrada utilizando este cargo'
          )
        }
      } else {
        snackbarActions.setMessage(
          'Não foi possível carregar a última vaga cadastrada utilizando este cargo'
        )
      }
    } else {
      snackbarActions.setMessage(
        'É necessário selecionar um cargo para realizar a busca'
      )
    }
    setFormWrapLoading(false)
  }

  const handleCloseDialog = () => {
    setConfirmationDialog({
      open: false
    })
  }

  const handleOpenDialog = async () => {
    setConfirmationDialog({
      open: true
    })
  }

  const handleConfirmDialog = async (data: any) => {
    data.solicitadoPor = userLogged?.name

    const responseCargo = await requestNewCargo(data)
    if (responseCargo.ok) {
      snackbarActions.setMessage(
        'Sua solicitação de cargo foi enviada para o RH, assim que criado você poderá solicitar a vaga.'
      )
    } else {
      snackbarActions.setMessage(
        'Ocorreu um erro ao enviar a solicitação de novo cargo.'
      )
    }
  }

  const { save } = useEntitySaver<SaveVagaInput>(async data => {
    const response = await vagasRepository.save(data)
    setTimeout(() => {
      setFormWrapLoading(false)
    }, 1000)

    return response
  })

  const handleUpdateStatusVaga = async (
    status: 'EM_DIVULGACAO' | 'REJEITADA'
  ) => {
    setFormWrapLoading(true)

    delete values.cargoFk

    await save({
      ...values,
      status
    } as SaveVagaInput)
  }

  const handleDivulgarVaga = async () => {
    setFormWrapLoading(true)

    const response = await divulgarVaga(vagaId)

    if (response.ok) {
      snackbarActions.setMessage('Email enviado com sucesso!')
    } else if (response.data.message) {
      snackbarActions.setMessage(response.data.message)
    } else {
      snackbarActions.setMessage('Ocorreu um erro ao enviar o email')
    }

    setFormWrapLoading(false)
  }

  const loadCargoAreas = async () => {
    const response = await CargoAreaRepository.fetch({
      sort: {
        descricao: 'asc'
      },
      limit: '999'
    })

    if (response.ok) {
      setCargoAreas(
        response.data?.edges.map(area => ({
          id: area.node.descricao,
          name: area.node.descricao
        })) ?? []
      )
    }
  }

  const loadCargoCategorias = async () => {
    const response = await CargoCategoriaRepository.fetch({
      sort: {
        descricao: 'asc'
      },
      limit: '999'
    })

    if (response.ok) {
      setCargoCategorias(
        response.data?.edges.map(categoria => ({
          id: categoria.node.descricao,
          name: categoria.node.descricao
        })) ?? []
      )
    }
  }

  const customActionsFactory = () => {
    const actions = []
    if (isNew) {
      const useLastVagaByCargo: CustomAction = {
        label: 'Última Vaga',
        disabled: false,
        onClick: handleUseLastVagaByCargo,
        classes: '',
        position: 'left'
      }
      const requestNewCargo: CustomAction = {
        label: 'Novo Cargo',
        disabled: false,
        onClick: handleOpenDialog,
        classes: classes.novoCargo,
        position: 'left'
      }
      if (values.cargoId) {
        actions.push(useLastVagaByCargo)
      }

      if (!values.cargoId) {
        actions.push(requestNewCargo)
      }
    } else {
      if (isGestorRh && values.id && values.status === 'PENDENTE_APROVACAO') {
        const approveVaga: CustomAction = {
          label: 'Aprovar',
          disabled: false,
          onClick: () => {
            handleUpdateStatusVaga('EM_DIVULGACAO')
          },
          classes: classes.aprovado,
          position: 'right'
        }
        const rejectVaga: CustomAction = {
          label: 'Rejeitar',
          disabled: false,
          onClick: () => {
            handleUpdateStatusVaga('REJEITADA')
          },
          classes: classes.reprovado,
          position: 'right'
        }
        actions.push(rejectVaga)
        actions.push(approveVaga)
      }
      if (
        isGestorRh &&
        values.status === 'EM_DIVULGACAO' &&
        !values.vagaSigilosa
      ) {
        const divulgarVaga: CustomAction = {
          label: 'Divulgar Vaga',
          disabled: false,
          onClick: handleDivulgarVaga,
          classes: '',
          position: 'right'
        }
        actions.push(divulgarVaga)
      }
    }
    return actions
  }
  const customActions = customActionsFactory()

  const dialogSchema = yup.object().shape({
    nomeCargo: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    categoria: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    area: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    nivelExperiencia: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    descricao: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    salarioMin: yup
      .number()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .moreThan(0, MessagesYup.MENSAGEM_VALOR_ZERO)
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO),
    salarioMax: yup
      .number()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .test({
        name: 'salario-max-valid',
        message: "O valor informado é inferior ao 'Salário Mínimo'",
        test: (salarioMax, context) => {
          if (!salarioMax || !context.parent.salarioMin) {
            return false
          }
          return salarioMax >= context.parent.salarioMin
        }
      })
  })

  return (
    <>
      <FormWrap
        handleSubmit={handleSubmit}
        disableSubmit={isSubmitting || !values.cargoId}
        disableBack={isSubmitting}
        hideConfirmButton={isGestorRh}
        loading={formLoading || formWrapLoading}
        customActions={customActions}
        handleGoBack={() => history.goBack()}
      >
        {children}
      </FormWrap>
      <ComplexDialog
        open={confirmationDialog.open}
        title="Solicitar Novo Cargo"
        handleClose={handleCloseDialog}
        primaryAction={handleConfirmDialog}
        disableBackdropClick={true}
        validate={yupValidation(dialogSchema)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth name="nomeCargo" label="Nome do Novo Cargo" />
          </Grid>
          <Grid item xs={6}>
            <RFFAutocomplete name="area" label="Área" options={cargoAreas} />
          </Grid>
          <Grid item xs={6}>
            <RFFAutocomplete
              name="categoria"
              label="Categoria"
              options={cargoCategoria}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextField name="descricao" label="Descrição" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="nivelExperiencia"
              label="Nível de experiência"
            />
          </Grid>
          <Grid item xs={6}>
            <CurrencyInput
              name="salarioMin"
              label="Salário Mínimo"
              startAdornment={
                <InputAdornment position="start">R$</InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CurrencyInput
              name="salarioMax"
              label="Salário Máximo"
              startAdornment={
                <InputAdornment position="start">R$</InputAdornment>
              }
            />
          </Grid>
        </Grid>
      </ComplexDialog>
    </>
  )
}
