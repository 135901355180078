import { EdgesPage } from '@elentari/core/types'
import QueryString from 'qs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAbility } from 'src/modules/login'
import { apiSauceInstance } from 'src/services/api'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import { MotoristaDetail } from '../motorista/types'
import { CandidatoDetail } from '../types'
import { ContratadoCandidatoFilter } from './ContratadoCandidatoFilter'
import { ContratadoCandidatoTable } from './ContratadoCandidatoTable'

const ContratadoCandidatoList = (props: {
  list: { node: CandidatoDetail }[]
  loading: boolean
  totalCount: number
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
  candidatoType: 'ADMINISTRATIVO' | 'MOTORISTA'
}) => {
  return (
    <>
      <ContratadoCandidatoFilter candidatoType={props.candidatoType} />
      {props.candidatoType === 'MOTORISTA' ? (
        <ContratadoCandidatoTable
          {...props}
          list={props.list as { node: MotoristaDetail }[]}
        />
      ) : (
        <ContratadoCandidatoTable {...props} />
      )}
    </>
  )
}

type APIResponseCandidatos = {
  edges: { node: CandidatoDetail }[]
  totalCount: number
  loading: boolean
}

const ContratadoCandidato = ({
  candidatoType
}: {
  candidatoType: 'ADMINISTRATIVO' | 'MOTORISTA'
}) => {
  const [candidatosState, setCandidatosState] = useState<APIResponseCandidatos>(
    {} as APIResponseCandidatos
  )
  const { search, pathname } = useLocation()
  const ability = useAbility()

  const authorization = getAuthorizations(ability, 'motorista')

  useEffect(() => {
    async function loadCandidatos() {
      try {
        setCandidatosState({
          ...candidatosState,
          loading: true
        })

        const params: { [key: string]: any } = {
          sort: { createdAt: 'desc' },
          ...QueryString.parse(search.substring(1))
        }

        const response = await apiSauceInstance.get<EdgesPage<CandidatoDetail>>(
          `/candidato?${QueryString.stringify(params)}`,
          {
            tipo: candidatoType,
            statusCandidato: 'CONTRATADO',
            skip: params.page ? (+params.page - 1) * 10 : 0,
            limit: 10
          }
        )
        if (response.ok && response.data) {
          setCandidatosState({
            totalCount: response.data.totalCount,
            edges: response.data.edges,
            loading: false
          })
        } else {
          setCandidatosState({
            totalCount: 0,
            edges: [],
            loading: false
          })
        }
      } catch (error) {}
    }
    loadCandidatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search])

  return (
    <>
      {candidatosState.loading === false ? (
        <ContratadoCandidatoList
          totalCount={candidatosState.totalCount}
          list={candidatosState.edges}
          loading={candidatosState.loading}
          authorization={authorization}
          candidatoType={candidatoType}
        />
      ) : (
        <ContratadoCandidatoList
          totalCount={0}
          list={[]}
          loading={candidatosState.loading}
          authorization={authorization}
          candidatoType={candidatoType}
        />
      )}
    </>
  )
}

export const ContratadoMotorista = () => (
  <ContratadoCandidato candidatoType="MOTORISTA" />
)
export const ContratadoAdministrativo = () => (
  <ContratadoCandidato candidatoType="ADMINISTRATIVO" />
)
