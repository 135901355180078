import DatePicker from '@elentari/components-eve/final-form/DatePicker';
import Select from '@elentari/components-eve/final-form/Select';
import { useSnackbar } from "@elentari/core";
import { Button, Grid, Tooltip } from "@material-ui/core";
import EventIcon from '@material-ui/icons/Event';
import { useState } from "react";
import ComplexDialog from "src/components/ComplexDialog";
import { useLoggedUser } from "src/modules/login/AuthProvider";
import { MessagesYup } from "src/modules/messages";
import { tipoContratacao } from "src/utils";
import yupValidation from "src/utils/yupValidation";
import * as yup from 'yup';
import { createSelecaoCandidato } from "../hooks/selecaoCandidatoRepository";

type ModalFormData = {
  dataSelecao?: Date
  tipoContratacao?: string
}

interface CreateSelecaoButtonProps {
  candidatoId: string | false
  buttonLabel: string
  className?: string
  dialog?: {
    title?: string
    acceptLabel?: string
    rejectLabel?: string
  }
  formValues?: {
    tipoContratacao?: string
    dataSelecao?: Date
  }
  onSaveSuccessful?: () => Promise<void>
}

export const CreateSelecaoButton = ({
  candidatoId,
  buttonLabel,
  className,
  dialog,
  formValues,
  onSaveSuccessful
}: CreateSelecaoButtonProps) => {

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const userLogged = useLoggedUser()

  const dialogSchema = yup.object().shape({
    dataSelecao: yup
      .date()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
    tipoContratacao: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  })

  const handleSelecionarCandidato = async (modalData: ModalFormData) => {
    try {
      if (candidatoId && userLogged?.name) {
        if (modalData.tipoContratacao && modalData.dataSelecao) {
          const result = await createSelecaoCandidato({
            dataSelecao: modalData.dataSelecao,
            tipoContratacao: modalData.tipoContratacao,
            candidatoId: candidatoId,
            selecionadoPor: userLogged?.name
          })

          if (result?.ok && result.info) {
            if (onSaveSuccessful) {
              await onSaveSuccessful()
            }
            snackbarActions.setMessage('Candidato(a) aprovado(a) com sucesso')
          } else {
            snackbarActions.setMessage(
              'Ocorreu um erro ao aprovar o(a) Candidato(a)'
            )
          }
        } else {
          snackbarActions.setMessage('É necessário informar o tipo de contratação e a data')
        }
      } else {
        snackbarActions.setMessage('Não foi possível encontrar o candidato')
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  const handleOnClick = () => {
    if (formValues) return handleSelecionarCandidato(formValues)
    return setOpenDialog(true)
  }

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        className={className}
        onClick={handleOnClick}
      >
        {buttonLabel}
      </Button>

      <ComplexDialog
        open={openDialog}
        title={dialog?.title ?? "Deseja selecionar este Candidato(a)?"}
        primaryActionButtonLabel={dialog?.acceptLabel ?? "Selecionar"}
        closeActionButtonLabel={dialog?.rejectLabel ?? "Voltar"}
        handleClose={() => setOpenDialog(false)}
        primaryAction={handleSelecionarCandidato}
        validate={yupValidation(dialogSchema)}
        initialValues={{
          tipoContratacao: formValues?.tipoContratacao,
          dataSelecao: formValues?.dataSelecao
        }}
        disableBackdropClick={true}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DatePicker
              fullWidth
              name="dataSelecao"
              label="Data Seleção"
              keyboardIcon={
                <Tooltip title="Selecionar data">
                  <EventIcon />
                </Tooltip>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              fullWidth
              name="tipoContratacao"
              label="Tipo da Contratação"
              items={tipoContratacao}
            />
          </Grid>
        </Grid>
      </ComplexDialog>
    </>
  )
}