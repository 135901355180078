import { useInteresse } from '../hooks/useInteresse'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { InteresseForm } from './InteresseForm'

export function InteresseMasterDetail() {
  const [interesseState, interesseActions] = useInteresse()
  const [, appBarActions] = useAppBar()

  interesseState &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useReplaceFragments(appBarActions.replaceFragment, [
      mapFragmentReplace(
        interesseState,
        u => u.entity.id,
        u => u.entity.descricao
      )
    ])
  useFetchAndReset(interesseActions.fetch, interesseActions.reset)
  return handleStateErrorsToRender(interesseState, <InteresseForm />)
}
