import { useCargo } from '../hooks/useCargo'
import { useAppBar } from '../../../../hooks'
import { handleStateErrorsToRender } from '../../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { CargoTabs } from './CargoTabs'

export function CargoDetail() {
  const [CargoState, CargoActions] = useCargo()
  const [, appBarActions] = useAppBar()

  CargoState &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useReplaceFragments(appBarActions.replaceFragment, [
      mapFragmentReplace(
        CargoState,
        u => u.entity.id,
        u => u.entity.nome
      )
    ])
  useFetchAndReset(CargoActions.fetch, CargoActions.reset)
  return handleStateErrorsToRender(CargoState, <CargoTabs />)
}
