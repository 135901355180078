import Filter from '@elentari/components-eve/components/Filter'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams, useSnackbar } from '@elentari/core'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import LinkMD from '@material-ui/core/Link'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import {
  AddRecord,
  DeleteRecord,
  NoResultsTable,
  Paper,
  TableContainer
} from '../../../components'
import Spacer from '../../../components/Spacer'
import { TableCellHead } from '../../../components/TableCellHead'
import When from '../../../components/When'
import { getAuthorizations } from '../../../utils/crudAuthorization'
import { HandleErrorPage } from '../../../utils/handleErrorPage'
import yupValidation from '../../../utils/yupValidation'
import { useAbility } from '../../login'
import { MessagesYup } from '../../messages'
import { CargoCategoriaRepository } from './hooks/CargoCategoriaRepository'
import { useCargoCategoriaList } from './hooks/useCargosCategorias'
import { SubmitValues, CargoCategoriaFormData } from './types'

interface Props {
  list: CargoCategoriaFormData[]
  loading: boolean
  totalCount: number
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

const schema = yup.object().shape({
  descricao: yup.string().typeError(MessagesYup.MENSAGEM_CAMPO_STRING)
})

export const CargoCategoriaTable = ({
  list,
  loading,
  totalCount,
  onDelete,
  authorization
}: Props) => {
  return (
    <>
      <CargoCategoriaFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Descrição" sort="descricao" />

                  <When
                    value={!authorization || authorization.canDelete}
                    equals
                  >
                    <TableCell></TableCell>
                  </When>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(cargoCategoria => (
                  <TableRow hover key={cargoCategoria.id}>
                    <TableCell>
                      {!authorization ||
                        (authorization.canUpdate && (
                          <When
                            value={!authorization || authorization.canUpdate}
                            equals
                          >
                            <LinkMD
                              component={Link}
                              to={`/cargo-categoria/${cargoCategoria.id}`}
                            >
                              {cargoCategoria.descricao}
                            </LinkMD>
                          </When>
                        ))}
                    </TableCell>
                    <When value={authorization?.canDelete} equals>
                      <DeleteRecord
                        onDelete={() => onDelete(cargoCategoria.id)}
                      />
                    </When>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="cargo-categoria" authorization={authorization} />
    </>
  )
}

type CargoCategoriaQueryFilter = {
  [key: string]: any
}

const CargoCategoriaFilter = () => {
  const { clear, push, initialValues } =
    useQueryParams<CargoCategoriaQueryFilter>()

  const handleSubmitFilter = (values: SubmitValues) => {
    push(values)
  }

  const format = (initialValues: CargoCategoriaQueryFilter) => {
    return initialValues
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter(values)}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField fullWidth name="descricao" label="Descrição" />
        </Grid>
      </Grid>
    </Filter>
  )
}

const CargoCategoria = () => {
  const [state, actions] = useCargoCategoriaList()
  const [, snackbarActions] = useSnackbar()

  const ability = useAbility()
  async function handleDelete(id: string) {
    const response = await CargoCategoriaRepository.delete(id)
    actions.fetchPage()

    if (response.ok) {
      snackbarActions.setSnackBar({
        message: 'Deletado com sucesso',
        severity: 'success'
      })
      actions.fetchPage()
      return
    }
    const message = (response.data as unknown as any).message
    snackbarActions.setSnackBar({
      message: message as string,
      severity: 'warning'
    })
  }

  const authorization = getAuthorizations(ability, 'cargoCategoria')

  switch (state.tag) {
    case 'empty':
      return (
        <CargoCategoriaTable
          onDelete={() => {}}
          totalCount={0}
          list={[]}
          loading={true}
        />
      )
    case 'with-data':
      return (
        <CargoCategoriaTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onDelete={handleDelete}
          authorization={authorization}
        />
      )
    case 'error':
      return <HandleErrorPage state={state.data} />
  }
}

export default CargoCategoria
