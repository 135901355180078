import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import { apiSauceInstance } from 'src/services/api'
import { AdministrativoProcessoInfo } from '../../components/AdministrativoProcessoInfo'
import { EtapasAdministrativoCards } from '../../components/EtapasAdministrativoCard'

type StateProps = {
  candidatoId: string
}

export const ProcessoAdministrativoView = () => {
  const location = useLocation()

  const state = location.state as StateProps
  const [, setProcessoId] = useState('')
  const [candidatoId, setCandidatoId] = useState('')
  const processoSeletivoAdministrativoId = location.pathname.split('/')[2]
  const [processoEmpresaSigla, setProcessoEmpresaSigla] = useState<string>('')
  const userLogged = useLoggedUser()

  useEffect(() => {
    async function loadInfo() {
      if (processoSeletivoAdministrativoId !== 'new') {
        setProcessoId(processoSeletivoAdministrativoId)
      }
      if (state && state.candidatoId) {
        setCandidatoId(state.candidatoId)
      } else if (!state) {
        const { data } = await apiSauceInstance.get<any>(
          `/processo-seletivo-administrativos/${processoSeletivoAdministrativoId}`
        )
        if (data) {
          setCandidatoId(data.candidatoId)
          setProcessoEmpresaSigla(data.empresaFK?.sigla)
        } else {
          window.location.replace('/')
        }
      }
    }
    loadInfo()
  }, [state, processoSeletivoAdministrativoId])
  
  const userEmpresaEqualsProcessoEmpresa = (): boolean => {
    if(processoEmpresaSigla) {
      return processoEmpresaSigla=== userLogged?.empresa
    }

    return false
  }

  return (
    <>
      <AdministrativoProcessoInfo
        candidatoId={candidatoId}
        processoContratacaoId={processoSeletivoAdministrativoId}
        showIcons={userEmpresaEqualsProcessoEmpresa()}
      />

      {processoSeletivoAdministrativoId !== 'new' && (
        <EtapasAdministrativoCards
          processoSeletivoId={processoSeletivoAdministrativoId}
          showIcons={userEmpresaEqualsProcessoEmpresa()}
        />
      )}
    </>
  )
}
