import { ButtonBase, createStyles, makeStyles, Typography } from "@material-ui/core"
import { useState } from "react"
import theme from 'src/Theme'

type NavigationButtonProps = {
  label: string
  direction: 'left' | 'right'
  onClick: () => void
  disabled?: boolean
  arrowPointerSize?: number
}

export const NavigationButton = ({
  label,
  direction,
  onClick,
  disabled,
  arrowPointerSize
}: NavigationButtonProps) => {

  const arrowPointerWidth = arrowPointerSize ?? 20
  const directionArrow = direction === 'right' ? 'after' : 'before'
  const isRight = direction === 'right'
  const [backgroundColor, setBackgroundColor] = useState(theme.palette.grey[300])

  const useStyles = makeStyles(theme =>
    createStyles({
      buttonContainer: {
        paddingTop: '20px',
        paddingRight: isRight ? '40px' : undefined,
        paddingLeft: !isRight ? '40px' : undefined
      },
      arrowButton: {
        [`&::${directionArrow}`]: {
          content: '""',
          position: 'absolute',
          right: isRight ? `-${20 + arrowPointerWidth}px` : undefined,
          left: !isRight ? `-${20 + arrowPointerWidth}px` : undefined,
          top: `${0 - arrowPointerWidth}px`,
          borderTop: `${20 + arrowPointerWidth}px solid transparent`,
          borderBottom: `${20 + arrowPointerWidth}px solid transparent`,
          borderLeft: isRight ? `${20 + arrowPointerWidth}px solid ${backgroundColor}` : undefined,
          borderRight: !isRight ? `${20 + arrowPointerWidth}px solid ${backgroundColor}` : undefined,
        },
        position: 'relative',
        width: '100px',
        background: `${backgroundColor}`,
        height: '40px',
        lineHeight: '40px',
        marginBottom: '30px',
        textAlign: 'center',
        color: `${theme.palette.text.primary}`,
      },
    })
  )

  const styles = useStyles()

  const handleOnMouseOver = () => {
    setBackgroundColor(theme.palette.grey[400])
  }

  const handleOnMouseOut = () => {
    setBackgroundColor(theme.palette.grey[300])
  }

  return (
    <div className={styles.buttonContainer}>
      <ButtonBase
        className={styles.arrowButton}
        onClick={onClick}
        disabled={disabled}
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
        disableRipple={true}
      >
        <Typography variant="button">
          {label}
        </Typography>
      </ButtonBase>
    </div>
  )
}