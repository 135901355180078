import { makeAutoObservable } from "mobx";
import { fetchListaAgendamento } from "../hooks/agendamentoCandidatoRepository";

class AgendamentoStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  agendamentosId: string[] = []
  currentIndex: number = 0

  async fetchAgendamentos(filter: string, agendamentoIdSelected: string) {
    const response = await fetchListaAgendamento(filter)
    if (response) {
      const responseFormatted = response.info.filter(agendamentoId => agendamentoId !== agendamentoIdSelected)
      this.agendamentosId = [agendamentoIdSelected, ...responseFormatted]
      this.currentIndex = 0
    }
  }

  nextAgendamentoId(): string | null {
    const nextIndex = this.currentIndex + 1
    if (this.agendamentosId[nextIndex] !== undefined) {
      this.currentIndex = nextIndex
      return this.agendamentosId[nextIndex]
    } else {
      return null
    }
  }

  previousAgendamentoId() {
    this.currentIndex--
  }

  resetAgendamentoId() {
    this.agendamentosId = []
    this.currentIndex = 0
  }
}

const agendamentoStore = new AgendamentoStore()

export default agendamentoStore