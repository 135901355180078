import { apiSauceInstance } from "src/services/api"
import { CandidatoInfo, GridInfo, ProcessoAdministrativoInfo, VagasInfo } from "../types"

export const getVagasInfo = async (params?: Record<string, unknown>) => {
  const response = await apiSauceInstance.get<VagasInfo>(
    `/dashboard/vagas-info`,
    params
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const getProcessosAdministrativoInfo = async (params?: Record<string, unknown>) => {
  const response = await apiSauceInstance.get<ProcessoAdministrativoInfo>(
    `/dashboard/processo-seletivo-administrativo-info`,
    params
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const getCandidatosInfo = async (params?: Record<string, unknown>) => {
  const response = await apiSauceInstance.get<CandidatoInfo>(
    `/dashboard/candidato-info`,
    params
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const getGridsInfo = async (params?: Record<string, unknown>) => {
  const response = await apiSauceInstance.get<GridInfo>(
    `/dashboard/grids-info`,
    params
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}