import { EdgesPage } from "@elentari/core/types"
import { makeRepository, paginationParams } from "@elentari/core/utils/makeRepository"
import { ParsedQs } from "qs"
import { isEmpty } from "ramda"
import { apiSauceInstance } from "src/services/api"
import { AnexoCandidatoTreinamentoDetail, SaveAnexoCandidatoTreinamentoInput } from "../types"

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, limit, ...rest } = params

  return {
    ...paginationParams(rest),
    sort: sort || {
      createdAt: 'desc'
    },
    limit: limit || '10'
  }
}

export const anexosCandidatoTreinamentoRepository = makeRepository<
  EdgesPage<AnexoCandidatoTreinamentoDetail>,
  SaveAnexoCandidatoTreinamentoInput,
  AnexoCandidatoTreinamentoDetail,
  string
>({ resource: 'anexos-candidato-treinamento', mapQueryParams }, apiSauceInstance)

export const fetchAnexosByCandidatoTreinamentoId = async (id: string) => {
  const response = await apiSauceInstance.get<any>(
    `/anexos-candidato-treinamento/candidato-treinamento/${id}`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return info
}

export const uploadOneAnexoCandidatoTreinamento = async (anexosCandidatoTreinamento: SaveAnexoCandidatoTreinamentoInput) => {
  if (!anexosCandidatoTreinamento.anexo || isEmpty(anexosCandidatoTreinamento.anexo)) return null

  const formData = new FormData()
  formData.append('upload', anexosCandidatoTreinamento.anexo)

  if (anexosCandidatoTreinamento.candidatoTreinamentoId) formData.append('candidatoTreinamentoId', anexosCandidatoTreinamento.candidatoTreinamentoId)

  formData.append('descricao', anexosCandidatoTreinamento.descricao)

  const res = await apiSauceInstance.post<AnexoCandidatoTreinamentoDetail>(
    '/uploads/anexo-candidato-treinamento',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    }
  )

  if (res.ok) {
    return res.data!
  } else {
    return null
  }
}