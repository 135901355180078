import { useSnackbar } from "@elentari/core"
import { CircularProgress, FormControl, FormHelperText, TextField } from "@material-ui/core"
import { Autocomplete, createFilterOptions } from "@material-ui/lab"
import { useField } from "formik"
import { useEffect, useState } from "react"
import { apiSauceInstance } from "src/services/api"

interface ObservacaoInputProps {
  name: string
  label: string
  disabled: boolean
}

type ObservacaoOption = {
  label: string
  value?: string
  inputValue?: string
}

const filter = createFilterOptions();

const ObservacaoInput = ({
  name,
  label,
  disabled
}: ObservacaoInputProps) => {
  const [field, meta, helpers] = useField(name)
  const [, snackbarActions] = useSnackbar()

  const [options, setOptions] = useState<ObservacaoOption[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const createOptionsByName = async (name: string) => {
    setLoading(true)
    const response = await apiSauceInstance.get<string[]>(`/selecao-candidatos/observacoes/${name}`);

    if (response.ok && response.data && Array.isArray(response.data)) {
      setOptions(response.data.map(observacao => { return { label: observacao, value: observacao } }))
    } else {
      snackbarActions.setMessage(`Ocorreu um erro ao buscar observações previamente cadastradas.`)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (name) {
      createOptionsByName(name)
    }
  }, [name])

  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={meta.touched && Boolean(meta.error)}
      disabled={disabled}
    >
      <Autocomplete
        fullWidth
        options={options}
        value={field.value}
        onChange={(event, newValue) => {
          if (newValue && newValue.inputValue) {
            // Adicionar ao formulário a opção criada pelo usuário
            helpers.setValue(newValue.inputValue)
          } else if (newValue && newValue.value) {
            helpers.setValue(newValue.value);
          } else {
            helpers.setValue(newValue)
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Sugerir a criação de uma option
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              label: `Adicionar "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) => {
          // Retornar valor selecionado com tecla enter, direto do input
          if (typeof option === 'string') {
            return option;
          }
          // Retornar opção criada pelo usuário
          if (option.inputValue) {
            return option.inputValue;
          }
          // Retornar opção normal
          return option.label;
        }}
        renderOption={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        disabled={disabled}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
      />
      <FormHelperText>{meta.touched && meta.error}</FormHelperText>
    </FormControl>
  )
}

export default ObservacaoInput