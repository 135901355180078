import { IoIosArrowDown } from 'react-icons/io'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core/styles'

import { useSort } from '@elentari/core'
import { Tooltip } from '@material-ui/core'

const CustomTableCell = withStyles(() => ({
  root: {
    color: '',
    fontWeight: 'bold',
    fontSize: 14
  }
}))(TableCell)

interface TableCellHeadProps {
  label: string
  sort?: string
  className?: any
  tooltip?: string
}

/**
 * @param label word to show in the column
 * @param sort word that will go in the URL for API
 */

export function TableCellHead({ label, sort, className, tooltip }: TableCellHeadProps) {
  const [state, { sortBy }] = useSort()

  const renderLabel = ():JSX.Element => {
    if(sort) {
      return (
        <TableSortLabel
          IconComponent={IoIosArrowDown}
          direction={state.direction}
          onClick={() => sortBy(sort)}
        >
          {label}
        </TableSortLabel>
      )
    } else {
      return <span>{label}</span>
    }
  }

  return (
    <CustomTableCell className={className}>
      {tooltip ?
        <Tooltip title={tooltip} arrow>
          {renderLabel()}
        </Tooltip>
      :
        renderLabel()
      }
    </CustomTableCell>
  )
}
