import Filter from '@elentari/components-eve/components/Filter'
import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import { Grid, Tooltip } from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import { useEffect, useState } from 'react'
import { FormSpy } from 'react-final-form'
import RFFAutocomplete from 'src/components/Forms/RFFAutocomplete'
import { Option } from 'src/hooks/useAutoComplete'
import * as yup from 'yup'
import lugares from '../../../utils/cidades.json'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'

type ContratadoCandidatoQueryFilter = {
  createdAt?: { gt: string }
  [key: string]: any
}

const schema = yup.object().shape({
  dataContratacaoStart: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  dataContratacaoEnd: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .test({
      name: 'data-entrega-documento-valid',
      message: "A data informada é inferior a 'Data Entrega Documentos de'",
      test: (dataContratacaoEnd, context) => {
        if (!dataContratacaoEnd || !context.parent.dataContratacaoStart) {
          return true
        }
        return dataContratacaoEnd >= context.parent.dataContratacaoStart
      }
    })
})

const ContratadoCandidatoFilter = ({
  candidatoType
}: {
  candidatoType: 'ADMINISTRATIVO' | 'MOTORISTA'
}) => {
  const [uf, setUF] = useState<Option[]>([])

  useEffect(() => {
    const ufs = lugares.estados.map(locais => {
      return {
        id: locais.sigla,
        name: locais.sigla
      }
    })
    setUF(ufs)
  }, [])

  const getCidadesFromUf = (uf: string): Option[] => {
    const findUF = lugares.estados.find(cidade => cidade.sigla === uf)

    if (findUF) {
      const cidades = findUF.cidades.map(cidade => {
        return {
          id: cidade,
          name: cidade
        }
      })

      return cidades
    } else {
      return []
    }
  }

  const { clear, push, initialValues } =
    useQueryParams<ContratadoCandidatoQueryFilter>()

  const handleSubmitFilter = (values: any) => {
    if (values.dataContratacaoStart) {
      values.dataContratacaoStart = new Date(
        values.dataContratacaoStart
      ).toISOString()
    }
    if (values.dataContratacaoEnd) {
      values.dataContratacaoEnd = new Date(
        values.dataContratacaoEnd
      ).toISOString()
    }
    push({
      ...values
    })
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={initialValues}
      onClear={clear}
      onSubmit={values => handleSubmitFilter(values)}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField fullWidth name="name" label="Nome Completo" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField fullWidth name="cpf" label="CPF" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <RFFAutocomplete name="uf" label="UF" options={uf} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormSpy
            render={({ values }) => (
              <RFFAutocomplete
                name="cidade"
                label="Cidade"
                options={getCidadesFromUf(values.uf)}
                disabled={!values.uf}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField fullWidth name="supervisorDireto" label="Coordenador" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            fullWidth
            name="dataContratacaoStart"
            label="Data Contração de"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            fullWidth
            name="dataContratacaoEnd"
            label="Data Contratação até"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export { ContratadoCandidatoFilter }
