import {
  Grid, makeStyles, Typography
} from '@material-ui/core'
import { ResultadoSelecaoItem } from './ResultadoSelecaoItem'
import { ResultadoSelecaoLabel } from './ResultadoSelecaoLabel'

const useStyles = makeStyles(theme => ({
  disabledLabel: {
    color: 'rgba(0, 0, 0, 0.40)'
  },
  minWidth: {
    minWidth: '240px'
  }
}))

export type ResultadoSelecaoItemType = {
  fieldName: string,
  fieldLabel: string,
  initialValue: boolean | undefined
}

interface ResultadoSelecaoProps {
  selecaoCandidatoId: string | undefined
  items: ResultadoSelecaoItemType[]
  disabled: boolean
}

export const ResultadoSelecao = ({
  selecaoCandidatoId,
  items,
  disabled
}: ResultadoSelecaoProps) => {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography align="center" className={disabled ? classes.disabledLabel : undefined}>Resultado Seleção</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} className={classes.minWidth}>
          <Grid item xs={7}></Grid>
          <Grid item xs={2}>
            <ResultadoSelecaoLabel
              label="Apto"
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={3}>
            <ResultadoSelecaoLabel
              label="Inapto"
              disabled={disabled}
            />
          </Grid>
          {items.map((item, index) => <ResultadoSelecaoItem key={index} selecaoCandidatoId={selecaoCandidatoId} resultadoSelecao={item} disabled={disabled} />)}
        </Grid>
      </Grid>
    </Grid>
  )
}