import { useSnackbar } from '@elentari/core'
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { AppDialogBox } from 'src/components/AppDialogBox'
import { LoadingPage } from 'src/components/LoadingPage'
import { Paper } from '../../components'
import logoG10 from '../../images/g10-logo-2.png'
import logoTP from '../../images/tp-logo.png'
import { DropzoneDialogDocumentos } from './DropzoneDialogDocumentos'
import {
  fetchValidTokenByIdAndReturnDocuments,
  saveUploadS3,
  updateDocumentsById
} from './hooks/uploadDocumentos'
import { DocumentosDataExterno, ErrorMessage } from './types'

const useStyles = makeStyles(themes => ({
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eeeeee',
    minHeight: '100vh',
    '& .MuiDialogActions-root': {
      background: 'black'
    }
  },
  message: {
    marginTop: 40,
    marginBottom: 40
  },
  fieldset: {
    marginTop: 20,

    border: '2px solid #99999960',
    borderRadius: 8,
    padding: 20
  },
  legend: {
    color: themes.palette.primary.main
  }
}))

const Documents = () => {
  const classes = useStyles()
  const location = useLocation()
  const [documentos, setDocumentos] = useState<DocumentosDataExterno>()
  const [open, setOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({} as any)
  const [documentoId, setDocumentoId] = useState('')
  const [loading, setLoading] = useState(false)

  const [, snackBarActions] = useSnackbar()

  async function handleUploadFile(file: File[]): Promise<void> {
    try {
      setLoading(true)
      const uniqueFile = file[0]
      if (!uniqueFile) {
        return snackBarActions.setMessage('O arquivo é obrigatório!')
      }
      setOpen(false)

      const responseFile = await saveUploadS3({ file: uniqueFile })
      if (responseFile) {
        const urlS3 = responseFile.info.url
        const token = location.pathname.split('/')[2]

        const responseUpdateDocument = await updateDocumentsById(
          documentoId,
          urlS3,
          token
        )

        if (responseUpdateDocument.ok && responseUpdateDocument.info) {
          handleCloseModalSetLoadingFalse()
          setDocumentos(responseUpdateDocument.info as DocumentosDataExterno)
          snackBarActions.setMessage('Salvo com sucesso!')
        } else {
          throw new Error(responseUpdateDocument.info.message)
        }
      } else {
        throw new Error(
          'Ocorreu um erro ao salvar o arquivo, tente novamente mais tarde!'
        )
      }

      handleCloseModalSetLoadingFalse()
    } catch (error: any) {
      handleCloseModalSetLoadingFalse()
      snackBarActions.setMessage(error.message)
    }
  }

  const handleCloseModalSetLoadingFalse = () => {
    setLoading(false)
    setOpen(false)
  }

  function handleOpenDropzoneDialog(id: string, open: boolean) {
    if (id) {
      setDocumentoId(id)
      setOpen(open)
    }
  }

  const onClose = () => {
    setOpen(false)
    setDocumentoId('')
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const paramsId = location.pathname.split('/')[2]
        if (paramsId) {
          const response = await fetchValidTokenByIdAndReturnDocuments(paramsId)
          if (response.info && response.ok) {
            setDocumentos(response.info as DocumentosDataExterno)
          } else {
            setErrorMessage({
              info: response?.info?.message,
              open: true
            })
          }
        }
      } catch (error: any) {
        snackBarActions.setSnackBar({
          message: error.message.message,
          severity: 'error'
        })
      }
    }

    fetchData()
  }, [location])

  useEffect(() => {
    if (documentos) {
      document.title = `${documentos?.empresaSigla} - Recursos Humanos`
      const favIcon = document.getElementById('icon') as HTMLLinkElement
      if (favIcon) {
        if (documentos?.empresaSigla === 'TP') {
          favIcon.href = '/faviconTP.ico'
        } else {
          favIcon.href = '/favicon.ico'
        }
      }
    }
  }, [documentos])

  useEffect(() => {
    document.title = ''
    const favIcon = document.getElementById('icon') as HTMLLinkElement
    favIcon.href = ''
  }, [])

  if (errorMessage && errorMessage.open) {
    return (
      <div className={classes.background}>
        <AppDialogBox>
          <Typography
            align="center"
            variant="body1"
            className={classes.message}
          >
            {errorMessage.info}
          </Typography>
        </AppDialogBox>
      </div>
    )
  }

  if (!documentos) {
    return <LoadingPage open={true} />
  }

  return (
    <Grid container spacing={2} className={classes.background}>
      <DropzoneDialogDocumentos
        handleUploadFile={handleUploadFile}
        onClose={onClose}
        loading={loading}
        open={open}
      />
      <Paper>
        <Container maxWidth="lg">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom={5}
          >
            {documentos.empresaSigla === 'G10' ? (
              <img src={logoG10} alt="G10 Logo" />
            ) : (
              <img src={logoTP} alt="Transpanorama Logo" />
            )}
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="primary"
                align="center"
                component="h1"
              >
                Inclusão de Anexos
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
              xs={12}
            >
              <Typography variant="caption">
                Envio de documentos para andamento da etapa de conclusão do
                processo seletivo.
              </Typography>
            </Grid>

            {documentos.documentos.map(doc => (
              <Grid item xs={12} sm={documentos.documentos.length > 1 ? 6 : 12}>
                <Tooltip
                  key={doc.id}
                  placement="top"
                  title={
                    !!!doc.URL
                      ? `Fazer upload do arquivo "${doc.descricao}"`
                      : 'Upload do arquivo feito com sucesso'
                  }
                >
                  <fieldset className={classes.fieldset}>
                    <legend className={classes.legend}>
                      <Typography variant="body1" component="h1">
                        {doc.descricao}:
                      </Typography>
                    </legend>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={doc.URL === '' ? false : true}
                      color="primary"
                      onClick={() => handleOpenDropzoneDialog(doc.id, true)}
                    >
                      <DownloadIcon />
                    </Button>
                  </fieldset>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Paper>
    </Grid>
  )
}

export default Documents
