import { makeUseFetchOne } from '@elentari/core/hooks/makeUseFetchOne'
import { AnexoProcessoSeletivoAdministrativoDocumentoDetail } from '../types'
import { candidatosDocumentosRepository } from './documentosAdminRepository'

export const useCandidatoDocumento = makeUseFetchOne<
  AnexoProcessoSeletivoAdministrativoDocumentoDetail,
  string
>({
  fetch: candidatosDocumentosRepository.fetchOne
})
