import { EdgesPage } from "@elentari/core/types"
import { Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { NoResultsTable, Paper } from "src/components"
import ComplexDialog from "src/components/ComplexDialog"
import { ShowDetailsIcon } from "src/components/Icons/ShowDetails"
import Spacer from "src/components/Spacer"
import { StatusChip } from "src/components/StatusChip"
import { FormatedDate } from "src/utils"
import { fetchHistoricoCandidatoFromCandidatoId } from "../../hooks/historicoCandidatoRepository"
import { HistoricoCandidatoDetail, TipoHistorico, TriagemDetail } from "../../types"
import { aprovarTriagemOptions, formatMotivoTriagem } from "../../utils/triagemOptions"
import { AdmissaoCandidatoModalDetails } from "../AdmissaoCandidatoModalDetail"
import { AgendamentoCandidatoModalDetails } from "../AgendamentoCandidatoModalDetails"
import { SelecaoCandidatoModalDetails } from "../SelecaoCandidatoModalDetails"
import { TriagemModalDetails } from "../TriagemModalDetails"
import { HistoricoTable } from "./HistoricoTable"
import { HistoricoTableCellHead } from './HistoricoTableCellHead'
import historicoCandidatoStore from "./store"

type HistoricoCandidatoProps = {
  open: boolean
  candidatoId: string | false | undefined
  Triagens: TriagemDetail[] | false | undefined
}

type ModalAction = {
  action: () => void
  buttonLabel: string
}

const modalHistoricoTitles = {
  'TRIAGEM': 'Triagem',
  'AGENDAMENTO': 'Agendamento',
  'SELECAO': 'Seleção',
  'ADMISSAO': 'Admissão'
}

const useStyles = makeStyles({
  showHistorico: {
    display: 'visible'
  },
  hideHistorico: {
    display: 'none'
  },
  modalContainer: {
    minWidth: 500
  }
})

const HistoricoCandidato = observer(({
  open,
  candidatoId
}: HistoricoCandidatoProps) => {

  const [historicoCandidato, setHistoricoCandidato] = useState<EdgesPage<HistoricoCandidatoDetail>>({
    edges: [],
    totalCount: 0
  })

  const [loading, setLoading] = useState<boolean>(false)

  const classes = useStyles()

  const TempoModal = 100
  const [confirmationDialog, setConfirmationDialog] = useState<{
    title: string
    primaryAction?: ModalAction
    open: boolean
    tipo: TipoHistorico | 'NONE'
    itemIndex: number
  }>({
    title: '',
    open: false,
    tipo: 'NONE',
    itemIndex: -1
  })

  const handleOpenDialog = (
    title: string,
    tipo: TipoHistorico | 'NONE',
    itemIndex: number,
    primaryAction?: ModalAction
  ) => {
    setConfirmationDialog({
      title,
      tipo,
      itemIndex,
      open: true,
      primaryAction
    })
  }

  const closeDialog = () => {
    setConfirmationDialog({
      ...confirmationDialog,
      open: false
    })

    setTimeout(() => {
      setConfirmationDialog({
        title: '',
        tipo: 'NONE',
        itemIndex: -1,
        open: false
      })
    }, TempoModal)
  }

  useEffect(() => {
    historicoCandidatoStore.setPage(1)
    historicoCandidatoStore.setSort({
      createdAt: 'desc'
    })
  }, [])

  useEffect(() => {
    async function loadHistoricoCandidato() {
      if (candidatoId) {
        setLoading(true)

        const response = await fetchHistoricoCandidatoFromCandidatoId(candidatoId, {
          page: `${historicoCandidatoStore.page}`,
          sort: historicoCandidatoStore.sort
        })

        if (response.ok && response.data) {
          setHistoricoCandidato(response.data)
        }

        setLoading(false)
      }
    }
    loadHistoricoCandidato()
  }, [candidatoId, historicoCandidatoStore.page, historicoCandidatoStore.sort])

  const hasTriagemReprovada: boolean = historicoCandidato?.edges.length > 0 && historicoCandidato.edges.some(item => item.node.type === 'TRIAGEM' && item.node.tipoContratacao !== undefined && !aprovarTriagemOptions.find(motivoAprovacao => motivoAprovacao.value === item.node.tipoContratacao))

  return (
    <div className={classes.modalContainer}>
      <Spacer y={2} className={open ? classes.showHistorico : classes.hideHistorico} />

      <Paper className={open ? classes.showHistorico : classes.hideHistorico}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              align="center"
            >
              Histórico
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <HistoricoTable
              totalCount={historicoCandidato.totalCount}
              loading={loading}
            >
              {historicoCandidato?.edges.length ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <HistoricoTableCellHead label="Data" sort="createdAt" />
                      <HistoricoTableCellHead label="Fase da Seleção" sort="type" />
                      <HistoricoTableCellHead label="Status" />
                      {hasTriagemReprovada && (
                        <HistoricoTableCellHead label="Motivo" />
                      )}
                      {historicoCandidato.edges.some(item => item.node.tipoContratacao) && (
                        <HistoricoTableCellHead label="Tipo Contratação" sort="tipoContratacao" />
                      )}
                      <HistoricoTableCellHead label="Usuário" sort="usuario" />
                      <HistoricoTableCellHead label="Comentários RH" sort="comentarioRh" />
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {historicoCandidato.edges.map((item, index) => {
                      const reprovadoTriagem: boolean = !aprovarTriagemOptions.find(motivoAprovacao => motivoAprovacao.value === item.node.tipoContratacao)

                      return (
                        <TableRow key={index}>
                          <TableCell>{item.node.createdAt && FormatedDate(item.node.createdAt)}</TableCell>
                          <TableCell>{modalHistoricoTitles[item.node.type]}</TableCell>
                          <TableCell>
                            {item.node.type === 'TRIAGEM' ? (
                              <StatusChip
                                label={reprovadoTriagem ? 'Reprovado' : 'Aprovado'}
                                status={reprovadoTriagem ? 'FALSE' : 'TRUE'}
                              />
                            ) : (
                              <StatusChip
                                label={modalHistoricoTitles[item.node.type]}
                                status={item.node.type}
                              />
                            )}
                          </TableCell>
                          {hasTriagemReprovada && (
                            <TableCell>
                              {reprovadoTriagem ? formatMotivoTriagem(false, item.node.tipoContratacao) : '-'}
                            </TableCell>
                          )}
                          {historicoCandidato.edges.some(item => item.node.tipoContratacao) && (
                            <TableCell>
                              {
                                !reprovadoTriagem ?
                                  formatMotivoTriagem(true, item.node.tipoContratacao)
                                  : '-'
                              }
                            </TableCell>
                          )}
                          <TableCell>{item.node.usuario ? item.node.usuario : '-'}</TableCell>
                          <TableCell>
                            {item.node.comentarioRH && item.node.comentarioRH.replace(/<[^>]+>/g, '')}
                          </TableCell>
                          <TableCell>
                            <ShowDetailsIcon
                              onClick={() => handleOpenDialog(
                                `Detalhes ${modalHistoricoTitles[item.node.type]}`,
                                item.node.type,
                                index
                              )}
                              disabled={loading}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              ) : (
                <NoResultsTable loading={loading} />
              )}
            </HistoricoTable>
          </Grid>
        </Grid>
      </Paper>

      <ComplexDialog
        open={!!confirmationDialog.open}
        handleClose={closeDialog}
        primaryAction={confirmationDialog.primaryAction ? confirmationDialog.primaryAction.action : () => { }}
        title={confirmationDialog.title}
        primaryActionButtonLabel={confirmationDialog.primaryAction ? confirmationDialog.primaryAction.buttonLabel : undefined}
        hidePrimaryActionButton={!!!confirmationDialog.primaryAction}
        closeActionButtonLabel="Voltar"
        disableBackdropClick={true}
        transitionDuration={TempoModal}
      >
        <div className={classes.modalContainer}>
          {confirmationDialog.itemIndex !== -1 && {
            TRIAGEM: (
              <TriagemModalDetails idTriagem={historicoCandidato.edges[confirmationDialog.itemIndex].node.id} />
            ),
            AGENDAMENTO: (
              <AgendamentoCandidatoModalDetails idAgendamento={historicoCandidato.edges[confirmationDialog.itemIndex].node.id} />
            ),
            SELECAO: (
              <SelecaoCandidatoModalDetails idSelecao={historicoCandidato.edges[confirmationDialog.itemIndex].node.id} />
            ),
            ADMISSAO: (
              <AdmissaoCandidatoModalDetails idAdmissao={historicoCandidato.edges[confirmationDialog.itemIndex].node.id} />
            ),
            NONE: (
              <Typography color="textSecondary">
                <strong>Não foi possível carregar o histórico selecionado ...</strong>
              </Typography>
            )
          }[confirmationDialog.tipo]}
        </div>
      </ComplexDialog>
    </div>
  )
})

export default HistoricoCandidato