import DatePicker from '@elentari/components-eve/final-form/DatePicker';
import { useSnackbar } from "@elentari/core";
import { Button, CircularProgress, Grid, makeStyles, styled, Tooltip } from "@material-ui/core";
import EventIcon from '@material-ui/icons/Event';
import { useState } from "react";
import { useHistory } from "react-router-dom";
import ComplexDialog from "src/components/ComplexDialog";
import { MessagesYup } from "src/modules/messages";
import yupValidation from "src/utils/yupValidation";
import * as yup from 'yup';
import { CreateAdmissaoButton } from "../../components/CreateAdmissaoButton";
import { CreateAgendamentoButton } from "../../components/CreateAgendamentoButton";
import { alterarStatusSelecaoCandidato } from "../../hooks/selecaoCandidatoRepository";

export type StatusActions = 'DESISTIU' | 'REPROVADO' | 'REAGENDADO' | 'APROVADO' | 'FINALIZAR' | 'NONE'

interface Callback {
  (data: ModalFormData): Promise<null | void> | void
}

type ModalFormData = {
  dataSelecao?: Date
  status?: StatusActions
}

const useStyles = makeStyles(theme => ({
  returnButton: {
    backgroundColor: theme.palette.white
  },
  desistenciaButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    }
  },
  reprovarButton: {
    backgroundColor: theme.palette.error.light,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    }
  },
  reagendarButton: {
    backgroundColor: theme.palette.warning.light,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
    }
  },
  aprovarButton: {
    backgroundColor: theme.palette.success.light,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    }
  },
  finalizarButton: {
    backgroundColor: theme.palette.success.dark,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    }
  }
}))

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '135px'
}));

interface SelecaoCandidatoButtonsProps {
  id: string | false
  candidatoId: string | false
  onSubmit: () => void
  disabled: boolean
  actionButtonsDisabled: boolean
  onStatusChange: () => void
  isSubmitting: boolean
}

export const SelecaoCandidatoButtons = ({
  id,
  candidatoId,
  onSubmit,
  disabled,
  actionButtonsDisabled,
  onStatusChange,
  isSubmitting
}: SelecaoCandidatoButtonsProps) => {

  const [loading, setLoading] = useState<boolean>(false)

  const [, snackbarActions] = useSnackbar()
  const history = useHistory()
  const classes = useStyles()

  const buttonsDisabled = isSubmitting || disabled || loading
  const disableActionButtons = buttonsDisabled || actionButtonsDisabled

  const [confirmationDialog, setConfirmationDialog] = useState<{
    title: string
    acceptLabel: string
    rejectLabel: string
    open: boolean
    action: StatusActions
    callback: Callback
  }>({
    title: '',
    acceptLabel: '',
    rejectLabel: '',
    action: 'NONE',
    open: false,
    callback: (data: ModalFormData) => new Promise(() => { })
  })
  const TempoModal = 100

  const handleOpenDialog = (
    title: string,
    acceptLabel: string,
    rejectLabel: string,
    action: StatusActions,
    callback: Callback
  ) => {
    setConfirmationDialog({
      title,
      acceptLabel,
      rejectLabel,
      action,
      open: true,
      callback
    })
  }

  const handleCloseDialog = () => {
    setConfirmationDialog({
      ...confirmationDialog,
      open: false
    })

    setTimeout(() => {
      setConfirmationDialog({
        title: '',
        acceptLabel: '',
        rejectLabel: '',
        action: 'NONE',
        open: false,
        callback: (data: ModalFormData) => new Promise(() => { })
      })
    }, TempoModal)
  }

  const handleConfirmDialog = async (data: ModalFormData) => {
    const { callback } = confirmationDialog
    if (callback) {
      setLoading(true)
      await callback(data)
      setLoading(false)
    }
  }

  const dialogSchema = yup.object().shape({
    dataSelecao: yup
      .date()
      .nullable()
      .when('action', {
        is: (action: StatusActions) => action === 'REAGENDADO',
        then: yup
          .date()
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
      }),
  })

  const handleChangeStatus = async (modalData: ModalFormData) => {
    try {
      if (id) {
        const response = await alterarStatusSelecaoCandidato(id, {
          ...modalData
        })

        if (response?.ok) {
          snackbarActions.setMessage('Status alterado com sucesso!')

          onStatusChange()
        } else {
          snackbarActions.setMessage('Ocorreu um erro ao alterar o status.')
        }
      } else {
        snackbarActions.setMessage('Não foi possível encontrar a Seleção Candidato que deseja alterar o status.')
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  const updateToAgendado = async (): Promise<void> => {
    return handleChangeStatus({
      status: 'REAGENDADO'
    })
  }

  const updateToAprovado = async (): Promise<void> => {
    return handleChangeStatus({
      status: 'APROVADO'
    })
  }

  return (
    <>
      <Grid container alignContent="center" justifyContent={id ? "center" : 'flex-end'} spacing={2}>
        <Grid item>
          <StyledButton
            fullWidth
            variant="contained"
            className={classes.returnButton}
            onClick={() => history.goBack()}
          >
            Voltar
          </StyledButton>
        </Grid>
        {id && (
          <>
            <Grid item>
              <StyledButton
                fullWidth
                variant="contained"
                className={classes.desistenciaButton}
                onClick={() => {
                  handleOpenDialog(
                    'Candidato(a) desistiu da Seleção',
                    'Alterar',
                    'Voltar',
                    'DESISTIU',
                    handleChangeStatus
                  )
                }}
                disabled={disableActionButtons}
              >
                Desistência
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                fullWidth
                variant="contained"
                className={classes.reprovarButton}
                onClick={() => {
                  handleOpenDialog(
                    'Reprovar Seleção',
                    'Reprovar',
                    'Voltar',
                    'REPROVADO',
                    handleChangeStatus
                  )
                }}
                disabled={disableActionButtons}
              >
                Reprovar Seleção
              </StyledButton>
            </Grid>
            <Grid item>
              <CreateAgendamentoButton
                buttonLabel="REAGENDAR"
                candidatoId={candidatoId}
                dialog={{
                  title: 'Deseja reagendar este Candidato(a)?',
                  acceptLabel: 'Reagendar'
                }}
                className={classes.reagendarButton}
                onSaveSuccessful={updateToAgendado}
                disabled={disableActionButtons}
              />
            </Grid>
            <Grid item>
              <CreateAdmissaoButton
                buttonLabel="Aprovar Seleção"
                candidatoId={candidatoId}
                dialog={{
                  title: 'Aprovar Seleção',
                  acceptLabel: 'Aprovar'
                }}
                className={classes.aprovarButton}
                onSaveSuccessful={updateToAprovado}
                disabled={disableActionButtons}
              />
            </Grid>
            <Grid item>
              <StyledButton
                fullWidth
                variant="contained"
                className={classes.finalizarButton}
                onClick={() => {
                  handleOpenDialog(
                    'Finalizar Seleção',
                    'Finalizar',
                    'Voltar',
                    'FINALIZAR',
                    handleChangeStatus
                  )
                }}
                disabled={disableActionButtons}
              >
                Finalizar
              </StyledButton>
            </Grid>
          </>
        )}
        <Grid item>
          <StyledButton
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => onSubmit()}
            disabled={buttonsDisabled}
          >
            {
              isSubmitting || loading ?
                <CircularProgress color="inherit" size={24} />
                :
                <>Salvar</>
            }
          </StyledButton>
        </Grid>
      </Grid>
      <ComplexDialog
        open={confirmationDialog.open}
        title={confirmationDialog.title}
        primaryActionButtonLabel={confirmationDialog.acceptLabel}
        closeActionButtonLabel={confirmationDialog.rejectLabel}
        message={"Desejar alterar o status da Seleção?"}
        handleClose={handleCloseDialog}
        primaryAction={handleConfirmDialog}
        validate={yupValidation(dialogSchema)}
        initialValues={{
          status: confirmationDialog.action
        }}
        disableBackdropClick={true}
        transitionDuration={TempoModal}
      >
        <Grid container spacing={2}>
          {confirmationDialog.action === 'REAGENDADO' && (
            <>
              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  name="dataSelecao"
                  label="Data Seleção"
                  keyboardIcon={
                    <Tooltip title="Selecionar data">
                      <EventIcon />
                    </Tooltip>
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      </ComplexDialog>
    </>
  )
}
