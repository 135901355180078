import { Fab, IconButton, makeStyles, Tooltip } from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add'

type Props = {
  onClick: () => void
}

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    right: theme.spacing(4),
    bottom: theme.spacing(4)
  }
}))

export const AddAnexo = ({onClick}: Props) => {

  const classes = useStyles()
  
  return (
    <Tooltip
      title={'Adicionar registro'}
      aria-label="Adicionar registro"
    >
      <Fab
        component={IconButton}
        color="primary"
        aria-label="Add"
        onClick={onClick}
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </Tooltip>
  )
}