import { useAppBar } from 'src/hooks'
import { handleStateErrorsToRender } from 'src/utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { TriagemAdministrativoForm } from './TriagemAdministrativoForm'
import { useAdministrativo } from 'src/modules/candidato/administrativo/hooks/useAdministrativo'

export function TriagemAdministrativoMasterDetail() {
  const [state, actions] = useAdministrativo()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      state,
      u => u.entity.id,
      u => u.entity.nomeCompleto
    )
  ])
  useFetchAndReset(actions.fetch, actions.reset)
  return handleStateErrorsToRender(state, <TriagemAdministrativoForm />)
}
