import { Box, Container, Divider, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import config from '../../config'
import { AuthContext } from './AuthProvider'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  divider: {
    height: 60,
    marginInline: 16,
    background: 'rgb(255 255 255 / 15%)'
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.getContrastText('#263238')
  },
  login: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },
  button: {
    width: 200
  },
  presentation: {
    marginLeft: theme.spacing(2),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      '& img': {
        width: '100%'
      }
    }
  },

  presentationImage: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto'
  }
}))

const debug = (...msgs: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...msgs)
  }
}

const Login = () => {
  const classes = useStyles()
  const context = useContext(AuthContext)

  const handleLogin = useCallback(async () => {
    debug('context initialized:', context.initialized)
    if (context.initialized && !context.state.loggedin) {
      const ssoUrlLogin = config.ssoUrlLogin
      const rhUrl = `${window.location.origin}/callback`
      const clientId = config.clientId

      window.location.assign(`${ssoUrlLogin}?url=${rhUrl}&clientId=${clientId}`)
    }
  }, [context.initialized]) // eslint-disable-line

  if (context.initialized && context.state.loggedin) {
    return <Redirect to="/" />
  }

  return (
    <Box bgcolor="#263238" minHeight="100vh" minWidth="100vw">
      <Container className={classes.container}>
        <Box className={classes.login}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src="/g10-logo-white.png" alt="G10 Logo" />
            <Divider orientation="vertical" className={classes.divider} />
            <img src="/tp-logo-white.png" alt="Transpanorama Logo" />
          </Box>

          <Typography variant="h4" className={classes.title}>
            Recursos Humanos
          </Typography>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleLogin}
          >
            Entrar
          </Button>
        </Box>

        <Box className={classes.presentation}>
          <img width={638} height={608} src="/blob-g10-rh.svg" alt="Blob RH" />
          <img
            className={classes.presentationImage}
            width={436}
            height={284}
            src="/presentation.svg"
            alt="Apresentação RH"
          />
        </Box>
      </Container>
    </Box>
  )
}

export default Login
