import { CargoRequisitoDetail } from '../types'
import { apiSauceInstance } from 'src/services/api'

export const createCargoRequisitos = async (
  data: CargoRequisitoDetail
) => {
  const response = await apiSauceInstance.post<any>(
    `/cargo-requisitos/create-many`,
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }

  return response.data
}


export const getAllCargoRequisitosByCargoId = async (
  CargoId: string
) => {
  const response = await apiSauceInstance.get<any>(
    `/cargo-requisitos/cargo/${CargoId}`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}