import { SubEmpresaDetail, SaveSubEmpresaInput } from './../types'

import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { ParsedQs } from 'qs'
import { apiSauceInstance } from 'src/services/api'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  return params
}

export const subEmpresaRepository = makeRepository<
  EdgesPage<SubEmpresaDetail>,
  SaveSubEmpresaInput,
  SubEmpresaDetail,
  string
>(
  {
    resource: 'sub-empresas',
    mapQueryParams
  },
  apiSauceInstance
)