import LinkMD from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Link, useLocation } from 'react-router-dom'
import { CpfBloqueadoIcon } from 'src/components/Icons/CpfBloqueadoIcon'
import {
  AddRecord,
  NoResultsTable,
  Paper,
  TableContainer,
  When
} from '../../../components'
import Spacer from '../../../components/Spacer'
import { TableCellHead } from '../../../components/TableCellHead'
import { FormatedDate } from '../../../utils'
import { AdministrativoDetail } from './types'

interface Props {
  list: AdministrativoDetail[]
  loading: boolean
  totalCount: number
  onDelete: (id: string) => void
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const AdministrativoTable = ({
  list,
  loading,
  totalCount,
  authorization
}: Props) => {
  const { search } = useLocation()

  return (
    <>
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Nome Completo" sort="nomeCompleto" />
                  <TableCellHead label="CPF" sort="cpf" />
                  <TableCellHead label="E-mail" sort="email" />
                  <TableCellHead
                    label="Data Nascimento"
                    sort="dataNascimento"
                  />
                  <TableCellHead label="Celular" sort="celular" />
                  <TableCellHead label="Cidade" sort="cidade" />
                  <TableCellHead label="UF" sort="uf" />
                  <When
                    value={!authorization || authorization.canCreate}
                    equals
                  >
                    <TableCell></TableCell>
                  </When>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(administrativo => (
                  <TableRow hover key={administrativo.id}>
                    <TableCell>
                      {!authorization || authorization.canUpdate ? (
                        <When
                          value={!authorization || authorization.canUpdate}
                          equals
                        >
                          <LinkMD
                            component={Link}
                            to={`/candidato/${administrativo.id}`}
                          >
                            {administrativo.nomeCompleto}
                          </LinkMD>
                        </When>
                      ) : (
                        administrativo.id
                      )}
                    </TableCell>
                    <TableCell>{administrativo.cpf}</TableCell>
                    <TableCell>{administrativo.email}</TableCell>
                    <TableCell>
                      {administrativo.dataNascimento && FormatedDate(administrativo.dataNascimento)}
                    </TableCell>
                    <TableCell>{administrativo.celular}</TableCell>
                    <TableCell>{administrativo.cidade}</TableCell>
                    <TableCell>{administrativo.uf}</TableCell>
                    <When value={authorization?.canCreate} equals>
                      <TableCell>
                        {administrativo.idListaBloqueio && (
                          <CpfBloqueadoIcon idListaBloqueio={administrativo.idListaBloqueio} />
                        )}
                      </TableCell>
                    </When>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="administrativo" authorization={authorization} />
    </>
  )
}