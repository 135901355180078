import { FormControl, FormHelperText, InputLabel, MenuItem } from '@material-ui/core';
import MuiSelect from '@material-ui/core/Select';
import { useEffect } from 'react';
import { useField } from "react-final-form";

export interface SelectOption {
  value: string
  label: string
}

export default function SelectField({
  name,
  label,
  defaultValue,
  items
} : {
  name: string,
  label: string,
  defaultValue?: string,
  items: SelectOption[]
}) {
  const { input, meta } = useField(name, { defaultValue })

  useEffect(() => {
    if ((input.value === null || input.value === '') && defaultValue) {
      input.onChange(defaultValue)
    }
  }, [input.value, defaultValue])

  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={meta.touched && Boolean(meta.error)}
    >
      <InputLabel id={name}>{label}</InputLabel>
      <MuiSelect
        fullWidth
        variant="outlined"
        label={label}
        {...input}
      >
        {items.map((item, index) => {
          return (
            <MenuItem
              key={index}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </MuiSelect>
      <FormHelperText>{meta.touched && meta.error}</FormHelperText>
    </FormControl>
  )
}