import { Link } from 'react-router-dom'
import * as dateFns from 'date-fns'

import * as yup from 'yup'
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Grid,
  Tooltip
} from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import LinkMD from '@material-ui/core/Link'
import { useQueryParams, useSnackbar } from '@elentari/core'
import { useListaBloqueiosList } from './hooks/useListaBloqueios'
import { ListaBloqueioFormData, SubmitValues } from './types'
import { listaBloqueioRepository } from './hooks/listaBloqueiosRepository'

import { TableCellHead } from '../../components/TableCellHead'
import Spacer from '../../components/Spacer'

import Filter from '@elentari/components-eve/components/Filter'
import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import {
  AddRecord,
  DeleteRecord,
  Paper,
  TableContainer,
  NoResultsTable,
  When
} from '../../components'

import { useAbility } from '../login'
import { getAuthorizations } from '../../utils/crudAuthorization'
import { HandleErrorPage } from '../../utils/handleErrorPage'
import yupValidation from '../../utils/yupValidation'
import { MessagesYup } from '../messages'
import { formatCpfCnpj, formatDate } from '../../utils/formatters'
import TextField from '@elentari/components-eve/final-form/TextField'
import { getAlphanumericDigits } from '../../utils/utils'
import { useLoggedUser } from '../login/AuthProvider'

interface Props {
  list: ListaBloqueioFormData[]
  loading: boolean
  totalCount: number
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

const schema = yup.object().shape({
  datadesbloqueio: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
})

export const ListaBloqueiosTable = ({
  list,
  loading,
  totalCount,
  onDelete,
  authorization
}: Props) => {
  const userLogged = useLoggedUser()

  return (
    <>
      <ListaBLoqueioFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="CPF" sort="cpf" />
                  <TableCellHead
                    label="Data de Desbloqueio"
                    sort="datadesbloqueio"
                  />
                  <TableCellHead label="Bloqueado por" sort="bloqueadoPor" />
                  <TableCellHead label="Empresa" sort="empresaId" />
                  <When
                    value={!authorization || authorization.canDelete}
                    equals
                  >
                    <TableCell></TableCell>
                  </When>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(listaBloqueio => (
                  <TableRow hover key={listaBloqueio.id}>
                    <TableCell>
                      {!authorization || authorization.canUpdate ? (
                        <When
                          value={!authorization || authorization.canUpdate}
                          equals
                        >
                          {listaBloqueio.empresaFK.sigla ===
                          userLogged?.empresa ? (
                            <LinkMD
                              component={Link}
                              to={`/lista-bloqueios/${listaBloqueio.id}`}
                            >
                              {formatCpfCnpj(listaBloqueio.cpf)}
                            </LinkMD>
                          ) : (
                            formatCpfCnpj(listaBloqueio.cpf)
                          )}
                        </When>
                      ) : (
                        formatCpfCnpj(listaBloqueio.cpf)
                      )}
                    </TableCell>
                    <TableCell>
                      {listaBloqueio.datadesbloqueio
                        ? formatDate(listaBloqueio.datadesbloqueio)
                        : '-'}
                    </TableCell>
                    <TableCell>{listaBloqueio.bloqueadoPor}</TableCell>

                    <TableCell>{listaBloqueio.empresaFK.sigla}</TableCell>

                    <When value={authorization?.canDelete} equals>
                      <DeleteRecord
                        onDelete={() => onDelete(listaBloqueio.id)}
                      />
                    </When>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="lista-bloqueios" authorization={authorization} />
    </>
  )
}

type ListaBloqueioQueryFilter = {
  createdAt?: { gt: string }
  [key: string]: any
}

const ListaBLoqueioFilter = () => {
  const { clear, push, initialValues } =
    useQueryParams<ListaBloqueioQueryFilter>()

  const handleSubmitFilter = (values: SubmitValues) => {
    if (values.cpf) {
      values.cpf = getAlphanumericDigits(values.cpf)
    }
    if (values.createdAt) {
      const dateFormated = dateFns.parse(
        String(values.createdAt),
        'dd/MM/yyyy',
        new Date()
      )

      return push({
        ...values,
        createdAt: {
          gt: dateFormated.toISOString()
        }
      })
    }
    if (values.datadesbloqueio) {
      values.datadesbloqueio = new Date(values.datadesbloqueio).toISOString()
    }
    push({
      ...values,
      createdAt: undefined
    })
  }

  const format = (initialValues: ListaBloqueioQueryFilter) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues,
        createdAt: dateFns.format(
          dateFns.parseISO(initialValues.createdAt.gt),
          'dd/MM/yyyy'
        )
      }
    }
    return initialValues
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter(values)}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth name="cpf" label="CPF" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            fullWidth
            name="datadesbloqueio"
            label="Data de Desbloqueio"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

const ListaBloqueios = () => {
  const [state, actions] = useListaBloqueiosList()
  const [, snackbarActions] = useSnackbar()

  const ability = useAbility()
  async function handleDelete(id: string) {
    const response = await listaBloqueioRepository.delete(id)
    actions.fetchPage()

    if (response.ok) {
      snackbarActions.setSnackBar({
        message: 'Deletado com sucesso',
        severity: 'success'
      })
      actions.fetchPage()
      return
    }
    const message = (response.data as unknown as any).message
    snackbarActions.setSnackBar({
      message: message as string,
      severity: 'warning'
    })
  }

  const authorization = getAuthorizations(ability, 'listaBloqueios')

  switch (state.tag) {
    case 'empty':
      return (
        <ListaBloqueiosTable
          onDelete={() => {}}
          totalCount={0}
          list={[]}
          loading={true}
        />
      )
    case 'with-data':
      return (
        <ListaBloqueiosTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onDelete={handleDelete}
          authorization={authorization}
        />
      )
    case 'error':
      return <HandleErrorPage state={state.data} />
  }
}

export default ListaBloqueios
