import { EdgesPage } from '@elentari/core/types'
import QueryString from 'qs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAbility } from 'src/modules/login'
import { apiSauceInstance } from 'src/services/api'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import { MotoristaDetail } from './types'
import triagemStore from '../store/store'
import { observer } from 'mobx-react-lite'
import { TriadoMotoristaFilter } from './TriadoMotoristaFilter'
import { ReprovadosTriagemMotoristaTable } from './ReprovadosTriagemMotoristaTable'

const ReprovadosTriagemMotoristaList = (props: {
  list: { node: MotoristaDetail }[]
  loading: boolean
  totalCount: number
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
  resetList: () => void
}) => {
  return (
    <>
      <TriadoMotoristaFilter statusTriagem="REPROVADO" />
      <ReprovadosTriagemMotoristaTable {...props} />
    </>
  )
}

type APIResponseCandidatos = {
  edges: { node: MotoristaDetail }[]
  totalCount: number
  loading: boolean
}

const ReprovadosTriagemMotorista = observer(() => {
  const [candidatosState, setCandidatosState] = useState<APIResponseCandidatos>(
    {} as APIResponseCandidatos
  )
  const { search, pathname } = useLocation()
  const ability = useAbility()

  const authorization = getAuthorizations(ability, 'reprovadosTriagemMotoristas')

  useEffect(() => {
    triagemStore.resetCandidatosId()
  }, [])


  async function loadCandidatosMotoristas() {
    try {
      setCandidatosState({
        ...candidatosState,
        loading: true
      })

      const params: { [key: string]: any } = {
        sort: { createdAt: 'desc' },
        incluirBloqueados: 'false',
        ...QueryString.parse(search.substring(1))
      }

      const { data } = await apiSauceInstance.get<
        EdgesPage<MotoristaDetail>
      >(`/candidato?${QueryString.stringify(params)}`, {
        tipo: 'MOTORISTA',
        statusCandidato: 'TRIADO',
        statusTriagem: 'REPROVADO',
        skip: params.page ? (+params.page - 1) * 10 : 0,
        limit: 10
      })
      if (data) {
        setCandidatosState({
          totalCount: data.totalCount,
          edges: data.edges,
          loading: false
        })
      }
    } catch (error) { }
  }

  useEffect(() => {
    loadCandidatosMotoristas()
  }, [pathname, search])

  return (
    <>
      {candidatosState.loading === false ? (
        <ReprovadosTriagemMotoristaList
          totalCount={candidatosState.totalCount}
          list={candidatosState.edges}
          loading={candidatosState.loading}
          authorization={authorization}
          resetList={loadCandidatosMotoristas}
        />
      ) : (
        <ReprovadosTriagemMotoristaList
          totalCount={0}
          list={[]}
          loading={candidatosState.loading}
          authorization={authorization}
          resetList={loadCandidatosMotoristas}
        />
      )}
    </>
  )
})

export default ReprovadosTriagemMotorista