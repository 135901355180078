import { ToolbarConfig, StyleConfigList } from "react-rte"

export const INLINE_STYLE_BUTTONS: StyleConfigList = [
  { label: 'Negrito', style: 'BOLD' },
  { label: 'Itálico', style: 'ITALIC' },
  { label: 'Tachado', style: 'STRIKETHROUGH' },
  { label: 'Espaçado', style: 'CODE' },
  { label: 'Sublinhado', style: 'UNDERLINE' }
]

export const BLOCK_TYPE_DROPDOWN: StyleConfigList = [
  { label: 'Normal', style: 'unstyled' },
  { label: 'Título 1', style: 'header-one' },
  { label: 'Título 2', style: 'header-two' },
  { label: 'Título 3', style: 'header-three' },
  { label: 'Bloco', style: 'code-block' }
]
export const BLOCK_TYPE_BUTTONS: StyleConfigList = [
  { label: 'Lista com marcadores', style: 'unordered-list-item' },
  { label: 'Lista numerada', style: 'ordered-list-item' },
  { label: 'Citar', style: 'blockquote' }
]

const EditorToolbarConfig: ToolbarConfig = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
    'HISTORY_BUTTONS'
  ],
  INLINE_STYLE_BUTTONS,
  BLOCK_TYPE_DROPDOWN,
  BLOCK_TYPE_BUTTONS
}

export const EmptyEditorToolbarConfig: ToolbarConfig = {
  display: [],
  BLOCK_TYPE_BUTTONS: [],
  BLOCK_TYPE_DROPDOWN: [],
  INLINE_STYLE_BUTTONS: [],
}

export default EditorToolbarConfig
