import { useGrid } from '../hooks/useGrid'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { GridForm } from './GridForm'

export function GridsMasterDetail() {
  const [gridState, gridActions] = useGrid()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      gridState,
      u => u.entity.id,
      u => u.entity.nome
    )
  ])
  useFetchAndReset(gridActions.fetch, gridActions.reset)
  return handleStateErrorsToRender(gridState, <GridForm />)
}
