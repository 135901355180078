import { useSnackbar } from '@elentari/core'
import { Grid, Typography } from '@material-ui/core'
import { Formik, FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Paper } from 'src/components'
import RichTextInput from 'src/components/Formik/Forms/RichTextInput'
import Spacer from 'src/components/Spacer'
import { useAppBar } from 'src/hooks'
import { MessagesYup } from 'src/modules/messages'
import * as yup from 'yup'
import { CandidatoCardContent } from '../../components/CandidatoCardContent'
import { repository } from '../../hooks/candidatoRepository'
import { CandidatoDetail } from '../../types'
import { getNewestAdmissao } from '../../utils'
import AgendamentosAdmissaoInput from '../components/AgendamentosAdmissaoInput'
import { InstrucaoAdmissaoButtons } from '../components/InstrucaoAdmissaoButtons'
import PassosAdmissaoInput from '../components/PassosAdmissaoInput'
import { fetchInstrucaoAdmissao, saveInstrucaoAdmissao } from '../hooks/instrucaoAdmissaoRepository'
import { InstrucaoAdmissao, InstrucaoAdmissaoFormData } from '../types'

export const InstrucaoAdmissaoForm = () => {
  const location = useLocation()
  const [, snackbarActions] = useSnackbar()
  const [appBarState, appBarActions] = useAppBar()

  const [candidato, setCandidato] = useState<CandidatoDetail>()
  const [admissaoCandidatoId, setAdmissaoCandidatoId] = useState<string>()
  const [instrucaoAdmissao, setInstrucaoAdmissao] = useState<InstrucaoAdmissao>()
  const [loading, setLoading] = useState<boolean>(false)

  const scrollTop = () => {
    const mainContainer = document.querySelector('main')
    if (mainContainer) {
      mainContainer.scrollTo({
        top: 0
      })
    }
  }

  useEffect(() => {
    scrollTop()

    appBarActions.replacePaths([
      appBarState.linksPaths[0],
      { label: 'Candidato', path: `${appBarState.linksPaths[0].path}/${location.pathname.split('/')[2]}` },
    ], 'Gerar Instrução')
  }, [])

  useEffect(() => {
    if (candidato) {
      appBarActions.replacePaths([
        appBarState.linksPaths[0],
        { label: candidato.nomeCompleto, path: `${appBarState.linksPaths[0].path}/${candidato.id}` },
      ], 'Gerar Instrução')
    }
  }, [candidato])

  useEffect(() => {
    async function getCandidato() {
      setLoading(true)
      const candidatoId = location.pathname.split('/')[2]
      if (candidatoId) {
        const responseCandidato = await repository.fetchOne(candidatoId);

        if (responseCandidato.ok) {
          const candidatoData = responseCandidato.data
          setCandidato(candidatoData)

          if (candidatoData?.AdmissaoCandidato) {
            const admissaoCandidato = getNewestAdmissao(candidatoData.AdmissaoCandidato)

            setAdmissaoCandidatoId(admissaoCandidato.id)
          }
        }
      }
      setLoading(false)
    }

    getCandidato()
  }, [location])

  useEffect(() => {
    async function getInstrucaoAdmissao() {
      setLoading(true)
      if (admissaoCandidatoId) {
        const response = await fetchInstrucaoAdmissao(
          admissaoCandidatoId
        )
        setInstrucaoAdmissao(response?.info)
      }
      setLoading(false)
    }

    getInstrucaoAdmissao()
  }, [admissaoCandidatoId])

  const schemaObject = {
    passos: yup
      .array()
      .of(yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO)),
    AgendaInstrucao: yup.array().of(
      yup.object().shape({
        treinamentoId: yup
          .string()
          .trim()
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
        data: yup
          .date()
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
        turno: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
    )
  }
  const schema = yup.object().shape(schemaObject)

  const formikState = {
    initialValues: {
      admissaoCandidatoId: '',
      observacao: '',
      passos: [
        'Pegar uniforme no RH.',
        'Falar com seu Coordenador para pegar a placa do seu caminhão.',
        "Pegar os EPI's no SESMT (com a placa do caminhão em mãos). Para transporte de cargas perigosas (verifique com coordenador), conferir kit de emergência do veículo, no recebimento.",
        'Fazer o Treinamento de MACRO no CCO (com a placa do caminhão em mãos).',
        'Entregar no RH o Formulário de Treinamento do Motorista.',
        'Fazer o Check List do caminhão.',
        'Falar com seu coordenador para sair de viagem.'
      ],
      AgendaInstrucao: [
        {
          treinamentoId: '',
          data: null,
          turno: ''
        }
      ]
    },
    onSubmit: async (data: FormikValues) => {
      setLoading(true)
      try {
        if (admissaoCandidatoId) {
          data.admissaoCandidatoId = admissaoCandidatoId
          const response = await saveInstrucaoAdmissao(data as InstrucaoAdmissaoFormData)
          if (response?.ok) {
            if (response.info) setInstrucaoAdmissao(response.info)
            snackbarActions.setMessage('Salvo com sucesso!')
          } else {
            snackbarActions.setMessage('Ocorreu um erro ao salvar este recurso.')
          }
        } else {
          snackbarActions.setMessage('Não foi possível encontrar a Admissão do(a) Candidato(a)')
        }
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
      setLoading(false)
    }
  }

  return (
    <>
      {candidato && (
        <Paper>
          <CandidatoCardContent candidato={candidato} showIcons={true} />
        </Paper>
      )}

      <Spacer y={2} />

      <Formik
        validationSchema={schema}
        onSubmit={formikState.onSubmit}
        enableReinitialize
        initialValues={instrucaoAdmissao ? instrucaoAdmissao : formikState.initialValues}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <Paper>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    style={{ fontSize: 18, marginBottom: 20 }}
                    component="h6"
                  >
                    Agenda dos Treinamentos:
                  </Typography>
                  <AgendamentosAdmissaoInput />
                </Grid>
                <Grid item xs>
                  <Typography
                    style={{ fontSize: 18, marginBottom: 20 }}
                    component="h6"
                  >
                    Passos a serem seguidos:
                  </Typography>
                  <PassosAdmissaoInput />
                </Grid>
                <Grid item xs={12}>
                  <RichTextInput
                    name="observacao"
                    label="Observação"
                  />
                </Grid>
              </Grid>
            </Paper>

            <Spacer y={2} />

            <InstrucaoAdmissaoButtons
              instrucaoAdmissao={instrucaoAdmissao}
              onSubmit={handleSubmit}
              disabled={loading}
              isSubmitting={isSubmitting}
            />
          </>
        )}
      </Formik>
    </>
  )
}
