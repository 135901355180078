import { useSnackbar } from '@elentari/core'
import {
  Button,
  Grid,
  Tooltip,
  makeStyles,
  createStyles,
  Theme
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { FieldArray, FieldArrayRenderProps, Formik, FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import TextInput from 'src/components/Formik/Forms/TextInput'
import { useAppBar } from 'src/hooks'
import * as yup from 'yup'
import { FormWrap, Paper } from '../../../components'
import { MessagesYup } from '../../messages'
import {
  createTestes,
  getAllTestesByProcessoId
} from '../hooks/testeProcessoAdministrativoRepository'
import {
  TesteAdministrativoDetail,
  TesteProcessoAdministrativoDetail,
  ProcessoSeletivoAdministrativoDetail
} from '../types'
import {
  updateStatusProcesso,
  getProcessoById
} from '../hooks/processoSeletivoAdministrativoRepository'
import { ResultadoColors } from 'src/utils'
import RichTextInput from 'src/components/Formik/Forms/RichTextInput'
import ComplexDialog from 'src/components/ComplexDialog'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import { tipoReprovacao } from 'src/modules/candidato/types'
import yupValidation from '../../../utils/yupValidation'

interface Params {
  processoId: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2)
      }
    },
    reprovado: {
      backgroundColor: ResultadoColors.REPROVADO,
      '&:hover': {
        color: 'white',
        backgroundColor: ResultadoColors.REPROVADO_DARK
      }
    },
    aprovado: {
      backgroundColor: ResultadoColors.APROVADO,
      '&:hover': {
        color: 'white',
        backgroundColor: ResultadoColors.APROVADO_DARK
      }
    }
  })
)

export const TestesProcessoAdministrativoForm = () => {
  const [testeAdministrativo, setTesteAdministrativo] =
    useState<TesteProcessoAdministrativoDetail>()
  const [processoSeletivoAdministrativo, setProcessoSeletivoAdministrativo] =
    useState<ProcessoSeletivoAdministrativoDetail>()

  const params = useParams<Params>()

  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()
  const canEdit = testeAdministrativo === undefined ? true : false
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    loadTestesAdministrativos()
  }, [])

  const loadTestesAdministrativos = async () => {
    setLoading(true)

    const responseProcesso = await getProcessoById(params.processoId)
    if (responseProcesso?.ok) {
      setProcessoSeletivoAdministrativo(responseProcesso.info)
    }

    const response = await getAllTestesByProcessoId(params.processoId)
    if (response?.ok) {
      if (response?.info.length !== 0) {
        setTesteAdministrativo({
          processoSeletivoAdministrativoId: params.processoId,
          TestesAdministrativo: response.info,
          comentarioEntrevistaRH: responseProcesso.info.comentarioEntrevistaRH,
          comentarioEntrevistaGestor:
            responseProcesso.info.comentarioEntrevistaGestor
        } as TesteProcessoAdministrativoDetail)
      }
    }

    setLoading(false)
  }

  const schemaObject = {
    TestesAdministrativo: yup
      .array()
      .of(
        yup.object().shape({
          pergunta: yup
            .string()
            .trim()
            .required(MessagesYup.MENSAGEM_OBRIGATORIO)
            .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
          resposta: yup
            .string()
            .trim()
            .required(MessagesYup.MENSAGEM_OBRIGATORIO)
            .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
        })
      )
      .test('unique-pergunta', function (TestesAdministrativo: any) {
        const arrDescricoesPergunta = TestesAdministrativo?.map(
          (Teste: TesteAdministrativoDetail) => Teste?.pergunta?.toUpperCase()
        )

        const numberDiffPergunta = new Set(arrDescricoesPergunta).size

        if (TestesAdministrativo.length !== numberDiffPergunta) {
          const arrRepetidos: number[] = []
          arrDescricoesPergunta.filter((descricao: string, index: number) => {
            if (arrDescricoesPergunta.indexOf(descricao) !== index) {
              arrRepetidos.push(index)
            }

            return arrDescricoesPergunta.indexOf(descricao) == index
          })

          return this.createError({
            path: `TestesAdministrativo[${arrRepetidos[0]}].pergunta`,
            message: 'Não pode haver Perguntas repetidas'
          })
        } else {
          return true
        }
      })
  }
  const schema = yup.object().shape(schemaObject)

  const handleAddPergunta = (arrayHelpers: FieldArrayRenderProps) => {
    return () => {
      arrayHelpers.push({ 
        pergunta: '',
        resposta: '' 
      })
    }
  }

  const handleDeletePergunta = (
    index: number,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    return () => {
      arrayHelpers.remove(index)
    }
  }

  const formikState = {
    initialValues: {
      processoSeletivoAdministrativoId: '',
      TestesAdministrativo: [
        {
          pergunta: '',
          resposta: ''
        }
      ],
      comentarioEntrevistaRH: '',
      comentarioEntrevistaGestor: ''
    },
    onSubmit: async (data: FormikValues) => {
      try {
        setLoading(true)
        const response = await createTestes({
          ...data,
          processoSeletivoAdministrativoId: params.processoId
        } as TesteAdministrativoDetail)

        if (!response.ok) {
          snackbarActions.setMessage(response.message)
        } else {
          snackbarActions.setMessage('Salvo com sucesso!')
        }
        await loadTestesAdministrativos()
        setLoading(false)
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  const updateProcessoStatus = async (status: string, reprovacao?: {tipoReprovacao: string, motivoReprovacao: string}) => {
    try {
      setLoading(true)
      const response = await updateStatusProcesso(params.processoId, status, reprovacao)
      setProcessoSeletivoAdministrativo(response.info)
      setLoading(false)
      snackbarActions.setMessage('Salvo com sucesso!')
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  const customActionsFactory = () => {
    const actions = []
    const reprovarButton = {
      label: 'Reprovar',
      disabled: loading,
      classes: classes.reprovado,
      onClick: () => {
        setConfirmationDialog({
          open: true
        })
      }
    }

    const aprovarButton = {
      label: 'Aprovar',
      disabled: loading,
      classes: classes.aprovado,
      onClick: () => {
        updateProcessoStatus('CONCLUSAO')
        handleGoBack()
      }
    }
    if (!canEdit && processoSeletivoAdministrativo?.status === 'ENTREVISTA') {
      actions.push(reprovarButton)
      actions.push(aprovarButton)
    }

    return actions
  }

  const customActions = customActionsFactory()

  
  const [confirmationDialog, setConfirmationDialog] = useState<{
    open: boolean
  }>({
    open: false
  })

  const handleCloseDialog = () => {
    setConfirmationDialog({
      open: false
    })
  }

  function handleGoBack() {
    const newLocation = location.pathname
      .split('/')
      .filter(Boolean)
      .slice(0, -1)
      .join('/')

    history.push(`/${newLocation}`)
  }
  
  const handleConfirmDialog = async (data: any) => {
    await updateProcessoStatus(
      'REPROVADO',
      {
        ...data
      }
    )
    handleGoBack()
  }

  const dialogSchema = yup.object().shape({
    tipoReprovacao: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    motivoReprovacao: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  })

  return (
    <Formik
      validationSchema={schema}
      onSubmit={formikState.onSubmit}
      initialValues={testeAdministrativo ?? formikState.initialValues}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <Paper noTopBorderRadius>
          <FormWrap
            handleSubmit={handleSubmit}
            disableSubmit={isSubmitting}
            disableBack={isSubmitting}
            loading={loading}
            customActions={customActions}
          >
            <Grid container spacing={2}>
              <FieldArray name={'TestesAdministrativo'} validateOnChange={true}>
                {arrayHelpers => (
                  <Grid item xs={12}>
                    {values.TestesAdministrativo?.map(
                      (testeAdministrativo, index) => (
                        <Grid key={index} container>
                          <Grid
                            container
                            key={index}
                            spacing={2}
                            style={{
                              paddingBottom: 20,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'start'
                            }}
                            xs={
                              values.TestesAdministrativo.length > 1 ? 11 : 12
                            }
                          >
                            <Grid item xs={12} sm={12}>
                              <TextInput
                                name={`TestesAdministrativo[${index}].pergunta`}
                                label={`${index + 1}° Pergunta`}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextInput
                                name={`TestesAdministrativo[${index}].resposta`}
                                label={'Resposta'}
                              />
                            </Grid>
                          </Grid>

                          {values.TestesAdministrativo.length > 1 && (
                            <Grid
                              item
                              xs={1}
                              sm={1}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <Tooltip
                                placement="top"
                                title={`Deletar ${index + 1}° Pergunta`}
                              >
                                <Button
                                  style={{
                                    flex: 1,
                                    height: 56,
                                    border: 'none'
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  onClick={handleDeletePergunta(
                                    index,
                                    arrayHelpers
                                  )}
                                >
                                  <DeleteOutlineIcon />
                                </Button>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      )
                    )}
                    <Tooltip
                      placement="right-start"
                      title="Adicionar Perguntas"
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        type="button"
                        onClick={handleAddPergunta(arrayHelpers)}
                      >
                        <AddIcon />
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
              </FieldArray>
              <Grid item spacing={2} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <RichTextInput
                      name="comentarioEntrevistaRH"
                      label="Comentário do RH"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RichTextInput
                      name="comentarioEntrevistaGestor"
                      label="Comentário do Gestor"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <ComplexDialog
              open={confirmationDialog.open}
              title="Descreva o Motivo da Reprovação do Candidato"
              handleClose={handleCloseDialog}
              primaryAction={handleConfirmDialog}
              validate={yupValidation(dialogSchema)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name="tipoReprovacao"
                    label="Tipo de Reprovação"
                    items={tipoReprovacao}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="motivoReprovacao"
                    label="Motivo Reprovação"
                  />
                </Grid>
              </Grid>
            </ComplexDialog>
          </FormWrap>
        </Paper>
      )}
    </Formik>
  )
}
