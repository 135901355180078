import { Content, TableCell } from "pdfmake/interfaces"
import { categoriaCNH, estadoCivil, FormatedDate, getValueFormatted, OptionCaminhao, optionsOrigemCurriculo } from "src/utils"
import { AdministrativoDetail, AreaInteresse } from "../administrativo/types"
import { Motorista, MotoristaDetail } from "../motorista/types"
import { Curso, ExperienciaProfissional } from "../types"

export const getInformacoesAdicionaisData = (candidato: AdministrativoDetail | MotoristaDetail): Content => {
  return candidato.origemCurriculo ? [
    {
      text: 'Informações adicionais',
      margin: [10, 10],
      alignment: 'center',
      style: 'dataTitle'
    },
    {
      text: [
        {
          text: 'Origem do Candidato(a): ',
          style: 'dataLabel'
        },
        `${optionsOrigemCurriculo.find(origem => origem.value === candidato.origemCurriculo)?.label || candidato.origemCurriculo}`
      ],
      margin: [30, 3]
    },
  ] : []
}

export const getInteressesData = (interesses: AreaInteresse[]): Content => {
  return [
    {
      text: 'Interesses',
      margin: [10, 10],
      alignment: 'center',
      style: 'dataTitle'
    },
    interesses.map((interesse, index) => {
      return {
        text: [
          {
            text: `${index + 1}° Interesse: `,
            style: 'dataLabel'
          },
          `${interesse.descricao}`
        ],
        margin: [30, 3]
      }
    }),
  ]
}

export const getCursosData = (cursos: Curso[]): Content => {
  if (cursos && cursos.length > 0) {
    return [
      {
        text: 'Cursos',
        margin: [10, 10],
        alignment: 'center',
        style: 'dataTitle'
      },
      cursos.map((curso, index) => {
        return [
          {
            text: `${index + 1}° Curso`,
            margin: [30, 10],
            style: 'dataLabel'
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Descrição: ',
                    style: 'dataLabel',
                    width: 85
                  },
                  `${curso.descricao}`
                ],
              },
              {
                text: [
                  {
                    text: 'Carga Horária: ',
                    style: 'dataLabel',
                    width: 85
                  },
                  `${curso.cargaHoraria}`
                ],
              }
            ],
            margin: [40, 3]
          },
          {
            columns: [
              curso.dataInicio ? {
                text: [
                  {
                    text: 'Data de Início: ',
                    style: 'dataLabel',
                    width: 85
                  },
                  `${FormatedDate(curso.dataInicio)}`
                ],
              } : '',
              curso.dataConclusao ? {
                text: [
                  {
                    text: 'Data de Conclusão: ',
                    style: 'dataLabel',
                    width: 85
                  },
                  `${FormatedDate(curso.dataConclusao)}`
                ],
              } : ''
            ],
            margin: [40, 3]
          },
        ]
      })
    ]
  } else {
    return [
      {
        text: 'Não foi cadastrado nenhum Curso',
        margin: [0, 10],
        alignment: 'center',
        style: 'dataLabel'
      },
    ]
  }
}

export const getMotoristaData = (motorista: Motorista): Content => {
  return [
    {
      text: 'Dados do Motorista',
      margin: [10, 10],
      alignment: 'center',
      style: 'dataTitle'
    },
    {
      columns: [
        {
          text: [
            {
              text: 'Estado Civil: ',
              style: 'dataLabel'
            },
            motorista.estadoCivil ? estadoCivil.find(estadoCivil => estadoCivil.id === motorista.estadoCivil)?.name || motorista.estadoCivil : ''
          ]
        },
        {
          text: [
            {
              text: 'RG: ',
              style: 'dataLabel'
            },
            motorista.rg ?? ''
          ],
        }
      ],
      margin: [30, 1]
    },
    {
      columns: [
        {
          text: [
            {
              text: 'Categoria da CNH: ',
              style: 'dataLabel'
            },
            motorista.categoriaCNH ? categoriaCNH.find(categoria => categoria.value === motorista.categoriaCNH)?.label || motorista.categoriaCNH : ''
          ]
        },
        {
          text: [
            {
              text: 'Possui Pamcary: ',
              style: 'dataLabel'
            },
            motorista.pamcary ? 'Sim' : 'Não'
          ],
        }
      ],
      margin: [30, 1]
    },
    motorista.mopp && motorista.dataValidadeMopp ? [
      {
        columns: [
          {
            text: [
              {
                text: 'Mopp: ',
                style: 'dataLabel'
              },
              motorista.mopp ? 'Sim' : 'Não'
            ],
          },
          {
            text: [
              {
                text: 'Data de validade da Mopp: ',
                style: 'dataLabel'
              },
              motorista.dataValidadeMopp && FormatedDate(motorista.dataValidadeMopp)
            ],
          }
        ],
        margin: [30, 1]
      },
      {
        text: [
          {
            text: 'Possui EAR no CNH: ',
            style: 'dataLabel'
          },
          motorista.earCNH ? 'Sim' : 'Não'
        ],
        margin: [30, 3]
      },
    ] : {
      columns: [
        {
          text: [
            {
              text: 'Mopp: ',
              style: 'dataLabel'
            },
            motorista.mopp ? 'Sim' : 'Não'
          ],
        },
        {
          text: [
            {
              text: 'Possui EAR no CNH: ',
              style: 'dataLabel'
            },
            motorista.earCNH ? 'Sim' : 'Não'
          ]
        },
      ],
      margin: [30, 1]
    },
  ]
}

export const getMotoristaIndicacaoData = (motorista: Motorista): Content => {
  if (motorista.indicacao) {
    return [
      {
        text: 'Indicação',
        margin: [10, 10],
        alignment: 'center',
        style: 'dataTitle'
      },
      {
        columns: [
          {
            text: [
              {
                text: 'Quem Indicou: ',
                style: 'dataLabel'
              },
              `${motorista.quemIndicou}`
            ],
          },
          {
            text: [
              {
                text: 'Cargo de quem indicou: ',
                style: 'dataLabel'
              },
              `${motorista.cargoQuemIndicou}`
            ],
          }
        ],
        margin: [30, 3]
      },
      motorista.coordenadorQuemIndicou ? {
        text: [
          {
            text: 'Coordenador de quem indicou: ',
            style: 'dataLabel'
          },
          `${motorista.coordenadorQuemIndicou}`
        ],
        margin: [30, 3]
      } : '',
    ]
  } else {
    return [
      {
        text: 'Não possui Indicação',
        margin: [0, 10],
        alignment: 'center',
        style: 'dataLabel'
      }
    ]
  }
}

export const getExperienciaProfissionalData = (experienciasProfissionais: ExperienciaProfissional[]): Content => {
  if (experienciasProfissionais.length > 0) {
    return [
      {
        text: 'Experiências Profissionais',
        margin: [10, 10],
        alignment: 'center',
        style: 'dataTitle'
      },
      experienciasProfissionais.map((experienciaProfissional, index) => {
        return [
          {
            text: `${index + 1}° Experiência Profissional`,
            margin: [30, 10],
            style: 'dataLabel'
          },
          experienciaProfissional.cargoFuncao ? {
            columns: [
              {
                text: [
                  {
                    text: 'Empresa: ',
                    style: 'dataLabel'
                  },
                  `${experienciaProfissional.empresa}`
                ],
              },
              {
                text: [
                  {
                    text: 'Cargo: ',
                    style: 'dataLabel'
                  },
                  `${experienciaProfissional.cargoFuncao}`
                ],
              }
            ],
            margin: [40, 3]
          } : [
            {
              text: [
                {
                  text: 'Empresa: ',
                  style: 'dataLabel'
                },
                `${experienciaProfissional.empresa}`
              ],
              margin: [40, 3]
            },
          ],
          {
            columns: [
              {
                text: [
                  {
                    text: 'UF: ',
                    style: 'dataLabel'
                  },
                  `${experienciaProfissional.uf ? experienciaProfissional.uf : 'Não possui'}`
                ],
              },
              {
                text: [
                  {
                    text: 'Cidade: ',
                    style: 'dataLabel'
                  },
                  `${experienciaProfissional.cidade ? experienciaProfissional.cidade : 'Não possui'}`
                ],
              }
            ],
            margin: [40, 3]
          },
          experienciaProfissional.atualmenteEmpregado ? {
            columns: [
              {
                text: [
                  {
                    text: 'Emprego atual: ',
                    style: 'dataLabel'
                  },
                  `${experienciaProfissional.atualmenteEmpregado ? 'Sim' : 'Não'}`
                ],
              },
              {
                text: [
                  {
                    text: 'Telefone: ',
                    style: 'dataLabel'
                  },
                  `${experienciaProfissional.telefone ? experienciaProfissional.telefone : 'Não possui'}`
                ],
              }
            ],
            margin: [40, 3]
          } : [
            {
              text: [
                {
                  text: 'Telefone: ',
                  style: 'dataLabel'
                },
                `${experienciaProfissional.telefone ? experienciaProfissional.telefone : 'Não possui'}`
              ],
              margin: [40, 3]
            },
          ],
          {
            columns: [
              experienciaProfissional.dataEntrada ? {
                text: [
                  {
                    text: 'Data de Entrada: ',
                    style: 'dataLabel'
                  },
                  `${FormatedDate(experienciaProfissional.dataEntrada.toString())}`
                ],
              } : '',
              experienciaProfissional.dataSaida ? {
                text: [
                  {
                    text: 'Data de Saída: ',
                    style: 'dataLabel'
                  },
                  `${FormatedDate(experienciaProfissional.dataSaida.toString())}`
                ],
              } : ''
            ],
            margin: [40, 3]
          },
          {
            columns: [
              experienciaProfissional.tipoCaminhao ? {
                text: [
                  {
                    text: 'Tipo Caminhão: ',
                    style: 'dataLabel'
                  },
                  `${OptionCaminhao.find(tipoCaminhao => tipoCaminhao.id === experienciaProfissional.tipoCaminhao)?.name}`
                ],
              } : '',
              experienciaProfissional.tempoExperienciaCaminhao && experienciaProfissional.dataEntrada ? {
                text: [
                  {
                    text: 'Tempo de Experiência: ',
                    style: 'dataLabel'
                  },
                  `${getValueFormatted(
                    experienciaProfissional.dataEntrada,
                    !experienciaProfissional.atualmenteEmpregado && experienciaProfissional.dataSaida
                      ? experienciaProfissional.dataSaida
                      : new Date(),
                    experienciaProfissional.tempoExperienciaCaminhao
                  )}`
                ],
              } : ''
            ],
            margin: [40, 3]
          },
          experienciaProfissional.principaisAtividades ? [
            {
              text: [
                {
                  text: 'Principais Atividades: ',
                  style: 'dataLabel'
                },
              ],
              margin: [40, 3]
            },
            {
              text: [
                {
                  text: experienciaProfissional.principaisAtividades.replace(/<[^>]+>/g, '')
                },
              ],
              margin: [45, 3]
            },
          ] : ''
        ]
      })
    ]
  } else {
    return [
      {
        text: 'Não possui Experiências Profissionais',
        margin: [0, 10],
        alignment: 'center',
        style: 'dataLabel'
      }
    ]
  }
}