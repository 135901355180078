import { Chip, makeStyles } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

type StatusChipProps = {
  label: string
  status:
  | 'AGENDAMENTO'
  | 'ENTREVISTA'
  | 'ADMISSAO'
  | 'REPROVADO'
  | 'CONCLUSAO'
  | 'SELECAO'
  | 'PENDENTE'
  | 'ATIVO'
  | 'INATIVO'
  | 'TRUE'
  | 'FALSE'
}

const useStyles = makeStyles({
  AGENDAMENTO: {
    backgroundColor: '#fff176',
    color: 'black'
  },
  ENTREVISTA: {
    backgroundColor: '#6FBEE4',
    color: 'black'
  },
  ADMISSAO: {
    backgroundColor: '#F7BB64',
    color: 'black'
  },
  REPROVADO: {
    backgroundColor: '#e3e3e3',
    color: 'black'
  },
  CONCLUSAO: {
    backgroundColor: '#81c784',
    color: 'black'
  },
  SELECAO: {
    backgroundColor: '#6FBEE4',
    color: 'black'
  },
  PENDENTE: {
    backgroundColor: '#fff176',
    color: 'black'
  },
  ATIVO: {
    backgroundColor: '#81c784',
    color: 'black'
  },
  INATIVO: {
    backgroundColor: '#e3e3e3',
    color: 'black'
  },
  TRUE: {
    backgroundColor: '#81c784',
    color: 'white'
  },
  FALSE: {
    backgroundColor: '#e57373',
    color: 'white'
  },
  chipLabel: {
    whiteSpace: 'break-spaces'
  }
})

const CustomChip = styled(Chip)({
  width: '7rem',
  height: '100%',
  minHeight: '32px',
  borderRadius: '10px',
  boxShadow: '0 2.5px #d0d0d0',
  textAlign: 'center'
})

export const StatusChip = ({ label, status }: StatusChipProps) => {
  const classes = useStyles()

  return (
    <CustomChip
      label={
        <span className={classes.chipLabel}>{label}</span>
      }
      className={classes[status]}
    />
  )
}
