import { optionsOrigemCurriculo } from '../../../utils'
import { CandidatoInfo, GridInfo, ProcessoAdministrativoInfo, VagasInfo } from '../types'

export interface DonutChartProps {
  labels: string[]
  series: number[]
}

export interface GroupedBarChartProps {
  customLegendItems: string[]
  categories: string[]
  series: ApexAxisChartSeries
}

export const labelsVagasInfo = [
  'Vagas Pendente de Aprovação',
  'Vagas em Divulgação',
  'Vagas em Triagem de Currículos',
  'Vagas em Entrevista',
  'Vagas Concluídas'
]

export function vagaInfoApiResponseToChart(vagaInfo: VagasInfo | undefined): DonutChartProps {
  if (vagaInfo) {
    return {
      labels: labelsVagasInfo,
      series: [
        vagaInfo.statusPendenteAprovacao,
        vagaInfo.statusEmDivulgacao,
        vagaInfo.statusEmTriagemCurriculos,
        vagaInfo.statusEmEntrevista,
        vagaInfo.statusConcluida
      ]
    }
  } else {
    return {
      labels: labelsVagasInfo,
      series: labelsVagasInfo.map(label => {
        return 0
      })
    }
  }
}

export const labelsProcessosAdministrativoInfo = [
  'Processos em Agendamento',
  'Processos em Entrevista',
  'Processos em Conclusão',
  'Processos Reprovados'
]

export function processosAdministrativoInfoApiResponseToChart(processoAdministrativoInfo: ProcessoAdministrativoInfo | undefined): DonutChartProps {
  if (processoAdministrativoInfo) {
    return {
      labels: labelsProcessosAdministrativoInfo,
      series: [
        processoAdministrativoInfo.statusAgendamento,
        processoAdministrativoInfo.statusEntrevista,
        processoAdministrativoInfo.statusConclusao,
        processoAdministrativoInfo.statusReprovado
      ]
    }
  } else {
    return {
      labels: labelsProcessosAdministrativoInfo,
      series: labelsProcessosAdministrativoInfo.map(label => {
        return 0
      })
    }
  }
}

export function candidatoInfoApiResponseToChart(candidatoInfo: CandidatoInfo | undefined): DonutChartProps {
  if (candidatoInfo) {
    const candidatoInfoKeys = Object.keys(candidatoInfo.countOrigemAdministrativo);
    if (candidatoInfoKeys && candidatoInfoKeys.length > 0) {
      return {
        labels: candidatoInfoKeys.map(key => {
          return optionsOrigemCurriculo.find(origem => origem.value === key)?.label || key
        }),
        series: candidatoInfoKeys.map(key => {
          return candidatoInfo.countOrigemAdministrativo[key]
        })
      }
    } else {
      return {
        labels: ['Nenhum candidato com origem informada'],
        series: [0]
      }
    }
  } else {
    return {
      labels: ['Nenhum dado retornado'],
      series: [0]
    }
  }
}

export const labelsGridsInfo = [
  'Total de Motoristas Titulares Hoje',
  'Total de Feristas Hoje',
  'Quantidade de Feristas Ideal',
  'Quantidade de Veículos'
]


export function gridInfoApiResponseToChart(gridInfo: GridInfo | undefined): GroupedBarChartProps {
  if (gridInfo) {
    return {
      customLegendItems: labelsGridsInfo,
      categories: gridInfo.grids.map(grid => grid.nome),
      series: [
        {
          name: labelsGridsInfo[0],
          data: gridInfo.grids.map(grid => grid.totalMotoristasAtual)
        }, {
          name: labelsGridsInfo[1],
          data: gridInfo.grids.map(grid => grid.totalFeristasAtual)
        }, {
          name: labelsGridsInfo[2],
          data: gridInfo.grids.map(grid => grid.feristasQtdIdeal)
        }, {
          name: labelsGridsInfo[3],
          data: gridInfo.grids.map(grid => grid.veiculos)
        }
      ]
    }
  } else {
    return {
      customLegendItems: labelsGridsInfo,
      categories: ['Nenhum dado retornado'],
      series: [{
        data: [0]
      }]
    }
  }
}