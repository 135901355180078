import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Login, PrivateRoute, AuthProvider, Callback } from './modules/login'
import HomeNavigation from './modules/home/HomeNavigation'
import Snackbar from './components/Snackbar'
import { withScollBar } from './App.styles'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import ptBr from 'date-fns/locale/pt-BR'
import './font.css'
import theme from './Theme'
import useSnackbar from '@elentari/core/hooks/useSnackbar'
import Documents from './modules/uploadDocuments/Documents'
import Curriculos from './modules/curriculos/screens/Home'
import ContratarDp from './modules/contratar-dp/ContratarDp'

const App = () => {
  const [snackbarState, snackbaractions] = useSnackbar()

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider locale={ptBr} utils={DateFnsUtils}>
        <AuthProvider>
          <Router>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/callback" component={Callback} />
              <Route path="/candidato/:id/documentos" component={Documents} />
              <Route path="/curriculos" component={Curriculos} />
	      <Route path="/contratar-dp" component={ContratarDp} />

              <PrivateRoute
                loginPath="/login"
                path="/"
                render={() => <HomeNavigation />}
              />
            </Switch>
          </Router>
          <Snackbar
            message={snackbarState.message}
            onClose={() => snackbaractions.setMessage('')}
            classes={{}}
          />
        </AuthProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default withScollBar(App)
