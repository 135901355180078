import { useContext } from 'react'
import { AuthContext } from '../../modules/login'
import ListTile from './ListTile'
import { User, AuthContext as AuthContextType } from '../../modules/login/types'

const withUser = (authContext: AuthContextType, map: (user: User) => string) =>
  authContext.initialized && authContext.state.loggedin
    ? map(authContext.state.user)
    : ''

const logoutUser = (authContext: AuthContextType) =>
  authContext.initialized && authContext.state.loggedin
    ? authContext.logout
    : () => {}

const UserAvatar = () => {
  const authContext = useContext(AuthContext)
  return (
    <ListTile
      logout={logoutUser(authContext)}
      name={withUser(authContext, user => user.name)}
    />
  )
}

export default UserAvatar
