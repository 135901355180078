import DatePicker from '@elentari/components-eve/final-form/DatePicker';
import Select from '@elentari/components-eve/final-form/Select';
import TextField from "@elentari/components-eve/final-form/TextField";
import { useSnackbar } from "@elentari/core";
import { Button, Grid, Tooltip } from "@material-ui/core";
import EventIcon from '@material-ui/icons/Event';
import { useState } from "react";
import ComplexDialog from "src/components/ComplexDialog";
import RichTextField from 'src/components/Forms/RichTextField';
import TimeField from "src/components/Forms/TimeField";
import { useLoggedUser } from "src/modules/login/AuthProvider";
import { MessagesYup } from "src/modules/messages";
import { etapaProcesso, isG10, tipoAgendamento, tipoContratacao, tipoEntrevista, tipoEntrevistaG10, tipoModalidade } from "src/utils";
import { combineDateWithTime } from "src/utils/formatters/formatDate";
import yupValidation from "src/utils/yupValidation";
import * as yup from 'yup';
import { agendarCandidato } from "../agendamento-candidato/hooks/agendamentoCandidatoRepository";
import { AgendamentosCandidatoFormData } from '../agendamento-candidato/types';

interface CreateAgendamentoButtonProps {
  candidatoId: string | false
  buttonLabel: string
  className?: string
  dialog?: {
    title?: string
    acceptLabel?: string
    rejectLabel?: string
  }
  candidatoFirstAgendamento?: boolean
  onSaveSuccessful?: () => Promise<void>
  disabled?: boolean
}

export const CreateAgendamentoButton = ({
  candidatoId,
  buttonLabel,
  className,
  dialog,
  candidatoFirstAgendamento,
  onSaveSuccessful,
  disabled
}: CreateAgendamentoButtonProps) => {

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const userLogged = useLoggedUser()
  const userEmpresaIsG10 = isG10(userLogged?.empresa)

  const dialogSchema = yup.object().shape({
    agendadoPor: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    dataMotoristaAgendado: yup
      .date()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
    tipoContratacao: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    dataAgendamento: yup
      .date()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
    horaAgendamento: yup
      .date()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_HORA_INVALIDA),
    tipoEntrevista: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    tipoAgendamento: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    comentarioRH: yup
      .string()
      .nullable()
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    modalidade: yup
      .string()
      .nullable()
      .typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
    etapa: yup
      .string()
      .nullable()
      .typeError(MessagesYup.MENSAGEM_CAMPO_STRING)
  })

  const handleAgendarCandidato = async (modalData: AgendamentosCandidatoFormData) => {
    try {
      if (!modalData.dataAgendamento || !modalData.horaAgendamento) {
        throw new Error('É necessário selecionar uma data e hora para o agendamento.')
      }
      if (candidatoId) {
        const result = await agendarCandidato({
          agendadoPor: modalData.agendadoPor,
          comentarioRH: modalData.comentarioRH,
          dataAgendamento: combineDateWithTime(modalData.dataAgendamento, modalData.horaAgendamento),
          tipoEntrevista: modalData.tipoEntrevista,
          tipoContratacao: modalData.tipoContratacao,
          tipoAgendamento: modalData.tipoAgendamento,
          etapa: modalData.etapa,
          modalidade: modalData.modalidade,
          candidatoId: candidatoId,
        })

        if (result.ok && result.info) {
          if (onSaveSuccessful) {
            await onSaveSuccessful()
          }
          snackbarActions.setMessage('Candidato(a) agendado(a) com sucesso')
        } else {
          if (result.info.message) {
            snackbarActions.setMessage(result.info.message)
          } else {
            snackbarActions.setMessage(
              'Ocorreu um erro ao agendar o(a) Candidato(a)'
            )
          }
        }
      } else {
        snackbarActions.setMessage('Não foi possível encontrar o candidato')
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        className={className}
        onClick={() => setOpenDialog(true)}
        disabled={disabled}
      >
        {buttonLabel}
      </Button>

      <ComplexDialog
        open={openDialog}
        title={dialog?.title ?? "Deseja agendar este Candidato(a)?"}
        primaryActionButtonLabel={dialog?.acceptLabel ?? "Agendar"}
        closeActionButtonLabel={dialog?.rejectLabel ?? "Voltar"}
        handleClose={() => setOpenDialog(false)}
        primaryAction={handleAgendarCandidato}
        validate={yupValidation(dialogSchema)}
        initialValues={{
          agendadoPor: userLogged?.name,
          dataMotoristaAgendado: new Date(),
          tipoAgendamento: candidatoFirstAgendamento ? 'PRIMEIRO_AGENDAMENTO' : undefined
        }}
        disableBackdropClick={true}
        maxWidth={'lg'}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              name="agendadoPor"
              label="Agendado por"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DatePicker
              fullWidth
              name="dataMotoristaAgendado"
              label={userEmpresaIsG10 ? "Data do Agendamento" : "Data em que o Agendamento aconteceu"}
              keyboardIcon={
                <Tooltip title="Selecionar data">
                  <EventIcon />
                </Tooltip>
              }
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Select
              fullWidth
              name="tipoContratacao"
              label="Tipo da Contratação"
              items={tipoContratacao}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Select
              fullWidth
              name="tipoEntrevista"
              label="Tipo da Entrevista"
              items={userEmpresaIsG10 ? tipoEntrevistaG10 : tipoEntrevista}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <DatePicker
              fullWidth
              name="dataAgendamento"
              label={userEmpresaIsG10 ? "Data do Processo" : "Data para o qual foi Agendado"}
              keyboardIcon={
                <Tooltip title="Selecionar data">
                  <EventIcon />
                </Tooltip>
              }
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TimeField label={userEmpresaIsG10 ? "Horário do Processo" : "Hora para o qual foi agendado"} name="horaAgendamento" />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Select
              fullWidth
              name="tipoAgendamento"
              label="Tipo de Agendamento"
              items={tipoAgendamento}
            />
          </Grid>
          {userEmpresaIsG10 && (
            <>
              <Grid item xs={12} md={6} lg={6}>
                <Select
                  fullWidth
                  name="modalidade"
                  label="Modalidade"
                  items={tipoModalidade}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Select
                  fullWidth
                  name="etapa"
                  label="Etapa"
                  items={etapaProcesso}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <RichTextField
              name="comentarioRH"
              label="Comentário RH"
            />
          </Grid>
        </Grid>
      </ComplexDialog>
    </>
  )
}