import { apiSauceInstance } from 'src/services/api'

export const updateStatusProcesso = async (
  processoSeletivoId: string,
  statusProcesso: string,
  reprovacao?: {tipoReprovacao: string, motivoReprovacao: string}
) => {
  const response = await apiSauceInstance.put<any>(
    `/processo-seletivo-administrativos/${processoSeletivoId}`,
    {
      status: statusProcesso,
      ...reprovacao
    }
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }

  return response.data
}

export const getProcessoById = async (processoSeletivoId: string) => {
  const response = await apiSauceInstance.get<any>(
    `/processo-seletivo-administrativos/${processoSeletivoId}`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }

  return response.data
}
