export interface VagaDetail {
  id: string
  area: string
  categoria?: string
  descricao: string
  nivelExperiencia: string
  salarioMin: number
  salarioMax: number
  salario: number
  vagaSigilosa: boolean
  nomeSubstituicao: string
  modoSelecao: string
  hasSalarioLimite: boolean
  status: string
  motivoContratacao?: string
  solicitadoPor?: string
  emailSolicitadoPor?: string
  divulgaSite: boolean
  equipamentos?: string
  responsavelRH?: string
  cargoId?: string
  cargoFk?: {
    nome: string
  }
  empresaId: string
}
export interface VagaFormData extends VagaDetail {}

export interface VagaShort extends VagaDetail {}

export interface SaveVagaInput extends Omit<VagaDetail, 'cargoFk'> {}

export const StatusVagas = [
  {
    label: 'Pendente de Aprovação',
    value: 'PENDENTE_APROVACAO'
  },
  {
    label: 'Rejeitada',
    value: 'REJEITADA'
  },
  {
    label: 'Em Divulgação',
    value: 'EM_DIVULGACAO'
  },
  {
    label: 'Em Triagem de Currículos',
    value: 'EM_TRIAGEM_DE_CURRICULOS'
  },
  {
    label: 'Em Entrevista',
    value: 'EM_ENTREVISTA'
  },
  {
    label: 'Concluído',
    value: 'CONCLUIDO'
  }
]

export const ModoSelecao = [
  {
    label: 'Interna',
    value: 'INTERNA'
  },
  {
    label: 'Externa',
    value: 'EXTERNA'
  },
  {
    label: 'Mista',
    value: 'MISTA'
  }
]

export const MotivoContratacao = {
  AUMENTO_QUADRO: 'AUMENTO_QUADRO',
  SUBSTITUICAO: 'SUBSTITUICAO_COLABORADOR'
}

export const motivoContratacao = [
  { label: 'Aumento de Quadro', value: 'AUMENTO_QUADRO' },
  { label: 'Substituição de Colaborador', value: 'SUBSTITUICAO_COLABORADOR' },
  { label: 'Estagiário', value: 'ESTAGIARIO' }
]
// VAGAS - Beneficios

export interface BeneficioDetail {
  id?: string
  vagasId: string
  descricao: string
  empresaId?: string
}

export interface VagaBeneficioDetail {
  vagasId: string
  Beneficios: BeneficioDetail[]
}

// VAGAS - Requisito

export interface RequisitoDetail {
  id?: string
  vagasId: string
  descricao: string
  empresaId?: string
}

export interface VagaRequisitoDetail {
  vagasId: string
  Requisitos: RequisitoDetail[]
}

// VAGAS - Responsabilidade

export interface ResponsabilidadeDetail {
  id?: string
  vagasId: string
  descricao: string
  empresaId?: string
}

export interface VagaResponsabilidadeDetail {
  vagasId: string
  Responsabilidades: ResponsabilidadeDetail[]
}

type Status = 'AGENDAMENTO' | 'ENTREVISTA' | 'CONCLUSAO' | 'REPROVADO'
export interface ProcessoSeletivoAdministrativoDetail {
  id: string
  candidatoId: string
  status: Status
  createdAt: string
  dataEncerramento: string
  comentario: string
  aderencia: number
  candidatoFK: {
    nomeCompleto: string
  }
  vagasFK: {
    cargoFk: {
      nome: string
    }
  }
  empresaFK: {
    sigla: string
  }
}

export const statusProcessoAdministrativo = [
  {
    value: 'AGENDAMENTO',
    label: 'Agendamento'
  },
  {
    value: 'ENTREVISTA',
    label: 'Entrevista'
  },
  {
    value: 'REPROVADO',
    label: 'Reprovado'
  },
  {
    value: 'CONCLUSAO',
    label: 'Concluído'
  }
]

export interface SubmitValues {
  createdAt?: Date
  name?: string
  active?: string
  solicitadoPor?: string
  status?: string
  modoSelecao?: string
}
