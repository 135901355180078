import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core"
import { ChangeEvent } from "react"

type RadioSelecaoOption = {
  label: string
  value: any
}

interface RadioGroupSelecaoProps {
  options: RadioSelecaoOption[]
  value: boolean | undefined
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void
}

export const RadioGroupSelecaoField = ({
  options,
  value,
  onChange
}: RadioGroupSelecaoProps) => {
  return (
    <FormControl>
      <RadioGroup
        row
        value={value}
        onChange={onChange}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            label={option.label}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}