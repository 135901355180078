import { Button, CardContent, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core'
import {
  ArrowBack,
  Add
} from '@material-ui/icons'
import { ReactNode, useState } from 'react'
import { Paper } from '.'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fill-available',
    paddingTop: '0.5rem'
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right',
    padding: '2rem 0rem'
  },
  overflowContainer: {
    maxHeight: '203px',
    overflow: 'scroll'
  },
  cardInicial: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  returnButton: {
    position: 'absolute',
    top: '2rem',
    left: '2rem'
  },
  titleSpacing: {
    paddingLeft: 60
  }
}))

export const PaperCard = ({
  title,
  Content,
  Icons,
  returnButtonText,
  returnButtonAction,
  primaryButtonText,
  primaryButtonAction,
  primaryButtonIcon,
  disableScroll
} : {
  title: string
  Content: ReactNode
  Icons?: ReactNode
  returnButtonText?: string
  returnButtonAction?: () => void
  primaryButtonText?: string
  primaryButtonAction?: () => void
  primaryButtonIcon?: ReactNode
  disableScroll?: boolean
}) => {

  const [openTooltip, setOpenTooltipTooltip] = useState<boolean>(false)
  const style = useStyles()

  return (
    <Paper minHeight={340} maxHeight={340}>
      {returnButtonAction && (
        <Button
          className={style.returnButton}
          color="default"
          onClick={returnButtonAction}
          startIcon={<ArrowBack />}
        >
          {returnButtonText || 'Voltar'}
        </Button>
      )}
      <CardContent className={style.container}>
        <div className={style.dataContainer}>
          <div className={style.cardInicial}>
            <div className={style.dataContainer}>
              <Typography
                variant="h5"
                color="primary"
                align="center"
                className={primaryButtonAction || Icons ? style.titleSpacing : undefined}
              >
                {title}
              </Typography>
            </div>
            {primaryButtonAction && (
              <Tooltip
                title={primaryButtonText || "Adicionar"}
                disableHoverListener={true}
                onMouseOver={() => setOpenTooltipTooltip(true)}
                onMouseLeave={() => setOpenTooltipTooltip(false)}
                open={openTooltip}
              >
                <IconButton
                  aria-label="edit"
                  onClick={() => {
                    setOpenTooltipTooltip(false)
                    primaryButtonAction()
                  }}
                  color="primary"
                >
                  {primaryButtonIcon || <Add />}
                </IconButton>
              </Tooltip>
            )}
          </div>
          <div className={disableScroll ? undefined : style.overflowContainer}>
            {Content}
          </div>
        </div>
        {Icons && (
          <div className={style.iconContainer}>
            {Icons}
          </div>
        )}
      </CardContent>
    </Paper>
  )
}