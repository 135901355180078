import { makeAutoObservable } from "mobx";
import { SubEmpresaDetail } from "../types";

class SubEmpresasStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  subEmpresasList: SubEmpresaDetail[] = []

  getSubEmpresasList() {
    return this.subEmpresasList
  }

  setSubEmpresasList(subEmpresas: SubEmpresaDetail[]) {
    this.subEmpresasList = subEmpresas
  }

}

const subEmpresasStore = new SubEmpresasStore()

export default subEmpresasStore