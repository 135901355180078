import { useSnackbar } from '@elentari/core'
import { getAuthorizations } from '../../../utils/crudAuthorization'
import { HandleErrorPage } from '../../../utils/handleErrorPage'
import { useAbility } from '../../login'
import { repository } from './hooks/administrativoRepository'
import { useAdministrativos } from './hooks/useAdministrativos'
import { AdministrativoFilter } from './AdministrativoFilter'
import { AdministrativoTable } from './AdministrativoTable'
import { AdministrativoDetail } from './types'

const AdministrativoList = (props: {
  list: AdministrativoDetail[]
  loading: boolean
  totalCount: number
  onDelete: (id: string) => void
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}) => {

  return (
    <>
      <AdministrativoFilter />
      <AdministrativoTable {...props} />
    </>
  )
}

const Administrativos = () => {
  const [state, actions] = useAdministrativos()
  const [, snackbarActions] = useSnackbar()
  const ability = useAbility()
  const user = JSON.parse(localStorage.user)

  async function handleDelete(id: string) {
    const response = await repository.delete(id)
    actions.fetchPage()
    if (response.ok) {
      snackbarActions.setSnackBar({
        message: 'Deletado com sucesso',
        severity: 'success'
      })
      actions.fetchPage()
      return
    }
    const message = (response.data as unknown as any).message
    snackbarActions.setSnackBar({
      message: message as string,
      severity: 'warning'
    })
  }

  const authorization = getAuthorizations(ability, 'administrativo')

  switch (state.tag) {
    case 'empty':
      return (
        <AdministrativoList
          onDelete={() => { }}
          totalCount={0}
          list={[]}
          loading={true}
        />
      )
    case 'with-data':
      return (
        <AdministrativoList
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onDelete={handleDelete}
          authorization={authorization}
        />
      )
    case 'error':
      return <HandleErrorPage state={state.data} />
  }
}

export default Administrativos
