import { apiSauceInstance } from '../../../services/api'
import { AgendamentoAdministrativoDetail, SaveAgendamentoInput } from '../types'
import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { ParsedQs } from 'qs'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params
  return { ...params, ...paginationParams(rest) }
}

export const AgendamentoRepository = makeRepository<
  EdgesPage<AgendamentoAdministrativoDetail>,
  SaveAgendamentoInput,
  AgendamentoAdministrativoDetail,
  string
>(
  {
    resource: 'agendamento-administrativos',
    mapQueryParams
  },
  apiSauceInstance
)

export const enviarEmailGestor = async (idAgendamento: string) => {
  const response = await apiSauceInstance.post<any>(
    '/agendamento-administrativos/enviar-email-gestor',
    {
      idAgendamento
    }
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }

  return null
}
