import { TipoContratacao } from '../types'

export const aprovarTriagemOptions: { label: string, value: TipoContratacao }[] = [
  {
    label: 'Curso de Formação',
    value: 'CURSO_FORMACAO'
  },
  {
    label: 'Contratação Direta',
    value: 'DIRETA'
  }
]

export const reprovarTriagemOptions = [
  {
    label: 'Instabilidade Profissional',
    value: 'INSTABILIDADE_PROFISSIONAL'
  },
  {
    label: 'Não tem CNH E',
    value: 'NO_CNH_E'
  },
  {
    label: 'Processos Judiciais',
    value: 'PROCESSOS_JUDICIAIS'
  },
  {
    label: 'Reside em estado em que não temos rota',
    value: 'ESTADO_SEM_ROTA'
  },
  {
    label: 'Não tem interesse',
    value: 'NO_INTERESSE'
  },
  {
    label: 'Excesso de pontuação negativa na CNH',
    value: 'CNH_PONTUACAO_NEGATIVA'
  },
  {
    label: 'Potencial',
    value: 'POTENCIAL'
  }
]

export function formatMotivoTriagem(atendeCriterios: boolean, motivo: string | undefined): string {
  if (!motivo) return '-'

  if (atendeCriterios) {
    return aprovarTriagemOptions.find(triagem => triagem.value === motivo)?.label || '-'
  } else {
    if (motivo === 'LISTA_BLOQUEIO') {
      return 'Lista Bloqueio'
    } else {
      return reprovarTriagemOptions.find(triagem => triagem.value === motivo)?.label || '-'
    }
  }
}