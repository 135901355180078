import { MessagesYup } from 'src/modules/messages'
import * as yup from 'yup'

const schemaObject = yup.object().shape({
  operacao: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  coordenador: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  gridId: yup.string().nullable().typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  tipoContratacao: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  dataPrevistaExame: yup
    .date()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  dataEntregaDocumentos: yup
    .date()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  dataPrevistaAdmissao: yup
    .date()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_HORA_INVALIDA),
  observacao: yup
    .string()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
})

export const schema = schemaObject
