import { Paper, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

interface InfoCardProps {
  title: string
  info: string
}

const InfoPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  padding: theme.spacing(2)
}))

export default function InfoCard({ title, info }: InfoCardProps) {
  return (
    <InfoPaper>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="h5">{info}</Typography>
    </InfoPaper>
  )
}
