import {
  IconButton,
  Tooltip,
} from "@material-ui/core"
import PrintIcon from '@material-ui/icons/Print';

type ImprimirIconProps = {
  title: string
  onClick: () => void
  disabled: boolean
}

export const ImprimirIcon = ({
  title,
  onClick,
  disabled
} : ImprimirIconProps) => {
  return (
    <Tooltip title={title}>
      <IconButton
        aria-label="imprimir"
        onClick={onClick}
        disabled={disabled}
      >
        <PrintIcon />
      </IconButton>
    </Tooltip>
  )
}