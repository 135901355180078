import {
  FormControl,
  FormHelperText,
  makeStyles,
  TextField
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useField } from 'formik'
import { useState, useEffect } from 'react'
import { Option } from '../../../hooks/useAutoComplete'

interface TextInputProps {
  id?: string
  name: string
  label: string
  disabled?: boolean
  options: Option[]
  forceFieldValue?: boolean
}

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120
  }
}))

function AutoCompleteInput(props: TextInputProps) {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<Option[]>([])
  const [field, meta, helpers] = useField(props)

  useEffect(() => {
    if(field.value && props.forceFieldValue) {
      setInputValue(field.value)
    }
  }, [field.value])

  useEffect(() => {
    if(field.value && props.forceFieldValue && props.options.find(option => option.id === field.value ) === undefined) {
      setOptions([
        {
          id: field.value,
          name: field.value
        },
        ...props.options
      ])
    } else {
      setOptions(props.options)
    }
  }, [props.options])

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      fullWidth
      error={meta.touched && Boolean(meta.error)}
    >
      <Autocomplete
        fullWidth
        options={options}
        disabled={props.disabled}
        clearText="Limpar"
        closeText="Fechar"
        openText="Abrir"
        getOptionLabel={option => option.name}
        noOptionsText="Sem opção"
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        value={options.find(u => u.id === field.value) ?? null}
        onChange={(e, value) => helpers.setValue(value?.id || null)}
        renderInput={params => (
          <TextField
            variant="outlined"
            {...params}
            {...props}
            error={meta.touched && Boolean(meta.error)}
          />
        )}
      ></Autocomplete>
      <FormHelperText>{meta.touched && meta.error}</FormHelperText>
    </FormControl>
  )
}

export default AutoCompleteInput
