import { EdgesPage } from '@elentari/core/types'
import QueryString from 'qs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAbility } from 'src/modules/login'
import { apiSauceInstance } from 'src/services/api'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import { AdmissaoCandidatoTable } from './AdmissaoCandidatoTable'
import { AdmissaoCandidatoFilter } from './AdmissaoCandidatoFilter'
import { CandidatoDetail } from '../types'
import { MotoristaDetail } from '../motorista/types'

const AdmissaoCandidatoList = (props: {
  list: { node: CandidatoDetail }[]
  loading: boolean
  totalCount: number
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
  candidatoType: 'ADMINISTRATIVO' | 'MOTORISTA'
}) => {
  return (
    <>
      <AdmissaoCandidatoFilter candidatoType={props.candidatoType} />
      {props.candidatoType === 'MOTORISTA' ?
        <AdmissaoCandidatoTable {...props} list={props.list as { node: MotoristaDetail }[]} />
        :
        <AdmissaoCandidatoTable {...props} />
      }
    </>
  )
}

type APIResponseCandidatos = {
  edges: { node: CandidatoDetail }[]
  totalCount: number
  loading: boolean
}

const AdmissaoCandidato = ({
  candidatoType
}: {
  candidatoType: 'ADMINISTRATIVO' | 'MOTORISTA'
}) => {
  const [candidatosState, setCandidatosState] = useState<APIResponseCandidatos>(
    {} as APIResponseCandidatos
  )
  const { search, pathname } = useLocation()
  const ability = useAbility()

  const authorization = getAuthorizations(ability, 'agendamentosMotoristas')

  useEffect(() => {
    async function loadCandidatos() {
      try {
        setCandidatosState({
          ...candidatosState,
          loading: true
        })

        const params: { [key: string]: any } = {
          sort: { createdAt: 'desc' },
          ...QueryString.parse(search.substring(1))
        }

        const response = await apiSauceInstance.get<
          EdgesPage<CandidatoDetail>
        >(`/candidato?${QueryString.stringify(params)}`, {
          tipo: candidatoType,
          statusCandidato: 'ADMITIDO',
          skip: params.page ? (+params.page - 1) * 10 : 0,
          limit: 10
        })
        if (response.ok && response.data) {
          setCandidatosState({
            totalCount: response.data.totalCount,
            edges: response.data.edges,
            loading: false
          })
        } else {
          setCandidatosState({
            totalCount: 0,
            edges: [],
            loading: false
          })
        }
      } catch (error) { }
    }
    loadCandidatos()
  }, [pathname, search])

  return (
    <>
      {candidatosState.loading === false ? (
        <AdmissaoCandidatoList
          totalCount={candidatosState.totalCount}
          list={candidatosState.edges}
          loading={candidatosState.loading}
          authorization={authorization}
          candidatoType={candidatoType}
        />
      ) : (
        <AdmissaoCandidatoList
          totalCount={0}
          list={[]}
          loading={candidatosState.loading}
          authorization={authorization}
          candidatoType={candidatoType}
        />
      )}
    </>
  )
}

export const AdmissaoMotorista = () => <AdmissaoCandidato candidatoType="MOTORISTA" />
export const AdmissaoAdministrativo = () => <AdmissaoCandidato candidatoType="ADMINISTRATIVO" />