import lugares from '../cidades.json'

export function cidadeExistInEstado(cidade: string | undefined, estado: string | undefined): boolean {
    if(!cidade || !estado) {
        return false;
    }

    const findEstado = lugares.estados.find(
        estados => estados.sigla === estado
    )

    if(findEstado) {
        return findEstado.cidades.indexOf(cidade) !== -1
    }

    return false;
}
