import { useSnackbar } from '@elentari/core'
import { Grid, Typography } from '@material-ui/core'
import { Formik, FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import CopyInput from 'src/components/Formik/Forms/CopyInput'
import RichTextInput from 'src/components/Formik/Forms/RichTextInput'
import * as yup from 'yup'
import { FormWrap, Paper } from '../../../components'
import { MessagesYup } from '../../messages'
import DescricaoInput from '../components/DescricaoInput'
import { GenerateNewLink } from '../components/GenerateNewLink'
import { SendEmailLink } from '../components/SendEmailLink'
import {
  createDocumentos,
  fetchProcessoById,
  renovateLink
} from '../hooks/documentosRepository'
import { getProcessoById } from '../hooks/processoSeletivoAdministrativoRepository'
import { DocumentosArray, DocumentosDetail, ProcessoSeletivoAdministrativoDetail } from '../types'

export const DocumentosAdministrativoForm = () => {
  const location = useLocation()
  const [documentos, setDocumentos] = useState<DocumentosArray>()
  const [processo, setProcesso] = useState<ProcessoSeletivoAdministrativoDetail>()
  const [, snackbarActions] = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingGenerateLink, setLoadingGenerateLink] = useState<boolean>(false)

  const webUrl = window.location.origin
  useEffect(() => {
    async function fetchData() {
      const paramProcessoId = location.pathname.split('/')[2]
      if (paramProcessoId) {
        const response = await fetchProcessoById(paramProcessoId)
        const responseProcesso = await getProcessoById(paramProcessoId)
        
        setProcesso(responseProcesso.info)
        setDocumentos({
          processoSeletivoId: paramProcessoId,
          documentosToken: response?.info[0]?.candidatoFK?.documentosToken,
          Documentos: response?.info,
          observacaoConclusao: responseProcesso.info.observacaoConclusao
        } as DocumentosArray)
      }
    }

    fetchData()
  }, [location])

  const handleGenerateLink = async () => {
    try {
      setLoadingGenerateLink(true)
      if(processo) {
        const response = await renovateLink(processo.id)

        if (!response.ok) {
          snackbarActions.setMessage(response.message)
        } else {
          setDocumentos({
            documentosToken: response?.info?.token,
            processoSeletivoId: processo.id,
            Documentos: documentos!.Documentos,
            observacaoConclusao: processo.observacaoConclusao
          })
          snackbarActions.setMessage('Link renovado com sucesso!')
        }
      } else {
        snackbarActions.setMessage('Ocorreu um erro ao renovar o link')
      }
      setLoadingGenerateLink(false)
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  const schemaObject = {
    Documentos: yup.array().of(
      yup.object().shape({
        descricao: yup
          .string()
          .trim()
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
    )
  }
  const schema = yup.object().shape(schemaObject)

  const formikState = {
    initialValues: {
      processoSeletivoId: '',
      documentosToken: '',
      Documentos: [
        {
          descricao: '',
          URL: '',
          origemDocumento: ''
        }
      ]
    },
    onSubmit: async (data: FormikValues) => {
      try {
        setLoading(true)
        const response = await createDocumentos({
          ...data,
          Documentos: data.Documentos.map((documento: any) => {
            return {
              ...documento,
              origemDocumento: 'ETAPA_CONCLUSAO'
            } as DocumentosDetail
          }),
          processoSeletivoId: processo?.id
        } as DocumentosArray)
        setDocumentos({
          processoSeletivoId: processo?.id,
          documentosToken: response?.info[0]?.candidatoFK?.documentosToken,
          Documentos: response?.info,
          observacaoConclusao: data.observacaoConclusao
        } as DocumentosArray)

        if (!response.ok) {
          snackbarActions.setMessage(response.message)
        } else {
          snackbarActions.setMessage('Salvo com sucesso!')
        }
        setLoading(false)
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={formikState.onSubmit}
        enableReinitialize
        initialValues={
          documentos?.Documentos?.length
            ? documentos
            : formikState.initialValues
        }
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <Paper>
            <FormWrap
              handleSubmit={handleSubmit}
              disableSubmit={isSubmitting}
              disableBack={isSubmitting}
              loading={loading}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    color="primary"
                    align="center"
                    component="h1"
                  >
                    Etapa da Conclusão
                  </Typography>
                </Grid>

                <DescricaoInput />

                <Grid item xs={12}>
                  <Typography variant="caption">
                    Esta etapa de conclusão deve ser enviada ao candidato para
                    inclusão de documentos solicitados.
                  </Typography>
                </Grid>
                {documentos?.Documentos?.length ? (
                  <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item xs={12} sm={12} md={10}>
                      <CopyInput
                        name="link"
                        value={`${webUrl}/candidato/${documentos?.documentosToken}/documentos`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1}>
                      <SendEmailLink id={processo?.id || ''} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={1}>
                      <GenerateNewLink
                        id={processo?.id || ''}
                        onClick={handleGenerateLink}
                        loading={loadingGenerateLink}
                      />
                    </Grid>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <RichTextInput
                    name="observacaoConclusao"
                    label="Observação"
                  />
                </Grid>
              </Grid>
            </FormWrap>
          </Paper>
        )}
      </Formik>
    </>
  )
}
