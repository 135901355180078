import { useQueryParams } from '@elentari/core'
import Filter from '@elentari/components-eve/components/Filter'
import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import TextField from '@elentari/components-eve/final-form/TextField'
import {
  Tooltip,
  Grid
} from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import {
  useEffect,
  useState
} from 'react'
import * as yup from 'yup'
import { FormSpy } from 'react-final-form'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import lugares from '../../../utils/cidades.json'
import { booleanOptions } from '../../../utils'
import { Option } from '../../../hooks/useAutoComplete'
import { InteresseRepository } from 'src/modules/areas-interesse/hooks/InteresseRepository'
import RFFAutocomplete from '../../../components/Forms/RFFAutocomplete'
import SelectField from 'src/components/Forms/SelectField'

type AdministrativoQueryFilter = {
  createdAt?: { gt: string }
  [key: string]: any
}

const schema = yup.object().shape({
  name: yup.string().typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  email: yup.string().typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  dataNascimento: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
})

const AdministrativoFilter = () => {
  const [uf, setUF] = useState<Option[]>([])
  const [interessesOptions, setInteresseOptions] = useState<Option[]>([])

  const { clear, push, initialValues } =
    useQueryParams<AdministrativoQueryFilter>()

  useEffect(() => {
    const ufs = lugares.estados.map(locais => {
      return {
        id: locais.sigla,
        name: locais.sigla
      }
    })
    setUF(ufs)
  }, [])

  const getCidadesFromUf = (uf: string): Option[] => {
    const findUF = lugares.estados.find(cidade => cidade.sigla === uf)

    if (findUF) {
      const cidades = findUF.cidades.map(cidade => {
        return {
          id: cidade,
          name: cidade
        }
      })

      return cidades
    } else {
      return []
    }
  }

  const loadInteresses = async () => {
    const response = await InteresseRepository.fetch({ limit: '999' })

    if (response.ok) {
      setInteresseOptions(
        response.data?.edges.map(i => ({
          id: i.node.id,
          name: i.node.descricao
        })) ?? []
      )
    }
  }

  useEffect(() => {
    loadInteresses()
  }, [])

  const handleSubmitFilter = (values: any) => {
    if (values.dataNascimento) {
      values.dataNascimento = new Date(values.dataNascimento).toISOString()
    }
    push({
      ...values
    })
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={{
        ...initialValues,
        incluirBloqueados: 'false'
      }}
      onClear={clear}
      onSubmit={values => handleSubmitFilter(values)}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item md={3} sm={12} xs={12}>
          <TextField fullWidth name="cpf" label="CPF" />
        </Grid>
        <Grid item md={3} sm={12} xs={12}>
          <TextField fullWidth name="name" label="Nome Completo" />
        </Grid>
        <Grid item md={3} sm={12} xs={12}>
          <TextField fullWidth name="email" label="E-mail" />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <SelectField
            name="incluirBloqueados"
            label="Lista Bloqueio"
            items={booleanOptions}
            defaultValue={'false'}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <RFFAutocomplete
            name="interesseId"
            label="Área de interesse"
            options={interessesOptions}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <RFFAutocomplete name="uf" label="UF" options={uf} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <FormSpy
            render={({ values }) => (
              <RFFAutocomplete name="cidade" label="Cidade" options={getCidadesFromUf(values.uf)} disabled={!values.uf} />
            )}
          />
        </Grid>
        <Grid item md={3} sm={12} xs={12}>
          <DatePicker
            fullWidth
            name="dataNascimento"
            label="Data Nascimento"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export { AdministrativoFilter }