import { useSnackbar } from '@elentari/core'
import { CircularProgress, Grid, GridJustification } from '@material-ui/core'
import { ApiResponse } from 'apisauce'
import { useState } from 'react'
import { AbrirAnexoIcon } from 'src/components/Icons/AbrirAnexoIcon'
import { apiSauceInstance } from 'src/services/api'

interface FileComponentProps {
  id?: string
  route: string
  iconType?: 'clip' | 'default'
  justifyContent?: GridJustification
}

const FileComponent = ({ id, route, iconType = 'default', justifyContent = 'flex-start' }: FileComponentProps) => {
  const [loading, setLoading] = useState(false)

  const [, snackbarActions] = useSnackbar()

  function getResponseHeaderMimeType(response: ApiResponse<BlobPart>): string | null {
    const headers = response.headers
    const headerMimeType: string = headers !== undefined ? headers['content-type'] : ''
    return headerMimeType
  }

  const downloadDocumentoCandidato = (id: string | undefined, route: string) => {
    if (id) {
      const url = `uploads/${route}/${id}`
      setLoading(true)

      apiSauceInstance
        .get<BlobPart>(url, {}, { responseType: 'blob' })
        .then(response => {
          setLoading(false)

          const headerMimeType = getResponseHeaderMimeType(response)

          if (response.ok && headerMimeType) {

            const url = URL.createObjectURL(
              new Blob([response.data!], { type: headerMimeType })
            )

            window.open(url)
          } else {
            snackbarActions.setMessage('Erro ao baixar arquivo')
          }
        })
    } else {
      snackbarActions.setMessage('Não foi possível encontrar o arquivo')
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        cursor: 'pointer',
        width: '100%',
        height: '40px'
      }}

    >
      <Grid container spacing={2} justifyContent={justifyContent} alignContent="center">
        {loading ? (
          <CircularProgress size={20} style={{ padding: '12px' }} />
        ) : (
          <AbrirAnexoIcon
            onClick={() => downloadDocumentoCandidato(id, route)}
            disabled={!id}
            iconType={iconType}
          />
        )}
      </Grid>
    </div>
  )
}

export default FileComponent