import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import LinkMD from '@material-ui/core/Link'
import { Skeleton } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  ProcessoSeletivoAdministrativoDetail,
  statusProcessoAdministrativo
} from '../types'
import {
  NoResultsTable,
  Paper,
  TableContainer,
  TableCellHead
} from '../../../components'
import { StatusChip } from 'src/components/StatusChip'
import { FormatedDate } from 'src/utils'
import { apiSauceInstance } from '../../../services/api'

interface Props {
  list: ProcessoSeletivoAdministrativoDetail[]
  loading: boolean
  totalCount: number
}

export const ProcessosAdmVagaTable = ({ list, loading, totalCount }: Props) => {
  return (
    <Paper>
      <Grid style={{ marginBottom: 30 }} container>
        <Grid item xs>
          <Typography variant="h5" color="primary" align="center">
            Candidatos(as)
          </Typography>
        </Grid>
      </Grid>
      <TableContainer loading={loading} totalCount={0} disablePagination={true}>
        {list.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCellHead label="Candidato(a)" />
                <TableCellHead label="Aderência" />
                <TableCellHead label="Status" />
                <TableCellHead label="Data Início do Processo" />
                <TableCellHead label="Data Encerramento do Processo" />
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map(processo => (
                <TableRow hover key={processo.id}>
                  <TableCell>
                    <LinkMD
                      component={Link}
                      to={`/processo-seletivo-administrativo/${processo.id}`}
                    >
                      {processo.candidatoFK.nomeCompleto}
                    </LinkMD>
                  </TableCell>
                  <TableCell>{`${processo.aderencia}%`}</TableCell>
                  <TableCell>
                    <StatusChip
                      label={
                        statusProcessoAdministrativo.find(
                          status => status.value === processo.status
                        )?.label ?? processo.status
                      }
                      status={processo.status}
                    />
                  </TableCell>
                  <TableCell>
                    {processo.createdAt && FormatedDate(processo.createdAt)}
                  </TableCell>
                  <TableCell>
                    {processo.dataEncerramento &&
                      FormatedDate(processo.dataEncerramento)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <NoResultsTable loading={loading} />
        )}
      </TableContainer>
    </Paper>
  )
}

type CandidatosVagasListProps = {
  vagaId: string
}

type APIResponseProcessos = {
  edges: ProcessoSeletivoAdministrativoDetail[]
  totalCount: number
  loading: boolean
}

const ProcessoAdministrativoVagaList = ({ vagaId }: CandidatosVagasListProps) => {
  const [processosState, setProcessosState] = useState<APIResponseProcessos>(
    {} as APIResponseProcessos
  )

  useEffect(() => {
    async function loadProcessosById() {
      try {
        setProcessosState({
          ...processosState,
          loading: true
        })

        const { data } = await apiSauceInstance.get<any>(
          `/processo-seletivo-administrativos/candidato`,
          {
            vagaId
          }
        )
        if (data) {
          setProcessosState({
            totalCount: data.totalCount,
            edges: data.edges,
            loading: false
          })
        }
      } catch (error) {}
    }
    loadProcessosById()
  }, [vagaId])

  return (
    <div>
      {processosState.loading === false ? (
        <ProcessosAdmVagaTable
          list={processosState.edges}
          totalCount={processosState.totalCount}
          loading={processosState.loading}
        />
      ) : (
        <Paper>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Paper>
      )}
    </div>
  )
}

export default ProcessoAdministrativoVagaList
