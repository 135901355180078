import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { ParsedQs } from 'qs'
import { apiSauceInstance } from '../../../services/api'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { SaveVagaInput, VagaDetail } from '../types'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, limit, ...rest } = params

  return {
    ...paginationParams(rest),
    sort: sort || {
      createdAt: 'desc'
    },
    limit: limit || '10'
  }
}

export const vagasRepository = makeRepository<
  EdgesPage<VagaDetail>,
  SaveVagaInput,
  VagaDetail,
  string
>({ resource: 'vagas', mapQueryParams }, apiSauceInstance)

export const vagasEmTriagem = async () => {
  const response = await apiSauceInstance.get<any>('/vagas', {
    status: ['EM_ENTREVISTA', 'EM_DIVULGACAO', 'EM_TRIAGEM_DE_CURRICULOS']
  })

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const lastVagaByCargo = async (cargoId: string) => {
  return await apiSauceInstance.get<VagaDetail>(
    `/vagas/last-vaga-by-cargo/${cargoId}`
  )
}

export const divulgarVaga = async (vagaId: string) => {
  return await apiSauceInstance.get<any>(`/vagas/divulgar-vaga/${vagaId}`)
}

export const requestNewCargo = async (cargo: VagaDetail) => {
  return await apiSauceInstance.post<VagaDetail>(
    `/vagas/solicitar-cargo`,
    cargo
  )
}
