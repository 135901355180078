  import {
    CrudRoute,
    CrudTabs,
    useTabsNavigation
  } from '@elentari/components-eve/components/CrudTabs'
  import { CargoBeneficioForm } from './CargoBeneficioForm'
  import { CargoResponsabilidadeForm } from './CargoResponsabilidadeForm'
  import { CargoRequisitoForm } from './CargoRequisitoForm'
  import { CargoForm } from './CargoForm'

  export const CargoTabs = () => {
    const hook = useTabsNavigation({
      mainPath: 'cargo',
      tabs: [
        {
          value: '',
          label: 'Dados Cargo'
        },
        { value: 'beneficios', label: 'Benefícios' },
        { value: 'responsabilidades', label: 'Responsabilidades' },
        { value: 'requisitos', label: 'Requisitos' }
      ]
    })
    return (
      <CrudTabs {...hook}>
        <CrudRoute render={() => <CargoForm />} />
        <CrudRoute name="beneficios" render={() => <CargoBeneficioForm />} />
        <CrudRoute name="responsabilidades" render={() => <CargoResponsabilidadeForm />} />
        <CrudRoute name="requisitos" render={() => <CargoRequisitoForm />} />
      </CrudTabs>
    )
  }
  