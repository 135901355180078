import { Button } from "@material-ui/core"
import { useHistory, useLocation } from "react-router-dom"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

type ReturnButtonProps = {
  disableButtons: boolean
}

export const ReturnButton = ({
  disableButtons
}: ReturnButtonProps) => {

  const history = useHistory()
  const location = useLocation()

  function handleReturnList(): void {
    const newLocation = location.pathname
      .split('/')
      .filter(Boolean)
      .slice(0, -1)
      .join('/')

    history.push(`/${newLocation}`)
  }


  return (
    <Button
      disabled={disableButtons}
      color="default"
      onClick={() => handleReturnList()}
      startIcon={<ArrowBackIcon />}
    >
      Retornar para a Listagem
    </Button>
  )
}