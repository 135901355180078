import CurrencyInput from 'react-currency-input'
import { useEffect, useState } from 'react'
import {
  FilledInput,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  OutlinedInput,
  styled
} from '@material-ui/core'

type CurrencyComponentProps = {
  value?: number
  onChange(value: number): void
  onBlur?(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void
  precision: string
  label: string
  disabled?: boolean
  id?: string
  inputType?: string
  allowNegative?: boolean
  error?: boolean
  helperText?: string
  name?: string
  color?: 'primary' | 'secondary'
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode

  decimalSeparator: string
  thousandSeparator: string

  variant?: 'standard' | 'outlined' | 'filled'
}

const Control = styled(FormControl)(() => ({
  '& .MuiInputLabel-shrink': {
    transform: 'translate(13px, -5.5px) scale(0.75) !important'
  }
}))

export const CurrencyComponent: React.FC<CurrencyComponentProps> = props => {
  const [value, setValue] = useState(0)

  useEffect(() => {
    setValue(props.value ?? 0)
  }, [props.value])

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    const newValue = Number(
      e.target.value
        .split(props.thousandSeparator)
        .join('')
        .replace(props.decimalSeparator, '.')
    )

    setValue(newValue)
    props.onChange(newValue)
  }

  if (!props.variant || props.variant === 'standard') {
    return (
      <FormControl color={props.color} error={props.error} fullWidth>
        <InputLabel error={props.error} shrink id={props.label}>
          {props.label}
        </InputLabel>
        <Input
          name={props.name}
          error={props.error}
          fullWidth
          inputComponent={CurrencyInput}
          disabled={props.disabled}
          onBlur={props.onBlur}
          color={props.color}
          inputProps={{
            id: props.id,
            value: props.value ? Number(props.value) : value,
            disabled: props.disabled,
            onChangeEvent: handleChange,
            decimalSeparator: props.decimalSeparator,
            thousandSeparator: props.thousandSeparator,
            precision: props.precision,
            inputType: props.inputType,
            allowNegative: props.allowNegative
          }}
          endAdornment={props.endAdornment}
          startAdornment={props.startAdornment}
        />
        {props.error ? (
          <FormHelperText error={props.error}>
            {props.helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    )
  } else if (props.variant === 'filled') {
    return (
      <FormControl color={props.color} error={props.error} fullWidth>
        <InputLabel error={props.error} shrink id={props.label}>
          {props.label}
        </InputLabel>
        <FilledInput
          name={props.name}
          error={props.error}
          fullWidth
          inputComponent={CurrencyInput}
          disabled={props.disabled}
          onBlur={props.onBlur}
          color={props.color}
          inputProps={{
            id: props.id,
            value: props.value ? Number(props.value) : value,
            disabled: props.disabled,
            onChangeEvent: handleChange,
            decimalSeparator: props.decimalSeparator,
            thousandSeparator: props.thousandSeparator,
            precision: props.precision,
            inputType: props.inputType,
            allowNegative: props.allowNegative
          }}
          endAdornment={props.endAdornment}
          startAdornment={props.startAdornment}
        />
        {props.error ? (
          <FormHelperText error={props.error}>
            {props.helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    )
  } else {
    return (
      <Control color={props.color} error={props.error} fullWidth>
        <InputLabel error={props.error} shrink id={props.label}>
          {props.label}
        </InputLabel>
        <OutlinedInput
          name={props.name}
          error={props.error}
          notched
          label={props.label}
          fullWidth
          inputComponent={CurrencyInput}
          disabled={props.disabled}
          onBlur={props.onBlur}
          color={props.color}
          inputProps={{
            id: props.id,
            value: props.value ? Number(props.value) : value,
            disabled: props.disabled,
            onChangeEvent: handleChange,
            decimalSeparator: props.decimalSeparator,
            thousandSeparator: props.thousandSeparator,
            precision: props.precision,
            inputType: props.inputType,
            allowNegative: props.allowNegative
          }}
          endAdornment={props.endAdornment}
          startAdornment={props.startAdornment}
        />
        {props.error ? (
          <FormHelperText error={props.error}>
            {props.helperText}
          </FormHelperText>
        ) : null}
      </Control>
    )
  }
}