import { useEffect, useState } from "react"
import { Option } from "src/hooks/useAutoComplete"
import lugares from '../utils/cidades.json'
import AutoCompleteInput from "./Formik/Forms/AutoCompleteInput"

interface CidadeInputProps {
  label: string
  name: string
  uf: string | undefined
}

export const CidadeInput = ({
  label,
  name,
  uf
}: CidadeInputProps) => {
  const [cidades, setCidades] = useState<Option[]>([])

  const handleListCitys = (estado: string): Option[] => {
    const findUF = lugares.estados.find(estados => estados.sigla === estado)

    if (findUF) {
      const cidades = findUF.cidades.map(cidade => {
        return {
          id: cidade,
          name: cidade
        }
      })

      return cidades
    }

    return []
  }

  useEffect(() => {
    if (uf) {
      setCidades(
        handleListCitys(uf)
      )
    }
  }, [uf])

  return (
    <AutoCompleteInput
      label={label}
      disabled={!cidades || !uf}
      name={name}
      options={cidades}
    />
  )
}