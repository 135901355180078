import { MongoQuery, Ability, AbilityTuple, Subject } from '@casl/ability'

import { AnyObject } from '@casl/ability/dist/types/types'

export const getAuthorizations = (
  ability:
    | Ability<AbilityTuple<string, Subject>, MongoQuery<AnyObject>>
    | undefined,
  name: string
) => ({
  canDelete: !!ability?.can('delete', name),
  canUpdate: !!ability?.can('update', name),
  canCreate: !!ability?.can('create', name)
})
