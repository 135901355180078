import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import { useVaga } from '../hooks/useVaga'
import { LayoutTabs } from './VagaTabs'
export function VagaMasterDetail() {
  const [vagaState, vagaActions] = useVaga()

  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      vagaState,
      l => l.entity.id,
      l => l.entity.cargoFk?.nome ?? 'Cargo'
    )
  ])
  useFetchAndReset(vagaActions.fetch, vagaActions.reset)
  return handleStateErrorsToRender(vagaState, <LayoutTabs />)
}
