import { Button, Divider, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import CaptchaInput from 'src/components/CapcthaInput'
import { CidadeInput } from 'src/components/CidadeInput'
import ConvertionInput from 'src/components/Formik/Forms/ConvertionInput'
import RichTextInput from 'src/components/Formik/Forms/RichTextInput'
import SimpleDialog from 'src/components/SimpleDialog'
import {
  dateValues,
  getValueFormatted,
  isCellPhone,
  OptionCaminhao
} from 'src/utils'
import { FormikStep } from '../../../../components/Formik/FormikStepper'
import AutoCompleteInput from '../../../../components/Formik/Forms/AutoCompleteInput'
import SimpleCheckbox from '../../../../components/Formik/Forms/ChekboxInput'
import DateInput from '../../../../components/Formik/Forms/DateInput'
import MaskInput from '../../../../components/Formik/Forms/MaskInput'
import '../../../../components/Formik/Forms/SelectInput'
import TextInput from '../../../../components/Formik/Forms/TextInput'
import { Option } from '../../../../hooks/useAutoComplete'
import lugares from '../../../../utils/cidades.json'

interface Props {
  label: string
  candidatoType: string
  validationSchema: any
}

interface FormikValuesProps {
  primeiroEmprego: boolean
  ExperienciaProfissional: {
    dataEntrada: Date
    dataSaida: Date
    uf: string
    atualmenteEmpregado: boolean
    telefone: string
    tempoExperienciaCaminhao: number
  }[]
}

const useStyles = makeStyles(theme => ({
  bottomSpace: {
    paddingBottom: 20
  },
  captchaContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  removeButton: {
    flex: 1,
    minHeight: '56px',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      border: 'none',
      '&:hover': {
        border: 'none'
      }
    }
  },
}))

export default function ExperienciaProfCurriculoStep({
  label,
  candidatoType,
  validationSchema
}: Props) {
  const [uf, setUF] = useState<Option[]>([])
  const [confirmationMultipleEmpregoAtual, setConfirmationMultipleEmpregoAtual] = useState<{
    open: boolean,
    alreadyShown: boolean,
    indexExpProfChecked: number
  }>({
    open: false,
    alreadyShown: false,
    indexExpProfChecked: 0
  })
  const [openConfirmationPrimeiroEmprego, setOpenConfirmationPrimeiroEmprego] =
    useState<boolean>(false)

  const { values, setFieldValue } = useFormikContext<FormikValuesProps>()

  const classes = useStyles()

  const isAdministrativo = candidatoType === 'Administrativo'
  const showRemoveItem = values.ExperienciaProfissional.length > 1

  useEffect(() => {
    if (values.ExperienciaProfissional.length === 0) {
      setFieldValue('ExperienciaProfissional', [
        {
          empresa: '',
          cargoFuncao: '',
          cidade: '',
          uf: '',
          telefone: '',
          dataEntrada: null,
          dataSaida: null,
          principaisAtividades: '',
          tipoCaminhao: undefined,
          tempoExperienciaCaminhao: 0,
          atualmenteEmpregado: false
        }
      ])
    }
  }, [setFieldValue, values])

  const handleAddExp = () => {
    setFieldValue('ExperienciaProfissional', [
      ...values.ExperienciaProfissional,
      {
        atualmenteEmpregado: false,
        cargoFuncao: '',
        cidade: '',
        dataEntrada: null,
        dataSaida: null,
        empresa: '',
        telefone: '',
        uf: '',
        principaisAtividades: '',
        tipoCaminhao: undefined,
        tempoExperienciaCaminhao: 0
      }
    ])
  }
  const handleClose = () => {
    setOpenConfirmationPrimeiroEmprego(false)
    setFieldValue('primeiroEmprego', false)
  }

  const handleConfirmation = () => {
    setOpenConfirmationPrimeiroEmprego(false)
    setFieldValue('primeiroEmprego', true)
  }

  const handleRemoveExp = (index: number) => {
    const exp = [...values.ExperienciaProfissional]
    if (exp.length < 2) {
      return
    }
    exp.splice(index, 1)
    setFieldValue('ExperienciaProfissional', [...exp])
  }

  const handleClickPrimeiroEmprego = () => {
    if (values.primeiroEmprego === false) {
      setOpenConfirmationPrimeiroEmprego(true)
    }
  }

  const handleMultipleEmpregoAtual = (indexExpProf: number) => {
    if (
      values.ExperienciaProfissional.filter(exp => exp.atualmenteEmpregado).length === 1 &&
      values.ExperienciaProfissional.find(exp => exp.atualmenteEmpregado) !== values.ExperienciaProfissional[indexExpProf] &&
      !confirmationMultipleEmpregoAtual.alreadyShown
    ) {
      setConfirmationMultipleEmpregoAtual({
        ...confirmationMultipleEmpregoAtual,
        open: true,
        indexExpProfChecked: indexExpProf
      })
    }
  }

  const handleConfirmationMultipleEmpregoAtual = () => {
    setConfirmationMultipleEmpregoAtual({
      ...confirmationMultipleEmpregoAtual,
      alreadyShown: true,
      open: false
    })
    setFieldValue(`ExperienciaProfissional.${confirmationMultipleEmpregoAtual.indexExpProfChecked}.atualmenteEmpregado`, true)
  }

  const handleCloseMultipleEmpregoAtual = () => {
    setConfirmationMultipleEmpregoAtual({
      ...confirmationMultipleEmpregoAtual,
      open: false
    })
    setFieldValue(`ExperienciaProfissional.${confirmationMultipleEmpregoAtual.indexExpProfChecked}.atualmenteEmpregado`, false)
  }

  useEffect(() => {
    const ufs = lugares.estados.map(locais => {
      return {
        id: locais.sigla,
        name: locais.sigla
      }
    })
    setUF(ufs)
  }, [])

  return (
    <FormikStep label={label} validationSchema={validationSchema}>
      <Grid container spacing={2} className={classes.bottomSpace}>
        <Grid item xs={12}>
          <Typography
            className={classes.bottomSpace}
            variant="h5"
            align="center"
          >
            {label}
          </Typography>
        </Grid>
        {candidatoType === 'Administrativo' && (
          <Grid item xs={12}>
            <SimpleCheckbox
              onClick={handleClickPrimeiroEmprego}
              name="primeiroEmprego"
              label="Meu Primeiro Emprego"
            />
          </Grid>
        )}
        {!values.primeiroEmprego && (
          <>
            {values.ExperienciaProfissional.map((exp, index) => (
              <Grid key={index} container className={classes.bottomSpace}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.bottomSpace}
                    variant="h5"
                    align="center"
                  >
                    {index > 0 && ` ${index + 1}° Experiência Profissional`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  spacing={2}
                  xs={12}
                  sm={showRemoveItem ? 10 : 12}
                  md={showRemoveItem ? 11 : 12}
                  lg={showRemoveItem ? 11 : 12}
                  className={classes.bottomSpace}
                >
                  <Grid item xs={12} md={4}>
                    <TextInput
                      label="Empresa"
                      name={`ExperienciaProfissional.${index}.empresa`}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <MaskInput
                      mask={
                        isCellPhone(
                          values.ExperienciaProfissional[index].telefone
                        )
                          ? '(99) 9 9999-9999'
                          : '(99) 9999-9999'
                      }
                      label="Telefone"
                      name={`ExperienciaProfissional.${index}.telefone`}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                      label="Cargo"
                      name={`ExperienciaProfissional.${index}.cargoFuncao`}
                    />
                  </Grid>

                  {!isAdministrativo && (
                    <Grid item xs={12} md={4}>
                      <AutoCompleteInput
                        label="Tipo do Caminhão"
                        name={`ExperienciaProfissional.${index}.tipoCaminhao`}
                        options={OptionCaminhao}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={4}>
                    <AutoCompleteInput
                      label="UF"
                      name={`ExperienciaProfissional.${index}.uf`}
                      options={uf}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <CidadeInput
                      label="Cidade"
                      name={`ExperienciaProfissional.${index}.cidade`}
                      uf={values.ExperienciaProfissional[index].uf}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DateInput
                      label="Data de Entrada"
                      name={`ExperienciaProfissional.${index}.dataEntrada`}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DateInput
                      label="Data de Saída"
                      name={`ExperienciaProfissional.${index}.dataSaida`}
                      disabled={
                        values.ExperienciaProfissional[index]
                          .atualmenteEmpregado
                      }
                    />
                  </Grid>

                  <Grid item xs={6} md={isAdministrativo ? 4 : 2}>
                    <SimpleCheckbox
                      label="Emprego Atual"
                      name={`ExperienciaProfissional.${index}.atualmenteEmpregado`}
                      onBlur={() => {
                        setFieldValue(
                          `ExperienciaProfissional.${index}.dataSaida`,
                          null
                        )
                      }}
                      onClick={() => handleMultipleEmpregoAtual(index)}
                    />
                  </Grid>

                  <Grid item xs={6} md={isAdministrativo ? 4 : 2}>
                    <TextInput
                      name="tempoExperiencia"
                      label="Tempo de Experiência"
                      disabled
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={`${getValueFormatted(
                        values.ExperienciaProfissional[index].dataEntrada,
                        !values.ExperienciaProfissional[index]
                          .atualmenteEmpregado
                          ? values.ExperienciaProfissional[index].dataSaida
                          : new Date(),
                        values.ExperienciaProfissional[index]
                          .tempoExperienciaCaminhao
                      )}`}
                    />
                    <ConvertionInput
                      hidden
                      disabled
                      index={index}
                      diffDates={{
                        startDate:
                          values.ExperienciaProfissional[index].dataEntrada,
                        endDate: !values.ExperienciaProfissional[index]
                          .atualmenteEmpregado
                          ? values.ExperienciaProfissional[index].dataSaida
                          : new Date().toISOString()
                      }}
                      name={`ExperienciaProfissional.${index}.tempoExperienciaCaminhao`}
                      label="Tempo de Experiência"
                      convertion={{
                        items: dateValues,
                        operator: 'MULT'
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <RichTextInput
                      label="Principais Atividades"
                      name={`ExperienciaProfissional.${index}.principaisAtividades`}
                    />
                  </Grid>

                  <Divider />
                </Grid>
                {showRemoveItem && (
                  <Grid item spacing={2} container xs={12} sm={2} md={1} lg={1}>
                    <Grid item xs={12}>
                      <Tooltip
                        placement="top"
                        title={`Deletar ${index + 1}° Experiência`}
                      >
                        <Button
                          className={classes.removeButton}
                          fullWidth
                          variant="outlined"
                          color="primary"
                          onClick={() => handleRemoveExp(index)}
                        >
                          <DeleteOutlineIcon />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ))}
            <Grid item xs={12}>
              <Typography variant="caption" align="center">
                Adicione aqui todas as suas experiências profissionais.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Tooltip placement="right-start" title="Adicionar Experiência">
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={handleAddExp}
                  startIcon={<AddIcon />}
                >
                  Adicionar outra Experiência
                </Button>
              </Tooltip>
            </Grid>
          </>
        )}
        <Grid item className={classes.captchaContainer} xs={12}>
          <CaptchaInput name="recaptcha" />
        </Grid>
        <SimpleDialog
          open={openConfirmationPrimeiroEmprego === true}
          buttonLabel="Cancelar"
          handleClose={handleClose}
          primaryAction={handleConfirmation}
          title="Deseja selecionar Primeiro Emprego?"
          primaryActionButtonLabel="Confirmar"
        >
          <strong>Atenção:</strong> Ao confirmar e salvar, você estará
          concordando em anular todas as experiências profissionais preenchidas.
        </SimpleDialog>
        <SimpleDialog
          open={confirmationMultipleEmpregoAtual.open}
          buttonLabel="Cancelar"
          title="Mais de um emprego atual identificado"
          primaryAction={handleConfirmationMultipleEmpregoAtual}
          handleClose={handleCloseMultipleEmpregoAtual}
          primaryActionButtonLabel="Confirmar"
        >
          <strong>Atenção:</strong> Ao confirmar e salvar, você estará
          concordando que possui mais de uma experiência profissional que trabalha atualmente.
        </SimpleDialog>
      </Grid>
    </FormikStep>
  )
}
