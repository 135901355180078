import { ReactNode, useState } from 'react'
import { FormWrap } from 'src/components';
import { useLoggedUser } from 'src/modules/login/AuthProvider';
import { useHistory } from 'react-router'
import { ResultadoColors } from 'src/utils';
import {
  makeStyles,
  createStyles,
  Theme
} from '@material-ui/core'
import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver';
import { SaveTreinamentoInput, TreinamentoFormData } from '../types';
import { treinamentosRepository } from '../hooks/treinamentosRepository';
import { useFormikContext } from 'formik';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reprovado: {
      backgroundColor: ResultadoColors.REPROVADO,
      '&:hover': {
        color: 'white',
        backgroundColor: ResultadoColors.REPROVADO_DARK
      }
    },
    aprovado: {
      backgroundColor: ResultadoColors.APROVADO,
      '&:hover': {
        color: 'white',
        backgroundColor: ResultadoColors.APROVADO_DARK
      }
    }
  })
)

export const TreinamentoFormWrap = ({
  children,
  formLoading,
  isNew
}: {
  children: ReactNode,
  formLoading: boolean,
  isNew: boolean
}) => {
  const { handleSubmit, isSubmitting, values } = useFormikContext<TreinamentoFormData>();
  const [formWrapLoading, setFormWrapLoading] = useState<boolean>(false)
  const userLogged = useLoggedUser()
  const isGestorRh = userLogged?.rolesInSystem.includes('GESTOR_RH')
  const history = useHistory()
  const classes = useStyles()

  const { save } = useEntitySaver<SaveTreinamentoInput>(async data => {
    const response = await treinamentosRepository.save(data)
    setTimeout(() => {
      setFormWrapLoading(false)
    }, 1000)

    return response
  })
  
  const handleUpdateStatusTreinamento = async (status: 'APROVADO' | 'REPROVADO') => {
    setFormWrapLoading(true)
    
    await save({
      ...values,
      status
    } as SaveTreinamentoInput);
  }

  const customActionsFactory = () => {
    const actions = []
    if(!isNew) {
      if(isGestorRh && values.id && values.status === 'PENDENTE_APROVACAO') {
        const approveTreinamento = {
          label: 'Aprovar',
          disabled: false,
          onClick: () => {
            handleUpdateStatusTreinamento('APROVADO')
          },
          classes: classes.aprovado
        }
        const rejectTreinamento = {
          label: 'Rejeitar',
          disabled: false,
          onClick: () => {
            handleUpdateStatusTreinamento('REPROVADO')
          },
          classes: classes.reprovado
        }
        actions.push(rejectTreinamento)
        actions.push(approveTreinamento)
      }
    }
    return actions
  }
  const customActions = customActionsFactory()
  
  return (
    <FormWrap
      handleSubmit={handleSubmit}
      disableSubmit={isSubmitting}
      disableBack={isSubmitting}
      hideConfirmButton={isGestorRh}
      loading={formLoading || formWrapLoading}
      customActions={customActions}
      handleGoBack={() => history.goBack()}
    >
      {children}
    </FormWrap>
  )
}