import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import LinkMD from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { isUuid } from 'src/utils/regexValidations'
import useAppBar from '../../hooks/useAppBar'


type PropsBreadCrumb = { path: string; label: string }
export default function SimpleBreadcrumb() {
  const location = useLocation()
  const [state, actions] = useAppBar()
  const [paths, setPaths] = useState<PropsBreadCrumb[]>([] as PropsBreadCrumb[])

  const tamanhoMaximoTexto = 30

  const pathFormated = (text: string) => {
    const firstLetter = text.charAt(0).toUpperCase()
    const rest = text.slice(1)

    return firstLetter + rest
  }

  useEffect(() => {
    actions.setPath(location.pathname)
  }, [location.pathname])

  useEffect(() => {
    const newPaths: PropsBreadCrumb[] = []
    const pathIgnores = [
      'candidato',
      'processo-seletivo-administrativo',
      'candidato-treinamento'
    ]

    state.linksPaths.map((item: { path: string; label: string }) => {
      if (!pathIgnores.includes(item?.label)) {
        if (isUuid(item?.label)) {
          newPaths.push({
            ...item,
            label: 'carregando ...'
          })
        } else {
          newPaths.push({
            ...item,
            label: pathFormated(item?.label)
          })
        }
      }
    })
    setPaths(newPaths)
  }, [state])

  if (!state.lastPath) {
    return null
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {paths.map((item: PropsBreadCrumb) => (
        <LinkMD component={Link} color="inherit" key={item.path} to={item.path}>
          {item?.label}
        </LinkMD>
      ))}
      <Typography key={state.lastPath} color="textPrimary">
        {!isUuid(state.lastPath)
          ? state.lastPath.length > tamanhoMaximoTexto
            ? pathFormated(state.lastPath.slice(0, tamanhoMaximoTexto) + '...')
            : pathFormated(state.lastPath)
          : 'carregando ...'}
      </Typography>
    </Breadcrumbs>
  )
}
