import {
  Button,
  CircularProgress,
  Divider,
  Grid, makeStyles, Step,
  StepLabel,
  Stepper
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { Form, Formik, FormikConfig, FormikValues } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FormikStepperFieldPending } from './FormikStepperFieldPending'

interface FormikStepperProps extends FormikConfig<FormikValues> {
  hideValidationMessage?: boolean
}

interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string
  disabled?: boolean
  header?: boolean
}

const useStyles = makeStyles(theme => ({
  divisor: {
    marginTop: 20,
    marginBottom: 20
  },
  buttonCell: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '160px'
    }
  }
}))

export function FormikStep({ children }: FormikStepProps) {
  const classes = useStyles()

  return (
    <>
      <Divider className={classes.divisor} />
      {children}
    </>
  )
}

export function FormikStepper({
  children,
  hideValidationMessage = false,
  ...props
}: FormikStepperProps) {
  const [step, setStep] = useState(0)
  const history = useHistory()
  const location = useLocation()
  const allChilds = React.Children.toArray(
    children
  ) as React.ReactElement<FormikStepProps>[]
  const childHeader = allChilds.find(child => child.props.header === true)
  const childrenArray = allChilds.filter(child => child.props.header === undefined)
  const currentChild = childrenArray[step]
  const [forceSave, setForceSave] = useState(false)
  const [pathUpdate, setPathUpdate] = useState(
    !location.pathname.includes('/new')
  )
  const classes = useStyles()
  const isCurriculos = location.pathname.includes('/curriculos')

  function isLastStep() {
    return step === childrenArray.length - 1
  }

  function handleReturnList(): void {
    const newLocation = location.pathname
      .split('/')
      .filter(Boolean)
      .slice(0, -1)
      .join('/')

    history.push(`/${newLocation}`)
  }

  function handleGoBack() {
    const patnameNew = location.pathname.includes('/new')
    if (!patnameNew && step <= 0) {
      const id = location.pathname.split('/')[2]
      history.push(`/candidato/${id}`)
    }
    if (patnameNew && step <= 0) {
      handleReturnList()
    }
    setStep(s => s - 1)
  }

  const scrollTop = () => {
    const mainContainer = document.querySelector('main')
    if (mainContainer) {
      mainContainer.scrollTo({
        top: 0
      })
    }
  }

  useEffect(() => {
    scrollTop()
  }, [step])

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers)
        } else if (forceSave) {
          await props.onSubmit(values, helpers)
          setForceSave(false)
        } else {
          setStep(s => s + 1)
          helpers.setTouched({})
        }
      }}
    >
      {({ isSubmitting, errors, submitCount }) => (
        <Form autoComplete="off">
          {childHeader}

          <Stepper alternativeLabel activeStep={step}>
            {childrenArray.map((child, index) => (
              <Step key={child.props.label}>
                <StepLabel> {child.props.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {currentChild}

          <div className={classes.divisor}></div>
          {!hideValidationMessage && (
            <FormikStepperFieldPending step={step} />
          )}

          <Grid justifyContent="flex-end" item container spacing={2}>
            {pathUpdate && !isCurriculos && (
              <Grid item className={classes.buttonCell}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="default"
                  disabled={isSubmitting}
                  onClick={() => history.goBack()}
                >
                  Voltar
                </Button>
              </Grid>
            )}

            {!isCurriculos && (
              <Grid item className={classes.buttonCell}>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  color={pathUpdate ? 'primary' : 'default'}
                  onClick={() => handleGoBack()}
                  startIcon={pathUpdate && <ArrowBackIcon />}
                >
                  {pathUpdate ? 'Anterior' : 'Voltar'}
                </Button>
              </Grid>
            )}
            {(!isLastStep() || !pathUpdate) && (
              <Grid item className={classes.buttonCell}>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  color="primary"
                  variant={isLastStep() ? 'contained' : 'outlined'}
                  type="submit"
                  onClick={() => setForceSave(false)}
                  endIcon={pathUpdate && <ArrowForwardIcon />}
                >
                  {isSubmitting && isLastStep() ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : isLastStep() ? (
                    'Salvar'
                  ) : (
                    'Próximo'
                  )}
                </Button>
              </Grid>
            )}

            {pathUpdate && !isCurriculos && (
              <Grid item className={classes.buttonCell}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={() => setForceSave(true)}
                  type="submit"
                >
                  {isSubmitting ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    <>Salvar</>
                  )}
                </Button>
              </Grid>
            )}

            {isCurriculos && isLastStep() && (
              <>
                <Grid item className={classes.buttonCell}>
                  <Button
                    fullWidth
                    disabled={isSubmitting}
                    variant="outlined"
                    color="primary"
                    onClick={() => handleGoBack()}
                    startIcon={<ArrowBackIcon />}
                  >
                    Anterior
                  </Button>
                </Grid>
                <Grid item className={classes.buttonCell}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={() => setForceSave(true)}
                    type="submit"
                  >
                    {isSubmitting ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      <>Salvar</>
                    )}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
