import { EdgesPage } from "@elentari/core/types"
import QueryString from "qs"
import { apiSauceInstance } from "src/services/api"
import { LabelCandidatoDetail, LabelCaracteristicalDetail, LabelsCandidatoFormData } from "../types"

export const fetchAllLabelsCaracteristicas = async () => {
  const response = await apiSauceInstance.get<EdgesPage<LabelCaracteristicalDetail>>(
    `/label-caracteristicas`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const fetchLabelsCandidatoByCandidatoId = async (candidatoId: string) => {
  const response = await apiSauceInstance.get<LabelCandidatoDetail[]>(
    `/labels-candidatos?${QueryString.stringify({sort: {labelCaracteristicaFK: {descricao: 'asc'}}})}`,
    {
      candidatoId: candidatoId
    }
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const createLabelsCandidato = async (
  data: LabelsCandidatoFormData
) => {
  const response = await apiSauceInstance.post<any>(
    `/labels-candidatos/create-many`,
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }

  return response.data
}