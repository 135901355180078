import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core'
import { useField } from 'formik'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120
  }
}))

export interface SelectOption {
  value: number | string
  label: string
}

interface SelectInputProps {
  label: string
  name: string
  options: SelectOption[]
  disabled?: boolean
  defaultValue?: string
  firstOptionDisabled?: boolean
  onChange?: (event: any) => void
}

function SelectInput(props: SelectInputProps) {
  const classes = useStyles()
  const [field, meta] = useField(props)
  const { label, name, options, defaultValue, firstOptionDisabled } = props

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      fullWidth
      error={meta.touched && Boolean(meta.error)}
    >
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        defaultChecked
        defaultValue={defaultValue}
        labelId={name}
        {...field}
        {...props}
      >
        {!firstOptionDisabled && (
          <MenuItem value="" disabled>
            <em>Nenhuma opção</em>
          </MenuItem>
        )}

        {options.map(option => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{meta.touched && meta.error}</FormHelperText>
    </FormControl>
  )
}

export default SelectInput
