import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'
import { User } from './types'

type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete'

type Roles = 'Admin' | 'User' | string

export type TypeAppAbility = Ability<[Actions, string]>
export const AppAbility = Ability as AbilityClass<TypeAppAbility>

export const abilityFor = (user: User): Ability => {
  return new AppAbility(
    defineRulesFor(user.rolesInSystem ? user.rolesInSystem : []),
    {
      // @ts-ignore
      detectSubjectType: object => object!.type
    }
  )
}

function defineRulesFor(roles: Roles[]) {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility)

  if (roles?.includes('ADMIN')) {
    can(['manage'], 'all')
  }

  if (roles?.includes('ANALISTA_RH')) {
    can(['manage'], 'grids')
    can(['manage'], 'demandaContratacao')
    can(['manage'], 'listaBloqueios')

    can(['manage'], 'administrativo')
    can(['manage'], 'triagemAdministrativos')
    can(['manage'], 'aprovadosTriagemAdministrativos')
    can(['manage'], 'reprovadosTriagemAdministrativos')
    can(['manage'], 'agendamentosAdministrativos')
    can(['manage'], 'selecaoAdministrativo')
    can(['manage'], 'admissaoAdministrativo')
    can(['manage'], 'candidatosAdministrativos')
    can(['manage'], 'instrucaoAdmissaoAdministrativo')
    can(['manage'], 'processoContratacaoAdministrativo')
    can(['manage'], 'agendamentos-administrativo')
    can(['manage'], 'entrevista-administrativo')
    can(['manage'], 'admissao')
    can(['manage'], 'conclusao-administrativo')

    can(['manage'], 'motorista')
    can(['manage'], 'triagemMotoristas')
    can(['manage'], 'aprovadosTriagemMotoristas')
    can(['manage'], 'reprovadosTriagemMotoristas')
    can(['manage'], 'agendamentosMotoristas')
    can(['manage'], 'selecaoMotorista')
    can(['manage'], 'admissaoMotorista')
    can(['manage'], 'contratadoMotorista')
    can(['manage'], 'candidatosMotoristas')
    can(['manage'], 'instrucaoAdmissaoMotorista')

    can(['manage'], 'interesses')
    can(['manage'], 'label-caracteristicas')
    can(['manage'], 'selecao-candidatos-administrativo')
    can(['manage'], 'treinamentos')
    can(['manage'], 'treinamento-info')
  }

  if (roles?.includes('GESTOR_RH')) {
    can(['manage'], 'grids')
    can(['manage'], 'demandaContratacao')
    can(['manage'], 'listaBloqueios')

    can(['manage'], 'administrativo')
    can(['manage'], 'triagemAdministrativos')
    can(['manage'], 'aprovadosTriagemAdministrativos')
    can(['manage'], 'reprovadosTriagemAdministrativos')
    can(['manage'], 'agendamentosAdministrativos')
    can(['manage'], 'selecaoAdministrativo')
    can(['manage'], 'admissaoAdministrativo')
    can(['manage'], 'candidatosAdministrativos')
    can(['manage'], 'instrucaoAdmissaoAdministrativo')
    can(['manage'], 'processoContratacaoAdministrativo')
    can(['manage'], 'agendamentos-administrativo')
    can(['manage'], 'entrevista-administrativo')
    can(['manage'], 'admissao')
    can(['manage'], 'conclusao-administrativo')

    can(['manage'], 'motorista')
    can(['manage'], 'triagemMotoristas')
    can(['manage'], 'aprovadosTriagemMotoristas')
    can(['manage'], 'reprovadosTriagemMotoristas')
    can(['manage'], 'agendamentosMotoristas')
    can(['manage'], 'selecaoMotorista')
    can(['manage'], 'admissaoMotorista')
    can(['manage'], 'contratadoMotorista')
    can(['manage'], 'candidatosMotoristas')
    can(['manage'], 'instrucaoAdmissaoMotorista')

    can(['manage'], 'cargos')
    can(['manage'], 'cargo')
    can(['manage'], 'cargoArea')
    can(['manage'], 'cargoCategoria')
    can(['manage'], 'beneficio')
    can(['manage'], 'vagas')
    can(['manage'], 'vaga-info')
    can(['manage'], 'triagem-candidatos-administrativo')
    can(['manage'], 'treinamentos')
    can(['manage'], 'treinamento-info')
  }

  if (roles?.includes('GESTOR')) {
    can(['manage'], 'listaBloqueios')
    can(['manage'], 'vagas')
    can(['manage'], 'vaga-info')
    can(['manage'], 'cargos')
    can(['manage'], 'cargo')
    can(['manage'], 'cargoArea')
    can(['manage'], 'cargoCategoria')
    can(['manage'], 'beneficio')
    can(['manage'], 'treinamentos')
    can(['manage'], 'treinamento-info')
  }

  if (roles?.includes('GESTOR_FROTA')) {
    can(['manage'], 'grids')
    can(['manage'], 'demandaContratacao')

    can(['manage'], 'motorista')
    can(['manage'], 'triagemMotoristas')
    can(['manage'], 'aprovadosTriagemMotoristas')
    can(['manage'], 'reprovadosTriagemMotoristas')
    can(['manage'], 'agendamentosMotoristas')
    can(['manage'], 'selecaoMotorista')
    can(['manage'], 'admissaoMotorista')
    can(['manage'], 'contratadoMotorista')
    can(['manage'], 'candidatosMotoristas')
    can(['manage'], 'instrucaoAdmissaoMotorista')
  }

  return rules
}
