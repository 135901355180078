import { GridJustification } from '@material-ui/core'
import FileComponent from 'src/components/FileComponent'

interface DocumentoCandidatoFileProps {
  id?: string
  iconType?: 'clip' | 'default'
  justifyContent?: GridJustification
}

const DocumentoCandidatoFile = ({ id, iconType = 'default', justifyContent }: DocumentoCandidatoFileProps) => {
  return (
    <FileComponent id={id} route="documento-candidato" iconType={iconType} justifyContent={justifyContent} />
  )
}

export default DocumentoCandidatoFile