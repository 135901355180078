import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { NoResultsTable, TableCellHead, TableContainer } from '../../../components'
import { fetchTriagemByCandidatoId } from '../hooks/triagemRepository'
import { TriagemDetail } from '../types'
import { PaperCard } from 'src/components/PaperCard'
import { FormatedDate } from 'src/utils'
import { StatusChip } from 'src/components/StatusChip'
import { formatMotivoTriagem } from '../utils/triagemOptions'
import { ShowDetailsIcon } from "src/components/Icons/ShowDetails"
import ComplexDialog from 'src/components/ComplexDialog'
import { TriagemModalDetails } from './TriagemModalDetails'

type Props = {
  id: string
}

export const TriagemCard = ({ id }: Props) => {
  const [triagens, setTriagens] = useState<TriagemDetail[]>([])

  const TempoModal = 100
  const [confirmationDialog, setConfirmationDialog] = useState<{
    title: string
    open: boolean
    triagem?: TriagemDetail
  }>({
    title: '',
    open: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  const handleOpenDialog = (
    triagem: TriagemDetail
  ) => {
    setConfirmationDialog({
      title: 'Detalhes da Triagem',
      open: true,
      triagem
    })
  }

  const closeDialog = () => {
    setConfirmationDialog({
      ...confirmationDialog,
      open: false
    })

    setTimeout(() => {
      setConfirmationDialog({
        title: '',
        open: false
      })
    }, TempoModal)
  }

  useEffect(() => {
    async function fetchTriagem() {
      if (id) {
        setLoading(true)
        const response = await fetchTriagemByCandidatoId(id)
        setTriagens(response?.info)
        setLoading(false)
      }
    }
    fetchTriagem()
  }, [id])

  return (
    <>
      <PaperCard
        title="Triagens"
        Content={
          <TableContainer
            loading={loading}
            totalCount={0}
            disablePagination={true}
          >
            {triagens.length ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellHead label="Motivo" />
                    <TableCellHead label="Status" />
                    <TableCellHead label="Data de Triagem" />
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {triagens.map(triagem => (
                    <TableRow hover key={triagem.id}>
                      <TableCell>
                        {triagem.motivo ? formatMotivoTriagem(triagem.atendeCriterios, triagem.motivo) : '-'}
                      </TableCell>
                      <TableCell>
                        <StatusChip
                          label={triagem.atendeCriterios ? 'Aprovado' : 'Reprovado'}
                          status={triagem.atendeCriterios ? 'TRUE' : 'FALSE'}
                        />
                      </TableCell>
                      <TableCell>
                        {triagem.createdAt && FormatedDate(triagem.createdAt)}
                      </TableCell>
                      <TableCell>
                        <ShowDetailsIcon
                          onClick={() => handleOpenDialog(triagem)}
                          disabled={loading}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <NoResultsTable loading={loading} />
            )}
          </TableContainer>
        }
      />
      <ComplexDialog
        open={!!confirmationDialog.open}
        handleClose={closeDialog}
        primaryAction={() => {}}
        title={confirmationDialog.title}
        hidePrimaryActionButton={true}
        closeActionButtonLabel="Voltar"
        disableBackdropClick={true}
        transitionDuration={TempoModal}
      >
        <div style={{ minWidth: 500 }}>
          {confirmationDialog.triagem ? (
            <TriagemModalDetails triagem={confirmationDialog.triagem}/>
          ) : (
            <Typography color="textSecondary">
              <strong>Não foi possível carregar a triagem ...</strong>
            </Typography>
          )}
        </div>
      </ComplexDialog>
    </>
  )
}
