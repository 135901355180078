import { useCargoArea } from '../hooks/useCargoArea'
import { useAppBar } from '../../../../hooks'
import { handleStateErrorsToRender } from '../../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { CargoAreaForm } from './CargoAreaForm'

export function CargoAreaDetail() {
  const [CargoAreaState, CargoAreaActions] = useCargoArea()
  const [, appBarActions] = useAppBar()

  CargoAreaState &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useReplaceFragments(appBarActions.replaceFragment, [
      mapFragmentReplace(
        CargoAreaState,
        u => u.entity.id,
        u => u.entity.descricao
      )
    ])
  useFetchAndReset(CargoAreaActions.fetch, CargoAreaActions.reset)
  return handleStateErrorsToRender(CargoAreaState, <CargoAreaForm />)
}
