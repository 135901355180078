import {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useContext
} from 'react'
import { State, User, AuthContext as AuthContextType } from './types'
import api from '../../services/api'
import { Ability } from '@casl/ability'
import { abilityFor } from '.'
import config from '../../config'
import { LoadingPage } from 'src/components/LoadingPage'

export const AuthContext = createContext<AuthContextType>({
  initialized: false
})
export const { Consumer } = AuthContext

export const useAbility = () => {
  const context = useContext(AuthContext)
  return context.initialized ? context.ability : undefined
}
export const useLoggedUser = () => {
  const context = useContext(AuthContext)
  return context.initialized
    ? context.state.loggedin
      ? context.state.user
      : undefined
    : undefined
}

type Props = {
  children: ReactNode
}

const initialState: State = {
  loggedin: false
}

export default function AuthProvider({ children }: Props) {
  const [state, setState] = useState(initialState)
  const [ability, setAbility] = useState<Ability>()

  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const storedUser = localStorage.user

    if (storedUser) {
      const user = JSON.parse(storedUser) as User

      api
        .verifyToken(user.token)
        .then(response => {
          if (!response.problem && response.ok) {
            handleUserLogin()
          } else {
            throw new Error('Erro ao verificar o token')
          }
        })
        .catch(err => {
          delete localStorage.user
          api.setToken('')
          api.setEmpresa('')
        })
        .finally(() => {
          setInitialized(true)
        })
    } else {
      setInitialized(true)
    }
  }, [])

  const fetchRole = async (user: User) => {
    setAbility(abilityFor(user))
  }

  const handleUserLogin = (user?: User) => {
    if (!user) {
      user = JSON.parse(localStorage.user)
    }
    else{
      localStorage.user = JSON.stringify(user)   
    }
    api.setToken(user!.token)
    api.setEmpresa(user!.empresa)
    setState({
      user: user!,
      loggedin: true
    })
    fetchRole(user!)
  }

  const logout = async () => {
    const user = JSON.parse(localStorage.user)
    await api.logout(user.token, user.refresh_token)

    delete localStorage.user
    api.setToken('')
    api.setEmpresa('')
    // setAbility(undefined) TODO abilitar isso quando tiver roles configuradas
    window.location.reload()
  }

  return (
    <AuthContext.Provider
      value={{
        initialized,
        state,
        ability,
        logout,
        handleUserLogin
      }}
    >
      {!initialized ? <LoadingPage open={true} /> : children}
    </AuthContext.Provider>
  )
}
