import { Button, Grid, makeStyles } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { useAbility } from "src/modules/login"
import { getAuthorizations } from "src/utils/crudAuthorization"
import { CreateAgendamentoButton } from "./CreateAgendamentoButton"
import { CreateSelecaoButton } from "./CreateSelecaoButton"
import { CreateTriagemButton } from "./CreateTriagemButton"

const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    [theme.breakpoints.up('md')]: {
      width: '600px',
    },
    justifyContent: 'center'
  },
  returnButton: {
    backgroundColor: theme.palette.white
  },
  triagemButton: {
    backgroundColor: '#81c784',
    '&:hover': {
      backgroundColor: '#4dae52',
    }
  },
  agendamentoButton: {
    backgroundColor: '#fff176',
    '&:hover': {
      backgroundColor: '#ffe92a',
    }
  },
  selecaoButton: {
    backgroundColor: '#6FBEE4',
    '&:hover': {
      backgroundColor: '#6f84e4',
    }
  }
}))

interface CandidatoButtonsProps {
  candidatoId: string
  candidatoType: 'ADMINISTRATIVO' | 'MOTORISTA'
}

export const CandidatoButtons = ({
  candidatoId,
  candidatoType
}: CandidatoButtonsProps) => {
  const history = useHistory()
  const style = useStyles()

  const ability = useAbility()
  const triagemCandidatoAuthorization = getAuthorizations(ability,
    candidatoType === 'MOTORISTA' ? 'triagemMotoristas' : 'triagemAdministrativos'
  )
  const agendamentoCandidatoAuthorization = getAuthorizations(ability,
    candidatoType === 'MOTORISTA' ? 'agendamentosMotoristas' : 'agendamentosAdministrativos'
  )
  const selecaoCandidatoAuthorization = getAuthorizations(ability,
    candidatoType === 'MOTORISTA' ? 'selecaoMotorista' : 'selecaoAdministrativo'
  )


  return (
    <Grid item container xs={12} justifyContent='center'>
      <Grid container spacing={4} className={style.buttonsContainer}>
        <Grid item container xs={12} md={3} alignContent='center' justifyContent='center'>
          <Button
            fullWidth
            variant="outlined"
            className={style.returnButton}
            onClick={() => history.goBack()}
          >
            Voltar
          </Button>
        </Grid>
        {triagemCandidatoAuthorization.canCreate && (
          <Grid item container xs={12} md={3} alignContent='center' justifyContent='center'>
            <CreateTriagemButton
              candidatoId={candidatoId}
              buttonLabel="Triagem"
              dialog={{
                title: "Desejar aprovar a triagem deste Candidato(a)?",
                acceptLabel: "APROVAR"
              }}
              defaultAtendeCriterios={true}
              className={style.triagemButton}
            />
          </Grid>
        )}
        {agendamentoCandidatoAuthorization.canCreate && (
          <Grid item container xs={12} md={3} alignContent='center' justifyContent='center'>
            <CreateAgendamentoButton
              candidatoId={candidatoId}
              buttonLabel="Agendamento"
              className={style.agendamentoButton}
            />
          </Grid>
        )}
        {selecaoCandidatoAuthorization.canCreate && (
          <Grid item container xs={12} md={3} alignContent='center' justifyContent='center'>
            <CreateSelecaoButton
              candidatoId={candidatoId}
              buttonLabel="Seleção"
              className={style.selecaoButton}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}