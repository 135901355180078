import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { useEffect, useState } from 'react'
import { IoIosArrowDown } from 'react-icons/io'
import historicoCandidatoStore from './store'

const CustomTableCell = withStyles(() => ({
  root: {
    color: '',
    fontWeight: 'bold',
    fontSize: 14
  }
}))(TableCell)

interface TableCellHeadProps {
  label: string
  sort?: string
  className?: any
  tooltip?: string
}

export function HistoricoTableCellHead({ label, sort, className, tooltip }: TableCellHeadProps) {
  const [direction, setDirection] = useState<'asc' | 'desc'>('desc')

  useEffect(() => {
    //Verifica se o sort do mobx que foi alterado é o mesmo que este TableCellHead realiza o sort e seta a direção deste componente como a mesma direção alterada pelo mobx
    if (sort && Object.keys(historicoCandidatoStore.sort).indexOf(sort) !== -1) {
      setDirection(historicoCandidatoStore.sort[sort] === 'desc' ? 'asc' : 'desc')
    }
  }, [historicoCandidatoStore.sort])

  const handleSort = () => {
    if (!direction || direction === 'asc') {
      setDirection('desc')
    } else {
      setDirection('asc')
    }
    if (direction && sort) {
      historicoCandidatoStore.setSort({
        [sort]: direction
      })
    }
  }

  const renderLabel = (): JSX.Element => {
    if (sort) {
      return (
        <TableSortLabel
          IconComponent={IoIosArrowDown}
          direction={direction}
          onClick={handleSort}
        >
          {label}
        </TableSortLabel>
      )
    } else {
      return <span>{label}</span>
    }
  }

  return (
    <CustomTableCell className={className}>
      {tooltip ?
        <Tooltip title={tooltip} arrow>
          {renderLabel()}
        </Tooltip>
        :
        renderLabel()
      }
    </CustomTableCell>
  )
}
