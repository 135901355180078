import { Skeleton } from '@material-ui/lab'
import { useEffect } from 'react'
import { Redirect, useParams } from 'react-router'
import { useAppBar } from 'src/hooks'
import { Paper } from '../../components'
import { HandleErrorPage } from '../../utils/handleErrorPage'
import CandidatosTreinamentoList from './components/CandidatosTreinamentoList'
import { TreinamentoCard } from './components/TreinamentoCard'
import { useTreinamento } from './hooks/useTreinamento'

const Treinamento = () => {
  const params = useParams<{ treinamentoId: string }>()

  if (!params.treinamentoId) {
    return <Redirect to="/" />
  }

  return <TreinamentoInfo id={params.treinamentoId} />
}

type TreinamentoProps = {
  id: string
}

const TreinamentoInfo = ({ id }: TreinamentoProps) => {
  const [state, actions] = useTreinamento()
  const [, appBarActions] = useAppBar()
  
  useEffect(() => {
    if(state.tag === 'with-data') {
      appBarActions.replacePaths([
        {
          label: `Treinamentos`,
          path: `/treinamentos`
        },
      ],
      state.entity.nome
      )
    } else {
      appBarActions.replacePaths([], id)
    }
  }, [state])

  useEffect(() => {
    async function fetchData() {
      await actions.fetch(id)
    }
    fetchData()
  }, [id, actions])

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      actions.reset()
    }
  }, [actions])

  switch (state.tag) {
    case 'initial':
      return (
        <Paper>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Paper>
      )
    case 'empty-response':
      return (
        <Paper>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Paper>
      )
    case 'error':
      return <HandleErrorPage state={state.data} />
    case 'with-data':
      return (
        <>
          {state.entity && (
            <>
              <TreinamentoCard {...state.entity} />
              <CandidatosTreinamentoList treinamentoId={state.entity.id} />
            </>
          )}
        </>
      )
  }
}

export default Treinamento
