import { apiSauceInstance } from '../../../services/api'
import { Curriculo } from '../types'

export const SaveCurriculosApi = async (data: Curriculo) => {
  const response = await apiSauceInstance.post<any>('/curriculos', data)

  const { data: info, ok, status } = response

  return {
    info,
    ok,
    status
  }
}

export const getVagas = async (empresa: string) => {
  const response = await apiSauceInstance.get<any>(`/vagas/external`, {
    empresa: empresa
  })

  const { data: info, ok, status } = response

  return {
    info,
    ok,
    status
  }
}
