import LinkMD from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Link } from 'react-router-dom'
import { CpfBloqueadoIcon } from 'src/components/Icons/CpfBloqueadoIcon'
import {
  NoResultsTable,
  Paper,
  TableContainer,
  When
} from '../../../components'
import Spacer from '../../../components/Spacer'
import { TableCellHead } from '../../../components/TableCellHead'
import { FormatedDate } from '../../../utils'
import { MotoristaDetail } from '../motorista/types'
import { CandidatoDetail } from '../types'

interface ContratadoCandidatoTableProps {
  list: { node: MotoristaDetail | CandidatoDetail }[]
  loading: boolean
  totalCount: number
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const ContratadoCandidatoTable = ({
  list,
  loading,
  totalCount,
  authorization
}: ContratadoCandidatoTableProps) => {
  return (
    <>
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Nome Completo" sort="nomeCompleto" />
                  <TableCellHead label="CPF" sort="cpf" />
                  <TableCellHead label="Celular" sort="celular" />
                  <TableCellHead label="Cidade" sort="cidade" />
                  <TableCellHead label="UF" sort="uf" />
                  <TableCellHead
                    label="Data Contratacao"
                    sort="dataContratacao"
                  />
                  <TableCellHead label="Coordenador" sort="supervisorDireto" />
                  <When
                    value={!authorization || authorization.canCreate}
                    equals
                  >
                    <TableCell></TableCell>
                  </When>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(candidato => {
                  return (
                    <TableRow hover key={candidato.node.id}>
                      <TableCell>
                        {!authorization || authorization.canUpdate ? (
                          <When
                            value={!authorization || authorization.canUpdate}
                            equals
                          >
                            <LinkMD
                              component={Link}
                              to={`candidato/${candidato.node.id}`}
                            >
                              {candidato.node.nomeCompleto}
                            </LinkMD>
                          </When>
                        ) : (
                          candidato.node.nomeCompleto
                        )}
                      </TableCell>
                      <TableCell>{candidato.node.cpf}</TableCell>
                      <TableCell>{candidato.node.celular}</TableCell>
                      <TableCell>{candidato.node.cidade}</TableCell>
                      <TableCell>{candidato.node.uf}</TableCell>
                      <TableCell>
                        {FormatedDate(candidato.node.dataContratacao)}
                      </TableCell>
                      <TableCell>{candidato.node.supervisorDireto}</TableCell>
                      <When value={authorization?.canCreate} equals>
                        <TableCell>
                          {candidato.node.idListaBloqueio && (
                            <CpfBloqueadoIcon
                              idListaBloqueio={candidato.node.idListaBloqueio}
                            />
                          )}
                        </TableCell>
                      </When>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
    </>
  )
}
