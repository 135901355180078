import { Button, Grid, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { useFormikContext } from 'formik'
import TextInput from 'src/components/Formik/Forms/TextInput'
import { makeStyles } from '@material-ui/styles'

interface IDocumentosDetail {
  descricao: string
  URL: string
}

const useStyles = makeStyles({
  documentosContainer: {
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'start'
  },
  addButtonPadding: {
    marginTop: 14
  }
})

export default function DescricaoInput() {
  const { values, setFieldValue } =
    useFormikContext<{ Documentos: IDocumentosDetail[] }>()

  const classes = useStyles()

  const handleAddDocumentos = () => {
    setFieldValue('Documentos', [
      ...values.Documentos,
      {
        descricao: '',
        URL: ''
      }
    ])
  }

  const handleRemoveDocumentos = (index: number) => {
    const documentos = [...values.Documentos]
    if (documentos.length < 2) {
      return
    }
    documentos.splice(index, 1)
    setFieldValue('Documentos', [...documentos])
  }

  return (
    <>
      <Grid container spacing={2}>
        {values.Documentos.map((doc, index) => (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={values.Documentos.length > 1 ? 10 : 10}
            >
              <TextInput
                name={`Documentos.${index}.descricao`}
                label={`${index + 1}° Anexo`}
                placeholder="Sequência de documentos a serem enviados."
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Tooltip
              placement="top"
              title={
                !!!values.Documentos[index].URL ? 'Pendente' : 'Fazer Download'
              }
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={values.Documentos.length > 1 ? 1 : 2}
              >
                <Button
                  style={{ height: 56 }}
                  fullWidth
                  disabled={!!!values.Documentos[index].URL}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    window.open(values.Documentos[index].URL)
                  }}
                >
                  <DownloadIcon />
                </Button>
              </Grid>
            </Tooltip>

            {values.Documentos.length > 1 && (
              <Tooltip placement="top" title={`Deletar ${index + 1}° Anexo`}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={values.Documentos.length > 1 ? 1 : 1}
                >
                  <Button
                    style={{ height: 56 }}
                    variant="outlined"
                    fullWidth
                    color="primary"
                    onClick={() => handleRemoveDocumentos(index)}
                  >
                    <DeleteOutlineIcon />
                  </Button>
                </Grid>
              </Tooltip>
            )}
          </>
        ))}
      </Grid>
      <div className={classes.addButtonPadding}>
        <Tooltip
          placement="right-start"
          title="Adicionar anexo"
        >
          <Button
            variant="outlined"
            color="primary"
            type="button"
            onClick={handleAddDocumentos}
          >
            <AddIcon />
          </Button>
        </Tooltip>
      </div>
    </>
  )
}
