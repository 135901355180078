import { useTreinamento } from '../hooks/useTreinamento'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { TreinamentosForm } from './TreinamentosForm'

export function TreinamentosMasterDetail() {
  const [treinamentoState, treinamentoActions] = useTreinamento()
  const [, appBarActions] = useAppBar()

  treinamentoState &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useReplaceFragments(appBarActions.replaceFragment, [
      mapFragmentReplace(
        treinamentoState,
        u => u.entity.id,
        u => u.entity.nome
      )
    ])
  useFetchAndReset(treinamentoActions.fetch, treinamentoActions.reset)
  return handleStateErrorsToRender(treinamentoState, <TreinamentosForm />)
}
