import { Skeleton } from '@material-ui/lab'
import { useEffect } from 'react'
import { Redirect, useHistory, useParams } from 'react-router'
import { useAppBar } from 'src/hooks'
import { Paper } from '../../components'
import { HandleErrorPage } from '../../utils/handleErrorPage'
import { useLoggedUser } from '../login/AuthProvider'
import { CandidatoCard } from './components/CandidatoCard'
import ProcessoAdministrativoList from './components/ProcessoAdministrativoList'
import { useCandidato } from './hooks/useCandidato'

const Candidato = () => {
  const params = useParams<{ id: string }>()

  if (!params.id) {
    return <Redirect to="/" />
  }

  return <CandidatoInfo id={params.id} />
}

type CandidatoProps = {
  id: string
}

const CandidatoInfo = ({ id }: CandidatoProps) => {
  const [state, actions] = useCandidato()
  const [, appBarActions] = useAppBar()
  const history = useHistory()
  const userLogged = useLoggedUser()

  useEffect(() => {
    if (state.tag === 'with-data') {
      if(userLogged?.empresa !== state.entity.empresaFK?.sigla) {
        history.push('/')
      }

      appBarActions.replacePaths(
        [
          {
            label: `${
              state.entity.tipo[0].toUpperCase() +
              state.entity.tipo.slice(1).toLowerCase()
            }s`,
            path: `/${state.entity.tipo.toLowerCase()}`
          }
        ],
        state.entity.nomeCompleto
      )
    } else {
      appBarActions.replacePaths([], id)
    }
  }, [state])

  useEffect(() => {
    async function fetchData() {
      await actions.fetch(id)
    }
    fetchData()
  }, [id, actions])

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      actions.reset()
    }
  }, [actions])

  switch (state.tag) {
    case 'initial':
      return (
        <Paper>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Paper>
      )
    case 'empty-response':
      return (
        <Paper>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Paper>
      )
    case 'error':
      return <HandleErrorPage state={state.data} />
    case 'with-data':
      return <>{state.entity && <CandidatoCard {...state.entity} />}</>
  }
}

export default Candidato
