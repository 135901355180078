import {
  Box,
  CardContent,
  createStyles,
  IconButton, Link, makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme, Tooltip,
  Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Skeleton } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import {
  DeleteRecord,
  NoResultsTable,
  Paper,
  TableCellHead,
  TableContainer
} from '../../../../components'
import { apiSauceInstance } from '../../../../services/api'
import { FormatedDate } from '../../../../utils'
import { DocumentosCandidatoDetail } from '../../types'
import DocumentoCandidatoFile from '../DocumentoCandidatoFile'
import { AnexoCandidatoDocumentoForm } from './AnexoCandidatoDocumentoForm'

interface Props {
  list: { node: DocumentosCandidatoDetail }[]
  loading: boolean
  totalCount?: number
  candidatoId?: string
  refreshData: () => void
  onDelete: (id: string) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    candidatoDocumentosListContainer: {
      maxHeight: '198px',
      overflow: 'scroll'
    },
    cardInicial: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '30px'
    },
    titleSpacing: {
      paddingLeft: 60
    },
    dataContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: 'fill-available'
    },
    iconContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'right'
    }
  })
)

export const ProcessoSeletivoAdminDocumentosTable = ({
  list,
  loading,
  totalCount,
  candidatoId,
  onDelete,
  refreshData
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [idCandidato, setIdCandidato] = useState<string | null>(null)
  const [idDocumento, setIdDocumento] = useState<string | null>(null)
  const classes = useStyles()

  const handleAddNewDocument = () => {
    setModalOpen(true)
    if (candidatoId) {
      setIdCandidato(candidatoId)
    }
  }
  const handleOpenFormModal = (id: string) => {
    setModalOpen(true)
    setIdDocumento(id)
    if (candidatoId) {
      setIdCandidato(candidatoId)
    }
  }

  const handleCloseFormModal = () => {
    setModalOpen(false)
    setIdCandidato(null)
    setIdDocumento(null)
  }

  return (
    <Box>
      <Paper minHeight={340}>
        <CardContent>
          <div className={classes.cardInicial}>
            <div className={classes.dataContainer}>
              <Typography
                variant="h6"
                style={{ fontWeight: 'bold' }}
                gutterBottom
              >
                Documentos
              </Typography>
            </div>
            <div className={classes.iconContainer}>
              <Tooltip title="Adicionar Documentos">
                <IconButton
                  aria-label="edit"
                  onClick={handleAddNewDocument}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </CardContent>
        <TableContainer
          loading={loading}
          totalCount={0}
          disablePagination={true}
        >
          <div className={classes.candidatoDocumentosListContainer}>
            {list.length ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellHead label="Descrição do Documento" />
                    <TableCellHead label="URL do Documento" />
                    <TableCellHead label="Data de Inserção do Documento" />
                    <TableCellHead label="" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map(documento => (
                    <TableRow hover key={documento.node.id}>
                      <TableCell>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => {
                            handleOpenFormModal(documento.node.id!)
                          }}
                        >
                          {documento.node.descricao}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <DocumentoCandidatoFile id={documento.node.id} />
                      </TableCell>

                      <TableCell>
                        {FormatedDate(documento.node.createdAt)}
                      </TableCell>

                      <DeleteRecord
                        onDelete={() => onDelete(documento.node.id!)}
                      />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <NoResultsTable loading={loading} />
            )}
          </div>
        </TableContainer>
      </Paper>
      <AnexoCandidatoDocumentoForm
        idDocumento={idDocumento}
        idCandidato={idCandidato}
        open={modalOpen}
        fetchData={refreshData}
        handleClose={handleCloseFormModal}
      />
    </Box>
  )
}

type CandidatosDocumentosListProps = {
  id: string
  processoId: string
}

type APIResponseDocumentos = {
  edges: { node: DocumentosCandidatoDetail }[]
  totalCount: number
  loading: boolean
}

const DocumentoProcessoSeletivoAdminList = ({
  id,
  processoId
}: CandidatosDocumentosListProps) => {
  const [candidatosDocumentosState, setCandidatosDocumentosState] =
    useState<APIResponseDocumentos>({} as APIResponseDocumentos)

  async function onDelete(id: string) {
    await apiSauceInstance.delete(`/documentos-administrativos/${id}`)
    setCandidatosDocumentosState(prevState => ({
      ...prevState,
      edges: prevState.edges.filter(documento => documento.node.id !== id),
      totalCount: prevState.totalCount - 1
    }))
  }

  async function loadCandidatosDocumentosByProcessoId() {
    try {
      setCandidatosDocumentosState({
        ...candidatosDocumentosState,
        loading: true
      })

      const { data } = await apiSauceInstance.get<any>(
        `/documentos-administrativos`,
        {
          candidatoId: id,
          processoSeletivoAdminId: processoId,
          origemDocumento: 'GESTOR_PROCESSO_SELETIVO'
        }
      )
      if (data) {
        setCandidatosDocumentosState({
          totalCount: data.totalCount,
          edges: data.edges,
          loading: false
        })
      }
    } catch (error) { }
  }

  useEffect(() => {
    loadCandidatosDocumentosByProcessoId()
  }, [id])

  return (
    <div>
      {candidatosDocumentosState.loading === false ? (
        <ProcessoSeletivoAdminDocumentosTable
          onDelete={onDelete}
          list={candidatosDocumentosState.edges}
          totalCount={candidatosDocumentosState.totalCount}
          loading={candidatosDocumentosState.loading}
          candidatoId={id}
          refreshData={loadCandidatosDocumentosByProcessoId}
        />
      ) : (
        <Paper minHeight={340}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Paper>
      )}
    </div>
  )
}

export default DocumentoProcessoSeletivoAdminList
