export enum MessagesYup {
  MESSAGE_ACESSOU_ROTA_SEM_CANDIDATOID = 'A página em questão não podê ser acessada. tente acessar através dos candidatos',
  MENSAGEM_OBRIGATORIO = 'O campo em específico é obrigatório',
  MENSAGEM_CAMPO_STRING = 'O campo em específico não aceita esses parâmetros em específico.',
  MENSAGEM_TIPO_NUMERICO = 'O campo em específico precisa ser numérico',
  MENSAGEM_VALOR_MINIMO = 'O valor mínimo para este campo não pode ser negativo',
  MENSAGEM_VALOR_NEGATIVO = 'O valor mínimo não pode ser negativo',
  MENSAGEM_EMAIL = 'Formato inválido de e-mail',
  MENSAGEM_ERRO = 'O campo em específico é uma string, algo deu errado.',
  MENSAGEM_VALOR_ZERO = 'O valor mínimo para este campo deve ser maior que zero.',
  MENSAGEM_LIMTADA_ATE = 'O campo em específico contém limite de caracteres.',
  MENSAGEM_VALOR_MAXIMO_EXERCICIO = 'O valor máximo para este campo deve ser menor que 9999.',
  MENSAGEM_VALOR_MINIMO_EXERCICIO = 'O valor mínimo para este campo deve ser maior que 1900.',
  MESSAGEM_VALOR_CELULAR_MENOR = 'O valor mínimo para este campo deve ser maior que os 11 dígitos',
  MESSAGEM_VALOR_CPF_INVALIDO = 'O CPF possui um valor inválido.',
  MESSAGEM_VALOR_RG_INVALIDO = 'O RG Possui um valor inválido.',
  MENSAGEM_CPF_LIMTADA = 'O campo CPF em específico contém limite de 11 caracteres.',
  MENSAGEM_RG_LIMTADA = 'O campo RG em específico contém uma quantidade inválida de caracteres.',
  MESSAGEM_SOBRE_PROCESSO_BLOQUEADO = 'Para este processo em específico não atende os critérios.',
  MENSAGEM_CIDADE_INVALIDA = 'Selecione uma cidade válida.',
  MENSAGEM_UF_INVALIDA = 'Selecione uma cidade válida.',
  MENSAGEM_HORA_INVALIDA = 'A hora informada é inválida.',
  MENSAGEM_DATA_INVALIDA = 'A data informada é inválida.',
  MENSAGEM_DATA_MAX_ATUAL = 'A data informada é posterior a data atual.',
  MENSAGEM_DATA_MIN_ATUAL = 'A data informada é anterior a data atual.',
  MENSAGEM_DATA_SAIDA_MAIOR_DATA_ENTRADA = 'A data de saída não pode ser menor que a data de entrada.',
  MENSAGEM_INDICACAO = 'Caso seja indicação preencher todos os campos de indicação.',
  MENSAGEM_MAX_LENGTH = 'O campo pode conter no máximo 20 caracteres'
}
