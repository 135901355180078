import { GridDetail } from '../grids/types'
import { AdmissaoCandidatoDetail } from './admissao-candidato/types'
import { AgendamentosCandidatoDetail } from './agendamento-candidato/types'

export interface Curso {
  id: string
  descricao: string
  cargaHoraria: number
  dataConclusao: string
  dataInicio?: string
}

export interface ExperienciaProfissional {
  empresa?: string
  cargoFuncao?: string
  uf?: string
  cidade?: string
  telefone?: string
  atualmenteEmpregado: boolean
  tipoCaminhao?: string
  tempoExperienciaCaminhao?: number
  principaisAtividades?: string
  dataEntrada?: Date
  dataSaida?: Date
}

export interface InfoFile {
  file?: File
  name: string
  path: string
}

export interface SaveInfoFile {
  file?: File
  name: string
  candidatoId?: string
}

export interface CandidatoFormData {
  id: string
  nomeCompleto: string
  cpf?: string
  email?: string
  dataNascimento?: string
  cidade?: string
  uf?: string
  telefone?: string
  celular?: string
  tipo: string
  empresaId: string
  origemCurriculo?: string
  comentario?: string
  notaClassificacao: number
  anexoCurriculo?: InfoFile
  anexoCNH?: InfoFile
}

type StatusTriagem = 'APROVADO' | 'REPROVADO'

export interface CandidatoDetail {
  id: string
  nomeCompleto: string
  cpf?: string
  email?: string
  dataNascimento?: string
  cidade?: string
  uf?: string
  telefone?: string
  celular?: string
  empresaId: string
  tipo: string
  comentario?: string
  idListaBloqueio?: string
  motivoListaBloqueio?: string
  candidatouVaga: boolean
  exFuncionario: boolean
  atualFuncionario: boolean
  origemCurriculo?: string
  notaClassificacao: number
  statusTriagem?: StatusTriagem
  quantidadeCandidaturas: number
  dataContratacao?: string
  supervisorDireto?: string
  contagemAgendamentos: number
  Cursos: Curso[]
  ExperienciaProfissional: ExperienciaProfissional[]
  Triagem?: TriagemDetail[]
  AgendamentoCandidato?: AgendamentosCandidatoDetail[]
  SelecaoCandidato?: SelecaoCandidatoDetail[]
  AdmissaoCandidato?: AdmissaoCandidatoDetail[]
  empresaFK?: {
    sigla: string
  }
  createdAt?: string
}

export interface SaveCandidatoInput {
  id: string
  nomeCompleto: string
  cpf?: string
  email?: string
  dataNascimento?: string
  cidade?: string
  uf?: string
  telefone?: string
  celular?: string
  tipo: string
  empresaId: string
  comentario?: string
}

export const tipoReprovacao = [
  {
    value: 'REPROVAR_VAGA',
    label: 'Reprovar para essa vaga'
  },
  {
    value: 'REPROVAR_PERMANENTE',
    label: 'Reprovar permanentemente'
  }
]

type TipoReprovacao = 'REPROVAR_VAGA' | 'REPROVAR_PERMANENTE'

type StatusProcessoAdministrativo =
  | 'AGENDAMENTO'
  | 'ENTREVISTA'
  | 'CONCLUSAO'
  | 'REPROVADO'

export const statusProcessoAdministrativo = [
  {
    value: 'AGENDAMENTO',
    label: 'Agendamento'
  },
  {
    value: 'ENTREVISTA',
    label: 'Entrevista'
  },
  {
    value: 'REPROVADO',
    label: 'Reprovado'
  },
  {
    value: 'CONCLUSAO',
    label: 'Concluído'
  }
]

export interface ProcessoSeletivoAdministrativoFormData {
  id: string
  candidatoId: string
  status: StatusProcessoAdministrativo
  dataEncerramento: string
  comentario: string
  createdAt: string
  aderencia: number
  observacaoConclusao?: string
  criadoPor?: string
  candidatoFK: {
    nomeCompleto: string
  }
  vagasFK?: {
    cargoFk: {
      nome: string
    }
  }
}

export interface ProcessoSeletivoAdministrativoDetail {
  id: string
  candidatoId: string
  status: StatusProcessoAdministrativo
  dataEncerramento: string
  createdAt: string
  comentario: string
  aderencia: number
  tipoReprovacao?: TipoReprovacao
  motivoReprovacao?: string
  observacaoConclusao?: string
  criadoPor?: string
  candidatoFK?: {
    nomeCompleto: string
  }
  vagasFK?: {
    cargoFk: {
      nome: string
    }
  }
  empresaFK?: {
    sigla: string
  }
  empresaId?: string
  vagasId?: string
}

export interface SaveProcessoSeletivoAdministrativoInput
  extends ProcessoSeletivoAdministrativoDetail { }

export interface SubmitValues {
  createdAt: string
  dataEncerramentoPs?: string
}
export interface TriagemDetail {
  id?: string
  candidatoId: string
  triadoPor: string
  atendeCriterios: boolean
  comentario?: string
  motivo?: string
  gridId?: string
  gridFK?: GridDetail
  createdAt?: string
}

export interface SaveTriagemInput {
  id: string
  candidatoId: string
  triadoPor: string
  atendeCriterios: boolean
  comentario?: string
}

export interface LabelCaracteristicalDetail {
  id: string
  descricao: string
  empresaId: string
}

export interface LabelCandidatoDetail {
  id: string
  candidatoId: string
  labelCaracteristicaId: string
  labelCaracteristicaFK?: {
    descricao: string
  }
}

export interface LabelsCandidatoFormData {
  candidatoId: string
  caracteristicasIds: string[]
}

export interface CargoCandidatoDetail {
  id?: string
  cargoId: string
  aderencia: number
  cargoFK?: {
    nome: string
  }
}

export interface CargoCandidatoFormData {
  candidatoId: string
  CargosCandidatos: CargoCandidatoDetail[]
}

export interface CandidatoTreinamentoDetail {
  id: string
  nota: string
  comentario: string
  candidatoId: string
  candidatoFK?: {
    nomeCompleto: string
  }
  treinamentoFK?: {
    nome: string
  }
  treinamentoId: string
  empresaId: string
  createdAt: string
}

export interface DocumentosCandidatoDetail {
  id?: string
  candidatoId?: string
  descricao: string
  URL?: string
  mimeType?: string
  originalName?: string
  createdAt?: string
  updatedAt?: string
  empresaId?: string
}

export interface SaveDocumentoCandidatoInput {
  id?: string
  candidatoId?: string
  descricao: string
  file?: File
  siglaEmpresa?: 'G10' | 'TP'
}

export const notasClassificacao = [
  {
    value: 1,
    label: '1'
  },
  {
    value: 2,
    label: '2'
  },
  {
    value: 3,
    label: '3'
  }
]

export type TipoContratacao = 'DIRETA' | 'CURSO_FORMACAO'

export interface SelecaoCandidatoDetail {
  id: string
  candidatoId: string
  dataSelecao: string
  tipoContratacao: TipoContratacao
  compareceu?: boolean
  parecerRh?: string
  parecerGestor?: string
  createdAt: string
  empresaId?: string
  subEmpresaId?: string
  selecionadoPor?: string
  status?: string

  testePratico?: boolean
  dataTestePratico?: Date
  observacaoTestePratico?: string

  testePsicologico?: boolean
  dataTestePsicologico?: Date
  observacaoTestePsicologico?: string

  testeTecnico?: boolean
  dataTesteTecnico?: Date
  observacaoTesteTecnico?: string

  entrevistaRh?: boolean
  dataEntrevistaRh?: Date
  observacaoEntrevistaRh?: string

  entrevistaGestor?: boolean
  dataEntrevistaGestor?: Date
  observacaoEntrevistaGestor?: string
}

export const statusSelecao = [
  { value: 'APROVADO', label: 'Aprovado' },
  { value: 'REPROVADO', label: 'Reprovado' },
  { value: 'REAGENDADO', label: 'Reagendado' },
  { value: 'DESISTIU', label: 'Desistiu' },
  { value: 'PENDENTE', label: 'Pendente' }
]

export const statusAdmissao = [
  { value: 'APROVADO', label: 'Aprovado' },
  { value: 'DESISTIU', label: 'Desistiu' },
  { value: 'PENDENTE', label: 'Pendente' }
]

export const tipoTurnos = [
  {
    label: 'Manhã',
    value: 'MANHA'
  },
  {
    label: 'Tarde',
    value: 'TARDE'
  },
  {
    label: 'Noite',
    value: 'NOITE'
  }
]

export type TipoHistorico =
  | 'TRIAGEM'
  | 'AGENDAMENTO'
  | 'SELECAO'
  | 'ADMISSAO';

export interface HistoricoCandidatoDetail {
  id: string;
  createdAt: string;
  type: TipoHistorico;
  tipoContratacao?: string;
  usuario?: string;
  comentarioRH?: string;
}