import Grid from '@material-ui/core/Grid'
import { ReactNode } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

import { createStyles, makeStyles, styled, Theme } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2)
      }
    },
    gridItem: {
      [theme.breakpoints.up('sm')]: {
        flexGrow: 0
      },
    }
  })
)

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '160px'
  }
}));

export interface CustomAction {
  label: string
  classes: string
  onClick: () => void
  disabled?: boolean
  position?: 'left' | 'right'
}

export const FormWrap = ({
  children,
  handleSubmit,
  nameButton,
  disableSubmit = false,
  disableBack = false,
  loading = false,
  hideConfirmButton = false,
  hideGoBack = false,
  customActions = [],
  handleGoBack = undefined
}: {
  children: ReactNode
  handleSubmit: any
  disableBack?: boolean
  loading?: boolean
  disableSubmit?: boolean
  hideConfirmButton?: boolean
  hideGoBack?: boolean
  nameButton?: string
  customActions?: CustomAction[]
  handleGoBack?: () => void
}) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  function defaultHandleGoBack() {
    const newLocation = location.pathname
      .split('/')
      .filter(Boolean)
      .slice(0, -1)
      .join('/')

    history.push(`/${newLocation}`)
  }

  const hasLeftButtons = customActions.find(action => action.position === 'left') !== undefined

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2} direction="column">
        <Grid item>{children}</Grid>

        <Grid item container xs={12} spacing={2}>
          {hasLeftButtons && (
            <Grid item xs={12} sm={6}>
              <Grid justify="flex-start" container spacing={2}>
                {customActions.filter(action => action.position === 'left').map(action => (
                  <Grid item xs={12} md sm>
                    <StyledButton
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={action.onClick}
                      disabled={action.disabled}
                      className={action.classes}
                    >
                      {action.label}
                    </StyledButton>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sm={hasLeftButtons ? 6 : 12}>
            <Grid justify="flex-end" container spacing={2}>
              {!hideGoBack && (
                <Grid item xs={12} md sm className={classes.gridItem}>
                  <StyledButton
                    fullWidth
                    type="button"
                    variant="outlined"
                    onClick={handleGoBack ?? defaultHandleGoBack}
                    disabled={disableBack}
                  >
                    Voltar
                  </StyledButton>
                </Grid>
              )}
              {customActions.filter(action => action.position !== 'left').map(action => (
                <Grid item xs={12} md sm className={classes.gridItem}>
                  <StyledButton
                    fullWidth
                    type="button"
                    variant="outlined"
                    onClick={action.onClick}
                    disabled={action.disabled}
                    className={action.classes}
                  >
                    {action.label}
                  </StyledButton>
                </Grid>
              ))}
              {!hideConfirmButton && (
                <Grid item xs={12} md sm className={classes.gridItem}>
                  {loading ? (
                    <StyledButton
                      data-testid="salvar"
                      fullWidth
                      disabled={true}
                      variant="contained"
                      color="primary"
                    >
                      <div className={classes.root}>
                        <CircularProgress color="inherit" size={24} />
                      </div>
                    </StyledButton>
                  ) : (
                    <StyledButton
                      data-testid="salvar"
                      fullWidth
                      type="submit"
                      disabled={disableSubmit}
                      variant="contained"
                      color="primary"
                    >
                      {nameButton ?? 'Salvar'}
                    </StyledButton>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
