import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Paper } from 'src/components'
import { CardButton } from '../../components/Cards/CardButton'
import { CurriculosBackground } from '../../components/CurriculosBackground'
import tpBackground from '../../images/tp-background.jpg'
import tpLogo from '../../images/tp-logo.png'

const useStyles = makeStyles(theme => ({
  gridPaper: {
    minHeight: '100vh',
    padding: '20px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 20px'
    }
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px'
  },
  imgLogo: {
    width: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%'
    }
  }
}))

export const TrabalheConoscoTP = () => {
  const classes = useStyles()

  return (
    <CurriculosBackground backgroundImage={tpBackground}>
      <Grid
        container
        className={classes.gridPaper}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <div className={classes.logoContainer}>
            <img src={tpLogo} className={classes.imgLogo} />
          </div>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} style={{ padding: '20px' }}>
                <Typography variant="h4" color="primary">
                  <b>Trabalhe Conosco</b>
                </Typography>
                <Typography variant="subtitle1">
                  Faça parte do nosso crescimento
                </Typography>
                <br />
                <Typography variant="subtitle1" align="justify">
                  A Transpanorama está entre as 10 Melhores e Maiores
                  transportadoras do Brasil, sendo a segunda empresa do país a
                  obter a ISO 39001, que trata sobre segurança viária e está
                  ligada aos objetivos da ONU, para diminuição de mortes e
                  acidentes de trânsito. Sempre inovando em suas atividades é
                  uma empresa pautada em transparência, eficiência e ética em
                  suas relações com seus colaboradores e clientes.
                </Typography>
                <br />
                <Typography variant="subtitle1" align="justify">
                  Entre as 10 Melhores e Maiores transportadoras do Brasil
                </Typography>
                <br />
                <Typography variant="subtitle1" align="justify">
                  Possui o Programa Compliance, com o Código de Ética e Conduta,
                  que serve como base paras as diretrizes da Transpanorama,
                  auxiliando os colaboradores na escolha de sua conduta.
                </Typography>
                <br />
                <Typography variant="subtitle1" align="justify">
                  A empresa também tem uma conduta de Recursos Humanos
                  consolidada.
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={6}
                spacing={2}
                style={{ alignContent: 'center' }}
              >
                <Grid item container xs={12}>
                  <CardButton
                    title="Administrativo"
                    tipo="administrativo"
                    empresa="Transpanorama"
                  />
                </Grid>
                <Grid item container xs={12}>
                  <CardButton
                    title="Motorista"
                    tipo="motorista"
                    empresa="Transpanorama"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </CurriculosBackground>
  )
}
