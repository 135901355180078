import { EdgesPage } from '@elentari/core/types'
import { observer } from 'mobx-react-lite'
import QueryString from 'qs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AdministrativoDetail } from 'src/modules/candidato/administrativo/types'
import { useAbility } from 'src/modules/login'
import { apiSauceInstance } from 'src/services/api'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import triagemStore from '../store/store'
import { AdministrativoFilter } from './AdministrativoFilter'
import { TriagemAdministrativoTable } from './TriagemAdministrativoTable'

const TriagemAdministrativoList = (props: {
  list: AdministrativoDetail[]
  loading: boolean
  totalCount: number
  onDelete: (id: string) => void
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}) => {
  return (
    <>
      <AdministrativoFilter />
      <TriagemAdministrativoTable {...props} />
    </>
  )
}

type APIResponseCandidatos = {
  edges: { node: AdministrativoDetail }[]
  totalCount: number
  loading: boolean
}

const TriagemAdministrativo = observer(() => {
  const [candidatosState, setCandidatosState] = useState<APIResponseCandidatos>(
    {} as APIResponseCandidatos
  )
  const { search, pathname } = useLocation()
  const ability = useAbility()

  const authorization = getAuthorizations(ability, 'triagemAdministrativos')

  useEffect(() => {
    triagemStore.resetCandidatosId()
  }, [])

  useEffect(() => {
    async function loadCandidatosAdministrativos() {
      try {
        setCandidatosState({
          ...candidatosState,
          loading: true
        })

        const params: { [key: string]: any } = {
          sort: { createdAt: 'desc' },
          incluirBloqueados: 'false',
          ...QueryString.parse(search.substring(1))
        }

        const { data } = await apiSauceInstance.get<
          EdgesPage<AdministrativoDetail>
        >(`/candidato?${QueryString.stringify(params)}`, {
          tipo: 'ADMINISTRATIVO',
          statusCandidato: 'INICIAL',
          skip: params.page ? (+params.page - 1) * 10 : 0,
          limit: 10
        })
        if (data) {
          setCandidatosState({
            totalCount: data.totalCount,
            edges: data.edges,
            loading: false
          })
        }
      } catch (error) { }
    }
    loadCandidatosAdministrativos()
  }, [pathname, search])

  return (
    <>
      {candidatosState.loading === false ? (
        <TriagemAdministrativoList
          totalCount={candidatosState.totalCount}
          list={candidatosState.edges.map(candidato => candidato.node)}
          loading={candidatosState.loading}
          authorization={authorization}
          onDelete={() => { }}
        />
      ) : (
        <TriagemAdministrativoList
          totalCount={0}
          list={[]}
          loading={candidatosState.loading}
          authorization={authorization}
          onDelete={() => { }}
        />
      )}
    </>
  )
})

export default TriagemAdministrativo