import { InputAdornment, TextField } from '@material-ui/core'
import { useField } from 'formik'
import { ClipboardButton } from 'src/modules/conclusao-administrativo/components/ClipboardButton'

interface TextInputProps {
  name: string
  noIcon?: boolean
  value?: string
}

function CopyInput({ noIcon, ...props }: TextInputProps) {
  const [field, meta] = useField(props)

  return (
    <TextField
      fullWidth
      variant="outlined"
      {...field}
      {...props}
      disabled
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ClipboardButton link={props.value || ''} />
          </InputAdornment>
        )
      }}
    />
  )
}

export default CopyInput
