import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from "@elentari/components-eve/final-form/TextField"
import { useSnackbar } from "@elentari/core"
import { FetchState } from "@elentari/core/types"
import { Button, CircularProgress, createStyles, Divider, Grid, makeStyles, Tooltip } from "@material-ui/core"
import EventIcon from '@material-ui/icons/Event'
import { useState } from "react"
import { Form } from "react-final-form"
import { useHistory, useLocation } from "react-router-dom"
import { Paper } from "src/components"
import RichTextField from "src/components/Forms/RichTextField"
import TimeField from "src/components/Forms/TimeField"
import { NavigationButton } from "src/components/NavigationButton"
import Spacer from "src/components/Spacer"
import { useAppBar } from 'src/hooks'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import { MessagesYup } from 'src/modules/messages'
import { etapaProcesso, isG10, tipoAgendamento, tipoContratacao, tipoEntrevista, tipoEntrevistaG10, tipoModalidade } from "src/utils"
import { combineDateWithTime } from "src/utils/formatters/formatDate"
import yupValidation from 'src/utils/yupValidation'
import * as yup from 'yup'
import { useAdministrativo } from "../../administrativo/hooks/useAdministrativo"
import { CandidatoCardContent } from "../../components/CandidatoCardContent"
import HistoricoCandidato from "../../components/HistoricoCandidato"
import { ReturnButton } from "../../components/ReturnButton"
import { useMotorista } from "../../motorista/hooks/useMotorista"
import { MotoristaDetail } from "../../motorista/types"
import { CandidatoDetail } from "../../types"
import { getNewestAgendamento } from "../../utils"
import { AgendamentosButtons } from "../components/AgendamentosButtons"
import { updateAgendamento } from "../hooks/agendamentoCandidatoRepository"
import agendamentoStore from "../store/store"

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '160px'
      }
    },
    divider: {
      marginTop: 30,
      marginBottom: 30
    },
    marginBottom: {
      marginBottom: 20
    },
    containerLocation: {
      display: 'flex',
      flexDirection: 'row'
    },
    dataContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: 'fill-available',
      padding: '50px 10px'
    },
    iconContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'right'
    },
    fullHeight: {
      height: '100%'
    }
  })
)

const AgendamentoCandidatoForm = ({
  state
}: {
  state: FetchState<CandidatoDetail | MotoristaDetail>
}) => {
  const [showHistorico, setShowHistorico] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const userLogged = useLoggedUser()
  const userEmpresaIsG10 = isG10(userLogged?.empresa)

  const initialValues = {
    comentario: '',
    agendadorPor: '',
    dataAgendamento: '',
    horaAgendamento: '',
    dataMotoristaAgendado: '',
    tipoEntrevista: '',
    tipoContratacao: '',
    comentarioRH: '',
    modalidade: '',
    etapa: ''
  }

  const schemaObject = {
    agendadoPor: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    createdAt: yup
      .date()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
    tipoContratacao: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    dataAgendamento: yup
      .date()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
    horaAgendamento: yup
      .date()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_HORA_INVALIDA),
    tipoEntrevista: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    tipoAgendamento: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    comentarioRH: yup
      .string()
      .nullable()
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    modalidade: yup
      .string()
      .nullable()
      .typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
    etapa: yup
      .string()
      .nullable()
      .typeError(MessagesYup.MENSAGEM_CAMPO_STRING)
  }
  const schema = yup.object().shape(schemaObject)

  const handleSubmit = async (data: any) => {
    setLoading(true)
    try {
      const response = await updateAgendamento(data.id, {
        agendadoPor: data.agendadoPor,
        comentarioRH: data.comentarioRH,
        dataAgendamento: combineDateWithTime(new Date(data.dataAgendamento), new Date(data.horaAgendamento)),
        tipoEntrevista: data.tipoEntrevista,
        tipoContratacao: data.tipoContratacao,
        tipoAgendamento: data.tipoAgendamento,
        etapa: data.etapa,
        modalidade: data.modalidade,
        candidatoId: data.candidatoId,
      })

      if (response?.ok) {
        snackbarActions.setMessage('Salvo com sucesso!')
      } else {
        snackbarActions.setMessage('Ocorreu um erro ao salvar.')
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
    setLoading(false)
  }

  const scrollTop = () => {
    const mainContainer = document.querySelector('main')
    if (mainContainer) {
      mainContainer.scrollTo({
        top: 0
      })
    }
  }

  const handleNextCandidato = () => {
    const nextCandidato = agendamentoStore.nextAgendamentoId()
    if (nextCandidato) {
      history.push(`/${location.pathname.split('/')[1]}/${nextCandidato}`)
      scrollTop()
    } else {
      snackbarActions.setMessage('Não há mais candidatos na lista.')
    }
  }

  const handlePrevious = () => {
    agendamentoStore.previousAgendamentoId()
    history.goBack()
    scrollTop()
  }

  const format = (candidato: CandidatoDetail) => {
    const agendamentoCandidato = getNewestAgendamento(candidato.AgendamentoCandidato ?? [])
    return {
      ...agendamentoCandidato,
      horaAgendamento: agendamentoCandidato.dataAgendamento
    }
  }

  return (
    <>
      {state.tag === 'with-data' && (
        <Paper>
          <CandidatoCardContent
            candidato={state.entity}
            showIcons={true}
          />
        </Paper>
      )}

      <Spacer y={2} />

      <Form
        validate={yupValidation(schema)}
        onSubmit={handleSubmit}
        initialValues={
          state.tag === 'with-data'
            ?
            format(state.entity) : initialValues}
      >
        {({ handleSubmit, submitting, values }) => (
          <>
            <Paper>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    name="agendadoPor"
                    label="Agendado por"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DatePicker
                    fullWidth
                    name="createdAt"
                    label={userEmpresaIsG10 ? "Data do Agendamento" : "Data em que o Agendamento aconteceu"}
                    keyboardIcon={
                      <Tooltip title="Selecionar data">
                        <EventIcon />
                      </Tooltip>
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <Select
                    fullWidth
                    name="tipoContratacao"
                    label="Tipo da Contratação"
                    items={tipoContratacao}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <Select
                    fullWidth
                    name="tipoEntrevista"
                    label="Tipo da Entrevista"
                    items={userEmpresaIsG10 ? tipoEntrevistaG10 : tipoEntrevista}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <DatePicker
                    fullWidth
                    name="dataAgendamento"
                    label={userEmpresaIsG10 ? "Data do Processo" : "Data para o qual foi Agendado"}
                    keyboardIcon={
                      <Tooltip title="Selecionar data">
                        <EventIcon />
                      </Tooltip>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TimeField
                    label={userEmpresaIsG10 ? "Horário do Processo" : "Hora para o qual foi agendado"}
                    name="horaAgendamento"
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Select
                    fullWidth
                    name="tipoAgendamento"
                    label="Tipo de Agendamento"
                    items={tipoAgendamento}
                  />
                </Grid>
                {userEmpresaIsG10 && (
                  <>
                    <Grid item xs={12} md={6} lg={6}>
                      <Select
                        fullWidth
                        name="modalidade"
                        label="Modalidade"
                        items={tipoModalidade}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Select
                        fullWidth
                        name="etapa"
                        label="Etapa"
                        items={etapaProcesso}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <RichTextField
                    name="comentarioRH"
                    label="Comentário RH"
                  />
                </Grid>
              </Grid>
            </Paper>

            <Spacer y={2} />

            <Paper>
              <Divider className={classes.divider} />

              <AgendamentosButtons
                candidato={state.tag === 'with-data' ? state.entity : undefined}
                candidatoBloqueio={false}
                agendamentoTipoContratacao={values.tipoContratacao}
                agendamentoDataAgendamento={values.dataAgendamento}
                historicoState={{ state: showHistorico, action: setShowHistorico }}
              />

              <Divider className={classes.divider} />

              <Grid item container spacing={2} className={classes.marginBottom}>
                <Grid item xs={12} sm={4}>
                  <NavigationButton
                    label="Anterior"
                    direction="left"
                    disabled={submitting}
                    onClick={() => handlePrevious()}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container className={classes.fullHeight} justifyContent='center' alignContent='center'>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      disabled={submitting}
                      onClick={() => handleSubmit()}
                      type="submit"
                    >
                      {submitting ? (
                        <CircularProgress color="inherit" size={24} />
                      ) : (
                        <>Salvar</>
                      )}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container justifyContent='flex-end' spacing={2}>
                    <Grid item xs={12}>
                      <Grid container justifyContent='flex-end'>
                        <NavigationButton
                          label="Próximo"
                          direction="right"
                          disabled={submitting}
                          onClick={() => handleNextCandidato()}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <ReturnButton disableButtons={submitting} />
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
      </Form >
      <HistoricoCandidato
        open={showHistorico}
        candidatoId={state.tag === 'with-data' && state.entity.id}
        Triagens={state.tag === 'with-data' && state.entity.Triagem}
      />
    </>
  )
}

export const AgendamentoCandidatoAdministrativoForm = () => {
  const [administrativoState, actions] = useAdministrativo()

  return <AgendamentoCandidatoForm state={administrativoState} />
}

export const AgendamentoCandidatoMotoristaForm = () => {
  const [motoristaState, actions] = useMotorista()

  return <AgendamentoCandidatoForm state={motoristaState} />
}