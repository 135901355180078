import { TextField } from '@material-ui/core'
import { useField } from 'formik'
import React from 'react'
import InputMask from 'react-input-mask'

interface MaskInputProps {
  name: string
  label: string
  mask: string
  type?: string
  onBlur?: React.ChangeEventHandler<HTMLInputElement>
}

function MaskInput(props: MaskInputProps) {
  const [field, meta] = useField(props)
  const { label, mask, type, onBlur } = props

  return (
    <InputMask mask={mask} maskChar="" alwaysShowMask={false} {...field} onBlur={onBlur}>
      {(inputProps: unknown) => (
        <TextField
          {...inputProps}
          label={label}
          type={type}
          variant="outlined"
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          fullWidth
        />
      )}
    </InputMask>
  )
}

export default MaskInput
