import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import { ChangeEvent } from "react"

export type SelectSelecaoOption = {
  label: string
  value: any
}

export const SelectSelecao = ({
  label,
  options,
  onChange,
  value,
  disabled
}: {
  label: string,
  options: SelectSelecaoOption[],
  onChange: (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void,
  value?: string
  disabled: boolean
}) => {
  return (
    <FormControl
      variant="outlined"
      fullWidth
      disabled={disabled}
    >
      <InputLabel variant="outlined" id={label}>{label}</InputLabel>
      <Select
        labelId={label}
        label={label}
        value={value}
        onChange={onChange}
      >
        {options.map(option => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}