import { useSnackbar } from '@elentari/core'
import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  styled
} from '@material-ui/core'
import { FormikValues, useFormikContext } from 'formik'
import { isEmpty } from 'ramda'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ComplexDialog from 'src/components/ComplexDialog'
import { useAbility } from 'src/modules/login'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import {
  alterarStatusAdmissaoCandidato,
  notificarAdmissaoDp
} from '../hooks/admissaoCandidatoRepository'

export type StatusActions = 'PENDENTE' | 'DESISTIU' | 'APROVADO'
interface Callback {
  (data: ModalFormData): Promise<null | void> | void
}

type ModalFormData = {
  dataSelecao?: Date
  status?: StatusActions
}

const useStyles = makeStyles(theme => ({
  returnButton: {
    backgroundColor: theme.palette.white
  },
  desistenciaButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  gerarInstrucoesButton: {
    backgroundColor: '#fff176',
    '&:hover': {
      backgroundColor: '#ffe92a'
    }
  },
  notificarDpButton: {
    backgroundColor: '#195a9b',
    color: 'white',
    '&:hover': {
      backgroundColor: '#4283c4',
      color: 'black'
    }
  },
  contratarButton: {
    backgroundColor: theme.palette.success.light,
    '&:hover': {
      backgroundColor: theme.palette.success.main
    }
  }
}))

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '135px'
}))

interface AdmissaoCandidatoButtonsProps {
  admissaoCandidatoId?: string | false
  candidatoId?: string | false
  candidatoType: string | false
  statusAdmissao: StatusActions | false
  onSubmit: () => void
  onStatusChange: () => void
  isSubmitting: boolean
}

export const AdmissaoCandidatoButtons = ({
  admissaoCandidatoId,
  candidatoId,
  candidatoType,
  onSubmit,
  statusAdmissao,
  onStatusChange,
  isSubmitting
}: AdmissaoCandidatoButtonsProps) => {
  const [, snackbarActions] = useSnackbar()
  const history = useHistory()
  const classes = useStyles()
  const ability = useAbility()
  const instrucaoAdmissaoAuthorization = getAuthorizations(ability,
    candidatoType === 'MOTORISTA' ? 'instrucaoAdmissaoMotorista' : 'instrucaoAdmissaoAdministrativo'
  )

  const [confirmationDialog, setConfirmationDialog] = useState<{
    title: string
    acceptLabel: string
    rejectLabel: string
    open: boolean
    action: StatusActions
    callback: Callback
  }>({
    title: '',
    acceptLabel: '',
    rejectLabel: '',
    action: 'PENDENTE',
    open: false,
    callback: (data: ModalFormData) => new Promise(() => { })
  })
  const [loading, setLoading] = useState<boolean>(false)

  const buttonsDisabled =
    isSubmitting || loading || statusAdmissao !== 'PENDENTE'

  const { validateForm, submitForm } = useFormikContext<FormikValues>()

  const handleOpenDialog = (
    title: string,
    acceptLabel: string,
    rejectLabel: string,
    action: StatusActions,
    callback: Callback
  ) => {
    setConfirmationDialog({
      title,
      acceptLabel,
      rejectLabel,
      action,
      open: true,
      callback
    })
  }

  const handleCloseDialog = () => {
    setConfirmationDialog({
      ...confirmationDialog,
      open: false
    })

    setTimeout(() => {
      setConfirmationDialog({
        title: '',
        acceptLabel: '',
        rejectLabel: '',
        action: 'PENDENTE',
        open: false,
        callback: (data: ModalFormData) => new Promise(() => { })
      })
    }, 100)
  }

  const handleConfirmDialog = async (data: ModalFormData) => {
    const { callback } = confirmationDialog
    if (callback) {
      setLoading(true)
      await callback(data)
      setLoading(false)
    }
  }

  const handleChangeStatus = async (modalData: ModalFormData) => {
    try {
      if (admissaoCandidatoId) {
        const response = await alterarStatusAdmissaoCandidato(
          admissaoCandidatoId,
          {
            ...modalData
          }
        )

        if (response?.ok) {
          snackbarActions.setMessage('Status alterado com sucesso!')
          onStatusChange()
        } else {
          snackbarActions.setMessage('Ocorreu um erro ao alterar o status.')
        }
      } else {
        snackbarActions.setMessage(
          'Não foi possível encontrar a Seleção Candidato que deseja alterar o status.'
        )
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  const handleGerarInstrucoes = () => {
    if (candidatoId && candidatoType) {
      if (candidatoType === 'MOTORISTA') {
        history.push(`/admissao-motorista/${candidatoId}/gerar-instrucao`)
      } else {
        history.push(`/admissao-administrativo/${candidatoId}/gerar-instrucao`)
      }
    } else {
      snackbarActions.setMessage(
        'Não foi possível encontrar a Admissão Candidato'
      )
    }
  }

  const resetForm = async () => {
    const formValidation = await validateForm()
    await submitForm()

    return isEmpty(formValidation)
  }

  const handleNotificarDp = async () => {
    if (await resetForm()) {
      snackbarActions.setMessage('Enviando e-mail para o DP')
      if (!admissaoCandidatoId) {
        return
      }
      const response = await notificarAdmissaoDp(admissaoCandidatoId)
      if (response) {
        snackbarActions.setMessage('E-mail de notificação enviado com sucesso!')
      } else {
        snackbarActions.setMessage('Falha ao notificar DP!')
      }
    } else {
      snackbarActions.setMessage('Existem campos inválidos no formulário')
    }
  }

  return (
    <>
      <Grid
        container
        alignContent="center"
        justifyContent={admissaoCandidatoId ? 'center' : 'flex-end'}
        spacing={4}
      >
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <StyledButton
            fullWidth
            variant="contained"
            className={classes.returnButton}
            onClick={() => history.goBack()}
          >
            Voltar
          </StyledButton>
        </Grid>
        {admissaoCandidatoId && (
          <>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <StyledButton
                fullWidth
                variant="contained"
                className={classes.desistenciaButton}
                onClick={() => {
                  handleOpenDialog(
                    'Candidato(a) desistiu da etapa de Admissão',
                    'Alterar',
                    'Voltar',
                    'DESISTIU',
                    handleChangeStatus
                  )
                }}
                disabled={buttonsDisabled}
              >
                Desistência
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <StyledButton
                fullWidth
                variant="contained"
                className={classes.contratarButton}
                disabled={buttonsDisabled}
                onClick={() => {
                  handleOpenDialog(
                    'Candidato(a) contratado na etapa de Admissão',
                    'Alterar',
                    'Voltar',
                    'APROVADO',
                    handleChangeStatus
                  )
                }}
              >
                Contratar
              </StyledButton>
            </Grid>
            {instrucaoAdmissaoAuthorization.canCreate && (
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <StyledButton
                  fullWidth
                  variant="contained"
                  className={classes.gerarInstrucoesButton}
                  disabled={buttonsDisabled}
                  onClick={handleGerarInstrucoes}
                >
                  Gerar Instruções
                </StyledButton>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <StyledButton
                fullWidth
                variant="contained"
                className={classes.notificarDpButton}
                onClick={handleNotificarDp}
                disabled={buttonsDisabled}
              >
                Notificar DP
              </StyledButton>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <StyledButton
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => onSubmit()}
            disabled={buttonsDisabled}
          >
            {isSubmitting ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              <>Salvar</>
            )}
          </StyledButton>
        </Grid>
        <ComplexDialog
          open={confirmationDialog.open}
          title={confirmationDialog.title}
          primaryActionButtonLabel={confirmationDialog.acceptLabel}
          closeActionButtonLabel={confirmationDialog.rejectLabel}
          message={'Desejar alterar o status da Admissão?'}
          handleClose={handleCloseDialog}
          primaryAction={handleConfirmDialog}
          initialValues={{
            status: confirmationDialog.action
          }}
          disableBackdropClick={true}
          transitionDuration={100}
        >
          {null}
        </ComplexDialog>
      </Grid>
    </>
  )
}
