import config from '../config'
import { ApiResponse, create } from 'apisauce'
import { User } from '../modules/login/types'
import {
  AdministrativoDetail,
  SaveAdministrativoInput
} from '../modules/candidato/administrativo/types'
import { UserProfile } from '../modules/login/types'

import qs from 'qs'
import { EdgesPage } from '@elentari/core/types'
import { handlerErrorApiInstance } from './apiSauceInstanceError'
import { FileData } from './types'

export const apiSauceInstance = create({
  baseURL: config.baseURL
})

apiSauceInstance.axiosInstance.interceptors.response.use(
  function (config) {
    return config
  },
  async error => {
    return await handlerErrorApiInstance(error)
  }
)

const getRoleFake = async (
  user: User
): Promise<ApiResponse<{ ability: string }>> => {
  return {
    ok: true,
    problem: null,
    originalError: null,
    data: {
      ability: '[{"action":["manage"],"subject":["all"]}]'
    }
  }
}

const realApi = {
  setToken: (token: string) => apiSauceInstance.setHeader('token', `${token}`),
  setEmpresa: (empresa: string) =>
    apiSauceInstance.setHeader('empresa', `${empresa}`),
  ping: () => apiSauceInstance.get('/me'),
  getRole: getRoleFake,
  login: () => {
    return {}
  },

  logout: (token: string, refresh_token: string) => {
    return apiSauceInstance.post('/auth/logout', {
      accessToken: token,
      refreshToken: refresh_token
    })
  },

  refreshToken: (refresh_token: string) => {
    return apiSauceInstance.post('/auth/refresh', {
      refresh_token
    })
  },

  getProfile: async (): Promise<ApiResponse<UserProfile>> => {
    return apiSauceInstance.get(`/auth/profile`)
  },
  verifyToken: async (token: string): Promise<ApiResponse<UserProfile>> => {
    apiSauceInstance.setHeader('token', `${token}`)
    return apiSauceInstance.post(`/auth/verify-token`)
  },
  getUsers: async (
    params: qs.ParsedQs
  ): Promise<ApiResponse<EdgesPage<AdministrativoDetail>>> => {
    return apiSauceInstance.get(`/users?${qs.stringify(params)}`)
  },
  getUser: async (id: string): Promise<ApiResponse<AdministrativoDetail>> => {
    return apiSauceInstance.get(`/users/${id}`)
  },
  saveUser: async (
    user: SaveAdministrativoInput
  ): Promise<ApiResponse<SaveAdministrativoInput>> => {
    if (user.id) {
      return apiSauceInstance.patch(`/users/${user.id}`, user)
    }
    return apiSauceInstance.post(`/users`, user)
  },
  deleteUser: async (
    id: string
  ): Promise<ApiResponse<AdministrativoDetail>> => {
    return apiSauceInstance.delete(`/users/${id}`)
  },
  deleteUnidadeNegocio: async (
    id: string
  ): Promise<ApiResponse<AdministrativoDetail>> => {
    return apiSauceInstance.delete(`/unidade-negocios/${id}`)
  },
  saveUploadS3: async (data: FileData) => {
    const formData = new FormData()
    if (data.file) formData.append('upload', data.file)
  
    const response = await apiSauceInstance.post<any>('/uploads/file', formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    })
  
    const { data: info, ok, status } = response
  
    if (info && ok) {
      return {
        info,
        ok,
        status
      }
    }
    return null
  }
}

const api = /*
  process.env.NODE_ENV === 'development' && config.useFixture
    ? fixture
    :  */ realApi

export default api
