import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import * as yup from 'yup'

import TextField from '@elentari/components-eve/final-form/TextField'
import { Grid } from '@material-ui/core'
import { GridFormData } from '../types'
import yupValidation from '../../../utils/yupValidation'

import { FormWrap, Paper } from '../../../components'
import { useGrid } from '../hooks/useGrid'

import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { GridRepository } from '../hooks/GridRepository'
import { MessagesYup } from '../../messages'
import { useSnackbar } from '@elentari/core'
import Select from '@elentari/components-eve/final-form/Select'
import { handleKeyCodes } from 'src/modules/options'
import { useAppBar } from 'src/hooks'
import { useHistory } from 'react-router-dom'
import { tiposOperacao } from 'src/utils'

export const GridForm = () => {
  const [GridState, GridAction] = useGrid()

  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()
  const history = useHistory()

  const { save } = useEntitySaver<GridFormData>(async data => {
    const response = await GridRepository.save(data)
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return response
  })

  useEffect(() => {
    return GridAction.reset()
  }, [GridAction])

  const handleSubmit = async (data: GridFormData) => {
    try {
      setLoading(true)
      data.feristasQtdIdeal = Number(data.feristasQtdIdeal)
      data.totalFeristasAtual = Number(data.totalFeristasAtual)
      data.totalMotoristasAtual = Number(data.totalMotoristasAtual)
      data.veiculos = Number(data.veiculos)
      const response = await save(data)
      if (response.status === 200) {
        appBarActions.setTitle(data.nome)
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }
  const schemaObject = {
    nome: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    operacao: yup
      .string()
      .nullable()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    coordenador: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    sessaoKMMId: yup.string().nullable(),
    veiculos: yup
      .number()
      .min(0)
      .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO)
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),

    feristasQtdIdeal: yup
      .number()
      .min(0)
      .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO)

      .required(MessagesYup.MENSAGEM_OBRIGATORIO),

    totalMotoristasAtual: yup
      .number()
      .min(0)
      .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO)

      .required(MessagesYup.MENSAGEM_OBRIGATORIO),

    totalFeristasAtual: yup
      .number()
      .min(0)
      .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO)
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
  }
  const schema = yup.object().shape(schemaObject)

  return (
    <Form
      validate={yupValidation(schema)}
      onSubmit={handleSubmit}
      initialValues={GridState.tag === 'with-data' && GridState.entity}
    >
      {({ handleSubmit, submitting, values }) => (
        <Paper>
          <FormWrap
            handleSubmit={handleSubmit}
            disableSubmit={submitting}
            disableBack={submitting}
            loading={loading}
            handleGoBack={() => history.goBack()}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField fullWidth name="nome" label="Nome" />
              </Grid>
              <Grid item xs={6}>
                <Select
                  fullWidth
                  name="operacao"
                  label="Operação"
                  items={tiposOperacao}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth name="coordenador" label="Coordenador" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth name="sessaoKMMId" label="Sessão KMM" />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  name="veiculos"
                  label="Quantidade de Veículos"
                  inputProps={{ min: 0 }}
                  onKeyDown={e => handleKeyCodes(e)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  name="feristasQtdIdeal"
                  label="Quantidade de Feristas Ideal"
                  inputProps={{ min: 0 }}
                  onKeyDown={e => handleKeyCodes(e)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  name="totalMotoristasAtual"
                  label="Total de Motoristas Titulares Hoje"
                  inputProps={{ min: 0 }}
                  onKeyDown={e => handleKeyCodes(e)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  name="totalFeristasAtual"
                  label="Total de Feristas Hoje"
                  inputProps={{ min: 0 }}
                  onKeyDown={e => handleKeyCodes(e)}
                />
              </Grid>
            </Grid>
          </FormWrap>
        </Paper>
      )}
    </Form>
  )
}
