import { useSnackbar } from '@elentari/core'
import {
  Box,
  Button,
  CardContent,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import { Skeleton } from '@material-ui/lab'
import { FieldArray, FieldArrayRenderProps, Formik, FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import AutoCompleteInput from 'src/components/Formik/Forms/AutoCompleteInput'
import * as yup from 'yup'
import { MessagesYup } from '../../messages'
import { FormWrap, Paper } from '../../../components'
import {
  createLabelsCandidato,
  fetchAllLabelsCaracteristicas,
  fetchLabelsCandidatoByCandidatoId
} from '../hooks/labelsRepository'
import { LabelCandidatoDetail, LabelsCandidatoFormData } from '../types'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import React from 'react'
import { PaperCard } from 'src/components/PaperCard'

interface Option {
  id: string
  name: string
}

type Props = {
  id: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelsContainer: {
      maxHeight: '203px',
      overflow: 'scroll'
    },
    selectContainer: {
      paddingBottom: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'start'
    },
  })
)

export const LabelsCard = ({ id }: Props) => {
  const [labelsCandidato, setLabelsCandidato] = useState<
    LabelCandidatoDetail[]
  >([])
  const [labelCaracteristicas, setLabelCaracteristicas] = useState<Option[]>([])
  const [editFields, setEditFields] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingLabelCaracteristicas, setLoadingLabelCaracteristicas] =
    useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const classes = useStyles()
  const labelsContainerRef: React.RefObject<HTMLDivElement> = React.createRef()
  const addButtonRef: React.RefObject<HTMLButtonElement> = React.createRef()

  useEffect(() => {
    async function fetchAllLabelsCandidato() {
      setLoading(true)
      if (id) {
        const response = await fetchLabelsCandidatoByCandidatoId(id)
        if (response?.ok) {
          setLabelsCandidato(response?.info)
        }
      }
      setLoading(false)
    }
    fetchAllLabelsCandidato()
  }, [id])

  useEffect(() => {
    async function fetchAllLabels() {
      setLoadingLabelCaracteristicas(true)
      const response = await fetchAllLabelsCaracteristicas()
      if (response?.ok) {
        setLabelCaracteristicas(
          response.info.edges.map(label => {
            return {
              id: label.node.id,
              name: label.node.descricao
            }
          })
        )
      }
      setLoadingLabelCaracteristicas(false)
    }

    if (editFields && labelCaracteristicas.length <= 0) {
      fetchAllLabels()
    }
  }, [editFields])

  const schemaObject = {
    caracteristicasIds: yup
      .array()
      .of(
        yup
          .string()
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
      )
      .test('unique-caracteristica', function (caracteristicasIds: any) {
        const numberDiffCaracteristicas = new Set(caracteristicasIds).size

        if (caracteristicasIds.length !== numberDiffCaracteristicas) {
          const arrRepetidos: number[] = []
          caracteristicasIds.filter(
            (caracteristicaId: string, index: number) => {
              if (caracteristicasIds.indexOf(caracteristicaId) !== index) {
                arrRepetidos.push(index)
              }

              return caracteristicasIds.indexOf(caracteristicaId) == index
            }
          )

          return this.createError({
            path: `caracteristicasIds[${arrRepetidos[0]}]`,
            message: 'Não podem haver características repetidas'
          })
        } else {
          return true
        }
      })
  }
  const schema = yup.object().shape(schemaObject)

  const formikState = {
    initialValues: {
      caracteristicasIds: []
    },
    onSubmit: async (data: FormikValues) => {
      try {
        setLoading(true)
        const response = await createLabelsCandidato({
          ...data,
          candidatoId: id
        } as LabelsCandidatoFormData)

        if (!response.ok) {
          snackbarActions.setMessage(response.message)
        } else {
          setLabelsCandidato(response?.info)
          setEditFields(false)
          snackbarActions.setMessage('Salvo com sucesso!')
        }
        setLoading(false)
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  const handleAddLabel = (arrayHelpers: FieldArrayRenderProps) => {
    return () => {
      arrayHelpers.push(undefined)

      setTimeout(() => {
        labelsContainerRef.current?.scrollTo({
          top: addButtonRef.current?.offsetTop,
          behavior: 'smooth'
        })
      }, 10)
    }
  }

  const handleDeleteLabel = (
    index: number,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    return () => {
      arrayHelpers.remove(index)
    }
  }
  
  return (
    <Box>
      <PaperCard
        title="Labels"
        disableScroll
        primaryButtonText={
          editFields
            ? 'Cancelar Edição'
            : labelsCandidato?.length !== 0
            ? 'Editar Label'
            : 'Adicionar Label'
        }
        primaryButtonAction={() => setEditFields(!editFields)}
        primaryButtonIcon={
          editFields ? (
            <CancelIcon />
          ) : labelsCandidato?.length !== 0 ? (
            <EditIcon />
          ) : (
            <AddIcon />
          )
        }
        Content={
          editFields ? (
            <Formik
              validationSchema={schema}
              onSubmit={formikState.onSubmit}
              initialValues={
                labelsCandidato.length !== 0
                  ? {
                      caracteristicasIds: labelsCandidato.map(
                        label => label.labelCaracteristicaId
                      )
                    }
                  : formikState.initialValues
              }
              enableReinitialize
            >
              {({ handleSubmit, isSubmitting, values }) => (
                <Box marginTop={0}>
                  <FormWrap
                    handleSubmit={handleSubmit}
                    disableSubmit={isSubmitting || loadingLabelCaracteristicas}
                    disableBack={isSubmitting}
                    loading={loading}
                    hideGoBack={true}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        className={classes.labelsContainer}
                        ref={labelsContainerRef}
                      >
                        {!loadingLabelCaracteristicas ? (
                          <FieldArray
                            name={'caracteristicasIds'}
                            validateOnChange={true}
                          >
                            {arrayHelpers => (
                              <Grid item xs={12}>
                                {values.caracteristicasIds?.map(
                                  (caracteristicaId, index) => (
                                    <Grid
                                      container
                                      key={index}
                                      className={classes.selectContainer}
                                    >
                                      <Grid item xs={10}>
                                        <AutoCompleteInput
                                          name={`caracteristicasIds[${index}]`}
                                          label={`${index + 1}° Label`}
                                          options={labelCaracteristicas}
                                        />
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Tooltip
                                          placement="top"
                                          title={`Deletar ${index + 1}° Label`}
                                        >
                                          <Button
                                            style={{
                                              flex: 1,
                                              height: 56,
                                              border: 'none'
                                            }}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleDeleteLabel(
                                              index,
                                              arrayHelpers
                                            )}
                                          >
                                            <DeleteOutlineIcon />
                                          </Button>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  )
                                )}
                                <Tooltip
                                  placement="right-start"
                                  title="Adicionar Label"
                                >
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleAddLabel(arrayHelpers)}
                                    ref={addButtonRef}
                                  >
                                    <AddIcon />
                                  </Button>
                                </Tooltip>
                              </Grid>
                            )}
                          </FieldArray>
                        ) : (
                          <Box>
                            <Skeleton />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </FormWrap>
                </Box>
              )}
            </Formik>
          ) : loading ? (
            <Box marginTop={2}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          ) : labelsCandidato?.length === 0 ? (
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              align="left"
            >
              <strong>Não foi adicionado nenhum label para este usuário</strong>
            </Typography>
          ) : (
            <div className={classes.labelsContainer}>
              {labelsCandidato.map(label => {
                return (
                  <Typography color="textSecondary" align="left">
                    {label.labelCaracteristicaFK?.descricao}
                  </Typography>
                )
              })}
            </div>
          )
        }
      />
    </Box>
  )
}
