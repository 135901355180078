import { MessagesYup } from 'src/modules/messages'
import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { candidatoSchemaPropertiesOptional, candidatoSchemaPropertiesRequired } from './candidatoSchema'

const motoristaSchema = {
  Motorista: Yup.object().shape({
    estadoCivil: Yup.string().nullable(),
    rg: Yup.string().trim(),
    categoriaCNH: Yup.string(),
    pamcary: Yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    earCNH: Yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    mopp: Yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    dataValidadeMopp: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('mopp', {
        is: 'true',
        then: Yup.date()
          .nullable()
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          .test({
            name: 'data-validation',
            message: MessagesYup.MENSAGEM_DATA_INVALIDA,
            test: (values, context) => {
              if (values) {
                return new Date(values).getFullYear() >= 1900
              }
              return true
            }
          })
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
      })
      .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
    indicacao: Yup.boolean()
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    quemIndicou: Yup.string()
      .nullable()
      .when('indicacao', {
        is: true,
        then: Yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
      .test(
        'Verify empty fields indicacao',
        MessagesYup.MENSAGEM_INDICACAO,
        function (value: any) {
          return (
            value ||
            (!this.parent.cargoQuemIndicou &&
              !this.parent.coordenadorQuemIndicou)
          )
        }
      ),
    coordenadorQuemIndicou: Yup.string().nullable().trim(),
    cargoQuemIndicou: Yup.string()
      .nullable()
      .when('indicacao', {
        is: true,
        then: Yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
      .test(
        'Verify empty fields indicacao',
        MessagesYup.MENSAGEM_INDICACAO,
        function (value: any) {
          return (
            value ||
            (!this.parent.coordenadorQuemIndicou && !this.parent.quemIndicou)
          )
        }
      ),
    grauEscolaridade: Yup.string()
  })
}

const schemaRequired: ObjectShape = {
  ...candidatoSchemaPropertiesRequired,
  ...motoristaSchema,
  Motorista: Yup.object().shape({
    ...motoristaSchema.Motorista.fields,
    rg: motoristaSchema.Motorista.fields.rg.required(MessagesYup.MENSAGEM_OBRIGATORIO).typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    categoriaCNH: motoristaSchema.Motorista.fields.categoriaCNH.required(MessagesYup.MENSAGEM_OBRIGATORIO).typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    indicacao: motoristaSchema.Motorista.fields.indicacao.required(MessagesYup.MENSAGEM_OBRIGATORIO).typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    grauEscolaridade: motoristaSchema.Motorista.fields.grauEscolaridade.required(MessagesYup.MENSAGEM_OBRIGATORIO).typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  })
}

export const CandidatoSchemaStepMotorista = (isAnalista: boolean) => {
  if (isAnalista) {
    return Yup.object().shape({
      ...candidatoSchemaPropertiesOptional,
      ...motoristaSchema,
      Motorista: Yup.object().shape({
        ...motoristaSchema.Motorista.fields,
        rg: motoristaSchema.Motorista.fields.rg.nullable(),
        categoriaCNH: motoristaSchema.Motorista.fields.categoriaCNH.nullable(),
        indicacao: motoristaSchema.Motorista.fields.indicacao.nullable(),
        grauEscolaridade: motoristaSchema.Motorista.fields.grauEscolaridade.nullable(),
      })
    })
  } else {
    return Yup.object().shape(schemaRequired)
  }
}

export const validationMotoristaCurriculo = Yup.object().shape(schemaRequired)