import { makeUseFetchOne } from '@elentari/core/hooks/makeUseFetchOne'
import { AgendamentoAdministrativoDetail } from '../types'
import { AgendamentoRepository } from './AgendamentoRepository'

export const useAgendamento = makeUseFetchOne<
  AgendamentoAdministrativoDetail,
  string
>({
  fetch: AgendamentoRepository.fetchOne
})
