import { isBefore } from 'date-fns'
import { isNil } from 'ramda'
import { MessagesYup } from 'src/modules/messages'
import { cidadeExistInEstado } from 'src/utils/validators/cidadeExistInEstado'
import * as Yup from 'yup'

interface TestContextArrayOption {
  options: {
    index: number;
  };
}

const ExperienciaProfissionalModel = {
  empresa: Yup.string().trim(),
  cargoFuncao: Yup.string().trim().nullable().typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  cidade: Yup.string().nullable()
    .when('uf', (uf, field) =>
      uf
        ? field.test({
          name: 'cidade-valid',
          message: MessagesYup.MENSAGEM_CIDADE_INVALIDA,
          test: (
            cidade: string,
            context: Yup.TestContext<Record<string, any>>
          ) => cidadeExistInEstado(cidade, context.parent.uf)
        })
        : field
    )
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  uf: Yup.string().nullable().typeError(MessagesYup.MENSAGEM_UF_INVALIDA),
  telefone: Yup.string().nullable().trim(),
  atualmenteEmpregado: Yup.bool(),
  dataEntrada: Yup.date()
    .nullable()
    .max(new Date(Date.now() + 1), MessagesYup.MENSAGEM_DATA_MAX_ATUAL)
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  dataSaida: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .when('atualmenteEmpregado', {
      is: false,
      then: schema => schema
        .max(
          new Date(Date.now() + 1),
          MessagesYup.MENSAGEM_DATA_MAX_ATUAL
        )
        .test({
          name: 'data-saida-maior-data-entrada',
          message: MessagesYup.MENSAGEM_DATA_SAIDA_MAIOR_DATA_ENTRADA,
          test: (dataSaida, context) => {
            if (dataSaida) {
              return !isBefore(dataSaida, context.parent.dataEntrada)
            }
            return true
          }
        })
    })
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
}

const expProfMotorista = {
  ...ExperienciaProfissionalModel,
  tipoCaminhao: Yup.string().nullable(),
  tempoExperienciaCaminhao: Yup.number().typeError(
    MessagesYup.MENSAGEM_TIPO_NUMERICO
  ),
  empresa: Yup.string()
    .trim(),
  dataEntrada: Yup.date()
    .nullable()
    .max(new Date(Date.now() + 1), MessagesYup.MENSAGEM_DATA_MAX_ATUAL)
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
}

const expProfAdministrativo = {
  ...ExperienciaProfissionalModel
}

const expProfAdministrativoRequired = {
  ExperienciaProfissional: Yup.array()
    .of(
      Yup.object().shape({
        ...expProfAdministrativo,
        cargoFuncao: Yup.string()
          .trim()
          .nullable()
          .typeError(MessagesYup.MENSAGEM_CAMPO_STRING)
          .when('empresa', (empresa, field) =>
            empresa ? field.trim().required(MessagesYup.MENSAGEM_OBRIGATORIO).typeError(MessagesYup.MENSAGEM_OBRIGATORIO) : field
          )
      })
    )
    .test('validate-first-object-in-array', function (expProfissional: any) {
      const exp = expProfissional[0]
      const { primeiroEmprego } = this.parent

      if (!primeiroEmprego && exp && !exp.empresa) {
        return this.createError({
          path: 'ExperienciaProfissional[0].empresa',
          message: MessagesYup.MENSAGEM_OBRIGATORIO
        })
      }
      return true
    })
}

const expProfMotoristaRequired = (isG10: boolean) => {
  return {
    ExperienciaProfissional: Yup.array()
      .of(
        Yup.object().shape({
          ...expProfMotorista,
          tempoExperienciaCaminhao: Yup.number().typeError(
            MessagesYup.MENSAGEM_TIPO_NUMERICO
          )
            .test('validate-two-tempos-experiencia-caminhao', MessagesYup.MENSAGEM_OBRIGATORIO, function (tempoExperienciaCaminhao) {
              const { options, parent } = this as Yup.TestContext & TestContextArrayOption;
              if (options.index > 0) {
                return true;
              }

              if (
                parent.primeiroEmprego !== true &&
                tempoExperienciaCaminhao === undefined
              ) {
                return false
              }

              return true
            }),
          empresa: Yup.string()
            .trim()
            .test('validate-two-empresas', MessagesYup.MENSAGEM_OBRIGATORIO, function (empresa) {
              const { options, parent } = this as Yup.TestContext & TestContextArrayOption;
              if (options.index > 0) {
                return true;
              }

              if (
                parent.primeiroEmprego !== true &&
                (empresa === undefined || empresa === '')
              ) {
                return false
              }

              return true
            }),
          dataEntrada: Yup.date()
            .nullable()
            .max(new Date(Date.now() + 1), MessagesYup.MENSAGEM_DATA_MAX_ATUAL)
            .transform((curr, orig) => (orig === '' ? null : curr))
            .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
            .when('empresa', {
              is: (empresa: string) => !isNil(empresa) && !isG10,
              then: schema => schema
                .required(MessagesYup.MENSAGEM_OBRIGATORIO)
            }),
          dataSaida: Yup.date()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
            .when(['atualmenteEmpregado', 'empresa'], {
              is: (atualmenteEmpregado: boolean, empresa: string) => !isNil(empresa) && !atualmenteEmpregado && !isG10,
              then: schema => schema
                .required(MessagesYup.MENSAGEM_OBRIGATORIO)
                .test({
                  name: 'data-saida-maior-data-entrada',
                  message: MessagesYup.MENSAGEM_DATA_SAIDA_MAIOR_DATA_ENTRADA,
                  test: (dataSaida, context) => {
                    if (dataSaida) {
                      return !isBefore(dataSaida, context.parent.dataEntrada)
                    }
                    return true
                  }
                })
            })
        })
      )
  }
}

export const ExperienciaProfMotoristaSchemaStep = (isAnalista: boolean, isG10: boolean) => {
  if (isAnalista) {
    return Yup.object().shape({
      ExperienciaProfissional: Yup.array()
        .of(
          Yup.object().shape({
            ...expProfMotorista,
          })
        )
    })
  } else {
    return Yup.object().shape(expProfMotoristaRequired(isG10))
  }
}

export const ExperienciaProfAdministrativoSchemaStep = (isAnalista: boolean) => {
  if (isAnalista) {
    return Yup.object().shape({
      ExperienciaProfissional: Yup.array()
        .of(
          Yup.object().shape({
            ...expProfAdministrativo,
            cargoFuncao: Yup.string().trim().nullable()
          })
        )
    })
  } else {
    return Yup.object().shape(expProfAdministrativoRequired)
  }
}

export const ExperienciaProfMotoristaCurriculos = Yup.object().shape({
  ...expProfMotoristaRequired(false),
  recaptcha: Yup.string().nullable().required(MessagesYup.MENSAGEM_OBRIGATORIO)
})

export const ExperienciaProfAdministrativoCurriculos = Yup.object().shape({
  ...expProfAdministrativoRequired,
  recaptcha: Yup.string().nullable().required(MessagesYup.MENSAGEM_OBRIGATORIO)
})