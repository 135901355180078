import Select from '@elentari/components-eve/final-form/Select'
import { useSnackbar } from '@elentari/core'
import Grid from '@material-ui/core/Grid'
import LinkMD from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Rating } from '@material-ui/lab'
import QueryString from 'qs'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import ComplexDialog from 'src/components/ComplexDialog'
import RichTextField from 'src/components/Forms/RichTextField'
import { AprovarTriagemIcon } from 'src/components/Icons/AprovarTriagemIcon'
import { CpfBloqueadoIcon } from 'src/components/Icons/CpfBloqueadoIcon'
import { StatusChip } from 'src/components/StatusChip'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import * as yup from 'yup'
import {
  NoResultsTable,
  Paper,
  TableContainer,
  When
} from '../../../components'
import Spacer from '../../../components/Spacer'
import { TableCellHead } from '../../../components/TableCellHead'
import { FormatedDate } from '../../../utils'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import { ModalHistorico } from '../components/ModalHistorico'
import { save } from "../hooks/triagemRepository"
import triagemStore from '../store/store'
import { TriagemDetail } from '../types'
import { getNewestTriagem } from '../utils'
import { aprovarTriagemOptions, formatMotivoTriagem } from '../utils/triagemOptions'
import { MotoristaDetail } from './types'

interface Props {
  list: { node: MotoristaDetail }[]
  loading: boolean
  totalCount: number
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
  resetList: () => void
}

interface Callback {
  (data: ModalFormData): Promise<null | void> | void
}

type ModalFormData = {
  motivo: string
  outrosMotivos?: string
  candidatoId?: string
}

type ModalActions = 'APROVAR' | 'HISTORICO' | 'NONE'

export const ReprovadosTriagemMotoristaTable = ({
  list,
  loading,
  totalCount,
  authorization,
  resetList
}: Props) => {
  const { search } = useLocation()

  const [, snackbarActions] = useSnackbar()
  const userLogged = useLoggedUser()

  const TempoModal = 100
  const [confirmationDialog, setConfirmationDialog] = useState<{
    title: string
    open: boolean
    action: ModalActions
    acceptLabel?: string
    candidatoId?: string
    triagens?: TriagemDetail[]
    callback?: Callback
  }>({
    title: '',
    action: 'NONE',
    open: false,
  })

  const handleOpenDialog = (
    title: string,
    action: ModalActions,
    actionLabel?: string,
    candidatoId?: string,
    triagens?: TriagemDetail[],
    callback?: Callback
  ) => {
    setConfirmationDialog({
      title,
      action,
      acceptLabel: actionLabel,
      open: true,
      candidatoId,
      triagens,
      callback
    })
  }

  const closeDialog = () => {
    setConfirmationDialog({
      ...confirmationDialog,
      open: false
    })

    setTimeout(() => {
      setConfirmationDialog({
        title: '',
        action: 'NONE',
        open: false
      })
    }, TempoModal)
  }

  const handleConfirmDialog = async (data: any) => {
    const { callback } = confirmationDialog
    if (callback) {
      await callback(data)
    }
  }

  const handleAprovarCandidato = async (modalData: ModalFormData) => {
    try {
      if (modalData.candidatoId && userLogged?.name) {
        const response = await save({
          atendeCriterios: true,
          candidatoId: modalData.candidatoId,
          motivo: modalData.motivo,
          comentario: modalData.outrosMotivos,
          triadoPor: userLogged.name
        })

        if (response?.ok && response.info) {
          resetList()

          snackbarActions.setMessage("Candidato triado com sucesso")
        } else {
          snackbarActions.setMessage(
            'Ocorreu um erro ao triar o(a) Candidato(a)'
          )
        }
      } else {
        snackbarActions.setMessage('Não foi possível encontrar o candidato')
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  const dialogSchema = yup.object().shape({
    motivo: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    outrosMotivos: yup
      .string()
      .nullable()
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  })

  return (
    <>
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Nome Completo" sort="nomeCompleto" />
                  <TableCellHead label="CPF" sort="cpf" />
                  <TableCellHead label="Celular" sort="celular" />
                  <TableCellHead label="Cidade" sort="cidade" />
                  <TableCellHead label="UF" sort="uf" />
                  <TableCellHead label="Classificação" sort="notaClassificacao" />
                  <TableCellHead
                    label="Data do Cadastro"
                    sort="createdAt"
                  />
                  <TableCellHead label="Status" />
                  <TableCellHead label="Aprovar na Triagem" />
                  <When
                    value={!authorization || authorization.canCreate}
                    equals
                  >
                    <TableCell></TableCell>
                  </When>
                  <TableCellHead label="Histórico" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(motorista => (
                  <TableRow hover key={motorista.node.id}>
                    <TableCell>
                      {!authorization || authorization.canUpdate ? (
                        <When
                          value={!authorization || authorization.canUpdate}
                          equals
                        >
                          <LinkMD
                            component={Link}
                            to={`/reprovados-triagem-motorista/${motorista.node.id}`}
                            onClick={() => {
                              triagemStore.fetchCandidatos(
                                QueryString.stringify({
                                  sort: { createdAt: 'desc' },
                                  incluirBloqueados: 'false',
                                  tipo: 'MOTORISTA',
                                  statusCandidato: 'TRIADO',
                                  statusTriagem: 'REPROVADO',
                                  limit: 50,
                                  ...QueryString.parse(search.substring(1))
                                }),
                                motorista.node.id
                              )
                            }}
                          >
                            {motorista.node.nomeCompleto}
                          </LinkMD>
                        </When>
                      ) : (
                        motorista.node.id
                      )}
                    </TableCell>
                    <TableCell>{motorista.node.cpf}</TableCell>
                    <TableCell>{motorista.node.celular}</TableCell>
                    <TableCell>{motorista.node.cidade}</TableCell>
                    <TableCell>{motorista.node.uf}</TableCell>
                    <TableCell>
                      <Rating
                        max={3}
                        value={motorista.node.notaClassificacao}
                        disabled
                      />
                    </TableCell>
                    <TableCell>
                      {motorista.node.createdAt && FormatedDate(motorista.node.createdAt)}
                    </TableCell>
                    <TableCell>
                      <StatusChip
                        label={motorista.node.Triagem ? formatMotivoTriagem(false, getNewestTriagem(motorista.node.Triagem)?.motivo) : '-'}
                        status="FALSE"
                      />
                    </TableCell>
                    <TableCell>
                      <AprovarTriagemIcon
                        onClick={() => handleOpenDialog(
                          'Deseja aprovar este Candidato(a)?',
                          'APROVAR',
                          'Aprovar',
                          motorista.node.id,
                          motorista.node.Triagem,
                          handleAprovarCandidato
                        )}
                        disabled={loading}
                      />
                    </TableCell>
                    <When value={authorization?.canCreate} equals>
                      <TableCell>
                        {motorista.node.idListaBloqueio && (
                          <CpfBloqueadoIcon
                            idListaBloqueio={motorista.node.idListaBloqueio}
                          />
                        )}
                      </TableCell>
                    </When>
                    <TableCell>
                      <ModalHistorico
                        candidatoId={motorista.node.id}
                        candidatoType="MOTORISTA"
                        triagens={motorista.node.Triagem}
                        disabled={loading}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <ComplexDialog
        open={!!confirmationDialog.open}
        handleClose={closeDialog}
        title={confirmationDialog.title}
        primaryActionButtonLabel={confirmationDialog.acceptLabel}
        hidePrimaryActionButton={!!!confirmationDialog.acceptLabel}
        primaryAction={handleConfirmDialog}
        validate={yupValidation(dialogSchema)}
        initialValues={confirmationDialog.action === 'APROVAR' ? { candidatoId: confirmationDialog.candidatoId } : {}}
        closeActionButtonLabel="Voltar"
        disableBackdropClick={true}
        transitionDuration={TempoModal}
        maxWidth='lg'
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Select
              fullWidth
              name="motivo"
              label="Motivo"
              items={aprovarTriagemOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextField name="outrosMotivos" label="Outros Motivos" />
          </Grid>
        </Grid>
      </ComplexDialog>
    </>
  )
}