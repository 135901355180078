import Filter from '@elentari/components-eve/components/Filter'
import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import {
  Grid, Tooltip
} from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import { useEffect, useState } from 'react'
import { FormSpy } from 'react-final-form'
import RFFAutocomplete from 'src/components/Forms/RFFAutocomplete'
import { Option } from 'src/hooks/useAutoComplete'
import { treinamentosRepository } from 'src/modules/treinamentos/hooks/treinamentosRepository'
import * as yup from 'yup'
import lugares from '../../../utils/cidades.json'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import { tipoTurnos } from '../types'

type AdmissaoCandidatoQueryFilter = {
  createdAt?: { gt: string }
  [key: string]: any
}

const schema = yup.object().shape({
  admissaoDataEntregaDocumentosStart: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  admissaoDataEntregaDocumentosEnd: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .test({
      name: 'data-entrega-documento-valid',
      message: "A data informada é inferior a 'Data Entrega Documentos de'",
      test: (admissaoDataEntregaDocumentosEnd, context) => {
        if (!admissaoDataEntregaDocumentosEnd || !context.parent.admissaoDataEntregaDocumentosStart) {
          return true
        }
        return admissaoDataEntregaDocumentosEnd >= context.parent.admissaoDataEntregaDocumentosStart
      }
    }),
  admissaoDataExameStart: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  admissaoDataExameEnd: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .test({
      name: 'data-exame-valid',
      message: "A data informada é inferior a 'Data Exame de'",
      test: (admissaoDataExameEnd, context) => {
        if (!admissaoDataExameEnd || !context.parent.admissaoDataExameStart) {
          return true
        }
        return admissaoDataExameEnd >= context.parent.admissaoDataExameStart
      }
    }),
  admissaoDataPrevistaAdmissaoStart: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  admissaoDataPrevistaAdmissaoEnd: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .test({
      name: 'data-admissao-valid',
      message: "A data informada é inferior a 'Data Admissão de'",
      test: (admissaoDataPrevistaAdmissaoEnd, context) => {
        if (!admissaoDataPrevistaAdmissaoEnd || !context.parent.admissaoDataPrevistaAdmissaoStart) {
          return true
        }
        return admissaoDataPrevistaAdmissaoEnd >= context.parent.admissaoDataPrevistaAdmissaoStart
      }
    }),
  agendaInstrucaoData: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
})

const AdmissaoCandidatoFilter = ({
  candidatoType
}: {
  candidatoType: 'ADMINISTRATIVO' | 'MOTORISTA'
}) => {
  const [treinamentos, setTreinamentos] = useState<Option[]>()
  const [uf, setUF] = useState<Option[]>([])

  useEffect(() => {
    const ufs = lugares.estados.map(locais => {
      return {
        id: locais.sigla,
        name: locais.sigla
      }
    })
    setUF(ufs)
  }, [])

  const getCidadesFromUf = (uf: string): Option[] => {
    const findUF = lugares.estados.find(cidade => cidade.sigla === uf)

    if (findUF) {
      const cidades = findUF.cidades.map(cidade => {
        return {
          id: cidade,
          name: cidade
        }
      })

      return cidades
    } else {
      return []
    }
  }

  useEffect(() => {
    async function fetchTreinamentos() {
      const response = await treinamentosRepository.fetch({ limit: '999' })

      if (response.ok) {
        setTreinamentos(
          response.data?.edges.map(treinamento => {
            return {
              id: treinamento.node.id,
              name: treinamento.node.nome
            }
          })
        )
      }
    }

    fetchTreinamentos()
  }, [])

  const { clear, push, initialValues } =
    useQueryParams<AdmissaoCandidatoQueryFilter>()

  const handleSubmitFilter = (values: any) => {
    if (values.admissaoDataEntregaDocumentosStart) {
      values.admissaoDataEntregaDocumentosStart = new Date(values.admissaoDataEntregaDocumentosStart).toISOString()
    }
    if (values.admissaoDataEntregaDocumentosEnd) {
      values.admissaoDataEntregaDocumentosEnd = new Date(values.admissaoDataEntregaDocumentosEnd).toISOString()
    }
    if (values.admissaoDataExameStart) {
      values.admissaoDataExameStart = new Date(values.admissaoDataExameStart).toISOString()
    }
    if (values.admissaoDataExameEnd) {
      values.admissaoDataExameEnd = new Date(values.admissaoDataExameEnd).toISOString()
    }
    if (values.admissaoDataPrevistaAdmissaoStart) {
      values.admissaoDataPrevistaAdmissaoStart = new Date(values.admissaoDataPrevistaAdmissaoStart).toISOString()
    }
    if (values.admissaoDataPrevistaAdmissaoEnd) {
      values.admissaoDataPrevistaAdmissaoEnd = new Date(values.admissaoDataPrevistaAdmissaoEnd).toISOString()
    }
    if (values.agendaInstrucaoData) {
      values.agendaInstrucaoData = new Date(values.agendaInstrucaoData).toISOString()
    }
    if (values.agendaInstrucaoTreinamentoId === null) {
      delete values.agendaInstrucaoTreinamentoId
    }
    if (values.agendaInstrucaoTurno === null) {
      delete values.agendaInstrucaoTurno
    }

    push({
      ...values
    })
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={initialValues}
      onClear={clear}
      onSubmit={values => handleSubmitFilter(values)}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3} sm={12} md={6}>
          <TextField fullWidth name="name" label="Nome Completo" />
        </Grid>
        <Grid item xs={12} lg={2} sm={6} md={6}>
          <TextField fullWidth name="cpf" label="CPF" />
        </Grid>
        <Grid item xs={12} lg={2} sm={6} md={4}>
          <RFFAutocomplete name="uf" label="UF" options={uf} />
        </Grid>
        <Grid item xs={12} lg={2} sm={6} md={4}>
          <FormSpy
            render={({ values }) => (
              <RFFAutocomplete name="cidade" label="Cidade" options={getCidadesFromUf(values.uf)} disabled={!values.uf} />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={3} sm={6} md={4}>
          <TextField fullWidth name="admissaoCoordenador" label="Coordenador" />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <DatePicker
            fullWidth
            name="admissaoDataEntregaDocumentosStart"
            label="Data Entrega Documentos de"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <DatePicker
            fullWidth
            name="admissaoDataEntregaDocumentosEnd"
            label="Data Entrega Documentos até"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <DatePicker
            fullWidth
            name="admissaoDataExameStart"
            label="Data Exame de"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <DatePicker
            fullWidth
            name="admissaoDataExameEnd"
            label="Data Exame até"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <DatePicker
            fullWidth
            name="admissaoDataPrevistaAdmissaoStart"
            label="Data Admissão de"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <DatePicker
            fullWidth
            name="admissaoDataPrevistaAdmissaoEnd"
            label="Data Admissão até"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4} sm={12} md={4}>
          <RFFAutocomplete name="agendaInstrucaoTreinamentoId" label="Treinamento" options={treinamentos ?? []} />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <DatePicker
            fullWidth
            name="agendaInstrucaoData"
            label="Data Treinamento"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <Select
            fullWidth
            name="agendaInstrucaoTurno"
            label="Período Treinamento"
            items={tipoTurnos}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export { AdmissaoCandidatoFilter }

