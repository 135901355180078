import { useSnackbar } from '@elentari/core'
import { Button } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import EmailIcon from '@material-ui/icons/Email'
import { useState } from 'react'
import { sendEmail } from '../../hooks/documentosRepository'

type Props = {
  id: string
}

export const SendEmailLink = ({ id }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()

  const handleButtonClick = async () => {
    try {
      setLoading(true)
      const response = await sendEmail(id)

      if (!response.ok) {
        snackbarActions.setMessage(response.message)
      } else {
        snackbarActions.setMessage('Email enviado!')
      }
      setLoading(false)
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  return (
    <Tooltip
      placement="top"
      title="Enviar e-mail para o candidato(a) anexar documentos"
    >
      <Button
        onClick={handleButtonClick}
        style={{ height: 55 }}
        fullWidth
        color="primary"
        variant="contained"
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : <EmailIcon />}
      </Button>
    </Tooltip>
  )
}
