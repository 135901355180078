import {
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core"
import { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import { CpfBloqueadoIcon } from "src/components/Icons/CpfBloqueadoIcon"
import { ImprimirIcon } from "src/components/Icons/ImprimirIcon"
import { AdministrativoDetail } from "../administrativo/types"
import { findCNHCandidato, findCurriculoCandidato } from "../hooks/documentosRepository"
import { MotoristaDetail } from "../motorista/types"
import { handleCandidatoToPDF } from "../utils/configPDF"
import DocumentoCandidatoFile from "./DocumentoCandidatoFile"
import { ReturnButton } from "./ReturnButton"
import { StarRateInput } from "./StarRateInput"

type FormHeaderProps = {
  showReturnButton: boolean,
  disableButtons: boolean,
  candidatoData?: AdministrativoDetail | MotoristaDetail | false,
  header?: boolean,
  isTriagem?: boolean
}

const useStyles = makeStyles({
  anexoButton: {
    textAlign: 'center'
  },
  anexoTitleDisabled: {
    color: 'rgba(0, 0, 0, 0.26)'
  },
  gridAlignCenter: {
    alignSelf: 'center'
  },
  gridAlignEnd: {
    alignSelf: 'end'
  }
})

export const FormHeader = ({
  showReturnButton,
  disableButtons,
  candidatoData,
  header,
  isTriagem
}: FormHeaderProps) => {

  const [idDocumentoCandidatoCurriculo, setIdDocumentoCandidatoCurriculo] = useState<string>()
  const [idDocumentoCandidatoCNH, setIdDocumentoCandidatoCNH] = useState<string>()

  const classes = useStyles()
  const params = useParams<{ id: string }>()

  const isNew = params.id === 'new'

  const findCandidatoCurriculo = async () => {
    const response = await findCurriculoCandidato(params.id)
    setIdDocumentoCandidatoCurriculo(response?.info.id)
  }

  const findCandidatoCNH = async () => {
    const response = await findCNHCandidato(params.id)
    setIdDocumentoCandidatoCNH(response?.info.id)
  }

  useEffect(() => {
    if (!isNew) {
      findCandidatoCurriculo()
    }
  }, [idDocumentoCandidatoCurriculo, params])

  useEffect(() => {
    if (!isNew) {
      findCandidatoCNH()
    }
  }, [idDocumentoCandidatoCNH, params])

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12} sm={3} className={isTriagem ? classes.gridAlignCenter : undefined}>
        {showReturnButton && !isTriagem && (
          <ReturnButton disableButtons={disableButtons} />
        )}
        {isTriagem && (
          <Typography variant="h5" align="center">
            Candidato(a)
          </Typography>
        )}
      </Grid>

      <Grid item xs={6} sm={2}>
        {candidatoData && (
          <Grid container>
            <Grid item xs={12} className={classes.anexoButton}>
              <DocumentoCandidatoFile id={idDocumentoCandidatoCurriculo} iconType="clip" justifyContent="center" />
              <Typography
                className={!idDocumentoCandidatoCurriculo ? classes.anexoTitleDisabled : undefined}
              >
                Visualizar Currículo
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item xs={6} sm={2}>
        {candidatoData && candidatoData.tipo === 'MOTORISTA' && (
          <Grid container>
            <Grid item xs={12} className={classes.anexoButton}>
              <DocumentoCandidatoFile id={idDocumentoCandidatoCNH} iconType="clip" justifyContent="center" />
              <Typography
                className={!idDocumentoCandidatoCNH ? classes.anexoTitleDisabled : undefined}
              >
                Visualizar CNH
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item xs={6} sm={2} className={classes.gridAlignEnd}>
        {candidatoData && (
          <StarRateInput
            name="notaClassificacao"
            label="Classificação"
            starsQuantity={3}
            disabled={disableButtons}
            idCandidato={candidatoData.id}
          />
        )}
      </Grid>

      <Grid item xs={3} sm={2} className={classes.gridAlignCenter}>
        {candidatoData && candidatoData.idListaBloqueio && (
          <CpfBloqueadoIcon idListaBloqueio={candidatoData.idListaBloqueio} alignCenter />
        )}
      </Grid>
      <Grid item xs={3} sm={1} className={classes.gridAlignCenter}>
        {candidatoData && (
          <ImprimirIcon
            title="Imprimir Currículo do Candidato"
            onClick={() => handleCandidatoToPDF(candidatoData)}
            disabled={disableButtons}
          />
        )}
      </Grid>
    </Grid>
  )
}