import moment from 'moment'

export const formatDate = (date: Date) => {
  if (date) {
    return moment(date).format('DD/MM/YYYY')
  }
  return 'Data não informada'
}

export const formatTime = (date: Date) => {
  if (date) {
    return moment(date).format('HH:mm')
  }
  return 'Hora não informada'
}

export const combineDateWithTime = (date: Date, time: Date) => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds()
  )
}
