import Filter from '@elentari/components-eve/components/Filter'
import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import {
  Grid, Tooltip
} from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import * as yup from 'yup'
import { booleanOptions, isG10, tipoContratacao } from '../../../utils'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import { statusSelecao } from '../types'
import subEmpresasStore from './store/subEmpresasList'

type SelecaoCandidatoQueryFilter = {
  createdAt?: { gt: string }
  [key: string]: any
}

const schema = yup.object().shape({
  agendamentoCreatedAt: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  dataSelecaoStart: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  dataSelecaoEnd: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
})

const SelecaoCandidatoFilter = ({
  candidatoType
}: {
  candidatoType: 'ADMINISTRATIVO' | 'MOTORISTA'
}) => {
  const isMotorista = candidatoType === 'MOTORISTA'

  const { clear, push, initialValues } =
    useQueryParams<SelecaoCandidatoQueryFilter>()

  const userLogged = useLoggedUser()
  const userEmpresaIsG10 = isG10(userLogged?.empresa)

  const handleSubmitFilter = (values: any) => {
    if (values.notaClassificacao === null) {
      delete values.notaClassificacao
    }
    if (values.selecaoTipoContratacao === null) {
      delete values.selecaoTipoContratacao
    }
    if (values.selecaoCompareceu === null) {
      delete values.selecaoCompareceu
    }
    if (values.statusSelecao === null) {
      delete values.statusSelecao
    }
    if (values.selecaoSubEmpresa === null) {
      delete values.selecaoSubEmpresa
    }
    if (values.agendamentoCreatedAt) {
      values.agendamentoCreatedAt = new Date(values.agendamentoCreatedAt).toISOString()
    }
    if (values.dataSelecaoStart) {
      values.dataSelecaoStart = new Date(values.dataSelecaoStart).toISOString()
    }
    if (values.dataSelecaoEnd) {
      values.dataSelecaoEnd = new Date(values.dataSelecaoEnd).toISOString()
    }
    push({
      ...values
    })
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={initialValues}
      onClear={clear}
      onSubmit={values => handleSubmitFilter(values)}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4} sm={12} md={4}>
          <TextField fullWidth name="agendamentoAgendadoPor" label="Agendado por" />
        </Grid>
        <Grid item xs={12} lg={4} sm={12} md={4}>
          <DatePicker
            fullWidth
            name="agendamentoCreatedAt"
            label={userEmpresaIsG10 ? "Data do Agendamento" : "Data em que o Agendamento aconteceu"}
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4} sm={12} md={4}>
          <TextField fullWidth name="cpf" label="CPF" />
        </Grid>
        <Grid item xs={12} lg={4} sm={12} md={4}>
          <TextField fullWidth name="name" label="Nome Completo" />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <DatePicker
            fullWidth
            name="dataSelecaoStart"
            label="Data Seleção de"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>

        <Grid item xs={12} lg={4} sm={6} md={4}>
          <DatePicker
            fullWidth
            name="dataSelecaoEnd"
            label="Data Seleção até"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        {isMotorista && (
          <Grid item xs={12} lg={4} sm={12} md={4}>
            <TextField fullWidth name="quemIndicou" label="Quem Indicou" />
          </Grid>
        )}
        <Grid item xs={12} lg={2} sm={6} md={isMotorista ? 4 : 6}>
          <Select
            fullWidth
            name="selecaoCompareceu"
            label="Compareceu"
            items={booleanOptions}
          />
        </Grid>
        <Grid item xs={12} lg={2} sm={6} md={isMotorista ? 4 : 6}>
          <Select
            fullWidth
            name="statusSelecao"
            label="Status Seleção"
            items={statusSelecao}
          />
        </Grid>
        <Grid item xs={12} lg={isMotorista ? 2 : 4} sm={6} md={6}>
          <Select
            fullWidth
            name="selecaoTipoContratacao"
            label="Tipo da Contratação"
            items={tipoContratacao}
          />
        </Grid>
        <Grid item xs={12} lg={isMotorista ? 2 : 4} sm={6} md={6}>
          <Select
            fullWidth
            name="selecaoSubEmpresa"
            label="Empresa"
            items={
              subEmpresasStore.getSubEmpresasList().map(subEmpresa => {
                return {
                  label: subEmpresa.nome,
                  value: subEmpresa.nome
                }
              })
            }
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export { SelecaoCandidatoFilter }
