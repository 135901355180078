import { ReactNode } from 'react'

interface EqualsProps<T> {
  value: T
  equals: T
  children: ReactNode
}

/**
 * @param value item to be compared
 * @param getItem referece will be compared with value param
 */

function When<T>(props: EqualsProps<T>) {
  if (props.equals) {
    if (props.value === props.equals) {
      return <>{props.children}</>
    }
  }
  return <></>
}

export default When
