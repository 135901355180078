import { EdgesPage } from '@elentari/core/types'
import QueryString from 'qs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAbility } from 'src/modules/login'
import { apiSauceInstance } from 'src/services/api'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import triagemStore from '../store/store'
import { SelecaoCandidatoTable } from './SelecaoCandidatoTable'
import { SelecaoCandidatoFilter } from './SelecaoCandidatoFilter'
import { CandidatoDetail } from '../types'
import { MotoristaDetail } from '../motorista/types'
import { observer } from 'mobx-react-lite'
import { useSubEmpresas } from './hooks/useSubEmpresas'
import subEmpresasStore from './store/subEmpresasList'

const SelecaoCandidatoList = (props: {
  list: { node: CandidatoDetail }[]
  loading: boolean
  totalCount: number
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
  candidatoType: 'ADMINISTRATIVO' | 'MOTORISTA'
}) => {
  const [subEmpresasState, subEmpresasAction] = useSubEmpresas()

  useEffect(() => {
    if (subEmpresasState.tag === 'with-data') {
      subEmpresasStore.setSubEmpresasList(subEmpresasState.list)
    }
  }, [subEmpresasState])

  return (
    <>
      <SelecaoCandidatoFilter candidatoType={props.candidatoType} />
      {props.candidatoType === 'MOTORISTA' ?
        <SelecaoCandidatoTable {...props} list={props.list as { node: MotoristaDetail }[]} />
        :
        <SelecaoCandidatoTable {...props} />
      }
    </>
  )
}

type APIResponseCandidatos = {
  edges: { node: CandidatoDetail }[]
  totalCount: number
  loading: boolean
}

const SelecaoCandidato = ({
  candidatoType
}: {
  candidatoType: 'ADMINISTRATIVO' | 'MOTORISTA'
}) => {
  const [candidatosState, setCandidatosState] = useState<APIResponseCandidatos>(
    {} as APIResponseCandidatos
  )
  const { search, pathname } = useLocation()
  const ability = useAbility()

  const authorization = getAuthorizations(ability, 'agendamentosMotoristas')

  const makeParams = (queryParams: string) => {
    let baseParams: { [key: string]: any } = {
      sort: { createdAt: 'desc' },
    }

    if (queryParams.substring(1).length === 0) {
      return {
        ...baseParams,
        dataSelecaoEnd: new Date(new Date().setUTCHours(23, 59, 59, 999)),
        statusCandidato: 'SELECIONADO',
        statusSelecao: 'PENDENTE',
      }
    }
    return {
      ...baseParams,
      ...QueryString.parse(queryParams.substring(1))
    }
  }

  useEffect(() => {
    async function loadCandidatos() {
      try {
        setCandidatosState({
          ...candidatosState,
          loading: true
        })

        const params: { [key: string]: any } = makeParams(search)

        const response = await apiSauceInstance.get<
          EdgesPage<CandidatoDetail>
        >(`/candidato?${QueryString.stringify(params)}`, {
          tipo: candidatoType,
          hasSelecao: true,
          skip: params.page ? (+params.page - 1) * 10 : 0,
          limit: 10
        })
        if (response.ok && response.data) {
          setCandidatosState({
            totalCount: response.data.totalCount,
            edges: response.data.edges,
            loading: false
          })
        } else {
          setCandidatosState({
            totalCount: 0,
            edges: [],
            loading: false
          })
        }
      } catch (error) { }
    }
    loadCandidatos()
  }, [pathname, search])

  return (
    <>
      {candidatosState.loading === false ? (
        <SelecaoCandidatoList
          totalCount={candidatosState.totalCount}
          list={candidatosState.edges}
          loading={candidatosState.loading}
          authorization={authorization}
          candidatoType={candidatoType}
        />
      ) : (
        <SelecaoCandidatoList
          totalCount={0}
          list={[]}
          loading={candidatosState.loading}
          authorization={authorization}
          candidatoType={candidatoType}
        />
      )}
    </>
  )
}

export const SelecaoMotorista = observer(() => <SelecaoCandidato candidatoType="MOTORISTA" />)
export const SelecaoAdministrativo = observer(() => <SelecaoCandidato candidatoType="ADMINISTRATIVO" />)