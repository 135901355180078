import { useSnackbar } from '@elentari/core'
import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Grid } from '@material-ui/core'
import { FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Paper } from 'src/components'
import { useAppBar } from 'src/hooks'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import { isAnalistaRh } from 'src/utils'
import { CandidatoSchemaStepAdmin } from 'src/utils/yup-schemas/candidato/adminStepSchema'
import { ExperienciaProfAdministrativoSchemaStep } from 'src/utils/yup-schemas/candidato/expProfStepSchema'
import { FormikStepper } from '../../../../components/Formik/FormikStepper'
import { FormHeader } from '../../components/FormHeader'
import { uploadManyDocumentos } from '../../hooks/documentosRepository'
import CandidatoStep from '../../sharedSteps/CandidatoStep'
import ExperienciaProfeStep from '../../sharedSteps/ExperienciaProfStep'
import { objectRemoveEmptyStrings } from '../../utils'
import { repository as usersRepository } from '../hooks/administrativoRepository'
import { useAdministrativo } from '../hooks/useAdministrativo'
import { SaveAdministrativoInput } from '../types'
import { initialValues } from '../utils/initialValues'

export const AdministrativoForm = () => {
  const [administrativoState, actions] = useAdministrativo()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()

  const history = useHistory()
  const userLogged = useLoggedUser()
  const isAnalista = isAnalistaRh(userLogged?.rolesInSystem)

  const { save } = useEntitySaver<SaveAdministrativoInput>(data =>
    usersRepository.save(data)
  )

  const formikState = {
    initialValues,
    onSubmit: async (data: FormikValues) => {
      setIsSubmitting(true)
      try {
        data.tipo = 'ADMINISTRATIVO'

        const documentos = await uploadManyDocumentos([
          { ...data.anexoCurriculo, descricao: 'anexoCurriculo', candidatoId: data.id }
        ])
        if (!documentos) {
          setIsSubmitting(false)
          return snackbarActions.setMessage("Ocorreu um erro ao salvar o(s) documento(s)")
        }

        delete data.anexoCurriculo

        const dataFormatted = objectRemoveEmptyStrings(data)
        const response = await save({
          ...dataFormatted,
          documentos
        } as SaveAdministrativoInput)
        if (response.status === 200) {
          if (response.data?.id) {
            actions.fetch(response.data.id)
          }
          appBarActions.setTitle(data.nomeCompleto)
        }
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (administrativoState.tag === 'with-data') {
      if (userLogged?.empresa !== administrativoState.entity.empresaFK?.sigla) {
        history.push('/')
      }
    }
  }, [administrativoState])

  useEffect(() => {
    return actions.reset()
  }, [actions])

  return (
    <Paper noTopBorderRadius>
      <Grid container spacing={2} direction="column">
        <FormikStepper
          onSubmit={formikState.onSubmit}
          enableReinitialize={true}
          initialValues={
            administrativoState.tag === 'with-data'
              ? administrativoState.entity
              : formikState.initialValues
          }
        >
          <FormHeader
            header
            disableButtons={isSubmitting}
            showReturnButton={true}
            candidatoData={administrativoState.tag === 'with-data' && administrativoState.entity}
          />

          <CandidatoStep
            label="Candidato(a)"
            candidatoType="Administrativo"
            validationSchema={CandidatoSchemaStepAdmin(isAnalista)}
          />

          <ExperienciaProfeStep
            label="Experiência Profissional"
            candidatoType="Administrativo"
            validationSchema={ExperienciaProfAdministrativoSchemaStep(isAnalista)}
          />
        </FormikStepper>
      </Grid>
    </Paper>
  )
}
