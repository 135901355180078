import {
  Button,
  Card,
  Grid,
  makeStyles,
  Typography,
  withStyles
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { CurriculoType } from 'src/modules/curriculos/types'

interface CardButtonProps {
  title: string
  empresa: 'G10' | 'TRANSPANORAMA' | 'g10' | 'transpanorama' | 'Transpanorama'
  tipo: CurriculoType
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    padding: 24,
    borderRadius: 8,
    boxShadow: '0px 0px 0px 1px #9999',
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.white
  }
}))

const StyledButton = withStyles(theme => ({
  root: {
    width: '100%',
    padding: '10px 10px',
    backgroundColor: theme.palette.white,
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    }
  }
}))(Button)

export const CardButton = ({ title, empresa, tipo }: CardButtonProps) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Card className={classes.root}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" component="h2">
            Sou {title}
          </Typography>
          <br />
          <Typography variant="body2" component="p">
            Plataforma de envio de currículos para {title}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledButton
            variant="contained"
            onClick={() =>
              history.push(`/curriculos?empresa=${empresa}&tipo=${tipo}`)
            }
            color="primary"
            size="small"
          >
            Ir para Envio {title}
          </StyledButton>
        </Grid>
      </Grid>
    </Card>
  )
}
