import api, { apiSauceInstance } from './api'
import axios, { AxiosResponse } from 'axios'
import { User } from 'src/modules/login/types'

export async function handlerErrorApiInstance(error: any) {
  if (
    (error.response.status === 498 || error.response.status === 401) &&
    !error.config._retry
  ) {
    error.config._retry = true
    try {
      const user: User = JSON.parse(localStorage.user)

      const response = await axios.post(
        `${apiSauceInstance.getBaseURL()}/auth/refresh`,
        {
          refreshToken: user.refresh_token
        }
      )

      const newToken = response.data.accessToken
      api.setToken(newToken)

      user.refresh_token = response.data.refreshToken
      user.token = newToken

      localStorage.setItem('user', JSON.stringify(user))

      const retorno = apiSauceInstance.axiosInstance({
        ...error.config,
        headers: {
          ...error.config.headers,
          token: newToken
        }
      })
      return retorno
    } catch (error) {
      window.location.reload()
      delete localStorage.user
      return Promise.reject(error)
    }
  } else {
    return Promise.reject(error)
  }
}
