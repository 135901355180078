import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { ParsedQs } from 'qs'
import { apiSauceInstance } from '../../../../../services/api'
import { paginationParams } from '../../../../../utils/paginationQueryParams'
import {
  AnexoProcessoSeletivoAdministrativoDocumentoDetail,
  SaveAnexoProcessoSeletivoAdministrativoDocumentoInput
} from '../types'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, limit, ...rest } = params

  return {
    ...paginationParams(rest),
    sort: sort || {
      createdAt: 'desc'
    },
    limit: limit || '10'
  }
}

export const candidatosDocumentosRepository = makeRepository<
  EdgesPage<AnexoProcessoSeletivoAdministrativoDocumentoDetail>,
  SaveAnexoProcessoSeletivoAdministrativoDocumentoInput,
  AnexoProcessoSeletivoAdministrativoDocumentoDetail,
  string
>({ resource: 'documentos-administrativos', mapQueryParams }, apiSauceInstance)
