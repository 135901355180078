import { useMotorista } from '../hooks/useMotorista'
import { useAppBar } from '../../../../hooks'
import { handleStateErrorsToRender } from '../../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { MotoristaForm } from './MotoristaForm'

export function MotoristaMasterDetail() {
  const [state, actions] = useMotorista()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      state,
      u => u.entity.id,
      u => u.entity.nomeCompleto
    )
  ])
  useFetchAndReset(actions.fetch, actions.reset)
  return handleStateErrorsToRender(state, <MotoristaForm />)
}
