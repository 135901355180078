import { makeUseFetchOne } from '@elentari/core/hooks/makeUseFetchOne'
import { AnexoCandidatoDocumentoDetail } from '../components/documents/types'
import { candidatosDocumentosRepository } from './documentosRepository'

export const useCandidatoDocumento = makeUseFetchOne<
  AnexoCandidatoDocumentoDetail,
  string
>({
  fetch: candidatosDocumentosRepository.fetchOne
})
