import { makeStyles } from "@material-ui/core"
import { ReactNode } from "react"

interface CurriculosBackgroundProps {
  children: ReactNode
  backgroundImage: string
}

export const CurriculosBackground = ({
  children,
  backgroundImage
}: CurriculosBackgroundProps) => {

  const useStyles = makeStyles(theme => ({
    root: {
      minHeight: '100vh',
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      opacity: '0.44',
    },
    rootContainer: {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.4',
      height: '100vh',
    },
    backgroundOverlay: {
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      position: 'absolute',
      overflowY: 'auto'
    },
  }))
  const classes = useStyles()

  return (
    <div>
      <div className={classes.backgroundOverlay}>
        <div className={classes.rootContainer}>
          <div className={classes.root}></div>
        </div>
      </div>
      <div className={classes.backgroundOverlay}>
        {children}
      </div>
    </div>
  )
}