import { useSnackbar } from "@elentari/core";
import { Grid, Typography } from "@material-ui/core"
import { Rating } from "@material-ui/lab";
import { useField } from "formik"
import { updateNotaClassificacao } from "../hooks/candidatoRepository";

type StarRateInputProps = {
  name: string
  label: string
  starsQuantity: number
  disabled: boolean
  idCandidato: string
}

export const StarRateInput = ({
  name,
  label,
  starsQuantity,
  disabled,
  idCandidato
} : StarRateInputProps) => {

  const [input, meta, helpers] = useField(name)
  const [, snackbarActions] = useSnackbar()
  
  return (
    <Grid container>
      <Grid item xs={12} style={{ textAlign: 'center'}}>
        <Rating
          name={name}
          max={starsQuantity}
          value={Number(input.value)}
          disabled={disabled}
          onChange={async (event, newValue) => {
            if (newValue && newValue !== input.value) {
              helpers.setValue(newValue)
              const response = await updateNotaClassificacao(idCandidato, newValue)

              if(!response || !response.ok) {
                snackbarActions.setMessage('Ocorreu um erro ao alterar a nota do candidato.')
              }
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          align="center"
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  )
}