import {
  IconButton, makeStyles, Tooltip
} from "@material-ui/core"
import LinkMD from '@material-ui/core/Link'
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined'
import { Link } from "react-router-dom"
import { useAbility } from "src/modules/login"
import { getAuthorizations } from "src/utils/crudAuthorization"

type CpfBloqueadoIconProps = {
  onClick?: () => void,
  idListaBloqueio?: string,
  alignCenter?: boolean
}

const useStyles = makeStyles(theme => ({
  warningIcon: {
    color: 'red',
  },
  centralizedWarningIcon: {
    color: 'red',
    padding: '12px'
  }
}))

export const CpfBloqueadoIcon = (props: CpfBloqueadoIconProps) => {
  const ability = useAbility()
  const authorization = getAuthorizations(ability, 'listaBloqueios')
  const style = useStyles()

  const linkToBloqueio = authorization.canCreate ? `/lista-bloqueios/${props.idListaBloqueio}` : null

  const Icon = <WarningOutlinedIcon className={props.alignCenter ? style.centralizedWarningIcon : style.warningIcon} />

  const tooltipTitle = `CPF presente na Lista de Bloqueios. ${linkToBloqueio ? 'Clique para mais detalhes.' : ''}`

  return (
    <Tooltip
      title={tooltipTitle}
      aria-label={tooltipTitle}
    >
      {props.onClick ? (
        <IconButton aria-label="cpf-bloqueado" onClick={props.onClick}>
          {Icon}
        </IconButton>
      ) : linkToBloqueio ? (
        <LinkMD
          component={Link}
          to={linkToBloqueio}
        >
          {Icon}
        </LinkMD>
      ) : (
        Icon
      )}
    </Tooltip>
  )
}