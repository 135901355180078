import { LinearProgress } from "@material-ui/core"
import { useEffect, useState } from "react"
import ReactHtmlParser from 'react-html-parser'
import { When } from "src/components"
import { useLoggedUser } from "src/modules/login/AuthProvider"
import { FormatedDate, isG10, searchTipoEntrevista, tipoAgendamento, tipoContratacao } from "src/utils"
import { fetchAgendamentoCandidatoById } from "../agendamento-candidato/hooks/agendamentoCandidatoRepository"
import { AgendamentosCandidatoDetail } from "../agendamento-candidato/types"
import { ModalDetailLabel } from "./ModalDetailLabel"

type AgendamentoCandidatoModalDetailsProps = {
  idAgendamento: string
}

export const AgendamentoCandidatoModalDetails = ({ idAgendamento }: AgendamentoCandidatoModalDetailsProps) => {
  const [agendamentoCandidato, setAgendamentoCandidato] = useState<AgendamentosCandidatoDetail>()
  const [loading, setLoading] = useState<boolean>(false)

  const userLogged = useLoggedUser()
  const userEmpresaIsG10 = isG10(userLogged?.empresa)

  useEffect(() => {
    async function getAgendamentoById(id: string) {
      setLoading(true)
      const response = await fetchAgendamentoCandidatoById(id)

      if (response && response.ok) {
        setAgendamentoCandidato(response.info)
      }
      setLoading(false)
    }

    if (idAgendamento) {
      getAgendamentoById(idAgendamento)
    }
  }, [idAgendamento])

  return (
    <>
      <When value={loading} equals>
        <LinearProgress />
      </When>
      {agendamentoCandidato && (
        <>
          <ModalDetailLabel
            title="Agendado por:"
            subtitle={agendamentoCandidato.agendadoPor}
          />
          <ModalDetailLabel
            title={userEmpresaIsG10 ? "Data do Processo:" : "Data para o qual foi Agendado:"}
            subtitle={FormatedDate(agendamentoCandidato.dataAgendamento)}
          />
          <ModalDetailLabel
            title={userEmpresaIsG10 ? "Data do Agendamento:" : "Data em que o Agendamento aconteceu:"}
            subtitle={agendamentoCandidato.createdAt && FormatedDate(agendamentoCandidato.createdAt)}
          />
          <ModalDetailLabel
            title="Tipo da Contratação:"
            subtitle={tipoContratacao.find(tipoContratacao => tipoContratacao.value === agendamentoCandidato.tipoContratacao)?.label ?? agendamentoCandidato.tipoContratacao}
          />
          <ModalDetailLabel
            title="Tipo da Entrevista:"
            subtitle={searchTipoEntrevista(agendamentoCandidato.tipoEntrevista)}
          />
          <ModalDetailLabel
            title="Tipo de Agendamento:"
            subtitle={tipoAgendamento.find(tipoAgendamento => tipoAgendamento.value === agendamentoCandidato.tipoAgendamento)?.label}
          />
          <ModalDetailLabel
            title="Comentário RH:"
            subtitle={agendamentoCandidato.comentarioRH && ReactHtmlParser(agendamentoCandidato.comentarioRH)}
            show={Boolean(agendamentoCandidato.comentarioRH)}
          />
        </>
      )}
    </>
  )
}