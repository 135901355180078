export interface AgendamentoAdministrativoDetail {
  id: string
  processoSeletivoAdministrativoId?: string
  agendadoPor: string
  dataCandidatoAgendado: string
  tipoEntrevista: string
  reagendamento: boolean
  compareceu: boolean
  comentario?: string
  empresaId: string
}

export interface AgendamentoFormData extends AgendamentoAdministrativoDetail {}

export interface SaveAgendamentoInput extends AgendamentoAdministrativoDetail {}

export interface SubmitValues {
  createdAt?: Date
  agendadoPor: string
  dataCandidatoAgendado?: string
  tipoEntrevista?: string
  reagendamento?: string
  compareceu?: boolean
  comentario?: string
}

export const AgendamentoColors = {
  EMAIL_GESTOR: '#fff176',
  EMAIL_GESTOR_DARK: '#D7C946'
}
