import { useSnackbar } from "@elentari/core"
import { Button, createStyles, Grid, IconButton, makeStyles, styled, Tooltip, Typography } from "@material-ui/core"
import BlockIcon from '@material-ui/icons/Block'
import { useState } from "react"
import ComplexDialog from 'src/components/ComplexDialog'
import RichTextField from 'src/components/Forms/RichTextField'
import { useAbility } from "src/modules/login"
import { MessagesYup } from 'src/modules/messages'
import { getAuthorizations } from "src/utils/crudAuthorization"
import { getOnlyDigits } from 'src/utils/utils'
import yupValidation from 'src/utils/yupValidation'
import * as yup from 'yup'
import { CreateSelecaoButton } from "../../components/CreateSelecaoButton"
import { reprovarCandidatoAgendamento } from "../../hooks/candidatoRepository"
import { bloquearCandidato } from '../../hooks/listaBloqueioRepository'
import { CandidatoDetail } from "../../types"

interface Callback {
  (data: ModalFormData): Promise<null | void> | void
}

type ModalFormData = {
  motivo?: string
  action?: ModalActions
}

type AgendamentosButtonsProps = {
  candidato: CandidatoDetail | undefined
  candidatoBloqueio: boolean
  agendamentoTipoContratacao: string | undefined
  agendamentoDataAgendamento: Date | undefined
  historicoState: { state: boolean, action: (prevState: boolean) => void }
}

type ModalActions = 'BLOQUEAR' | 'NONE'

const buttonsSpacing = 20

const StyledRejectButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '180px',
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: buttonsSpacing,
  },
  backgroundColor: '#e57373',
  color: 'white',
  '&:hover': {
    backgroundColor: '#e53935',
  }
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    right: '3rem',
    marginTop: '-0.8rem'
  },
}))

const bloquearColor: string = 'red'

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '160px'
      }
    },
    gridAlignCenter: {
      alignSelf: 'center'
    },
    gridBloquear: {
      textAlign: 'center',
      color: bloquearColor
    },
    blockButtonContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '80px'
    },
    approveButton: {
      [theme.breakpoints.up('md')]: {
        width: '180px',
      },
      [theme.breakpoints.up('md')]: {
        marginRight: buttonsSpacing,
      },
      backgroundColor: '#81c784',
      color: 'white',
      '&:hover': {
        backgroundColor: '#388e3c',
      }
    },
  })
)

export const AgendamentosButtons = ({
  candidato,
  candidatoBloqueio,
  agendamentoTipoContratacao,
  agendamentoDataAgendamento,
  historicoState
}: AgendamentosButtonsProps) => {

  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const classes = useStyles()
  const ability = useAbility()
  const listaBloqueioAuthorization = getAuthorizations(ability, 'listaBloqueios')

  const [confirmationDialog, setConfirmationDialog] = useState<{
    title: string
    acceptLabel: string
    rejectLabel: string
    open: boolean
    action: ModalActions
    callback: Callback
  }>({
    title: '',
    acceptLabel: '',
    rejectLabel: '',
    action: 'NONE',
    open: false,
    callback: (data: ModalFormData) => new Promise(() => { })
  })
  const TempoModal = 100

  const handleOpenDialog = (
    title: string,
    acceptLabel: string,
    rejectLabel: string,
    action: ModalActions,
    callback: Callback
  ) => {
    setConfirmationDialog({
      title,
      acceptLabel,
      rejectLabel,
      action,
      open: true,
      callback
    })
  }

  const handleCloseDialog = () => {
    setConfirmationDialog({
      ...confirmationDialog,
      open: false
    })

    setTimeout(() => {
      setConfirmationDialog({
        title: '',
        acceptLabel: '',
        rejectLabel: '',
        action: 'NONE',
        open: false,
        callback: (data: ModalFormData) => new Promise(() => { })
      })
    }, TempoModal)
  }

  const handleConfirmDialog = async (data: ModalFormData) => {
    const { callback } = confirmationDialog
    if (callback) {
      setLoading(true)
      await callback(data)
      setLoading(false)
    }
  }

  const dialogSchema = yup.object().shape({
    motivo: yup
      .string()
      .nullable()
      .when('action', {
        is: (action: ModalActions) => action === 'BLOQUEAR',
        then: yup
          .string()
          .trim()
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
      }),
    outrosMotivos: yup
      .string()
      .nullable()
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  })

  const handleBloquearCandidato = async (modalData: ModalFormData) => {
    try {
      if (candidato && candidato.cpf && modalData.motivo) {
        setLoading(true)
        const result = await bloquearCandidato({
          cpf: getOnlyDigits(candidato.cpf),
          motivo: modalData.motivo
        })

        if (result?.ok && result.info) {
          snackbarActions.setMessage('Candidato(a) bloqueado(a) com sucesso')
        } else {
          snackbarActions.setMessage(
            'Ocorreu um erro ao bloquear o(a) Candidato(a)'
          )
        }
        setLoading(false)
      } else {
        snackbarActions.setMessage('Não foi possível encontrar o candidato')
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  const handleReprovarAgendamento = async () => {
    try {
      if (candidato) {
        setLoading(true)
        const result = await reprovarCandidatoAgendamento(candidato.id)

        if (result?.ok && result.info) {
          snackbarActions.setMessage('Candidato(a) reprovado(a) com sucesso')
        } else {
          snackbarActions.setMessage(
            'Ocorreu um erro ao reprovar o(a) Candidato(a)'
          )
        }
        setLoading(false)
      } else {
        snackbarActions.setMessage('Não foi possível encontrar o candidato')
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  return (
    <>
      <Grid item container spacing={2}>
        {(!candidatoBloqueio && listaBloqueioAuthorization.canCreate) && (
          <StyledGrid item container justifyContent='flex-end' xs={12} md={2}>
            <Grid item xs={12} className={classes.gridBloquear}>
              <Tooltip title="Adicionar Candidato(a) na Lista de Bloqueio">
                <IconButton
                  aria-label="bloquear-candidato"
                  disabled={loading}
                  onClick={() => {
                    handleOpenDialog(
                      'Deseja adicionar este Candidato(a) na Lista de Bloqueio?',
                      'Bloquear',
                      'Voltar',
                      'BLOQUEAR',
                      handleBloquearCandidato
                    )
                  }}
                  style={{ color: bloquearColor }}
                >
                  <div className={classes.blockButtonContent}>
                    <BlockIcon />
                    <Typography>
                      Lista de Bloqueio
                    </Typography>
                  </div>
                </IconButton>
              </Tooltip>
            </Grid>
          </StyledGrid>
        )}

        <Grid item xs={12} md={2}>
          <Grid container style={{ height: '100%' }}>
            <CreateSelecaoButton
              buttonLabel="APROVADO AGENDAMENTO"
              dialog={{
                title: 'Deseja aprovar o agendamento deste Candidato(a)?',
                acceptLabel: 'Aprovar'
              }}
              className={classes.approveButton}
              candidatoId={candidato !== undefined && candidato.id}
              formValues={{
                tipoContratacao: agendamentoTipoContratacao,
                dataSelecao: agendamentoDataAgendamento
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={2}>
          <Grid container>
            <StyledRejectButton
              fullWidth
              variant="contained"
              disabled={loading}
              onClick={() => handleReprovarAgendamento()}
            >
              REPROVADO AGENDAMENTO
            </StyledRejectButton>
          </Grid>
        </Grid>

        <Grid item xs={12} md={8} className={classes.gridAlignCenter}>
          <Grid container justifyContent='center'>
            <Button
              fullWidth
              variant="outlined"
              color="default"
              className={classes.button}
              onClick={() => historicoState.action(!!!historicoState.state)}
            >
              Histórico
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ComplexDialog
        open={confirmationDialog.open}
        title={confirmationDialog.title}
        primaryActionButtonLabel={confirmationDialog.acceptLabel}
        closeActionButtonLabel={confirmationDialog.rejectLabel}
        handleClose={handleCloseDialog}
        primaryAction={handleConfirmDialog}
        validate={yupValidation(dialogSchema)}
        initialValues={{
          action: confirmationDialog.action
        }}
        disableBackdropClick={true}
        transitionDuration={TempoModal}
      >
        <Grid container spacing={2}>
          {{
            BLOQUEAR: (
              <Grid item xs={12}>
                <RichTextField name="motivo" label="Motivo" />
              </Grid>
            ),
            NONE: (
              <p>Ocorreu um erro ...</p>
            )
          }[confirmationDialog.action]}
        </Grid>
      </ComplexDialog>
    </>
  )
}