import { Link } from 'react-router-dom'
import * as dateFns from 'date-fns'

import * as yup from 'yup'
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Grid
} from '@material-ui/core'
import LinkMD from '@material-ui/core/Link'
import { useQueryParams } from '@elentari/core'
import { useGridsListWithoutPagination } from './hooks/useGrids'
import { GridFormData, SubmitValues, TotalGrids } from './types'

import { TableCellHead } from '../../components/TableCellHead'
import Spacer from '../../components/Spacer'
import Filter from '@elentari/components-eve/components/Filter'
import {
  Paper,
  TableContainer,
  NoResultsTable,
  When
} from '../../components'
import { useAbility } from '../login'
import { getAuthorizations } from '../../utils/crudAuthorization'
import { HandleErrorPage } from '../../utils/handleErrorPage'
import yupValidation from '../../utils/yupValidation'
import { MessagesYup } from '../messages'
import TextField from '@elentari/components-eve/final-form/TextField'
import Select from '@elentari/components-eve/final-form/Select'
import { tiposOperacao } from 'src/utils'

interface Props {
  list: GridFormData[]
  loading: boolean
  totalCount: number
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

const schema = yup.object().shape({
  name: yup.string().typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  email: yup.string().typeError(MessagesYup.MENSAGEM_CAMPO_STRING)
})

export const DemandaContratacaoTable = ({
  list,
  loading,
  totalCount,
  authorization
}: Props) => {

  const calculaQtdTitularesContratar = (motoristasAtuais: number, veiculos: number): number => {
    const qtdTitularesContratar = motoristasAtuais - veiculos;

    return (qtdTitularesContratar < 0) ? qtdTitularesContratar : 0;
  }

  const calculaQtdFeristasContratar = (feristasAtuais: number, qtdFeristasIdeal: number): number => {
    const qtdFeristasContratar = feristasAtuais - qtdFeristasIdeal;

    return (qtdFeristasContratar < 0) ? qtdFeristasContratar : 0;
  }

  const totalList: TotalGrids = list.reduce((totalList, gridAtual) => {
    const titularesQtdContratar = calculaQtdTitularesContratar(gridAtual.totalMotoristasAtual, gridAtual.veiculos);
    const feristasQtdContratar = calculaQtdFeristasContratar(gridAtual.totalFeristasAtual, gridAtual.feristasQtdIdeal);

    return {
      coordenador: totalList.coordenador,
      veiculos: totalList.veiculos + gridAtual.veiculos,
      feristasQtdIdeal: totalList.feristasQtdIdeal + gridAtual.feristasQtdIdeal,
      totalMotoristasAtual: totalList.totalMotoristasAtual + gridAtual.totalMotoristasAtual,
      totalFeristasAtual: totalList.totalFeristasAtual + gridAtual.totalFeristasAtual,
      titularesQtdContratar: totalList.titularesQtdContratar + titularesQtdContratar,
      feristasQtdContratar: totalList.feristasQtdContratar + feristasQtdContratar,
      totalQtdContratar: totalList.totalQtdContratar + (titularesQtdContratar + feristasQtdContratar)
    }
  }, {
    coordenador: new Set(list.map(grid => grid.coordenador)).size,
    veiculos: 0,
    feristasQtdIdeal: 0,
    totalMotoristasAtual: 0,
    totalFeristasAtual: 0,
    titularesQtdContratar: 0,
    feristasQtdContratar: 0,
    totalQtdContratar: 0
  });

  return (
    <>
      <GridFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount} disablePagination={true}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Nome" sort="nome" tooltip="Nome" />
                  <TableCellHead label="Operação" sort="operacao" tooltip="Operação" />
                  <TableCellHead label="Coordenador" sort="coordenador" tooltip="Coordenador" />
                  <TableCellHead label="Veículos" sort="veiculos" tooltip="Veículos" />
                  <TableCellHead label="Tit. Hoje" sort="totalMotoristasAtual" tooltip="Quantidade de Titulares Hoje" />
                  <TableCellHead label="Ferist. Ideal" sort="feristasQtdIdeal" tooltip="Quantidade de Feristas Ideal" />
                  <TableCellHead label="Ferist. Hoje" sort="totalFeristasAtual" tooltip="Quantidade de Feristas Hoje" />
                  <TableCellHead label="Tit. Contratar" tooltip="Quantidade de Titulares a Contratar" />
                  <TableCellHead label="Ferist. Contratar" tooltip="Quantidade de Feristas a Contratar" />
                  <TableCellHead label="Total Contratar" tooltip="Quantidade Total a Contratar (Titulares + Feristas)" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(grid => (
                  <TableRow hover key={grid.id}>
                    <TableCell>
                      {!authorization || authorization.canUpdate ? (
                        <When
                          value={!authorization || authorization.canUpdate}
                          equals
                        >
                          <LinkMD component={Link} to={`/grids/${grid.id}`}>
                            {grid.nome}
                          </LinkMD>
                        </When>
                      ) : (
                        grid.nome
                      )}
                    </TableCell>
                    <TableCell>{grid.operacao}</TableCell>
                    <TableCell>{grid.coordenador}</TableCell>
                    <TableCell>{grid.veiculos}</TableCell>
                    <TableCell>{grid.totalMotoristasAtual}</TableCell>
                    <TableCell>{grid.feristasQtdIdeal}</TableCell>
                    <TableCell>{grid.totalFeristasAtual}</TableCell>
                    <TableCell>{calculaQtdTitularesContratar(grid.totalMotoristasAtual, grid.veiculos)}</TableCell>
                    <TableCell>{calculaQtdFeristasContratar(grid.totalFeristasAtual, grid.feristasQtdIdeal)}</TableCell>
                    <TableCell>
                      {
                        calculaQtdTitularesContratar(grid.totalMotoristasAtual, grid.veiculos)
                        +
                        calculaQtdFeristasContratar(grid.totalFeristasAtual, grid.feristasQtdIdeal)
                      }
                    </TableCell>
                  </TableRow>
                ))}
                {totalList &&
                  <TableRow hover key="total">
                    <TableCell>
                      TOTAL
                    </TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>{totalList.coordenador}</TableCell>
                    <TableCell>{totalList.veiculos}</TableCell>
                    <TableCell>{totalList.totalMotoristasAtual}</TableCell>
                    <TableCell>{totalList.feristasQtdIdeal}</TableCell>
                    <TableCell>{totalList.totalFeristasAtual}</TableCell>
                    <TableCell>{totalList.titularesQtdContratar}</TableCell>
                    <TableCell>{totalList.feristasQtdContratar}</TableCell>
                    <TableCell>{totalList.totalQtdContratar}</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
    </>
  )
}

type GridQueryFilter = {
  createdAt?: { gt: string }
  [key: string]: any
}

const GridFilter = () => {
  const { clear, push, initialValues } = useQueryParams<GridQueryFilter>()

  const handleSubmitFilter = (values: SubmitValues) => {
    if (values.operacao === null) {
      delete values.operacao
    }
    if (values.createdAt) {
      const dateFormated = dateFns.parse(
        String(values.createdAt),
        'dd/MM/yyyy',
        new Date()
      )

      return push({
        ...values,
        createdAt: {
          gt: dateFormated.toISOString()
        }
      })
    }
    push({
      ...values,
      createdAt: undefined
    })
  }

  const format = (initialValues: GridQueryFilter) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues,
        createdAt: dateFns.format(
          dateFns.parseISO(initialValues.createdAt.gt),
          'dd/MM/yyyy'
        )
      }
    }
    return initialValues
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter(values)}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField fullWidth name="nome" label="Nome" />
        </Grid>
        <Grid item xs>
          <Select
            fullWidth
            name="operacao"
            label="Operação"
            items={tiposOperacao}
          />
        </Grid>
        <Grid item xs>
          <TextField fullWidth name="coordenador" label="Coordenador" />
        </Grid>
      </Grid>
    </Filter>
  )
}

const DemandaContratacao = () => {
  const [state, actions] = useGridsListWithoutPagination()
  const ability = useAbility()

  const authorization = getAuthorizations(ability, 'grids')

  switch (state.tag) {
    case 'empty':
      return (
        <DemandaContratacaoTable
          totalCount={0}
          list={[]}
          loading={true}
        />
      )
    case 'with-data':
      return (
        <DemandaContratacaoTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          authorization={authorization}
        />
      )
    case 'error':
      return <HandleErrorPage state={state.data} />
  }
}

export default DemandaContratacao
