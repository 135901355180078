import { makeRepository } from "@elentari/core"
import { EdgesPage } from "@elentari/core/types"
import { ParsedQs } from "qs"
import { apiSauceInstance } from "src/services/api"
import { paginationParams } from "src/utils/paginationQueryParams"
import { AgendamentosCandidatoDetail, AgendamentosCandidatoFormData, SaveAgendamentoCandidatoInput } from "../types"

const mapQueryParamsAdministrativo = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params
  return {
    incluirBloqueados: 'false',
    sort: { dataAgendamento: 'desc' },
    ...params,
    ...paginationParams(rest),
    tipoCandidato: 'ADMINISTRATIVO',
    statusCandidato: 'AGENDADO',
    onlyNewest: 'true'
  }
}
const mapQueryParamsMotorista = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params
  return {
    incluirBloqueados: 'false',
    sort: { dataAgendamento: 'desc' },
    ...params,
    ...paginationParams(rest),
    tipoCandidato: 'MOTORISTA',
    statusCandidato: 'AGENDADO',
    onlyNewest: 'true'
  }
}

export const agendamentoCandidatoAdministrativoRepository = makeRepository<
  EdgesPage<AgendamentosCandidatoDetail>,
  SaveAgendamentoCandidatoInput,
  AgendamentosCandidatoDetail,
  string
>(
  {
    resource: 'agendamentos-candidato',
    mapQueryParams: mapQueryParamsAdministrativo
  },
  apiSauceInstance
)
export const agendamentoCandidatoMotoristaRepository = makeRepository<
  EdgesPage<AgendamentosCandidatoDetail>,
  SaveAgendamentoCandidatoInput,
  AgendamentosCandidatoDetail,
  string
>(
  {
    resource: 'agendamentos-candidato',
    mapQueryParams: mapQueryParamsMotorista
  },
  apiSauceInstance
)

export const agendarCandidato = async (data: AgendamentosCandidatoFormData) => {
  const response = await apiSauceInstance.post<any>(`/agendamentos-candidato`, data)

  const { data: info, ok, status } = response

  return {
    info,
    ok,
    status
  }
}

export const updateAgendamento = async (agendamentoId: string, data: AgendamentosCandidatoFormData) => {
  const response = await apiSauceInstance.put<any>(`/agendamentos-candidato/${agendamentoId}`, data)

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const fetchAgendamentosCandidatoFromCandidatoId = async (candidatoId: string) => {
  const response = await apiSauceInstance.get<EdgesPage<AgendamentosCandidatoDetail>>(
    `/agendamentos-candidato`,
    {
      candidatoId
    }
  )

  return response
}

export const fetchAgendamentoCandidatoById = async (id: string) => {
  const response = await apiSauceInstance.get<AgendamentosCandidatoDetail>(`/agendamentos-candidato/${id}`)

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const fetchListaAgendamento = async (filterString: string) => {
  const response = await apiSauceInstance.get<string[]>(
    `/agendamentos-candidato/lista-agendados?${filterString}`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}