import { useState } from "react"
import ComplexDialog from "src/components/ComplexDialog"
import { ShowDetailsIcon } from "src/components/Icons/ShowDetails"
import { TriagemDetail } from "../types"
import HistoricoCandidato from "./HistoricoCandidato"

type ModalHistoricoProps = {
  candidatoId: string
  candidatoType: 'MOTORISTA' | 'ADMINISTRATIVO'
  triagens: TriagemDetail[] | undefined
  disabled: boolean
}

export const ModalHistorico = ({
  candidatoId,
  candidatoType,
  triagens,
  disabled
}: ModalHistoricoProps) => {

  const TempoModal = 100
  const [confirmationDialog, setConfirmationDialog] = useState<{
    title: string
    open: boolean
    candidatoId?: string
    triagens?: TriagemDetail[]
  }>({
    title: '',
    open: false,
  })
  const handleOpenDialog = (
    candidatoId?: string,
    triagens?: TriagemDetail[]
  ) => {
    setConfirmationDialog({
      title: 'Histórico',
      open: true,
      candidatoId,
      triagens
    })
  }

  const closeDialog = () => {
    setConfirmationDialog({
      ...confirmationDialog,
      open: false
    })

    setTimeout(() => {
      setConfirmationDialog({
        title: '',
        open: false
      })
    }, TempoModal)
  }

  return (
    <>
      <ShowDetailsIcon
        onClick={() => handleOpenDialog(candidatoId, triagens)}
        disabled={disabled}
      />
      <ComplexDialog
        open={!!confirmationDialog.open}
        handleClose={closeDialog}
        primaryAction={() => { }}
        title={confirmationDialog.title}
        hidePrimaryActionButton={true}
        closeActionButtonLabel="Voltar"
        disableBackdropClick={true}
        transitionDuration={TempoModal}
        maxWidth='lg'
        hideTitle
      >
        <HistoricoCandidato
          open={!!confirmationDialog.open}
          candidatoId={confirmationDialog.candidatoId}
          Triagens={confirmationDialog.triagens}
        />
      </ComplexDialog>
    </>
  )
}