import { makeStyles, Typography } from "@material-ui/core"

interface ResultadoSelecaoLabelProps {
  label: string
  disabled?: boolean
}


const useStyles = makeStyles(theme => ({
  backgroundCell: {
    backgroundColor: theme.palette.grey[300],
    color: 'black',
    padding: 2
  },
  disabledBackgroundCell: {
    backgroundColor: 'rgba(0, 0, 0, 0.26)',
    color: 'white',
    padding: 2
  }
}))

export const ResultadoSelecaoLabel = ({
  label,
  disabled
}: ResultadoSelecaoLabelProps) => {
  const classes = useStyles()

  return (
    <Typography variant="overline" className={disabled ? classes.disabledBackgroundCell : classes.backgroundCell}>
      {label}
    </Typography>
  )
}