import { Grid, LinearProgress } from '@material-ui/core'
import TableContainerMD from '@material-ui/core/TableContainer'
import { Pagination } from '@material-ui/lab'
import { Observer } from "mobx-react-lite"
import { ReactNode } from 'react'
import { When } from 'src/components'
import Spacer from 'src/components/Spacer'
import historicoCandidatoStore from './store'

interface HistoricoTableProps {
  totalCount: number
  loading: boolean
  children: ReactNode
}

export const HistoricoTable = ({
  totalCount = 0,
  loading,
  children
}: HistoricoTableProps) => {

  function handlePageChange(event: React.ChangeEvent<unknown>, page: number) {
    historicoCandidatoStore.setPage(page)
  }

  return (
    <TableContainerMD>
      <When value={loading} equals>
        <LinearProgress />
      </When>
      {children}
      <Spacer y={2} />
      <Grid container justify="center">
        <Grid item>
          <Observer>
            {() => (
              <Pagination
                count={Math.ceil(
                  totalCount / 10
                )}
                page={historicoCandidatoStore.page}
                onChange={handlePageChange}
              />
            )}
          </Observer>
        </Grid>
      </Grid>
      <Spacer y={2} />
    </TableContainerMD>
  )
}