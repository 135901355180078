export type StatusTreinamento = 'PENDENTE_APROVACAO' | 'APROVADO' | 'REPROVADO' | 'EM_ANDAMENTO';

export const statusTreinamento = [
  { value: 'PENDENTE_APROVACAO', label: 'Pendente de Aprovação' },
  { value: 'APROVADO', label: 'Aprovado' },
  { value: 'REPROVADO', label: 'Reprovado' },
  { value: 'EM_ANDAMENTO', label: 'Em Andamento' }
]

export interface TreinamentoDetail {
  id: string;
  nome: string;
  descricao: string;
  cargaHoraria: number;
  valorPorPessoa: number;
  status: StatusTreinamento;
  empresaId: string;
}

export interface TreinamentoFormData extends TreinamentoDetail{}

export interface SaveTreinamentoInput extends TreinamentoDetail{}

export interface SubmitValues{
  createdAt?: Date,
  nome?: string
  status?: StatusTreinamento
  cargaHoraria?: number;
  valorPorPessoa?: number;
}

export interface CandidatoTreinamentoDetail {
  id: string
  nota: string
  comentario: string
  treinamentoId: string
  candidatoId: string
  candidatoFK: {
    nomeCompleto: string
  }
  treinamentoFK: {
    nome: string
  }
  empresaId: string
  createdAt: string
}