import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { paginationParams } from '../../../../utils/paginationQueryParams'
import { ParsedQs } from 'qs'
import { CargoAreaDetail, SaveCargoAreaInput } from '../types'
import { apiSauceInstance } from 'src/services/api'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params
  return { ...paginationParams(rest), ...params }
}

export const CargoAreaRepository = makeRepository<
  EdgesPage<CargoAreaDetail>,
  SaveCargoAreaInput,
  CargoAreaDetail,
  string
>(
  {
    resource: 'cargo-areas',
    mapQueryParams
  },
  apiSauceInstance
)
