import { Checkbox, Grid, makeStyles, Typography } from "@material-ui/core"
import { useState } from "react"
import { updateSelecaoCandidato } from "../../hooks/selecaoCandidatoRepository"
import { TipoContratacao } from "../../types"
import { SelectSelecao } from "./SelectSelecao"
import { useSnackbar } from "@elentari/core"
import subEmpresasStore from "../store/subEmpresasList"

const useStyles = makeStyles(theme => ({
  alignContent: {
    display: 'flex',
    alignItems: 'center'
  },
  disabledLabel: {
    color: 'rgba(0, 0, 0, 0.40)'
  },
  minWidth: {
    minWidth: '200px'
  }
}))

type TipoContratacaoSelecaoProps = {
  selecaoCandidatoId: string | undefined
  tipoContratacao: TipoContratacao | undefined
  subEmpresaId?: string
  disabled: boolean
}

export const TipoContratacaoSelecao = ({
  selecaoCandidatoId,
  tipoContratacao,
  subEmpresaId,
  disabled
}: TipoContratacaoSelecaoProps) => {
  const [tipoContratacaoValue, setTipoContratacaoValue] = useState<TipoContratacao | undefined>(tipoContratacao)
  const [subEmpresaValue, setSubEmpresaValue] = useState<string | undefined>(subEmpresaId)
  const [, snackbarActions] = useSnackbar()
  const classes = useStyles()

  const handleOnChange = async (value: any, fieldName: 'tipoContratacao' | 'subEmpresaId', fieldValue: string | undefined) => {
    if (fieldValue === value) return

    if (selecaoCandidatoId) {
      const response = await updateSelecaoCandidato(selecaoCandidatoId, {
        [fieldName]: value
      })

      if (response && response.ok) {
        if (fieldName === 'tipoContratacao')
          setTipoContratacaoValue(value)
        if (fieldName === 'subEmpresaId')
          setSubEmpresaValue(value)
      } else {
        snackbarActions.setMessage(
          `Ocorreu um erro ao alterar o ${fieldName} da Seleção do(a) Candidato(a)`
        )
      }
    } else {
      snackbarActions.setMessage(
        `O Candidato(a) não possui nenhuma Seleção Cadastrada`
      )
    }
  }

  return (
    <Grid container className={classes.minWidth}>
      <Grid item xs={12}>
        <Typography align="center" className={disabled || !selecaoCandidatoId ? classes.disabledLabel : undefined}>Tipo de Contratação</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={10} className={classes.alignContent}>
            <Typography align="center" className={disabled || !selecaoCandidatoId ? classes.disabledLabel : undefined}>Curso Formação</Typography>
          </Grid>
          <Grid item xs={2}>
            <Checkbox
              checked={tipoContratacaoValue !== undefined && tipoContratacaoValue === 'CURSO_FORMACAO'}
              value={'CURSO_FORMACAO'}
              onChange={(event) => handleOnChange(event.target.value, 'tipoContratacao', tipoContratacaoValue)}
              disabled={disabled || !selecaoCandidatoId}
            />
          </Grid>
          <Grid item xs={10} className={classes.alignContent}>
            <Typography align="center" className={disabled ? classes.disabledLabel : undefined}>Contratação Direta</Typography>
          </Grid>
          <Grid item xs={2}>
            <Checkbox
              checked={tipoContratacaoValue !== undefined && tipoContratacaoValue === 'DIRETA'}
              value={'DIRETA'}
              onChange={(event) => handleOnChange(event.target.value, 'tipoContratacao', tipoContratacaoValue)}
              disabled={disabled || !selecaoCandidatoId}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SelectSelecao
          label="Empresa"
          options={
            subEmpresasStore.getSubEmpresasList().map(subEmpresa => {
              return {
                label: subEmpresa.nome,
                value: subEmpresa.id
              }
            })
          }
          value={subEmpresaValue}
          onChange={(event) => handleOnChange(event.target.value, 'subEmpresaId', subEmpresaValue)}
          disabled={disabled || !selecaoCandidatoId}
        />
      </Grid>
    </Grid>
  )
}