import { Box, Container, Grid, Typography } from "@material-ui/core"

interface MessageCardProps {
  title: string
  subtitle: string
  logo: JSX.Element
}

export const MessageCard = ({
  title,
  subtitle,
  logo
}: MessageCardProps) => {
  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom={5}
      >
        {logo}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            color="primary"
            align="center"
            component="h1"
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
          xs={12}
        >
          <Typography variant="subtitle2">
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}