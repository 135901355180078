import { CardContent, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core"
import AssignmentIndOutlined from "@material-ui/icons/AssignmentIndOutlined"
import EditIcon from '@material-ui/icons/Edit'
import { useState } from "react"
import { useHistory } from "react-router-dom"
import ComplexDialog from "src/components/ComplexDialog"
import { AtualFuncionarioIcon } from "src/components/Icons/AtualFuncionarioIcon"
import { CpfBloqueadoIcon } from "src/components/Icons/CpfBloqueadoIcon"
import { ExFuncionarioIcon } from "src/components/Icons/ExFuncionarioIcon"
import { CandidatoDetail } from "../types"
import ReactHtmlParser from 'react-html-parser'

const useStyles = makeStyles(theme => ({
  containerLocation: {
    display: 'flex',
    flexDirection: 'row'
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fill-available'
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right'
  },
  returnButton: {
    top: '-1rem',
    left: '-1rem'
  }
}))

interface CandidatoCardContentProps {
  candidato: CandidatoDetail
  showIcons: boolean
}

export const CandidatoCardContent = ({
  candidato,
  showIcons
}: CandidatoCardContentProps) => {

  const style = useStyles()
  const history = useHistory()
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false)

  const openDialog = () => {
    setConfirmationDialogOpen(true)
  }

  const closeDialog = () => {
    setConfirmationDialogOpen(false)
  }

  const handleEditCandidato = () => {
    if (candidato.tipo === 'MOTORISTA') {
      history.push('/motorista/' + candidato.id)
    } else {
      history.push('/administrativo/' + candidato.id)
    }
  }

  return (
    <>
      <CardContent>
        <div className={style.containerLocation}>
          <div className={style.dataContainer}>
            <Typography
              variant="h5"
              style={{ fontWeight: 'bold' }}
            >
              {candidato.nomeCompleto}
            </Typography>
            <Typography
              style={{ paddingBottom: 20 }}
              color="textSecondary"
            >
              {candidato.tipo}
            </Typography>

            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              align="left"
            >
              <strong>CPF: </strong> {candidato.cpf}
            </Typography>

            <div className={style.containerLocation}>
              {candidato.telefone && (
                <Typography
                  variant="body2"
                  style={{ marginRight: 10 }}
                  color="textSecondary"
                  component="p"
                  align="left"
                >
                  <strong>Telefone: </strong> {candidato.telefone}
                </Typography>
              )}
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="left"
              >
                <strong>Celular: </strong> {candidato.celular}
              </Typography>
            </div>
          </div>
          {showIcons && (
            <div className={style.iconContainer}>
              <Tooltip
                placement="top"
                title="Acessar a Página do Candidato"
                onClick={() =>
                  history.push(`/candidato/${candidato.id}`)
                }
              >
                <IconButton>
                  <AssignmentIndOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Editar Candidato(a)">
                <IconButton
                  aria-label="edit"
                  onClick={handleEditCandidato}
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              {candidato.idListaBloqueio && (
                <CpfBloqueadoIcon onClick={openDialog} />
              )}
              {candidato.atualFuncionario && (
                <AtualFuncionarioIcon />
              )}
              {candidato.exFuncionario && <ExFuncionarioIcon />}
            </div>
          )}
        </div>
      </CardContent>
      <ComplexDialog
        open={confirmationDialogOpen}
        closeActionButtonLabel="Não"
        handleClose={closeDialog}
        primaryAction={() => {
          history.push(
            `/lista-bloqueios/${candidato.idListaBloqueio}`
          )
        }}
        title="Deseja acessar os detalhes na página de Lista Bloqueio?"
        primaryActionButtonLabel="Acessar"
      >
        {candidato.idListaBloqueio &&
          candidato.motivoListaBloqueio && (
            <div>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="left"
              >
                <strong>CPF Bloqueado:</strong> {candidato.cpf}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="left"
              >
                <strong>Motivo:</strong>{' '}
                {ReactHtmlParser(candidato.motivoListaBloqueio)}
              </Typography>
            </div>
          )}
      </ComplexDialog>
    </>
  )
}