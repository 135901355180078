import Filter from '@elentari/components-eve/components/Filter'
import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import { EdgesPage } from '@elentari/core/types'
import { Button, Checkbox, Tooltip, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import LinkMD from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import EventIcon from '@material-ui/icons/Event'
import { makeStyles } from '@material-ui/styles'
import { observer } from 'mobx-react-lite'
import QueryString from 'qs'
import { useEffect, useState } from 'react'
import { FormSpy } from 'react-final-form'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { CpfBloqueadoIcon } from 'src/components/Icons/CpfBloqueadoIcon'
import { useAppBar } from 'src/hooks'
import { fetchAllLabelsCaracteristicas } from 'src/modules/candidato/hooks/labelsRepository'
import { apiSauceInstance } from 'src/services/api'
import { monetaryFormatter } from 'src/utils/formatters'
import * as yup from 'yup'
import {
  NoResultsTable,
  Paper,
  TableContainer,
  When
} from '../../../components'
import RFFAutocomplete from '../../../components/Forms/RFFAutocomplete'
import Spacer from '../../../components/Spacer'
import { TableCellHead } from '../../../components/TableCellHead'
import { Option } from '../../../hooks/useAutoComplete'
import { FormatedDate } from '../../../utils'
import lugares from '../../../utils/cidades.json'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import candidatosStore from '../store/store'
import { CandidatoSelecaoDetail } from '../types'

interface Props {
  list: { node: CandidatoSelecaoDetail }[]
  loading: boolean
  totalCount: number
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

const schema = yup.object().shape({
  name: yup.string().typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  email: yup.string().typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  dataNascimento: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
})

const useStyles = makeStyles(theme => ({
  warningIcon: {
    color: 'red'
  },
  treinamentoContainer: {
    padding: '1rem'
  }
}))

export const AdministrativoTable = observer(
  ({ list, loading, totalCount, authorization }: Props) => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()

    function defaultHandleGoBack() {
      const newLocation = location.pathname
        .split('/')
        .filter(Boolean)
        .slice(0, -1)
        .join('/')

      history.push(`/${newLocation}`)
    }

    function handleSelectCandidatos() {
      candidatosStore.selecionarCandidatos()
      const newLocation = location.pathname
        .split('/')
        .filter(Boolean)
        .slice(0, -1)
        .join('/')

      history.push(`/${newLocation}`)
    }

    return (
      <>
        <Paper>
          <Grid container spacing={2} className={classes.treinamentoContainer}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                style={{ fontWeight: 'bold' }}
                gutterBottom
              >
                Treinamento
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="left"
              >
                <strong>Nome: </strong>
                {candidatosStore.treinamento?.nome}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="left"
              >
                <strong>Carga Horária: </strong>
                {candidatosStore.treinamento?.cargaHoraria}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="left"
              >
                <strong>Valor por Pessoa: </strong>
                {monetaryFormatter.format(
                  Number(candidatosStore.treinamento?.valorPorPessoa)
                )
                }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                align="left"
              >
                <strong>Obs: </strong> Candidatos que já se inscreveram para este treinamento não serão listados
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Spacer y={4} />
        <AdministrativoFilter />
        <Spacer y={4} />
        <Paper>
          <TableContainer loading={loading} totalCount={totalCount}>
            {list.length ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellHead label="Selecionar" />
                    <TableCellHead label="Nome Completo" sort="nomeCompleto" />
                    <TableCellHead label="E-mail" sort="email" />
                    <TableCellHead
                      label="Data de Nascimento"
                      sort="dataNascimento"
                    />
                    <TableCellHead label="Celular" sort="celular" />
                    <TableCellHead label="Cidade" sort="cidade" />
                    <TableCellHead label="UF" sort="uf" />
                    <When
                      value={!authorization || authorization.canCreate}
                      equals
                    >
                      <TableCell></TableCell>
                    </When>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map(administrativo => (
                    <TableRow hover key={administrativo.node.id}>
                      <TableCell>
                        <Checkbox
                          checked={candidatosStore.isChecked(
                            administrativo.node.id
                          )}
                          onChange={(_event, checked) =>
                            candidatosStore.selectCandidato(
                              administrativo.node,
                              checked
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {!authorization || authorization.canUpdate ? (
                          <When
                            value={!authorization || authorization.canUpdate}
                            equals
                          >
                            <LinkMD
                              component={Link}
                              to={`/candidato/${administrativo.node.id}`}
                            >
                              {administrativo.node.nomeCompleto}
                            </LinkMD>
                          </When>
                        ) : (
                          administrativo.node.id
                        )}
                      </TableCell>
                      <TableCell>{administrativo.node.email}</TableCell>
                      <TableCell>
                        {administrativo.node.dataNascimento &&
                          FormatedDate(administrativo.node.dataNascimento)}
                      </TableCell>
                      <TableCell>{administrativo.node.celular}</TableCell>
                      <TableCell>{administrativo.node.cidade}</TableCell>
                      <TableCell>{administrativo.node.uf}</TableCell>
                      <When value={!authorization || authorization.canUpdate} equals>
                        <TableCell>
                          {administrativo.node.idListaBloqueio && (
                            <CpfBloqueadoIcon idListaBloqueio={administrativo.node.idListaBloqueio} />
                          )}
                        </TableCell>
                      </When>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <NoResultsTable loading={loading} />
            )}
          </TableContainer>
          <Grid item xs={12}>
            <Grid justify="flex-end" container spacing={2}>
              <Grid item style={{ width: 160 }}>
                <Button
                  fullWidth
                  type="button"
                  variant="outlined"
                  onClick={defaultHandleGoBack}
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item style={{ width: 160 }}>
                <Button
                  fullWidth
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleSelectCandidatos}
                >
                  Selecionar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </>
    )
  }
)

type AdministrativoQueryFilter = {
  createdAt?: { gt: string }
  candidato_antigo: boolean
  [key: string]: any
}

const AdministrativoFilter = () => {
  const [uf, setUF] = useState<Option[]>([])
  const [labelCaracteristicas, setLabelCaracteristicas] = useState<Option[]>([])

  const { clear, push, initialValues } =
    useQueryParams<AdministrativoQueryFilter>()

  useEffect(() => {
    async function fetchAllLabels() {
      const response = await fetchAllLabelsCaracteristicas()
      if (response?.ok) {
        setLabelCaracteristicas(
          response.info.edges.map(label => {
            return {
              id: label.node.id,
              name: label.node.descricao
            }
          })
        )
      }
    }
    fetchAllLabels()
  }, [])

  useEffect(() => {
    const ufs = lugares.estados.map(locais => {
      return {
        id: locais.sigla,
        name: locais.sigla
      }
    })
    setUF(ufs)
  }, [])

  const getCidadesFromUf = (uf: string): Option[] => {
    const findUF = lugares.estados.find(cidade => cidade.sigla === uf)

    if (findUF) {
      const cidades = findUF.cidades.map(cidade => {
        return {
          id: cidade,
          name: cidade
        }
      })

      return cidades
    } else {
      return []
    }
  }

  useEffect(() => {
    return () => {
      candidatosStore.resetChecados()
    }
  }, [])

  const handleSubmitFilter = (values: any) => {
    if (values.dataNascimento) {
      values.dataNascimento = new Date(values.dataNascimento).toISOString()
    }
    push({
      ...values
    })
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={initialValues}
      onClear={clear}
      onSubmit={values => handleSubmitFilter(values)}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item md={4} sm={12}>
          <TextField fullWidth name="name" label="Nome Completo" />
        </Grid>
        <Grid item md={4} sm={12}>
          <TextField fullWidth name="email" label="E-mail" />
        </Grid>
        <Grid item md={4} sm={12}>
          <DatePicker
            fullWidth
            name="dataNascimento"
            label="Data de Nascimento"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <RFFAutocomplete
            name="labelCaracteristicas"
            label="Características"
            options={labelCaracteristicas}
          />
        </Grid>
        <Grid item md={4} sm={6}>
          <RFFAutocomplete name="uf" label="UF" options={uf} />
        </Grid>
        <Grid item md={4} sm={6}>
          <FormSpy
            render={({ values }) => (
              <RFFAutocomplete name="cidade" label="Cidade" options={getCidadesFromUf(values.uf)} disabled={!values.uf} />
            )}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

type APIResponseCandidatos = {
  edges: { node: CandidatoSelecaoDetail }[]
  totalCount: number
  loading: boolean
}

const Administrativos = observer(() => {
  const [candidatosState, setCandidatosState] = useState<APIResponseCandidatos>(
    {} as APIResponseCandidatos
  )
  const { search, pathname } = useLocation()
  const [, appBarActions] = useAppBar()
  const history = useHistory()

  if (!candidatosStore.treinamento) {
    history.push(`/inscricao-candidatos-treinamento`)
  }

  useEffect(() => {
    appBarActions.setTitle('Seleção de Candidatos(as)')
  }, [])

  useEffect(() => {
    async function loadCandidatosAdministrativos() {
      try {
        setCandidatosState({
          ...candidatosState,
          loading: true
        })

        const params: { [key: string]: any } = {
          sort: { createdAt: 'desc' },
          ...QueryString.parse(search.substring(1))
        }

        const { data } = await apiSauceInstance.get<
          EdgesPage<CandidatoSelecaoDetail>
        >(`/candidato?${QueryString.stringify(params)}`, {
          tipo: 'ADMINISTRATIVO',
          treinamentoId: candidatosStore.treinamento?.id,
          skip: params.page ? (+params.page - 1) * 10 : 0,
          limit: 10
        })
        if (data) {
          setCandidatosState({
            totalCount: data.totalCount,
            edges: data.edges,
            loading: false
          })
        }
      } catch (error) { }
    }
    loadCandidatosAdministrativos()
  }, [pathname, search])

  return (
    <>
      {candidatosState.loading === false ? (
        candidatosState.totalCount > 0 ? (
          <AdministrativoTable
            list={candidatosState.edges}
            totalCount={candidatosState.totalCount}
            loading={candidatosState.loading}
          />
        ) : (
          <AdministrativoTable list={[]} totalCount={0} loading={false} />
        )
      ) : (
        <AdministrativoTable totalCount={0} list={[]} loading={true} />
      )}
    </>
  )
})

export default Administrativos
