import { differenceInYears, format } from 'date-fns'
import ptBr from 'date-fns/locale/pt-BR'

export function FormatedDate(data: string | undefined, dayString?: boolean): string {
  if (data) {
    const date = new Date(data)

    if (dayString) {
      const formattedDate = format(date, 'PPPP', {
        locale: ptBr
      })
      return formattedDate
    } else {
      const formattedDate = format(date, 'dd/MM/yyyy', {
        locale: ptBr
      })
      return formattedDate
    }
  }
  return '-'
}

export const getIdade = (dataNascimento: string): string => {
  if (dataNascimento !== '') {
    const dataDoNascimento = new Date(dataNascimento)

    const age = differenceInYears(new Date(), dataDoNascimento)
    if (!isNaN(age)) {
      return `${age} anos`
    }
  }
  return `0 anos`
}

export const getValueFormatted = (
  initDate: Date,
  endDate: Date,
  value: number
): string => {
  if (initDate && endDate) {
    const years = differenceInYears(new Date(endDate), new Date(initDate))
    const month = Math.floor(value % 12)

    if (years > 0) {
      return `${years} ano${years > 1 ? 's' : ''} ${month === 0 ? '' : 'e ' + month + ' meses'
        }`
    } else {
      return `${value} meses`
    }
  }
  return '0 meses'
}

export const isCellPhone = (number: string): boolean => {
  const phoneDigits = number.replace(/[^\d]+/g, '')
  return phoneDigits[2] === '9'
}

export const isAnalistaRh = (rolesInSystem: string[] | undefined): boolean => {
  if (!rolesInSystem) return false
  return rolesInSystem.includes("ANALISTA_RH")
}

export const isG10 = (empresa: string | undefined): boolean => {
  if (!empresa) return false
  return empresa === 'G10'
}

export const estadoCivil = [
  {
    name: 'Casado(a)',
    id: 'CASADO'
  },
  {
    name: 'Divorciado(a)',
    id: 'DIVORCIADO'
  },
  {
    name: 'Solteiro(a)',
    id: 'SOLTEIRO'
  },
  {
    name: 'Viúvo(a)',
    id: 'VIUVO'
  },
  {
    name: 'União Estável',
    id: 'UNIAO_ESTAVEL'
  }
]

export const categoriaCNH = [
  {
    label: 'A',
    value: 'A'
  },
  {
    label: 'B',
    value: 'B'
  },
  {
    label: 'A/B',
    value: 'AB'
  },
  {
    label: 'C',
    value: 'C'
  },
  {
    label: 'A/C',
    value: 'AC'
  },
  {
    label: 'D',
    value: 'D'
  },
  {
    label: 'A/D',
    value: 'AD'
  },
  {
    label: 'E',
    value: 'E'
  },
  {
    label: 'A/E',
    value: 'AE'
  }
]

export const grauEscolaridade = [
  {
    label: 'Ensino Fundamental Incompleto',
    value: 'FUNDAMENTAL_INCOMPLETO'
  },
  {
    label: 'Ensino Fundamental Completo',
    value: 'FUNDAMENTAL_COMPLETO'
  },
  {
    label: 'Ensino Médio Incompleto',
    value: 'MEDIO_INCOMPLETO'
  },
  {
    label: 'Ensino Médio Completo',
    value: 'MEDIO_COMPLETO'
  },
  {
    label: 'Ensino Superior Incompleto',
    value: 'SUPERIOR_INCOMPLETO'
  },
  {
    label: 'Ensino Superior Completo',
    value: 'SUPERIOR_COMPLETO'
  }
]

export const booleanOptions = [
  {
    label: 'Sim',
    value: 'true'
  },
  {
    label: 'Não',
    value: 'false'
  }
]

export const OptionCaminhao = [
  {
    id: 'CARRETA_BAU_SIDER',
    name: 'Carreta Baú/Sider'
  },
  {
    id: 'CARRETA_GRANELEIRA',
    name: 'Carreta Graneleira'
  },
  {
    id: 'CARRETA_CACAMBA',
    name: 'Carreta Caçamba'
  },
  {
    id: 'CARRETA_TANQUE',
    name: 'Carreta Tanque'
  },
  {
    id: 'CARRETA_TRUCK',
    name: 'Caminhão Truck/Toco/ ¾'
  },
  {
    id: 'CARRETA_BI_ARTICULADA',
    name: 'Carreta Bi-Articulada (9 e 7 Eixos)'
  },
  {
    id: 'CARRETA_CANAVIEIRA',
    name: 'Carreta Canavieira'
  },
  {
    id: 'ONIBUS',
    name: 'Ônibus'
  },
  {
    id: 'OUTROS',
    name: 'Outros'
  }
]

export const optionCaminhao = [
  {
    label: 'Carreta Baú/Sider',
    value: 'CARRETA_BAU_SIDER'
  },
  {
    label: 'Carreta Graneleira',
    value: 'CARRETA_GRANELEIRA'
  },
  {
    label: 'Carreta Tanque',
    value: 'CARRETA_TANQUE'
  },
  {
    label: 'Caminhão Truck/Toco/ ¾',
    value: 'CARRETA_TRUCK'
  },
  {
    label: 'Carreta Bi-Articulada (9 e 7 Eixos)',
    value: 'CARRETA_BI_ARTICULADA'
  },
  {
    label: 'Carreta Canavieira',
    value: 'CARRETA_CANAVIEIRA'
  },
  {
    label: 'Ônibus',
    value: 'ONIBUS'
  },
  {
    label: 'Outros',
    value: 'OUTROS'
  }
]

export const dateValues = [
  {
    label: 'Anos',
    value: 12
  },
  {
    label: 'Meses',
    value: 1
  }
]

export const tipoContratacao = [
  { label: 'Direta', value: 'DIRETA' },
  { label: 'Curso de Formação', value: 'CURSO_FORMACAO' }
]

export function searchTipoContratacao(name: string) {
  return tipoContratacao.find(tipo => tipo.value === name)?.label!
}

export const tipoEntrevista = [
  { label: 'Vídeo', value: 'VIDEO' },
  { label: 'Presencial', value: 'PRESENCIAL' }
]

export const tipoEntrevistaG10 = [
  ...tipoEntrevista,
  { label: 'Telefone', value: 'TELEFONE' }
]

export const tipoAgendamento = [
  { label: '1° Agendamento', value: 'PRIMEIRO_AGENDAMENTO' },
  { label: 'Reagendamento', value: 'REAGENDAMENTO' }
]

export function searchTipoEntrevista(name: string) {
  return tipoEntrevistaG10.find(tipo => tipo.value === name)?.label!
}

export const ResultadoColors = {
  APROVADO: '#81c784',
  APROVADO_DARK: '#388e3c',
  REPROVADO: '#e57373',
  REPROVADO_DARK: '#e53935',
  NOVO_CARGO: '#d9d9d9',
  NOVO_CARGO_DARK: 'transparent'
}

export const optionsOrigemCurriculo = [
  { label: 'Site', value: 'SITE' },
  {
    label: 'LinkedIn',
    value: 'LINKEDIN'
  },
  {
    label: 'Maringa.com',
    value: 'MARINGA_COM'
  },
  {
    label: 'Facebook',
    value: 'FACEBOOK'
  },
  {
    label: 'Google Vagas',
    value: 'GOOGLE_VAGAS'
  },
  {
    label: 'Outros',
    value: 'OUTROS'
  }
]

export const tipoDepartamento = [
  {
    value: 'RH',
    label: 'RH'
  },
  {
    value: 'CONTABILIDADE',
    label: 'Contabilidade'
  },
  {
    value: 'FINANCEIRO',
    label: 'Financeiro'
  },
  {
    value: 'OUTROS',
    label: 'Outros'
  }
]

export const tiposOperacao = [
  { value: 'SOLIDO', label: 'Sólido' },
  { value: 'LIQUIDO', label: 'Líquido' },
  { value: 'INDUSTRIAL', label: 'Industrial' }
]

export const tipoModalidade = [
  { value: 'INDIVIDUAL', label: 'Individual' },
  { value: 'COLETIVA', label: 'Coletiva' }
]

export const etapaProcesso = [
  { value: 'ENTREVISTA_RH', label: 'Entrevista Rh' },
  { value: 'ENTREVISTA_GESTOR', label: 'Entrevista Gestor' },
  { value: 'TESTE', label: 'Teste' }
]