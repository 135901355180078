import { Button, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { useFormikContext } from 'formik'
import { handleKeyCodes } from 'src/modules/options'
import DateInput from '../DateInput'
import TextInput from '../TextInput'

interface cursosProps {
  descricao: string
  dataConclusao: Date
  dataInicio: Date
  cargaHoraria: number
}

const useStyles = makeStyles(theme => ({
  subtitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: 12,
    marginBottom: 8
  },
  cursoContainer: {
    paddingBottom: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'start'
  },
  removeButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  removeButton: {
    flex: 1,
    minHeight: '56px',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      border: 'none',
      '&:hover': {
        border: 'none'
      }
    }
  },
  bottomSpace: {
    paddingBottom: 20
  }
}))

export default function Cursos() {
  const { values, setFieldValue } =
    useFormikContext<{ Cursos: cursosProps[] }>()
  const classes = useStyles()

  const handleAddCursos = () => {
    setFieldValue('Cursos', [
      ...values.Cursos,
      {
        descricao: '',
        dataInicio: null,
        dataConclusao: null,
        cargaHoraria: 0
      }
    ])
  }

  const handleRemoveCursos = (index: number) => {
    const curso = [...values.Cursos]
    if (curso.length < 1) {
      return
    }
    curso.splice(index, 1)
    setFieldValue('Cursos', [...curso])
  }

  return (
    <Grid item xs>
      <Typography
        style={{ fontWeight: 'bold', marginBottom: 10 }}
        component="p"
      >
        Cursos:
      </Typography>
      <Grid item xs={12}>
        <Typography
          component="span"
          className={classes.subtitle}
        >
          Adicione aqui todos os cursos que o(a) candidato(a) realizou.
        </Typography>
      </Grid>
      {values.Cursos.map((curso, index) => (
        <Grid
          container
          key={index}
          className={classes.cursoContainer}
        >
          <Grid item xs={12}>
            <Typography
              variant="h5"
              align="center"
              className={classes.bottomSpace}
            >
              {values.Cursos.length > 1 && ` ${index + 1}° Curso`}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10} md={11} lg={11}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={3}>
                  <TextInput name={`Cursos.${index}.descricao`} label="Descrição" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <DateInput
                    name={`Cursos.${index}.dataInicio`}
                    label="Data de Início"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <DateInput
                    name={`Cursos.${index}.dataConclusao`}
                    label="Data de Conclusão"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <TextInput
                    name={`Cursos.${index}.cargaHoraria`}
                    label="Carga Horária"
                    type="number"
                    inputProps={{ min: 0 }}
                    onKeyDown={handleKeyCodes}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={2} md={1} lg={1}>
              {values.Cursos.length > 0 && (
                <Tooltip placement="top" title={`Deletar ${index + 1}° Curso`}>
                  <Grid item xs={12} className={classes.removeButtonContainer}>
                    <Button
                      className={classes.removeButton}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleRemoveCursos(index)}
                    >
                      <DeleteOutlineIcon />
                    </Button>
                  </Grid>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Tooltip placement="right-start" title="Adicionar Curso">
        <Button
          variant="outlined"
          color="primary"
          type="button"
          onClick={handleAddCursos}
          startIcon={<AddIcon />}
        >
          Adicionar Cursos
        </Button>
      </Tooltip>
    </Grid>
  )
}
