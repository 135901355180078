import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import * as yup from 'yup'

import TextField from '@elentari/components-eve/final-form/TextField'
import { Grid } from '@material-ui/core'
import { LabelCaracteristicasFormData } from '../types'
import yupValidation from '../../../utils/yupValidation'

import { FormWrap, Paper } from '../../../components'
import { useLabelCaracteristica } from '../hooks/useLabelCaracteristica'

import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { labelCaracteristicasRepository } from '../hooks/labelCaracteristicasRepository'
import { MessagesYup } from '../../messages'

import { useSnackbar } from '@elentari/core'
import { setTimeout } from 'timers'
import { useAppBar } from 'src/hooks'

export const LabelCaracteristicaForm = () => {
  const [LabelCaracteristicaState, LabelCaracteristicaAction] =
    useLabelCaracteristica()

  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()

  const { save } = useEntitySaver<LabelCaracteristicasFormData>(async data => {
    const response = await labelCaracteristicasRepository.save(data)
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return response
  })

  useEffect(() => {
    return LabelCaracteristicaAction.reset()
  }, [LabelCaracteristicaAction])

  const handleSubmit = async (data: any) => {
    try {
      setLoading(true)
      const response = await save(data)
      if(response.ok) {
        appBarActions.setTitle(data.descricao)
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }
  const schemaObject = {
    descricao: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
  }
  const schema = yup.object().shape(schemaObject)

  return (
    <Form
      validate={yupValidation(schema)}
      onSubmit={handleSubmit}
      initialValues={
        LabelCaracteristicaState.tag === 'with-data' &&
        LabelCaracteristicaState.entity
      }
    >
      {({ handleSubmit, submitting, values }) => (
        <Paper>
          <FormWrap
            handleSubmit={handleSubmit}
            disableSubmit={submitting}
            disableBack={submitting}
            loading={loading}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField fullWidth name="descricao" label="Descrição" />
              </Grid>
            </Grid>
          </FormWrap>
        </Paper>
      )}
    </Form>
  )
}
