import { useSnackbar } from '@elentari/core'
import { FetchState } from '@elentari/core/types'
import { Grid, InputAdornment, makeStyles, Typography } from '@material-ui/core'
import { Formik, FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { Paper } from 'src/components'
import AutoCompleteInput from 'src/components/Formik/Forms/AutoCompleteInput'
import SimpleCheckbox from 'src/components/Formik/Forms/ChekboxInput'
import CurrencyInput from 'src/components/Formik/Forms/CurrencyInput'
import DateInput from 'src/components/Formik/Forms/DateInput'
import RichTextInput from 'src/components/Formik/Forms/RichTextInput'
import SelectInput from 'src/components/Formik/Forms/SelectInput'
import TextInput from 'src/components/Formik/Forms/TextInput'
import Spacer from 'src/components/Spacer'
import { Option } from 'src/hooks/useAutoComplete'
import { GridRepository } from 'src/modules/grids/hooks/GridRepository'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import { MotivoContratacao, motivoContratacao } from 'src/modules/vagas/types'
import { isG10, tipoContratacao, tiposOperacao } from 'src/utils'
import { useAdministrativo } from '../../administrativo/hooks/useAdministrativo'
import { CandidatoCardContent } from '../../components/CandidatoCardContent'
import { useMotorista } from '../../motorista/hooks/useMotorista'
import { MotoristaDetail } from '../../motorista/types'
import { CandidatoDetail } from '../../types'
import { getNewestAdmissao } from '../../utils'
import { AdmissaoCandidatoButtons } from '../components/AdmissaoCandidatoButtons'
import { updateAdmissao } from '../hooks/admissaoCandidatoRepository'
import { AdmissaoCandidatoDetail } from '../types'
import { schema } from '../utils/validationSchema'

const useStyles = makeStyles(theme => ({
  alignContentBottom: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  minWidth: {
    minWidth: '720px'
  },
  datePicker: {
    '& label': {
      width: '80%'
    }
  },
  formContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
      paddingBottom: theme.spacing(4)
    }
  }
}))

const AdmissaoCandidatoForm = ({
  state,
  action
}: {
  state: FetchState<CandidatoDetail | MotoristaDetail>
  action: { fetch: (id: string) => Promise<void>; reset: () => void }
}) => {
  const [, snackbarActions] = useSnackbar()
  const classes = useStyles()
  const userLogged = useLoggedUser()
  const userEmpresaIsG10 = isG10(userLogged?.empresa)

  const [gridsOptions, setGridsOptions] = useState<Option[]>([])

  const formikState = {
    initialValues: {
      coordenador: '',
      operacao: '',
      tipoContratacao: '',
      cargo: '',
      filialCNPJ: '',
      centroCusto: '',
      departamento: '',
      motivoContratacao: '',
      nomeSubstituicao: '',
      escalaHorario: '',
      salarioInicial: 0,
      salarioPosExperiencia: 0,
      cargoConfianca: false,
      celular: false,
      notebook: false,
      chip: false,
      desktop: false,
      ramal: false,
      linhaCorporativa: false,
      email: false,
      pastasCompartilhadas: false,
      kmm: false,
      colaboradorKmmEspelhado: '',
      celularIMEI: '',
      notebookNumPatrimonio: '',
      aprovadoPorGerenteArea: '',
      aprovadoPorGerenteRh: ''
    },
    onSubmit: async (data: FormikValues) => {
      try {
        const response = await updateAdmissao(data.id, data)

        if (response?.ok) {
          snackbarActions.setMessage('Salvo com sucesso!')
        } else {
          snackbarActions.setMessage('Ocorreu um erro ao salvar.')
        }
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  const formatData = (
    state: CandidatoDetail | MotoristaDetail
  ): AdmissaoCandidatoDetail => {
    return getNewestAdmissao(state.AdmissaoCandidato ?? [])
  }

  const loadGrids = async () => {
    const response = await GridRepository.fetch({ limit: '999' })

    if (response.ok) {
      setGridsOptions(
        response.data?.edges.map(i => ({
          id: i.node.id,
          name: i.node.nome
        })) ?? []
      )
    }
  }

  const resetForm = async () => {
    if (state.tag === 'with-data') {
      await action.fetch(state.entity.id)
    }
  }

  const scrollTop = () => {
    const mainContainer = document.querySelector('main')
    if (mainContainer) {
      mainContainer.scrollTo({
        top: 0
      })
    }
  }

  useEffect(() => {
    scrollTop()
    loadGrids()
  }, [])

  return (
    <>
      {state.tag === 'with-data' && (
        <Paper>
          <CandidatoCardContent candidato={state.entity} showIcons={true} />
        </Paper>
      )}

      <Spacer y={2} />

      <Formik
        initialValues={
          state.tag === 'with-data'
            ? formatData(state.entity)
            : formikState.initialValues
        }
        onSubmit={formikState.onSubmit}
        validationSchema={schema}
        enableReinitialize
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <>
            <Paper>
              <div className={classes.formContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <SelectInput
                      name="operacao"
                      label="Operação"
                      options={tiposOperacao}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextInput name="coordenador" label="Coordenador" />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <AutoCompleteInput
                      name="gridId"
                      label="Grid"
                      options={gridsOptions}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectInput
                      label="Tipo da Contratação"
                      name="tipoContratacao"
                      options={tipoContratacao}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DateInput
                      name="dataEntregaDocumentos"
                      label="Data da Entrega dos Documentos"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DateInput
                      name="dataPrevistaExame"
                      label="Data Prevista do Exame"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <DateInput
                      name="dataPrevistaAdmissao"
                      label="Data Prevista de Admissão"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RichTextInput name="observacao" label="Observação" />
                  </Grid>
                  <Grid item xs={12} sm={userEmpresaIsG10 ? 3 : 12}>
                    <TextInput name="cargo" label="Cargo" />
                  </Grid>
                  {userEmpresaIsG10 && (
                    <>
                      <Grid item xs={12} sm={3}>
                        <TextInput name="filialCNPJ" label="Filial(CNPJ)" />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextInput name="centroCusto" label="Centro de Custo" />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextInput name="departamento" label="Departamento" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectInput
                          name="motivoContratacao"
                          label="Motivo Contratação"
                          options={motivoContratacao}
                          firstOptionDisabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          name="nomeSubstituicao"
                          label="Nome Substituição"
                          disabled={
                            values.motivoContratacao !==
                            MotivoContratacao.SUBSTITUICAO
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextInput name="escalaHorario" label="Escala/Horário" />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <CurrencyInput
                          name="salarioInicial"
                          label="Salário Inicial"
                          startAdornment={
                            <InputAdornment position="start">R$</InputAdornment>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <CurrencyInput
                          name="salarioPosExperiencia"
                          label="Salário Pós Experiência"
                          startAdornment={
                            <InputAdornment position="start">R$</InputAdornment>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <SimpleCheckbox
                          name="cargoConfianca"
                          label="Cargo de Confiança 40%"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          <b>Equipamentos</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <SimpleCheckbox name="celular" label="Celular" />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <SimpleCheckbox name="notebook" label="Notebook" />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <SimpleCheckbox name="chip" label="Chip" />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <SimpleCheckbox name="desktop" label="Desktop" />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          <b>Sistema</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <SimpleCheckbox name="ramal" label="Ramal" />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <SimpleCheckbox
                          name="linhaCorporativa"
                          label="Linha Coorporativa"
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <SimpleCheckbox name="email" label="E-mail" />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <SimpleCheckbox
                          name="pastasCompartilhadas"
                          label="Pasta Compartilhada"
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <SimpleCheckbox name="kmm" label="KMM" />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <TextInput
                          name="colaboradorKmmEspelhado"
                          label="Espelhar para qual colaborador?"
                          disabled={!values.kmm}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption">
                          <b>
                            Observação: * Caso seja substituição de funcionário
                            favor informar número do patrimônio de equipamento que
                            será repassado ao colaborador substituto.
                          </b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          name="notebookNumPatrimonio"
                          label="Notebook: Nº Patrimônio"
                          disabled={
                            values.motivoContratacao !==
                            MotivoContratacao.SUBSTITUICAO
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          name="celularIMEI"
                          label="Celular: Nº IMEI"
                          disabled={
                            values.motivoContratacao !==
                            MotivoContratacao.SUBSTITUICAO
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          name="aprovadoPorGerenteArea"
                          label="Gerente da Área"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          name="aprovadoPorGerenteRh"
                          label="Gerente de RH"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
            </Paper>

            <Spacer y={2} />

            <AdmissaoCandidatoButtons
              admissaoCandidatoId={
                state.tag === 'with-data' &&
                state.entity.AdmissaoCandidato !== undefined &&
                getNewestAdmissao(state.entity.AdmissaoCandidato).id
              }
              statusAdmissao={
                state.tag === 'with-data' &&
                state.entity.AdmissaoCandidato !== undefined &&
                getNewestAdmissao(state.entity.AdmissaoCandidato).status
              }
              onStatusChange={resetForm}
              candidatoId={state.tag === 'with-data' && state.entity.id}
              candidatoType={state.tag === 'with-data' && state.entity.tipo}
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
          </>
        )}
      </Formik>
    </>
  )
}

export const AdmissaoCandidatoAdministrativoForm = () => {
  const [administrativoState, administrativoActions] = useAdministrativo()

  return (
    <AdmissaoCandidatoForm
      state={administrativoState}
      action={administrativoActions}
    />
  )
}

export const AdmissaoCandidatoMotoristaForm = () => {
  const [motoristaState, motoristaActions] = useMotorista()

  return (
    <AdmissaoCandidatoForm state={motoristaState} action={motoristaActions} />
  )
}
