import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import SyncIcon from '@material-ui/icons/Sync'

type Props = {
  id: string
  loading: boolean
  onClick: () => {}
}

export const GenerateNewLink = ({ id, onClick, loading }: Props) => {
  return (
    <Tooltip
      placement="top"
      title="Gerar novo link para o candidato(a) anexar documentos"
    >
      <Button
        onClick={onClick}
        style={{ height: 55 }}
        fullWidth
        color="primary"
        variant="outlined"
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : <SyncIcon />}
      </Button>
    </Tooltip>
  )
}
