import Filter from '@elentari/components-eve/components/Filter'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams, useSnackbar } from '@elentari/core'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import LinkMD from '@material-ui/core/Link'
import * as dateFns from 'date-fns'
import { Link } from 'react-router-dom'
import {
  AddRecord,
  DeleteRecord,
  NoResultsTable,
  Paper,
  TableCellHead,
  TableContainer,
  When
} from '../../components'
import Spacer from '../../components/Spacer'
import { SubmitValues } from './types'
import { HandleErrorPage } from '../../utils/handleErrorPage'
import { menuPropsMultipleSelect } from '../options'
import { useVagas } from './hooks/useVagas'
import { vagasRepository } from './hooks/vagaRepository'
import { ModoSelecao, StatusVagas, VagaDetail } from './types'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import ReactHtmlParser from 'react-html-parser'

interface Props {
  list: VagaDetail[]
  loading: boolean
  totalCount: number
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
  }
}

type LayoutQueryFilter = {
  createdAt?: { gt: string }
  [key: string]: any
}

const VagaFilter = () => {
  const { clear, push, initialValues } = useQueryParams<LayoutQueryFilter>()
  const userLogged = useLoggedUser()
  const isGestor = userLogged?.rolesInSystem.includes('GESTOR')

  const handleSubmitFilter = (values: SubmitValues & { page: number }) => {
    if (isGestor) {
      values.solicitadoPor = userLogged?.name
    }
    if (values.status === null) {
      delete values.status
    }
    if (values.modoSelecao === null) {
      delete values.modoSelecao
    }

    if (values.createdAt) {
      const dateFormatted = dateFns.parse(
        String(values.createdAt),
        'dd/MM/yyyy',
        new Date()
      )
      return push({
        ...values,
        createdAt: {
          gt: dateFormatted.toISOString()
        }
      })
    }
    push({
      ...values,
      createdAt: undefined
    })
  }
  const format = (initialValues: LayoutQueryFilter) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues,
        createdAt: dateFns.format(
          dateFns.parseISO(initialValues.createdAt.gt),
          'dd/MM/yyyy'
        )
      }
    }
    return initialValues
  }
  return (
    <Filter
      labels={{ clear: 'Limpar', find: 'Buscar' }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter({ ...values, page: 1 })}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Select
            fullWidth
            name="status"
            label="Status da Vaga"
            items={StatusVagas}
            MenuProps={menuPropsMultipleSelect}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField fullWidth name="categoria" label="Categoria" />
        </Grid>

        <Grid item xs={4}>
          <TextField fullWidth name="area" label="Área" />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="nivelExperiencia"
            label="Nível de Experiência"
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            fullWidth
            name="modoSelecao"
            label="Modo da Seleção"
            items={ModoSelecao}
            MenuProps={menuPropsMultipleSelect}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export const VagaTable = ({
  list,
  loading,
  totalCount,
  onDelete,
  authorization
}: Props) => {
  return (
    <>
      <VagaFilter />
      <Spacer y={4} />
      <Paper noTopBorderRadius>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead
                    label="Cargo"
                    sort="cargo_nome"
                  />
                  <TableCellHead label="Status" sort="status" />
                  <TableCellHead label="Categoria" sort="categoria" />
                  <TableCellHead label="Área" sort="area" />
                  <TableCellHead
                    label="Nível de Experiência"
                    sort="nivelExperiencia"
                  />
                  <TableCellHead label="Modo Seleção" sort="modoSelecao" />

                  <When
                    value={!authorization || authorization.canDelete}
                    equals
                  >
                    <TableCell></TableCell>
                  </When>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(vaga => (
                  <TableRow hover key={vaga.id}>
                    <TableCell>
                      <LinkMD component={Link} to={`/vagas-info/${vaga.id}`}>
                        {vaga.cargoFk?.nome}
                      </LinkMD>
                    </TableCell>
                    <TableCell>
                      {
                        StatusVagas.find(status => status.value === vaga.status)
                          ?.label
                      }
                    </TableCell>
                    <TableCell>{vaga.categoria}</TableCell>
                    <TableCell>{vaga.area}</TableCell>
                    <TableCell>{vaga.nivelExperiencia}</TableCell>
                    <TableCell>
                      {
                        ModoSelecao.find(
                          modo => modo.value === vaga.modoSelecao
                        )?.label
                      }
                    </TableCell>

                    <When value={authorization?.canDelete} equals>
                      <DeleteRecord onDelete={() => onDelete(vaga.id)} />
                    </When>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="vagas" authorization={authorization} />
    </>
  )
}

const Vagas = () => {
  const [state, actions] = useVagas()
  const [_, snackbarActions] = useSnackbar()
  async function handleDelete(id: string) {
    const response = await vagasRepository.delete(id)
    actions.fetchPage()

    if (response.ok) {
      snackbarActions.setSnackBar({
        message: 'Deletado com sucesso',
        severity: 'success'
      })
      actions.fetchPage()
      return
    }
    const message = (response.data as unknown as any).message
    snackbarActions.setSnackBar({
      message: message as string,
      severity: 'warning'
    })
  }
  switch (state.tag) {
    case 'empty':
      return (
        <VagaTable
          list={[]}
          onDelete={() => {}}
          totalCount={0}
          loading={true}
        />
      )
    case 'with-data':
      return (
        <VagaTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onDelete={handleDelete}
          authorization={{ canCreate: true, canDelete: true }}
        />
      )
    case 'error':
      return <HandleErrorPage state={state.data} />
  }
}
export default Vagas
