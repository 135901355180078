import {
  CrudRoute,
  CrudTabs,
  useTabsNavigation
} from '@elentari/components-eve/components/CrudTabs'
import { VagaBeneficiosForm } from './VagaBeneficiosForm'

import { VagasForm } from './VagaForm'
import { VagaRequisitoForm } from './VagaRequisitoForm'
import { VagaResponsabilidadeForm } from './VagaResponsabilidadeForm'

export const LayoutTabs = () => {
  const hook = useTabsNavigation({
    mainPath: 'vagas',
    tabs: [
      {
        value: '',
        label: 'Dados Vagas'
      },
      { value: 'beneficios', label: 'Benefícios' },
      { value: 'responsabilidades', label: 'Responsabilidades' },
      { value: 'requisitos', label: 'Requisitos' }
    ]
  })
  return (
    <CrudTabs {...hook}>
      <CrudRoute render={() => <VagasForm />} />
      <CrudRoute name="beneficios" render={() => <VagaBeneficiosForm />} />
      <CrudRoute
        name="responsabilidades"
        render={() => <VagaResponsabilidadeForm />}
      />
      <CrudRoute name="requisitos" render={() => <VagaRequisitoForm />} />
    </CrudTabs>
  )
}
