import { EdgesPage } from "@elentari/core/types"
import { apiSauceInstance } from "src/services/api"
import { SelecaoCandidatoDetail } from "../types"

export const createSelecaoCandidato = async (data: any) => {
  const response = await apiSauceInstance.post<any>(`/selecao-candidatos`, data)

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const updateSelecaoCandidato = async (selecaoId: string, data: any) => {
  const response = await apiSauceInstance.put<any>(`/selecao-candidatos/${selecaoId}`, data)

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const alterarStatusSelecaoCandidato = async (selecaoId: string, data: any) => {
  const response = await apiSauceInstance.patch<any>(`/selecao-candidatos/${selecaoId}/alterar-status`, data)

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const fetchSelecoesCandidatoFromCandidatoId = async (candidatoId: string) => {
  const response = await apiSauceInstance.get<EdgesPage<SelecaoCandidatoDetail>>(
    `/selecao-candidatos`,
    {
      candidatoId
    }
  )

  return response
}

export const fetchSelecaoCandidatoById = async (id: string) => {
  const response = await apiSauceInstance.get<SelecaoCandidatoDetail>(`/selecao-candidatos/${id}`)

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}