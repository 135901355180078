import { Typography } from "@material-ui/core"
import { ReactElement } from "react"

interface ModalDetailLabelProps {
  title: string
  subtitle: ReactElement<any>[] | string | undefined
  show?: boolean
}

export const ModalDetailLabel = ({
  title,
  subtitle,
  show = true
}: ModalDetailLabelProps) => {
  return (
    <>
      {show && (
        <Typography color="textSecondary">
          <strong>{title} </strong>
          {subtitle}
        </Typography>
      )}
    </>
  )
}