import { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import SimpleDialog from './SimpleDialog'

type Props = {
  onDelete: () => void
  title?: string
  message?: string
}

export function DeleteRecord({ onDelete, title, message }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <Tooltip title="Deletar registro" aria-label="Deletar registro">
        <TableCell align="right" padding="checkbox">
          <IconButton onClick={() => setIsModalOpen(true)}>
            <DeleteOutlineIcon />
          </IconButton>
        </TableCell>
      </Tooltip>
      <SimpleDialog
        message={message || 'Deseja realmente deletar esse item?'}
        open={isModalOpen}
        buttonLabel="Não"
        handleClose={() => setIsModalOpen(false)}
        primaryAction={onDelete}
        title={title || 'Deletar este Registro'}
        primaryActionButtonLabel="Sim"
      />
    </>
  )
}
