import { useMotorista } from '../../motorista/hooks/useMotorista'
import { useAdministrativo } from '../../administrativo/hooks/useAdministrativo'

import { useAppBar } from '../../../../hooks'
import { handleStateErrorsToRender } from '../../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { SelecaoCandidatoMotoristaForm, SelecaoCandidatoAdministrativoForm } from './SelecaoCandidatoForm'

export function SelecaoCandidatoMotoristaMasterDetail() {
  const [state, actions] = useMotorista()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      state,
      u => u.entity.id,
      u => u.entity.nomeCompleto
    )
  ])
  useFetchAndReset(actions.fetch, actions.reset)
  return handleStateErrorsToRender(state, <SelecaoCandidatoMotoristaForm />)
}


export function SelecaoCandidatoAdministrativoMasterDetail() {
  const [state, actions] = useAdministrativo()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      state,
      u => u.entity.id,
      u => u.entity.nomeCompleto
    )
  ])
  useFetchAndReset(actions.fetch, actions.reset)
  return handleStateErrorsToRender(state, <SelecaoCandidatoAdministrativoForm />)
}

