import { makeStyles } from '@material-ui/core'
import React, { useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { empresas, EmpresaType } from '../../types'
import AdministrativoG10 from '../AdministrativoG10'
import AdministrativoTP from '../AdministrativoTP'
import Motorista from '../Motorista'
import { TrabalheConoscoG10 } from '../TrabalheConoscoG10'
import { TrabalheConoscoTP } from '../TrabalheConoscoTP'
import { HomeScreen } from './HomeScreen'

interface InfoQuery {
  empresa: string
  tipo: string
}

const useStyles = makeStyles({
  mainContainer: {
    height: '100vh',
    overflow: 'auto'
  },
})

function CurriculosRoutes() {
  const [tipo, setTipo] = useState('')
  const [empresa, setEmpresa] = useState<EmpresaType>('HOME')

  const { state, search } = useLocation()

  useLayoutEffect(() => {
    const [urlEmpresa, urlTipo] = search
      .substring(search.search('=') + 1)
      .split('&tipo=')

    setEmpresa(empresas.includes(urlEmpresa.toUpperCase() as EmpresaType) ? urlEmpresa.toUpperCase() as EmpresaType : 'HOME')
    setTipo(urlTipo)

    if ((!urlEmpresa && !urlTipo) && state) {
      const { empresa, tipo } = state as InfoQuery
      if (empresa && tipo) {
        if (empresa === 'TRANSPANORAMA') {
          setEmpresa('TRANSPANORAMA')
          setTipo(tipo)
        } else if (empresa === 'G10') {
          setEmpresa('G10')
        }
      }
    }
  }, [state, search])


  if (empresa === 'TRANSPANORAMA' && tipo === 'administrativo') {
    return <AdministrativoTP candidatoType="Administrativo" empresa="Transpanorama" />
  }
  if (empresa === 'TRANSPANORAMA' && tipo === 'motorista') {
    return <Motorista candidatoType="Motorista" empresa="Transpanorama" />
  }
  if (empresa === 'G10' && tipo === 'administrativo') {
    return <AdministrativoG10 candidatoType="Administrativo" empresa="G10" />
  }
  if (empresa === 'G10' && tipo === 'motorista') {
    return <Motorista candidatoType="Motorista" empresa="G10" />
  }
  if (empresa === 'G10' && tipo === 'operacional') {
    return <AdministrativoG10 candidatoType="Administrativo" empresa="G10" />
  }
  if (empresa === 'TRANSPANORAMA') {
    return <TrabalheConoscoTP />
  }
  if (empresa === 'G10') {
    return <TrabalheConoscoG10 />
  }

  return <HomeScreen />
}

function Curriculos() {
  const classes = useStyles()
  return (
    <main className={classes.mainContainer}>
      <CurriculosRoutes />
    </main>
  )
}

export default Curriculos
