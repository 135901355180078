import { Grid, LinearProgress, InputAdornment } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import AutoCompleteInput from 'src/components/Formik/Forms/AutoCompleteInput'
import TextInput from 'src/components/Formik/Forms/TextInput'
import { Option } from 'src/hooks/useAutoComplete'
import { CargoRepository } from 'src/modules/cargos/cargo/hooks/CargoRepository'
import { useCargo } from 'src/modules/cargos/cargo/hooks/useCargo'
import CurrencyInput from 'src/components/Formik/Forms/CurrencyInput'
import SimpleCheckbox from 'src/components/Formik/Forms/ChekboxInput'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import RichTextInput from 'src/components/Formik/Forms/RichTextInput'

interface FormikValuesProps {
  area: string
  descricao: string
  salarioMax: number
  salarioMin: number
  cargoId: string
  categoria: string
  nivelExperiencia: string
  criarCargo: boolean
  salario: number
}

type Props = {
  isDisabled: boolean
}

interface Params {
  id: string
}
export function VagaFormikInput({ isDisabled = false }: Props) {
  const [cargoState, cargoAction] = useCargo()
  const userLogged = useLoggedUser()
  const isGestor = userLogged?.rolesInSystem.includes('GESTOR')
  const [cargos, setCargos] = useState<Option[]>([])
  const params = useParams<Params>()
  const { values, setValues, setFieldValue } =
    useFormikContext<FormikValuesProps>()
  const [loadingCargos, setLoadginCargos] = useState(false)

  const loadCargos = async () => {
    const response = await CargoRepository.fetch({ status: 'ATIVO' })
    if (response.ok) {
      setCargos(
        response.data?.edges.map(e => ({
          id: e.node.id,
          name: e.node.nome
        })) ?? []
      )
    }
  }

  useEffect(() => {
    loadCargos()
  }, [])

  useEffect(() => {
    if (
      values.salario > values.salarioMax ||
      values.salario < values.salarioMin
    ) {
      setFieldValue('hasSalarioLimite', true)
    } else {
      setFieldValue('hasSalarioLimite', false)
    }
  }, [values.salario, values.salarioMax, values.salarioMin])

  useEffect(() => {
    if (values.cargoId !== '' && values.cargoId !== null) {
      setLoadginCargos(true)
      cargoAction.fetch(values.cargoId)
    }
  }, [values.cargoId])

  useEffect(() => {
    if (cargoState.tag === 'with-data' && params && params.id === 'new') {
      setValues({
        ...values,
        area: cargoState.entity.area,
        salario: Number(cargoState.entity.salarioMin),
        salarioMin: Number(cargoState.entity.salarioMin),
        salarioMax: Number(cargoState.entity.salarioMax),
        categoria: cargoState.entity.categoria,
        descricao: cargoState.entity.descricao,
        nivelExperiencia: cargoState.entity.nivelExperiencia
      })
    }

    return () => cargoAction.reset()
  }, [cargoState.tag])

  useEffect(() => {
    if (cargoState.tag === 'with-data' && cargoState.entity.id) {
      setLoadginCargos(false)
    }
  }, [cargoState.tag])

  return (
    <>
      <Grid item xs={12} sm={6}>
        <AutoCompleteInput
          name="cargoId"
          label="Cargo"
          options={cargos}
          disabled={isDisabled || values.criarCargo}
        />
        {loadingCargos && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput name="categoria" label="Categoria" disabled={isDisabled} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput name="area" label="Área" disabled={isDisabled} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput
          name="nivelExperiencia"
          label="Nível de Experiência"
          disabled={isDisabled}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <CurrencyInput
          name="salarioMin"
          label="Salário Mínimo"
          disabled={!!values.cargoId || isGestor}
          startAdornment={<InputAdornment position="start">R$</InputAdornment>}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <CurrencyInput
          name="salarioMax"
          label="Salário Máximo"
          disabled={!!values.cargoId || isGestor}
          startAdornment={<InputAdornment position="start">R$</InputAdornment>}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <CurrencyInput
          label="Salário"
          name="salario"
          disabled={isDisabled}
          startAdornment={<InputAdornment position="start">R$</InputAdornment>}
        />
      </Grid>

      <Grid item xs={12} md={3} sm={3}>
        <SimpleCheckbox
          name="hasSalarioLimite"
          label="Salário Fora do Especificado"
          disabled={true}
        />
      </Grid>

      <Grid item xs={12}>
        <RichTextInput
          name="descricao"
          label="Descrição"
          disabled={isDisabled}
        />
      </Grid>
    </>
  )
}
