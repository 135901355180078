import { Assignment } from '@material-ui/icons'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import BlockIcon from '@material-ui/icons/Block'
import BusinessIcon from '@material-ui/icons/Business'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import CastForEducationIcon from '@material-ui/icons/CastForEducation'
import CategoryIcon from '@material-ui/icons/Category'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import DashboardIcon from '@material-ui/icons/Dashboard'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import EventSeatIcon from '@material-ui/icons/EventSeat'
import GridOnIcon from '@material-ui/icons/GridOn'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import LabelIcon from '@material-ui/icons/Label'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PersonOutlineSharp from '@material-ui/icons/PersonOutlineSharp'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot'
import SchoolIcon from '@material-ui/icons/School'
import ViewListOutlined from '@material-ui/icons/ViewListOutlined'
import WorkIcon from '@material-ui/icons/Work'
import { Item } from '../../components/AppWrap/MenuItems'
import AgendamentoAdministrativo from '../agendamentos-administrativo/AgendamentoAdministrativoList'
import { AgendamentoAdministrativoMasterDetail } from '../agendamentos-administrativo/master-detail/AgendamentoAdministrativoMasterDetail'
import Interesse from '../areas-interesse/InteresseList'
import { InteresseMasterDetail } from '../areas-interesse/master-detail/InteresseMasterDetail'
import Beneficio from '../beneficios/BeneficioList'
import { BeneficioDetail } from '../beneficios/master-detail/BeneficioDetail'
import Administrativos from '../candidato/administrativo/adminstrativosList'
import AprovadosTriagemAdministrativo from '../candidato/administrativo/AprovadosTriagemAdministrativoList'
import { AdministrativoMasterDetail } from '../candidato/administrativo/master-detail/AdministrativoMasterDetail'
import { TriagemAdministrativoMasterDetail } from '../candidato/administrativo/master-detail/TriagemAdministrativoMasterDetail'
import ReprovadosTriagemAdministrativo from '../candidato/administrativo/ReprovadosTriagemAdministrativoList'
import TriagemAdministrativo from '../candidato/administrativo/TriagemAdministrativoList'
import {
  AdmissaoAdministrativo,
  AdmissaoMotorista
} from '../candidato/admissao-candidato/AdmissaoCandidatoList'
import { InstrucaoAdmissaoForm } from '../candidato/admissao-candidato/master-detail/InstrucaoAdmissaoForm'
import {
  AgendamentoCandidatoAdministrativo,
  AgendamentoCandidatoMotorista
} from '../candidato/agendamento-candidato/AgendamentoCandidatoList'
import {
  AgendamentoCandidatoAdministrativoMasterDetail,
  AgendamentoCandidatoMotoristaMasterDetail
} from '../candidato/agendamento-candidato/master-detail/AgendamentoCandidatoMasterDetail'
import { ContratadoMotorista } from '../candidato/contratados-candidato/ContratadoCandidatoList'
import AprovadosTriagemMotorista from '../candidato/motorista/AprovadosTriagemMotoristaList'
import { MotoristaMasterDetail } from '../candidato/motorista/master-detail/MotoristaMasterDetail'
import { TriagemMotoristaMasterDetail } from '../candidato/motorista/master-detail/TriagemMotoristaMasterDetail'
import Motoristas from '../candidato/motorista/MotoristaList'
import ReprovadosTriagemMotorista from '../candidato/motorista/ReprovadosTriagemMotoristaList'
import TriagemMotorista from '../candidato/motorista/TriagemMotoristaList'
import {
  SelecaoCandidatoAdministrativoMasterDetail,
  SelecaoCandidatoMotoristaMasterDetail
} from '../candidato/selecao-candidato/master-detail/SelecaoCandidatoMasterDetail'
import {
  SelecaoAdministrativo,
  SelecaoMotorista
} from '../candidato/selecao-candidato/SelecaoCandidatoList'
import { CandidatoTreinamentoDetail } from '../candidatos-treinamentos/master-detail/CandidatoTreinamentoMasterDetail'
import CargoArea from '../cargos/cargo-area/CargoAreaList'
import { CargoAreaDetail } from '../cargos/cargo-area/master-detail/CargoAreaMasterDetail'
import CargoCategoria from '../cargos/cargo-categoria/CargoCategoriaList'
import { CargoCategoriaDetail } from '../cargos/cargo-categoria/master-detail/CargoCategoriaDetail'
import Cargo from '../cargos/cargo/CargoList'
import { CargoDetail } from '../cargos/cargo/master-detail/CargoMasterDetail'
import { DocumentosAdministrativoForm } from '../conclusao-administrativo/master-detail/ConclusaoAdministrativoForm'
import Dashboard from '../dashboard/Dashboard'
import { TestesProcessoAdministrativoForm } from '../entrevista-administrativo/master-detail/EntrevistaAdministrativoForm'
import DemandaContratacao from '../grids/DemandaContratacaoList'
import Grids from '../grids/GridsList'
import { GridsMasterDetail } from '../grids/master-detail/GridsMasterDetail'
import { CandidatoTreinamentoForm } from '../inscricao-candidatos-treinamento/master-detail/CandidatoTreinamentoForm'
import SelecaoCandidatosTreinamento from '../inscricao-candidatos-treinamento/master-detail/SelecaoCandidatosTreinamentos'
import LabelCaracteristicas from '../labels-caracteristicas/LabelCaracteristicasList'
import { LabelCaracteristicaMasterDetail } from '../labels-caracteristicas/master-detail/LabelCaracteristicaMasterDetail'
import ListaBloqueios from '../lista-bloqueios/ListaBloqueiosList'
import { ListaBloqueiosMasterDetail } from '../lista-bloqueios/master-detail/ListaBloqueiosMasterDetail'
import { TreinamentosMasterDetail } from '../treinamentos/master-detail/TreinamentosMasterDetail'
import Treinamento from '../treinamentos/Treinamento'
import Treinamentos from '../treinamentos/TreinamentosList'
import { CandidatoVagaForm } from '../triagem-candidatos-administrativo/master-detail/CandidatoVagaForm'
import SelecaoCandidatos from '../triagem-candidatos-administrativo/master-detail/SelecaoCandidatos'
import { VagaInfo } from '../vagas/master-detail/VagaInfo'
import { VagaMasterDetail } from '../vagas/master-detail/VagasMasterDetail'
import Vagas from '../vagas/VagaList'

const menuItems: Item[] = [
  {
    name: 'listaBloqueios',
    label: 'Lista de Bloqueios',
    pathname: '/lista-bloqueios',
    icon: BlockIcon,
    group: false,
    detail: ListaBloqueiosMasterDetail,
    list: ListaBloqueios
  },
  {
    name: 'dashboard',
    label: 'Dashboard',
    icon: DashboardIcon,
    group: false,
    pathname: '/',
    list: Dashboard
  },
  {
    name: 'grids',
    label: 'Grids',
    pathname: '/grids',
    icon: GridOnIcon,
    group: false,
    detail: GridsMasterDetail,
    list: Grids
  },
  {
    name: 'demandaContratacao',
    label: 'Demandas de Contratação',
    pathname: '/demandas-contratacao',
    icon: Assignment,
    group: false,
    detail: GridsMasterDetail,
    list: DemandaContratacao
  },
  {
    name: 'administrativo',
    label: 'Administrativo',
    icon: RecentActorsIcon,
    group: true,
    items: [
      {
        name: 'triagemAdministrativos',
        label: 'Triagem',
        pathname: '/triagem-administrativo',
        icon: HowToRegIcon,
        group: false,
        detail: TriagemAdministrativoMasterDetail,
        list: TriagemAdministrativo
      },
      {
        name: 'aprovadosTriagemAdministrativos',
        label: 'Aprovado na Triagem',
        pathname: '/aprovados-triagem-administrativo',
        icon: CheckBoxOutlinedIcon,
        group: false,
        detail: TriagemAdministrativoMasterDetail,
        list: AprovadosTriagemAdministrativo
      },
      {
        name: 'reprovadosTriagemAdministrativos',
        label: 'Reprovado na Triagem',
        pathname: '/reprovados-triagem-administrativo',
        icon: HighlightOffOutlinedIcon,
        group: false,
        detail: TriagemAdministrativoMasterDetail,
        list: ReprovadosTriagemAdministrativo
      },
      {
        name: 'agendamentosAdministrativos',
        label: 'Agendamento',
        pathname: '/agendamento-administrativo',
        icon: EventAvailableIcon,
        group: false,
        detail: AgendamentoCandidatoAdministrativoMasterDetail,
        list: AgendamentoCandidatoAdministrativo
      },
      {
        name: 'selecaoAdministrativo',
        label: 'Seleção',
        pathname: '/selecao-administrativo',
        icon: ViewListOutlined,
        group: false,
        detail: SelecaoCandidatoAdministrativoMasterDetail,
        list: SelecaoAdministrativo
      },
      {
        name: 'admissaoAdministrativo',
        label: 'Admissão',
        pathname: '/admissao-administrativo',
        icon: PersonAddIcon,
        group: false,
        list: AdmissaoAdministrativo
      },
      {
        name: 'candidatosAdministrativos',
        label: 'Candidatos(as)',
        pathname: '/administrativo',
        icon: PersonOutlineSharp,
        group: false,
        detail: AdministrativoMasterDetail,
        list: Administrativos
      }
    ]
  },
  {
    name: 'motorista',
    label: 'Motorista',
    icon: LocalShippingIcon,
    group: true,
    items: [
      {
        name: 'triagemMotoristas',
        label: 'Triagem',
        pathname: '/triagem-motorista',
        icon: HowToRegIcon,
        group: false,
        detail: TriagemMotoristaMasterDetail,
        list: TriagemMotorista
      },
      {
        name: 'aprovadosTriagemMotoristas',
        label: 'Aprovado na Triagem',
        pathname: '/aprovados-triagem-motorista',
        icon: CheckBoxOutlinedIcon,
        group: false,
        detail: TriagemMotoristaMasterDetail,
        list: AprovadosTriagemMotorista
      },
      {
        name: 'reprovadosTriagemMotoristas',
        label: 'Reprovado na Triagem',
        pathname: '/reprovados-triagem-motorista',
        icon: HighlightOffOutlinedIcon,
        group: false,
        detail: TriagemMotoristaMasterDetail,
        list: ReprovadosTriagemMotorista
      },
      {
        name: 'agendamentosMotoristas',
        label: 'Agendamento',
        pathname: '/agendamento-motorista',
        icon: EventAvailableIcon,
        group: false,
        detail: AgendamentoCandidatoMotoristaMasterDetail,
        list: AgendamentoCandidatoMotorista
      },
      {
        name: 'selecaoMotorista',
        label: 'Seleção',
        pathname: '/selecao-motorista',
        icon: ViewListOutlined,
        group: false,
        detail: SelecaoCandidatoMotoristaMasterDetail,
        list: SelecaoMotorista
      },
      {
        name: 'admissaoMotorista',
        label: 'Admissão',
        pathname: '/admissao-motorista',
        icon: PersonAddIcon,
        group: false,
        list: AdmissaoMotorista
      },
      {
        name: 'contratadoMotorista',
        label: 'Contratados',
        pathname: '/contratados-motorista',
        icon: AccountBoxIcon,
        group: false,
        list: ContratadoMotorista
      },
      {
        name: 'candidatosMotoristas',
        label: 'Candidatos(as)',
        pathname: '/motorista',
        icon: PersonOutlineSharp,
        group: false,
        detail: MotoristaMasterDetail,
        list: Motoristas
      }
    ]
  },
  {
    name: 'instrucaoAdmissaoMotorista',
    label: 'Gerar Instrução',
    pathname: '/admissao-motorista/:candidatoId/gerar-instrucao',
    icon: ScatterPlotIcon,
    hidden: true,
    group: false,
    list: InstrucaoAdmissaoForm
  },
  {
    name: 'instrucaoAdmissaoAdministrativo',
    label: 'Gerar Instrução',
    pathname: '/admissao-administrativo/:candidatoId/gerar-instrucao',
    icon: ScatterPlotIcon,
    hidden: true,
    group: false,
    list: InstrucaoAdmissaoForm
  },
  {
    name: 'interesses',
    label: 'Áreas de Interesse',
    pathname: '/interesses',
    icon: ScatterPlotIcon,
    group: false,
    detail: InteresseMasterDetail,
    list: Interesse
  },
  {
    name: 'agendamentos-administrativo',
    label: 'Agendamentos',
    pathname: '/processo-seletivo-administrativo/:processoId/agendamentos',
    icon: ScatterPlotIcon,
    group: false,
    hidden: true,
    detail: AgendamentoAdministrativoMasterDetail,
    list: AgendamentoAdministrativo
  },
  {
    name: 'conclusao-administrativo',
    label: 'Conclusão',
    pathname: '/processo-seletivo-administrativo/:processoId/conclusao',
    icon: ScatterPlotIcon,
    hidden: true,
    group: false,
    list: DocumentosAdministrativoForm
  },
  {
    name: 'cargos',
    label: 'Cargos',
    icon: WorkIcon,
    group: true,
    items: [
      {
        name: 'cargo',
        label: 'Cargos',
        pathname: '/cargo',
        icon: BusinessCenterIcon,
        group: false,
        detail: CargoDetail,
        list: Cargo
      },
      {
        name: 'cargoArea',
        label: 'Cargo Área',
        pathname: '/cargo-area',
        icon: BusinessIcon,
        group: false,
        detail: CargoAreaDetail,
        list: CargoArea
      },
      {
        name: 'cargoCategoria',
        label: 'Cargo Categoria',
        pathname: '/cargo-categoria',
        icon: CategoryIcon,
        group: false,
        detail: CargoCategoriaDetail,
        list: CargoCategoria
      },
      {
        name: 'beneficio',
        label: 'Benefícios',
        pathname: '/beneficio',
        icon: CardGiftcardIcon,
        group: false,
        detail: BeneficioDetail,
        list: Beneficio
      }
    ]
  },
  {
    name: 'vagas',
    label: 'Vagas',
    pathname: '/vagas',
    icon: EventSeatIcon,
    group: false,
    detail: VagaMasterDetail,
    list: Vagas
  },
  {
    name: 'label-caracteristicas',
    label: 'Labels Características',
    pathname: '/label-caracteristicas',
    icon: LabelIcon,
    group: false,
    detail: LabelCaracteristicaMasterDetail,
    list: LabelCaracteristicas
  },
  {
    name: 'entrevista-administrativo',
    label: 'Entrevista',
    pathname: '/processo-seletivo-administrativo/:processoId/entrevista',
    icon: ScatterPlotIcon,
    hidden: true,
    group: false,
    list: TestesProcessoAdministrativoForm
  },
  {
    name: 'triagem-candidatos-administrativo',
    label: 'Triagem de Candidatos(as) Administrativos',
    pathname: '/triagem-candidatos-administrativo',
    icon: GroupAddIcon,
    group: false,
    list: CandidatoVagaForm
  },
  {
    name: 'selecao-candidatos-administrativo',
    label: 'Seleção de Candidatos Administrativo',
    pathname: '/triagem-candidatos-administrativo/selecao-candidatos',
    icon: GroupAddIcon,
    group: false,
    hidden: true,
    list: SelecaoCandidatos
  },
  {
    name: 'vaga-info',
    label: 'Informações da Vaga',
    pathname: '/vagas-info/:vagaId',
    icon: GroupAddIcon,
    group: false,
    hidden: true,
    list: VagaInfo
  },
  {
    name: 'treinamentos',
    label: 'Treinamentos',
    pathname: '/treinamentos',
    icon: SchoolIcon,
    group: false,
    detail: TreinamentosMasterDetail,
    list: Treinamentos
  },
  {
    name: 'treinamento-info',
    label: 'Informações do Treinamento',
    pathname: '/treinamento-info/:treinamentoId',
    icon: null,
    group: false,
    hidden: true,
    list: Treinamento
  },
  {
    name: 'inscricao-candidatos-treinamento',
    label: 'Inscrição de Candidatos(as) para Treinamentos',
    pathname: '/inscricao-candidatos-treinamento',
    icon: CastForEducationIcon,
    group: false,
    list: CandidatoTreinamentoForm
  },
  {
    name: 'selecao-candidatos-treinamento',
    label: 'Seleção de Candidatos(as) para Treinamentos',
    pathname: '/inscricao-candidatos-treinamento/selecao-candidatos',
    icon: CastForEducationIcon,
    group: false,
    hidden: true,
    list: SelecaoCandidatosTreinamento
  },
  {
    name: 'candidato-treinamento',
    label: 'candidato-treinamento',
    pathname: '/candidato-treinamento',
    icon: null,
    group: false,
    hidden: true,
    detail: CandidatoTreinamentoDetail
  }
]

export default menuItems
