import Button from '@material-ui/core/Button'
import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import When from './When'

interface SimpleDialogProps {
  open: boolean
  buttonLabel: string
  handleClose: () => void
  primaryAction?: () => void
  primaryActionButtonLabel: string
  title: string
  message?: string
  children?: React.ReactNode
}

function SimpleDialog({
  open,
  buttonLabel = 'Ok',
  handleClose,
  primaryAction,
  primaryActionButtonLabel,
  title,
  message,
  children
}: SimpleDialogProps) {
  const handlePrimaryAction = () => {
    handleClose()
    primaryAction && primaryAction()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message ?? children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          color={primaryAction ? 'default' : 'primary'}
          autoFocus
        >
          {buttonLabel}
        </Button>
        <When value={!!primaryAction} equals={true}>
          <Button
            onClick={handlePrimaryAction}
            variant="contained"
            color="primary"
            autoFocus
          >
            {primaryActionButtonLabel}
          </Button>
        </When>
      </DialogActions>
    </Dialog>
  )
}

export default SimpleDialog
