import { useSnackbar } from '@elentari/core'
import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Grid, InputAdornment } from '@material-ui/core'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import CurrencyInput from 'src/components/Formik/Forms/CurrencyInput'
import AutoCompleteInput from 'src/components/Formik/Forms/AutoCompleteInput'
import SelectInput from 'src/components/Formik/Forms/SelectInput'
import TextInput from 'src/components/Formik/Forms/TextInput'
import { useAppBar } from 'src/hooks'
import { setTimeout } from 'timers'
import * as yup from 'yup'
import { FormWrap, Paper } from '../../../../components'
import { MessagesYup } from '../../../messages'
import { CargoAreaRepository } from '../../cargo-area/hooks/CargoAreaRepository'
import { CargoCategoriaRepository } from '../../cargo-categoria/hooks/CargoCategoriaRepository'
import { CargoRepository } from '../hooks/CargoRepository'
import { useCargo } from '../hooks/useCargo'
import { CargoDetail, CargoFormData, StatusCargo } from '../types'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import RichTextInput from 'src/components/Formik/Forms/RichTextInput'

interface Option {
  id: string
  name: string
}

interface Params {
  id: string
}

export const CargoForm = () => {
  const [cargoState, cargoAction] = useCargo()
  const params = useParams<Params>()
  const [cargoAreas, setCargoAreas] = useState<Option[]>([])
  const [cargoCategoria, setCargoCategorias] = useState<Option[]>([])
  const [, appBarActions] = useAppBar()
  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const location = useLocation()
  const userLogged = useLoggedUser()

  useEffect(() => {
    loadCargoAreas()
    loadCargoCategorias()
  }, [])

  useEffect(() => {
    async function fetchCargo() {
      if (cargoState.tag === 'with-data') {
        cargoState.tag === 'with-data' &&
          appBarActions.setTitle(cargoState.entity.nome)
      }
    }
    fetchCargo()
  }, [cargoState])

  useEffect(() => {
    async function fetchCargo() {
      if (params && params.id !== 'new' && location.pathname) {
        await cargoAction.fetch(params.id)
      }
    }
    fetchCargo()
  }, [location])

  useEffect(() => {
    return cargoAction.reset()
  }, [cargoAction])

  const loadCargoAreas = async () => {
    const response = await CargoAreaRepository.fetch({
      sort: {
        descricao: 'asc'
      },
      limit: '999'
    })

    if (response.ok) {
      setCargoAreas(
        response.data?.edges.map(area => ({
          id: area.node.descricao,
          name: area.node.descricao
        })) ?? []
      )
    }
  }

  const loadCargoCategorias = async () => {
    const response = await CargoCategoriaRepository.fetch({
      sort: {
        descricao: 'asc'
      },
      limit: '999'
    })

    if (response.ok) {
      setCargoCategorias(
        response.data?.edges.map(categoria => ({
          id: categoria.node.descricao,
          name: categoria.node.descricao
        })) ?? []
      )
    }
  }

  const { save } = useEntitySaver<CargoFormData>(async data => {
    const response = await CargoRepository.save(data)
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return response
  })

  const formikState = {
    initialValues: {
      nome: '',
      area: '',
      categoria: '',
      descricao: '',
      empresaId: '',
      nivelExperiencia: '',
      salarioMax: 0,
      salarioMin: 0,
      status: 'ATIVO',
      criadoPor: userLogged?.name
    } as CargoDetail,
    onSubmit: async (data: CargoFormData) => {
      try {
        setLoading(true)

        data.salarioMin = Number(data.salarioMin)
        data.salarioMax = Number(data.salarioMax)

        await save(data)
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  const schemaObject = {
    nome: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    area: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    categoria: yup
      .string()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    salarioMin: yup
      .number()
      .moreThan(0, MessagesYup.MENSAGEM_VALOR_ZERO)
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO),
    salarioMax: yup
      .number()
      .moreThan(0, MessagesYup.MENSAGEM_VALOR_ZERO)
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO)
      .test({
        name: 'salario-max-valid',
        message: "O valor informado é inferior ao 'Salário Mínimo'",
        test: (salarioMax, context) => {
          if (!salarioMax || !context.parent.salarioMin) {
            return false
          }
          return salarioMax >= context.parent.salarioMin
        }
      }),
    descricao: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    nivelExperiencia: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    status: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    criadoPor: yup.string().trim()
  }
  const schema = yup.object().shape(schemaObject)

  const formatData = (values: CargoDetail) => {
    return {
      ...values,
      salarioMin: Number(values.salarioMin),
      salarioMax: Number(values.salarioMax),
      criadoPor: values.criadoPor || userLogged?.name
    }
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={formikState.onSubmit}
      initialValues={
        cargoState.tag === 'with-data'
          ? formatData(cargoState.entity)
          : formikState.initialValues
      }
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <Paper noTopBorderRadius>
          <FormWrap
            handleSubmit={handleSubmit}
            disableSubmit={isSubmitting}
            disableBack={isSubmitting}
            loading={loading}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput name="nome" label="Nome do Cargo" />
              </Grid>
              <Grid item xs={6}>
                <AutoCompleteInput
                  name="area"
                  label="Área"
                  options={cargoAreas}
                  forceFieldValue
                />
              </Grid>
              <Grid item xs={6}>
                <AutoCompleteInput
                  name="categoria"
                  label="Categoria"
                  options={cargoCategoria}
                  forceFieldValue
                />
              </Grid>
              <Grid item xs={4}>
                <CurrencyInput
                  name="salarioMin"
                  label="Salário Mínimo"
                  startAdornment={
                    <InputAdornment position="start">R$</InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <CurrencyInput
                  name="salarioMax"
                  label="Salário Máximo"
                  startAdornment={
                    <InputAdornment position="start">R$</InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  name="nivelExperiencia"
                  label="Nível de Experiência"
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  name="status"
                  label="Status"
                  options={StatusCargo}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput name="criadoPor" label="Criado por" disabled />
              </Grid>
              <Grid item xs={12}>
                <RichTextInput name="descricao" label="Descrição" />
              </Grid>
            </Grid>
          </FormWrap>
        </Paper>
      )}
    </Formik>
  )
}
