import { Button, Grid, makeStyles } from '@material-ui/core'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Paper } from 'src/components'
import { CurriculosBackground } from '../../components/CurriculosBackground'
import curriculosBackground from '../../images/curriculos-background.png'
import g10Logo from '../../images/g10-logo-2.png'
import tpLogo from '../../images/tp-logo.png'

const useStyles = makeStyles(theme => ({
  gridPaper: {
    minHeight: '100vh',
    padding: '20px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 20px'
    }
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px'
  },
  g10Logo: {
    width: '220px',
    height: '70px',
    marginLeft: '30px',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      height: '50%'
    }
  },
  tpLogo: {
    width: '440px',
    height: '70px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%'
    }
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      height: '3rem'
    }
  }
}))

interface HomeScreenProps {}

export function HomeScreen({}: HomeScreenProps) {
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    document.title = `RH - Envio de Currículos`
    const favIcon = document.getElementById('icon') as HTMLLinkElement

    favIcon.href = '/favicon.ico'
  }, [])

  return (
    <CurriculosBackground backgroundImage={curriculosBackground}>
      <Grid
        container
        className={classes.gridPaper}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Paper>
            <Grid container spacing={6}>
              <Grid item container xs={12} md={6} spacing={2}>
                <Grid item xs={12}>
                  <div className={classes.logoContainer}>
                    <img src={g10Logo} className={classes.g10Logo} />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => history.push(`/curriculos?empresa=G10`)}
                  >
                    Ir para Envio G10
                  </Button>
                </Grid>
              </Grid>
              <Grid item container xs={12} md={6} spacing={2}>
                <Grid item xs={12}>
                  <div className={classes.logoContainer}>
                    <img src={tpLogo} className={classes.tpLogo} />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      history.push(`/curriculos?empresa=Transpanorama`)
                    }
                  >
                    Ir para Envio Transpanorama
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </CurriculosBackground>
  )
}
