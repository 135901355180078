export type EmpresaType = 'G10' | 'TRANSPANORAMA' | 'HOME'
export const empresas: Array<EmpresaType> = ['G10', 'TRANSPANORAMA']
export type CurriculoType = 'administrativo' | 'motorista' | 'operacional'

export interface Curso {
  descricao: string
  dataConclusao?: Date
  dataInicio?: Date
  cargaHoraria: number
}

export interface AreaInteresse {
  id: string
}

export interface ExperienciaProfissional {
  empresa: string
  cargoFuncao: string
  cidade: string
  uf: string
  telefone: string
  dataEntrada?: Date
  dataSaida?: Date
  atualmenteEmpregado: boolean
  principaisAtividades: string
  tempoExperienciaCaminhao: number
}

export interface Motorista {
  candidatoId?: number
  estadoCivil: string
  indicacao: boolean
  tipoCaminhao: string
  tempoExperienciaCaminhao?: number
  rg: string
  categoriaCNH: string
  pamcary: boolean
  earCNH: boolean
  grauEscolaridade: string
  moop: boolean
  quemIndicou: string
  cargoQuemIndicou: string
  coordenadorQuemIndicou: string
}

export interface Curriculo {
  nomeCompleto: string
  uf: string
  tipo: string
  telefone: string
  celular: string
  origemCurriculo: string
  cidade: string
  email: string
  cpf: string
  primeiroEmprego: boolean
  recaptcha: string
  Cursos: Curso[]
  dataNascimento: Date
  empresaId: string
  AreaInteresse: AreaInteresse[]
  Motorista: Motorista
  ExperienciaProfissional: ExperienciaProfissional[]
  vagasId?: string[]
  documentos?: { id: string }[]
}

export interface InfoFile {
  file?: File
  name: string
  path: string
}