import { ListaBloqueioDetail } from "src/modules/lista-bloqueios/types"
import { apiSauceInstance } from "src/services/api"

export const bloquearPorCpf = async (data: {cpf: string; motivo: string}) => {
  const response = await apiSauceInstance.post<ListaBloqueioDetail>(
    '/lista-bloqueios/cpf',
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const bloquearCandidato = async (data: {cpf: string; motivo: string}) => {
  const response = await apiSauceInstance.post<ListaBloqueioDetail>(
    '/lista-bloqueios/candidato',
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}