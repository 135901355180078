import { apiSauceInstance } from "src/services/api"

export const validateTokenAndUpdateStatusCandidato = async (
  token: string
) => {
  const response = await apiSauceInstance.get<any>(
    `/candidato/contratar-dp/${token}`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }

  return {
    info
  }
}