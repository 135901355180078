import { apiSauceInstance } from 'src/services/api'
import { VagaRequisitoDetail } from '../types'

export const createVagaRequisito = async (data: VagaRequisitoDetail) => {
  const response = await apiSauceInstance.post<any>(
    `/vagas-requisitos/create-many`,
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }

  return response.data
}

export const getAllVagaRequisitoByVagaId = async (VagaId: string) => {
  const response = await apiSauceInstance.get<any>(
    `/vagas-requisitos/vagas/${VagaId}`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}
