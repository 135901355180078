import {
  IconButton,
  Tooltip,
} from "@material-ui/core"
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled'

export const ExFuncionarioIcon = () => {
  return (
    <Tooltip title="Candidato(a) é um(a) Ex-Funcionário(a)">
      <IconButton aria-label="ex-funcionario">
        <PersonAddDisabledIcon />
      </IconButton>
    </Tooltip>
  )
}