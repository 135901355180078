import { EdgesPage } from '@elentari/core/types'
import { observer } from 'mobx-react-lite'
import QueryString from 'qs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AdministrativoDetail } from 'src/modules/candidato/administrativo/types'
import { useAbility } from 'src/modules/login'
import { apiSauceInstance } from 'src/services/api'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import triagemStore from '../store/store'
import { AprovadosTriagemAdministrativoTable } from './AprovadosTriagemAdministrativoTable'
import { TriadoAdministrativoFilter } from './TriadoAdministrativoFilter'


const AprovadosTriagemAdministrativoList = (props: {
  list: { node: AdministrativoDetail }[]
  loading: boolean
  totalCount: number
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}) => {
  return (
    <>
      <TriadoAdministrativoFilter statusTriagem="APROVADO" />
      <AprovadosTriagemAdministrativoTable {...props} />
    </>
  )
}

type APIResponseCandidatos = {
  edges: { node: AdministrativoDetail }[]
  totalCount: number
  loading: boolean
}

const AprovadosTriagemAdministrativo = observer(() => {
  const [candidatosState, setCandidatosState] = useState<APIResponseCandidatos>(
    {} as APIResponseCandidatos
  )
  const { search, pathname } = useLocation()
  const ability = useAbility()

  const authorization = getAuthorizations(ability, 'aprovadosTriagemAdministrativos')

  useEffect(() => {
    triagemStore.resetCandidatosId()
  }, [])

  useEffect(() => {
    async function loadCandidatosAdministrativos() {
      try {
        setCandidatosState({
          ...candidatosState,
          loading: true
        })

        const params: { [key: string]: any } = {
          sort: { createdAt: 'desc' },
          incluirBloqueados: 'false',
          ...QueryString.parse(search.substring(1))
        }

        const { data } = await apiSauceInstance.get<
          EdgesPage<AdministrativoDetail>
        >(`/candidato?${QueryString.stringify(params)}`, {
          tipo: 'ADMINISTRATIVO',
          statusCandidato: 'TRIADO',
          statusTriagem: 'APROVADO',
          skip: params.page ? (+params.page - 1) * 10 : 0,
          limit: 10
        })
        if (data) {
          setCandidatosState({
            totalCount: data.totalCount,
            edges: data.edges,
            loading: false
          })
        }
      } catch (error) { }
    }
    loadCandidatosAdministrativos()
  }, [pathname, search])

  return (
    <>
      {candidatosState.loading === false ? (
        <AprovadosTriagemAdministrativoList
          totalCount={candidatosState.totalCount}
          list={candidatosState.edges}
          loading={candidatosState.loading}
          authorization={authorization}
        />
      ) : (
        <AprovadosTriagemAdministrativoList
          totalCount={0}
          list={[]}
          loading={candidatosState.loading}
          authorization={authorization}
        />
      )}
    </>
  )
})

export default AprovadosTriagemAdministrativo