import { LinearProgress } from "@material-ui/core"
import { useEffect, useState } from "react"
import ReactHtmlParser from 'react-html-parser'
import { When } from "src/components"
import { repository } from "../hooks/triagemRepository"
import { TriagemDetail } from "../types"
import { formatMotivoTriagem } from "../utils/triagemOptions"
import { ModalDetailLabel } from "./ModalDetailLabel"

type TriagemModalDetailsProps = {
  idTriagem?: string
  triagem?: TriagemDetail
}

export const TriagemModalDetails = ({ idTriagem, triagem }: TriagemModalDetailsProps) => {
  const [triagemCandidato, setTriagemCandidato] = useState<TriagemDetail | undefined>(triagem)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    async function getTriagemById(id: string) {
      setLoading(true)
      const response = await repository.fetchOne(id)

      if (response.ok) {
        setTriagemCandidato(response.data)
      }
      setLoading(false)
    }

    if (idTriagem && !triagem) {
      getTriagemById(idTriagem)
    }
  }, [idTriagem])

  return (
    <>
      <When value={loading} equals>
        <LinearProgress />
      </When>
      {triagemCandidato && (
        <>
          <ModalDetailLabel
            title="Status:"
            subtitle={triagemCandidato.atendeCriterios ? 'Aprovado' : 'Reprovado'}
          />
          <ModalDetailLabel
            title="Motivo:"
            subtitle={formatMotivoTriagem(triagemCandidato.atendeCriterios, triagemCandidato.motivo) || '-'}
          />
          <ModalDetailLabel
            title="Triado por:"
            subtitle={triagemCandidato.triadoPor}
          />
          <ModalDetailLabel
            title="Grid:"
            subtitle={triagemCandidato.gridFK?.nome}
            show={Boolean(triagemCandidato.gridFK)}
          />
          <ModalDetailLabel
            title="Outros Motivos:"
            subtitle={triagemCandidato.comentario && ReactHtmlParser(triagemCandidato.comentario)}
          />
        </>
      )}
    </>
  )
}