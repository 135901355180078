import { makeAutoObservable } from 'mobx'

class HistoricoCandidatoStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  page: number = 1
  sort: { [key: string]: 'asc' | 'desc' } = {}

  setPage(newPage: number) {
    this.page = newPage
  }

  setSort(newSort: { [key: string]: 'asc' | 'desc' }) {
    this.sort = newSort
  }

}

const historicoCandidatoStore = new HistoricoCandidatoStore()
export default historicoCandidatoStore
