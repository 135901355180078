import { FormLabel, Grid, makeStyles, Typography } from "@material-ui/core"
import { ResultadoSelecaoFormItem } from "./ResultadoSelecaoFormItem"
import { ResultadoSelecaoLabel } from "./ResultadoSelecaoLabel"

const useStyles = makeStyles(theme => ({
  minWidth: {
    minWidth: '720px'
  },
  fontBold: {
    fontWeight: 'bold'
  }
}))

export const ResultadoSelecaoForm = ({
  disabled
}: {
  disabled: boolean
}) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2} className={classes.minWidth}>
      <Grid item xs={12}>
        <FormLabel>
          <Typography
            variant="h6"
            className={classes.fontBold}
            component="h2"
          >
            Resultado Seleção
          </Typography>
        </FormLabel>
      </Grid>
      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={6} />

          <Grid item xs={3}>
            <ResultadoSelecaoLabel
              label="Apto"
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={3}>
            <ResultadoSelecaoLabel
              label="Inapto"
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={8} />

      <ResultadoSelecaoFormItem
        label="Teste Prático"
        fieldsNames={{
          checkbox: "testePratico",
          data: "dataTestePratico",
          observacao: "observacaoTestePratico"
        }}
        disabled={disabled}
      />

      <ResultadoSelecaoFormItem
        label="Teste Psicológico"
        fieldsNames={{
          checkbox: "testePsicologico",
          data: "dataTestePsicologico",
          observacao: "observacaoTestePsicologico"
        }}
        disabled={disabled}
      />

      <ResultadoSelecaoFormItem
        label="Teste Técnico"
        fieldsNames={{
          checkbox: "testeTecnico",
          data: "dataTesteTecnico",
          observacao: "observacaoTesteTecnico"
        }}
        disabled={disabled}
      />

      <ResultadoSelecaoFormItem
        label="Entrevista RH"
        fieldsNames={{
          checkbox: "entrevistaRh",
          data: "dataEntrevistaRh",
          observacao: "observacaoEntrevistaRh"
        }}
        disabled={disabled}
      />

      <ResultadoSelecaoFormItem
        label="Entrevista Gestor"
        fieldsNames={{
          checkbox: "entrevistaGestor",
          data: "dataEntrevistaGestor",
          observacao: "observacaoEntrevistaGestor"
        }}
        disabled={disabled}
      />
    </Grid>
  )
}