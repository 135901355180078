import { ReactNode } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Theme, withStyles } from '@material-ui/core/styles'

type Props = {
  classes: { close: string }
  message: string
  action?: ReactNode[]
  onClose: () => void
}

function CustomSnackbar({ classes, message, action = [], ...props }: Props) {
  return (
    <Snackbar
      {...props}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={!!message}
      ContentProps={{
        'aria-describedby': 'message-id'
      }}
      action={[
        ...action,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={props.onClose}
        >
          <CloseIcon />
        </IconButton>
      ]}
      message={<span id="message-id">{message}</span>}
    />
  )
}

const styles = (theme: Theme) => ({
  close: {
    padding: theme.spacing(1 / 2)
  }
})

export default withStyles(styles)(CustomSnackbar)
