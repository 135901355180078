import Filter from '@elentari/components-eve/components/Filter'
import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import {
  Grid, Tooltip
} from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import SelectField from 'src/components/Forms/SelectField'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import * as yup from 'yup'
import { booleanOptions, etapaProcesso, isG10, tipoContratacao, tipoEntrevista, tipoEntrevistaG10 } from '../../../utils'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import { notasClassificacao } from '../types'

type AgendamentoCandidatoQueryFilter = {
  createdAt?: { gt: string }
  [key: string]: any
}

const schema = yup.object().shape({
  createdAt: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  dataAgendamentoStart: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  dataAgendamentoEnd: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .test({
      name: 'data-agendado-valid',
      message: "A data informada é inferior a 'Data para o qual foi Agendado de'",
      test: (dataAgendamentoEnd, context) => {
        if (!dataAgendamentoEnd || !context.parent.dataAgendamentoStart) {
          return true
        }
        return dataAgendamentoEnd >= context.parent.dataAgendamentoStart
      }
    }),
})

const AgendamentoCandidatoFilter = ({
  candidatoType
}: {
  candidatoType: 'ADMINISTRATIVO' | 'MOTORISTA'
}) => {
  const isMotorista = candidatoType === 'MOTORISTA'

  const { clear, push, initialValues } =
    useQueryParams<AgendamentoCandidatoQueryFilter>()

  const userLogged = useLoggedUser()
  const userEmpresaIsG10 = isG10(userLogged?.empresa)

  const handleSubmitFilter = (values: any) => {
    if (values.notaClassificacaoCandidatos === null) {
      delete values.notaClassificacaoCandidatos
    }
    if (values.tipoEntrevista === null) {
      delete values.tipoEntrevista
    }
    if (values.tipoContratacao === null) {
      delete values.tipoContratacao
    }
    if (values.createdAt) {
      values.createdAt = new Date(values.createdAt).toISOString()
    }
    if (values.dataAgendamentoStart) {
      values.dataAgendamentoStart = new Date(values.dataAgendamentoStart).toISOString()
    }
    if (values.dataAgendamentoEnd) {
      values.dataAgendamentoEnd = new Date(values.dataAgendamentoEnd).toISOString()
    }
    if (values.etapa === null) {
      delete values.etapa
    }
    push({
      ...values
    })
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={{
        ...initialValues,
        incluirBloqueados: 'false'
      }}
      onClear={clear}
      onSubmit={values => handleSubmitFilter(values)}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4} sm={12} md={4}>
          <TextField fullWidth name="agendadoPor" label="Agendado por" />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <DatePicker
            fullWidth
            name="createdAt"
            label={userEmpresaIsG10 ? "Data do Agendamento" : "Data em que o Agendamento Aconteceu"}
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <Select
            fullWidth
            name="tipoEntrevista"
            label="Tipo de Entrevista"
            items={userEmpresaIsG10 ? tipoEntrevistaG10 : tipoEntrevista}
          />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <Select
            fullWidth
            name="tipoContratacao"
            label="Tipo da Contratação"
            items={tipoContratacao}
          />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <SelectField
            name="incluirBloqueados"
            label="Lista Bloqueio"
            items={booleanOptions}
            defaultValue={'false'}
          />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <Select
            fullWidth
            name="notaClassificacaoCandidatos"
            label="Classificação"
            items={notasClassificacao}
          />
        </Grid>
        {userEmpresaIsG10 ? (
          <>
            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Select
                fullWidth
                name="etapa"
                label="Etapa"
                items={etapaProcesso}
              />
            </Grid>
            <Grid item xs={12} lg={3} sm={12} md={6}>
              <TextField fullWidth name="nomeCandidato" label="Nome Completo" />
            </Grid>
            <Grid item xs={12} lg={3} sm={6} md={6}>
              <DatePicker
                fullWidth
                name="dataAgendamentoStart"
                label="Data do Processo de"
                keyboardIcon={
                  <Tooltip title="Selecionar data">
                    <EventIcon />
                  </Tooltip>
                }
              />
            </Grid>
            <Grid item xs={12} lg={3} sm={6} md={6}>
              <DatePicker
                fullWidth
                name="dataAgendamentoEnd"
                label="Data do Processo até"
                keyboardIcon={
                  <Tooltip title="Selecionar data">
                    <EventIcon />
                  </Tooltip>
                }
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} lg={4} sm={6} md={4}>
              <TextField fullWidth name="nomeCandidato" label="Nome Completo" />
            </Grid>
            <Grid item xs={12} lg={4} sm={6} md={4}>
              <DatePicker
                fullWidth
                name="dataAgendamentoStart"
                label="Data para o qual foi Agendado de"
                keyboardIcon={
                  <Tooltip title="Selecionar data">
                    <EventIcon />
                  </Tooltip>
                }
              />
            </Grid>
            <Grid item xs={12} lg={4} sm={6} md={4}>
              <DatePicker
                fullWidth
                name="dataAgendamentoEnd"
                label="Data para o qual foi Agendado até"
                keyboardIcon={
                  <Tooltip title="Selecionar data">
                    <EventIcon />
                  </Tooltip>
                }
              />
            </Grid>
          </>
        )}
      </Grid>
    </Filter>
  )
}

export { AgendamentoCandidatoFilter }

