import { useSnackbar } from '@elentari/core'
import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Grid, InputAdornment } from '@material-ui/core'
import { Formik, FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import SimpleCheckbox from 'src/components/Formik/Forms/ChekboxInput'
import SelectInput from 'src/components/Formik/Forms/SelectInput'
import TextInput from 'src/components/Formik/Forms/TextInput'
import { useAppBar } from 'src/hooks'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import * as yup from 'yup'
import { Paper } from '../../../components'
import { MessagesYup } from '../../messages'
import { VagaFormikInput } from '../components/VagaFormikInput'
import { useVaga } from '../hooks/useVaga'
import { vagasRepository } from '../hooks/vagaRepository'
import {
  ModoSelecao,
  MotivoContratacao,
  motivoContratacao,
  SaveVagaInput,
  StatusVagas,
  VagaDetail
} from '../types'
import { VagaFormWrap } from '../components/VagaFormWrap'
import RichTextInput from 'src/components/Formik/Forms/RichTextInput'

interface Params {
  id: string
}
const schema = yup.object().shape({
  cargoId: yup.string().nullable(),
  descricao: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  status: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO).nullable(),
  categoria: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  modoSelecao: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  area: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  nivelExperiencia: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  motivoContratacao: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  nomeSubstituicao: yup
    .string()
    .nullable()
    .when('motivoContratacao', {
      is: (motivoContratacao: string) =>
        motivoContratacao === MotivoContratacao.SUBSTITUICAO,
      then: yup
        .string()
        .trim()
        .required(MessagesYup.MENSAGEM_OBRIGATORIO)
        .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
    }),
  salarioMin: yup
    .number()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .moreThan(0, MessagesYup.MENSAGEM_VALOR_ZERO)
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO),
  salarioMax: yup
    .number()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .test({
      name: 'salario-max-valid',
      message: "O valor informado é inferior ao 'Salário Mínimo'",
      test: (salarioMax, context) => {
        if (!salarioMax || !context.parent.salarioMin) {
          return false
        }
        return salarioMax >= context.parent.salarioMin
      }
    }),
  salario: yup
    .number()
    .when('hasSalarioLimite', (hasSalarioLimite, field) =>
      !hasSalarioLimite
        ? field
            .moreThan(0, MessagesYup.MENSAGEM_VALOR_ZERO)
            .test({
              name: 'salario-between-values',
              message:
                "O valor informado é fora do intervalo de 'Salário Mínimo' e 'Salário Máximo'",
              test: (
                salario: string,
                context: yup.TestContext<Record<string, any>>
              ) => {
                if (
                  !salario ||
                  !context.parent.salarioMin ||
                  !context.parent.salarioMax
                ) {
                  return false
                }
                return (
                  salario <= context.parent.salarioMax &&
                  salario >= context.parent.salarioMin
                )
              }
            })
            .required(MessagesYup.MENSAGEM_OBRIGATORIO)
        : field
    )
    .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO)
})

export const VagasForm = () => {
  const params = useParams<Params>()

  const [VagasState, VagasAction] = useVaga()
  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()
  const isNew = params.id === 'new'
  const userLogged = useLoggedUser()
  const isGestor = userLogged?.rolesInSystem.includes('GESTOR')
  const isAdmin = userLogged?.rolesInSystem.includes('ADMIN')
  const isResponsavelRh =
    userLogged?.rolesInSystem.includes('GESTOR_RH') || isAdmin
  const disableFields =
    !isNew &&
    !(
      isGestor &&
      VagasState.tag === 'with-data' &&
      VagasState.entity.status === 'PENDENTE_APROVACAO'
    ) &&
    !isAdmin
  const location = useLocation()

  const { save } = useEntitySaver<SaveVagaInput>(async data => {
    const response = await vagasRepository.save(data)
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return response
  })

  const formikState = {
    initialValues: {
      area: '',
      descricao: '',
      empresaId: '',
      modoSelecao: '',
      nivelExperiencia: '',
      nomeSubstituicao: '',
      salario: 0,
      salarioMax: 0,
      salarioMin: 0,
      hasSalarioLimite: false,
      status: 'PENDENTE_APROVACAO',
      vagaSigilosa: false,
      cargoId: '',
      categoria: '',
      motivoContratacao: '',
      solicitadoPor: userLogged?.name,
      divulgaSite: false,
      equipamentos: ''
    } as VagaDetail,
    onSubmit: async (data: FormikValues) => {
      try {
        setLoading(true)
        delete data.cargoFk
        if (data.motivoContratacao === MotivoContratacao.AUMENTO_QUADRO) {
          data.nomeSubstituicao = ''
        }
        if (data.hasSalarioLimite) {
          if (isNew) {
            data.status = 'PENDENTE_APROVACAO'
          }
        }
        if (!data.emailSolicitadoPor) {
          data.emailSolicitadoPor = userLogged?.username
        }
        save(data as SaveVagaInput)
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  useEffect(() => {
    async function fetchVaga() {
      if (VagasState.tag === 'with-data') {
        VagasState.tag === 'with-data' &&
          appBarActions.setTitle(VagasState.entity.cargoFk?.nome ?? 'Cargo')
      }
    }
    fetchVaga()
  }, [VagasState])

  useEffect(() => {
    async function fetchVaga() {
      if (params && params.id !== 'new' && location.pathname) {
        await VagasAction.fetch(params.id)
      }
    }
    fetchVaga()
  }, [location])

  useEffect(() => {
    return VagasAction.reset()
  }, [VagasAction])

  const formatData = (values: VagaDetail) => {
    return {
      ...values,
      responsavelRH: values.responsavelRH
        ? values.responsavelRH
        : isResponsavelRh
        ? userLogged?.name
        : undefined,
      motivoContratacao: values.nomeSubstituicao
        ? MotivoContratacao.SUBSTITUICAO
        : MotivoContratacao.AUMENTO_QUADRO,
      // nomeSubstituicao: values.motivoContratacao === MotivoContratacao.SUBSTITUICAO ? values.nomeSubstituicao : undefined,
      salario: Number(values.salario),
      salarioMin: Number(values.salarioMin),
      salarioMax: Number(values.salarioMax)
    }
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={formikState.onSubmit}
      enableReinitialize
      initialValues={
        VagasState.tag === 'with-data'
          ? formatData(VagasState.entity)
          : formikState.initialValues
      }
    >
      {({ values }) => (
        <Paper noTopBorderRadius>
          <VagaFormWrap vagaId={params.id} formLoading={loading} isNew={isNew}>
            <Grid container spacing={2}>
              <VagaFormikInput isDisabled={disableFields || false} />

              <Grid item xs={12} sm={6}>
                <TextInput
                  name="solicitadoPor"
                  label="Solicitado Por"
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <SelectInput
                  name="status"
                  label="Status da Vaga"
                  options={StatusVagas}
                  defaultValue={isNew ? 'PENDENTE_APROVACAO' : ''}
                  disabled={!isNew && !isAdmin}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <SelectInput
                  name="modoSelecao"
                  label="Modo da Seleção"
                  disabled={disableFields}
                  options={ModoSelecao}
                />
              </Grid>

              <Grid item xs={12} md={6} sm={6}>
                <SelectInput
                  name="motivoContratacao"
                  label="Motivo Contratação"
                  options={motivoContratacao}
                  firstOptionDisabled={true}
                  disabled={disableFields}
                />
              </Grid>

              <Grid item xs={12} md={3} sm={3}>
                <SimpleCheckbox
                  name="vagaSigilosa"
                  label="Vaga Sigilosa"
                  disabled={disableFields}
                />
              </Grid>
              <Grid item xs={12} md={3} sm={3}>
                <SimpleCheckbox
                  name="divulgaSite"
                  label="Divulga Site"
                  disabled={disableFields}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextInput
                  name="nomeSubstituicao"
                  label="Nome Substituição"
                  disabled={
                    disableFields ||
                    values.motivoContratacao !== MotivoContratacao.SUBSTITUICAO
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <RichTextInput
                  name="equipamentos"
                  label="Equipamentos"
                  disabled={disableFields}
                />
              </Grid>
              {!isNew && (
                <Grid item xs={12}>
                  <TextInput
                    name="responsavelRH"
                    label="Responsável RH"
                    disabled={!isResponsavelRh}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </VagaFormWrap>
        </Paper>
      )}
    </Formik>
  )
}
