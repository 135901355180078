import { useSnackbar } from '@elentari/core'
import {
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useHistory } from 'react-router'
import RichTextField from 'src/components/Forms/RichTextField'
import { AtualFuncionarioIcon } from 'src/components/Icons/AtualFuncionarioIcon'
import { CpfBloqueadoIcon } from 'src/components/Icons/CpfBloqueadoIcon'
import { ExFuncionarioIcon } from 'src/components/Icons/ExFuncionarioIcon'
import { PaperCard } from 'src/components/PaperCard'
import { ListaBloqueioDetail } from 'src/modules/lista-bloqueios/types'
import { useAbility } from 'src/modules/login'
import { MessagesYup } from 'src/modules/messages'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import { getOnlyDigits } from 'src/utils/utils'
import yupValidation from 'src/utils/yupValidation'
import * as yup from 'yup'
import ComplexDialog from '../../../components/ComplexDialog'
import { FormatedDate } from '../../../utils'
import { bloquearPorCpf } from '../hooks/listaBloqueioRepository'
import { CandidatoDetail } from '../types'
import { CandidatoButtons } from './CandidatoButtons'
import CandidatoTreinamentoList from './CandidatoTreinamentoList'
import { CargoCandidatoCard } from './CargoCandidatoCard'
import DocumentoCandidatoList from './documents/DocumentosCandidatoList'
import HistoricoCandidato from './HistoricoCandidato'
import { LabelsCard } from './LabelsCard'
import ProcessoAdministrativoList from './ProcessoAdministrativoList'
import { TriagemCard } from './TriagemCard'

const useStyles = makeStyles(theme => ({
  containerLocation: {
    display: 'flex',
    flexDirection: 'row'
  },
}))

interface IHistory {
  from: string
}

const TempoModal = 100

interface Callback {
  (data: { motivo: string }): Promise<null | void> | void
}

const CandidatoCard = (data: CandidatoDetail) => {
  const history = useHistory<IHistory>()
  const style = useStyles()
  const [, snackbarActions] = useSnackbar()
  const ability = useAbility()
  const listaBloqueioAuthorization = getAuthorizations(ability, 'listaBloqueios')
  const candidatoAuthorization = getAuthorizations(ability, data.tipo === 'MOTORISTA' ? 'motorista' : 'administrativo')

  const [bloqueioCandidato, setBloqueioCandidato] =
    useState<ListaBloqueioDetail | null>(null)
  const [confirmationDialog, setConfirmationDialog] = useState<{
    title: string
    acceptLabel: string
    rejectLabel: string
    open: boolean
    isForm: boolean
    callback: Callback
  }>({
    title: '',
    acceptLabel: '',
    rejectLabel: '',
    isForm: false,
    open: false,
    callback: (data: { motivo: string }) => new Promise(() => { })
  })

  const handleEditCandidato = () => {
    if (data.tipo === 'MOTORISTA') {
      history.push('/motorista/' + data.id)
    } else {
      history.push('/administrativo/' + data.id)
    }
  }

  const handleReturnList = () => {
    if (history.location.state?.from) {
      history.push(history.location.state.from)
    } else {
      if (data.tipo === 'MOTORISTA') {
        history.push('/motorista')
      } else {
        history.push('/administrativo')
      }
    }
  }

  const handleAccessListaBloqueio = () => {
    history.push(
      `/lista-bloqueios/${data.idListaBloqueio ?? bloqueioCandidato?.id}`
    )
  }

  const handleBloquearCandidato = async (modalData: { motivo: string }) => {
    try {
      if (data.cpf) {
        const result = await bloquearPorCpf({
          cpf: getOnlyDigits(data.cpf),
          motivo: modalData.motivo
        })

        if (result?.ok && result.info) {
          setBloqueioCandidato(result.info)
          snackbarActions.setMessage('Candidato(a) bloqueado(a) com sucesso')
        } else {
          snackbarActions.setMessage(
            'Ocorreu um erro ao bloquear o(a) Candidato(a)'
          )
        }
      } else {
        snackbarActions.setMessage(
          'Não foi possível bloquear o Candidato(a). Motivo: Não possui CPF cadastrado'
        )
      }
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  const handleConfirmationDialog = async (data: any) => {
    const { callback } = confirmationDialog
    if (callback) {
      await callback(data)
    }
  }

  const dialogSchema = yup.object().shape({
    motivo: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
  })

  const handleOpenDialog = (
    title: string,
    acceptLabel: string,
    rejectLabel: string,
    isForm: boolean,
    callback: Callback
  ) => {
    setConfirmationDialog({
      title,
      acceptLabel,
      rejectLabel,
      isForm,
      open: true,
      callback
    })
  }

  const closeDialog = () => {
    setConfirmationDialog({
      ...confirmationDialog,
      open: false
    })

    setTimeout(() => {
      setConfirmationDialog({
        title: '',
        acceptLabel: '',
        rejectLabel: '',
        isForm: false,
        open: false,
        callback: (data: { motivo: string }) => new Promise(() => { })
      })
    }, TempoModal)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <PaperCard
          title="Candidato(a)"
          returnButtonText="Retornar para a Listagem"
          returnButtonAction={handleReturnList}
          Content={
            <>
              <Typography variant="h5" color="textSecondary" component="h2">
                {data.nomeCompleto}
              </Typography>
              <Typography style={{ paddingBottom: 20 }} color="textSecondary">
                {data.tipo}
              </Typography>

              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="left"
              >
                <strong>Data de Nascimento: </strong>
                {data.dataNascimento && FormatedDate(data.dataNascimento)}
              </Typography>

              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="left"
              >
                <strong>CPF: </strong> {data.cpf}
              </Typography>
              <div className={style.containerLocation}>
                {data.telefone && (
                  <Typography
                    variant="body2"
                    style={{ marginRight: 10 }}
                    color="textSecondary"
                    component="p"
                    align="left"
                  >
                    <strong>Telefone: </strong> {data.telefone}
                  </Typography>
                )}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  align="left"
                >
                  <strong>Celular: </strong> {data.celular}
                </Typography>
              </div>
              <div className={style.containerLocation}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  align="left"
                >
                  <strong>Cidade: </strong> {data.cidade}
                </Typography>
                <Typography
                  style={{ marginLeft: 10 }}
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  align="left"
                >
                  <strong>UF: </strong> {data.uf}
                </Typography>
              </div>
            </>
          }
          Icons={
            <>
              {candidatoAuthorization.canUpdate && (
                <Tooltip title="Editar Candidato(a)">
                  <IconButton
                    aria-label="edit"
                    onClick={handleEditCandidato}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {data.idListaBloqueio || bloqueioCandidato?.id ? (
                <CpfBloqueadoIcon
                  onClick={
                    listaBloqueioAuthorization.canCreate ? () => {
                      handleOpenDialog(
                        'Deseja acessar os detalhes na página de Lista Bloqueio?',
                        'Acessar',
                        'Não',
                        false,
                        handleAccessListaBloqueio
                      )
                    } : undefined
                  }
                  alignCenter
                />
              ) : listaBloqueioAuthorization.canCreate && (
                <Tooltip title="Adicionar Candidato(a) na Lista de Bloqueio">
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      handleOpenDialog(
                        'Deseja adicionar Candidato(a) na Lista de Bloqueio?',
                        'Bloquear',
                        'Voltar',
                        true,
                        handleBloquearCandidato
                      )
                    }}
                    color="default"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              )}
              {data.atualFuncionario && <AtualFuncionarioIcon />}
              {data.exFuncionario && <ExFuncionarioIcon />}
            </>
          }
        />
        <ComplexDialog
          open={!!confirmationDialog.open}
          handleClose={closeDialog}
          primaryAction={handleConfirmationDialog}
          title={confirmationDialog.title}
          primaryActionButtonLabel={confirmationDialog.acceptLabel}
          closeActionButtonLabel={confirmationDialog.rejectLabel}
          disableBackdropClick={true}
          transitionDuration={TempoModal}
          validate={confirmationDialog.isForm && yupValidation(dialogSchema)}
        >
          {(data.idListaBloqueio && data.motivoListaBloqueio) ||
            (bloqueioCandidato?.id && bloqueioCandidato?.motivo) ? (
            <div>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="left"
              >
                <strong>CPF Bloqueado:</strong> {data.cpf}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="left"
              >
                <strong>Motivo:</strong>{' '}
                {ReactHtmlParser(
                  `${data.motivoListaBloqueio ?? bloqueioCandidato?.motivo}`
                )}
              </Typography>
            </div>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RichTextField name="motivo" label="Motivo" />
              </Grid>
            </Grid>
          )}
        </ComplexDialog>
      </Grid>
      <Grid item xs={12} md={6} sm={12}>
        <DocumentoCandidatoList id={data.id} />
      </Grid>

      {data.tipo === 'MOTORISTA' && (
        <>
          <Grid item xs={12}>
            <HistoricoCandidato
              open
              candidatoId={data.id}
              Triagens={data.Triagem}
            />
          </Grid>
        </>
      )}

      {data.tipo === 'ADMINISTRATIVO' && (
        <>
          <Grid item xs={12} sm={12} md={6}>
            <LabelsCard id={data.id} />
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <CargoCandidatoCard id={data.id} />
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <CandidatoTreinamentoList id={data.id} />
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <TriagemCard id={data.id} />
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <ProcessoAdministrativoList id={data.id} />
          </Grid>
        </>
      )}

      <CandidatoButtons
        candidatoId={data.id}
        candidatoType={data.tipo === 'MOTORISTA' ? 'MOTORISTA' : 'ADMINISTRATIVO'}
      />

    </Grid>
  )
}

export { CandidatoCard }

