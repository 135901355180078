import Filter from '@elentari/components-eve/components/Filter'
import TextField from '@elentari/components-eve/final-form/TextField'
import Select from '@elentari/components-eve/final-form/Select'
import CurrencyField from 'src/components/Forms/CurrencyField'
import { useQueryParams, useSnackbar } from '@elentari/core'
import {
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import LinkMD from '@material-ui/core/Link'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import {
  AddRecord,
  DeleteRecord,
  NoResultsTable,
  Paper,
  TableContainer
} from '../../components'
import Spacer from '../../components/Spacer'
import { TableCellHead } from '../../components/TableCellHead'
import When from '../../components/When'
import { getAuthorizations } from '../../utils/crudAuthorization'
import { HandleErrorPage } from '../../utils/handleErrorPage'
import yupValidation from '../../utils/yupValidation'
import { useAbility } from '../login'
import { MessagesYup } from '../messages'
import { treinamentosRepository } from './hooks/treinamentosRepository'
import { useTreinamentoList } from './hooks/useTreinamentos'
import { statusTreinamento, SubmitValues, TreinamentoFormData } from './types'
import { monetaryFormatter } from 'src/utils/formatters'
import { handleKeyCodes } from '../options'

interface Props {
  list: TreinamentoFormData[]
  loading: boolean
  totalCount: number
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

const schema = yup.object().shape({
  nome: yup.string().typeError(MessagesYup.MENSAGEM_CAMPO_STRING)
})

export const TreinamentosTable = ({
  list,
  loading,
  totalCount,
  onDelete,
  authorization
}: Props) => {
  return (
    <>
      <TreinamentosFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Nome" sort="nome" />
                  <TableCellHead label="Status" sort="status" />
                  <TableCellHead label="Carga Horária" sort="cargaHoraria" />
                  <TableCellHead
                    label="Valor por Pessoa"
                    sort="valorPorPessoa"
                  />

                  <When
                    value={!authorization || authorization.canDelete}
                    equals
                  >
                    <TableCell></TableCell>
                  </When>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(treinamento => (
                  <TableRow hover key={treinamento.id}>
                    <TableCell>
                      {!authorization ||
                        (authorization.canUpdate && (
                          <When
                            value={!authorization || authorization.canUpdate}
                            equals
                          >
                            <LinkMD
                              component={Link}
                              to={`/treinamento-info/${treinamento.id}`}
                            >
                              {treinamento.nome}
                            </LinkMD>
                          </When>
                        ))}
                    </TableCell>
                    <TableCell>
                      {statusTreinamento.find(
                        status => status.value === treinamento.status
                      )?.label ?? treinamento.status}
                    </TableCell>
                    <TableCell>{treinamento.cargaHoraria}</TableCell>
                    <TableCell>
                      {monetaryFormatter.format(treinamento.valorPorPessoa)}
                    </TableCell>
                    <When value={authorization?.canDelete} equals>
                      <DeleteRecord onDelete={() => onDelete(treinamento.id)} />
                    </When>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="treinamentos" authorization={authorization} />
    </>
  )
}

type TreinamentosQueryFilter = {
  [key: string]: any
}

const TreinamentosFilter = () => {
  const { clear, push, initialValues } =
    useQueryParams<TreinamentosQueryFilter>()

  const handleSubmitFilter = (values: SubmitValues) => {
    if (values.status === null) {
      delete values.status
    }
    push(values)
  }

  const format = (initialValues: TreinamentosQueryFilter) => {
    return initialValues
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter(values)}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField fullWidth name="nome" label="Nome" />
        </Grid>
        <Grid item xs={6}>
          <Select
            fullWidth
            name="status"
            label="Status"
            items={statusTreinamento}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="number"
            name="cargaHoraria"
            inputProps={{ min: 0 }}
            onKeyDown={handleKeyCodes}
            label="Carga Horária (A partir de)"
          />
        </Grid>
        <Grid item xs={6}>
          <CurrencyField
            name="valorPorPessoa"
            label="Valor por Pessoa (A partir de)"
            startAdornment={
              <InputAdornment position="start">R$</InputAdornment>
            }
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Treinamentos = () => {
  const [state, actions] = useTreinamentoList()
  const [, snackbarActions] = useSnackbar()

  const ability = useAbility()
  async function handleDelete(id: string) {
    const response = await treinamentosRepository.delete(id)
    actions.fetchPage()

    if (response.ok) {
      snackbarActions.setSnackBar({
        message: 'Deletado com sucesso',
        severity: 'success'
      })
      actions.fetchPage()
      return
    }
    const message = (response.data as unknown as any).message
    snackbarActions.setSnackBar({
      message: message as string,
      severity: 'warning'
    })
  }

  const authorization = getAuthorizations(ability, 'treinamentos')

  switch (state.tag) {
    case 'empty':
      return (
        <TreinamentosTable
          onDelete={() => {}}
          totalCount={0}
          list={[]}
          loading={true}
        />
      )
    case 'with-data':
      return (
        <TreinamentosTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onDelete={handleDelete}
          authorization={authorization}
        />
      )
    case 'error':
      return <HandleErrorPage state={state.data} />
  }
}

export default Treinamentos
