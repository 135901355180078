import { makeAutoObservable } from 'mobx'
import {
  CandidatoAdministrativoDetail,
  CandidatoSelecaoDetail,
  VagaDetail
} from '../types'
import { normalizeCargoCandidato } from '../utils/normalizeCargoCandidato'
import * as ramda from 'ramda'

class CandidatosStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  candidatosSelecionados = [] as CandidatoSelecaoDetail[]
  candidatosChecados = [] as CandidatoSelecaoDetail[]
  vaga: VagaDetail | undefined = undefined
  selecaoCandidatos = false

  isChecked(candidatoId: string) {
    return this.candidatosChecados.some(
      candidato => candidato.id === candidatoId
    )
  }

  selecionarCandidatos() {
    this.candidatosSelecionados = ramda.clone(this.candidatosChecados)
  }

  resetChecados() {
    this.candidatosChecados = ramda.clone(this.candidatosSelecionados)
  }

  adicionarCandidato(candidato: CandidatoAdministrativoDetail) {
    this.candidatosChecados.push({
      ...candidato,
      aderencia: normalizeCargoCandidato(candidato.CargoCandidato)
    })
  }

  setAderenciaByCandidatoId(
    candidato: CandidatoSelecaoDetail,
    aderencia: number
  ) {
    this.candidatosSelecionados = this.candidatosSelecionados.map(
      candidatoArr => {
        if (candidatoArr.id === candidato.id) {
          candidato.aderencia = aderencia
          return candidato
        }
        return candidatoArr
      }
    )
  }

  removerCandidato(candidatoId: string) {
    this.candidatosChecados = this.candidatosChecados.filter(
      candidatoArr => candidatoArr.id !== candidatoId
    )
  }

  selectCandidato(candidato: CandidatoSelecaoDetail, checked: boolean) {
    if (checked) {
      this.adicionarCandidato(candidato)
    } else {
      this.removerCandidato(candidato.id)
    }
  }

  resetarTriagemCandidatos() {
    this.candidatosSelecionados = []
    this.candidatosChecados = []
    this.vaga = undefined
    this.selecaoCandidatos = false
  }

  setVaga(vaga: VagaDetail) {
    this.vaga = vaga
  }

  setSelecaoCandidatos(valor: boolean) {
    this.selecaoCandidatos = valor
  }

  resetCandidatos() {
    this.candidatosSelecionados = []
    this.candidatosChecados = []
  }
}

const candidatosStore = new CandidatosStore()

export default candidatosStore
