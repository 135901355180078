import { useEffect, useState } from 'react'
import { vagasRepository, vagasEmTriagem } from './hooks/vagaRepository'
import RFFAutocomplete from '../../components/Forms/RFFAutocomplete'

interface Props {
  label?: string
  name?: string
}

const VagaRFFAutocomplete = ({ label, name }: Props) => {
  const [vagas, setVagas] = useState<{ id: string; name: string }[]>([])

  const loadVaga = async () => {
    const response = await vagasEmTriagem()

    if (response?.ok) {
      setVagas(
        response.info.edges.map((vaga: any) => ({
          id: vaga?.node.id,
          name: vaga?.node.cargoFk?.nome
        })) ?? []
      )
    }
  }

  useEffect(() => {
    loadVaga()
  }, [])

  return (
    <RFFAutocomplete
      label={label ?? 'Vagas'}
      name={name ?? 'vagasId'}
      options={vagas}
    />
  )
}

export default VagaRFFAutocomplete
