import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core"
import { useField } from "formik"
import { ChangeEvent } from "react"

export type RadioOption = {
  label: string
  value: any
}

interface RadioGroupInputProps {
  name: string
  label: string
  options: RadioOption[]
  type: "string" | "boolean"
  labelVariant?: "default" | "h6"
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void
}

export const RadioGroupInput = ({
  name,
  label,
  options,
  type,
  labelVariant,
  onChange
}: RadioGroupInputProps) => {
  const [input, meta, helpers] = useField(name)

  const defaultOnChange = (event: ChangeEvent<HTMLInputElement>, value: any) => {
    switch (type) {
      case "boolean":
        helpers.setValue(value === "true")
        break;
      default:
        helpers.setValue(value)
    }
  }

  return (
    <FormControl>
      <FormLabel>
        {labelVariant === 'h6' ? (
          <Typography
            variant="h6"
            style={{ fontWeight: 'bold', marginBottom: '1rem' }}
          >
            {label}
          </Typography>
        ) : (
          label
        )}
      </FormLabel>
      <RadioGroup
        row
        {...input}
        {...meta}
        onChange={onChange ? onChange : defaultOnChange}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            label={option.label}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
      <FormHelperText error={meta.touched && Boolean(meta.error)}>
        {meta.touched && meta.error}
      </FormHelperText>
    </FormControl>
  )
}