import { apiSauceInstance } from '../../../services/api'

export const createManyProcessoSeletivo = async (data: any) => {
  const response = await apiSauceInstance.post<any>(
    '/processo-seletivo-administrativos/create-many',
    data
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}
