import TextField from '@elentari/components-eve/final-form/TextField'
import { useSnackbar } from '@elentari/core'
import { Grid } from '@material-ui/core'
import { isNil } from 'ramda'
import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { AnexoInput } from 'src/components/AnexoInput'
import { MessagesYup } from 'src/modules/messages'
import yupValidation from 'src/utils/yupValidation'
import * as yup from 'yup'
import ComplexDialog from '../../../components/ComplexDialog'
import { uploadOneAnexoCandidatoTreinamento } from '../hooks/anexosCandidatoTreinamentoRepository'
import { useAnexoCandidatoTreinamento } from '../hooks/useAnexoCandidatoTreinamento'
import { SaveAnexoCandidatoTreinamentoInput } from '../types'

type Props = {
  idAnexo: string
  open: boolean
  handleClose: () => void
  fetchData: () => void
}

export const AnexoCandidatoTreinamentoForm = ({
  idAnexo,
  open,
  handleClose,
  fetchData
}: Props) => {
  const [anexoCandidatoTreinamentoState, anexoCandidatoTreinamentoAction] = useAnexoCandidatoTreinamento()
  const [, snackbarActions] = useSnackbar()
  const location = useLocation()

  const dialogSchema = yup.object().shape({
    descricao: yup
      .string()
      .trim()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    anexo: yup
      .object()
      .when('URL', {
        is: (URL: string) => isNil(URL),
        then: yup.object().required(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
  })

  const handleSubmit = async (data: SaveAnexoCandidatoTreinamentoInput) => {
    try {
      if (location.pathname.split('/')[2]) {
        data.candidatoTreinamentoId = location.pathname.split('/')[2]
      }

      if (!data.candidatoTreinamentoId) return snackbarActions.setMessage("Não foi possivel encontrar o Treinamento do Candidato")

      const anexosCandidatoTreinamento = await uploadOneAnexoCandidatoTreinamento({
        descricao: data.descricao,
        anexo: data.anexo,
        candidatoTreinamentoId: data.candidatoTreinamentoId
      })
      if (!anexosCandidatoTreinamento) {
        return snackbarActions.setMessage("Ocorreu um erro ao salvar o Anexo do Treinamento")
      }

      fetchData()
    } catch (error: any) {
      snackbarActions.setMessage(error.message)
    }
  }

  useEffect(() => {
    async function fetchAnexo() {
      if (idAnexo && idAnexo !== 'new') {
        await anexoCandidatoTreinamentoAction.fetch(idAnexo)
      }
    }

    fetchAnexo()
  }, [idAnexo])

  useEffect(() => {
    return anexoCandidatoTreinamentoAction.reset()
  }, [anexoCandidatoTreinamentoAction, open])

  return (
    <ComplexDialog
      open={open}
      title={"Insira as informações do Anexo"}
      handleClose={handleClose}
      primaryAction={handleSubmit}
      validate={yupValidation(dialogSchema)}
      initialValues={anexoCandidatoTreinamentoState.tag === 'with-data' && {
        id: anexoCandidatoTreinamentoState.entity.id,
        descricao: anexoCandidatoTreinamentoState.entity.descricao,
        URL: anexoCandidatoTreinamentoState.entity.URL
      }}
      disableBackdropClick={true}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="descricao"
            label="Descrição"
          />
        </Grid>
        <Grid item xs={12}>
          <AnexoInput
            name="anexo"
          />
        </Grid>
      </Grid>
    </ComplexDialog>
  )
}
