import { useSnackbar } from '@elentari/core'
import {
  CircularProgress,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Paper } from 'src/components'
import { AppDialogBox } from 'src/components/AppDialogBox'
import logoG10 from '../../images/g10-logo-2.png'
import logoTP from '../../images/tp-logo.png'
import { ErrorMessage } from '../uploadDocuments/types'
import { MessageCard } from './components/MessageCard'
import { validateTokenAndUpdateStatusCandidato } from './hooks/contratarDp'

const useStyles = makeStyles(themes => ({
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eeeeee',
    minHeight: '100vh',
    '& .MuiDialogActions-root': {
      background: 'black'
    }
  },
  message: {
    marginTop: 40,
    marginBottom: 40
  },
  paper: {
    width: '600px'
  }
}))

const ContratarDp = () => {
  const classes = useStyles()
  const location = useLocation()
  const [, snackBarActions] = useSnackbar()

  const [loading, setLoading] = useState<boolean>(true)
  const [admissaoCandidato, setAdmissaoCandidato] = useState<{
    candidato: string
    empresaSigla: string
  }>()
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>()

  useEffect(() => {
    if (admissaoCandidato) {
      document.title = `${admissaoCandidato?.empresaSigla} - Recursos Humanos`
      const favIcon = document.getElementById('icon') as HTMLLinkElement
      if (favIcon) {
        if (admissaoCandidato?.empresaSigla === 'TP') {
          favIcon.href = '/faviconTP.ico'
        } else {
          favIcon.href = '/favicon.ico'
        }
      }
    }
  }, [admissaoCandidato])

  useEffect(() => {
    document.title = 'Contratar DP - Recursos Humanos'
    const favIcon = document.getElementById('icon') as HTMLLinkElement
    favIcon.href = ''
  }, [])

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        const token = location.pathname.split('/')[2]
        if (token) {
          const response = await validateTokenAndUpdateStatusCandidato(token)
          if (response.info && response.ok) {
            setAdmissaoCandidato(response.info)
          } else {
            setErrorMessage({
              info: response?.info?.message,
              open: true
            })
          }
        }
      } catch (error: any) {
        snackBarActions.setSnackBar({
          message: error.message.message,
          severity: 'error'
        })
      }
      setLoading(false)
    }

    fetchData()
  }, [location])

  if (errorMessage && errorMessage.open) {
    return (
      <div className={classes.background}>
        <AppDialogBox>
          <Typography
            align="center"
            variant="body1"
            className={classes.message}
          >
            {errorMessage.info}
          </Typography>
        </AppDialogBox>
      </div>
    )
  }

  return (
    <Grid container spacing={2} className={classes.background}>
      <Paper className={classes.paper}>
        {loading ? (
          <MessageCard
            logo={<CircularProgress />}
            title="Carregando ..."
            subtitle="Por favor aguarde enquanto buscamos o candidato"
          />
        ) : admissaoCandidato ? (
          <MessageCard
            logo={
              admissaoCandidato.empresaSigla === 'G10' ? (
                <img src={logoG10} alt="G10 Logo" />
              ) : (
                <img src={logoTP} alt="Transpanorama Logo" />
              )
            }
            title="Candidato Contratado"
            subtitle="O status do candidato foi atualizado com sucesso para Contratado."
          />
        ) : (
          <MessageCard
            logo={<img src={logoG10} alt="G10 Logo" />}
            title="Ocorreu um erro"
            subtitle="Não foi possível contratar o candidato."
          />
        )}
      </Paper>
    </Grid>
  )
}

export default ContratarDp
