import Filter from '@elentari/components-eve/components/Filter'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams, useSnackbar } from '@elentari/core'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import LinkMD from '@material-ui/core/Link'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import {
  AddRecord,
  DeleteRecord,
  NoResultsTable,
  Paper,
  TableContainer
} from '../../components'
import Spacer from '../../components/Spacer'
import { TableCellHead } from '../../components/TableCellHead'
import When from '../../components/When'
import { getAuthorizations } from '../../utils/crudAuthorization'
import { HandleErrorPage } from '../../utils/handleErrorPage'
import yupValidation from '../../utils/yupValidation'
import { useAbility } from '../login'
import { MessagesYup } from '../messages'
import { labelCaracteristicasRepository } from './hooks/labelCaracteristicasRepository'
import { useLabelCaracteristicasList } from './hooks/useLabelsCaracteristicas'
import {
  LabelCaracteristicasSubmitValues,
  LabelCaracteristicasFormData
} from './types'

interface Props {
  list: LabelCaracteristicasFormData[]
  loading: boolean
  totalCount: number
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

const schema = yup.object().shape({
  descricao: yup.string().typeError(MessagesYup.MENSAGEM_CAMPO_STRING)
})

export const LabelCaracteristicasTable = ({
  list,
  loading,
  totalCount,
  onDelete,
  authorization
}: Props) => {
  return (
    <>
      <LabelCaracteristicasFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Descrição" sort="descricao" />

                  <When
                    value={!authorization || authorization.canDelete}
                    equals
                  >
                    <TableCell></TableCell>
                  </When>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(labelCaracteristica => (
                  <TableRow hover key={labelCaracteristica.id}>
                    <TableCell>
                      {!authorization ||
                        (authorization.canUpdate && (
                          <When
                            value={!authorization || authorization.canUpdate}
                            equals
                          >
                            <LinkMD
                              component={Link}
                              to={`/label-caracteristicas/${labelCaracteristica.id}`}
                            >
                              {labelCaracteristica.descricao}
                            </LinkMD>
                          </When>
                        ))}
                    </TableCell>
                    <When value={authorization?.canDelete} equals>
                      <DeleteRecord onDelete={() => onDelete(labelCaracteristica.id)} />
                    </When>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="label-caracteristicas" authorization={authorization} />
    </>
  )
}

type LabelCaracteristicasQueryFilter = {
  [key: string]: any
}

const LabelCaracteristicasFilter = () => {
  const { clear, push, initialValues } =
    useQueryParams<LabelCaracteristicasQueryFilter>()

  const handleSubmitFilter = (values: LabelCaracteristicasSubmitValues) => {
    push(values)
  }

  const format = (initialValues: LabelCaracteristicasQueryFilter) => {
    return initialValues
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter(values)}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField fullWidth name="descricao" label="Descrição" />
        </Grid>
      </Grid>
    </Filter>
  )
}

const LabelCaracteristicas = () => {
  const [state, actions] = useLabelCaracteristicasList()
  const [, snackbarActions] = useSnackbar()

  const ability = useAbility()
  async function handleDelete(id: string) {
    const response = await labelCaracteristicasRepository.delete(id)
    actions.fetchPage()

    if (response.ok) {
      snackbarActions.setSnackBar({
        message: 'Deletado com sucesso',
        severity: 'success'
      })
      actions.fetchPage()
      return
    }
    const message = (response.data as unknown as any).message
    snackbarActions.setSnackBar({
      message: message as string,
      severity: 'warning'
    })
  }

  const authorization = getAuthorizations(ability, 'label-caracteristicas')

  switch (state.tag) {
    case 'empty':
      return (
        <LabelCaracteristicasTable
          onDelete={() => {}}
          totalCount={0}
          list={[]}
          loading={true}
        />
      )
    case 'with-data':
      return (
        <LabelCaracteristicasTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onDelete={handleDelete}
          authorization={authorization}
        />
      )
    case 'error':
      return <HandleErrorPage state={state.data} />
  }
}

export default LabelCaracteristicas
