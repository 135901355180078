import { useSnackbar } from '@elentari/core'
import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Grid } from '@material-ui/core'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import TextInput from 'src/components/Formik/Forms/TextInput'
import * as yup from 'yup'
import { FormWrap, Paper } from '../../../components'
import { MessagesYup } from '../../messages'
import { useCandidatoTreinamento } from '../hooks/useCandidatoTreinamento'
import { candidatosTreinamentosRepository } from '../hooks/candidatosTreinamentosRepository'
import { SaveCandidatoTreinamentoInput, TreinamentoDetail } from '../types'
import RichTextInput from 'src/components/Formik/Forms/RichTextInput'
import { treinamentosEmAndamento } from '../hooks/treinamentosRepository'
import SelectInput from 'src/components/Formik/Forms/SelectInput'

interface Option {
  value: string
  label: string
}

type Props = {
  from: string
}

export const CandidatoTreinamentoForm = ({ from }: Props) => {
  const [candidatoTreinamentoState, candidatoTreinamentoAction] =
    useCandidatoTreinamento()
  const [candidatoId, setCandidatoId] = useState<string>()
  const [treinamentosOptions, setTreinamentosOptions] = useState<Option[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const history = useHistory()
  const location = useLocation()
  const pathname = location.pathname

  useEffect(() => {
    if (pathname.includes('/new')) {
      loadTreinamentos()
    }
  }, [])

  const loadTreinamentos = async () => {
    const response = await treinamentosEmAndamento()

    if (response?.ok) {
      setTreinamentosOptions(
        response?.info?.edges.map(
          (treinamento: { node: TreinamentoDetail }) => ({
            value: treinamento.node.id,
            label: treinamento.node.nome
          })
        ) ?? []
      )
    }
  }

  const { save } = useEntitySaver<SaveCandidatoTreinamentoInput>(async data => {
    const response = await candidatosTreinamentosRepository.save(data)
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return response
  })

  const formikState = {
    initialValues: {
      treinamentoId: '',
      nota: '',
      comentario: ''
    } as SaveCandidatoTreinamentoInput,
    onSubmit: async (data: SaveCandidatoTreinamentoInput) => {
      try {
        setLoading(true)
        delete data.candidatoFK
        delete data.treinamentoFK

        if (candidatoId) {
          data.candidatoId = candidatoId
        }

        save(data)
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  useEffect(() => {
    if (pathname.includes('/new')) {
      if (location.state && (location.state as any).candidatoId) {
        setCandidatoId((location.state as any).candidatoId)
      }
    }

    if (candidatoTreinamentoState.tag === 'with-data') {
      setCandidatoId(candidatoTreinamentoState.entity.candidatoId)
    }
  }, [candidatoTreinamentoState, candidatoId, location, pathname])

  useEffect(() => {
    async function fetchCandidatoTreinamento() {
      if (!pathname.includes('/new')) {
        await candidatoTreinamentoAction.fetch(location.pathname.split('/')[2])
      }
    }
    fetchCandidatoTreinamento()
  }, [location])

  const schemaObject = {
    treinamentoId: yup
      .string()
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
    nota: yup.string().trim().nullable(),
    comentario: yup
      .string()
      .trim()
      .nullable()
      .typeError(MessagesYup.MENSAGEM_CAMPO_STRING)
  }
  const schema = yup.object().shape(schemaObject)

  return (
    <Formik
      validationSchema={schema}
      onSubmit={formikState.onSubmit}
      enableReinitialize
      initialValues={
        candidatoTreinamentoState.tag === 'with-data'
          ? candidatoTreinamentoState.entity
          : formikState.initialValues
      }
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <Paper noTopBorderRadius>
          <FormWrap
            handleSubmit={handleSubmit}
            disableSubmit={isSubmitting}
            disableBack={isSubmitting}
            handleGoBack={() => {
              if (from === 'treinamento') {
                candidatoTreinamentoState.tag === 'with-data' &&
                  history.push(
                    `/treinamento-info/${candidatoTreinamentoState.entity.treinamentoId}`
                  )
              } else {
                history.push(`/candidato/${candidatoId}`)
              }
            }}
            loading={loading}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {!pathname.includes('/new') ? (
                  <TextInput
                    name="treinamentoFK.nome"
                    label="Treinamento"
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled
                  />
                ) : (
                  <SelectInput
                    name="treinamentoId"
                    label="Treinamento"
                    options={treinamentosOptions}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <TextInput name="nota" label="Nota" />
              </Grid>
              <Grid item xs={12}>
                <RichTextInput name="comentario" label="Comentário" />
              </Grid>
            </Grid>
          </FormWrap>
        </Paper>
      )}
    </Formik>
  )
}
