import { makeUseFetchOne } from '@elentari/core/hooks/makeUseFetchOne'
import { ProcessoSeletivoAdministrativoDetail } from '../types'
import { repository } from './processoAdministrativoRepository'

export const useProcessoAdministrativo = makeUseFetchOne<
  ProcessoSeletivoAdministrativoDetail,
  string
>({
  fetch: repository.fetchOne
})
