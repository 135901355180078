import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import CursosInput from 'src/components/Formik/Forms/CursosInput'
import FileUploadInput from 'src/components/Formik/Forms/FileUploadInput'
import { getIdade, isCellPhone } from 'src/utils'
import { FormikStep } from '../../../../components/Formik/FormikStepper'
import AutoCompleteInput from '../../../../components/Formik/Forms/AutoCompleteInput'
import DateInput from '../../../../components/Formik/Forms/DateInput'
import MaskInput from '../../../../components/Formik/Forms/MaskInput'
import TextInput from '../../../../components/Formik/Forms/TextInput'
import { Option } from '../../../../hooks/useAutoComplete'
import lugares from '../../../../utils/cidades.json'
import EscolaridadeStep from '../../../candidato/motorista/steps/EscolaridadeStep'
import MotoristaStep from '../../../candidato/motorista/steps/MotoristaStep'
import { CandidatoFormData } from '../../../candidato/types'
import { getVagas } from '../../hooks'
import AdministrativoCurriculoStep from '../AdministrativoCurriculoStep'
import VagasInfo from '../VagasInfo/VagasInfo'

interface Props {
  label: string
  validationSchema: any
  candidatoType: 'Administrativo' | 'Motorista'
  empresa: string
}

interface PropsResponse {
  node: VagaDetail
}

interface DetalhesVaga {
  descricao: string
}
export interface VagaDetail {
  id: string
  descricao: string
  area: string
  categoria: string
  VagaBeneficio: DetalhesVaga[]
  VagaRequisito: DetalhesVaga[]
  VagaResponsabilidade: DetalhesVaga[]
}

const useStyles = makeStyles({
  uploadButton: {
    padding: '20px 0'
  }
})

export default function CandidatoCurriculosStep({
  label,
  validationSchema,
  candidatoType,
  empresa
}: Props) {
  const [uf, setUF] = useState<Option[]>([])
  const [cidade, setCidade] = useState<Option[]>([])
  const [tipo, setTipo] = useState('')

  const { values, setFieldValue } = useFormikContext<CandidatoFormData>()

  const [vagas, setVagas] = useState<VagaDetail[]>()

  const classes = useStyles()

  const loadVagas = async () => {
    const response = await getVagas(empresa)

    if (response.status === 200 && response.info) {
      setVagas(
        response.info?.edges.map(
          (i: PropsResponse) =>
          ({
            id: i.node.id,
            descricao: i.node.descricao,
            area: i.node.area,
            categoria: i.node.categoria,
            VagaBeneficio: i.node.VagaBeneficio,
            VagaResponsabilidade: i.node.VagaResponsabilidade,
            VagaRequisito: i.node.VagaRequisito
          } as VagaDetail)
        ) ?? []
      )
    }
  }

  useEffect(() => {
    loadVagas()
  }, [])

  useEffect(() => {
    if (window.location.pathname.includes('motorista')) {
      setTipo('Motorista')
    } else {
      setTipo('Administrativo')
    }
  }, [tipo])

  useEffect(() => {
    const ufs = lugares.estados.map(locais => {
      return {
        id: locais.sigla,
        name: locais.sigla
      }
    })
    setUF(ufs)
  }, [])

  useEffect(() => {
    const findUF = lugares.estados.find(cidade => cidade.sigla === values.uf)

    if (findUF) {
      const cidades = findUF.cidades.map(cidade => {
        return {
          id: cidade,
          name: cidade
        }
      })
      setCidade(cidades)
    }
  }, [values.uf])

  useEffect(() => {
    const match = lugares.estados.find(estado => estado.sigla === values.uf)
    if (!values.cidade || !match?.cidades.includes(values.cidade)) {
      setFieldValue('cidade', null)
    }
  }, [values.uf, setFieldValue, values.cidade])

  return (
    <FormikStep label={label} validationSchema={validationSchema}>
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        {!!vagas?.length && candidatoType === 'Administrativo' && (
          <>
            <Grid item xs={12}>
              <Typography align="center" variant="h6">
                Conheça nossas Vagas
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <VagasInfo vagas={vagas} name="vagasId" label="Vagas" />
            </Grid>
          </>
        )}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput label="Nome Completo" name="nomeCompleto" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaskInput mask="999.999.999-99" label="CPF" name="cpf" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="E-mail" name="email" />
        </Grid>
        <Grid item xs={8} md={4} sm={6}>
          <DateInput
            label="Data Nascimento"
            name="dataNascimento"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
        <Grid item xs={4} md={2} sm={6}>
          <TextInput
            disabled={true}
            inputProps={{
              shrink: true
            }}
            name="idade"
            label="Idade"
            value={getIdade(values.dataNascimento ?? '')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaskInput mask="(99) 9 9999-9999" label="Celular" name="celular" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaskInput
            mask={
              values.telefone !== null && values.telefone && isCellPhone(values.telefone)
                ? '(99) 9 9999-9999'
                : '(99) 9999-9999'
            }
            label="Telefone"
            name="telefone"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoCompleteInput label="UF" name="uf" options={uf} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoCompleteInput
            label="Cidade"
            disabled={cidade.length === 0 || !values.uf}
            name="cidade"
            options={cidade}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.uploadButton}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FileUploadInput name="anexoCurriculo" label="Anexar Currículo" />
          </Grid>
          {candidatoType === 'Motorista' && (
            <Grid item xs={12}>
              <FileUploadInput name="anexoCNH" label="Anexar CNH" />
            </Grid>
          )}
        </Grid>
      </Grid>

      {candidatoType === 'Administrativo' && (
        <AdministrativoCurriculoStep
          label="Administrativo"
          sigla_empresa={empresa}
        />
      )}

      {candidatoType === 'Motorista' && (
        <>
          <MotoristaStep label="Dados Motorista" />

          <EscolaridadeStep label="Escolaridade" />
        </>
      )}

      <CursosInput />

    </FormikStep>
  )
}
