import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { ParsedQs } from 'qs'
import { LabelCaracteristicasDetail, SaveLabelCaracteristicasInput } from '../types'
import { apiSauceInstance } from 'src/services/api'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params
  return { ...params, ...paginationParams(rest) }
}

export const labelCaracteristicasRepository = makeRepository<
  EdgesPage<LabelCaracteristicasDetail>,
  SaveLabelCaracteristicasInput,
  LabelCaracteristicasDetail,
  string
>(
  {
    resource: 'label-caracteristicas',
    mapQueryParams
  },
  apiSauceInstance
)
