import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import LinkMD from '@material-ui/core/Link'
import { Skeleton } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CandidatoTreinamentoDetail } from '../types'
import {
  NoResultsTable,
  Paper,
  TableContainer,
  TableCellHead
} from '../../../components'
import { apiSauceInstance } from '../../../services/api'
import { FormatedDate } from 'src/utils'

interface Props {
  list: { node: CandidatoTreinamentoDetail }[]
  loading: boolean
  totalCount: number
}

export const CandidatosTreinamentoTable = ({
  list,
  loading,
  totalCount
}: Props) => {
  return (
    <Paper>
      <Grid style={{ marginBottom: 30 }} container>
        <Grid item xs>
          <Typography variant="h5" color="primary" align="center">
            Candidatos(as)
          </Typography>
        </Grid>
      </Grid>
      <TableContainer loading={loading} totalCount={0} disablePagination={true}>
        {list.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCellHead label="Candidato(a)" />
                <TableCellHead label="Nota" />
                <TableCellHead label="Data Início do Treinamento" />
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map(candidatoTreinamento => (
                <TableRow hover key={candidatoTreinamento.node.id}>
                  <TableCell>
                    <LinkMD
                      component={Link}
                      to={`/candidato-treinamento/${candidatoTreinamento.node.id}?from=treinamento`}
                    >
                      {candidatoTreinamento.node.candidatoFK.nomeCompleto}
                    </LinkMD>
                  </TableCell>
                  <TableCell>{candidatoTreinamento.node.nota}</TableCell>
                  <TableCell>
                    {FormatedDate(candidatoTreinamento.node.createdAt)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <NoResultsTable loading={loading} />
        )}
      </TableContainer>
    </Paper>
  )
}

type CandidatosTreinamentosListProps = {
  treinamentoId: string
}

type APIResponseCandidatosTreinamento = {
  edges: { node: CandidatoTreinamentoDetail }[]
  totalCount: number
  loading: boolean
}

const CandidatosTreinamentoList = ({
  treinamentoId
}: CandidatosTreinamentosListProps) => {
  const [candidatosTreinamentoState, setCandidatosTreinamentoState] =
    useState<APIResponseCandidatosTreinamento>(
      {} as APIResponseCandidatosTreinamento
    )

  useEffect(() => {
    async function loadCandidatosTreinamentosById() {
      try {
        setCandidatosTreinamentoState({
          ...candidatosTreinamentoState,
          loading: true
        })

        const { data } = await apiSauceInstance.get<any>(
          `/candidatos-treinamentos`,
          {
            treinamentoId
          }
        )
        if (data) {
          setCandidatosTreinamentoState({
            totalCount: data.totalCount,
            edges: data.edges,
            loading: false
          })
        }
      } catch (error) {}
    }
    loadCandidatosTreinamentosById()
  }, [treinamentoId])

  return (
    <div>
      {candidatosTreinamentoState.loading === false ? (
        <CandidatosTreinamentoTable
          list={candidatosTreinamentoState.edges}
          totalCount={candidatosTreinamentoState.totalCount}
          loading={candidatosTreinamentoState.loading}
        />
      ) : (
        <Paper>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Paper>
      )}
    </div>
  )
}

export default CandidatosTreinamentoList
