import { InfoFile } from "../../types";

export const initialValues = {
  nomeCompleto: '',
  uf: '',
  tipo: '',
  telefone: '',
  celular: '',
  cidade: '',
  email: '',
  cpf: '',
  dataNascimento: null,
  empresaId: '',
  primeiroEmprego: false,
  origemCurriculo: '',
  anexoCurriculo: {
    file: {} as File,
    name: '',
    path: ''
  } as InfoFile,
  anexoCNH: {
    file: {} as File,
    name: '',
    path: ''
  } as InfoFile,
  Cursos: [
    {
      descricao: '',
      dataInicio: null,
      dataConclusao: null,
      cargaHoraria: 0
    }
  ],
  Motorista: {
    categoriaCNH: '',
    rg: '',
    estadoCivil: null,
    dataValidadeMopp: null,
    pamcary: false,
    earCNH: false,
    mopp: false,
    grauEscolaridade: '',
    indicacao: null,
    cargoQuemIndicou: '',
    quemIndicou: '',
    coordenadorQuemIndicou: ''
  },
  ExperienciaProfissional: [
    {
      empresa: '',
      cargoFuncao: '',
      cidade: '',
      uf: '',
      telefone: '',
      dataEntrada: null,
      dataSaida: null,
      tipoCaminhao: null,
      tempoExperienciaCaminhao: 0,
      atualmenteEmpregado: false,
      principaisAtividades: ''
    },
    {
      empresa: '',
      cargoFuncao: '',
      cidade: '',
      uf: '',
      telefone: '',
      dataEntrada: null,
      dataSaida: null,
      tipoCaminhao: null,
      tempoExperienciaCaminhao: 0,
      atualmenteEmpregado: false,
      principaisAtividades: ''
    },
    {
      empresa: '',
      cargoFuncao: '',
      cidade: '',
      uf: '',
      telefone: '',
      dataEntrada: null,
      dataSaida: null,
      tipoCaminhao: null,
      tempoExperienciaCaminhao: 0,
      atualmenteEmpregado: false,
      principaisAtividades: ''
    }
  ]
}