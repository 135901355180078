import { useContext, useState } from 'react'
import { AuthContext } from './AuthProvider'

import api from '../../services/api'
import config from '../../config'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from '@elentari/core'
import { AppDialogBox } from 'src/components/AppDialogBox'
import Button from '@material-ui/core/Button'
import { LoadingPage } from 'src/components/LoadingPage'

const useStyles = makeStyles(themes => ({
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: themes.palette.primary.main
  },
  message: {
    marginTop: 40,
    marginBottom: 40
  }
}))

const Callback = () => {
  const classes = useStyles()
  const context = useContext(AuthContext)
  const history = useHistory()
  const [forbidden, setForbidden] = useState(false)
  const [, snackBarActions] = useSnackbar()
  const [open, setOpen] = useState(true)

  if (context.initialized && !context.state.loggedin) {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const token = params.get('token')
    const refresh_token = params.get('refresh_token')

    if (token && refresh_token) {
      api
        .verifyToken(token)
        .then(result => {
          const user = result.data
          if (user && !user.id) {
            setForbidden(true)
            return
          }
          context.handleUserLogin({
            id: user?.id.toString() || '',
            name: user?.name || '',
            username: user?.username || '',
            token,
            refresh_token,
            rolesInSystem: user?.rolesInSystem || [],
            empresa: user?.empresa || ''
          })
          history.push('/')
        })
        .catch(error => {
          setOpen(false)
          snackBarActions.setSnackBar({
            message: 'Error ao conectar o usuário',
            severity: 'error'
          })
        })
    }
  }

  const handleRedirectButton = () => {
    const ssoUrlLogin = config.ssoUrlLogin
    window.location.assign(`${ssoUrlLogin}`)
  }

  return (
    <>
      {forbidden ? (
        <div className={classes.background}>
          <AppDialogBox>
            <Typography align="center" variant="h5" className={classes.message}>
              Você não possui acesso a este sistema!
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleRedirectButton}
            >
              Voltar para a tela do SSO
            </Button>
          </AppDialogBox>
        </div>
      ) : (
        <LoadingPage open={open} />
      )}
    </>
  )
}

export default Callback
