import {
  Box,
  createStyles, Link, makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import { PaperCard } from 'src/components/PaperCard'
import {
  DeleteRecord,
  NoResultsTable,
  Paper,
  TableCellHead,
  TableContainer
} from '../../../../components'
import { apiSauceInstance } from '../../../../services/api'
import { FormatedDate } from '../../../../utils'
import { DocumentosCandidatoDetail } from '../../types'
import DocumentoCandidatoFile from '../DocumentoCandidatoFile'
import { AnexoCandidatoDocumentoForm } from './AnexoCandidatoDocumentoForm'

interface Props {
  list: { node: DocumentosCandidatoDetail }[]
  loading: boolean
  totalCount?: number
  candidatoId?: string
  refreshData: () => void
  onDelete: (id: string) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    candidatoDocumentosListContainer: {
      maxHeight: '198px',
      overflow: 'scroll'
    },
  })
)

export const CandidatosDocumentosTable = ({
  list,
  loading,
  totalCount,
  candidatoId,
  onDelete,
  refreshData
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [idCandidato, setIdCandidato] = useState<string | null>(null)
  const [idDocumento, setIdDocumento] = useState<string | null>(null)
  const classes = useStyles()

  const handleAddNewDocument = () => {
    setModalOpen(true)
    if (candidatoId) {
      setIdCandidato(candidatoId)
    }
  }
  const handleOpenFormModal = (id: string) => {
    setModalOpen(true)
    if (candidatoId) {
      setIdCandidato(candidatoId)
    }
    setIdDocumento(id)
  }

  const handleCloseFormModal = () => {
    setModalOpen(false)
    setIdCandidato(null)
    setIdDocumento(null)
  }

  return (
    <Box>
      <PaperCard
        title="Documentos"
        primaryButtonText="Adicionar Documentos"
        primaryButtonAction={handleAddNewDocument}
        Content={
          <TableContainer
            loading={loading}
            totalCount={0}
            disablePagination={true}
          >
            {list.length ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellHead label="Descrição do Documento" />
                    <TableCellHead label="URL do Documento" />
                    <TableCellHead label="Data de Inserção do Documento" />
                    <TableCellHead label="" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map(documento => (
                    <TableRow hover key={documento.node.id}>
                      <TableCell>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => {
                            handleOpenFormModal(documento.node.id!)
                          }}
                        >
                          {documento.node.descricao}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <DocumentoCandidatoFile id={documento.node.id} />
                      </TableCell>

                      <TableCell>
                        {FormatedDate(documento.node.updatedAt)}
                      </TableCell>

                      <DeleteRecord
                        onDelete={() => onDelete(documento.node.id!)}
                      />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <NoResultsTable loading={loading} />
            )}
          </TableContainer>
        }
      />
      <AnexoCandidatoDocumentoForm
        idDocumento={idDocumento}
        idCandidato={idCandidato}
        open={modalOpen}
        fetchData={refreshData}
        handleClose={handleCloseFormModal}
      />
    </Box>
  )
}

type CandidatosDocumentosListProps = {
  id: string
}

type APIResponseDocumentos = {
  edges: { node: DocumentosCandidatoDetail }[]
  totalCount: number
  loading: boolean
}

const DocumentoCandidatoList = ({ id }: CandidatosDocumentosListProps) => {
  const [candidatosDocumentosState, setCandidatosDocumentosState] =
    useState<APIResponseDocumentos>({} as APIResponseDocumentos)

  async function onDelete(id: string) {
    await apiSauceInstance.delete(`/documentos/${id}`)
    setCandidatosDocumentosState(prevState => ({
      ...prevState,
      edges: prevState.edges.filter(documento => documento.node.id !== id),
      totalCount: prevState.totalCount - 1
    }))
  }

  async function loadCandidatosDocumentosByCandidatoId() {
    try {
      setCandidatosDocumentosState({
        ...candidatosDocumentosState,
        loading: true
      })

      const { data } = await apiSauceInstance.get<any>(`/documentos`, {
        candidatoId: id
      })
      if (data) {
        setCandidatosDocumentosState({
          totalCount: data.totalCount,
          edges: data.edges,
          loading: false
        })
      }
    } catch (error) { }
  }

  useEffect(() => {
    loadCandidatosDocumentosByCandidatoId()
  }, [id])

  return (
    <div>
      {candidatosDocumentosState.loading === false ? (
        <CandidatosDocumentosTable
          onDelete={onDelete}
          list={candidatosDocumentosState.edges}
          totalCount={candidatosDocumentosState.totalCount}
          loading={candidatosDocumentosState.loading}
          candidatoId={id}
          refreshData={loadCandidatosDocumentosByCandidatoId}
        />
      ) : (
        <Paper minHeight={340}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Paper>
      )}
    </div>
  )
}

export default DocumentoCandidatoList
