import { useSnackbar } from '@elentari/core'
import { Button, Grid, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { FieldArray, FieldArrayRenderProps, Formik, FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import TextInput from 'src/components/Formik/Forms/TextInput'
import { useAppBar } from 'src/hooks'
import { useLoggedUser } from 'src/modules/login/AuthProvider'
import * as yup from 'yup'
import { FormWrap, Paper } from '../../../components'
import { MessagesYup } from '../../messages'
import {
  createVagaRequisito,
  getAllVagaRequisitoByVagaId
} from '../hooks/vagaRequisitoRepository'
import { RequisitoDetail, VagaRequisitoDetail } from '../types'

interface Params {
  id: string
}

export const VagaRequisitoForm = () => {
  const [vagaRequisito, setVagaRequisito] = useState<VagaRequisitoDetail>()

  const params = useParams<Params>()

  const [loading, setLoading] = useState<boolean>(false)
  const [, snackbarActions] = useSnackbar()
  const [, appBarActions] = useAppBar()
  const userLogged = useLoggedUser()
  const canUpdate = userLogged?.rolesInSystem.includes('GESTOR') || userLogged?.rolesInSystem.includes('ADMIN')

  useEffect(() => {
    appBarActions.setTitle('Requisitos')
    loadVagaRequisito()
  }, [])

  const loadVagaRequisito = async () => {
    const response = await getAllVagaRequisitoByVagaId(params.id)

    if (response?.ok) {
      if (response?.info.length !== 0) {
        setVagaRequisito({
          vagasId: params.id,
          Requisitos: response.info
        } as VagaRequisitoDetail)
      }
    }
  }

  const schemaObject = {
    Requisitos: yup
      .array()
      .of(
        yup.object().shape({
          descricao: yup
            .string()
            .trim()
            .required(MessagesYup.MENSAGEM_OBRIGATORIO)
            .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
        })
      )
      .test('unique-requisito', function (Requisitos: any) {
        const arrDescricoesRequisito = Requisitos?.map(
          (Requisito: RequisitoDetail) => Requisito?.descricao?.toUpperCase()
        )

        const numberDiffRequisito = new Set(arrDescricoesRequisito).size

        if (Requisitos.length !== numberDiffRequisito) {
          const arrRepetidos: number[] = []
          arrDescricoesRequisito.filter((descricao: string, index: number) => {
            if (arrDescricoesRequisito.indexOf(descricao) !== index) {
              arrRepetidos.push(index)
            }

            return arrDescricoesRequisito.indexOf(descricao) == index
          })

          return this.createError({
            path: `Requisitos[${arrRepetidos[0]}].descricao`,
            message: 'Não pode haver Requisitos repetidos'
          })
        } else {
          return true
        }
      })
  }
  const schema = yup.object().shape(schemaObject)

  const handleAddRequisito = (arrayHelpers: FieldArrayRenderProps) => {
    return () => {
      arrayHelpers.push({ descricao: '' })
    }
  }

  const handleDeleteRequisito = (
    index: number,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    return () => {
      arrayHelpers.remove(index)
    }
  }

  const formikState = {
    initialValues: {
      vagasId: '',
      Requisitos: [
        {
          descricao: ''
        }
      ]
    },
    onSubmit: async (data: FormikValues) => {
      try {
        setLoading(true)
        const response = await createVagaRequisito({
          ...data,
          vagasId: params.id
        } as VagaRequisitoDetail)

        if (!response.ok) {
          snackbarActions.setMessage(response.message)
        } else {
          snackbarActions.setMessage('Salvo com sucesso!')
        }
        setLoading(false)
      } catch (error: any) {
        snackbarActions.setMessage(error.message)
      }
    }
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={formikState.onSubmit}
      initialValues={vagaRequisito ?? formikState.initialValues}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <Paper noTopBorderRadius>
          <FormWrap
            handleSubmit={handleSubmit}
            disableSubmit={isSubmitting}
            disableBack={isSubmitting}
            loading={loading}
          >
            <Grid container spacing={2}>
              <FieldArray name={'Requisitos'} validateOnChange={true}>
                {arrayHelpers => (
                  <Grid item xs={12}>
                    {values.Requisitos?.map((Requisito, index) => (
                      <Grid
                        container
                        key={index}
                        style={{
                          paddingBottom: 20,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'start'
                        }}
                      >
                        <Grid item xs={(values.Requisitos.length > 1 && canUpdate) ? 11 : 12}>
                          <TextInput
                            name={`Requisitos[${index}].descricao`}
                            label={`${index + 1}° Requisito`}
                            disabled={!canUpdate}
                          />
                        </Grid>

                        {(values.Requisitos.length > 1 && canUpdate) && (
                          <Grid
                            item
                            xs={1}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <Tooltip
                              placement="top"
                              title={`Deletar ${index + 1}° Requisito`}
                            >
                              <Button
                                style={{
                                  flex: 1,
                                  height: 56,
                                  border: 'none'
                                }}
                                variant="outlined"
                                color="primary"
                                onClick={handleDeleteRequisito(
                                  index,
                                  arrayHelpers
                                )}
                              >
                                <DeleteOutlineIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    ))}
                    {canUpdate && (
                      <Tooltip
                        placement="right-start"
                        title="Adicionar Requisito"
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          type="button"
                          onClick={handleAddRequisito(arrayHelpers)}
                        >
                          <AddIcon />
                        </Button>
                      </Tooltip>
                    )}
                  </Grid>
                )}
              </FieldArray>
            </Grid>
          </FormWrap>
        </Paper>
      )}
    </Formik>
  )
}
