import { useSnackbar } from "@elentari/core";
import { Button, CircularProgress, Grid, makeStyles, styled } from "@material-ui/core";
import { FormikValues, useFormikContext } from "formik";
import { clone, isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { InstrucaoAdmissao, InstrucaoAdmissaoToPDf } from "../types";
import { handleDataToPDF } from "../utils/configInstrucaoAdmissaoPDF";

const useStyles = makeStyles(theme => ({
  returnButton: {
    backgroundColor: theme.palette.white
  },
  imprimirButton: {
    backgroundColor: theme.palette.success.light,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    }
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '135px'
}));

interface AdmissaoCandidatoButtonsProps {
  instrucaoAdmissao?: InstrucaoAdmissao
  onSubmit: () => void
  disabled: boolean
  isSubmitting: boolean
}

export const InstrucaoAdmissaoButtons = ({
  instrucaoAdmissao,
  onSubmit,
  disabled,
  isSubmitting
}: AdmissaoCandidatoButtonsProps) => {

  const history = useHistory()
  const classes = useStyles()

  const [, snackbarActions] = useSnackbar()

  const [imprimir, setImprimir] = useState<boolean>(false)

  const buttonsDisabled = isSubmitting || disabled

  const { validateForm, submitForm } = useFormikContext<FormikValues>()

  const handleImprimir = async () => {
    if (await resetForm()) {
      setImprimir(true)
    } else {
      snackbarActions.setMessage('Existem campos inválidos no formulário')
    }
  }

  useEffect(() => {
    if (imprimir) {
      setImprimir(false)
      handleDataToPDF(clone(instrucaoAdmissao) as InstrucaoAdmissaoToPDf)
    }
  }, [instrucaoAdmissao, imprimir])

  const resetForm = async () => {
    const formValidation = await validateForm()
    await submitForm()

    return isEmpty(formValidation)
  }

  return (
    <>
      <Grid container alignContent="center" justifyContent={'flex-end'} spacing={4}>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <StyledButton
            fullWidth
            variant="contained"
            className={classes.returnButton}
            onClick={() => history.goBack()}
          >
            Voltar
          </StyledButton>
        </Grid>
        {instrucaoAdmissao && (
          <>
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <StyledButton
                fullWidth
                variant="contained"
                className={classes.imprimirButton}
                disabled={buttonsDisabled}
                onClick={handleImprimir}
              >
                Imprimir
              </StyledButton>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <StyledButton
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => onSubmit()}
            disabled={buttonsDisabled}
          >
            {
              isSubmitting ?
                <CircularProgress color="inherit" size={24} />
                :
                <>Salvar</>
            }
          </StyledButton>
        </Grid>
      </Grid>
    </>
  )
}
