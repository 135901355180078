import { useSnackbar } from "@elentari/core"
import { Checkbox, Grid, makeStyles, styled, Typography } from "@material-ui/core"
import { ChangeEvent, useState } from "react"
import { updateSelecaoCandidato } from "../../hooks/selecaoCandidatoRepository"
import { ResultadoSelecaoItemType } from "./ResultadoSelecao"

const useStyles = makeStyles(theme => ({
  checkbox: {
    marginTop: '-10px'
  },
  alignContent: {
    display: 'flex',
    alignItems: 'center'
  },
  disabledLabel: {
    color: 'rgba(0, 0, 0, 0.40)'
  }
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  height: '40px'
}))

export const ResultadoSelecaoItem = ({
  selecaoCandidatoId,
  resultadoSelecao,
  disabled
}: {
  selecaoCandidatoId: string | undefined
  resultadoSelecao: ResultadoSelecaoItemType
  disabled: boolean
}) => {
  const [resultadoValue, setResultadoValue] = useState<boolean | undefined>(resultadoSelecao.initialValue)
  const [, snackbarActions] = useSnackbar()
  const classes = useStyles()

  const handleOnChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value === 'true'

    if (resultadoValue === eventValue) return

    if (selecaoCandidatoId) {
      const response = await updateSelecaoCandidato(selecaoCandidatoId, {
        [resultadoSelecao.fieldName]: eventValue
      })

      if (response && response.ok) {
        setResultadoValue(eventValue)
      } else {
        snackbarActions.setMessage(
          `Ocorreu um erro ao alterar o ${resultadoSelecao.fieldName} da Seleção do(a) Candidato(a)`
        )
      }
    } else {
      snackbarActions.setMessage(
        `O Candidato(a) não possui nenhuma Seleção Cadastrada`
      )
    }
  }

  return (
    <>
      <StyledGrid item xs={7} className={classes.alignContent}>
        <Typography variant="body2" className={disabled || !selecaoCandidatoId ? classes.disabledLabel : undefined}>
          {resultadoSelecao.fieldLabel}
        </Typography>
      </StyledGrid>
      <StyledGrid item xs={2}>
        <Checkbox
          checked={resultadoValue !== undefined && resultadoValue === true}
          value={true}
          onChange={handleOnChange}
          className={classes.checkbox}
          disabled={disabled || !selecaoCandidatoId}
        />
      </StyledGrid>
      <StyledGrid item xs={3}>
        <Checkbox
          checked={resultadoValue !== undefined && resultadoValue === false}
          value={false}
          onChange={handleOnChange}
          className={classes.checkbox}
          disabled={disabled || !selecaoCandidatoId}
        />
      </StyledGrid>
    </>
  )
}