import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { ParsedQs } from 'qs'
import {
  ProcessoSeletivoAdministrativoDetail,
  SaveProcessoSeletivoAdministrativoInput
} from '../types'
import { apiSauceInstance } from 'src/services/api'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params
  return { ...params, ...paginationParams(rest) }
}

export const repository = makeRepository<
  EdgesPage<ProcessoSeletivoAdministrativoDetail>,
  SaveProcessoSeletivoAdministrativoInput,
  ProcessoSeletivoAdministrativoDetail,
  string
>(
  {
    resource: 'processo-seletivo-administrativos',
    mapQueryParams
  },
  apiSauceInstance
)

export const getInfoProcessoAdministrativo = async (processoId: string) => {
  const response = await apiSauceInstance.get<any>(
    `/processo-seletivo-administrativos/get-info/${processoId}`
  )

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}

export const fetchProcessoAdministrativoFromCandidatoId = async (candidatoId: string) => {
  const response = await apiSauceInstance.get<{ edges: ProcessoSeletivoAdministrativoDetail[], totalCount: number }>(
    `/processo-seletivo-administrativos/candidato`,
    {
      candidatoId
    }
  )

  return response
}
