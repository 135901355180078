import Filter from '@elentari/components-eve/components/Filter'
import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import {
  Grid, Tooltip
} from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import {
  useEffect,
  useState
} from 'react'
import { FormSpy } from 'react-final-form'
import {
  ConvertionSelect,
  useConvertionStyles
} from 'src/components/Formik/Forms/ConvertionInput'
import SelectField from 'src/components/Forms/SelectField'
import * as yup from 'yup'
import RFFAutocomplete from '../../../components/Forms/RFFAutocomplete'
import { Option } from '../../../hooks/useAutoComplete'
import {
  booleanOptions,
  categoriaCNH,
  dateValues,
  optionCaminhao
} from '../../../utils'
import lugares from '../../../utils/cidades.json'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import { notasClassificacao } from '../types'
import { aprovarTriagemOptions, reprovarTriagemOptions } from '../utils/triagemOptions'

type TriadoMotoristaQueryFilter = {
  createdAt?: { gt: string }
  [key: string]: any
}

const schema = yup.object().shape({
  name: yup.string().typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  email: yup.string().typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  dataNascimento: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  createdAtStart: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  createdAtEnd: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .test({
      name: 'data-cadastrado-valid',
      message: "A data informada é inferior a 'Data Cadastro de'",
      test: (createdAtEnd, context) => {
        if (!createdAtEnd || !context.parent.createdAtStart) {
          return true
        }
        return createdAtEnd >= context.parent.createdAtStart
      }
    }),
})

const TriadoMotoristaFilter = ({
  statusTriagem
}: {
  statusTriagem: 'APROVADO' | 'REPROVADO'
}) => {
  const [uf, setUF] = useState<Option[]>([])
  const { clear, push, initialValues } = useQueryParams<TriadoMotoristaQueryFilter>()

  const handleDefaultSelectValue = (): Number => {
    if (
      initialValues.tempoExperienciaCaminhao &&
      initialValues.tempoExperienciaCaminhao % 12 === 0
    ) {
      return 12
    }

    return 1
  }

  const [convertionValue, setConvertionValue] = useState<Number>(
    handleDefaultSelectValue()
  )
  const classes = useConvertionStyles()

  const handleSubmitFilter = (values: any) => {
    if (values.categoriaCNH === null) {
      delete values.categoriaCNH
    }
    if (values.tipoCaminhao === null) {
      delete values.tipoCaminhao
    }
    if (values.mopp === null) {
      delete values.mopp
    }
    if (values.notaClassificacao === null) {
      delete values.notaClassificacao
    }
    if (values.triagemMotivo === null) {
      delete values.triagemMotivo
    }
    if (values.dataNascimento) {
      values.dataNascimento = new Date(values.dataNascimento).toISOString()
    }
    if (values.createdAtStart) {
      values.createdAtStart = new Date(values.createdAtStart).toISOString()
    }
    if (values.createdAtEnd) {
      values.createdAtEnd = new Date(values.createdAtEnd).toISOString()
    }

    const valorConversao =
      typeof convertionValue === 'number' ? convertionValue : 1
    push({
      ...values,
      tempoExperienciaCaminhao:
        typeof values.tempoExperienciaCaminhao !== 'undefined'
          ? values.tempoExperienciaCaminhao * valorConversao
          : undefined
    })
  }

  useEffect(() => {
    const ufs = lugares.estados.map(locais => {
      return {
        id: locais.sigla,
        name: locais.sigla
      }
    })
    setUF(ufs)
  }, [])

  const getCidadesFromUf = (uf: string): Option[] => {
    const findUF = lugares.estados.find(cidade => cidade.sigla === uf)

    if (findUF) {
      const cidades = findUF.cidades.map(cidade => {
        return {
          id: cidade,
          name: cidade
        }
      })

      return cidades
    } else {
      return []
    }
  }

  const setInitialValues = (initialValues: TriadoMotoristaQueryFilter) => {
    const tempoExperienciaCaminhao = initialValues.tempoExperienciaCaminhao
    return {
      ...initialValues,
      incluirBloqueados: 'false',
      tempoExperienciaCaminhao: tempoExperienciaCaminhao
        ? tempoExperienciaCaminhao % 12 === 0
          ? tempoExperienciaCaminhao / 12
          : tempoExperienciaCaminhao
        : undefined
    }
  }

  const handleOnChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      typeof event.target.value === 'number' ? event.target.value : 0
    setConvertionValue(value)
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={setInitialValues(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter(values)}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4} sm={12} md={4}>
          <TextField fullWidth name="cpf" label="CPF" />
        </Grid>
        <Grid item xs={12} lg={4} sm={12} md={4}>
          <TextField fullWidth name="name" label="Nome Completo" />
        </Grid>
        <Grid item xs={12} lg={4} sm={12} md={4}>
          <DatePicker
            fullWidth
            name="dataNascimento"
            label="Data Nascimento"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <RFFAutocomplete name="uf" label="UF" options={uf} />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <FormSpy
            render={({ values }) => (
              <RFFAutocomplete
                name="cidade"
                label="Cidade"
                options={getCidadesFromUf(values.uf)}
                disabled={!values.uf}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <TextField fullWidth name="quemIndicou" label="Quem Indicou" />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          {statusTriagem === 'APROVADO' ? (
            <Select
              fullWidth
              name="triagemMotivo"
              label="Status Aprovação"
              items={aprovarTriagemOptions}
            />
          ) : (
            <Select
              fullWidth
              name="triagemMotivo"
              label="Status Reprovação"
              items={reprovarTriagemOptions}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <DatePicker
            fullWidth
            name="createdAtStart"
            label="Data Cadastro de"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4} sm={6} md={4}>
          <DatePicker
            fullWidth
            name="createdAtEnd"
            label="Data Cadastro até"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <EventIcon />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} lg={3} sm={12} md={3}>
          <SelectField
            name="incluirBloqueados"
            label="Lista Bloqueio"
            items={booleanOptions}
            defaultValue={'false'}
          />
        </Grid>
        <Grid item xs={12} lg={3} sm={4} md={3}>
          <Select
            fullWidth
            name="notaClassificacao"
            label="Classificação"
            items={notasClassificacao}
          />
        </Grid>
        <Grid item xs={12} lg={3} sm={4} md={3}>
          <Select
            fullWidth
            name="mopp"
            label="Mopp"
            items={booleanOptions}
          />
        </Grid>
        <Grid item xs={12} lg={3} sm={4} md={3}>
          <Select
            fullWidth
            name="categoriaCNH"
            label="Habilitação"
            items={categoriaCNH}
          />
        </Grid>
        <Grid item xs={12} lg={6} sm={12} md={6}>
          <Select
            fullWidth
            name="tipoCaminhao"
            label="Tipo do Caminhão"
            items={optionCaminhao}
          />
        </Grid>
        <Grid item xs={12} lg={6} sm={12} md={6}>
          <TextField
            fullWidth
            name="tempoExperienciaCaminhao"
            label="Tempo de Experiência (A partir de)"
            className={classes.textField}
            type="number"
            InputProps={{
              endAdornment: ConvertionSelect(
                dateValues,
                handleDefaultSelectValue(),
                handleOnChangeSelect
              ),
              classes: {
                adornedEnd: classes.endAdornment
              }
            }}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export { TriadoMotoristaFilter }

