import {
  Button,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  Box
} from '@material-ui/core'
import { useHistory } from 'react-router'
import EditIcon from '@material-ui/icons/Edit'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { TreinamentoDetail } from '../types'
import { Paper } from '../../../components'
import { statusTreinamento } from '../types'
import { monetaryFormatter } from '../../../utils/formatters'
import ReactHtmlParser from 'react-html-parser'

const useStyles = makeStyles(theme => ({
  cardInicial: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  containerLocation: {
    display: 'flex',
    flexDirection: 'row'
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fill-available',
    padding: '2rem 0rem'
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right',
    padding: '2rem 0rem'
  },
  warningIcon: {
    color: 'red'
  },
  returnButton: {
    position: 'absolute',
    top: '2rem',
    left: '2rem'
  },
  itemTreinamento: {
    display: 'flex',
    alignItems: 'center',
    padding: 2
  },
  itemTreinamentoLabel: {
    marginRight: 5
  },
  itemTreinamentoContent: {
    paddingLeft: 10
  }
}))

export const TreinamentoCard = (treinamento: TreinamentoDetail) => {
  const classes = useStyles()
  const history = useHistory()

  const handleReturnList = () => {
    history.push('/treinamentos')
  }

  const handleEditTreinamento = () => {
    history.push('/treinamentos/' + treinamento.id)
  }

  return (
    <Box marginBottom={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Paper minHeight={300}>
            <Button
              className={classes.returnButton}
              color="default"
              onClick={handleReturnList}
              startIcon={<ArrowBackIcon />}
            >
              Retornar para a Listagem
            </Button>
            <CardContent>
              <div className={classes.containerLocation}>
                <div className={classes.dataContainer}>
                  <div className={classes.cardInicial}>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: 'bold' }}
                      gutterBottom
                    >
                      Treinamento
                    </Typography>
                    <Typography
                      className={classes.itemTreinamento}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="left"
                    >
                      <strong className={classes.itemTreinamentoLabel}>Nome: </strong>
                      {treinamento.nome}
                    </Typography>
                    <Typography
                      className={classes.itemTreinamento}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="left"
                    >
                      <strong className={classes.itemTreinamentoLabel}>Status: </strong>
                      {
                        statusTreinamento.find(status => status.value === treinamento.status)
                          ?.label
                      }
                    </Typography>
                    <Typography
                      className={classes.itemTreinamento}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="left"
                    >
                      <strong className={classes.itemTreinamentoLabel}>Carga Horária: </strong>
                      {treinamento.cargaHoraria}
                    </Typography>
                    <Typography
                      className={classes.itemTreinamento}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="left"
                    >
                      <strong className={classes.itemTreinamentoLabel}>Valor por Pessoa: </strong>
                      {monetaryFormatter.format(treinamento.valorPorPessoa)}
                    </Typography>
                    <Typography
                      className={classes.itemTreinamento}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      <strong className={classes.itemTreinamentoLabel}>Descrição: </strong>
                      <div className={classes.itemTreinamentoContent}>
                        {ReactHtmlParser(treinamento.descricao)}
                      </div>
                    </Typography>
                  </div>
                </div>
                <div className={classes.iconContainer}>
                  <Tooltip title="Editar Treinamento">
                    <IconButton
                      aria-label="edit"
                      onClick={handleEditTreinamento}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}
