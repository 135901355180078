import { EdgesPage } from "@elentari/core/types"
import { apiSauceInstance } from "src/services/api"
import { TreinamentoDetail } from "../types"

export const treinamentosEmAndamento = async () => {
  const response = await apiSauceInstance.get<EdgesPage<TreinamentoDetail>>('/treinamentos', {
    status: 'EM_ANDAMENTO'
  })

  const { data: info, ok, status } = response

  if (info && ok) {
    return {
      info,
      ok,
      status
    }
  }
  return null
}